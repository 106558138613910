import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NovaDineHttpService } from './novadine-http.service';
import { KeyValuePair } from './interfaces/key-value-pair.interface';
import { HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Menu } from './interfaces/menu.interface';
import { Store } from './interfaces/store.interface';
import { CreateBasketRequest } from './interfaces/create-basket-request.interface';
import { OrderResponse } from './interfaces/order-response.interface';
import { UpdateOrderRequest } from './interfaces/update-order-request.interface';
import { PaymentMethod } from './interfaces/payment-method.interface';
import {CashPaymentRequest, NewCardPaymentRequest, SavedCardPaymentRequest} from './interfaces/payment-request.interface';
import { PaymentResponse } from './interfaces/payment-response.interface';
import { CustomerInfo } from './interfaces/customer-info.interface';
import { UpdateCustomerInfoRequest } from './interfaces/update-customer-info-request.interface';
import { CustomerRegistrationRequest } from './interfaces/customer-registration-request.interface';
import { ProductDetails } from './interfaces/product-details.interface';
import { OrderInfo } from './interfaces/order-info.interface';
import { PastOrdersParams } from './interfaces/past-order-params.interface';
import { SaveCardRequest } from './interfaces/save-card-request.interface';
import { OkResponse } from './interfaces/ok-response.interface';
import { SavedCard } from './interfaces/saved-card.interface';
import { StoresRequest } from './interfaces/stores-request.interface';
import { StoreDeliveryInfo } from './interfaces/store-delivery-info.interface';
import { CustomerAddress } from './interfaces/customer-address.interface';
import { CustomerAddressResponse } from './interfaces/customer-address-response.interface';
import { UpdateBasketRequest } from './interfaces/update-basket-request.interface';
import {OrderInfoResponse} from './interfaces/order-info-response.interface';
import { CheckPaymentResponse } from './interfaces/check-payment-response.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import {Cacheable} from 'ts-cacheable';
import {RetrieveCustomerLoyaltyBalancesResponse} from './interfaces/retrieve-customer-loyalty-balances.interface';
import {RetrieveAvailableLoyaltyDiscountsForOrderResponse} from './interfaces/retrieve-available-loyalty-discounts-for-order.interface';
import {GroupOrderResponse} from '../olo/interfaces/group-order.interface';

@Injectable({
    providedIn: 'root'
})
export class NovaDineAPIService {

    constructor(private novaDineHttp: NovaDineHttpService) { }

    ///////////////////////////////////////////// STORES /////////////////////////////////////////////////////////////////////

    getAllStores(): Observable<Store[]> {
        const paramsStr = this.getQueryParamsString([
            { key: 'all_stores', value: true }
        ]);
        const resource = '/stores';
        return this.novaDineHttp.get<Store[]>(resource + paramsStr);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    getStores(params: StoresRequest): Observable<Store[]> {
        const paramsStr = this.getQueryParamsString([
            { key: 'store_id', value: params.storeID },
            { key: 'ready_ts', value: params.readyTimestamp },
            { key: 'street_number', value: params.streetNumber },
            { key: 'street_address', value: params.streetAddress },
            { key: 'latitude', value: params.latitude },
            { key: 'longitude', value: params.longitude },
            { key: 'city', value: params.city },
            { key: 'state', value: params.state },
            { key: 'postal_code', value: params.zip },
            { key: 'service_type_id', value: params.serviceTypeID },
            { key: 'service_schedule', value: params.includeServiceSchedule }
        ]);
        const resource = '/stores';
        return this.novaDineHttp.get<Store[]>(resource + paramsStr);
    }

    getStoreDeliveryInfo(storeID: string, zip: string, address1: string, city: string, state: string): Observable<StoreDeliveryInfo> {
        const body = {
            address: {
                postal_code: zip,
                street_number: address1.split(' ')[0],
                street_address: address1.replace(address1.split(' ')[0] + ' ', ''),
                city,
                state
            }
        };
        const resource = `/stores/${storeID}/delivery-info`;
        return this.novaDineHttp.post(resource, body);
    }

    /*     getStorePromos(storeID: string): Observable<Promo[]> {
            const resource = `/stores/${storeID}/promos`;
            return this.novaDineHttp.get(resource);
        } */

    /*     getMenuItemsByBarcode(storeID: string, barcode: string): Observable<MenuItem> {
            const resource = `/stores/${storeID}/menus/item-search`;
            return this.novaDineHttp.get(resource);
        } */

    @Cacheable({
        maxAge: (15 * 60 * 1000)
    })
    getStore(storeID: string): Observable<Store> {
        const resource = `/stores/${storeID}`;
        return this.novaDineHttp.get(resource);
    }

    /*     getStoreHours(storeID: string): Observable<StoreHoursSet> {
            const resource = `/stores/${storeID}/hours`;
            return this.novaDineHttp.get(resource);
        } */

    /*     getStoreMenu(storeID: string): Observable<StoreMenuResponse> {
            const resource = `/stores/${storeID}/menu`;
            return this.novaDineHttp.get(resource);
        } */

    @Cacheable({
        maxAge: (15 * 60 * 1000)
    })
    getStoreBaseMenu(storeID: string, serviceTypeID: number): Observable<Menu[]> {
        const paramsStr = this.getQueryParamsString([{
            // key: 'service_type_id', value: serviceTypeID
            key: 'skip_pick_lists', value: true
        }]);
        const resource = `/stores/${storeID}/menus`;
        return this.novaDineHttp.get(resource + paramsStr);
    }

    /*     getStoreMenuCategoryItems(storeID: string, menuID: number, categoryID: number): Observable<StoreMenuCategoryItem[]> {
            const resource = `/stores/${storeID}/menus/${menuID}/categories/${categoryID}/items`;
            return this.novaDineHttp.get(resource);
        } */

    /*     getStoreMenuCategories(storeID: string, menuID: number): Observable<Category[]> {
            const resource = `/stores/${storeID}/menus/${menuID}/categories`;
            return this.novaDineHttp.get(resource);
        } */

    @Cacheable({
        maxAge: (15 * 60 * 1000),
        maxCacheCount: 100
    })
    getStoreMenuCategoryItemDetails(storeID: string, menuID: string, categoryID: string, itemID: string): Observable<ProductDetails> {
        const resource = `/stores/${storeID}/menus/${menuID}/categories/${categoryID}/items/${itemID}`;
        return this.novaDineHttp.get<ProductDetails>(resource);
    }

    /*     submitOrder(storeID: string, body: SubmitOrderRequest): Observable<SubmitOrderResponse> {
            const resource = `/stores/${storeID}/orders`;
            return this.novaDineHttp.post(resource, body);
        } */

    //////////////////////////////////////////////////////// ORDERS //////////////////////////////////////////////////////////////////////

    /* searchOrders(req: SearchOrdersRequest): Observable<SearchOrdersResponse> {
        const resource = `/orders/search`;
        return this.novaDineHttp.post(resource, req);
    } */

    startOrUpdateOrder(edit: boolean, body: CreateBasketRequest | UpdateBasketRequest): Observable<OrderResponse> {
        const paramsStr = this.getQueryParamsString([{
            key: 'edit_mode', value: edit
        }]);
        const resource = `/orders/new`;
        return this.novaDineHttp.post(resource, body);
    }

    /*     cancelOrder(orderID: string): Observable<any> {
            const resource = `/orders/${orderID}`;
            return this.novaDineHttp.delete(resource);
        } */

    getOrderDetails(orderID: string): Observable<OrderResponse> {
        const resource = `/orders/${orderID}`;
        return this.novaDineHttp.get(resource);
    }

    updateOrder(orderID: string, body: any): Observable<any> {
        const resource = `/orders/${orderID}`;
        return this.novaDineHttp.put(resource, body);
    }

    /*     listAvailableCharitableDonations(orderID: string): Observable<any> {
            const resource = `/orders/${orderID}/charitable-foundations`;
            return this.novaDineHttp.get(resource);
        } */

    addCouponToOrder(orderID: string, body: any): Observable<OrderResponse> {
        const resource = `/orders/${orderID}/coupon`;
        return this.novaDineHttp.post(resource, body);
    }

    removeCouponFromOrder(orderID: string, params = {}): Observable<OrderResponse> {
        const resource = `/orders/${orderID}/coupon`;
        const httpParams = new HttpParams({fromObject: params});
        return this.novaDineHttp.delete(resource, httpParams);
    }

    /*     getOrderRelatedEntities(orderID: string): Observable<any> {
            const resource = `/orders/${orderID}/relate`;
            return this.novaDineHttp.get(resource);
        } */

    removeItemFromOrder(orderID: string, orderItemID: string): Observable<OrderResponse> {
        const resource = `/orders/${orderID}/items/${orderItemID}`;
        return this.novaDineHttp.delete(resource);
    }

    updateOrderItems(orderID: string, body: UpdateOrderRequest): Observable<OrderResponse> {
        const resource = `/orders/${orderID}/items`;
        return this.novaDineHttp.post(resource, body);
    }

    /*     getOrderPayment(orderID: string, paymentID: string): Observable<Payment> {
            const resource = `/orders/${orderID}/payment/${paymentID}`;
            return this.novaDineHttp.get(resource);
        } */

    /*     verifyReceiptIsReadyToBeShown(orderID: string, action: string): Observable<{ ok: boolean }> {
            const resource = `/orders/${orderID}/actions/${action}`;
            return this.novaDineHttp.get<{ ok: boolean }>(resource);
        } */

    getGroupOrder(joinCode: string): Observable<GroupOrderResponse> {
        const paramsStr = this.getQueryParamsString([{
            key: 'join_code', value: joinCode
        }]);
        const resource = `/orders/group-ordering`;
        return this.novaDineHttp.get(resource + paramsStr);
    }

    addMemberToGroupOrderByEmail(joinCode: string, email: string): Observable<GroupOrderResponse> {
        const paramsStr = this.getQueryParamsString([{
            key: 'join_code', value: joinCode
        }]);
        const resource = `/orders/group-ordering/members`;
        return this.novaDineHttp.post(resource + paramsStr, { email });
    }

    /*     addMemberToGroupOrderByCustomerID(groupOrderID: string, customerID: string): Observable<{ customerID: string }> {
            const resource = `/orders/group-ordering/${groupOrderID}/members`;
            return this.novaDineHttp.post(resource, { customer_id: customerID });
        } */

    @Cacheable()
    getPaymentMethodsForOrder(orderID: string): Observable<PaymentMethod[]> {
        const resource = `/orders/${orderID}/payment-methods`;
        return this.novaDineHttp.get(resource);
    }

    submitOrderPaymentUsingSavedCard(orderID: string, body: SavedCardPaymentRequest): Observable<PaymentResponse> {
        const resource = `/orders/${orderID}/payment`;
        return this.novaDineHttp.post(resource, body);
    }

    submitOrderPaymentUsingNewCard(orderID: string, body: NewCardPaymentRequest | SavedCardPaymentRequest | CashPaymentRequest): Observable<PaymentResponse> {
        const resource = `/orders/${orderID}/payment`;
        return this.novaDineHttp.post(resource, body);
    }

    /*     submitOrderPaymentUsingHeartland(orderID: string, body: HeartlandPaymentRequest): Observable<PaymentResponse> {
            const resource = `/orders/${orderID}/payment`;
            return this.novaDineHttp.post(resource, body);
        } */

    checkPaymentStatus(paymentToken: string, payProcID: string): Observable<CheckPaymentResponse> { // no example
        const paramsStr = this.getQueryParamsString([{
            key: 'pay_proc_id', value: payProcID
        }]);
        const resource = `/orders/payment/${paymentToken}`;
        return this.novaDineHttp.get(resource + paramsStr);
    }

    retrieveAvailableLoyaltyDiscountsForOrder(orderID: string): Observable<RetrieveAvailableLoyaltyDiscountsForOrderResponse> {
        const resource = `/orders/${orderID}/available_discounts`;
        return this.novaDineHttp.get<RetrieveAvailableLoyaltyDiscountsForOrderResponse>(resource);
    }

    //////////////////////////////////////////////// CUSTOMERS ///////////////////////////////////////////////////////////////////////////

    addToSavedOrders(customerID: string, orderID: string): Observable<OrderInfoResponse> {
        const resource = `/customers/${customerID}/saved-orders`;
        const body = { order_id: orderID };
        return this.novaDineHttp.post<OrderInfoResponse>(resource, body);
    }

    /*    removeFromSavedOrders(customerID: string, orderID: string): Observable<NovaDine.OrderInfoResponse> {
           const resource = `/customers/${customerID}/saved-orders`;
           const body = { order_id: orderID };
           return this.novaDineHttp.delete<NovaDine.OrderInfoResponse>(resource, body);
       } */ // the docs say we need to send a body on a delete request???

    /*     addToFavoriteItems(customerID: string, body: SaveFavoriteRequest): Observable<OrderInfoResponse> {
            const resource = `/customers/${customerID}/favorite-items`;
            return this.novaDineHttp.post<OrderInfoResponse>(resource, body);
        } */

    /*     removeFromFavoriteItems(customerID: string, favoriteID: string): Observable<any> {
            const resource = `/customers/${customerID}/favorite-items`;
            const paramsStr = this.getQueryParamsString([{
                key: 'favorite_id', value: favoriteID
            }]);
            return this.novaDineHttp.delete<any>(resource + paramsStr);
        } */

    /*     getFavoriteItems(customerID: string, params: FavoriteItemsParams): Observable<FavoriteItem[]> {
            const resource = `/customers/${customerID}/favorite-items`;
            const paramsStr = this.getQueryParamsString([
                { key: 'ready_ts', value: params.readyTimestamp },
                { key: 'service_type_id', value: params.serviceTypeID },
                { key: 'store_id', value: params.storeID },
                { key: 'include_unavailable', value: params.includeUnavailable }]);
            return this.novaDineHttp.get<FavoriteItem[]>(resource + paramsStr);
        } */

    @Cacheable({
        maxAge: (3 * 60 * 1000)
    })
    getPastOrders(customerID: string, params: PastOrdersParams): Observable<OrderInfo[]> {
        const resource = `/customers/${customerID}/orders`;
        const paramsStr = this.getQueryParamsString([
            { key: 'include_items', value: params.includeItemDetails },
            { key: 'saved_orders', value: params.onlySavedOrders },
            { key: 'open_orders', value: params.onlyOpenOrders },
            { key: 'all_orders', value: params.allOrders }]);
        return this.novaDineHttp.get<OrderInfo[]>(resource + paramsStr);
    }

    /*     getPastGroupOrders(customerID: string, params: PastOrdersParams): Observable<GroupOrderInfo[]> {
            const resource = `/customers/${customerID}/group-ordering-orders`;
            return this.novaDineHttp.get<GroupOrderInfo[]>(resource);
        } */

    updateCustomerInfo(customerID: string, body: UpdateCustomerInfoRequest): Observable<CustomerInfo> {
        const resource = `/customers/${customerID}`;
        return this.novaDineHttp.post<CustomerInfo>(resource, body);
    }

    getCustomerInfo(customerID: string): Observable<CustomerInfo> {
        const resource = `/customers/${customerID}`;
        return this.novaDineHttp.get<CustomerInfo>(resource);
    }

    /*     checkInAnOrder(customerID: string, orderID: string, body: CheckInRequest): Observable<OrderInfoResponse> {
            const resource = `/customers/${customerID}/orders/checkin/${orderID}`;
            return this.novaDineHttp.post<OrderInfoResponse>(resource, body);
        } */

    /*     getOrdersPossibleToCheckIn(customerID: string): Observable<CheckInOrder[]> {
            const resource = `/customers/${customerID}/orders/checkin`;
            return this.novaDineHttp.get<CheckInOrder[]>(resource);
        } */

    getLoggedInCustomerInfo(): Observable<CustomerInfo> {
        const resource = `/customers/me`;
        return this.novaDineHttp.get<CustomerInfo>(resource);
    }

    /*     sendRegistrationVerificationCode(email: string): Observable<{ ok: boolean }> {
            const resource = `/customers/send-verify-code`;
            const body = { email: email };
            return this.novaDineHttp.post<{ ok: boolean }>(resource, body);
        } */

    /*     verifySecurityCode(email: string, securityCode: string): Observable<{ ok: boolean }> {
            const resource = `/customers/verify`;
            const body = {
                email: email,
                security_code: securityCode
            };
            return this.novaDineHttp.get<{ ok: boolean }>(resource);
        } */

    /* setOrResetPassword(email: string, newPassword: string, isReset: boolean): Observable<CustomerRegistrationResponse> {
        const resource = `/customers/password`;
        return this.novaDineHttp.post<CustomerRegistrationResponse>(resource, body);
    } */

    forgotPassword(email: string): Observable<{ message: string, ok: boolean }> {
        const body = {
            email,
            reset: true
        };
        const resource = `/customers/password`;
        return this.novaDineHttp.post<{ message: string, ok: boolean }>(resource, body);
    }

    changePassword(email: string, newPassword: string): Observable<{ message: string, ok: boolean }> {
        const body = {
            email,
            new_password: newPassword,
            reset: true
        };
        const resource = `/customers/password`;
        return this.novaDineHttp.post<{ message: string, ok: boolean }>(resource, body);
    }

    resetPassword(email: string, newPass: string, token: string): Observable<PassResetResponse> {
        const body = {
            new_password: newPass,
            reset: false,
            token
        };
        const resource = `/customers/password`;
        return this.novaDineHttp.post<{ message: string, ok: boolean }>(resource, body);
    }

    registerNewCustomer(body: CustomerRegistrationRequest): Observable<CustomerInfo> {
        const resource = '/customers/registration';
        return this.novaDineHttp.post<CustomerInfo>(resource, body).pipe(map(res => {
            return res;
        }, catchError(e => {
            return e;
        })));
    }

    /*     getCustomerAddresses(customerID: string, addressID: string = null): Observable<any> {
            const resource = `/customers/${customerID}/addresses`;
            const paramsStr = this.getQueryParamsString([{
                key: 'address_id', value: addressID
            }]);
            return this.novaDineHttp.get<any>(resource + paramsStr);
        } */

    /*     deleteCustomerAddress(customerID: string, addressID: string): Observable<any> {
            const resource = `/customers/${customerID}/addresses`;
            const paramsStr = this.getQueryParamsString([{
                key: 'address_id', value: addressID
            }]);
            return this.novaDineHttp.delete<any>(resource + paramsStr);
        } */

    saveCustomerAddress(customerID: string, body: CustomerAddress): Observable<CustomerAddressResponse> {
        const resource = `/customers/${customerID}/addresses`;
        return this.novaDineHttp.post<CustomerAddressResponse>(resource, body);
    }

    logOutCustomer(): Observable<{ ok: string }> {
        const resource = '/customers/logout';
        return this.novaDineHttp.delete<{ ok: string }>(resource);
    }

    logInExistingCustomer(username: string, password: string): Observable<number> {
        const body = {
            username,
            password
        };
        const resource = '/customers/login';
        return this.novaDineHttp.post<{ customer_id: number, auth_cookie: string }>(resource, body).pipe(map(res => {
            return res.customer_id;
        }));
    }

    logInGuestCustomer(sourceURL: string): Observable<number> {
        const resource = '/customers/guest';
        return this.novaDineHttp.post<any>(resource, { source: sourceURL }).pipe(map(res => {
            return res.customer_id;
        }));
    }

    logInByForeignAuth(username: string, password: string, provider: string): Observable<number> {
        const body = {
            provider,
            auth_info: {
                username,
                password
            }
        };
        const resource = '/customers/login_by_foreign_auth';
        return this.novaDineHttp.post<{ ok: boolean, customer_id: number }>(resource, body).pipe(map(res => {
            return res.customer_id;
        }));
    }

    retrieveCustomerLoyaltyBalances(customerID: string): Observable<RetrieveCustomerLoyaltyBalancesResponse> {
        const resource = `/customers/${customerID}/loyalty`;
        return this.novaDineHttp.get<RetrieveCustomerLoyaltyBalancesResponse>(resource);
    }

    getSavedCards(customerID: string): Observable<SavedCard[]> {
        const resource = `/customers/${customerID}/saved-cards`;
        return this.novaDineHttp.get<SavedCard[]>(resource);
    }

    addToSavedCards(customerID: string, body: SaveCardRequest): Observable<any> {
        const resource = `/customers/${customerID}/saved-cards`;
        return this.novaDineHttp.post<any>(resource, body);
    }

    removeFromSavedCards(customerID: string, ccAccountID: string): Observable<OkResponse> {
        const resource = `/customers/${customerID}/saved-cards`;
        const paramsStr = this.getQueryParamsString([{
            key: 'cc_account_id', value: ccAccountID
        }]);
        return this.novaDineHttp.delete<OkResponse>(resource + paramsStr);
    }

    /*     getFavoriteStores(customerID: string): Observable<FavoriteStore[]> {
            const resource = `/customers/${customerID}/favorite-stores`;
            return this.novaDineHttp.get<FavoriteStore[]>(resource);
        } */

    /*     addToFavoriteStores(customerID: string, storeName: string, storeID: string): Observable<{ ok: string }> {
            const resource = `/customers/${customerID}/favorite-stores`;
            const body = {
                store_name: storeName,
                store_id: storeID
            };
            return this.novaDineHttp.post<{ ok: string }>(resource, body);
        } */

    /*     removeFromFavoriteStores(customerID: string, storeID: string): Observable<{ ok: string }> {
            const resource = `/customers/${customerID}/favorite-stores`;
            const paramsStr = this.getQueryParamsString([{
                key: 'store_id', value: storeID
            }]);
            return this.novaDineHttp.delete<{ ok: string }>(resource + paramsStr);
        } */

    deleteUser(customerID: string) {
        const resource = `/customers/${customerID}`;
        return this.novaDineHttp.delete<{ok: boolean}>(resource);
    }

    ///////////////////////////////// TOP LEVEL ///////////////////////////////////////////////////////

    /*     getContactForm(): Observable<ContactForm> {
            const resource = `/contact`;
            return this.novaDineHttp.get<ContactForm>(resource);
        } */

    /*     sendContactForm(body: ContactFormRequest): Observable<OkResponse> {
            const resource = '/contact';
            return this.novaDineHttp.post<OkResponse>(resource, body);
        } */

    /*     getCountries(): Observable<CountryResponse> {
            const resource = `/countries`;
            return this.novaDineHttp.get<CountryResponse>(resource);
        } */

    /*     getStates(): Observable<State[]> {
            const resource = `/states`;
            return this.novaDineHttp.get<State[]>(resource);
        } */

    private getQueryParamsString(pairs: KeyValuePair[]): string {
        let params = new HttpParams();
        pairs.forEach(p => {
            if (p.value !== undefined && p.value !== null) {
                params = params.set(p.key, p.value);
            }
        });
        return params && params.keys().length > 0 ?
            '?' + params.toString()
            : '';
    }
}
