<div class="px-3 pt-3 pb-5" (click)="bodyClicked()">
  <div class="d-flex justify-content-end w-100">
    <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="activeModal.close('Close click')"></i>
  </div>
  <div class="d-flex flex-row flex-nowrap pt-4 pt-md-0">
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
    <div class="d-flex flex-row flex-wrap gap-3 w-100 grow-3 basis-0">
      <div class="d-flex flex-row flex-wrap gap-2 w-100">
        <div class="text-center w-100" *ngIf="eyebrowText">
          {{ eyebrowText }}
        </div>
        <div *ngIf="headingText" [ngStyle]="{'color': headingColor}" class="w-100 modal-heading m-0 header-font">
          <h1 class="font-size-h1 m-0 fw-bold text-center">{{ headingText }}</h1>
        </div>
        <div class="text-center subheading-text w-100" *ngIf="subheadingText">
          {{ subheadingText }}
        </div>
      </div>
      <div class="text-center w-100" *ngIf="bodyText">
        {{ bodyText }}
      </div>
      <div class="text-center w-100" *ngIf="imageUrl">
          <img class="modal-image" [src]="imageUrl" [alt]="headingText">
      </div>
      <div class="text-center w-100">
        <button type="button" class="de-primary-btn w-100" (click)="buttonClicked()">{{ buttonText }}</button>
      </div>
    </div>
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
  </div>
</div>
