<div class="input-group pb-3">
  <div class="w-100 pb-1">Amount</div>

  <div
    class="w-100 pb-3 value-btn-group"
    id="button-addon3">
    <ng-container
      *ngIf="
        data &&
        data.cardValues &&
        data.cardValues.priceIntervals &&
        data.cardValues.priceIntervals.length
      ">
      <ng-container *ngFor="let value of data.cardValues.priceIntervals">
        <button
          (click)="setAmount(value)"
          class="btn btn-outline-primary px-2 w-100 card-value de-ghost-bg de-primary-border de-primary-text value-btn"
          type="button">
          {{ value | currency: currencyCode }}
        </button>
      </ng-container>
    </ng-container>
  </div>

  <div class="w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
    <form [formGroup]="cardValue">
      <label for="manualEntry" class="w-100"
        >Or Enter Desired Gift Card Amount</label
      >

      <input
        id="manualEntry"
        type="text"
        class="form-control rounded w-100 mb-1"
        placeholder=""
        aria-label="Example text with two button addons"
        aria-describedby="button-addon3"
        formControlName="value" />
      <!--               aria-describedby="button-addon3" formControlName="value" mask="0*.00">-->
      <ng-container
        *ngIf="
          data &&
          data.cardValues &&
          data.cardValues.lowerLimit &&
          data.cardValues.upperLimit
        ">
        <div class="w-100 small fs-italic">
          Enter an amount from between
          {{ data.cardValues.lowerLimit | currency: currencyCode }} to
          {{ data.cardValues.upperLimit | currency: currencyCode }}
        </div>
      </ng-container>
    </form>
  </div>
</div>

<ng-container *ngIf="flowType === 'EGIFT'">
  <div class="row m-0 w-100 border-top pt-3">
    <div class="pe-1">Total:</div>
    <div class="">{{ value.value | currency: currencyCode }}</div>
  </div>
</ng-container>
