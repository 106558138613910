import { Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../interfaces/user.interface';
import {AuthService} from '@modules/auth/services';
import {Order} from '../../../../interfaces/order.interface';
import {AppCommonService} from '@common/services';

@Component({
  selector: 'app-past-orders-list',
  templateUrl: './past-orders-list.component.html',
  styleUrls: ['past-orders-list.component.scss'],
})
export class PastOrdersListComponent implements OnInit {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;

  constructor(
    public authService: AuthService,
    public appCommonService: AppCommonService,
  ) {}

  ngOnInit() {}
}
