import {Component, Input} from '@angular/core';
import {Store} from '@ngxs/store';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  TransferStoredValueToLoyaltyAccount,
} from '../../../../store/actions/user.actions';
import {GiftCard} from '../../../../interfaces/giftcard.interface';
import {ToastService} from '../../../../services/toast.service';
import {collapseAnimation} from 'angular-animations';

@Component({
  selector: 'app-gift-card-balance-transfer',
  templateUrl: './gift-card-balance-transfer.component.html',
  styleUrls: ['gift-card-balance-transfer.component.scss'],
  animations: [
    collapseAnimation(),
  ],
  host: {
    class: 'flex-grow-1',
  },
})
export class GiftCardBalanceTransferComponent {

  @Input() storedValueCents: number;
  @Input() currencyCode: string;

  cardNumberForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(null, [Validators.required]),
    pinNumber: new UntypedFormControl(null, [Validators.required]),
  });

  isLoading = false;

  errorMessage: string;
  errorOpen = false;
  successMessage: string;
  successOpen = false;

  transferBalanceCopy = 'Transfer Gift Card';

  constructor(
      private store: Store,
      private toast: ToastService,
  ) {
    this.cardNumberForm.valueChanges.subscribe(() => {
      this.errorOpen = false;
      this.successOpen = false;
      this.errorMessage = null;
      this.successMessage = null;
    });
  }

  startTransfer(): void {
    if (this.isLoading) {
      return;
    }
    if (this.cardNumberForm.valid) {
      const card: GiftCard = {
        cardNumber: this.cardNumber.value,
        cardPin: this.pinNumber.value,
        balance: null,
      };
      this.isLoading = true;
      this.store.dispatch(new TransferStoredValueToLoyaltyAccount(card)).subscribe(() => {
        this.toast.success('Balance Transferred');
        this.successMessage = 'Balance Transfer Successful';
        this.cardNumberForm.reset();
        this.successOpen = true;
        this.isLoading = false;
        setTimeout(() => {
          this.successOpen = false;
          this.successMessage = null;
        }, 5000);
      }, (error) => {
        this.isLoading = false;
        this.errorMessage = error.message;
        this.errorOpen = true;
        this.toast.danger(this.errorMessage);
      });
    } else {
      this.errorMessage = 'Please enter a valid card number and pin';
      this.errorOpen = true;
    }
  }

  get cardNumber(): AbstractControl {
    return this.cardNumberForm.get('cardNumber');
  }

  get pinNumber(): AbstractControl {
    return this.cardNumberForm.get('pinNumber');
  }
}
