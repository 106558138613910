<ion-row>
  <ion-col>
    <ion-item (click)="productClicked()" lines="none" class="realItem fake-card category p-0">
      <ion-row style="width: 100%;">

        <ion-col size="{{ product.standardImageURL ? 8 : 12 }}" style="min-height: 110px;">
          <ion-label text-wrap class="menu-card-title">
            <b>{{ product.name }}</b>
          </ion-label>
          <ion-label text-wrap class="menu-card-desc">
            {{ product.shortDesc | truncate:50 }}
          </ion-label>
        </ion-col>

        <ion-col *ngIf="product.standardImageURL" class="img-col p-0" size="4" no-margin>
          <div
            class="real-img"
            [appBgLoader]="{ src: product.standardImageURL }"
            shine="true">
          </div>
        </ion-col>

      </ion-row>
    </ion-item>
  </ion-col>
</ion-row>