import { KioskCardComponent } from './kiosk-card/kiosk-card.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { CategoryScrollerComponent } from './category-scroller/category-scroller.component';
import { MenuBlockComponent } from './menu-block/menu-block.component';
import { CategoryJumpNavComponent } from './category-jump-nav/category-jump-nav.component';
import { CategoryBlockComponent } from './category-block/category-block.component';
import { IngredientCardComponent } from './ingredient-card/ingredient-card.component';
import { CustomizeProductBlockComponent } from './customize-product-block/customize-product-block.component';
import { ModifyFooterComponent } from './modify-footer/modify-footer.component';
import { OrderControlsComponent } from './order-controls/order-controls.component';
import { MenuLimitToggleComponent } from './menu-limit-toggle/menu-limit-toggle.component';
import { LocationInfoDisplayComponent } from './location-info-display/location-info-display.component';
import { MenuBreadcrumbsComponent } from './menu-breadcrumbs/menu-breadcrumbs.component';

// import { CustomizeProductSummaryComponent } from './customize-product-summary/customize-product-summary.component';
// import { RadioCheckboxSelectorComponent } from './radio-checkbox-selector/radio-checkbox-selector.component';
// import { OptionGroupStatusComponent } from './option-group-status/option-group-status.component';
// import { OptionGroupInfoComponent } from './option-group-info/option-group-info.component';
// import { OptionCardSelectorComponent } from './option-card-selector/option-card-selector.component';
// import { ButtonGroupSelectorComponent } from './button-group-selector/button-group-selector.component';
// import { DropdownSelectorComponent } from './dropdown-selector/dropdown-selector.component';
// import { ChildOptionSelectorComponent } from './child-option-selector/child-option-selector.component';
// import { HalfAndHalfSelectorComponent } from './half-and-half-selector/half-and-half-selector.component';
// import { OptionCardComponent } from './option-card/option-card.component';
// import { OptionSelectorComponent } from './option-selector/option-selector.component';

// tslint:disable-next-line:max-line-length
export const components = [KioskCardComponent, ProductCardComponent, CategoryScrollerComponent, MenuBlockComponent, CategoryJumpNavComponent, CategoryBlockComponent, IngredientCardComponent, CustomizeProductBlockComponent, ModifyFooterComponent, OrderControlsComponent, MenuLimitToggleComponent, LocationInfoDisplayComponent, MenuBreadcrumbsComponent];

export * from './kiosk-card/kiosk-card.component';
export * from './product-card/product-card.component';
export * from './category-scroller/category-scroller.component';
export * from './menu-block/menu-block.component';
export * from './category-jump-nav/category-jump-nav.component';
export * from './category-block/category-block.component';
export * from './ingredient-card/ingredient-card.component';
export * from './customize-product-block/customize-product-block.component';
export * from './modify-footer/modify-footer.component';
export * from './order-controls/order-controls.component';
export * from './menu-limit-toggle/menu-limit-toggle.component';
// export * from './half-and-half-selector/half-and-half-selector.component';
// export * from './option-card/option-card.component';
// export * from './option-selector/option-selector.component';
// export * from './child-option-selector/child-option-selector.component';
// export * from './dropdown-selector/dropdown-selector.component';
// export * from './button-group-selector/button-group-selector.component';
// export * from './radio-checkbox-selector/radio-checkbox-selector.component';
// export * from './option-group-status/option-group-status.component';
// export * from './option-group-info/option-group-info.component';
// export * from './option-card-selector/option-card-selector.component';
// export * from './customize-product-summary/customize-product-summary.component';
export * from './radio-button-checkbox-card/radio-button-checkbox-card.component';
export * from './location-info-display/location-info-display.component';
export * from './menu-breadcrumbs/menu-breadcrumbs.component';
