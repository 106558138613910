<ng-container [ngSwitch]="displayMode">
    <ng-container *ngSwitchCase="'classic'">
        <div
                [@fadeInOut]
                class="card"
                id="expiredCard"
                [class.expired-card]="isExpired"
                [class.default-card]="card.isDefault == true"
                *ngIf="!isLoyalty; else loyalty">
            <div class="row pe-2 ps-4">
                <div class="col-9">
                    <div class="row text-uppercase f-10">Card Number</div>
                    <div class="row">
            <span class="card-number"
            >**** **** **** {{ card.lastFourDigits }}</span
            >
                    </div>
                </div>

                <div class="col-3 text-end bookmark-icon">
                    <ng-container *ngIf="!card.isGiftCard">
                        <a tabindex="0" type="button"
                           (click)="defaultCardClicked()"
                           (keydown.enter)="defaultCardClicked()">
                            <i *ngIf="!card.isDefault" class="bi bi-heart"></i>
                            <i *ngIf="card.isDefault" class="bi bi-heart-fill"></i>
                        </a>
                    </ng-container>
                </div>
            </div>

            <div class="row p-2">
                <div class="col-6 card-exp">
                    <ng-container *ngIf="card.isGiftCard; else ccExp">
                        <ng-container *ngIf="card.balanceCents">
                            <div class="text-uppercase f-10">Balance</div>
                            <span class="card-number exp-date">{{
                                card.balanceCents / 100 | currency
                                }}</span>
                        </ng-container>
                    </ng-container>
                    <ng-template #ccExp>
                        <div class="text-uppercase f-10">Exp Date</div>
                        <span class="card-number exp-date">{{
                            card.cardExpiration | date: 'MM/YY'
                            }}</span>
                    </ng-template>
                </div>
                <div class="col-6 card-branding">
                    <ng-container *ngIf="card.isGiftCard; else ccType">
                        Gift Card
                    </ng-container>
                    <ng-template #ccType>
                        <ng-container *ngIf="card?.type.includes('Visa')">
                            <img src="../../../../assets/icons/cc-visa.svg" alt="Visa"/>
                        </ng-container>
                        <ng-container *ngIf="card?.type.includes('Master Card')">
                            <img
                                    src="../../../../assets/icons/cc-mastercard.svg"
                                    alt="Master Card"/>
                        </ng-container>
                        <ng-container *ngIf="card?.type.includes('Discover')">
                            <img
                                    src="../../../../assets/icons/cc-discover.svg"
                                    alt="Discover"/>
                        </ng-container>
                        <ng-container
                                *ngIf="card?.type.includes('Amex' || 'American Express')">
                            <img
                                    src="../../../../assets/icons/cc-amex.svg"
                                    alt="American Express"/>
                        </ng-container>
                        <ng-container
                                *ngIf="
                card.type &&
                card?.type.includes(
                  !'Amex' ||
                    !'American Express' ||
                    !'Visa' ||
                    !'Discover' ||
                    !'Master Card'
                )
              ">
                            {{ card.type }}
                        </ng-container>
                    </ng-template>
                </div>
            </div>

            <button
                    type="button"
                    tabindex="0"
                    class="de-secondary-btn delete-btn"
                    (click)="deleteCardClicked()"
                    (keydown.enter)="deleteCardClicked()"
                    *ngIf="card.isRemovable">
                Delete Card
            </button>
        </div>
        <ng-template #loyalty>
            <div
                    class="payment-card card card-loyalty de-border h-100 justify-content-between">
                <div class="row pe-2 ps-4">
                    <div class="col-9">
                        <div class="row text-uppercase f-10"></div>
                        <div class="row">
                            <span class="card-number">Loyalty Balance</span>
                        </div>
                    </div>
                </div>

                <div class="row p-2">
                    <div class="col-6 card-exp">
                        <div class="text-uppercase f-10">Balance</div>
                        <span class="card-number exp-date">{{
                            storedValueCents / 100 | currency
                            }}</span>
                    </div>
                    <div class="col-6 card-branding"></div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'improved'">
        <div
                class="payment-card"
                [class.visa]="card.type.toLowerCase().includes('visa')"
                [class.mastercard]="card.type.toLowerCase().includes('master')"
                [class.discover]="card.type.toLowerCase().includes('discover')"
                [class.amex]="card.type.toLowerCase().includes('ame')"
                *ngIf="!card.isGiftCard; else giftCard"
                [class.disabled]="!card.isDefault">
            <div class="card-settings">
                <button tabindex="0" type="button" class="delete-card" (click)="deleteCardClicked()" (keydown.enter)="deleteCardClicked()">
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16">
                        <path
                                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
                </button>

                <div class="default-toggle">
                    <input
                            type="checkbox"
                            id="switch{{ index }}"
                            [checked]="card.isDefault"/>
                    <label tabindex="0" for="switch{{ index }}" (click)="defaultCardClicked()" (keydown.enter)="defaultCardClicked()">
                        <div class="selected">Default</div>

                        <div class="unselected">Select</div>
                    </label>
                </div>
            </div>

            <div class="card-info">
                <div class="card-number">XXXX XXXX XXXX {{ card.lastFourDigits }}</div>

                <small class="card-exp">
                    Valid Thru <b>{{ card.cardExpiration | date: 'M/YY' }}</b>
                </small>

                <div class="card-user-type">
                    <!--        <div class="card-user">-->
                    <!--          <small class="card-name-title">-->
                    <!--            Card Holder Name-->
                    <!--          </small>-->

                    <!--          <div class="card-name">-->
                    <!--            Bartholomew James III-->
                    <!--          </div>-->
                    <!--        </div>-->

                    <div class="card-type">
                        <div class="mastercard-icon">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none">
                                <path
                                        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                                        fill="white"/>
                                <path
                                        d="M38.3789 24.1156C38.3789 29.0386 34.3956 33.0294 29.482 33.0294C24.5684 33.0294 20.5851 29.0386 20.5851 24.1156C20.5851 19.1927 24.5684 15.2019 29.482 15.2019C34.3956 15.2019 38.3789 19.1927 38.3789 24.1156Z"
                                        fill="#F79F1A"/>
                                <path
                                        d="M27.4032 24.1156C27.4032 29.0386 23.42 33.0294 18.5063 33.0294C13.5927 33.0294 9.60944 29.0386 9.60944 24.1156C9.60944 19.1927 13.5927 15.2019 18.5063 15.2019C23.42 15.2019 27.4032 19.1927 27.4032 24.1156Z"
                                        fill="#EA001B"/>
                                <path
                                        d="M23.9942 17.0986C21.9183 18.7304 20.5858 21.2662 20.5858 24.1148C20.5858 26.9633 21.9183 29.501 23.9942 31.1328C26.07 29.501 27.4026 26.9633 27.4026 24.1148C27.4026 21.2662 26.07 18.7304 23.9942 17.0986Z"
                                        fill="#FF5F01"/>
                            </svg>
                        </div>

                        <div class="visa-icon">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none">
                                <path
                                        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                                        fill="white"/>
                                <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M24.5053 23.0741C24.4889 24.3775 25.6575 25.1048 26.5379 25.5373C27.4424 25.981 27.7462 26.2655 27.7427 26.6622C27.7358 27.2695 27.0212 27.5375 26.3523 27.548C25.2169 27.5657 24.544 27.2483 24.0116 26.9971L23.9676 26.9763L23.5473 28.9592C24.0884 29.2106 25.0905 29.4299 26.1296 29.4395C28.5687 29.4395 30.1645 28.2257 30.1732 26.3438C30.1788 24.915 29.0049 24.2937 28.0655 23.7965C27.4346 23.4625 26.9095 23.1846 26.9185 22.7557C26.9263 22.432 27.2326 22.0866 27.9041 21.9987C28.2364 21.9544 29.1539 21.9204 30.1939 22.4033L30.6021 20.4849C30.0428 20.2795 29.3239 20.0829 28.4289 20.0829C26.1331 20.0829 24.5183 21.3131 24.5053 23.0741ZM34.5248 20.2482C34.0795 20.2482 33.704 20.5101 33.5366 20.9121L30.0523 29.2985H32.4897L32.9747 27.9473H35.9532L36.2346 29.2985H38.3828L36.5082 20.2482H34.5248ZM35.5691 26.0915L34.8657 22.6931L33.6427 26.0915H35.5691ZM21.5501 20.2482L19.6289 29.2985H21.9515L23.8718 20.2482H21.5501ZM15.6967 26.4082L18.1142 20.2482H20.5533L16.7902 29.2985H14.3356L12.4835 22.0762C12.3713 21.6316 12.2737 21.468 11.932 21.281C11.3736 20.9747 10.4518 20.6884 9.64049 20.511L9.69572 20.2482H13.6478C14.1509 20.2482 14.6041 20.5858 14.7188 21.1705L15.6967 26.4082Z"
                                        fill="url(#paint0_linear)"/>
                                <defs>
                                    <linearGradient
                                            id="paint0_linear"
                                            x1="9.69058"
                                            y1="22.9607"
                                            x2="17.8148"
                                            y2="11.5188"
                                            gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#222357"/>
                                        <stop offset="1" stop-color="#254AA5"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>

                        <div class="amex-icon">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 49 49"
                                    fill="none">
                                <g clip-path="url(#clip0_983_30574)">
                                    <path
                                            d="M0.385742 0.308594H48.2779V26.2223L45.909 29.9322L48.2779 33.2371V48.3086H0.385742V23.885L1.86936 22.1754L0.385742 20.543V0.308594Z"
                                            fill="white"/>
                                    <path
                                            d="M9.68213 33.759V26.2217H17.6431L18.4975 27.3377L19.3796 26.2217H48.2778V33.2396C47.7886 33.5506 47.2276 33.7295 46.6492 33.759H30.6471L29.6847 32.5718V33.759H26.5293V31.7309C26.1078 31.9441 25.6373 32.0412 25.166 32.0122H24.0927V33.759H19.3272L18.4728 32.6182L17.6061 33.759H9.68213Z"
                                            fill="#016FD0"/>
                                    <path
                                            d="M0.385742 20.5431L2.18087 16.3478H5.28691L6.30479 18.6974V16.3478H10.1634L10.7803 18.0451L11.3694 16.3478H28.6947V17.2011C29.3719 16.6424 30.2236 16.3403 31.1006 16.3478L36.7235 16.3663L37.7229 18.685V16.3478H40.9523L41.8437 17.6803V16.3478H45.1009V23.8851H41.8437L40.9893 22.5464V23.8851H36.2362L35.7581 22.6948H34.4842L34.0123 23.8851H30.7952C30.0131 23.8757 29.2619 23.5774 28.6855 23.0473V23.8851H23.8337L22.8682 22.6948V23.8851H4.83041L4.35233 22.6948H3.08154L2.60653 23.8851H0.388811L0.385742 20.5431Z"
                                            fill="#016FD0"/>
                                    <path
                                            d="M2.8163 17.2744L0.39502 22.9197H1.97116L2.41841 21.7881H5.01551L5.44734 22.9197H7.06049L4.63921 17.2744H2.8163ZM3.71388 18.5883L4.5035 20.5639H2.91809L3.71388 18.5883Z"
                                            fill="white"/>
                                    <path
                                            d="M7.23975 22.9211V17.2759H9.47905L10.7838 20.9147L12.0546 17.2666H14.2754V22.9211H12.8689V18.7598L11.376 22.9211H10.1422L8.64625 18.7629V22.9211H7.23975Z"
                                            fill="white"/>
                                    <path
                                            d="M15.2407 22.9197V17.2744H19.8335V18.5358H16.6626V19.5189H19.7471V20.7061H16.6626V21.683H19.8335V22.9197H15.2407Z"
                                            fill="white"/>
                                    <path
                                            d="M20.647 17.2744V22.9197H22.0535V20.9132H22.6457L24.3329 22.9197H26.054L24.2033 20.839C24.63 20.7965 25.0252 20.595 25.3108 20.2744C25.5964 19.9538 25.7515 19.5375 25.7455 19.1077C25.7455 17.9175 24.8202 17.2744 23.7777 17.2744H20.647ZM22.0535 18.5389H23.6635C23.8295 18.5328 23.9914 18.5911 24.1155 18.7017C24.2396 18.8122 24.3164 18.9666 24.3298 19.1325C24.3187 19.2954 24.2457 19.4478 24.1257 19.5583C24.0057 19.6687 23.848 19.7288 23.6851 19.7261H22.0535V18.5389V18.5389Z"
                                            fill="white"/>
                                    <path
                                            d="M27.7565 22.9197H26.3223V17.2744H27.7565V22.9197Z"
                                            fill="white"/>
                                    <path
                                            d="M31.1658 22.9205H30.8573C29.3552 22.9205 28.4453 21.7364 28.4453 20.1226C28.4453 18.5088 29.346 17.2783 31.2398 17.2783H32.782V18.6139H31.1689C30.3978 18.6139 29.8549 19.2167 29.8549 20.138C29.8549 21.2294 30.4718 21.6838 31.3724 21.6838H31.7426L31.1658 22.9205Z"
                                            fill="white"/>
                                    <path
                                            d="M34.2284 17.2744L31.8071 22.9197H33.3833L33.8305 21.7881H36.4276L36.8718 22.9197H38.4849L36.0637 17.2744H34.2284ZM35.126 18.5883L35.9156 20.5639H34.3302L35.126 18.5883Z"
                                            fill="white"/>
                                    <path
                                            d="M38.6479 22.9197V17.2744H40.4369L42.7225 20.8205V17.2744H44.129V22.9197H42.3986L40.0545 19.2839V22.9197H38.6479Z"
                                            fill="white"/>
                                    <path
                                            d="M10.6475 32.7947V27.1494H15.2402V28.4139H12.0694V29.3785H15.1538V30.5656H12.0694V31.5766H15.2402V32.8132L10.6475 32.7947Z"
                                            fill="white"/>
                                    <path
                                            d="M33.1519 32.7947V27.1494H37.7446V28.4139H34.5738V29.3785H37.6582V30.5656H34.5738V31.5766H37.7446V32.8132L33.1519 32.7947Z"
                                            fill="white"/>
                                    <path
                                            d="M15.4162 32.7943L17.6524 30.0119L15.3638 27.1553H17.1373L18.5006 28.9206L19.8701 27.1553H21.5728L19.3273 29.9717L21.5666 32.7943H19.793L18.4698 31.0538L17.1682 32.7943H15.4162Z"
                                            fill="white"/>
                                    <path
                                            d="M21.7212 27.1523V32.7945H23.1647V31.0106H24.6483C25.9006 31.0106 26.8506 30.3459 26.8506 29.0506C26.8506 27.9747 26.1073 27.1523 24.8303 27.1523H21.7212ZM23.1647 28.4261H24.7254C24.815 28.4177 24.9053 28.4285 24.9904 28.4578C25.0754 28.487 25.1533 28.534 25.2189 28.5956C25.2845 28.6573 25.3363 28.7322 25.3709 28.8155C25.4055 28.8987 25.422 28.9883 25.4194 29.0784C25.4197 29.168 25.4014 29.2567 25.3659 29.3388C25.3303 29.421 25.2781 29.4949 25.2126 29.5559C25.1471 29.6169 25.0698 29.6636 24.9854 29.6932C24.901 29.7227 24.8115 29.7345 24.7223 29.7276H23.1801V28.4261H23.1647Z"
                                            fill="white"/>
                                    <path
                                            d="M27.4609 27.1494V32.7947H28.8705V30.7882H29.4627L31.1499 32.7947H32.871L31.0204 30.714C31.4473 30.6707 31.8426 30.4686 32.1281 30.1475C32.4136 29.8264 32.5686 29.4097 32.5626 28.9796C32.5626 27.7925 31.6373 27.1494 30.5947 27.1494H27.4609ZM28.8705 28.4139H30.4775C30.6435 28.4078 30.8054 28.4661 30.9295 28.5767C31.0536 28.6872 31.1304 28.8416 31.1438 29.0075C31.1327 29.1704 31.0597 29.3228 30.9397 29.4333C30.8197 29.5437 30.662 29.6038 30.4991 29.601H28.8705V28.4139Z"
                                            fill="white"/>
                                    <path
                                            d="M38.3956 32.7951V31.5585H41.2271C41.6435 31.5585 41.8224 31.3328 41.8224 31.0855C41.8224 30.8382 41.6435 30.6094 41.2271 30.6094H39.9532C38.8459 30.6094 38.229 29.9323 38.229 28.9183C38.229 28.0155 38.7934 27.1406 40.4375 27.1406H43.1795L42.5873 28.4113H40.2154C39.762 28.4113 39.6232 28.6493 39.6232 28.8781C39.6231 28.9451 39.6366 29.0113 39.663 29.0729C39.6893 29.1344 39.728 29.1899 39.7765 29.2359C39.825 29.2819 39.8824 29.3175 39.9451 29.3405C40.0079 29.3635 40.0746 29.3734 40.1413 29.3697H41.4769C42.7107 29.3697 43.2443 30.0715 43.2443 30.9897C43.2443 31.9759 42.649 32.7859 41.3936 32.7859L38.3956 32.7951Z"
                                            fill="white"/>
                                    <path
                                            d="M43.5617 32.7951V31.5585H46.3778C46.7942 31.5585 46.9762 31.3328 46.9762 31.0855C46.9762 30.8382 46.7973 30.6094 46.3778 30.6094H45.107C43.9997 30.6094 43.3828 29.9323 43.3828 28.9183C43.3828 28.0155 43.9473 27.1406 45.5913 27.1406H48.321L47.7288 28.4113H45.3569C44.9034 28.4113 44.7646 28.6493 44.7646 28.8781C44.7645 28.9451 44.7781 29.0113 44.8045 29.0729C44.8308 29.1344 44.8694 29.1899 44.918 29.2359C44.9665 29.2819 45.0239 29.3175 45.0866 29.3405C45.1494 29.3635 45.2161 29.3734 45.2828 29.3697H46.6153C47.8491 29.3697 48.3858 30.0715 48.3858 30.9897C48.3858 31.9759 47.7874 32.7859 46.5351 32.7859L43.5617 32.7951Z"
                                            fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_983_30574">
                                        <rect
                                                x="0.385742"
                                                y="0.308594"
                                                width="48"
                                                height="48"
                                                rx="24"
                                                fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div class="discover-icon">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    fill="none"
                                    height="48"
                                    viewBox="0 0 100 100"
                                    width="48">
                                <linearGradient
                                        id="a"
                                        gradientUnits="userSpaceOnUse"
                                        x1="55.6796"
                                        x2="51.2186"
                                        y1="54.7148"
                                        y2="47.5731">
                                    <stop offset="0" stop-color="#f89f20"/>
                                    <stop offset=".25" stop-color="#f79a20"/>
                                    <stop offset=".53" stop-color="#f68d20"/>
                                    <stop offset=".62" stop-color="#f58720"/>
                                    <stop offset=".72" stop-color="#f48120"/>
                                    <stop offset="1" stop-color="#f37521"/>
                                </linearGradient>
                                <linearGradient
                                        id="b"
                                        gradientUnits="userSpaceOnUse"
                                        x1="54.9524"
                                        x2="48.4551"
                                        y1="54.6195"
                                        y2="41.6369">
                                    <stop offset="0" stop-color="#f58720"/>
                                    <stop offset=".36" stop-color="#e16f27"/>
                                    <stop offset=".7" stop-color="#d4602c"/>
                                    <stop offset=".98" stop-color="#d05b2e"/>
                                </linearGradient>
                                <clipPath id="c">
                                    <path d="m11 43h79v13h-79z"/>
                                </clipPath>
                                <rect fill="#fff" height="100" rx="50" width="100"/>
                                <g clip-path="url(#c)">
                                    <g fill="#201d1c">
                                        <path
                                                d="m14.5712 43.3389h-3.5712v12.2378h3.5712c1.883 0 3.2683-.4447 4.4587-1.4186 1.4285-1.1645 2.2726-2.9007 2.2726-4.7003-.0217-3.5994-2.7704-6.1189-6.7313-6.1189zm2.857 9.1889c-.7575.6775-1.7531.9739-3.3331.9739h-.6493v-8.0879h.6493c1.58 0 2.5323.2752 3.3331.9951.8441.741 1.3419 1.8632 1.3419 3.0489 0 1.1645-.4978 2.329-1.3419 3.07z"/>
                                        <path d="m22.4062 43.3389h2.4458v12.2378h-2.4458z"/>
                                        <path
                                                d="m30.8041 48.0393c-1.4718-.5293-1.883-.8892-1.883-1.5456 0-.7622.7575-1.355 1.8181-1.355.7358 0 1.3202.2964 1.9695.9951l1.277-1.6303c-1.0389-.8893-2.2942-1.3551-3.6794-1.3551-2.2077 0-3.8959 1.5033-3.8959 3.4935 0 1.6938.7792 2.5407 3.0734 3.3453.9523.3388 1.4501.5505 1.6882.6987.4978.3176.7359.7622.7359 1.2704 0 .9951-.8008 1.7149-1.883 1.7149-1.1688 0-2.0994-.5716-2.6622-1.6303l-1.58 1.4821c1.1255 1.6092 2.4674 2.329 4.3288 2.329 2.5323 0 4.3071-1.6514 4.3071-4.0228 0-1.9267-.8225-2.7948-3.6145-3.7899z"/>
                                        <path
                                                d="m35.1763 49.4573c0 3.5993 2.8786 6.373 6.6013 6.373 1.0389 0 1.948-.2118 3.0518-.7199v-2.816c-.974.9528-1.8397 1.3339-2.9435 1.3339-2.4458 0-4.1989-1.7361-4.1989-4.2133s1.7964-4.1922 4.0906-4.1922c1.1688 0 2.0346.4023 3.0518 1.3762v-2.816c-1.0605-.5293-1.9479-.741-3.0085-.741-3.6794.0212-6.6446 2.8583-6.6446 6.4153z"/>
                                        <path
                                                d="m64.1575 51.5539-3.3332-8.215h-2.6622l5.3244 12.5553h1.2986l5.411-12.5553h-2.6406z"/>
                                        <path
                                                d="m71.2998 55.5767h6.9044v-2.075h-4.4803v-3.3029h4.3071v-2.0749h-4.3071v-2.7101h4.4803v-2.0749h-6.9044z"/>
                                        <path
                                                d="m87.8575 46.9594c0-2.2867-1.6016-3.6205-4.4153-3.6205h-3.6146v12.2378h2.4458v-4.9121h.3246l3.3765 4.9121h3.0085l-3.9392-5.145c1.7964-.3811 2.8137-1.6091 2.8137-3.4723zm-4.8915 2.0114h-.7143v-3.7052h.7576c1.5151 0 2.3375.614 2.3375 1.8208 0 1.2069-.8225 1.8844-2.3808 1.8844z"/>
                                    </g>
                                    <path
                                            d="m52.275 56c3.6698 0 6.6447-2.9101 6.6447-6.5s-2.9749-6.5-6.6447-6.5c-3.6697 0-6.6446 2.9101-6.6446 6.5s2.9749 6.5 6.6446 6.5z"
                                            fill="url(#a)"/>
                                    <path
                                            d="m52.275 56c3.6698 0 6.6447-2.9101 6.6447-6.5s-2.9749-6.5-6.6447-6.5c-3.6697 0-6.6446 2.9101-6.6446 6.5s2.9749 6.5 6.6446 6.5z"
                                            fill="url(#b)"
                                            opacity=".65"/>
                                    <path
                                            d="m89.4157 43.8046c0-.2117-.1515-.3388-.4112-.3388h-.3463v1.0798h.2597v-.4234l.303.4234h.3247l-.3679-.4446c.1515-.0423.238-.1482.238-.2964zm-.4545.1482h-.0433v-.2753h.0433c.1299 0 .1948.0424.1948.1483 0 .0846-.0649.127-.1948.127z"
                                            fill="#fff"/>
                                    <path
                                            d="m89.0475 43.0635c-.5411 0-.9523.4234-.9523.9316 0 .5081.4329.9316.9523.9316.5195 0 .9524-.4235.9524-.9316 0-.5082-.4329-.9316-.9524-.9316zm0 1.715c-.4112 0-.7575-.3388-.7575-.7623 0-.4234.3463-.7622.7575-.7622.4113 0 .7576.36.7576.7622 0 .4235-.3463.7623-.7576.7623z"
                                            fill="#fff"/>
                                </g>
                            </svg>
                        </div>

                        <div class="gift-card-icon">
                            <!--          pull brand logo from CMS-->
                            <ng-container *ngIf="branding$ | async as branding">
                                <img
                                        class="gift-card-image"
                                        [src]="branding.main_logo.data.full_url"
                                        alt="Gift Card"/>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #giftCard>
            <ng-container *ngIf="theme$ | async as theme">
                <div
                        class="payment-card gift-card"
                        [style.background]="theme[0] | radialGradient"
                        [class.disabled]="!card.isDefault">
                    <div class="card-settings">
                        <button
                                tabindex="0"
                                type="button"
                                class="delete-card"
                                (click)="deleteCardClicked()"
                                (keydown.enter)="deleteCardClicked()">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    class="bi bi-trash3"
                                    viewBox="0 0 16 16">
                                <path
                                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                        </button>
                    </div>

                    <div class="card-info">
                        <div class="gift-card-title">Gift Card</div>

                        <small class="card-number">
                            XXXX XXXX XXXX {{ card.lastFourDigits }}
                        </small>

                        <div class="card-user-type">
                            <div class="card-user">
                                <small class="card-name-title"> Balance </small>

                                <div class="card-name">
                                    {{ card.balanceCents / 100 | currency }}
                                </div>
                            </div>

                            <div class="card-type">
                                <div class="gift-card-icon">
                                    <!--          pull brand logo from CMS-->
                                    <ng-container *ngIf="branding$ | async as branding">
                                        <img
                                                class="gift-card-image"
                                                [src]="branding.main_logo.data.full_url"
                                                alt="Gift Card"/>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>
