import { Pipe, PipeTransform } from '@angular/core';
import {PurchaseableReward} from '../interfaces/purchaseable-reward.interface';
import {produce} from 'immer';

@Pipe({
  name: 'purchaseableRewardsSort'
})
export class PurchaseableRewardsSortPipe implements PipeTransform {

  transform(rewards: PurchaseableReward[]): PurchaseableReward[] {
    return produce(rewards, draft => draft.sort((a, b) => a.pointCost - b.pointCost));
  }

}
