export interface BalanceTransferServiceError {
  result: 'failure';
  errorCode: BalanceTransferErrorCode;
  errorMessage: string;
}

export enum BalanceTransferErrorCode {
  SYSTEM_ERROR = 'balancetransfer.error',
  INVALID_FROM_CARD = 'balancetransfer.invalid_from_card',
  INVALID_TO_CARD = 'balancetransfer.invalid_to_card',
  INACTIVE_FROM_ACCOUNT = 'balancetransfer.inactive_from_account',
  INACTIVE_FROM_CARD = 'balancetransfer.inactive_from_card',
  REGISTERED_FROM_CARD = 'balancetransfer.registered_from_card',
  INACTIVE_TO_ACCOUNT = 'balancetransfer.inactive_to_account',
  INACTIVE_TO_CARD = 'balancetransfer.inactive_to_card',
  NO_TRANSFERABLE_BALANCES = 'balancetransfer.no_transferable_balances',
  ALL_BALANCES_NOT_TRANSFERABLE = 'balancetransfer.all_balances_not_transferable'
}
