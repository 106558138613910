<ng-container [ngSwitch]="role">
  <ng-container *ngSwitchCase="roles.member">
    <ng-container *ngIf="(user$ | async) as user">
      <ng-container *ngIf="!user.isGuest">
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="roles.guest">
    <ng-container *ngIf="(user$ | async) as user">
      <ng-container *ngIf="user.isGuest">
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="roles.all">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-content></ng-content>
  </ng-container>
</ng-container>
