import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoyaltySection } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { SetUserSavedAddress } from '../../../../store/actions/location.actions';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { OrderTypeService } from '@modules/cart/services/order-type.service';

@Component({
  selector: 'app-saved-addresses-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './saved-addresses-section.component.html',
  styleUrls: ['saved-addresses-section.component.scss'],
})
export class SavedAddressesSectionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.location.userSavedAddress) userSavedAddresses$: Observable<ISavedAddress[]>;

  @Input() section: LoyaltySection;

  constructor(
    private store: Store,
    private userLocationsService: UserLocationService,
    private orderTypeService: OrderTypeService
  ) {}
  ngOnInit() {
    this.store.dispatch(new SetUserSavedAddress(this.userLocationsService.getSavedAddresses()));
  }

  deleteLocation(address: ISavedAddress) {
    this.userLocationsService.deleteAddress(address);
    this.store.dispatch(new SetUserSavedAddress(this.userLocationsService.getSavedAddresses()));
  }

  favoriteLocation(address: ISavedAddress) {
    this.userLocationsService.favoriteAddress(address);
    this.store.dispatch(new SetUserSavedAddress(this.userLocationsService.getSavedAddresses()));
  }

  unfavoriteLocation() {
    this.userLocationsService.unfavoriteAddress();
    this.store.dispatch(new SetUserSavedAddress(this.userLocationsService.getSavedAddresses()));
  }

  orderNowClicked() {
    this.orderTypeService.setNewOrderType(false);
  }
}
