<section
  class="footer-shell d-flex align-items-center"
  *ngIf="mainSettings$ | async as mainSettings">
  <div
    [class.container]="navbarSettings?.fixed_navbar_width"
    [class.px-0]="navbarSettings?.fixed_navbar_width"
    [class.px-3]="!navbarSettings?.fixed_navbar_width"
    class="flex-grow-1">
    <div class="d-flex flex-row flex-nowrap align-items-center w-100">
      <div class="d-none d-lg-flex grow-1 basis-0"></div>
      <div
        class="d-flex grow-1 basis-0 align-items-center justify-content-lg-center justify-content-start">
        <a
          class="d-flex"
          target="_blank"
          [href]="
            mainSettings.powered_by_link
              ? mainSettings.powered_by_link
              : 'https://dineengine.com/'
          ">
          <img
            [ngClass]="
              mainSettings.powered_by_logo ? 'footer-logo' : 'de-footer-logo'
            "
            [src]="
              mainSettings.powered_by_logo
                ? mainSettings.powered_by_logo.data.full_url
                : '../../../../assets/images/DineEngine_Horizontal_LightBack_Red_Logo_3.svg'
            "
            alt="Powered by {{
              mainSettings.powered_by_name
                ? mainSettings.powered_by_name
                : 'DineEngine'
            }}" />
        </a>
      </div>

      <div
        class="d-flex flex-column flex-lg-row justify-content-end align-items-end align-items-lg-center flex-nowrap grow-1 basis-0 font-size-small">
        <div class="w-100 d-flex justify-content-end text-dark">
          Version: {{ version }}
        </div>
        <ng-container *ngIf="showReportBug">
          <a class="de-footer-link ps-2" (click)="openFeedbackForm()">
            Report a Bug
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</section>
