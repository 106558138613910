<ng-container *ngIf="mobile.isMobile; else desktopNutritionCard">
  <ion-card padding>
    <ion-grid>
      <ion-row>
        <h2>Nutrition Facts</h2>
      </ion-row>
      <ion-row>
        <p>Serving Size {{ data.serving_size }}</p>
      </ion-row>
      <ion-row> </ion-row>
      <ion-row id="amountPerServing">
        Amount per Serving
      </ion-row>
      <ion-row class="nutrition-item" *ngIf="data.protein">
        <ion-col size="7" no-padding>Calories</ion-col>
        <ion-col size="3" no-padding>{{ data.calories }}</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.protein / 50) * 100 | number: '1.0-0' }}%</ion-col
        >
      </ion-row>
      <ion-row class="nutrition-item" *ngIf="data.grams_of_fat">
        <ion-col size="7" no-padding>Total Fats</ion-col>
        <ion-col size="3" no-padding>{{ data.grams_of_fat }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.grams_of_fat / 65) * 100 | number: '1.0-0' }}%</ion-col
        >
      </ion-row>
      <ion-row class="nutrition-item" *ngIf="data.saturated_fats">
        <ion-col size="7" no-padding>Saturated Fats</ion-col>
        <ion-col size="3" no-padding>{{ data.saturated_fats }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.saturated_fats / 20) * 100 | number: '1.0-0' }}%</ion-col
        > </ion-row
      ><ion-row class="nutrition-item" *ngIf="data.trans_fats">
        <ion-col size="7" no-padding>Trans Fats</ion-col>
        <ion-col size="3" no-padding>{{ data.trans_fats }}g</ion-col>
        <ion-col size="2" no-padding></ion-col> </ion-row
      ><ion-row class="nutrition-item" *ngIf="data.cholesterol">
        <ion-col size="7" no-padding>Cholesterol</ion-col>
        <ion-col size="3" no-padding>{{ data.cholesterol }}mg</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.cholesterol / 300) * 100 | number: '1.0-0' }}%</ion-col
        >
      </ion-row>
      <ion-row class="nutrition-item" *ngIf="data.sodium">
        <ion-col size="7" no-padding>Sodium</ion-col>
        <ion-col size="3" no-padding>{{ data.sodium }}mg</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.sodium / 2400) * 100 | number: '1.0-0' }}%</ion-col
        >
      </ion-row>
      <ion-row class="nutrition-item" *ngIf="data.carbohydrates">
        <ion-col size="7" no-padding>Carbohydrate</ion-col>
        <ion-col size="3" no-padding>{{ data.carbohydrates }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.carbohydrates / 300) * 100 | number: '1.0-0' }}%</ion-col
        > </ion-row
      ><ion-row class="nutrition-item" *ngIf="data.dietary_fiber">
        <ion-col size="7" no-padding>Dietary Fiber</ion-col>
        <ion-col size="3" no-padding>{{ data.dietary_fiber }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.dietary_fiber / 25) * 100 | number: '1.0-0' }}%</ion-col
        > </ion-row
      ><ion-row class="nutrition-item" *ngIf="data.sugars">
        <ion-col size="7" no-padding>Sugars</ion-col>
        <ion-col size="3" no-padding>{{ data.sugars }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.sugars / 25) * 100 | number: '1.0-0' }}%</ion-col
        > </ion-row
      ><ion-row class="nutrition-item" *ngIf="data.protein">
        <ion-col size="7" no-padding>Protein</ion-col>
        <ion-col size="3" no-padding>{{ data.protein }}g</ion-col>
        <ion-col size="2" no-padding
          >{{ (data.protein / 50) * 100 | number: '1.0-0' }}%</ion-col
        >
      </ion-row>
      <ion-row class="border-top">
        <p>
          *Percentage Daily Values are based on a 2,000 calorie diet. Your daily
          values may be higher or lower depending on your calorie needs.
        </p>
      </ion-row>
    </ion-grid>
  </ion-card>
</ng-container>


<ng-template #desktopNutritionCard>
  <ion-grid style="padding: 0 80px;">
    <ion-row>
      <h2>Nutrition Facts</h2>
    </ion-row>
    <ion-row>
      <p>Serving Size {{ data.serving_size }}</p>
    </ion-row>
    <ion-row> </ion-row>
    <ion-row id="amountPerServing">
      Amount per Serving
    </ion-row>
    <ion-row class="nutrition-item" *ngIf="data.protein">
      <ion-col size="7" no-padding>Calories</ion-col>
      <ion-col size="3" no-padding>{{ data.calories }}</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.protein / 50) * 100 | number: '1.0-0' }}%</ion-col
      >
    </ion-row>
    <ion-row class="nutrition-item" *ngIf="data.grams_of_fat">
      <ion-col size="7" no-padding>Total Fats</ion-col>
      <ion-col size="3" no-padding>{{ data.grams_of_fat }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.grams_of_fat / 65) * 100 | number: '1.0-0' }}%</ion-col
      >
    </ion-row>
    <ion-row class="nutrition-item" *ngIf="data.saturated_fats">
      <ion-col size="7" no-padding>Saturated Fats</ion-col>
      <ion-col size="3" no-padding>{{ data.saturated_fats }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.saturated_fats / 20) * 100 | number: '1.0-0' }}%</ion-col
      > </ion-row
    ><ion-row class="nutrition-item" *ngIf="data.trans_fats">
      <ion-col size="7" no-padding>Trans Fats</ion-col>
      <ion-col size="3" no-padding>{{ data.trans_fats }}g</ion-col>
      <ion-col size="2" no-padding></ion-col> </ion-row
    ><ion-row class="nutrition-item" *ngIf="data.cholesterol">
      <ion-col size="7" no-padding>Cholesterol</ion-col>
      <ion-col size="3" no-padding>{{ data.cholesterol }}mg</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.cholesterol / 300) * 100 | number: '1.0-0' }}%</ion-col
      >
    </ion-row>
    <ion-row class="nutrition-item" *ngIf="data.sodium">
      <ion-col size="7" no-padding>Sodium</ion-col>
      <ion-col size="3" no-padding>{{ data.sodium }}mg</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.sodium / 2400) * 100 | number: '1.0-0' }}%</ion-col
      >
    </ion-row>
    <ion-row class="nutrition-item" *ngIf="data.carbohydrates">
      <ion-col size="7" no-padding>Carbohydrate</ion-col>
      <ion-col size="3" no-padding>{{ data.carbohydrates }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.carbohydrates / 300) * 100 | number: '1.0-0' }}%</ion-col
      > </ion-row
    ><ion-row class="nutrition-item" *ngIf="data.dietary_fiber">
      <ion-col size="7" no-padding>Dietary Fiber</ion-col>
      <ion-col size="3" no-padding>{{ data.dietary_fiber }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.dietary_fiber / 25) * 100 | number: '1.0-0' }}%</ion-col
      > </ion-row
    ><ion-row class="nutrition-item" *ngIf="data.sugars">
      <ion-col size="7" no-padding>Sugars</ion-col>
      <ion-col size="3" no-padding>{{ data.sugars }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.sugars / 25) * 100 | number: '1.0-0' }}%</ion-col
      > </ion-row
    ><ion-row class="nutrition-item" *ngIf="data.protein">
      <ion-col size="7" no-padding>Protein</ion-col>
      <ion-col size="3" no-padding>{{ data.protein }}g</ion-col>
      <ion-col size="2" no-padding
        >{{ (data.protein / 50) * 100 | number: '1.0-0' }}%</ion-col
      >
    </ion-row>
    <ion-row class="border-top">
      <p>
        *Percentage Daily Values are based on a 2,000 calorie diet. Your daily
        values may be higher or lower depending on your calorie needs.
      </p>
    </ion-row>
  </ion-grid>
</ng-template>
