<div class="group-name-title">
  <h4 class="header-font">{{ groupOrder.groupOrderOwner }}'s Group Order</h4>
</div>

<div class="d-flex flex-column gap-2 p-3 border-bottom">
  <div class="d-flex flex-column gap-1">
    <div class="d-flex flex-row flex-nowrap gap-3">
      <ng-container *ngIf="modeService.mode !== 'tableside'">
        <div class="order-deadline-title header-font me-auto">
          Order Deadline:
        </div>
      </ng-container>

      <ng-container *ngIf="groupOrder.role === 'owner'">
        <button
          type="button"
          class="dark-link bg-transparent p-0"
          (click)="goToDetails()">
          {{
            modeService.mode !== 'tableside'
              ? 'Edit Group Details'
              : 'View Group Details'
          }}
        </button>
      </ng-container>
      <ng-container *ngIf="groupOrder.role === 'member'">
        <button
          type="button"
          class="dark-link bg-transparent p-0"
          (click)="leaveGroupOrder()">
          Leave Order
        </button>
      </ng-container>
    </div>

    <ng-container *ngIf="modeService.mode !== 'tableside'">
      <h4 class="text-danger fw-bold header-font">
        {{ groupOrder.groupOrderDeadline | date: 'shortTime' }}
      </h4>
    </ng-container>
  </div>

  <ng-container *ngIf="groupOrder.groupOrderNote">
    <div class="order-note">
      <div class="order-note-title header-font">Order Note:</div>

      <p>
        {{ groupOrder.groupOrderNote }}
      </p>
    </div>
  </ng-container>
</div>

<!--<div class="group-name-title header-font">-->
<!--  <h4>-->
<!--    {{ groupOrder.groupOrderOwner }}'s Order-->
<!--  </h4>-->
<!--</div>-->
