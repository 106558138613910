import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { from } from 'rxjs';
import { DeliveredNotifications, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Dialog } from '@capacitor/dialog';
import { MessagingService } from './vendor-config-service/messaging.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readNotificationsKey = 'readNotifications';

  private _readNotifications: string[] = localStorage.getItem(this.readNotificationsKey)
    ? JSON.parse(localStorage.getItem(this.readNotificationsKey))
    : [];
  private readNotificationsSubject = new BehaviorSubject<string[]>(this._readNotifications);
  readNotifications$ = this.readNotificationsSubject.asObservable();
  get readNotifications(): string[] {
    return this._readNotifications;
  }

  private _notifications: PushNotificationSchema[] = [];
  private notificationsSubject = new BehaviorSubject<PushNotificationSchema[]>(this._notifications);
  notifications$ = this.notificationsSubject.asObservable();

  private unreadNotificationsCountSubject = new BehaviorSubject<number>(0);
  unreadNotificationsCount$ = this.unreadNotificationsCountSubject.asObservable();

  get notifications(): PushNotificationSchema[] {
    return this._notifications;
  }

  constructor(
    private zone: NgZone,
    private messageService: MessagingService
  ) {
    this.messageService.getService().subscribe(service => {
      if (service) {
        this.updateNotifications().subscribe(() => {
          if (Capacitor.getPlatform() !== 'web') {
            PushNotifications.checkPermissions().then(permissionStatus => {
              if (permissionStatus.receive === 'granted') {
                LocalNotifications.addListener('localNotificationReceived', () => {
                  this.zone.run(() => this.updateNotifications().subscribe(() => {}));
                });
                PushNotifications.addListener('pushNotificationReceived', () => {
                  this.zone.run(() => this.updateNotifications().subscribe(() => {}));
                });
                PushNotifications.register();
                // setInterval(() => {
                //   this.zone.run(() => this.updateNotifications().subscribe(() => {}));
                // }, 1000)
                interval(1000)
                  .pipe(withLatestFrom(this.updateNotifications()))
                  .subscribe(_ => {});
                this.updateNotifications().subscribe(_ => {});
              }
            });
          }
        });
      }
    });
  }

  getUnreadNotificationCount(): number {
    if (Capacitor.getPlatform() === 'web') {
      return 0;
    }
    return this._notifications.filter(n => !this._readNotifications.includes(n.id)).length;
  }

  checkIfNotificationIsRead(notificationId: string): boolean {
    return this._readNotifications.includes(notificationId);
  }

  markDeviceNotificationAsRead(notificationId: string): void {
    if (this._readNotifications.includes(notificationId)) {
      return;
    }
    this._readNotifications.push(notificationId);
    this.readNotificationsSubject.next(this._readNotifications);
    localStorage.setItem(this.readNotificationsKey, JSON.stringify(this._readNotifications));
  }

  deleteDeviceNotification(notification: PushNotificationSchema): Observable<PushNotificationSchema[]> {
    if (Capacitor.getPlatform() !== 'web') {
      this._readNotifications = this._readNotifications.filter(n => n !== notification.id);
      this.readNotificationsSubject.next(this._readNotifications);
      localStorage.setItem(this.readNotificationsKey, JSON.stringify(this._readNotifications));
      return from(PushNotifications.removeDeliveredNotifications({ notifications: [notification] })).pipe(
        switchMap(() => this.updateNotifications()),
        tap(() => {
          setTimeout(() => {
            this.updateNotifications().subscribe(() => {});
          }, 1000);
        })
      );
    } else {
      return of([]);
    }
  }

  updateNotifications(): Observable<PushNotificationSchema[]> {
    if (Capacitor.getPlatform() !== 'web') {
      return from(PushNotifications.getDeliveredNotifications()).pipe(
        map(notifications => {
          if (notifications.notifications.length === 0) {
            PushNotifications.removeAllDeliveredNotifications();
          }
          if (!this._readNotifications.every(n => notifications.notifications.map(nn => nn.id).includes(n))) {
            this._readNotifications = this._readNotifications.filter(n => notifications.notifications.map(nn => nn.id).includes(n));
            this.readNotificationsSubject.next(this._readNotifications);
            localStorage.setItem(this.readNotificationsKey, JSON.stringify(this._readNotifications));
          }
          this._notifications = this.mapDeliveredNotifications(notifications);
          this.notificationsSubject.next(this._notifications);
          this.unreadNotificationsCountSubject.next(this.getUnreadNotificationCount());
          return this._notifications;
        })
      );
    } else {
      return of([]);
    }
  }

  private mapDeliveredNotifications(notifications: DeliveredNotifications): PushNotificationSchema[] {
    return notifications.notifications.map(n => {
      return {
        id: n.id,
        title: n.title ?? 'Notification',
        body: n.body,
        data: n.data,
      };
    });
  }
}
