<div id="subnavbar" class="de-shadow">
  <ul>
    <li class="subnavItem header-font" *ngFor="let navItem of (subnavItems | async)" (click)="setCurrent($event)">
      <script>
        console.log(subnavItems);
      </script>
      <ng-container *ngIf="navItem.anchor_or_link">
        <ng-container *ngIf="navItem.anchor_or_link[0] === '#'; else externalLink">
          <a class="content-font ensurePointerCursor" (click)="smoothScroll(navItem.anchor_or_link)">{{ navItem.anchor_tag }}</a>
        </ng-container>

        <ng-template #externalLink>
          <a class="content-font" [href]="navItem.anchor_or_link" target="_blank">{{ navItem.anchor_tag }}</a>
        </ng-template>
      </ng-container>
      <!-- <ng-container *ngIf="!navItem.anchor_or_link">{{ navItem.anchor_tag }}</ng-container> -->
    </li>
  </ul>
</div>
