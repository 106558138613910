import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ModeService} from '../../../services/mode.service';
import {NavigationService} from '../../navigation/services';
import {Select} from '@ngxs/store';
import {MainSettings} from '../../../vendors/directus/interfaces/main-settings.interface';

@Injectable()
export class HomePageGuard  {
  @Select(state => state.app.mainSettings) settings$: Observable<MainSettings>;

  constructor(
    private mode: ModeService,
    private navigation: NavigationService
  ) {}

  canActivate(): Observable<boolean> {
    return this.settings$.pipe(filter(s => s !== null), map(settings => {
      if (!settings.automatically_route_to_locations_page && this.mode.isWeb()) {
        this.navigation.navigateToHomePage();
        return false;
      } else {
        if (settings.loyalty_first_welcome_page) {
          this.navigation.navigateToProfilePage();
          return false;
        } else {
          return true;
        }
      }
    }));
  }
}
