import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { PunchhHttpService } from './punchh-http.service';
import { KeyValuePair } from '../novadine/interfaces/key-value-pair.interface';
import { switchMap, map, catchError } from 'rxjs/operators';
import { UserLogInResponse } from './interfaces/user-log-in-response.model';
import { AccountBalanceResponse } from './interfaces/account-balance-response.interface';
import { CreateUserRequest, UserRequest } from './interfaces/create-user-request.interace';
import { AvailableReward } from './interfaces/available-reward.interface';
import { RedemptionResponse } from './interfaces/redemption-response.interface';
import { UpdateUserInfoRequest } from './interfaces/update-user-info-request.interface';
import { AccountHistoryItem } from './interfaces/account-history-item.interface';
import { GetResetPasswordTokenResponse } from './interfaces/get-reset-password-token-response.model';
import { GetUserBalanceResponse } from './interfaces/get-user-balance-response.model';
import { Deals } from './interfaces/deals.model';
import { GetBalanceTimelinesResponse } from './interfaces/get-balance-timelines-response.model';
import { UtilityService } from '@modules/utility/services/utility.service';
import { environment as env } from 'src/environments/environment';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { MobileSignInWithAppleRequest, SignInWithAppleRequest } from './interfaces/sign-in-with-apple-request.interface';
import { SignInWithAppleResponse } from './interfaces/sign-in-with-apple-response.interface';
import { CreateAccessTokenForSingleSignOnFromSsoTokenResponse } from './interfaces/create-access-token-for-single-sign-on-from-sso-token-response.interface';
import { FetchUserBalanceResponse } from './interfaces/mobile-fetch-user-balance.interface';
import { DirectusService } from '../directus/directus.service';
import { GetMetaResponse } from './interfaces/get-meta-response.interface';

const userCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class PunchhAPIService {
  private oauthRedirUrl = '/profile?oauth=1';

  constructor(
    private punchhHttp: PunchhHttpService,
    private directus: DirectusService,
    private utils: UtilityService
  ) {}

  getMeta(): Observable<GetMetaResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const paramsStr = this.getQueryParamsString([{ key: 'client', value: clientID }]);
        const resource = `/meta.json`;
        return this.punchhHttp.get<any>(resource + paramsStr, true);
      })
    );
  }

  getCateringLinkConfig(): Observable<CateringLink> {
    return this.directus.getPunchhSettings().pipe(
      map(res => {
        return {
          enabled: res.enable_catering,
          link: res.catering_link,
          linkGuest: res.guest_catering_link,
          clientId: res.punchh_client_id,
        };
      })
    );
  }

  logIn(email: string, password: string): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: {
            email,
            password,
          },
          client: clientID,
        };
        const resource = `/customers/sign_in`;
        return this.punchhHttp.post<UserLogInResponse>(resource, body, false);
      })
    );
  }

  loginWithSSOToken(securityToken: string): Observable<CreateAccessTokenForSingleSignOnFromSsoTokenResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          client: clientID,
          security_token: securityToken,
        };
        const resource = '/auth/sso';
        return this.punchhHttp.post<CreateAccessTokenForSingleSignOnFromSsoTokenResponse>(resource, body, false);
      })
    );
  }

  mobileLogIn(email: string, password: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: {
            email,
            password,
          },
          client: clientID,
        };
        const resource = `/users/login`;
        return this.punchhHttp.post<UserLogInResponse>(resource, body, true).pipe(
          catchError(error => {
            return error.error.errors.base ? ({ message: error.error.errors.base[0] } as Error) : error;
          })
        );
      })
    );
  }

  isOauthEnabled(): Observable<boolean> {
    return this.directus.getPunchhSettings().pipe(
      map(res => {
        return res.enable_oauth;
      })
    );
  }

  redirectToOauthPage() {
    this.directus.getPunchhSettings().subscribe(res => {
      const client_id = res.punchh_client_id;
      const base_url = res.sandbox_mode ? env.punchhOauthPage : env.punchhOauthPageProd;
      const redir_url = this.utils.getMyHost() + this.oauthRedirUrl;
      const url =
        base_url +
        this.utils.queryStringify({
          client_id,
          redirect_uri: redir_url,
          response_type: 'code',
          force_logout: 'true',
          sso: '1',
        });
      this.utils.redirectTo(url);
    });
  }

  getOauthToken(code: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const redirUrl = this.utils.getMyHost() + this.oauthRedirUrl;
        const data = {
          grant_type: 'authorization_code',
          code,
          redirect_uri: redirUrl,
        };
        const resource = '/oauth/token';
        return this.punchhHttp.post<any>(resource, data, false);
      })
    );
  }

  getAccountBalance(authToken: string): Observable<AccountBalanceResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAuthParams(authToken, client));
        const resource = '/checkins/balance';
        return this.punchhHttp.get<AccountBalanceResponse>(resource + paramsStr);
      })
    );
  }

  mobileFetchUserBalance(accessToken: string): Observable<FetchUserBalanceResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAccessParams(accessToken, client));
        const resource = '/users/balance';
        return this.punchhHttp.get<FetchUserBalanceResponse>(resource + paramsStr);
      })
    );
  }

  createUser(user: UserRequest): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body: CreateUserRequest = {
          user,
          client: clientID,
        };
        const resource = `/customers.json`;
        return this.punchhHttp.post<UserLogInResponse>(resource, body, false);
      })
    );
  }

  getAvailableRewards(authToken: string): Observable<AvailableReward[]> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAuthParams(authToken, client));
        const resource = '/rewards';
        return this.punchhHttp.get<AvailableReward[]>(resource + paramsStr);
      })
    );
  }

  redeemReward(rewardID: string | number, authToken: string): Observable<RedemptionResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          reward_id: rewardID,
          client: clientID,
        };
        const paramsStr = this.getQueryParamsString([{ key: 'authentication_token', value: authToken }]);
        const resource = `/redemptions`;
        return this.punchhHttp.post<RedemptionResponse>(resource + paramsStr, body, false);
      })
    );
  }

  redeemPoints(pointsAmount: string | number, authToken: string): Observable<RedemptionResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          redeemed_points: pointsAmount,
          client: clientID,
        };
        const paramsStr = this.getQueryParamsString([{ key: 'authentication_token', value: authToken }]);
        const resource = `/redemptions`;
        return this.punchhHttp.post<RedemptionResponse>(resource + paramsStr, body, false);
      })
    );
  }

  voidPoints(redemptionID: string | number, authToken: string, isCode?: boolean): Observable<RedemptionResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          redemption_id: isCode ? null : redemptionID,
          authentication_token: authToken,
          client: clientID,
          redemption_code: isCode ? redemptionID : null,
        };
        const resource = `/redemptions`;
        return this.punchhHttp.delete<RedemptionResponse>(resource, false, null, body);
      })
    );
  }

  connectWithFacebook(email: string, fbToken: string, fbUserId: string): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          email,
          access_token: fbToken,
          client: clientID,
          fb_uid: fbUserId,
        };
        const resource = `/users/connect_with_facebook`;
        return this.punchhHttp.post<UserLogInResponse>(resource, body, false);
      })
    );
  }

  mobileConnectWithFacebook(fbToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          client: clientID,
          user: {
            facebook_access_token: fbToken,
          },
        };
        const resource = '/users/connect_with_facebook';
        return this.punchhHttp.post(resource, body, true);
      })
    );
  }

  signInWithApple(request: SignInWithAppleRequest): Observable<SignInWithAppleResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body: SignInWithAppleRequest = {
          ...request,
          client: clientID,
        };
        const resource = '/users/connect_with_apple';
        return this.punchhHttp.post<SignInWithAppleResponse>(resource, body, false);
      })
    );
  }

  mobileSignInWithApple(request: MobileSignInWithAppleRequest): Observable<SignInWithAppleResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const queryParams = this.getQueryParamsString([{ key: 'client', value: clientID }]);
        const body = {
          user: {
            ...request,
          },
        };
        const resource = '/apple_registrations' + queryParams;
        return this.punchhHttp.post<SignInWithAppleResponse>(resource, body, true);
      })
    );
  }

  sendForgotPasswordEmail(email: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: {
            email,
          },
          client: clientID,
        };
        const resource = `/users/forgot_password`;
        return this.punchhHttp.post<any>(resource, body, false);
      })
    );
  }

  getUserInfo(authToken: string): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAuthParams(authToken, client));
        const resource = '/users';
        return this.punchhHttp.get<UserLogInResponse>(resource + paramsStr);
      })
    );
  }

  getUserInfoByAccessToken(accessToken: string): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAccessParams(accessToken, client));
        const resource = '/users';
        return this.punchhHttp.get<UserLogInResponse>(resource + paramsStr);
      })
    );
  }

  updateUserInfo(authToken: string, updatedUserInfo: UpdateUserInfoRequest, sendComplianceSMS = false): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: updatedUserInfo,
          client: clientID,
          authentication_token: authToken,
          send_compliance_sms: sendComplianceSMS,
        };
        const resource = `/users`;
        return this.punchhHttp.put<UserLogInResponse>(resource, body);
      })
    );
  }

  createLoyaltyCheckin(storeNumber: number, accessToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          store_number: storeNumber,
          client: clientID,
          access_token: accessToken,
        };
        const resource = `/checkins`;
        return this.punchhHttp.post<any>(resource, body, false);
      })
    );
  }

  redeemPointsFromScanner(barCode: string, accessToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          bar_code: barCode,
          client: clientID,
          // location_id: String(122)
          // latitude: String(40.0764775),
          // longitude: String(-83.1268258)
        };
        const resource = `/checkins/barcode`;
        return this.punchhHttp.post<any>(resource, body, true, accessToken);
      })
    );
  }

  deleteLoyaltyCheckin(externalUID: string, authToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const authParams = this.getAuthParams(authToken, client);
        authParams.push({ key: 'external_uid', value: externalUID });
        const paramsStr = this.getQueryParamsString(authParams);
        const resource = '/checkins';
        return this.punchhHttp.delete<UserLogInResponse[]>(resource + paramsStr);
      })
    );
  }

  getLoyaltyCheckin(externalUID: string, authToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const authParams = this.getAuthParams(authToken, client);
        authParams.push({ key: 'external_uid', value: externalUID });
        const paramsStr = this.getQueryParamsString(authParams);
        const resource = '/checkins';
        return this.punchhHttp.get<any>(resource + paramsStr);
      })
    );
  }

  getAccountHistory(authToken: string): Observable<AccountHistoryItem[]> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString(this.getAuthParams(authToken, client));
        const resource = '/accounts';
        return this.punchhHttp.get<AccountHistoryItem[]>(resource + paramsStr);
      })
    );
  }

  createAccessTokenForSSO(token: string): Observable<{ token: string; user: UserLogInResponse }> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          security_token: token,
          client: clientID,
        };
        const resource = `/sso`;
        return this.punchhHttp.post<{ token: string; user: UserLogInResponse }>(resource, body, false);
      })
    );
  }

  changePassword(newPassword: string, authToken: string): Observable<UserLogInResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: {
            password: newPassword,
            password_confirmation: newPassword,
          },
          client: clientID,
          authentication_token: authToken,
        };
        const resource = `/users/change_password`;
        return this.punchhHttp.patch<UserLogInResponse>(resource, body);
      })
    );
  }

  /**
   * Gets a reset password token for the user account in question
   * @param email: Account email
   * @param password: Account password
   */
  getResetPasswordToken(email: string, password: string): Observable<GetResetPasswordTokenResponse> {
    return this.getClientID().pipe(
      switchMap(clientID => {
        const body = {
          user: {
            email,
            password,
          },
          client: clientID,
        };
        const resource = '/users/reset_password_token';
        return this.punchhHttp.post<GetResetPasswordTokenResponse>(resource, body, false);
      })
    );
  }

  /**
   * Submit the subtotal amount and returns what would be the estimated points that would be earned before submission.
   */
  estimateLoyaltyPointsEarning(subtotal: number, authToken: string): Observable<{ estimated_points: number }> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
          { key: 'subtotal_amount', value: subtotal },
        ]);
        const resource = '/loyalty_points_estimator';
        return this.punchhHttp.get<{ estimated_points: number }>(resource + paramsStr);
      })
    );
  }

  /**
   * Displays user information regarding active redemptions, notifications, badges, balance etc.
   */
  getUserBalance(authToken: string): Observable<GetUserBalanceResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/users/balance';
        return this.punchhHttp.get<GetUserBalanceResponse>(resource + paramsStr);
      })
    );
  }

  /**
   * Allows to convert points to Cash, Fuel discounts, Charities based on the configuration or conversion rules set in
   * the Punchh dashboard.
   * @param conversion_rule_id: Conversion rule id set for the dashboard configuration.
   * (Returned for the converted_category_balances object as category_id)
   * @param source_value: Number of the points user wants to get converted to Cash, Fuel Discounts or Charities.
   * @param converted_value: Values for the Cash, Fuel Discounts or Charities which user wants to get points converted into.
   * @param social_cause_campaign_id: Campaign id in case of points converting into charity.
   * @param authToken: Authorization token retrieved from Punchh on a successful login.
   */
  // tslint:disable-next-line: max-line-length
  pointConversion(
    conversion_rule_id: number,
    source_value: number,
    converted_value: number,
    social_cause_campaign_id: string,
    authToken: string
  ): Observable<Array<string>> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
          { key: 'conversion_rule_id', value: conversion_rule_id },
          { key: 'source_value', value: source_value },
          { key: 'converted_value', value: converted_value },
          { key: 'social_cause_campaign_id', value: social_cause_campaign_id },
        ]);
        const resource = '/conversions';
        return this.punchhHttp.post<Array<string>>(resource + paramsStr, {}, false);
      })
    );
  }

  /**
   * This would return all the available deals for a user.
   */
  listAllDeals(authToken: string): Observable<Array<Deals>> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/deals';
        return this.punchhHttp.get<Array<Deals>>(resource + paramsStr);
      })
    );
  }

  /**
   * This would add the selected deal to the user account.
   * @param redeemable_uuid
   * @param authToken: Authorization token retrieved from Punchh on a successful login.
   */
  saveSelectedDeals(redeemable_uuid: string, authToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
          { key: 'redeemable_uuid', value: redeemable_uuid },
        ]);
        const resource = '/deals';
        return this.punchhHttp.post<any>(resource + paramsStr, {}, false);
      })
    );
  }

  /**
   * This would return the details of the deal.
   */
  getDealDetails(redeemable_uuid: string, authToken: string): Observable<Array<Deals>> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/deals/' + redeemable_uuid;
        return this.punchhHttp.get<Array<Deals>>(resource + paramsStr);
      })
    );
  }

  /**
   * Get balance timelines of the user
   */
  getBalanceTimelines(authToken: string): Observable<GetBalanceTimelinesResponse> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/balance_timelines';
        return this.punchhHttp.get<GetBalanceTimelinesResponse>(resource + paramsStr);
      })
    );
  }

  /**
   * Allows to enroll users into a campaign (currently supported for social cause campaigns).
   * @param iType: Type of the campaign. Type currently supported is 'social_cause_campaign'
   * @param iID: Campaign id in which user needs to be enrolled.
   * @param authToken: Authorization token retrieved from Punchh on a successful login.
   */
  enrollUser(iType: string, iID: string, authToken: string): Observable<Array<string>> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
          { key: 'item_type', value: iType },
          { key: 'item_id', value: iID },
        ]);
        const resource = '/user_enrollments';
        return this.punchhHttp.post<Array<string>>(resource + paramsStr, {}, false);
      })
    );
  }

  /**
   * Allows to de-register users from a campaign (currently supported for social cause campaigns)
   * @param iType: Type of the campaign. Type currently supported is 'social_cause_campaign'
   * @param iID: Campaign id in which user needs to be de-enrolled.
   * @param authToken: Authorization token retrieved from Punchh on a successful login.
   */
  deEnrollUser(iType: string, iID: string, authToken: string): Observable<Array<string>> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
          { key: 'item_type', value: iType },
          { key: 'item_id', value: iID },
        ]);
        const resource = '/user_enrollments';
        return this.punchhHttp.delete<Array<string>>(resource + paramsStr);
      })
    );
  }

  /**
   * Fetch available offers or offers details of given user. This request will returns how many offers are available to given user.
   */
  // getAvailableOffers(authToken: string): Observable<GetAvailableOffersResponse> {
  getAvailableOffers(authToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/offers';
        return this.punchhHttp.get<any>(resource + paramsStr);
      })
    );
  }

  getApplicableOffers(authToken: string, subtotalCents: number, totalCents: number): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([
          { key: 'authentication_token', value: authToken },
          { key: 'client', value: client },
        ]);
        const resource = '/redemptions/applicable_offers';
        return this.punchhHttp.get<any>(resource + paramsStr);
      })
    );
  }

  deleteAccount(accessToken: string) {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([{ key: 'client', value: client }]);
        const resource = '/users';
        return this.punchhHttp.delete<any>(resource + paramsStr, true, accessToken);
      })
    );
  }

  mobileFetchMessages(accessToken: string): Observable<any> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([{ key: 'client', value: client }]);
        const resource = '/messages';
        return this.punchhHttp.get<any>(resource + paramsStr, true, accessToken);
      })
    );
  }

  mobileMarkMessageRead(accessToken: string, messageId: string): Observable<void> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([{ key: 'client', value: client }]);
        const resource = '/message_readerships';
        const body = {
          user_rich_notifications: messageId,
        };
        return this.punchhHttp.post<any>(resource + paramsStr, body, true, accessToken);
      })
    );
  }

  mobileDeleteMessage(accessToken: string, messageId: string): Observable<void> {
    return this.getClientID().pipe(
      switchMap(client => {
        const paramsStr = this.getQueryParamsString([{ key: 'client', value: client }]);
        const resource = '/message_readerships/' + messageId;
        return this.punchhHttp.delete<void>(resource + paramsStr, true, accessToken);
      })
    );
  }

  private getQueryParamsString(pairs: KeyValuePair[]): string {
    let params = new HttpParams();
    pairs.forEach(p => {
      if (p.value) {
        params = params.set(p.key, p.value);
      }
    });
    return params && params.keys().length > 0 ? '?' + params.toString() : '';
  }

  private getClientID(): Observable<string> {
    return this.directus.getPunchhSettings().pipe(map(res => res.punchh_client_id));
  }

  private getAuthParams(authToken: string, client: string): KeyValuePair[] {
    return [
      { key: 'authentication_token', value: authToken },
      { key: 'client', value: client },
    ];
  }

  private getAccessParams(accessToken: string, client: string): KeyValuePair[] {
    return [
      { key: 'access_token', value: accessToken },
      { key: 'client', value: client },
    ];
  }
}
