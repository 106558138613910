import { Component, OnInit, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import moment from 'moment-timezone';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss'],
})
export class TimeSelectionComponent implements OnInit, AfterContentInit, OnDestroy {
  timeSelection;
  dateSelection;
  loc;
  subs = [];
  minDate;
  maxDate;
  displayHours;
  open;
  close;
  datePicked;
  displayTime;

  @ViewChild('dateTime', { static: true }) dateTime;
  @ViewChild('timeTime', { static: true }) timeTime;

  constructor(private alertCtrl: AlertController) {}

  initSelection(order) {
    this.loc = order.location;
    if (order.basket) {
      this.timeSelection = order.basket.timewanted ? 'advanced' : 'asap';
      this.dateSelection = null;
      this.datePicked = order.basket.timewanted ? 'advanced' : 'asap';
      this.displayTime = order.basket.timewanted ? moment(order.basket.timewanted, 'YYYYMMDD hh:mm').format() : null;
    } else {
      this.timeSelection = 'asap';
      this.dateSelection = null;
      this.datePicked = 'asap';
      this.displayTime = null;
    }
    if (!this.loc) {
      return;
    }

    const open = order.location.hours[order.dineEngineBasket.handoff.type].open;
    const close = order.location.hours[order.dineEngineBasket.handoff.type].close;
    if (open == close) {
      this.displayHours = '0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15 , 16, 17, 18, 19, 20,21,22,23';
    } else {
      const a = moment(open, 'HH:mm A'); // now
      const b = moment(close, 'HH:mm A');

      this.displayHours = '';
      let ah = a.get('hours');
      const bh = b.get('hours');
      this.open = ah;
      this.close = bh;
      while (ah <= bh) {
        this.displayHours += ah + ', ';
        ah++;
      }
    }
    this.minDate = moment(Date.now()).format('YYYY-MM-DD');
    this.maxDate = moment(Date.now()).add(this.loc.advanceorderdays, 'day').format('YYYY-MM-DD');
    // if (!this.loc.advanceonly) {
    //   this.timeSelection = order.dineEngineBasket.handoff.time;
    //   this.datePicked =
    //     order.dineEngineBasket.handoff.time !== 'asap' ? 'advanced' : 'asap';
    // } else {
    //   this.timeSelection =
    //     order.dineEngineBasket.handoff.time !== 'asap'
    //       ? order.dineEngineBasket.handoff.time
    //       : 'asap';

    //   this.datePicked =
    //     order.dineEngineBasket.handoff.time !== 'asap' ? 'advanced' : 'asap';
    // }
  }
  ngOnInit() {
    // this.subs.push(
    //   this.order$.subscribe(order => {
    //     this.initSelection(order);
    //   })
    // );
  }

  // ionViewWillEnter() {
  //   const order = this.store.selectSnapshot(OrderState);
  //   this.initSelection(order);
  // }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Unavailable',
      subHeader: 'This time is not available. Please choose a new time.',
      buttons: [
        {
          text: 'ok',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngAfterContentInit() {
    // setTimeout(() => {
    //   this.datetime.value = this.timeSelection;
    // }, 350);
  }

  updateTime(event) {
    // this.store.dispatch(new SetTimePreference(event.detail.value));
  }
  setAsap() {
    // this.store.dispatch(new SetTimePreference('asap'));
  }

  timeCancel() {
    if (!this.timeSelection) {
      this.timeSelection = 'asap';
      this.datePicked = 'asap';
      this.setAsap();
    }
  }

  dateCancel() {
    if (!this.timeSelection) {
      this.timeSelection = 'asap';
      this.datePicked = 'asap';
      this.setAsap();
    }
  }

  timeChange(e) {
    const now = moment(new Date());
    const dateMoment = moment(this.dateSelection);
    const timeMoment = moment(this.timeSelection);
    const splittedDate = {
      year: dateMoment.get('year'),
      month: dateMoment.get('month'),
      date: dateMoment.get('date'),
      hour: timeMoment.get('hour'),
      minute: timeMoment.get('minute'),
      second: timeMoment.get('second'),
      millisecond: timeMoment.get('millisecond'),
    };
    if (!dateMoment.isValid()) {
      return;
    }
    this.displayTime = moment(new Date()).set(splittedDate).format();

    if (this.displayTime && moment(this.displayTime).diff(now, 'minutes') != 0) {
      if (
        moment(this.displayTime).diff(now, 'minutes') < 5 ||
        moment(this.displayTime, 'HH:mm A').get('hours') > this.close ||
        moment(this.displayTime, 'HH:mm A').get('hours') < this.open
      ) {
        setTimeout(() => {
          this.timeSelection = 'asap';
          this.datePicked = 'asap';
          this.setAsap();
        }, 100);
        this.presentAlert();
        return;
      }
      this.datePicked = 'advanced';
      // this.store.dispatch(new SetTimePreference(this.displayTime));
    }
  }

  dateChange(e) {
    if (this.dateSelection) {
      this.timeTime.open();
    }
  }

  openDate() {
    this.timeSelection = null;
    this.dateSelection = null;

    this.datePicked = 'asap';
    this.dateTime.open();
  }
}
