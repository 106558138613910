import { Injectable } from '@angular/core';
import { Address } from 'src/interfaces/address.interface';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { ContentService } from '../../../services/vendor-config-service/content-provider.service';
import { Location } from '../../../vendors/directus/interfaces/location.interface';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../store/state.model';

@Injectable({
  providedIn: 'root',
})
export class UserLocationService {
  savedAddressesKey = 'savedAddresses';
  userLocation: Address = null;
  savedAddresses: ISavedAddress[] = [];
  storeLimit = 5;
  cmsLocations: Location[] = [];

  constructor(
    private contentService: ContentService,
    private store: Store
  ) {
    this.contentService.getService().subscribe(cService => {
      cService.getLocations().subscribe(locations => {
        this.cmsLocations = locations;
        this.getUserLocation();
        this.getSavedAddresses();
      });
    });
  }

  storeUserLocation(address: Address) {
    this.userLocation = address;
  }

  getUserLocation(): Address {
    return this.userLocation;
  }

  storeSavedAddress(address: Address, orderType: 'pickup' | 'delivery', location: DineEngineLocation) {
    const newSavedAddress: ISavedAddress = {
      address,
      orderType,
      location: location,
      isFavorite: false,
      lastUsed: new Date(),
      frequency: 1,
    };
    this.savedAddresses = [...this.savedAddresses, newSavedAddress];
    this.savedAddresses = this.deDupeSavedAddresses(this.savedAddresses);
    // this will limit the number of addresses stored, at the moment i think the idea is to not limit them?
    if (this.store.selectSnapshot((state: GlobalStateModel) => state.user.user)?.isGuest) {
      while (this.savedAddresses.length > this.storeLimit) {
        this.savedAddresses.shift();
      }
    }
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
  }

  getSavedAddresses() {
    this.savedAddresses = JSON.parse(localStorage.getItem(this.savedAddressesKey)) || [];
    this.savedAddresses = this.savedAddresses.filter(savedAddress =>
      this.cmsLocations.some(location => location.menu_id === savedAddress.location.locationID)
    );
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
    return this.sortAddresses();
  }

  getMostRecentLocation() {
    return this.getSavedAddresses()[0];
  }

  private deDupeSavedAddresses(savedAddresses: ISavedAddress[]): ISavedAddress[] {
    const uniqueAddresses: ISavedAddress[] = [];
    for (let i = savedAddresses.length - 1; i >= 0; i--) {
      if (!uniqueAddresses.some(savedAddress => this.isSameSavedAddress(savedAddress, savedAddresses[i]))) {
        uniqueAddresses.push(savedAddresses[i]);
      }
    }
    return uniqueAddresses.reverse();
  }

  isSameSavedAddress(address1: ISavedAddress, address2: ISavedAddress): boolean {
    return (
      address1.address.address1 === address2.address.address1 &&
      address1.address.address2 === address2.address.address2 &&
      address1.address.city === address2.address.city &&
      address1.address.state === address2.address.state &&
      address1.address.zipCode === address2.address.zipCode &&
      address1.address.latitude === address2.address.latitude &&
      address1.address.longitude === address2.address.longitude &&
      address1.address.specialInstructions === address2.address.specialInstructions &&
      address1.address.specialInst === address2.address.specialInst
    );
  }

  /**
   * Sets an address as the favorite. Only one address can be favorited at a time.
   */
  favoriteAddress(address: ISavedAddress) {
    this.savedAddresses = this.savedAddresses.map(addr => ({
      ...addr,
      isFavorite: this.isSameSavedAddress(addr, address),
    }));
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
  }

  /**
   * Deletes an address from the saved addresses.
   */
  deleteAddress(address: ISavedAddress) {
    this.savedAddresses = this.savedAddresses.filter(savedAddress => !this.isSameSavedAddress(savedAddress, address));
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
  }

  unfavoriteAddress() {
    this.savedAddresses = this.savedAddresses.map(addr => ({
      ...addr,
      isFavorite: false,
    }));
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
  }

  /**
   * Sort addresses to ensure favorite is first, followed by most recently used.
   */
  private sortAddresses(): ISavedAddress[] {
    return this.savedAddresses.sort((a, b) => {
      if (a.isFavorite) return -1;
      if (b.isFavorite) return 1;
      return b.lastUsed > a.lastUsed ? 1 : -1;
    });
  }
}
