import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/interfaces/order.interface';
import { swingAnimation } from 'angular-animations';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppStateModel} from '../../../../../store/state/app.state';
import {NavigationService} from '@modules/navigation/services';
import {GlobalStateModel} from '../../../../../store/state.model';
import {CustomIcons} from '../../../../../vendors/directus/interfaces/custom-icons.interface';

@Component({
  selector: 'app-desktop-nav-cart',
  templateUrl: './desktop-nav-cart.component.html',
  styleUrls: ['./desktop-nav-cart.component.scss'],
  animations: [
    swingAnimation({anchor: 'swing', duration: 1000})
  ]
})
export class DesktopNavCartComponent implements OnChanges {

  @Select(state => state.app) app$: Observable<AppStateModel>;
  @Select((state: GlobalStateModel) => state.app.customIcons) customIcons$: Observable<CustomIcons>;


  @Output() addNewOrderType = new EventEmitter<any>();
  @Input() currencyCode: string;
  @Input() order: Order;
  @Input() isProductPrice;
  @Input() cartIcon;
  @Input() showOrder;
  @Input() buttonText;

  animateIcon = false;

  buttonSwitch: boolean; // false = button text | true = cart

  constructor(
      private router: Router,
      private navigation: NavigationService
  ) {
    this.buttonDisplay();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buttonDisplay();
    if (changes.hasOwnProperty('order')) {
      if (changes.order.previousValue && changes.order.currentValue
          && changes.order.previousValue.items.length !== changes.order.currentValue.items.length) {
        this.animateIcon = !this.animateIcon;
      }
    }
  }

  buttonDisplay() {
    if (!this.showOrder) {
      this.buttonSwitch = true;
    } else {
      this.buttonSwitch = !!this.order;
    }
  }

  cartButtonClicked() {
    if (this.order && this.order.location) {
      if (this.order.items.length > 0) {
        this.navigation.navigateToUpsellPage();
      } else {
        const menuID = this.order.location.slugURL ? this.order.location.slugURL : this.order.location.locationID;
        this.navigation.navigateToMenuPage(menuID);
      }
    } else {
      this.addNewOrderType.emit();
    }
  }

}
