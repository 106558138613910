import { Injectable } from '@angular/core';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { ContentProvider } from '../../providers/content-provider.interface';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    constructor(private directus: DirectusService) { }

    getService(): Observable<ContentProvider> { // technically this should be set up like the other providers,
        return of(this.directus);               // but it's kind of a special case
    }
}
