<div class="mobile-modal-header">
    <button class="back-button" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
    </button>
    <div class="header-title">Transfer Balance</div>
</div>

<ion-content class="mobile-modal-content">

    <div class="modal-body">

        <!--  Use default icon from prototype, will be dynamic from CMS-->
        <!--  <img class="purchase-rewards-icon" src="http://placehold.it/" alt="">-->

        <div class="transfer-balance-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
            </svg>
        </div>

        <h4 class="transfer-balance-title header-font">
            {{ transferBalanceCopy }}
        </h4>
      <ng-container *ngIf="rewardPoints$ | async as rewardsBalances">
        <div class="d-flex flex-column gap-1 align-items-center justify-content-center">
          <h2 class="balance-amount header-font">
            Balance: {{(rewardsBalances.storedValueCents / 100) | currency: (currencyCode$ | async)}}
          </h2>

          <a class="de-primary-link font-size-body-large" routerLink="/rewards/balance-reload" (click)="close()">
            Add to Balance
          </a>
        </div>
      </ng-container>
        <form class="w-100" [formGroup]="cardNumberForm">
          <label>Enter Gift Card Information</label>
          <div class="d-flex flex-column flex-lg-row gap-2 w-100">
                <div class="input-group" style="height: 50px;">
                    <input type="text" class="form-control h-100" placeholder="Card Number" aria-label="Card Number"
                           aria-describedby="transfer-button"
                           formControlName="cardNumber">
                </div>

                <div class="input-group" style="height: 50px;">
                    <input type="text" class="form-control h-100" placeholder="Pin Number" aria-label="Pin Number"
                           aria-describedby="transfer-button"
                           formControlName="pinNumber">
                    <div class="input-group-append">
                        <button class="btn btn-primary text-uppercase fw-bold text-nowrap" style="width: 106px"
                                type="button" id="transfer-button"
                                (click)="startTransfer()">
                            <ng-container *ngIf="!isLoading else loading">Transfer</ng-container>
                            <ng-template #loading>
                                <div class="de-btn-loader"></div>
                            </ng-template>
                        </button>
                    </div>
                </div>
            </div>
        </form>

    <div class="transfer-info">
      <h5 class="header-font fw-bold">
        Transferring a gift card to Your Account to use at Checkout
      </h5>

      <div class="d-flex flex-column gap-2">
        <p>
          Enter your gift card number above and press the transfer button to add the gift card’s balance to your account.
        </p>

        <!--      Only show if purchasing egift is enabled-->
        <ng-container *ngIf="canPurchaseNewCard">
          <p>
            If you do not have a gift card, you can <a class="de-primary-link" routerLink="/gift-cards" (click)="close()">purchase one here</a>.
          </p>
        </ng-container>
        <p>
          To redeem your balance, please use the “Apply Balance” button in the payment section at checkout.
        </p>
      </div>

        </div>

    <!--  Only if reloading is enabled-->
    <ng-container *ngIf="canReloadCard">
      <div class="reload-info">
        <h5>
          Reloading Your Balance
        </h5>

                <p>
                    If you would like to reload your balance directly, you may do so <a
                        class="de-primary-link" routerLink="/gift-cards" (click)="close()">here</a>.
                </p>
            </div>
        </ng-container>

    </div>
</ion-content>
