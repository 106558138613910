<div class="slide text-center" style="background-color: transparent;">
  <ng-container *ngIf="item.image">
    <img
      [src]="item.image.data.full_url"
      class="img-fluid object-fit-contain"
      [alt]="item.main_heading"
      loading="lazy">
  </ng-container>
  <div class="mt-3">
    <ng-container *ngIf="item.price_heading">
    <h2 class="font-size-h1 header-font de-text-primary fw-bold">{{item.price_heading}}</h2>
    </ng-container>
    <ng-container *ngIf="item.top_divider">
    <hr class="divider" />
    </ng-container>
    <ng-container *ngIf="item.main_heading">
    <h3 class="header-font fw-bold">{{item.main_heading}}</h3>
    </ng-container>
    <ng-container *ngIf="item.bottom_divider">
    <hr class="divider" />
    </ng-container>
    <ng-container *ngIf="item.subheading">
    <p class="font-size-body-small">{{item.subheading}}</p>
    </ng-container>
    <ng-container *ngIf="item.body">
    <p class="font-size-body-large">{{item.body}}</p>
    </ng-container>
    <ng-container *ngIf="item.disclaimer_text">
    <small class="font-size-small">{{item.disclaimer_text}}</small>
      <br/>
    </ng-container>
    <ng-container *ngIf="item.button_text && item.button_link">
      <button class="de-primary-btn mt-3" (click)="buttonClick(item.button_link)">{{item.button_text}}</button>
    </ng-container>
  </div>
</div>
