<div class="border-top">

    <div class="d-flex flex-nowrap gap-3 pt-3">
      <ng-container *ngIf="!hideCartFunctionality">

      <div class="p-0 d-flex flex-nowrap align-items-center">
        <div class="crementers align-items-center">
          <i (click)="decrementQuantity()" data-type="plus" *ngIf="quantity !== 1"
             class="bi bi-dash-circle text-dark qty_selector h-100 remove d-flex align-items-center"></i>
          <i (click)="decrementQuantity()" data-type="plus" *ngIf="quantity === 1"
             class="bi bi-slash-circle text-muted qty_selector h-100 qty-one remove d-flex align-items-center"></i>

          <div class="number bg-white d-flex align-items-center text-center justify-content-center h-100 p-3">
            {{ quantity }}
          </div>
          <i (click)="incrementQuantity()" data-type="plus"
             class="bi bi-plus-circle qty_selector text-dark h-100 add d-flex align-items-center"></i>
        </div>
      </div>
      </ng-container>

      <!--                <button type="button" class="px-1 btn btn-primary rounded-end-0 text-primary d-flex justify-content-center align-items-center pointer de-primary-bg de-primary-border"-->
      <!--                    (click)="decrementQuantity()" data-type="plus">-->
      <!--                    <div class="incrementer-text-size text-center text-white d-flex align-items-center justify-content-center">-->
      <!--                        &ndash;-->
      <!--                    </div>-->
      <!--                </button>-->
      <!--                <div class="border border-primary text-primary d-flex align-items-center justify-content-center px-1 de-primary-border de-primary-text"-->
      <!--                     data-type="text">-->
      <!--                    <div class="incrementer-text-size text-center d-flex justify-content-center align-items-center">-->
      <!--                        {{ quantity }}-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <button type="button" class="px-1 btn btn-primary rounded-start-0 text-primary d-flex justify-content-center align-items-center pointer de-primary-bg de-primary-border"-->
      <!--                    (click)="incrementQuantity()" data-type="plus">-->
      <!--                    <div class="incrementer-text-size text-center text-white d-flex align-items-center justify-content-center">-->
      <!--                        &#43;-->
      <!--                    </div>-->

      <!--                </button>-->


      <div class="flex-grow-1 justify-content-start p-0 align-self-end w-100">
        <button [disabled]="!itemConfigured || disableButton" (click)="createCardClicked()"
                class="btn btn-primary d-flex align-items-center justify-content-center w-100 de-primary-btn">
          Add to Cart
        </button>
      </div>
    </div>
</div>
