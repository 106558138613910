import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/interfaces/user.interface';
import { combineLatest, merge, Observable, startWith, Subject, Subscription } from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { Router } from '@angular/router';

import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { ToastService } from '../../../../../services/toast.service';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import {
  ConnectWithApple,
  ConnectWithFacebook,
  DeleteAccount,
  LogOut,
  PurchaseReward,
  RedeemInStoreReward,
  RedeemPointsFromScanner,
  RedeemRewardPoints,
  ReturnRewardPoints,
  SetPastOrders,
  SetPurchaseableRewards,
  SetRewards,
  SetRewardsBalances,
  TransferStoredValueToLoyaltyAccount,
  UpdateAppliedRewards,
  UpdateProfileInfo,
} from '../../../../../store/actions/user.actions';
import { MetaService } from '../../../../../services/meta.service';
import { RewardsBalances } from '../../../../../interfaces/rewards-balances.interface';
import { Reward } from '../../../../../interfaces/reward.interface';
import { HistoryEvent } from '../../../../../interfaces/history-event.interface';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { Location, LoyaltyLocation } from '../../../../../interfaces/location.interface';
import { MobileService } from '../../../../../services/mobile.service';
import { MobileRedemptionComponent } from '@modules/loyalty/components/mobile-redemption/mobile-redemption.component';
import { CancelOrder, EditOrder, RemoveReward, ReorderPastOrder, StageReward } from '../../../../../store/actions/order.actions';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { InStoreRedemptionComponent } from '@modules/loyalty/components/in-store-redemption/in-store-redemption.page';
import { CancelEditOrderModalComponent } from '@modules/profile/components/cancel-edit-order-modal/cancel-edit-order-modal.component';
import { LoyaltySection, MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Device } from '@capacitor/device';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import config from '../../../../../../capacitor.config';
import { NavigationService } from '@modules/navigation/services';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { DeleteAccountConfirmationComponent } from '@modules/profile/components';
import { ProjectKeyService } from '../../../../../services/project-key.service';
import { ContentService } from '../../../../../services/vendor-config-service/content-provider.service';
import { ModeService } from '../../../../../services/mode.service';
import { GlobalStateModel } from '../../../../../store/state.model';
import { ThemeColor } from '../../../../../vendors/directus/interfaces/theme-color.interface';
import {
  AccountActivityModalComponent,
  CompleteRewardBalancesModalComponent,
  OffersModalComponent,
  PurchaseRewardsModalComponent,
} from '@modules/loyalty/components';
import { AlertController, ModalController } from '@ionic/angular';
import { PurchaseableReward } from '../../../../../interfaces/purchaseable-reward.interface';
import { GiftCard } from '../../../../../interfaces/giftcard.interface';
import { Offer } from '../../../../../interfaces/offer.interface';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';
import { DineEngineError } from '../../../../../interfaces/dineengine-error.interface';
import { DollarReward } from '../../../../../interfaces/dollar-reward.interface';
import { PointRedemptionComponent } from '@modules/loyalty/components/point-redemption/point-redemption.component';
import { RewardsBalancesComponent } from '@modules/loyalty/components/rewards-balances/rewards-balances.component';
import { produce } from 'immer';
import moment from 'moment-timezone';
import { SentryService } from '@common/services';
import { PaytronixConfiguration } from '../../../../../vendors/directus/interfaces/paytronix-configuration.interface';
import { DirectusService } from '../../../../../vendors/directus/directus.service';
import { InboxMessage } from '../../../../../interfaces/inbox-message.interface';
import { IonDatetimeCustomEvent } from '@ionic/core';
import { UserField } from '../../../../../interfaces/user-field';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '@modules/auth/services';
import { BiometricAuth, BiometryType } from '@aparajita/capacitor-biometric-auth';

@Component({
  selector: 'app-profile',
  template: '',
})
export class ProfileComponent implements OnDestroy, OnInit, AfterViewInit {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.rewardPoints)
  rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.user.rewards) rewards$: Observable<Reward[]>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;
  @Select(state => state.user.activity) activity$: Observable<HistoryEvent[]>;
  @Select(state => state.location.pickupLocations) pickupLocations$: Observable<Location[]>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.user.inStoreReward) inStoreReward$: Observable<Reward>;
  @Select(state => state.order.stagedReward) stagedReward$: Observable<Reward>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.user.loyaltyLocations) loyaltyLocations$: Observable<LoyaltyLocation[]>;
  @Select((state: GlobalStateModel) => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select(state => state.user.purchaseableRewards)
  purchaseableRewards$: Observable<PurchaseableReward[]>;
  @Select(state => state.user.allowsTransferToLoyalty)
  allowsTransfer$: Observable<boolean>;
  @Select(state => state.user.offers) offers$: Observable<Offer[]>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.user.inboxMessages)
  inboxMessages$: Observable<InboxMessage[]>;
  @Select((state: GlobalStateModel) => state.user.additionalUserFields)
  additionalUserFields$: Observable<UserField[]>;
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  @Input() currencyCode: string;
  @Input() canApply: boolean;
  @Input() currentDollars: number;

  @Output() clickedRedeem = new EventEmitter<string | number>();

  cardNumberForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(null, [Validators.required]),
    pinNumber: new UntypedFormControl(null, [Validators.required]),
  });

  sortedPastOrders$: Observable<Order[]>;

  // Page details for SEO
  title = 'profile';
  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displayCurrentReward: Reward;
  isLoading = false;
  isReorderLoading = [false, false, false, false, false];
  isTransferLoading = false;
  @ViewChild('passwordInput', /* TODO: add static flag */ {}) passwordInput;
  @ViewChild('birthdayPick') birthdayPick;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  password = null;
  modalRef: NgbModalRef;

  loginRoute = this.navigation.getLoginPageSlug();
  orderNowLoading = false;
  pastOrdersLoading = false;

  errorMessage: string;
  facebookLoading = false;

  enableAppleSignIn = false; // Checks Main Settings
  showAppleSignIn = false; // Checks Platform

  appleLoading = false;

  loyaltyLocations: LoyaltyLocation[] = null;
  loyaltyLinks = [];
  loyaltySections: LoyaltySection[] = [];

  purchaseRewardsCopy = 'Redeem Rewards';
  displayBalances: RewardsBalances = null;
  purchasingReward = false;

  transferBalanceCopy = 'Transfer Gift Card';
  errorOpen = false;
  successMessage: string;
  successOpen = false;

  displayOffers: Offer[];

  enableQR = false;
  showSuccess = false;
  barcodeForm = new UntypedFormGroup({
    manualBarcode: new UntypedFormControl(),
  });

  displayRewardsDollars: DollarReward[] = [];
  areRewardsSelecting = false;
  dollarConversion = new UntypedFormGroup({
    dollarAmount: new UntypedFormControl('', [Validators.required]),
  });

  signInWithAppleOptions: SignInWithAppleOptions = {
    clientId: config.appId,
    redirectURI: config.server.hostname + '/login',
    scopes: 'name email',
    state: '12345',
  };

  form = new UntypedFormGroup({
    phone: new UntypedFormControl('', [Validators.required]),
    firstname: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    lastname: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    username: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    birthday: new UntypedFormControl({ value: '', disabled: false }, [Validators.required, Validators.minLength(8)]),
    emailOpt: new UntypedFormControl(true),
    smsOpt: new UntypedFormControl(true),
    biometrics: new UntypedFormControl(false),
    favoriteLocation: new UntypedFormControl(null),
  });

  forgotPasswordRoute = this.navigation.getForgotPasswordPageSlug();
  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  rewardsRoute = this.navigation.getRewardsPageSlug();
  activityRoute = this.navigation.getActivityPageSlug();
  editProfileRoute = this.navigation.getEditProfilePageSlug();
  savedCardsRoute = this.navigation.getSavedCardsPageSlug();
  profilePageRoute = this.navigation.getProfilePageSlug();
  inboxRoute = this.navigation.getInboxPageSlug();

  private subs: Subscription[] = [];

  stagedReward: Reward;
  key = 'default';
  showTiers = false;

  pointsBalanceCopy = 'Points Balance';
  redeemsCodeSuccessText = 'Code Redeemed';

  earliestBDay = moment().subtract(13, 'years').subtract(1, 'day').toDate();
  earliestBDayISO = this.earliestBDay.toISOString().split('T')[0];

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  additionalFieldsForm = new UntypedFormGroup({});

  formatter = (result: Location) => result.name;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        return !term
          ? this.loyaltyLocations
          : this.loyaltyLocations.filter(location => location.name.toLowerCase().includes(term.toLowerCase()));
      })
    );
  };

  biometricsLabel = 'Login with Biometrics';
  biometricsAvailable = false;

  constructor(
    protected toast: ToastService,
    private router: Router,
    private navigation: NavigationService,
    // private navbarService: NavBarService,
    // private pageService: ProfilePageService,
    protected store: Store,
    private meta: MetaService,
    private mobile: MobileService,
    public modeService: ModeService,
    private modalService: NgbModal,
    private orderTypeService: OrderTypeService,
    private keyService: ProjectKeyService,
    private content: ContentService,
    private modal: ModalController,
    public activeModal: NgbActiveModal,
    private directus: DirectusService,
    private sentry: SentryService,
    public authService: AuthService,
    private alertController: AlertController
  ) {
    this.keyService.getProjectKey().subscribe((key: string) => {
      this.key = key;
    });
  }

  get firstName() {
    return this.form.get('firstname');
  }

  get lastName() {
    return this.form.get('lastname');
  }

  get phone() {
    return this.form.get('phone');
  }

  get username() {
    return this.form.get('username');
  }

  get birthday() {
    return this.form.get('birthday');
  }

  get biometrics() {
    return this.form.get('biometrics');
  }

  // async togglePush(event) {
  //   if (event.detail.checked) {
  //     this.loyalty.updateDevice(this.memberId, this.fishbowlToken);
  //   }
  // }

  // smsChange() {
  //   if (this.smsOpt) {
  //     this.smsDidOpt = true;
  //   }
  // }

  get emailOpt() {
    return this.form.get('emailOpt');
  }

  get smsOpt() {
    return this.form.get('smsOpt');
  }

  get favoriteLocation() {
    return this.form.get('favoriteLocation');
  }

  ngOnInit() {
    this.additionalUserFields$.subscribe(fields => {
      if (fields && fields.length > 0) {
        const user = this.store.selectSnapshot((state: GlobalStateModel) => state.user.user);
        fields.forEach(field => {
          const formControl = new UntypedFormControl('', field.required ? [Validators.required] : []);
          formControl.setValue(user.providerSpecificFields[field.providerFieldName]);
          this.additionalFieldsForm.addControl(field.providerFieldName, formControl);
        });
      }
    });
    this.store.dispatch(new SetRouteBack(''));
    this.user$.pipe(filter(u => u !== null)).subscribe(user => {
      this.displayUser = user ? user : null;
      this.store.dispatch(new SetRewardsBalances(user.userID));
      this.store.dispatch(new SetPurchaseableRewards());
      this.store.dispatch(new SetPastOrders());
    });
    this.rewardPoints$.subscribe(r => (this.displayBalances = r));
    Device.getInfo().then(info => {
      this.showAppleSignIn = info.platform === 'ios' && parseInt(info.osVersion, 10) > 13;
    });
    this.offers$.subscribe(offers => {
      this.displayOffers = offers ? offers : null;
    });
    this.store.dispatch(new UpdateTitle(this.title));
    this.sentry.setTransactionName('Profile');
    this.meta.manualUpdate({
      title: 'Profile',
      description: null,
      keywords: null,
    });
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // Navbar service data
    this.subs.push(
      this.vendorSetup$.subscribe(vendorSetup => {
        if (vendorSetup) {
          this.displayVendorSetup = vendorSetup;
          // if (vendorSetup.loyalty_provider === 'none' && this.mobile.isMobile) {
          //   this.navigation.navigateToEditProfilePage();
          // }
          if (vendorSetup.user_provider === 'spendgo') {
            this.phone.disable();
            this.username.enable();
          }
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        if (user) {
          this.displayUser = user;
          this.form.patchValue({
            username: this.displayUser.email,
            phone: this.displayUser.phoneNumber ? this.displayUser.phoneNumber : '',
            firstname: this.displayUser.firstName,
            lastname: this.displayUser.lastName,
            birthday: this.displayUser.birthday ? moment(this.displayUser.birthday).format('MM/DD/YYYY') : null,
            emailOpt: this.displayUser.emailOptIn,
            smsOpt: this.displayUser.sMSOptIn,
          });
          if (this.displayUser.birthday && moment(this.displayUser.birthday).isBefore(moment(this.earliestBDay), 'day')) {
            this.birthday.disable();
          }
        } else {
          this.displayUser = null;
        }
      })
    );
    this.subs.push(
      combineLatest([this.order$, this.user$.pipe(filter(u => u !== null)), this.content.getService()]).subscribe(
        ([order, user, cService]) => {
          if (order) {
            if (this.modeService.mode === 'tableside' && sessionStorage.getItem('tablesideGuest')) {
              this.store.dispatch(new SetRouteBack(`/menu/${order.location.locationID}`));
            } else {
              this.store.dispatch(new SetRouteBack('/'));
            }
            this.store.dispatch(new SetRewards(user.userID, order.location.locationID)).subscribe(() => {
              this.store.dispatch(new UpdateAppliedRewards(null));
            });
          } else {
            if (this.modeService.mode === 'tableside') {
              this.store.dispatch(new SetRouteBack('/'));
            }
            cService.getLocations().subscribe(cLocations => {
              const staticLoc = cLocations.find(l => l.is_static_menu);
              if (staticLoc) {
                this.store.dispatch(new SetRewards(user.userID, staticLoc.menu_id)).subscribe(() => {
                  this.store.dispatch(new UpdateAppliedRewards(null));
                });
              }
            });
          }
        }
      )
    );
    this.stagedReward$.subscribe(x => (this.stagedReward = x));
    // tslint:disable-next-line:max-line-length
    this.subs.push(
      combineLatest(this.vendorSetup$.pipe(filter(vs => !!vs)), this.mainSettings$.pipe(filter(ms => ms !== null))).subscribe(
        ([vendorSetup, mainSettings]) => {
          this.enableAppleSignIn = vendorSetup.loyalty_provider === 'paytronix' && mainSettings.enable_sign_in_with_apple;
        }
      )
    );
    this.subs.push(
      combineLatest([this.loyaltyLocations$.pipe(filter(ll => !!ll)), this.user$.pipe(filter(u => u !== null))])
        .pipe(take(1))
        .subscribe(([locations, user]) => {
          this.loyaltyLocations = locations;
          this.favoriteLocation.setValidators([Validators.required]);
          if (user.favoriteLocation) {
            if (this.mobile.isMobile) {
              this.form.patchValue({
                favoriteLocation: user.favoriteLocation,
              });
            } else {
              this.form.patchValue({
                favoriteLocation: user.favoriteLocation.locationID,
              });
            }

            // if (this.instance) {
            //   this.instance.writeValue(user.favoriteLocation.name);
            // }
          } else {
            const interval = setInterval(() => {
              const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
              if (order && this.instance) {
                (document.getElementById('typeahead-focus') as HTMLInputElement).value = order.location.name;
                clearInterval(interval);
              }
            }, 250);
          }
        })
    );
    this.subs.push(
      this.mainSettings$.subscribe(settings => {
        if (settings.loyalty_sections && settings.loyalty_sections.length > 0) {
          this.loyaltyLinks = produce(settings.loyalty_sections, draft => draft.sort(this.sortSectionLinks));
          this.loyaltySections = produce(settings.loyalty_sections, draft => draft.sort(this.sortSections));
          // this.loyaltyLinks = this.loyaltyLinks.sort(this.sortSections);
          // this.loyaltySections = this.loyaltySections.sort(this.sortSectionLinks);
        }
      })
    );
    this.subs.push(
      this.directus.getPaytronixSettings().subscribe((settings: PaytronixConfiguration) => {
        this.showTiers = settings.show_tier_progress;
      })
    );
    this.subs.push(
      this.textFields$.subscribe(textFields => {
        if (textFields) {
          this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
        }
      })
    );
    this.sortedPastOrders$ = this.pastOrders$.pipe(
      startWith(null),
      tap(orders => {
        if (orders !== null) {
          this.pastOrdersLoading = false;
        }
      }),
      map(orders => {
        if (orders === null) {
          return null;
        }
        const sortedOrders = [...orders].sort((a, b) => {
          const dateA = new Date(a.orderReadyTimestamp);
          const dateB = new Date(b.orderReadyTimestamp);
          return dateB.getTime() - dateA.getTime();
        });
        this.isReorderLoading = new Array(sortedOrders.length).fill(false);
        return sortedOrders;
      })
    );
    if (Capacitor.getPlatform() !== 'web') {
      this.setupBiometrics();
    }
  }

  async setupBiometrics() {
    const biometricInfo = await BiometricAuth.checkBiometry();
    if (biometricInfo.isAvailable) {
      this.biometricsAvailable = true;
      switch (biometricInfo.biometryType) {
        case BiometryType.faceId:
          this.biometricsLabel = 'Login with Face ID';
          break;
        case BiometryType.touchId:
          this.biometricsLabel = 'Login with Touch ID';
          break;
        case BiometryType.faceAuthentication:
          this.biometricsLabel = 'Login with your face';
          break;
        case BiometryType.fingerprintAuthentication:
          this.biometricsLabel = 'Login with your fingerprint';
          break;
        case BiometryType.irisAuthentication:
          this.biometricsLabel = 'Login with your iris';
          break;
        case BiometryType.none:
        default:
          this.biometricsLabel = 'Login with Biometrics';
          break;
      }
      try {
        if ((await SecureStoragePlugin.get({ key: 'auth.biometrics' })).value === 'true') {
          this.biometrics.setValue(true);
        } else {
          this.biometrics.setValue(false);
        }
      } catch (e) {
        this.biometrics.setValue(false);
      }
    } else {
      this.biometricsAvailable = false;
    }
  }

  ngAfterViewInit() {
    this.shouldEnableQR();
  }

  shouldEnableQR() {
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
      this.enableQR = ms.enable_qr_scanner;
    });
  }

  orderNow() {
    this.orderNowLoading = true;
    this.order$
      .pipe(take(1))
      .toPromise()
      .then(order => {
        if (order) {
          const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
          this.navigation.navigateToMenuPage(menuID).then(() => (this.orderNowLoading = false));
        } else {
          this.orderTypeService.setNewOrderType(false);
        }
      });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  onKey(e, nextElement, goBirth?) {
    if (e.key === 'Enter') {
      if (goBirth) {
        this.birthdayPick.open();
      } else {
        nextElement.setFocus();
      }
    }
  }

  // birthDayChange(nextElement) {
  birthDayChange() {
    // nextElement.setFocus();
  }

  changeFavoriteLocation(event: any) {}

  saveChangesClicked() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.additionalFieldsForm.markAllAsTouched();
    if (!this.form.valid || !this.additionalFieldsForm.valid) {
      this.toast.danger('Please check that all info is valid');
      this.isLoading = false;
      return;
    }
    if (moment(this.birthday.value, 'MMDDYYYY').isAfter(moment(this.earliestBDay))) {
      this.errorMessage = 'You must be at least 13 years old to create an account.';
      this.toast.danger(this.errorMessage);
      this.isLoading = false;
      return;
    }
    const newUser = {
      userID: this.displayUser.userID,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      phoneNumber: this.phone.value,
      email: this.username.value,
      emailOptIn: this.emailOpt.value,
      sMSOptIn: this.smsOpt.value,
      pushOptIn: false,
      birthday: moment(this.birthday.value, 'MMDDYYYY').format('YYYY-MM-DD'),
    } as unknown as User;

    const additionalFields = this.store
      .selectSnapshot((state: GlobalStateModel) => state.user.additionalUserFields)
      .map(field => {
        return {
          ...field,
          value: this.additionalFieldsForm.get(field.providerFieldName).value,
        };
      });
    if (this.loyaltyLocations) {
      if (typeof this.favoriteLocation.value === 'string') {
        newUser.favoriteLocation = this.loyaltyLocations.find(l => l.locationID === this.favoriteLocation.value);
      } else {
        newUser.favoriteLocation = this.favoriteLocation.value;
      }
    }
    this.store
      .dispatch(new UpdateProfileInfo(newUser, additionalFields))
      .toPromise()
      .then(async () => {
        if (Capacitor.getPlatform() !== 'web') {
          if (this.biometrics.value) {
            try {
              const biometryAvailable = await BiometricAuth.checkBiometry();
              if (biometryAvailable.isAvailable) {
                await BiometricAuth.authenticate();
                await SecureStoragePlugin.set({ key: 'auth.biometrics', value: 'true' });
              }
            } catch (e) {
              console.error(e);
            }
          } else {
            try {
              await SecureStoragePlugin.remove({ key: 'auth.biometrics' });
            } catch (e) {
              console.error(e);
            }
          }
        }
        this.toast.success('Member info updated');
        this.navigation.navigateToProfilePage().then(() => (this.isLoading = false));
      })
      .catch(error => {
        console.error(error);
        this.isLoading = false;
        this.toast.danger(error.message);
      });
  }

  activatePassword() {
    if (!this.password) {
      this.password = '';
      setTimeout(() => {
        this.passwordInput.setFocus();
      }, 200);
    }
  }

  logout() {
    FacebookLogin.getCurrentAccessToken()
      .then(result => {
        if (result.accessToken) {
          FacebookLogin.logout();
        }
      })
      .catch(() => {});
    this.store
      .dispatch(new LogOut())
      .toPromise()
      .then(async () => {
        // if ((await SecureStoragePlugin.keys()).value.includes('auth.biometrics')) {
        //   const alert = await this.alertController.create({
        //     mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md',
        //     header: 'Keep Biometrics?',
        //     message: 'Would you like to keep your biometrics enabled for faster sign in?',
        //     backdropDismiss: false,
        //     buttons: [
        //       {
        //         text: 'No',
        //         role: 'cancel',
        //         handler: async () => {
        //           const save = (await SecureStoragePlugin.get({ key: 'auth.biometrics' })).value === 'true';
        //           await SecureStoragePlugin.remove({ key: 'auth.biometrics' });
        //
        //           if (!save) {
        //             await SecureStoragePlugin.remove({ key: 'auth.username' });
        //             await SecureStoragePlugin.remove({ key: 'auth.password' });
        //           }
        //         },
        //       },
        //       {
        //         text: 'Yes',
        //       },
        //     ],
        //   });
        //   await alert.present();
        // }
        if (this.modeService.mode === 'tableside') {
          this.store.dispatch(new SetRouteBack('/'));
        }
        this.navigation.navigateToLocationsPage();
      });
  }

  deleteAccount() {
    if (this.displayVendorSetup.user_provider === 'spendgo') {
      const accountName = this.key[0].toUpperCase() + this.key.substr(1).toLowerCase();
      this.modalRef = this.modalService.open(DeleteAccountConfirmationComponent, { centered: true });
      this.modalRef.componentInstance.clickedDelete.subscribe(() => {
        const supportEmail = 'support@spendgo.com';
        const subject = `Delete Member Submission - ${accountName}`;
        const body = `Hi Spendgo Support,

        On behalf of the member, they have requested in our integrated UI to delete their data linked to ${accountName}:

        Member Name: ${this.displayUser.firstName + ' ' + this.displayUser.lastName}
        Member Phone Number: ${this.displayUser.phoneNumber}
        Email Contact: ${this.displayUser.email}
        Request source: ${window.navigator.userAgent}

        Please delete their account data immediately and respond to customer with deletion confirmation.

        Thanks,
        The DineEngine Team`;
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);
        window.location.href = `mailto:${supportEmail}?subject=${encodedSubject}&body=${encodedBody}`;
        this.modalRef.dismiss();
      });
      this.modalRef.componentInstance.clickedCancel.subscribe(() => {
        this.modalService.dismissAll();
      });
    } else if (this.displayVendorSetup.user_provider === 'personica') {
      const accountName = this.key[0].toUpperCase() + this.key.substr(1).toLowerCase();
      this.modalRef = this.modalService.open(DeleteAccountConfirmationComponent, { centered: true });
      this.modalRef.componentInstance.clickedDelete.subscribe(() => {
        const supportEmail = 'clientsupport@personica.com';
        const subject = `Delete Member Submission - ${accountName}`;
        const body = `Hi Personica Support,

        On behalf of the member, they have requested in our integrated UI to delete their data linked to ${accountName}:

        Member Name: ${this.displayUser.firstName + ' ' + this.displayUser.lastName}
        Member Phone Number: ${this.displayUser.phoneNumber}
        Email Contact: ${this.displayUser.email}

        Please delete their account data immediately and respond to customer with deletion confirmation.

        Thanks,
        The DineEngine Team`;
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);
        window.location.href = `mailto:${supportEmail}?subject=${encodedSubject}&body=${encodedBody}`;
        this.modalRef.dismiss();
      });
      this.modalRef.componentInstance.clickedCancel.subscribe(() => {
        this.modalService.dismissAll();
      });
    } else {
      this.modalRef = this.modalService.open(DeleteAccountConfirmationComponent, { centered: true });
      this.modalRef.componentInstance.clickedDelete.subscribe(() => {
        this.store
          .dispatch(new DeleteAccount())
          .toPromise()
          .then(() => this.toast.success('Account deleted'));
        this.modalRef.dismiss();
      });
      this.modalRef.componentInstance.clickedCancel.subscribe(() => {
        this.modalService.dismissAll();
      });
    }
  }

  generateLabel = num => {
    return `${num.toFixed(0)} Points`;
  };

  redeemedReward(reward: Reward) {
    if (this.displayVendorSetup.loyalty_provider === 'punchh-olo' || this.displayVendorSetup.loyalty_provider === 'personica') {
      this.modalRef = this.modalService.open(MobileRedemptionComponent, {
        centered: true,
      });
      this.modalRef.componentInstance.reward = reward;
      this.modalRef.componentInstance.clickedRedeem.subscribe(() => {
        this.store
          .dispatch(new StageReward(reward))
          .toPromise()
          .then(() =>
            this.toast.success(
              'Reward added. Remember to add the necessary item to your cart to take advantage of this reward. Your reward will be applied at checkout.',
              7000
            )
          );
        this.modalRef.dismiss();
      });
      this.modalRef.componentInstance.clickedRedeemInStore.subscribe(() => {
        this.redeemedInStoreReward(reward);
      });
    } else {
      this.store
        .dispatch(new StageReward(reward))
        .toPromise()
        .then(() =>
          this.toast.success(
            'Reward added. Remember to add the necessary item to your cart to take advantage of this reward. Your reward will be applied at checkout.',
            7000
          )
        );
    }
  }

  redeemedInStoreReward(reward: Reward) {
    this.store.dispatch(new RedeemInStoreReward(reward));
    this.subs.push(
      this.inStoreReward$.subscribe(currentReward => {
        if (currentReward && currentReward.rewardID === reward.rewardID) {
          this.displayCurrentReward = currentReward;
          this.openInStoreRedemption(currentReward);
        }
      })
    );
    // this.navbarService.redeemInStoreReward(reward);
  }

  removedReward(reward: Reward) {
    this.store.dispatch([new RemoveReward(reward), new StageReward(null)]);
  }

  reorder(order: Order, index: number) {
    // this.error = null;
    // this.successMessage = null;
    this.isReorderLoading[index] = true;
    this.modalService.dismissAll();
    this.store
      .dispatch(new ReorderPastOrder(order))
      .toPromise()
      .then(() => {
        this.navigation.navigateToCheckoutPage().then(() => {
          this.isReorderLoading[index] = false;
        });
      })
      .catch(error => {
        this.isReorderLoading[index] = false;
        if (error && error.error && error.error.message) {
          // this.error = error.error.message;
          this.toast.danger(error.error.message);
        } else {
          // this.error = 'There was an error starting your reorder';
          this.toast.danger('There was an error starting your reorder');
        }
      });
  }

  viewConfirmation(order: Order) {
    this.navigation.navigateToConfirmationPage(order.orderID).then(() => this.modalService.dismissAll());
  }

  editOrderClicked(order: Order) {
    // this.error = null;
    // this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, {
      centered: true,
      modalDialogClass: 'condensed-modal',
    });
    this.modalRef.componentInstance.isCancelOrder = false;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store
        .dispatch(new EditOrder(order))
        .toPromise()
        .then(newState => {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          const menuID = newState.order.order.location.slugURL
            ? newState.order.order.location.slugURL
            : newState.order.order.location.locationID;
          this.navigation.navigateToMenuPage(menuID);
        })
        .catch(error => {
          if (error && error.error && error.error.message) {
            // this.error = error.error.message;
            this.toast.danger(error.error.message);
          } else {
            // this.error = 'There was an error editing this order';
            this.toast.danger('There was an error editing this order');
          }
          this.isLoading = false;
          this.store.dispatch(new SetPastOrders());
        });
    });
  }

  cancelOrderClicked(order: Order) {
    // this.error = null;
    // this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, {
      centered: true,
      modalDialogClass: 'condensed-modal',
    });
    this.modalRef.componentInstance.isCancelOrder = true;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store
        .dispatch(new CancelOrder(order))
        .toPromise()
        .then(() => {
          this.isLoading = false;
          this.toast.success('Order Canceled');
          // this.successMessage = 'Order Canceled';
          this.store.dispatch(new SetPastOrders());
        })
        .catch(error => {
          if (error && error.error && error.error.message) {
            // this.error = error.error.message;
            this.toast.danger(error.error.message);
          } else {
            // this.error = 'There was an error canceling this order';
            this.toast.danger('There was an error canceling this order');
          }
          this.isLoading = false;
          this.store.dispatch(new SetPastOrders());
        });
    });
  }

  connectWithFacebook() {
    this.errorMessage = '';
    this.facebookLoading = true;
    const FACEBOOK_PERMISSIONS = ['email'];
    FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
      .then(result => {
        FacebookLogin.getCurrentAccessToken()
          .then(result2 => {
            // @ts-ignore
            FacebookLogin.getProfile<{ email: string }>({ fields: ['email'] })
              .then(result3 => {
                if (result2.accessToken.userId && result2.accessToken.token && result3.email) {
                  // tslint:disable-next-line:max-line-length
                  this.store
                    .dispatch(new ConnectWithFacebook(result3.email, result2.accessToken.token, result2.accessToken.userId))
                    .toPromise()
                    .then(() => {
                      this.facebookLoading = false;
                    })
                    .catch(error => {
                      this.facebookLoading = false;
                      console.log(error);
                      this.errorMessage = error;
                    });
                } else {
                  this.errorMessage = 'There was an error retrieving your info from Facebook. Please try again later.';
                }
              })
              .catch(error => {
                this.facebookLoading = false;
                console.log(error);
                this.errorMessage = error;
              });
          })
          .catch(error => {
            this.facebookLoading = false;
            console.log(error);
            this.errorMessage = error;
          });
      })
      .catch(result => {
        console.error(result);
        this.errorMessage = 'Facebook Login Error';
        this.facebookLoading = false;
      });
  }

  connectWithApple() {
    this.appleLoading = true;
    SignInWithApple.authorize(this.signInWithAppleOptions)
      .then((result: SignInWithAppleResponse) => {
        this.store
          .dispatch(new ConnectWithApple(result, this.signInWithAppleOptions.redirectURI))
          .toPromise()
          .then(() => {
            this.appleLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.appleLoading = false;
          });
      })
      .catch(error => {
        console.log(error);
        this.appleLoading = false;
      });
  }

  navigateToRoute(route: string) {
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }

  routeToRewards() {
    this.navigation.navigateToRewardsPage();
  }

  routeToPayments() {
    this.navigation.navigateToSavedCardsPage();
  }

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }

  routeToInbox() {
    this.navigation.navigateToInboxPage();
  }

  routeToEdit() {
    this.navigation.navigateToEditProfilePage();
  }

  routeToActivity() {
    this.navigation.navigateToActivityPage();
  }

  pastOrders() {
    this.pastOrdersLoading = true;
    this.navigation.navigateToPreviousOrdersPage().then(() => (this.pastOrdersLoading = false));
  }

  private openInStoreRedemption(reward: Reward) {
    this.modalRef.dismiss();
    this.modalRef = this.modalService.open(InStoreRedemptionComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.reward = reward;
  }

  sortSections(a, b) {
    const aSort = Number.isNaN(parseInt(a.section_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(a.section_order, 10);
    const bSort = Number.isNaN(parseInt(b.section_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(b.section_order, 10);
    if (aSort < bSort) {
      return -1;
    }
    if (aSort > bSort) {
      return 1;
    }
    return 0;
  }

  sortSectionLinks(a, b) {
    const aSort = Number.isNaN(parseInt(a.route_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(a.route_order, 10);
    const bSort = Number.isNaN(parseInt(b.route_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(b.route_order, 10);
    if (aSort < bSort) {
      return -1;
    }
    if (aSort > bSort) {
      return 1;
    }
    return 0;
  }

  openAccountActivity() {
    this.modal
      .create({
        component: AccountActivityModalComponent,
      })
      .then(modal => modal.present());
  }

  openPurchaseRewards() {
    this.modal
      .create({
        component: PurchaseRewardsModalComponent,
      })
      .then(modal => modal.present());
  }

  purchaseReward(reward: PurchaseableReward) {
    this.purchasingReward = true;
    this.store.dispatch(new PurchaseReward(reward)).subscribe({
      next: () => {
        this.store.dispatch(new SetPurchaseableRewards());
        this.store.dispatch(new SetRewardsBalances(this.displayUser.userID));
        this.purchasingReward = false;
      },
      error: err => {
        console.log(err);
        this.purchasingReward = false;
      },
    });
  }

  startTransfer(): void {
    if (this.isLoading) {
      return;
    }
    if (this.cardNumberForm.valid) {
      const card: GiftCard = {
        cardNumber: this.cardNumber.value,
        cardPin: this.pinNumber.value,
        balance: null,
      };
      this.isLoading = true;
      this.store.dispatch(new TransferStoredValueToLoyaltyAccount(card)).subscribe(
        () => {
          this.toast.success('Balance Transferred');
          this.successMessage = 'Balance Transfer Successful';
          this.cardNumberForm.reset();
          this.successOpen = true;
          this.isLoading = false;
          setTimeout(() => {
            this.successOpen = false;
            this.successMessage = null;
          }, 5000);
        },
        error => {
          this.isLoading = false;
          this.errorMessage = error.message;
          this.errorOpen = true;
          this.toast.danger(this.errorMessage);
        }
      );
    } else {
      this.errorMessage = 'Please enter a valid card number and pin';
      this.errorOpen = true;
    }
  }

  get cardNumber(): AbstractControl {
    return this.cardNumberForm.get('cardNumber');
  }

  get pinNumber(): AbstractControl {
    return this.cardNumberForm.get('pinNumber');
  }

  openOffers() {
    this.modal
      .create({
        component: OffersModalComponent,
        animated: true,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  openBalances() {
    this.modal
      .create({
        component: CompleteRewardBalancesModalComponent,
        animated: true,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  earnPointsFromReceiptCode() {
    const qr = this.barcodeForm.get('manualBarcode').value;
    if (qr) {
      this.store
        .dispatch(new RedeemPointsFromScanner(qr))
        .toPromise()
        .then(() => {
          this.toast.showDismissableIonicToast(this.redeemsCodeSuccessText, 'de-ionic-success-toast', 8000);
          this.barcodeForm.reset();
        })
        .catch(error => {
          if (error instanceof DineEngineError) {
            if (error.message.includes('range')) {
              this.toast.showDismissableIonicToast('Invalid Receipt Number', 'de-ionic-error-toast', 8000);
            } else {
              this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
            }
          } else {
            this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
          }
        });
    } else {
      this.toast.showDismissableIonicToast('Please enter in code', 'de-ionic-warning-toast', 8000);
    }
  }

  redeemedDollars(reward: DollarReward) {
    // The only thing keep this from being optimized is rewards not inheriting from a base class
    this.modalRef = this.modalService.open(InStoreRedemptionComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.reward = reward;
    this.modalRef.componentInstance.clickedVoidPoints.subscribe(() => {
      this.modalRef.dismiss();
      this.store.dispatch(new ReturnRewardPoints(reward));
    });
  }

  redeemPoints() {
    this.modalRef = this.modalService.open(PointRedemptionComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.currentDollars = this.displayBalances.bankedRewards;
    this.modalRef.componentInstance.clickedRedeem.subscribe(points => {
      this.store.dispatch(new RedeemRewardPoints(points));
    });
  }

  redeemClicked() {
    this.clickedRedeem.emit(this.dollarConversion.get('dollarAmount').value);
    this.activeModal.close();
  }

  get dollarAmount() {
    return this.dollarConversion.get('dollarAmount');
  }

  openRewardsBalances() {
    this.modalRef = this.modalService.open(RewardsBalancesComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.balanceTotals = this.displayBalances;
  }

  rewardTrackBy(index: number, item: Reward) {
    return `${index}${item.rewardID}`;
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: value => {
        console.log(`You selected:`, value);
        this.favoriteLocation.setValue(value.location.value);
      },
    },
  ];

  setBirthdayValue(event: IonDatetimeCustomEvent<{ value: string }>) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      if (dateObject > this.earliestBDay) {
        this.toast.danger('You must be at least 13 years old to create an account.');
        return;
      }

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}/${day}/${year}`;

      // Set the reformatted date to your form control or variable
      this.birthday.setValue(reformattedDate);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  setCustomFieldDateValue(event: IonDatetimeCustomEvent<HTMLInputElement>, field: UserField) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}${day}${year}`;

      // Set the reformatted date to your form control or variable
      this.additionalFieldsForm.controls[field.providerFieldName].setValue(reformattedDate);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  setFavoriteLocationValue(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.favoriteLocation.setValue(element.value);
  }
}
