<ion-content>
  <ion-list>
    <ion-item></ion-item>
    <ion-item *ngFor="let page of pages" (click)="navigateTo(page.route)">
      <i class="{{ page.icon | bootstrapIcon }}" style="color: var(--ion-color-primary); font-size: 2rem"></i>
      <ion-label> {{ page.title }} </ion-label>
    </ion-item>
    <ng-container *ngIf="(customPages$ | async) as customPages">
      <ion-item *ngFor="let page of customPages">{{ page.title }}</ion-item>
    </ng-container>
  </ion-list>
</ion-content>
