<div class="summary-heading-container">
  <ion-row class="summary-heading header-font">
    Item Summary
  </ion-row>
</div>
<div *ngIf="!isLoading" class="summary-container">
  <ion-row>
    <ion-col class="product-name" size="9">
      {{ product?.name }}
    </ion-col>
    <ion-col class="product-price" size="3">
      <p *ngIf="!(product?.priceCents == 0)" class="mb-2">
        {{ (product?.priceCents / 100) | currency: (currencyCode$ | async)  }}</p>
      <p *ngIf="product?.priceCents == 00" class="mb-2"></p>
    </ion-col>
  </ion-row>

  <div *ngFor="let optionGroup of optionGroups">
    <div *ngIf="optionGroup?.options">
      <ion-row *ngFor="let option of optionGroup?.options" style="padding: 0;">
        <ng-container *ngIf="option.isSelected">
          <ion-col size="1"></ion-col>
          <ion-col class="modifier-name" size="8">
            {{ option.name }}
          </ion-col>
        </ng-container>
        <ng-container *ngIf="option.isSelected && option.addedCents != 0">
          <!-- <ion-col size="9"></ion-col> -->
          <ion-col class="modifier-price" size="3">
            {{ option.addedCents / 100 | currency: (currencyCode$ | async) }}
          </ion-col>
        </ng-container>
      </ion-row>
    </div>
  </div>

  <ion-row>
    <ion-col class="product-name" size="9">
      Total
    </ion-col>
    <ion-col class="product-price" size="3">
      {{ adjustedPrice / 100 * quantity | currency: (currencyCode$ | async) }}
    </ion-col>
  </ion-row>
</div>
