import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MobileService} from '../../../../services/mobile.service';

@Component({
    selector: 'app-delete-account-confirmation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './delete-account-confirmation.component.html',
    styleUrls: ['delete-account-confirmation.component.scss'],
})
export class DeleteAccountConfirmationComponent {

  @Output() clickedCancel = new EventEmitter<any>();
  @Output() clickedDelete = new EventEmitter<any>();

  deleteAccountLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    public mobile: MobileService,
  ) {}

  cancelClicked() {
    this.clickedCancel.emit();
  }

  deleteAccountClicked() {
    this.deleteAccountLoading = true;
    this.clickedDelete.emit();
  }
}
