import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DateTimeService } from '../../../../services/date-time.service';
import { NavigationService } from '@modules/navigation/services';
import { CancelGroupOrder, UpdateGroupOrder } from '../../../../store/actions/order.actions';
import { Observable } from 'rxjs';
import { GroupOrder } from '../../../../interfaces/group-order.interface';
// import {CapacitorPlatform} from '@capacitor/core/types/platforms';
import { ToastService } from '../../../../services/toast.service';
import { Location as URLLocation } from '@angular/common';
import { SubscriptionComponent } from '@common/components';
import { GlobalStateModel } from '../../../../store/state.model';
import { User } from '../../../../interfaces/user.interface';
import { ModeService } from '../../../../services/mode.service';
import { SetRouteBack, UpdateTitle } from '../../../../store/actions/app.actions';

@Component({
  selector: 'app-update-group-order',
  templateUrl: './update-group-order.component.html',
  styleUrls: ['update-group-order.component.scss'],
})
export class UpdateGroupOrderComponent extends SubscriptionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  title = 'update group order';
  timeIndex = 0;
  availableTimes = [];
  joinUrl;
  menuID;
  groupOrderID: string;
  basketID: string;
  displayUser: User;
  deadlineForm = new UntypedFormGroup({
    deadline: new UntypedFormControl('', [Validators.required]),
  });
  membersForm = new UntypedFormGroup({
    members: new UntypedFormControl(''),
  });
  noteForm = new UntypedFormGroup({
    note: new UntypedFormControl(''),
  });
  constructor(
    private store: Store,
    private dateTimeService: DateTimeService,
    private navigation: NavigationService,
    // private capacitor: CapacitorPlatform,
    public mode: ModeService,
    private toast: ToastService,
    private urlLocation: URLLocation
  ) {
    super();
    this.availableTimes = this.dateTimeService.getGroupOrderTimesToday();
    this.subs.push(
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
      })
    );
  }
  ngOnInit() {
    this.groupOrder$.subscribe(groupOrder => {
      if (groupOrder) {
        this.groupOrderID = groupOrder.groupOrderID;
        this.basketID = groupOrder.order.orderID;
        const tablesideUrl = this.mode.mode === 'tableside' ? '&mode=tableside&location=' + groupOrder.order.location.locationID : '';
        this.joinUrl =
          'https://' + window.location.hostname + '/group-order/join/' + this.groupOrderID + '/?basket=' + this.basketID + tablesideUrl;
        this.menuID = groupOrder.order.location.locationID;
        this.store.dispatch(new UpdateTitle(this.title));
        this.store.dispatch(new SetRouteBack('menu/' + this.menuID));
        this.note.setValue(groupOrder.groupOrderNote);
        this.timeIndex = this.availableTimes.findIndex(time => {
          const hours = new Date(time.value).getHours();
          const minutes = new Date(time.value).getMinutes();
          return groupOrder.groupOrderDeadline.getHours() === hours && groupOrder.groupOrderDeadline.getMinutes() === minutes;
        });
        if (this.timeIndex > -1) {
          this.deadline.setValue(this.availableTimes[this.timeIndex]?.value);
        }
      }
    });
  }
  onTimeChange(event: any) {
    this.timeIndex = this.availableTimes.findIndex((time: any) => time.value === event.target.value);
  }

  onTimeButtonClick(index: number) {
    this.timeIndex = index;
    this.deadlineForm.controls['deadline'].setValue(this.availableTimes[this.timeIndex].value);
  }

  updateGroupOrder() {
    if (this.mode.mode !== 'tableside') {
      const deadline = new Date(this.availableTimes[this.timeIndex].value);
      const members = this.members.value.split(',');
      const guestName = this.displayUser.firstName + ' ' + this.displayUser.lastName;
      this.store.dispatch(new UpdateGroupOrder(this.note.value, members, deadline, guestName)).subscribe(() => {
        this.navigation.navigateToMenuPage(this.menuID);
      });
    } else {
      this.navigation.navigateToMenuPage(this.menuID);
    }
  }
  cancelGroupOrder() {
    this.store.dispatch(new CancelGroupOrder()).subscribe(() => {
      this.navigation.navigateToHomePage();
    });
  }
  copyLink() {
    navigator.clipboard.writeText(this.joinUrl).then(() => {
      this.toast.success('Group Link Copied ✔');
    });
  }
  closePage() {
    // Allow fade out animation to play before navigating back
    setTimeout(
      // () => this.router.navigate(['..']),
      () => this.urlLocation.back(),
      100
    );
  }
  get deadline() {
    return this.deadlineForm.get('deadline');
  }
  get members() {
    return this.membersForm.get('members');
  }
  get note() {
    return this.noteForm.get('note');
  }
}
