import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { from } from 'rxjs';
import { Preferences as Storage } from '@capacitor/preferences';
import { LocalStorageKey } from '../../../models/common.enum';
import { catchError, map } from 'rxjs/operators';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';
import { UtilityService } from '@modules/utility/services';
import { ToastService } from '../../../services/toast.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class TokenManagerService {
  private providerTokenKeys = [
    LocalStorageKey.NOVADINE_USER_ID,
    LocalStorageKey.OLO_ACCESS_TOKEN,
    LocalStorageKey.OLO_AUTH_TOKEN,
    LocalStorageKey.PUNCHH_AUTH_TOKEN,
    LocalStorageKey.PUNCHH_ACCESS_TOKEN,
    LocalStorageKey.PUNCHH_MOBILE_AUTH_TOKEN,
    LocalStorageKey.PUNCHH_MOBILE_ACCESS_TOKEN,
    LocalStorageKey.PUNCHH_OAUTH_JWT_TOKEN,
    LocalStorageKey.PUNCHH_OAUTH_CODE,
    LocalStorageKey.PUNCHH_APPLE_USER_DATA,
    LocalStorageKey.PAYTRONIX_AUTH_TOKEN,
    LocalStorageKey.PAYTRONIX_ACCESS_TOKEN,
    LocalStorageKey.PAYTRONIX_REFRESH_TOKEN,
    LocalStorageKey.PAYTRONIX_REFRESH_TOKEN_EXPIRATION,
    LocalStorageKey.PAYTRONIX_PRINTED_CARD_NUMBER,
    LocalStorageKey.PAYTRONIX_USERNAME,
    LocalStorageKey.PERSONICA_ACCESS_TOKEN,
    LocalStorageKey.PERSONICA_MEMBER_ID,
    LocalStorageKey.PERSONICA_ACCESS_TOKEN_EXPIRATION,
    LocalStorageKey.SPENDGO_MEMBER_ID,
    LocalStorageKey.SPENDGO_ACCESS_TOKEN,
    LocalStorageKey.SPENDGO_ACCESS_TOKEN_EXPIRATION,
    LocalStorageKey.SPENDGO_REFRESH_TOKEN,
    LocalStorageKey.SPENDGO_REFRESH_TOKEN_EXPIRATION,
  ];
  constructor(
    private util: UtilityService,
    private toast: ToastService
  ) {}

  clearProviderTokens(): Observable<void> {
    return forkJoin({
      novadineUserID: from(Storage.remove({ key: LocalStorageKey.NOVADINE_USER_ID })),
      oloAccessToken: from(Storage.remove({ key: LocalStorageKey.OLO_ACCESS_TOKEN })),
      oloAuthToken: from(Storage.remove({ key: LocalStorageKey.OLO_AUTH_TOKEN })),
      punchhAuthToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_AUTH_TOKEN })),
      punchhAccessToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_ACCESS_TOKEN })),
      punchhMobileAuthToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_MOBILE_AUTH_TOKEN })),
      punchhMobileAccessToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_MOBILE_ACCESS_TOKEN })),
      punchhOauthJwtToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_OAUTH_JWT_TOKEN })),
      punchhOauthCode: from(Storage.remove({ key: LocalStorageKey.PUNCHH_OAUTH_CODE })),
      punchhAppleUserData: from(Storage.remove({ key: LocalStorageKey.PUNCHH_APPLE_USER_DATA })),
      paytronixAuthToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_AUTH_TOKEN })),
      paytronixAccessToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_ACCESS_TOKEN })),
      paytronixRefreshToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_REFRESH_TOKEN })),
      paytronixRefreshTokenExpiration: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_REFRESH_TOKEN_EXPIRATION })),
      paytronixPrintedCardNumber: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_PRINTED_CARD_NUMBER })),
      paytronixUsername: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_USERNAME })),
      personicaAccessToken: from(Storage.remove({ key: LocalStorageKey.PERSONICA_ACCESS_TOKEN })),
      personicaMemberID: from(Storage.remove({ key: LocalStorageKey.PERSONICA_MEMBER_ID })),
      personicaAccessTokenExpiration: from(Storage.remove({ key: LocalStorageKey.PERSONICA_ACCESS_TOKEN_EXPIRATION })),
      spendgoMemberID: from(Storage.remove({ key: LocalStorageKey.SPENDGO_MEMBER_ID })),
      spendgoAccessToken: from(Storage.remove({ key: LocalStorageKey.SPENDGO_ACCESS_TOKEN })),
      spendgoAccessTokenExpiration: from(Storage.remove({ key: LocalStorageKey.SPENDGO_ACCESS_TOKEN_EXPIRATION })),
      spendgoRefreshToken: from(Storage.remove({ key: LocalStorageKey.SPENDGO_REFRESH_TOKEN })),
      spendgoRefreshTokenExpiration: from(Storage.remove({ key: LocalStorageKey.SPENDGO_REFRESH_TOKEN_EXPIRATION })),
    }).pipe(map(() => {}));
  }

  setProviderTokens(tokenString: string): Observable<void> {
    const key = 8675309;
    const tokenObject = JSON.parse(this.util.base64DecodeXOR(decodeURIComponent(tokenString), key));

    const observables = [];

    for (const tokenKey of this.providerTokenKeys) {
      const tokenValue = tokenObject[tokenKey];
      if (tokenValue !== undefined && tokenValue !== null) {
        observables.push(
          fromPromise(Storage.set({ key: tokenKey, value: tokenValue })).pipe(
            catchError(error => {
              console.error(`Error setting ${tokenKey} in storage`, error);
              return of(null);
            })
          )
        );
      }
    }

    return forkJoin(observables).pipe(map(() => {}));
  }

  getEncodedProviderTokens(): Observable<string> {
    const key = 8675309;

    return forkJoin(
      this.providerTokenKeys.map(tokenKey =>
        from(Storage.get({ key: tokenKey })).pipe(
          map(result => ({ [tokenKey]: result.value })),
          catchError(error => {
            console.error(`Error getting ${tokenKey} from storage`, error);
            return of({ [tokenKey]: null });
          })
        )
      )
    ).pipe(
      map(resultsArray => {
        const tokenObject = Object.assign({}, ...resultsArray);
        const tokenString = JSON.stringify(tokenObject);
        const encodedString = this.util.base64EncodeXOR(tokenString, key);
        return encodedString;
      })
    );
  }
}
