import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';

import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import { DeleteSavedCard, SetSavedCards, SaveCardAsDefault } from '../../../../../store/actions/user.actions';
import { filter, take } from 'rxjs/operators';
import { MetaService } from '../../../../../services/meta.service';
import { NavigationService } from '@modules/navigation/services';
import { RewardsBalances } from '../../../../../interfaces/rewards-balances.interface';
import { OrderTypeService } from '@modules/cart/services/order-type.service';

@Component({
  selector: 'app-saved-cards',
  template: '',
})
export class SavedCardsComponent implements OnInit, OnDestroy {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.savedCards) savedCards$: Observable<SavedCard[]>;
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'saved cards';

  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displaySavedCards: SavedCard[];
  savedCreditCards: SavedCard[];
  isLoading = true;

  orderNowLoading = false;

  constructor(
    // private navbarService: NavBarService,
    // private pageService: SavedCardsPageService,
    private store: Store,
    private meta: MetaService,
    private navigation: NavigationService,
    private orderTypeService: OrderTypeService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // Navbar service data
    this.subs.push(
      this.vendorSetup$.subscribe(vendorSetup => {
        if (vendorSetup) {
          this.displayVendorSetup = vendorSetup;
        }
      })
    );
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
        }
      })
    );
    this.subs.push(
      this.user$.pipe(filter(u => u !== null)).subscribe(user => {
        this.isLoading = true;
        this.displayUser = user ? user : null;
        this.store
          .dispatch(new SetSavedCards(user.userID, true))
          .toPromise()
          .then(() => {
            this.isLoading = false;
          });
      })
    );
    // Page service data
    this.subs.push(
      this.savedCards$.subscribe(savedCreditCards => {
        this.savedCreditCards = savedCreditCards;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  deleteCard(card: SavedCard) {
    this.isLoading = true;
    this.store
      .dispatch(new DeleteSavedCard(card))
      .toPromise()
      .then(() => {
        this.isLoading = false;
      });
    // this.navbarService.deleteSavedCard(card);
  }

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }

  defaultCard(card: SavedCard) {
    this.store
      .dispatch(new SaveCardAsDefault(card))
      .toPromise()
      .then(() => {});
    // document.getElementById('cardBorder').style.color = '#00ff00 !important';
  }

  orderNow() {
    this.orderNowLoading = true;
    this.order$
      .pipe(take(1))
      .toPromise()
      .then(order => {
        if (order) {
          const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
          this.navigation.navigateToMenuPage(menuID).then(() => (this.orderNowLoading = false));
        } else {
          this.orderTypeService.setNewOrderType(false);
        }
      });
  }
}
