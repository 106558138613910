<div class="mobile-modal-header-back">
  <button class="back-button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  </button>
  <div class="header-title fw-bold header-font">Offers</div>
</div>


<ion-content class="mobile-modal-content">
  <div class="h-100 w-100">
    <ng-container *ngIf="offers$ | async as offers else noOffers">
      <ng-container *ngIf="offers.length else noOffers">
        <div class="modal-body">
          <img src="assets/icons/coupon.svg" class="offers-icon" alt="Offers">
          <h4 class="m-0 header-font fw-bold text-center">Your Offers</h4>
          <ng-container *ngFor="let offer of offers">
            <app-offer-card [offer]="offer" [insideModal]="true" class="w-100"></app-offer-card>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #noOffers>
    <div class="no-offers-grid h-100">
      <div class="d-flex flex-column flex-nowrap gap-3 align-items-center">
        <img src="assets/icons/coupon.svg" class="offers-icon" alt="Offers">
        <div class="d-flex flex-column gap-1">
          <h4 class="m-0 header-font fw-bold text-center">You don't have any offers</h4>
          <div class="font-size-body-regular text-center">Check back later to see if you have received offers.</div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="w-100 px-4">
            <button class="de-primary-btn w-100">Order Now</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ion-content>
