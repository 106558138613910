import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { LocationService } from 'src/services/vendor-config-service/location.service';
import { ContentService } from 'src/services/vendor-config-service/content-provider.service';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { Address } from 'src/interfaces/address.interface';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { Order } from 'src/interfaces/order.interface';
import { UserService } from 'src/services/vendor-config-service/user.service';
import { OrderService } from 'src/services/vendor-config-service/order.service';
import { map } from 'rxjs/operators';
import moment from 'moment-timezone';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { StartOrderForDelivery } from '../../../../../store/actions/order.actions';
import { TimeFrame } from 'src/interfaces/time-frame.interface';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../../store/state.model';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';

@Injectable()
export class LocationsPageService {
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  private selectedHandoffTypeSubject = new BehaviorSubject<HandoffType>(HandoffType.pickup);
  private selectedLocationSubject = new BehaviorSubject<DineEngineLocation>(null);
  private allLocationsSubject = new BehaviorSubject<DineEngineLocation[]>([]);
  private deliveryLocationsSubject = new BehaviorSubject<DineEngineLocation[]>([]);
  private guestDeliveryAddressSubject = new BehaviorSubject<Address>(null);
  private gmapsKeySubject = new BehaviorSubject<string>(null);
  private deliveryAvailablitySubject = new BehaviorSubject<boolean>(null);
  private brandingSubject = new BehaviorSubject<Branding>(null);
  private orderSubject = new BehaviorSubject<Order>(null);
  private errorSubject = new BehaviorSubject<Error>(null);
  private loadingSubject = new BehaviorSubject<boolean>(null);

  selectedHandoffType$ = this.selectedHandoffTypeSubject.asObservable();
  selectedLocation$ = this.selectedLocationSubject.asObservable();
  allLocations$ = this.allLocationsSubject.asObservable();
  deliveryLocations$ = this.deliveryLocationsSubject.asObservable();
  guestDeliveryAddress$ = this.guestDeliveryAddressSubject.asObservable();
  gmapsKey$ = this.gmapsKeySubject.asObservable();
  deliveryAvailablity$ = this.deliveryAvailablitySubject.asObservable();
  branding$ = this.brandingSubject.asObservable();
  order$ = this.orderSubject.asObservable();
  error$ = this.errorSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  constructor(
    private locationsService: LocationService,
    private contentService: ContentService,
    private userService: UserService,
    private orderService: OrderService,
    // private navbarService: NavBarService,
    private geo: GeocodingService,
    private userLocations: UserLocationService
  ) {
    this.loadingSubject.next(true);
    this.mainSettings$.subscribe(settings => {
      if (settings && settings.gmaps_key) {
        this.gmapsKeySubject.next(settings.gmaps_key);
      }
    });
  }

  startLoading() {
    this.loadingSubject.next(true);
  }

  stopLoading() {
    this.loadingSubject.next(false);
  }

  // tslint:disable-next-line:max-line-length
  calculateTimeWanted(location: DineEngineLocation, handoffType: HandoffType) {
    // TODO: Make a private function to reduce the code
    const now = moment();
    if (handoffType === HandoffType.delivery) {
      if (now.isBetween(moment(location.deliveryHours[0].start), moment(location.deliveryHours[0].end))) {
        return now.toDate();
      } else if (now.isBefore(moment(location.deliveryHours[0].start))) {
        if (now.isAfter(moment(location.deliveryHours[0].start).subtract(45, 'minutes'))) {
          return moment(now).add(45, 'minutes').toDate();
        } else {
          return moment(location.deliveryHours[0].start).toDate();
        }
      } else if (location.deliveryHours.length > 1) {
        if (now.isBefore(moment(location.deliveryHours[1].start)) && now.isAfter(moment(location.deliveryHours[0].end))) {
          if (now.isAfter(moment(location.deliveryHours[1].start).subtract(45, 'minutes'))) {
            return moment(now).add(45, 'minutes').toDate();
          } else {
            return moment(location.deliveryHours[1].start).toDate();
          }
        } else if (now.isAfter(moment(location.deliveryHours[1].end), 'minute')) {
          return moment(location.deliveryHours[2].start).toDate();
        } else {
          return now.toDate();
        }
      } else {
        return now.toDate();
      }
    } else if (handoffType === HandoffType.dispatch) {
      if (location.dispatchHours && location.dispatchHours.length) {
        if (now.isBetween(moment(location.dispatchHours[0].start), moment(location.dispatchHours[0].end))) {
          return now.toDate();
        } else if (now.isBefore(moment(location.dispatchHours[0].start))) {
          if (now.isAfter(moment(location.dispatchHours[0].start).subtract(45, 'minutes'))) {
            return moment(now).add(45, 'minutes').toDate();
          } else {
            return moment(location.dispatchHours[0].start).toDate();
          }
        } else if (location.dispatchHours.length > 1) {
          if (now.isBefore(moment(location.dispatchHours[1].start)) && now.isAfter(moment(location.dispatchHours[0].end))) {
            if (now.isAfter(moment(location.dispatchHours[1].start).subtract(45, 'minutes'))) {
              return moment(now).add(45, 'minutes').toDate();
            } else {
              return moment(location.dispatchHours[1].start).toDate();
            }
          } else if (now.isAfter(moment(location.dispatchHours[1].end), 'minute')) {
            return moment(location.dispatchHours[2].start).toDate();
          } else {
            return now.toDate();
          }
        } else {
          return now.toDate();
        }
      } else {
        return now.toDate();
      }
    }
  }
}
