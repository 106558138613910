import {Component, Input, OnInit} from '@angular/core';

import {Plugins} from '@capacitor/core';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {InstagramDataObject, InstagramResponse} from '@modules/brochure/models/instagram.interface';
import { Browser } from '@capacitor/browser'




@Component({
  selector: 'app-instafeed',
  templateUrl: './instafeed.component.html',
  styleUrls: ['./instafeed.component.scss'],

})
export class InstafeedComponent implements OnInit {

  @Input() mainSettings: MainSettings;

  @Input() images: InstagramDataObject[] = [];
  @Input() isMobile: boolean;
  @Input() profileImage: string;

  constructor() { }

  ngOnInit(): void {
    // console.log('making call')
    // this.http.get<{Token: string}>('https://ig.instant-tokens.com/users/61840e1e-341d-46f6-bd66-9de1ca70738e/instagram/17841400144573656/token?userSecret=smxsp3x8tx4ei4jd0ooyi').subscribe(res => {
    //   console.log(res)
    //   const userFeed = new Instafeed({
    //     get: 'user',
    //     accessToken: res.Token,
    //     limit: 12,
    //     sortBy: 'least-recent',
    //     template: '',
    //     orientation: 'square',
    //     success: (response: InstagramResponse) => {
    //       console.log(response)
    //       this.images = response.data
    //       console.log(this.images)
    //     },
    //     error: (error) => {
    //       console.log(error)
    //     }
    //   })
    //   userFeed.run()
    // })

  }

  routeToImage(imageURL: string): void {
    Browser.open({
      url: imageURL,
    })
  }

  routeToProfile(username: string): void {
    Browser.open({
      url: `https://instagram.com/${username}`
    })
  }

}
