<h6 class="flex-grow-1 header-font fw-bold">
  Select a Day
</h6>

<form>
  <select
    (change)="onDateChange($event)"
    class="form-control de-input border w-100"
    id="availableDatesSelect">
    <!--    <option-->
    <!--      *ngFor="let date of availableDates; let i = index"-->
    <!--      value="{{ date.value }}"-->
    <!--      [selected]="i === dateIndex">-->
    <!--      {{ date.key }}-->
    <!--    </option>-->
    <option
      *ngFor="let date of availableDates; let i = index"
      [selected]="i === dateIndex">
      {{ date.key }}
    </option>
  </select>
</form>
