import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '../interfaces/option.interface';
import { OptionGroup } from '../interfaces/option-group.interface';

@Pipe({
  name: 'isOptionValid',
})
export class IsOptionValidPipe implements PipeTransform {
  transform(option: Option, optionGroups: OptionGroup[]): boolean {
    let isValid = true;
    optionGroups.forEach((group: OptionGroup) => {
      if (!this.optionGroupIsValid(group)) {
        if (!this.optionCheck(option, group)) {
          isValid = false;
        }
      }
    });
    return isValid;
  }

  isOptionValid(option: Option, optionGroups: OptionGroup[]): boolean {
    let isValid = true;
    optionGroups.forEach((group: OptionGroup) => {
      if (!this.optionGroupIsValid(group)) {
        if (!this.optionCheck(option, group)) {
          isValid = false;
        }
      }
    });
    return isValid;
  }

  optionCheck(option: Option, group: OptionGroup) {
    if (this.truthCheck(option, group)) {
      return false;
    } else if (option.optionGroups && option.optionGroups.length) {
      return option.optionGroups.every(gr => gr.options.every(op => this.optionCheck(op, group)));
    } else {
      return true;
    }
  }

  truthCheck(option: Option, group: OptionGroup) {
    return option.optionGroups.some(gr => gr === group);
  }

  optionGroupIsValid(group: OptionGroup): boolean {
    const selectedOptions = group.options.filter(op => op.isSelected);
    return !group.minRequired || selectedOptions.length >= group.minRequired;
  }
}
