export interface RestaurantMenu {
  imagepath: string;
  categories: Category[];
  singleusecategories: Category[];
}

export interface Category {
  id: number;
  name: string;
  description: string;
  metadata: Metadatum[];
  images: Image[];
  products: Product[];
}

export interface Image {
  groupname: string;
  description: string;
  isdefault: boolean;
  filename: string;
  url: string;
}

export interface Metadatum {
  key: string;
  value: string;
}

export interface Product {
  id: number;
  chainproductid: number;
  name: string;
  description: string;
  cost: number;
  basecalories: string;
  maxcalories: string;
  caloriesseparator: string;
  displayid: string;
  starthour: number;
  endhour: number;
  imagefilename: string;
  maximumquantity: string;
  minimumquantity: string;
  maximumbasketquantity: string;
  minimumbasketquantity: string;
  quantityincrement: string;
  shortdescription: string;
  metadata: Metadatum[];
  menuitemlabels: Menuitemlabel[];
  images: Image[];
  availability: Availability;
  unavailablehandoffmodes: string[];
  alcoholstatus: AlcoholStatus;
}

export enum AlcoholStatus {
  NONE = 'None',
  ALCOHOL = 'Alcohol',
  FOOD_AND_ALCOHOL = 'FoodAndAlcohol',
}

export interface Availability {
  always: boolean;
  now: boolean;
  description: string;
  startdate: string;
  enddate: string;
}

export interface Menuitemlabel {
  name: string;
  code: string;
  images: Image[];
}
