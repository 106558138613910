<ion-tabs main>

  <ion-tab-bar
    color="secondary"
    slot="bottom"
    
    class="ion-hide-md-up">

    <ion-tab-button (click)="routeToMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Restaurant</title><path d="M57.49 47.74l368.43 368.43a37.28 37.28 0 010 52.72h0a37.29 37.29 0 01-52.72 0l-90-91.55a32 32 0 01-9.2-22.43v-5.53a32 32 0 00-9.52-22.78l-11.62-10.73a32 32 0 00-29.8-7.44h0a48.53 48.53 0 01-46.56-12.63l-85.43-85.44C40.39 159.68 21.74 83.15 57.49 47.74z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M400 32l-77.25 77.25A64 64 0 00304 154.51v14.86a16 16 0 01-4.69 11.32L288 192M320 224l11.31-11.31a16 16 0 0111.32-4.69h14.86a64 64 0 0045.26-18.75L480 112M440 72l-80 80M200 368l-99.72 100.28a40 40 0 01-56.56 0h0a40 40 0 010-56.56L128 328" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
      <ion-label>Menu</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="locations">
      <i class="bi bi-cursor"></i>
      <ion-label>Locations</ion-label>
    </ion-tab-button>

    <ng-container *ngIf="(providers | async) as prov">
      <ion-tab-button tab="rewards" *ngIf="prov.loyalty_provider !== 'none'">
        <i class="bi bi-award"></i>
        <ion-label>Rewards</ion-label>
      </ion-tab-button>
    </ng-container>

    <ion-tab-button tab="orders">
      <i class="bi bi-receipt-cutoff"></i>  
      <ion-label>Reorder</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="routeToCart()">
      <i class="bi bi-bag"></i>
      <ion-label>Cart</ion-label>
      <ng-container>
        <ion-badge class="cart-count" *ngIf="order && order?.items && order?.items?.length > 0">
          {{ basketCount(order?.items) }}
        </ion-badge>
      </ng-container>
    </ion-tab-button>

  </ion-tab-bar>

</ion-tabs>
