import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '../interfaces/option.interface';
import { OptionGroup } from '../interfaces/option-group.interface';

@Pipe({
  name: 'maxGroupQuantity',
  pure: false,
})
export class MaxGroupQuantityPipe implements PipeTransform {
  transform(option: Option, optionGroup: OptionGroup, selectedOptions: Option[]): number {
    const maxAllowed = optionGroup.maxAllowed;
    // get all options that are selected from the same option group
    const selectedOptionsFromSameGroup = selectedOptions.filter(
      selectedOption => optionGroup.options.find(option => option.optionID === selectedOption.optionID) !== undefined
    );
    return maxAllowed - selectedOptionsFromSameGroup.length;
  }
}
