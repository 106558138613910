import { Pipe, PipeTransform } from '@angular/core';
import {OrderItem, Product} from '../interfaces/product.interface';
import {Store} from '@ngxs/store';
import {GlobalStateModel} from '../store/state.model';

@Pipe({
  name: 'productNotInCart',
  pure: false
})
export class ProductNotInCartPipe implements PipeTransform {

  constructor(private store: Store) {
  }

  transform(products: Product[]): Product[] {
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
    if (order) {
      return products.filter((product: Product) => {
        return !order.items.some((productInCart: OrderItem) => productInCart.productID === product.productID);
      });
    }
    return products;
  }

}
