<div class="ingredient-button-group">
  <ng-container *ngIf="!optionSubGroup; else subOptionGroupChoices">
    <ng-container *ngTemplateOutlet="optionGroupChoices"></ng-container>
  </ng-container>
</div>

<ng-template #subOptionGroupChoices>
  <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
    <button type="button" class="group-button-primary" [class.active]="childOption.option.optionID === selectedSubOption?.optionID"
            (click)="$event.stopPropagation(); subOptionClicked(childOption.option)">
      {{ childOption.name }}
    </button>
  </ng-container>
</ng-template>

<ng-template #optionGroupChoices>
  <ng-container *ngFor="let childOption of optionGroup.options">
    <button type="button" class="group-button-primary" [class.active]="childOption.optionID === selectedOption?.optionID"
            (click)="$event.stopPropagation(); optionClicked(childOption)">
      {{ childOption.name }}
    </button>
  </ng-container>
</ng-template>
