<div [id]="'option-group-ordering-'+ toSlug(optionGroup.name)" class="option-group-header d-flex flex-column">
  <h5 class="option-group-name header-font text-start m-0 mb-1">
    {{ optionGroup?.name }}
  </h5>
  <div *ngIf="optionGroup?.description" class="option-group-description d-inline-block w-100 text-start mb-1">
    {{ optionGroup?.description }}
  </div>
  <app-option-group-status
    [optionGroup]="optionGroup"
    [optionGroups]="optionGroups"
  ></app-option-group-status>
</div>
