import { Injectable, Injector } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { VendorSetup } from '../../interfaces/vendor.interface';
import { CustomerTrackingProvider } from '../../providers/customer-tracking-provider.interface';
import ProviderConfig from '../../vendors/config/vendor.config';
import { FlybuyProviderService } from '../../vendors/flybuy/flybuy-provider.service';
import { filter, map, take } from 'rxjs/operators';
import {RadarProviderService} from '../../vendors/radar/radar-provider.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerTrackingService {

  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  private currentService: CustomerTrackingProvider;

  constructor(
    private injector: Injector
  ) {}

  getService(): Observable<CustomerTrackingProvider> {
    if (this.currentService) {
      return of(this.currentService);
    } else {
      return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
        return this.chooseService(vs.customer_tracking_provider);
      }));
    }
  }

  private chooseService(vendor: string): CustomerTrackingProvider {
    switch (vendor) {
      case ProviderConfig.flybuy: return this.currentService = this.injector.get(FlybuyProviderService);
      case ProviderConfig.radar: return this.currentService = this.injector.get(RadarProviderService);
      default: return null;
    }
  }

}
