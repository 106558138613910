<div class="popup-body mb-3">
    <ion-row class="ion-justify-content-end">
        <ion-col class="ion-float-end" size="auto">
            <div id="btn-close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
                <i class="bi bi-x-circle font-size-h1 d-flex"></i>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="tableside-modal-contents ion-no-padding">
            <ion-row class="header-font modal-title">
                Cancel Order?
            </ion-row>
            <ion-row class="modal-text ln-1">
                Are you sure you would like to cancel your order? You will need to scan a new table QR code if you wish
                to begin a new order.
            </ion-row>

            <div class="modal-btns d-flex flex-column px-3">
                <button class="de-primary-btn w-100 mb-3" id="modify" (click)="activeModal.close('Close click')">
                    Don't Cancel
                </button>
                <button class="de-secondary-btn w-100" id="modify" (click)="cancelOrderButtonClick()">
                    Cancel Order
                </button>
            </div>
        </ion-col>
    </ion-row>
</div>
