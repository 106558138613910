import { Injectable, Injector } from '@angular/core';
import { LoyaltyProvider } from '../../providers/loyalty-provider.interface';
import { CmsVendorSetupService } from './cms-vendor-setup.service';
import { Observable, of } from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import { PunchhOLOProviderService } from 'src/vendors/punchh-olo/punchh-olo-provider.service';
import ProviderConfig from '../../vendors/config/vendor.config';
import { PaytronixProviderService } from 'src/vendors/paytronix/paytronix-provider.service';
import {Select} from '@ngxs/store';
import {VendorSetup} from '../../interfaces/vendor.interface';
import {PersonicaProviderService} from '../../vendors/personica/personica-provider.service';
import {SpendgoProviderService} from '../../vendors/spendgo/spendgo-provider.service';
import {NovaDineProviderService} from '../../vendors/novadine/novadine-provider.service';

@Injectable({
    providedIn: 'root'
})
export class LoyaltyService {

    @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

    private currentService: LoyaltyProvider;

    constructor(
        private vendorService: CmsVendorSetupService,
        private injector: Injector
    ) {}

    getService(): Observable<LoyaltyProvider> {
        if (this.currentService) {
            return of(this.currentService);
        } else {
            return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
                return this.chooseService(vs.loyalty_provider);
            }));
            // return this.vendorService.getService().pipe(switchMap(service => {
            //     return service.getVendorSetup().pipe(map(setup => {
            //         return this.chooseService(setup.loyalty_provider);
            //     }));
            // }));
        }
    }

    private chooseService(vendor: string): LoyaltyProvider {
        switch (vendor) {
            case ProviderConfig.punchhOlo: return this.currentService = this.injector.get(PunchhOLOProviderService);
            case ProviderConfig.paytronix: return this.currentService = this.injector.get(PaytronixProviderService);
            case ProviderConfig.personica: return this.currentService = this.injector.get(PersonicaProviderService);
            case ProviderConfig.spendgo: return this.currentService = this.injector.get(SpendgoProviderService);
            case ProviderConfig.novadine: return this.currentService = this.injector.get(NovaDineProviderService);
            default: return null;
        }
    }

}
