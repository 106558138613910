import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { of, Observable } from 'rxjs';
import { ProjectKeyService } from 'src/services/project-key.service';
import { tap, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DirectusClientService {
  private client: DirectusClient;

  constructor(
    private keyService: ProjectKeyService,
    private http: HttpClient
  ) {}

  getClient(): Observable<DirectusClient> {
    return this.client
      ? of(this.client)
      : this.keyService.getProjectKey().pipe(
          tap(key => {
            this.client = new DirectusClient(environment.domainAPI, key, this.http);
          }),
          map(key => {
            return this.client;
          })
        );
  }
}

class DirectusClient {
  private readonly url: string;
  public readonly project: string;

  constructor(
    url: string,
    project: string,
    private http: HttpClient
  ) {
    this.url = url;
    this.project = project;
  }

  getItems<T>(collection: string, options: Record<string, string | number | boolean>): Observable<T> {
    return this.http.get<T>(`${this.url}/${this.project}/items/${collection}`, {
      params: new HttpParams({
        fromObject: options,
      }),
    });
  }
}
