import { Pipe, PipeTransform } from '@angular/core';
import { OptionGroup } from '../interfaces/option-group.interface';
import { Option, OrderItemModifier } from '../interfaces/option.interface';

@Pipe({
  name: 'option-group-completion',
})
export class OptionGroupCompletionPipe implements PipeTransform {
  transform(optionGroup: OptionGroup, selectedOptions: Array<Option | OrderItemModifier>): boolean {
    const selectedChildren = selectedOptions.filter(option => optionGroup.options.find(gOption => gOption.optionID === option.optionID));
    return !!(
      (!optionGroup.mandatorySelection && !optionGroup.minRequired) ||
      (optionGroup.mandatorySelection && !optionGroup.minRequired && selectedChildren.length === 1) ||
      (optionGroup.minRequired && selectedChildren.length >= Number(optionGroup.minRequired))
    );
  }
}
