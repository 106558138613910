/// <reference types="@dineengine/capacitor-olo-pay" />

import { CapacitorConfig } from '@capacitor/cli';
import { KeyboardResize } from '@capacitor/keyboard';

const config: CapacitorConfig = {
  appId: 'com.chepri.chepriiodevtest',
  appName: 'Chepri Dev Test',
  webDir: 'www',
  overrideUserAgent: 'dineengine/v1/devtest',
  plugins: {
    CapacitorUpdater: {
      updateUrl: 'https://oxupaazetqlhuuzzohwy.functions.supabase.co/updates',
      statsUrl: 'https://oxupaazetqlhuuzzohwy.functions.supabase.co/stats',
      autoUpdate: false,
    },
    OloPay: {
      googlePayProductionEnvironment: false,
      merchantID: 'merchant.com.chepri.chepriiodevtest',
      companyLabel: 'Chepri',
      merchantName: 'Chepri',
      productionEnvironment: false,
    },
    SplashScreen: {
      launchShowDuration: 30000,
      launchAutoHide: true,
      // @ts-ignore
      launchFadeInDuration: 0,
      backgroundColor: '#ffffffff', // This MUST match the background color of the LaunchScreen
      androidSplashResourceName: 'splash_screen',
      androidScaleType: 'CENTER',
      showSpinner: false,
      androidSpinnerStyle: 'small',
      iosSpinnerStyle: 'large',
      spinnerColor: '#999999',
      splashFullScreen: false,
      splashImmersive: false,
      useDialog: false,
      layoutName: 'splash',
    },
    Keyboard: {
      resize: KeyboardResize.Body,
    },
    PushNotifications: {
      presentationOptions: ['sound', 'alert'],
    },
  },
  server: {
    hostname: 'default.ux.stage.dineengine.io',
    iosScheme: 'https',
    androidScheme: 'https',
  },
  ios: {
    scrollEnabled: true,
  },
  cordova: {},
};

export default config;
