import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Product } from 'src/interfaces/product.interface';

@Component({
  selector: 'app-mobile-category-card',
  templateUrl: './mobile-category-card.component.html',
  styleUrls: ['./mobile-category-card.component.scss']
})
export class MobileCategoryCardComponent implements OnInit {

  @Input() product: Product;

  @Output() clickedProduct = new EventEmitter<any>();

  ngOnInit() {
  }

  productClicked() {
    this.clickedProduct.emit();
  }

}
