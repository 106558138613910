import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '../../../../interfaces/order.interface';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';

@Component({
  selector: 'app-navbar-logo',
  templateUrl: './navbar-logo.component.html',
  styleUrls: ['navbar-logo.component.scss'],
})
export class NavbarLogoComponent {
  @Input() order: Order;
  @Input() branding: Branding;
  @Input() fixedNavbarWidth: boolean;
  @Output() homeClicked = new EventEmitter<void>();

  onHomeClicked() {
    this.homeClicked.emit();
  }
}
