import {Injectable} from '@angular/core';
import {DirectusService} from '../vendors/directus/directus.service';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Branding} from '../vendors/directus/interfaces/branding.interface';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {

  @Select(state => state.app.branding) branding$: Observable<Branding>;

  private elementId = 'favicons-service-injected-node';

  constructor(
      private directus: DirectusService,
  ) {

    this.branding$.pipe(filter(b => b !== null)).subscribe(branding => {
      if (branding) {
        if (branding && branding.favicon && branding.favicon.data && branding.favicon.data.full_url) {
          const href = branding.favicon.data.full_url;
          const type = branding.favicon.type;
          this.setFavicon(href, type);
        } else {
          console.log('no branding');
        }
      } else {
        console.log('no branding');
      }
    });
  }

  // Inject the favicon element into the document header.
  private setFavicon(href: string, type: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }
}
