<ion-content scrollEvents="true" class="light-background controls-section">
  <div class="d-flex flex-column h-100">
    <div class="border-bottom shadow" style="flex: 0 1 auto; z-index: 1;">
      <div class="p-3 bg-white">
        <div class="d-flex flex-column w-100">
          <ng-container *ngIf="displayUser && displayUser.firstName">
            <div class="user-text header-font font-size-subheading-2 w-100 pb-2">
              {{ 'Hello, ' + displayUser.firstName }}
            </div>
          </ng-container>
          <app-locations-search class="w-100 m-0"
                                [guestDeliveryAddress]="guestDeliveryAddress">
          </app-locations-search>
        </div>
      </div>
    </div>

    <div class="bg-light" style="flex: 1 1 auto; overflow: auto;" (scroll)="onScroll()">
      <ion-grid class="bg-light locations-list px-3" [hidden]="showListView">
        <ng-container *ngIf="!locations.findingLocations else findingLocationsText">
          <ng-container *ngIf="locations.isLoading === false; else loadingLocations">
            <ng-container *ngIf="sortedLocations && sortedLocations.length > 0; else emptyList">
              <div class="d-flex flex-column flex-wrap py-3">
                <ng-container *ngIf="savedUserLocations | savedPickupLocationFilter as savedPickupLocations">
                  <ng-container *ngIf="savedPickupLocations.length">
                    <h4 class="header-font fw-bold">Recent Locations</h4>
                    <ng-container *ngFor="let location of savedPickupLocations">

                      <app-desktop-location-card [locationID]="location.location.locationID"
                                                 [isCurrentLocation]="selectedLocation && location.location.locationID === selectedLocation.locationID"
                                                 (orderNowButtonClick)="orderNowButtonClicked($event)"
                                                 (locationInfoButtonClick)="locationInfoButtonClicked(location.location)"
                                                 pageFrom="locations"
                                                 [editHandoff]="editHandoff" [locationLoading]="locations.locationLoading"
                                                 *ngIf="location.location.isPrivate === false"
                                                 (leftCard)="listCardLeft()" [cardEmphasis]="cardEmphasis" class="mb-3">
                      </app-desktop-location-card>
                    </ng-container>
                    <h4 class="header-font fw-bold">Other Locations</h4>
                  </ng-container>
                </ng-container>


                <ng-container *ngFor="let location of shownLocations; index as i; first as first; last as last">
                  <ng-container *ngIf="!last">
                    <app-desktop-location-card [locationID]="location.locationID"
                                               [isCurrentLocation]="selectedLocation && location.locationID === selectedLocation.locationID"
                                               (orderNowButtonClick)="orderNowButtonClicked($event)"
                                               (locationInfoButtonClick)="locationInfoButtonClicked(location)"
                                               pageFrom="locations"
                                               [editHandoff]="editHandoff" [locationLoading]="locations.locationLoading"
                                               *ngIf="location.isPrivate === false" (hoveredIndex)="cardHovered(i)"
                                               (leftCard)="listCardLeft()" [cardEmphasis]="cardEmphasis" class="mb-3">
                    </app-desktop-location-card>
                  </ng-container>
                  <ng-container *ngIf="last">
                    <div #lastLocation>
                      <app-desktop-location-card [locationID]="location.locationID"
                                                 [isCurrentLocation]="selectedLocation && location.locationID === selectedLocation.locationID"
                                                 (orderNowButtonClick)="orderNowButtonClicked($event)"
                                                 (locationInfoButtonClick)="locationInfoButtonClicked(location)"
                                                 pageFrom="locations"
                                                 [editHandoff]="editHandoff"
                                                 [locationLoading]="locations.locationLoading"
                                                 *ngIf="location.isPrivate === false" [cardEmphasis]="cardEmphasis">
                      </app-desktop-location-card>
                    </div>
                  </ng-container>
                </ng-container>

              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ion-grid>
    </div>
  </div>
</ion-content>

<ng-template #loadingLocations>
  <div class="de-btn-loader-secondary my-5"></div>
</ng-template>

<ng-template #findingLocationsText>
  <div class="w-100 border bg-white text-center p-2 my-3 de-btn-radius">Finding Locations...</div>
</ng-template>

<ng-template #noLocationsNear>
  <div class="alert alert-info text-center de-btn-radius">
    {{ locations.infoMessage }}
  </div>
</ng-template>

<ng-template #emptyList>
  <section class="empty-list-container h-100">
    <div class="empty-list">
      <ng-container *ngIf="branding.favicon">
        <img alt="Empty Location List Image" class="empty-list-image"
             [src]="branding.favicon.data.full_url" />
      </ng-container>
      <h3 class="list-head fw-bold m-0 header-font text-center">Use Location Search</h3>
      <p class="text-center mb-2">{{ locations.infoMessage }}</p>
    </div>
  </section>
</ng-template>
