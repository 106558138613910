import { Component, ViewChild, Input } from '@angular/core';
import { ProfileComponent } from '../../base/profile.component';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { Location } from '../../../../../../interfaces/location.interface';
import { SendPasswordChangeRequest, SendPasswordResetRequest } from '../../../../../../store/actions/user.actions';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../../../store/state.model';
import { UserField } from '../../../../../../interfaces/user-field';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'app-mobile-edit-page',
  templateUrl: './mobile-edit-page.component.html',
  styleUrls: ['./mobile-edit-page.component.scss'],
})
export class MobileEditPageComponent extends ProfileComponent {
  model: any;
  @Input() isIncompleteProfile = false;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  formatter = (result: Location) => result.name;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        return !term
          ? this.loyaltyLocations
          : this.loyaltyLocations.filter(location => location.name.toLowerCase().includes(term.toLowerCase()));
      })
    );
  };

  forgotPassword() {
    this.store.dispatch(new SendPasswordChangeRequest(this.username.value)).subscribe(() => {
      this.toast.success('Password reset email sent');
    });
  }
}
