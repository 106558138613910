import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { HistoryEvent } from '../../../../interfaces/history-event.interface';
import { PurchaseableReward } from '../../../../interfaces/purchaseable-reward.interface';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { RewardsBalances } from '../../../../interfaces/rewards-balances.interface';
import { GlobalStateModel } from '../../../../store/state.model';
import { ThemeColor } from '../../../../vendors/directus/interfaces/theme-color.interface';
import { NavigationService } from '@modules/navigation/services';
import {
  AccountActivityModalComponent,
  CompleteRewardBalancesModalComponent,
  PurchaseRewardsModalComponent,
} from '@modules/loyalty/components';
import { ModalController } from '@ionic/angular';
import { User } from '../../../../interfaces/user.interface';
import { PaytronixConfiguration } from '../../../../vendors/directus/interfaces/paytronix-configuration.interface';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-loyalty-header',
  templateUrl: './loyalty-header.component.html',
  styleUrls: ['loyalty-header.component.scss'],
})
export class LoyaltyHeaderComponent implements OnInit {
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.activity) activity$: Observable<HistoryEvent[]>;
  @Select(state => state.user.purchaseableRewards)
  purchaseableRewards$: Observable<PurchaseableReward[]>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select(state => state.user.rewardPoints)
  rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select((state: GlobalStateModel) => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Select((state: GlobalStateModel) => state.user.inboxMessages)
  inboxMessages$: Observable<InboxMessage[]>;

  private subs: Subscription[] = [];

  editProfileRoute = this.navigation.getEditProfilePageSlug();
  savedCardsRoute = this.navigation.getSavedCardsPageSlug();
  profilePageRoute = this.navigation.getProfilePageSlug();
  inboxRoute = this.navigation.getInboxPageSlug();

  showTiers = false;

  constructor(
    private navigation: NavigationService,
    private modal: ModalController,
    private directus: DirectusService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.directus
        .getPaytronixSettings()
        .subscribe((settings: PaytronixConfiguration) => {
          this.showTiers = settings.show_tier_progress;
        })
    );
  }

  openAccountActivity() {
    this.modal
      .create({
        component: AccountActivityModalComponent,
      })
      .then(modal => modal.present());
  }

  openPurchaseRewards() {
    this.modal
      .create({
        component: PurchaseRewardsModalComponent,
      })
      .then(modal => modal.present());
  }

  openBalances() {
    this.modal
      .create({
        component: CompleteRewardBalancesModalComponent,
        animated: true,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }
}
