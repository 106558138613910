import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {HistoryEvent} from '../../../../interfaces/history-event.interface';

@Component({
    selector: 'app-account-activity-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './account-activity-modal.component.html',
    styleUrls: ['account-activity-modal.component.scss'],
})
export class AccountActivityModalComponent implements OnInit {
    @Select(state => state.user.activity) activity$: Observable<HistoryEvent[]>;


    constructor(
        private modalController: ModalController,
    ) {}
    ngOnInit() {}

    close() {
        this.modalController.dismiss()
    }
}
