import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Capacitor } from '@capacitor/core';
import { Radar } from 'capacitor-radar';
import { PickupTrip } from '@common/models';
import { RadarWebIntegrationService } from '@common/services/radar-web-integration.service';
import { App, AppInfo } from '@capacitor/app';
import moment from 'moment-timezone';
import { ToastService } from '../../../services/toast.service';

@Injectable()
export class CapacitorIntegrationService {
  constructor(
    private radarWeb: RadarWebIntegrationService,
    private toast: ToastService
  ) {
    Radar.requestLocationPermissions({ background: true });
    Radar.addListener('events', result => {
      const { events, user } = result;
      for (let i = 0; i < events.length; i++) {
        if (events[i].type == 'user.exited_geofence' && user.trip) {
          if (events[i].geofence.externalId == user.trip.destinationGeofenceExternalId) {
            // complete the trip
            Radar.completeTrip();

            //stop trip tracking
            Radar.stopTracking();
          }
        }
      }
    });
  }

  getCapacitorIntegration$(): Observable<{}> {
    return of({});
  }

  // Radar.io

  initializeRadar(publishableKey) {
    if (Capacitor.getPlatform() !== 'web') {
      // This happens in app
    } else {
      this.radarWeb.initializeRadar(publishableKey);
    }
  }

  setRadarUserID(userId: string) {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.setUserId({ userId });
    } else {
      this.radarWeb.setRadarUserID(userId);
    }
  }

  setRadarUserMetaData(metadata: any) {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.setMetadata({ metadata });
    } else {
      this.radarWeb.setRadarUserMetaData(metadata);
    }
  }

  setRadarUserDescription(description: string) {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.setDescription({ description });
    } else {
      this.radarWeb.setRadarUserDescription(description);
    }
  }

  getLocationPermissionsStatus() {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.getLocationPermissionsStatus().then(result => {
        // console.log(result);
      });
    } else {
      // Doesn't exist?
    }
  }

  requestLocationPermissions() {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.requestLocationPermissions({ background: true });
    } else {
      // Background location permissions don't exist on web
    }
  }

  requestLocationPermissionsOnce() {
    if (Capacitor.getPlatform() !== 'web') {
      Radar.trackOnce()
        .then(result => {
          // console.log(result.location, result.events, result.user.geofences);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.radarWeb.requestLocationPermissionsOnce();
    }
  }

  startBackgroundLocationTracking(trackingType: 'efficient' | 'responsive' | 'continuous') {
    if (Capacitor.getPlatform() !== 'web') {
      switch (trackingType) {
        case 'efficient':
          Radar.startTrackingEfficient();
          break;
        case 'responsive':
          Radar.startTrackingResponsive();
          break;
        case 'continuous':
          Radar.startTrackingContinuous();
          break;
        default:
          break;
      }
    } else {
      // Background location permissions don't exist on web
    }
  }

  async startCarTrip(pickupTrip: PickupTrip) {
    if (Capacitor.getPlatform() !== 'web') {
      try {
        const info: AppInfo = await App.getInfo();
        Radar.setForegroundServiceOptions({
          options: {
            text: `${pickupTrip.brandName} Order Tracking`,
            title: `${pickupTrip.brandName} Pickup`,
            updatesOnly: false,
            importance: 2,
            activity: `${info.id}.MainActivity`, // idk
          },
        });

        const tripResult = await Radar.startTrip({
          options: {
            externalId: pickupTrip.orderID,
            destinationGeofenceExternalId: pickupTrip.externalID,
            destinationGeofenceTag: pickupTrip.tagName,
            mode: 'car',
            scheduledArrivalAt: moment(pickupTrip.date).add(10, 'minutes').toDate(),
          },
        });

        Radar.addListener('location', result => {
          if (result?.user?.trip === null) {
            Radar.stopTracking();
          }
        });

        if (tripResult.status === 'SUCCESS') {
          Radar.startTrackingContinuous();
          return tripResult.trip.externalId;
        } else {
          throw new Error('Failed to start trip');
        }
      } catch (error) {
        // handle any errors that occurred during the async operations
        this.toast.danger(
          'We were unable to start tracking your trip. When you get to the store, please check in from your order confirmation.'
        );
        console.error('Error starting car trip:', error);
        return pickupTrip.orderID;
      }
    } else {
      // Trip tracking doesn't exist on web
      return pickupTrip.orderID;
    }
  }

  stopTracking() {
    Radar.completeTrip().then(result => {
      // console.log(result.status);
    });
    Radar.stopTracking();
  }
}
