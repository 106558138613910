import { GetPointRewardInfoResponse } from './interfaces/get-point-reward-info-response.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { GetOfferRewardInfoResponse, Reward, Offer } from './interfaces/get-offer-reward-info-response.interface';
import { Offer as DineEngineOffer } from '../../interfaces/offer.interface';
import { Reward as DineEngineReward } from '../../interfaces/reward.interface';
import { LoyaltyActivity } from './interfaces/get-activity-response.interface';
import { HistoryEvent } from '../../interfaces/history-event.interface';
import { Injectable } from '@angular/core';
import { GetMemberResponse } from './interfaces/get-member-response.interface';
import { User } from '../../interfaces/user.interface';
import moment from 'moment-timezone';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';

@Injectable({
  providedIn: 'root',
})
export class PersonicaMappingService {
  toRewardsBalances(balances: GetPointRewardInfoResponse): RewardsBalances {
    return {
      points: balances.earnedPoints,
      bankedRewards: null,
      pointsThreshold: balances.earnedPoints + balances.pointsToNextReward,
      rewardAmounts: null,
      storedValueCents: null,
    } as RewardsBalances;
  }

  toOffer(offer: Offer): DineEngineOffer {
    return {
      name: offer.offerPublicName,
      description: offer.offerPublicDescription,
      id: offer.offerId,
      couponCode: offer.couponCode,
      expireDate: offer.offerExpirationDate,
      redeemOnline: offer.onlineinStore === '1' || offer.onlineinStore === '3',
      redeemInStore: offer.onlineinStore === '1' || offer.onlineinStore === '2',
    } as DineEngineOffer;
  }

  toHistoryEvent(activity: LoyaltyActivity): HistoryEvent {
    return {
      type: activity.type,
      title: activity.publicDescription,
      description: activity.desc,
      pointsEarned: activity.pointsEarned,
      dollarsEarned: null,
      dollarsOff: Number(activity.offerDiscount),
      timeStamp: activity.eventDate,
      externalID: activity.offerId,
    } as HistoryEvent;
  }

  toReward(reward: Reward): DineEngineReward {
    return {
      rewardID: reward.offerId,
      membershipID: null,
      pointCost: null,
      name: reward.offerPublicName,
      description: reward.offerPublicDescription,
      isApplied: false,
      externalRef: String(reward.offerId),
      finePrint: null,
      imageURL: null,
      redeemInStore: reward.onlineinStore === '1' || reward.onlineinStore === '2',
      redeemOnline: reward.onlineinStore === '1' || reward.onlineinStore === '3',
      redemptionInfo: {
        redemptionCode: reward.couponCode,
        redemptionMessage: reward.offerPublicDescription,
        expiryDate: reward.offerExpirationDate,
      },
      isDollarReward: false,
    } as DineEngineReward;
  }

  toLoyaltyReward(reward: Reward): LoyaltyReward {
    return <LoyaltyReward>{
      name: reward.offerPublicName,
      description: reward.offerPublicDescription,
      pointCost: null,
      expDate: reward.offerExpirationDate,
      externalReferenceID: null,
    };
  }

  memberResponseToUser(member: GetMemberResponse): User {
    return {
      userID: String(member.memberId),
      userAsBarcode: String(member.memberId),
      userAsQrCode: String(member.memberId),
      orderingToken: null,
      orderingTokenProvider: null,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.emailAddress,
      birthday: moment(member.dOB, 'MM/DD/YYYY').toDate(),
      phoneNumber: member.phoneNumber,
      addresses: null,
      emailOptIn: member.emailOptIn,
      sMSOptIn: member.sMSOptIn,
      loyaltyOptIn: member.loyaltyOptIn,
      pushOptIn: member.pushOptIn,
      isGuest: false,
      company: null,
      cardNumber: null,
      providerSpecificFields: member,
    } as User;
  }
}
