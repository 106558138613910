import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CustomPage, CustomPageChildNavigation } from '../../../../vendors/directus/interfaces/custom-page.interface';
import { User } from '../../../../interfaces/user.interface';

@Component({
  selector: 'app-page-link',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './page-link.component.html',
  styleUrls: ['page-link.component.scss'],
})
export class PageLinkComponent implements OnInit {
  @Input() page: any;
  @Input() user: any;
  @Input() isActive = false;
  @Output() pageClicked = new EventEmitter<void>();
  @Output() childPageClicked = new EventEmitter<CustomPageChildNavigation>();

  public uniqueId: string;

  ngOnInit() {
    this.uniqueId = this.generateUniqueId();
  }

  /**
   * Generates a unique ID for ARIA attributes.
   * You can use a library like uuid or any other method to ensure uniqueness.
   */
  generateUniqueId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  /**
   * Determines if the page should be displayed based on show_on_navbar and login requirements.
   */
  shouldDisplayPage(): boolean {
    return this.page.show_on_navbar && (!this.page.require_login || (this.page.require_login && !this.user?.isGuest));
  }

  /**
   * Checks if the page has child navigation.
   */
  hasChildren(): boolean {
    return this.page.child_navigation && this.page.child_navigation.length > 0;
  }

  /**
   * Emits an event when a simple page link is clicked.
   */
  onPageClicked(): void {
    this.pageClicked.emit();
  }

  /**
   * Emits an event when a child page link is clicked.
   * @param child The child page object.
   */
  onChildPageClicked(child: CustomPageChildNavigation): void {
    this.childPageClicked.emit(child);
  }

  /**
   * Toggles the dropdown open or closed.
   * @param dropdown The NgbDropdown instance.
   */
  toggleDropdown(dropdown: NgbDropdown): void {
    dropdown.toggle();
  }

  /**
   * Opens the dropdown.
   * @param dropdown The NgbDropdown instance.
   */
  openDropdown(dropdown: NgbDropdown): void {
    if (!dropdown.isOpen) {
      dropdown.open();
    }
  }

  /**
   * Closes the dropdown.
   * @param dropdown The NgbDropdown instance.
   */
  closeDropdown(dropdown: NgbDropdown): void {
    if (dropdown.isOpen) {
      dropdown.close();
    }
  }
}
