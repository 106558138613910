import moment from 'moment-timezone';

export interface DateTimeInterface {
  moment: moment.Moment;
  addMinutes(minutes: number): DateTime;
  compare(dateTime: DateTime): number;
  roundTimeToNearest(base: number): DateTime;
}

export class DateTime implements DateTimeInterface {
  moment: moment.Moment;

  /**
   * MM/DD/YYYY format, e.g. 01/01/2020
   */
  get shortDateString() {
    return this.moment.format('MM/DD/YYYY');
  }
  /**
   * MMM Do, YYYY format, e.g. Jan 1st, 2020
   */
  get longDateString() {
    return this.moment.format('MMM Do, YYYY');
  }
  /**
   * YYYYMMDD HH:mm format, e.g. 20200101 00:00
   */
  get oloDateTime() {
    return this.moment.format('YYYYMMDD HH:mm');
  }
  /**
   * YYYY-MM-DD HH:mm:ss format, e.g. 2020-01-01 00:00:00
   */
  get directusDateTime() {
    return this.moment.format('YYYY-MM-DD HH:mm:ss');
  }
  /**
   * YYYY-MM-DD HH:mm:ss format, e.g. 2020-01-01 00:00:00
   */
  get javascriptDateTime() {
    return this.moment.toDate();
  }
  /**
   * Object similar to Olo model UpdateBasketTimeWantedPost, but without ismanualfire
   */
  get oloTimeWanted() {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
      hour: this.hour,
      minute: this.min,
    };
  }
  /**
   * hh:mm A format, e.g. 12:00AM
   */
  get time() {
    return this.moment.format('h:mm A');
  }
  /**
   * HH:mm format, e.g. 00:00
   */
  get time24() {
    return this.moment.format('HH:mm');
  }
  /**
   * YYYYY format, e.g. 2020
   */
  get year() {
    return this.moment.format('YYYYY');
  }
  /**
   * MM format, e.g. 01
   */
  get month() {
    return this.moment.format('MM');
  }
  /**
   * DD format, e.g. 01
   */
  get day() {
    return this.moment.format('DD');
  }
  /**
   * DD format, e.g. 01
   */
  get date() {
    return this.moment.format('MM/DD');
  }
  /**
   * HH format, number of hours out of 24
   */
  get hour() {
    return this.moment.format('HH');
  }
  /**
   * mm format, number of minutes out of 60
   */
  get min() {
    return this.moment.format('mm');
  }

  static fromOlo(oloString: string) {
    return new this(moment(oloString, 'YYYYMMDD HH:mm'));
  }

  static fromDirectus(directusString: string) {
    return new this(moment(directusString, 'YYYY-MM-DD HH:mm:ss'));
  }

  static fromDirectusTime(directusString: string) {
    return new this(moment(directusString, 'HH:mm:ss'));
  }

  static fromJavascriptDate(javascriptDate: Date) {
    return new this(moment(javascriptDate));
  }

  /**
   *
   * @param date YYYYMMDD
   * @param minutes time of day represented in total minutes
   * @param time HH:MM time of day as 24 clock
   */
  constructor(m: moment.Moment) {
    this.moment = m;
  }

  /**
   * Adds specified number of minutes to a formatted time.  Meant for small amounts of time, i.e. less than an hour
   * @param minutes Number of minutes to add to the time
   *
   * @returns new formatted time, HH:mm format
   */
  addMinutes(minutes: number): DateTime {
    return new DateTime(this.moment.add(minutes, 'm'));
  }

  /**
   * Returns 1 if this is greater than comparator, -1 if this is less than comparator, and 0 if equal
   * @param d object to compare to this
   */
  compare(d: DateTime): number {
    // TODO - Might not be needed bc of moment
    return;
  }

  /**
   * Rounds the DateTime up to the next nearest interval of the specified base
   * @param base number which minutes will be rounded to (e.g. 15, 30)
   */
  roundTimeToNearest(base: number): DateTime {
    const m = this.moment.clone();
    // return new DateTime(m.ceil(base, 'minutes'));
    // Find remainder of minutes / index, add remainder - return new DateTime
    return new DateTime(m.add(base - (m.minute() % base), 'minutes'));
  }
}
