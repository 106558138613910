import {WebSaleProgramType} from './sale-config-for-account.interface';

export interface ExecuteSaleForAccountRequest {
  /**
   * Paytronix-assigned merchant ID to perform the operation in.
   */
  merchantId: number;
  programType: WebSaleProgramType;
  /**
   * The card of the user
   */
  printedCardNumber: string;
  billingContact: Contact;
  billingAddress: Address;
  /**
   * If true, then the shippingContact and shippingAddress are optional and will be defaulted from billingContact and billingAddress.
   */
  shippingSameAsBilling: boolean;
  shippingContact?: Contact;
  shippingAddress?: Address;
  paymentMethod: PaymentMethod;
  /**
   * One of the codes returned by the GET sale/saleConfig.json call if required by the program.
   */
  shippingOptionCode?: string;
  /**
   * This is the value returned by a previous POST sale/calculatePrice.json call. It is used to confirm that none of the prices and discounts have changed since the user started the sale workflow.
   */
  totalPrice: number;
  /**
   * If true and relevant to the program, then the items will be in a gift box.
   */
  hasGiftBox?: boolean;
  /**
   * If required by the program, then this will be the store to which newly created cards will be associated.
   */
  activationStoreCode?: string;
  orderItemGroups: OrderItemGroup[];
  /**
   * The client IP of the person placing the order.
   */
  originatingIP?: string;
  /**
   * The IP address of the integration making the request.
   */
  callingIP?: string;
}

export interface ExecuteSaleForAccountResponse {
  result: 'success' | 'failure';
  order: SaleOrder;
}

interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  externalAccount: ExternalAccount;
}

interface ExternalAccount {
  /**
   * Paytronix-assigned code. Contact Paytronix to understand what the possible values are.
   */
  integration: string;
  /**
   * The external code of this user.
   */
  accountCode: string;
  /**
   * Future use.
   */
  accessToken: string;
}

interface Address {
  street: string;
  street2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

interface PaymentMethod {
  /**
   * Paytronix-assigned code
   */
  paymentMethodType: PaymentMethodType;
  /**
   * Only required if paymentMethod is SAVED_CC. This code was returned by the Payment Service endpoint savedCard.
   */
  savedCardCode?: string;
  /**
   * Only required if paymentMethod is CC. The name on the credit card.
   */
  cardholderName?: string;
  /**
   * Paytronix-assigned code
   */
  cardType: CardType;
  /**
   * Only required if paymentMethod is CC. The credit card number (no spaces or punctuation).
   */
  cardNumber?: string;
  /**
   * Must be passed along with savedCardCode when paymentMethod is SAVED_CC
   */
  lastFour?: string;
  /**
   * Only required if paymentMethod is CC. The month of the expiration (0 = Jan, ..., 11 = Dec).
   */
  expirationMonth?: number;
  /**
   * Only required if paymentMethod is CC. The year of the expiration (4 digits like: 2019)
   */
  expirationYear?: number;
  /**
   * Only required if paymentMethod is CC. Also known as the CVV, CVV2, CVVC, or CSC code on the back of the credit card
   */
  cardSecurityCode?: string;
  /**
   * Only required if paymentMethod is CC. Save this credit card for future use as the default credit card for this account. Default: false
   */
  saveCard?: boolean;
}

export enum PaymentMethodType {
  CREDIT_CARD = 'CC',
  ENCRYTPED_CREDIT_CARD = 'ECRYPTED_CC',
  SAVED_CREDIT_CARD = 'SAVED_CC',
  STORED_VALUE = 'SV',
  POINTS = 'POINTS',
  SPREEDLY_TOKEN = 'SPREEDLY_TOKEN',
  SAVED_SPREEDLY_TOKEN = 'SAVED_SPREEDLY_TOKEN'
}

export enum CardType {
  AMERICAN_EXPRESS = 'AMEX',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA'
}

export interface OrderItemGroup {
  /**
   * The unique number that identify the order item group within the same order. This should be a sequential number starts with 1.
   */
  groupNumber: number;
  recipient: Contact;
  /**
   * If the shipping date is not now, then this is the date in the future to ship the items.
   */
  deliveryDate: Date;
  orderItems: OrderItem[];
  /**
   * Name of gift sender (used with EGIFT program).
   */
  personalizedFrom: string;
  /**
   * Name of gift recipient (used with EGIFT program).
   */
  personalizedTo: string;
  /**
   * Personalized message associated with gift (used with EGIFT program).
   */
  personalizedMessage: string;
}

export interface OrderItem {
  /**
   * The unique number that identify the item within the same order. This should be a sequential number starts with 1.
   */
  itemNumber: number;
  /**
   * The unique code of the item.
   */
  code: string;
  /**
   * If this sale item has styles, then this is the style code to use for this item, as returned by the GET sale/saleConfig.json call endpoint. This element is required when programType is EGIFT
   */
  styleCode: string;
  /**
   * The quantity of these items
   */
  quantity: number;
  /**
   * If the price of this item (from the ItemConfig) did not have a predefined value (which means the value was entered or selected from the priceList by the user), then this is the value entered/selected.
   */
  value?: number;
}

export interface SaleOrder {
  /**
   * The order number for this sale.
   */
  orderNumber: number;
  /**
   * This is the value actually charged to user.
   */
  totalPrice: number;
  /**
   * List of item groups sold
   */
  saleGroups: SaleOrderGroup[];
  discount: Discount;
  /**
   * List of promotional items granted to the buyer for this sale.
   */
  promotionItems: PromotionItem[];
  /**
   * Number of promotion items granted to the buy for this sale.
   */
  promotionItemCount: number;
}

interface SaleOrderGroup {
  /**
   * Sale order group number matching the groupNumber in one of the orderItemGroups in the request.
   */
  groupNumber: number;
  /**
   * List of items in this sale group.
   */
  saleItems: SaleOrderItem[];
}

interface SaleOrderItem {
  /**
   * A unique number that identifies the order item within the same group. This is the echo of itemNumber in OrderItem from the request.
   */
  itemNumber: number;
  /**
   * This is the total price for this item after, if applies, discount
   */
  price: number;
  discount: Discount;
  /**
   * The message to be sent to the recipient by the client software. Currently used so the client software can implement Facebook delivery
   */
  recipientMessage: string;
  /**
   * The URL through which one can retrieve the detailed information of purchased cards.
   */
  deliveryUrl: string;
}

interface Discount {
  /**
   * The unique code of the item. If this is missing, then this discount applies to the entire order.
   */
  code: string;
  /**
   * The amount deducted from the original price of the item/order.
   */
  amount: number;
  /**
   * The final price of the item/order (after deducting amount).
   */
  price: number;
  /**
   * The description of the discount
   */
  description: string;
}

interface PromotionItem {
  /**
   * The unique code of the item(s) given by the promotion.
   */
  itemCode: string;
  /**
   * The label of the item given by the promotion.
   */
  itemLabel: string;
  /**
   * If available, a URL reference to retrieve the (full size) image of the item(s) given by the promotion.
   */
  imageUrl: string;
  /**
   * The quantity of the item(s) given.
   */
  quantity: number;
  /**
   * The price of each item given. Typically, promotion items are free so the price will be zero.
   */
  price: number;
  /**
   * The label of the promotion/reason for giving the item(s).
   */
  promotionLabel: string;
  /**
   * The description of the promotion/reason for giving the item(s).
   */
  description: string;
}
