<ion-content>
  <ng-container *ngIf="splashScreen$ | async as splashscreen">
    <div class="d-flex flex-column justify-content-end align-items-center gap-2 h-100 w-100"
         [style.background]="splashscreen.icon?.data?.full_url | splashscreenBG: splashscreen?.background_color">
      <ng-container [ngSwitch]="currentState">
        <ng-container *ngSwitchCase="downloadState.CHECKING">
          <small class="update-text" [style.color]="splashscreen.theme_color">{{checkingCopy}}</small>
        </ng-container>
        <ng-container *ngSwitchCase="downloadState.DOWNLOADING">
          <small class="update-text" [style.color]="splashscreen.theme_color">{{downloadCopy}}</small>
        </ng-container>
        <ng-container *ngSwitchCase="downloadState.FOUND">
          <small class="update-text" [style.color]="splashscreen.theme_color">{{newVersionFoundCopy}}</small>
        </ng-container>
      </ng-container>
      <div>
        <ng-container *ngTemplateOutlet="iosLoader"></ng-container>
      </div>
    </div>
  </ng-container>
</ion-content>


<ng-template #iosLoader>
  <div class="spinner">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</ng-template>
