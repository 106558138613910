import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NavigationService} from '@modules/navigation/services';
import {Store} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss']
})
export class CancelOrderComponent {
  @Input() error;

  orderName;

  constructor(
    public activeModal: NgbActiveModal,
    private navigation: NavigationService,
    private store: Store
  ) {}

  cancelOrderButtonClick() {
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order)
    this.activeModal.close('Close click');
    this.navigation.navigateToHomePage('tableside', order.location.locationID);
  }
}
