import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Select} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';
import {Observable} from 'rxjs';
import {NavigationService} from '@modules/navigation/services';

@Component({
    selector: 'app-complete-reward-balances-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './complete-reward-balances-modal.component.html',
    styleUrls: ['complete-reward-balances-modal.component.scss'],
})
export class CompleteRewardBalancesModalComponent implements OnInit {
    @Select((state: GlobalStateModel) => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;

    constructor(
        private modalController: ModalController,
        private navigation: NavigationService
    ) {}
    ngOnInit() {}

    close() {
        this.modalController.dismiss();
    }

    rewardsRoute() {
        this.modalController.dismiss();
        this.navigation.navigateToRewardsPage();
    }
}
