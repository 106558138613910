import { Component, Input } from '@angular/core';
import { NutritionInfo } from 'src/interfaces/nutrition-info.interface';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../../store/state.model';
import { Observable } from 'rxjs';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { ModeService } from '../../../../../services/mode.service';

@Component({
  selector: 'app-desktop-nutrition-card',
  templateUrl: './nutrition-card.component.html',
  styleUrls: ['./nutrition-card.component.scss'],
})
export class DesktopNutritionCardComponent {
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Input() nutritionInfo: NutritionInfo;

  constructor(protected mode: ModeService) {}
}
