import { Injectable } from '@angular/core';
import { RetrieveMemberResponse } from './interfaces/retrieve-member.interface';
import { User } from '../../interfaces/user.interface';
import moment from 'moment-timezone';
import { RetrieveMemberBalanceResponse, Reward } from './interfaces/retrieve-member-balance.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { RewardsBalanceAmount } from '../../interfaces/rewards-balance-amount.interface';
import { Reward as DEReward } from '../../interfaces/reward.interface';
import { DineEngineError } from '../../interfaces/dineengine-error.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SpendgoMappingService {
  constructor() {}

  spendgoErrorToDineEngineError(error: Error): DineEngineError {
    let msg = error.message;
    console.log(error);
    if (error instanceof HttpErrorResponse) {
      msg = error.error.message ? error.error.message : error.error.details;
    }
    return new DineEngineError(msg);
  }

  retrieveMemberResponseToUser(response: RetrieveMemberResponse): User {
    return {
      userID: response.spendgo_id,
      userAsBarcode: response.phone,
      userAsQrCode: response.phone,
      orderingToken: null,
      orderingTokenProvider: null,
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email,
      birthday: moment(response.dob, 'YYYY-MM-DD').toDate(),
      phoneNumber: response.phone,
      addresses: [],
      emailOptIn: response.email_opt_in,
      sMSOptIn: response.sms_opt_in,
      loyaltyOptIn: true,
      pushOptIn: null,
      isGuest: false,
      company: null,
      cardNumber: null,
      favoriteLocation: null,
      providerSpecificFields: response,
    };
  }

  retrieveMemberBalanceRequestToRewardsBalances(response: RetrieveMemberBalanceResponse): RewardsBalances {
    return {
      points: response.quantity,
      pointsUnit: response.units,
      bankedRewards: null,
      pointsThreshold: response.spend_threshold ? response.spend_threshold : null,
      rewardAmounts: response.rewards.map(reward => this.rewardToRewardsBalanceAmount(reward)),
      storedValueCents: null,
    };
  }

  retrieveMemberBalanceRequestRewardToDEReward(reward: Reward): DEReward {
    return {
      rewardID: null,
      membershipID: null,
      pointCost: reward.points ? reward.points : null,
      name: reward.label,
      description: null,
      isApplied: false,
      externalRef: null,
      finePrint: null,
      imageURL: null,
      redeemInStore: false,
      redeemOnline: false,
      redemptionInfo: {},
      isDollarReward: false,
      isCoupon: false,
      couponCode: null,
    };
  }

  private rewardToRewardsBalanceAmount(reward: Reward): RewardsBalanceAmount {
    return {
      amount: String(reward.quantity),
      type: String(reward.offer),
      description: reward.label,
      expDate: reward.next_expires_at ? moment(reward.next_expires_at).toDate() : null,
    };
  }
}
