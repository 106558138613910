import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../interfaces/product.interface';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OptionGroup } from '../../../../interfaces/option-group.interface';

@Component({
  selector: 'app-customize-product-summary',
  templateUrl: './customize-product-summary.component.html',
  styleUrls: ['customize-product-summary.component.scss'],
})
export class CustomizeProductSummaryComponent {
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Input() product: Product;
  @Input() optionGroups: OptionGroup[];
  @Input() quantity: number;
  @Input() adjustedPrice: number;
  @Input() isLoading = false;
  constructor() {}
}
