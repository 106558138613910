<div class="input-group d-block w-100">

  <form [formGroup]="billingInfo" class="mb-3">

    <!--        <ng-container *ngIf="flowType === 'GIFT_CARD'">-->
    <!--            <div class="custom-check pb-3">-->
    <!--                <input formControlName="sameAsShipping" class="custom-check-input" type="checkbox" value="" id="defaultCheck1">-->
    <!--                <label class="custom-check-label" for="defaultCheck1">-->
    <!--                    Use Shipping Address as Billing Address-->
    <!--                </label>-->
    <!--            </div>-->
    <!--        </ng-container>-->

    <!-- first name & last name -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="firstName" class="w-100">First Name*</label>
        <input type="text" autocomplete="given-name" class="form-control" id="firstName"
               formControlName="firstName" aria-describedby="firstName" placeholder="">
      </div>

      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="lastName" class="w-100">Last Name*</label>
        <input type="text" autocomplete="family-name" class="form-control" id="lastName"
               formControlName="lastName" aria-describedby="lastName" placeholder="">
      </div>
    </div>

    <!-- address & apartment/suite/etc -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="streetAddress" class="w-100">Street Address*</label>
        <input type="text" autocomplete="address-line1" class="form-control"
               formControlName="streetAddress" id="streetAddress" aria-describedby="streetAddress" placeholder="">
      </div>

      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="suiteNo" class="w-100">Apartment, Suite, Etc. (Optional)</label>
        <input type="text" autocomplete="address-line2" class="form-control"
               formControlName="suiteNumber" id="suiteNo" aria-describedby="suiteNo" placeholder="">
      </div>
    </div>

    <!-- city & state/province -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="cityName" class="w-100">City*</label>
        <input type="text" autocomplete="home city" class="form-control" id="cityName"
               formControlName="city" aria-describedby="cityName" placeholder="">
      </div>

      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="stateProvince" class="w-100">State/Province*</label>
        <select id="stateProvince" autocomplete="address-level1" name="state" (change)="changeState($event)"
                formControlName="state" class="form-control" aria-describedby="stateProvince">
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
    </div>

    <!-- country & zip code -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="countryCode" class="w-100">Country*</label>
        <select autocomplete="country" name="country" (change)="changeCountry($event)"
                formControlName="country" class="form-control" id="countryCode" aria-describedby="countryCode">
          <!--                    <option value="Canada">Canada</option>-->
          <!--                    <option value="Mexico">Mexico</option>-->
          <option value="US">United States</option>
          <!--                    <option value="United States Minor Outlying Islands">United States Minor-->
          <!--                        Outlying Islands</option>-->
        </select>
      </div>

      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="zipCode" class="w-100">ZIP Code*</label>
        <input type="text" autocomplete="postal-code" class="form-control" mask="00000"
               formControlName="zipCode" id="zipCode" aria-describedby="zipCode" placeholder="">
      </div>
    </div>

    <!-- phone number & email address -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2 mb-3 mb-md-0">
        <label for="phoneNumber" class="w-100">Phone Number*</label>
        <input type="tel" autocomplete="tel" class="form-control" name="phone"
               formControlName="phoneNumber" id="phoneNumber" aria-describedby="phoneNumber" placeholder="">
      </div>

      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2 mb-0">
        <label for="emailAddress" class="w-100">Email Address*</label>
        <input type="email" autocomplete="email" name="email" class="form-control"
               formControlName="email" id="emailAddress" aria-describedby="emailAddress" placeholder="">
      </div>
    </div>

  </form>

</div>

<ng-container *ngIf="flowType === 'EGIFT'">
  <app-add-to-cart
    [hideCartFunctionality]="hideCartFunctionality"
    [currencyCode]="currencyCode"
    [itemConfigured]="itemConfigured"
    [itemTotal]="itemTotal"
    (createCard)="createCardClicked($event)"
  ></app-add-to-cart>
</ng-container>
