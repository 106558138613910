import {Component, Input} from '@angular/core';
import {GroupOrder} from '../../../../interfaces/group-order.interface';
import {NavigationService} from '@modules/navigation/services';
import {Store} from '@ngxs/store';
import {CancelGroupOrder} from '../../../../store/actions/order.actions';
import {ModeService} from '../../../../services/mode.service';

@Component({
    selector: 'app-group-order-info',
    templateUrl: './group-order-info.component.html',
    styleUrls: ['group-order-info.component.scss'],
})
export class GroupOrderInfoComponent {
  @Input() groupOrder: GroupOrder;
  constructor(
    private store: Store,
    private navigation: NavigationService,
    public modeService: ModeService
  ) {}
  goToDetails() {
    this.navigation.navigateToUpdateGroupOrderPage(this.groupOrder.groupOrderID);
  }
  leaveGroupOrder() {
    this.store.dispatch(new CancelGroupOrder()).subscribe(() => {
      if (this.modeService.mode === 'tableside') {
        this.navigation.navigateToHomePage();
      } else {
        this.navigation.navigateToLocationsPage(true);
      }
    });
  }
}
