import { ChangeDetectionStrategy, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {GiftCardConfig, GiftCardOrder} from '@modules/gift-card/models';
import {GenericComponent} from '@modules/ecommerce/models';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {GiftCard} from '../../../../interfaces/giftcard.interface';
import {TransferStoredValueToLoyaltyAccount} from '../../../../store/actions/user.actions';

@Component({
  selector: 'app-balance-reload-order-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './balance-reload-order-summary.component.html',
  styleUrls: ['balance-reload-order-summary.component.scss'],
})
export class BalanceReloadOrderSummaryComponent implements OnInit, GenericComponent {
  @Select(state => state.giftCardOrder.saleConfig) saleConfig$: Observable<GiftCardConfig>;
  @Select(state => state.giftCardOrder.previousOrder) previousOrder$: Observable<GiftCardOrder>;
  @Select(state => state.giftCardOrder.confirmation) confirmation$: Observable<number>;
  @Select(state => state.giftCardOrder.newCard) newCard$: Observable<GiftCard>;
  @Input() flowType: string;
  @Input() hideCartFunctionality: boolean;
  @Input() set sectionComplete(val: boolean) {
    this.itemConfigured = val;
  }
  @Input() itemTotal = 0;
  @Output() sendItemTotal = new EventEmitter<number>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() passData = new EventEmitter<UntypedFormGroup>();
  @Output() createCard = new EventEmitter<number>();
  data = null;
  itemConfigured = false;
  previousOrder: GiftCardOrder = null;
  confirmation: number = null;
  newCard: GiftCard = null;
  cardValue = new UntypedFormGroup({
    value: new UntypedFormControl(0, Validators.required)
  });
  constructor(
    private store: Store
  ) {}
  ngOnInit() {
    this.saleConfig$.subscribe((saleConfig: GiftCardConfig) => {
      this.data = saleConfig;
    });
    this.cardValue.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.isValid.emit(this.cardValue.valid);
      if (this.cardValue.valid) {
        this.passData.emit(this.cardValue);
      }
    });
    this.previousOrder$.subscribe((previousOrder: GiftCardOrder) => {
      this.previousOrder = previousOrder;
    });
    this.confirmation$.subscribe((confirmation: number) => {
      this.confirmation = confirmation;
    });
    this.newCard$.subscribe((newCard) => {
      this.newCard = newCard;
      this.store.dispatch(new TransferStoredValueToLoyaltyAccount(newCard)).subscribe(() => {});
    });
  }
}
