export enum PaymentTypes {
    creditCard ,
    giftCard,
    cash,
    amex,
    dinersClub,
    discover,
    mastercard,
    visa,
    prepaid,
    savedCard,
    paypalExpress,
    authorizeNet,
    stripe,
    mollie,
    square,
    savedGiftCard,
    creditCardToken,
    loyaltyStoredValue,
    applePay,
    googlePay
}
