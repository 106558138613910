<form [formGroup]="searchForm">
  <div class="search-label" *ngIf="!mobile.isMobile">
    Search for a Pickup Location
  </div>

  <div class="input-group" style="height: 50px">
    <!--      Geolocation button-->
    <div
      class="input-group-prepend"
      *ngIf="locations.geolocationAllowed$ | async">
      <button
        class="btn btn-outline-secondary"
        [class.left-button-w-results]="showGeoDropdown"
        type="button"
        id="button-addon1"
        (click)="locations.getUserLocation()">
        <i
          class="bi"
          [class.bi-cursor]="!!!locations.userCoords"
          [class.bi-cursor-fill]="!!locations.userCoords"></i>
      </button>
    </div>

    <!--      Search field-->
    <ng-container *ngIf="locations.locationProvider === 'google'">
      <ng-container *ngIf="getTypeOfGoogle() !== 'undefined'">
        <input
          type="text"
          class="form-control cy-dineengine-searchbar"
          [class.rounded-0]="locations.geolocationAllowed$ | async"
          aria-label="Example text with button addon"
          aria-describedby="button-addon1"
          #autocomplete
          style="height: 50px"
          ngx-google-places-autocomplete
          (onAddressChange)="placeChanged($event)"
          formControlName="search"
          [placeholder]="
            locations.gettingGeolocation
              ? 'Getting your location...'
              : 'Search by City or ZIP'
          "
          (keyup.enter)="searchTextChanged()"
          [options]="searchOptions"
          (focus)="showGeoLocation()"
          (blur)="hideGeoLocation()"
          (input)="showGeoLocation()"
          (click)="fieldClicked()"
          [disabled]="locations.gettingGeolocation" />
      </ng-container>
    </ng-container>
    <ng-container *ngIf="locations.locationProvider !== 'google'">
      <input
        type="text"
        style="height: 50px"
        formControlName="search"
        placeholder="Search by City or ZIP"
        class="form-control cy-dineengine-searchbar"
        [class.rounded-0]="locations.geolocationAllowed$ | async"
        (keyup.enter)="searchTextChanged()"
        (focus)="showGeoLocation()"
        (blur)="hideGeoLocation()"
        (input)="showGeoLocation()"
        [placeholder]="
          locations.gettingGeolocation
            ? 'Getting your location...'
            : 'Search by City or ZIP'
        " />
    </ng-container>

    <!--      Search button-->
    <div class="input-group-append">
      <button
        class="btn btn-primary"
        [class.right-button-w-results]="showGeoDropdown"
        type="button"
        id="button-addon2"
        (click)="searchTextChanged()">
        <i class="bi bi-search"></i>
      </button>
    </div>
  </div>

  <ng-container *ngIf="locations.locationProvider !== 'google'">
    <ng-container *ngIf="!hasSelected">
      <div class="wrapper" *ngIf="results.length">
        <!--      Search results list-->
        <div class="list-group">
          <ng-container *ngFor="let result of results; index as i">
            <button
              (click)="resultSelected(result)"
              class="list-group-item list-group-item-action"
              [class.cy-first]="i === 0">
              {{ result.formattedAddress }}
            </button>
          </ng-container>
        </div>
        <!--      Provider tag-->
        <div *ngIf="results.length > 0" class="w-100 text-end">
          <small
            >Powered by ©
            <ng-container *ngIf="locations.locationProvider === 'osm'">
              <a href="https://www.openstreetmap.org/copyright" target="_blank"
                >OpenStreetMap</a
              >
              contributors
            </ng-container>
            <ng-container *ngIf="locations.locationProvider === 'chepri'">
              <a href="https://www.openstreetmap.org/copyright" target="_blank"
                >OpenStreetMap</a
              >
              contributors
            </ng-container>
            <ng-container *ngIf="locations.locationProvider === 'google-geo'"
              >Google</ng-container
            >
          </small>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="
      showGeoDropdown &&
      searchBarClicked &&
      (locations.geolocationAllowed$ | async)
    ">
    <div class="geo-wrapper">
      <button
        (click)="geolocationDropdownRequest($event)"
        (mouseenter)="overList()"
        (mouseleave)="leftList()"
        (blur)="hideGeoLocation()"
        class="list-group-item list-group-item-action results-list p-3 border"
        style="text-transform: capitalize !important">
        <i class="bi bi-cursor-fill me-3"></i>
        Use My Current Location
      </button>
    </div>
  </ng-container>
</form>
