import {Component, Input} from '@angular/core';
import {OptionGroup} from '../../../../interfaces/option-group.interface';
import {MenuService} from '@modules/menu/services';

@Component({
  selector: 'app-option-group-status',
  templateUrl: './option-group-status.component.html',
  styleUrls: ['option-group-status.component.scss'],
})
export class OptionGroupStatusComponent {
  @Input() optionGroup: OptionGroup;
  @Input() optionGroups: OptionGroup[];
  constructor(
    public menuService: MenuService
  ) {}
}
