import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-age-check',
  templateUrl: './age-check.component.html',
  styleUrls: ['./age-check.component.scss'],
})
export class AgeCheckComponent {
  @Input() error;

  constructor(public activeModal: NgbActiveModal) {}
}
