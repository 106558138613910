import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ItwercsHttpService } from './itwercs-http.service';
import { KeyValuePair } from './interfaces/key-value-pair.interface';
import { Concept } from './interfaces/concept.interface';
import { CreateGuestRequest, CreateGuestRequestGuest } from './interfaces/create-guest-request.interface';
import { Destination } from './interfaces/destination.interface';
import { LeadThrough } from './interfaces/lead-through.interface';
import { LeadThroughItem } from './interfaces/lead-through-item.interface';
import { CreateGuestResponse } from './interfaces/create-guest-response.interface';
import { MenuItem } from './interfaces/menu-item.interface';
import { Order } from './interfaces/order.interface';
import { OrderSubmitCalculateRequest } from './interfaces/order-submit-calculate-request.interface';
import { OrderSubmitResponse } from './interfaces/order-submit-response.interface';
import { PricingMatrix } from './interfaces/pricing.interface';
import { ScreenButton } from './interfaces/screen-button.interface';
import { ScreenPage } from './interfaces/screen-page.interface';
import { ServiceChargeResponse } from './interfaces/service-charge-response.interface';
import { Site } from './interfaces/site.interface';
import { Tender } from './interfaces/tender.interface';
import { Tablenumber } from './interfaces/tablenumber.interface';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class ItwercsAPIService {
  constructor(private itwercsHttp: ItwercsHttpService) {}

  // CONCEPT

  /**
     Returns the concept based on the session token
     @returns {Observable<Concept>}
     */
  getConcept(): Observable<Concept> {
    const resource = '/concept';
    return this.itwercsHttp.get<Concept>(resource);
  }

  /**
   * Creates a new guest for the concept
   * @param {CreateGuestRequestGuest} guest
   * @param {Concept} concept - Concept data returned from getConcept()
   * @returns {Observable<CreateGuestResponse>}
   */
  createGuest(guest: CreateGuestRequestGuest, concept: Concept): Observable<CreateGuestResponse> {
    const resource = '/concept/guest/create';
    const body = {
      Guest: guest,
      Concept: concept,
    } as CreateGuestRequest;
    return this.itwercsHttp.post<CreateGuestResponse>(resource, body);
  }

  // DESTINATIONS

  /**
   * Returns all available destinations for the specified site
   * @param {number} siteID
   * @returns {Observable<Destination[]>}
   */

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getDestinations(siteID: number): Observable<Destination[]> {
    const resource = `/destinations/site/${siteID}`;
    return this.itwercsHttp.get<Destination[]>(resource);
  }

  // LEAD THROUGHS

  /**
   * Returns all Lead Throughs for the site
   * @param {number} siteID
   * @returns {Observable<LeadThrough[]>}
   */

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getLeadThroughs(siteID: number): Observable<LeadThrough[]> {
    const resource = `/leadthroughs/site/${siteID}`;
    return this.itwercsHttp.get<LeadThrough[]>(resource);
  }

  // LEAD THROUGH ITEMS

  /**
   * Returns Lead Through Items with pricing during specified pickup time
   * @param {number} siteID -
   * @param {string} pickupTime - Optional | will default to current time if not provided (format: YYYYMMDDHHmm in UTC)
   * @returns {Observable<LeadThroughItem[]>}
   */

  getLeadThroughItemsByPickupTime(
    siteID: number,
    pickupTime: string = moment().utc().format('YYYYMMDDHHmm')
  ): Observable<LeadThroughItem[]> {
    const resource = `/leadthroughitems/site/${siteID}/pickupTime/${pickupTime}`;
    return this.itwercsHttp.get<LeadThroughItem[]>(resource);
  }

  /**
   * Returns all Lead Through Items for a specific Lead Through Group
   * @param {number} siteID
   * @param {number} groupID - Lead Through Group ID
   * @returns {Observable<LeadThroughItem[]>}
   */

  getLeadThroughItemsByGroup(siteID: number, groupID: number): Observable<LeadThroughItem[]> {
    const resource = `/leadthroughitems/site/${siteID}/group/${groupID}`;
    return this.itwercsHttp.get<LeadThroughItem[]>(resource);
  }

  /**
   * Returns Lead Through Items for a specific group-ordering with pricing for the specified pickup time
   * @param {number} siteID
   * @param {number} groupID - Lead Through Group ID
   * @param {Date} pickupTime
   * @returns {Observable<LeadThroughItem[]>}
   */

  getLeadThroughItemsByGroupAndPickupTime(siteID: number, groupID: number, pickupTime: Date): Observable<LeadThroughItem[]> {
    const formattedPickupTime = moment(pickupTime).utc().format('YYYYMMDDHHmm');
    const resource = `/leadthroughitems/site/${siteID}/group/${groupID}/pickupTime/${formattedPickupTime}`;
    return this.itwercsHttp.get<LeadThroughItem[]>(resource);
  }

  // MENU ITEMS

  /**
   * Returns a single menu item based on the id
   * @param {number} siteID
   * @param {number} menuItemID
   * @returns {Observable<MenuItem>}
   */

  getMenuItemByID(siteID: number, menuItemID: number | string): Observable<MenuItem> {
    const resource = `/menuitem/${menuItemID}/site/${siteID}`;
    return this.itwercsHttp.get<MenuItem>(resource);
  }

  getMenuItemLeadthroughs(siteID: number, menuItemID: number, destinationId: string | number, pickupTime: Date): Observable<any> {
    const formattedPickupTime = moment(pickupTime).utc().format('YYYYMMDDHHmm');
    // tslint:disable-next-line: max-line-length
    const resource = `/leadthroughs/site/${siteID}/menuItem/${menuItemID}/destination/${destinationId}/${formattedPickupTime}?destId=${destinationId}`;
    return this.itwercsHttp.get<any>(resource);
  }

  /**
   * Returns all Menu Items for the specified site
   * @param {number} siteID
   * @returns {Observable<MenuItem[]>}
   */

  @Cacheable({
    maxAge: 2 * 60 * 1000,
  })
  getAllMenuItems(siteID: number): Observable<MenuItem[]> {
    const resource = `/menuitems/site/${siteID}`;
    return this.itwercsHttp.get<MenuItem[]>(resource);
  }

  getMenuItemDetails(siteID: number, menuItemID: number | string, pickupTime: Date, destinationId: number | string): Observable<MenuItem> {
    const formattedPickupTime = moment(pickupTime).utc().format('YYYYMMDDHHmm');
    const resource = `/chepri/menuItemDtl/${menuItemID}/site/${siteID}/destination/${destinationId}/pickupTime/${formattedPickupTime}`;
    return this.itwercsHttp.get<MenuItem>(resource);
  }

  // MODIFIERS

  /**
   * Returns all Modifiers for a single Menu Item
   * @param {number} siteID
   * @param {number|string} menuItemID
   * @returns {Observable<LeadThrough>} - Modifiers formatted like a LeadThrough
   */

  getModifiersByMenuItem(siteID: number, menuItemID: number | string): Observable<LeadThrough> {
    const resource = `/modifiers/${siteID}/${menuItemID}`;
    return this.itwercsHttp.get<LeadThrough>(resource);
  }

  // ORDERS

  /**
   * Returns the order matching the specified ID
   * @param {number} orderID
   * @returns {Observable<Order>}
   */

  getOrderByID(orderID: number): Observable<Order> {
    const resource = `/order/${orderID}`;
    return this.itwercsHttp.get<Order>(resource);
  }

  /**
   * Returns the order matching the specified reference ID
   * @param {string} referenceID
   * @returns {Observable<Order>}
   */

  getOrderByReferenceID(referenceID: string): Observable<Order> {
    const resource = `/order/reference/${referenceID}`;
    return this.itwercsHttp.get<Order>(resource);
  }

  /**
   * Submits order to system
   * @param {OrderSubmitCalculateRequest} order
   * @returns {Observable<OrderSubmitResponse>}
   */

  submitOrder(order: Order): Observable<OrderSubmitResponse> {
    const resource = '/order/submit';
    return this.itwercsHttp.post<OrderSubmitResponse>(resource, order);
  }

  /**
   * Returns the specified order with totals calculated
   * @param {OrderSubmitCalculateRequest} order
   * @returns {Observable<Order>}
   */

  calculateOrderTotals(order: OrderSubmitCalculateRequest): Observable<OrderSubmitResponse> {
    const resource = '/order/calculate';
    return this.itwercsHttp.post<OrderSubmitResponse>(resource, order);
  }

  /**
   * Returns all orders attached to the specified guest ID
   * @param {string} guestID - Guest guID
   * @returns {Observable<Order[]>}
   */

  getOrdersByGuestID(guestID: string): Observable<Order[]> {
    const resource = `/order/guest/${guestID}`;
    return this.itwercsHttp.get<Order[]>(resource);
  }

  // PRICING

  /**
   * Returns a dictionary<MenuItemID: number, Price:number> of all pricing for the site
   * @param {number} siteID
   * @returns {Observable<Record<number,number>>}
   */

  getPricingBySite(siteID: number): Observable<Record<number, number>> {
    const resource = `/pricing/site/${siteID}`;
    return this.itwercsHttp.get<Record<number, number>>(resource);
  }

  /**
   * Returns a dictionary<MenuItemID: number, Price:number> of all pricing for the site at the specified pickup time
   * @param {number} siteID
   * @param {string} pickupTime
   * @returns {Observable<Record<number,number>>}
   */

  getPricingByPickupTime(siteID: number, pickupTime: Date): Observable<any> {
    const formattedPickupTime = moment(pickupTime).utc().format('YYYYMMDDHHmm');
    const resource = `/pricing/site/${siteID}/pickupTime/${formattedPickupTime}`;
    return this.itwercsHttp.get<any>(resource);
  }

  /**
   * Returns a list of all pricing; current and future. May contain duplicates, use record with higher priority
   * @param siteID
   * @returns {Observable<PricingMatrix[]>}
   */

  getPricingMatrix(siteID: number): Observable<PricingMatrix[]> {
    const resource = `/pricing/matrix/site/${siteID}`;
    return this.itwercsHttp.get<PricingMatrix[]>(resource);
  }

  // SCREEN BUTTONS

  /**
   * Returns all buttons by screen (no pricing)
   * @param {number} siteID
   * @param {number} screenID
   * @returns {Observable<ScreenButton[]>}
   */
  getScreenButtonsByScreen(siteID: number, screenID: number): Observable<ScreenButton[]> {
    const resource = `/screen/${screenID}/site/${siteID}`;
    return this.itwercsHttp.get<ScreenButton[]>(resource);
  }

  /**
   * Returns all buttons by screen with pricing
   * @param {number} siteID
   * @param {number} screenID
   * @param {Date|Moment} pickupTime - UTC Time
   * @returns {Observable<ScreenButton[]>}
   */

  getScreenButtonsByScreenAndPickupTime(siteID: number, screenID: number, pickupTime: Date | Moment): Observable<ScreenButton[]> {
    const date = moment(pickupTime).utc().format('YYYYMMDDHHmm');
    const resource = `/screen/${screenID}/site/${siteID}/pickupTime/${date}`;
    return this.itwercsHttp.get<ScreenButton[]>(resource);
  }

  /**
   * Returns all buttons by site (no pricing)
   * @param {number} siteID
   * @returns {Observable<ScreenButton[]>}
   */

  getScreenButtons(siteID: number): Observable<ScreenButton[]> {
    const resource = `/buttons/site/${siteID}`;
    return this.itwercsHttp.get<ScreenButton[]>(resource);
  }

  /**
   * Returns all buttons by site with pricing
   * @param {number} siteID
   * @param {Date} pickupTime
   * @returns {Observable<ScreenButton[]>}
   */

  getScreenButtonsByPickupTime(siteID: number, pickupTime: Date): Observable<ScreenButton[]> {
    const resource = `/buttons/site/${siteID}/pickupTime/${pickupTime}`;
    return this.itwercsHttp.get<ScreenButton[]>(resource);
  }

  // SCREEN PAGES (CATEGORIES)

  /**
   * Returns an array of all screen pages (categories) for a particular site.
   * @param {number} siteID
   * @returns {Observable<ScreenPage[]>}
   */

  getScreenPages(siteID: number): Observable<ScreenPage[]> {
    const resource = `/screens/site/${siteID}`;
    return this.itwercsHttp.get<ScreenPage[]>(resource);
  }

  /**
   * Returns an array of all screen pages for a particular site (Nested data can be toggled)
   * @param {number} siteID - the id of the site
   * @param {boolean} getButtons - retrieve nested ScreenButtons
   * @param {boolean} getMenuItems - retrieve nested MenuItems (ScreenButtons must be enabled)
   * @param {boolean} getLeadThroughs - retrieve nested LeadThroughs (MenuItems must be enabled)
   * @param getLeadThroughItems - retrieve nested LeadThroughItems (LeadThroughs must be enabled)
   * @returns {ScreenPage[]} - Observable of all ScreenPages with the requested fields
   */

  // tslint:disable-next-line:max-line-length
  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getFilteredScreenPages(
    siteID: number,
    getButtons = true,
    getMenuItems = true,
    getLeadThroughs = false,
    getLeadThroughItems = false
  ): Observable<ScreenPage[]> {
    // tslint:disable-next-line:max-line-length
    const resource = `/screens/site/${siteID}/getButtons/${getButtons}/getMenuItem/${getMenuItems}/getLeadThroughGrps/${getLeadThroughs}/getLeadThroughItems/${getLeadThroughItems}`;
    return this.itwercsHttp.get<ScreenPage[]>(resource);
  }

  // SERVICE CHARGES

  /**
   * Returns all service charges for a site? (There is no documentation on this)
   * @param {number} siteID
   * @returns {Observable<ServiceChargeResponse[]>}
   */

  getServiceCharges(siteID: number): Observable<ServiceChargeResponse[]> {
    const resource = `/servicecharges/site/${siteID}`;
    return this.itwercsHttp.get<ServiceChargeResponse[]>(resource);
  }

  // SITES

  /**
   * Specific Site.  Returns Site Object
   * @param siteID
   * @returns {Observable<Site>}
   */

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getSiteByID(siteID: number): Observable<Site> {
    const resource = `/site/${siteID}`;
    return this.itwercsHttp.get<Site>(resource);
  }

  /**
   * All sites within a concept.  Returns a list of the Site Object
   * @returns {Observable<Site[]>}
   */

  getAllSites(): Observable<Site[]> {
    const resource = '/sites';
    return this.itwercsHttp.get<Site[]>(resource);
  }

  /**
   * Returns sites near specified location? (No documentation)
   * @param {number|string} latitude
   * @param {number|string} longitude
   * @returns {Observable<Site[]>}
   */

  getSitesNearLatLong(latitude: number | string, longitude: number | string): Observable<Site[]> {
    const resource = `/sites?latitude=${latitude}&longitude=${longitude}`;
    return this.itwercsHttp.get<Site[]>(resource);
  }

  /**
   * Returns sites near specified zip code? (No documentation)
   * @param {string} zip
   * @returns {Observable<Site[]>}
   */

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getSitesNearZip(zip: string): Observable<Site[]> {
    const resource = `/site/zip/${zip}`;
    return this.itwercsHttp.get<Site[]>(resource);
  }

  // TENDERS

  /**
   * Returns a list of tenders for payments.
   * @param {string} siteID
   * @returns {Observable<Tender[]>}
   */

  getTendersBySite(siteID: number): Observable<Tender[]> {
    const resource = `/tenders/site/${siteID}`;
    return this.itwercsHttp.get<Tender[]>(resource);
  }

  private getQueryParamsString(pairs: KeyValuePair[]): string {
    let params = new HttpParams();
    pairs.forEach(p => {
      if (p.value !== undefined && p.value !== null) {
        params = params.set(p.key, p.value);
      }
    });
    return params && params.keys().length > 0 ? '?' + params.toString() : '';
  }

  private getNumbersOnlyString(str: string): string {
    return str.replace(/\D/g, '');
  }

  getTableNumbers(siteID: number): Observable<Tablenumber[]> {
    const resource = `/tables/site/${siteID}`;
    return this.itwercsHttp.get<Tablenumber[]>(resource);
  }
}
