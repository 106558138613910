import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { RecaptchaConfiguration } from '../../../../vendors/directus/interfaces/recaptcha-configuration.interface';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-recaptcha',
  template: ``,
})
export class RecaptchaComponent implements OnInit, OnDestroy {
  @Input() siteKey: string;
  @Input() action: string;
  @Output() token = new EventEmitter<string>();

  private isLoaded = false;

  ngOnInit() {
    this.loadRecaptchaScript()
      .then(() => {
        this.isLoaded = true;
      })
      .catch(error => {
        console.error('Error loading reCAPTCHA script:', error);
      });
  }

  ngOnDestroy() {
    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }
    const recaptcha = document.querySelector('.grecaptcha-badge');
    if (recaptcha) {
      recaptcha.remove();
    }
  }

  execute() {
    if (this.isLoaded && window['grecaptcha']) {
      window['grecaptcha']
        .execute(this.siteKey, { action: this.action })
        .then((token: string) => {
          this.token.emit(token);
        })
        .catch((error: any) => {
          console.error('Error executing reCAPTCHA:', error);
        });
    } else {
      console.error('reCAPTCHA not ready');
    }
  }

  private loadRecaptchaScript(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      if (document.getElementById('recaptcha-script')) {
        resolve();
        return;
      }

      window['grecaptchaLoaded'] = () => {
        resolve();
      };

      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      script.async = true;
      script.defer = true;
      script.onerror = () => reject('Failed to load reCAPTCHA script');
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }
}
