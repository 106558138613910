import { Pipe, PipeTransform } from '@angular/core';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';

@Pipe({
  name: 'savedPickupLocationFilter',
})
export class SavedPickupLocationFilterPipe implements PipeTransform {
  transform(addresses: ISavedAddress[]): ISavedAddress[] {
    if (!addresses) {
      return [];
    }
    return addresses.filter(address => address.orderType === 'pickup');
  }
}
