import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as Radar from 'radar-sdk-js';
import {Point} from '@common/models';

@Injectable()
export class RadarWebIntegrationService {
  constructor() {}

  getRadarWebIntegration$(): Observable<{}> {
    return of({});
  }

  initializeRadar(publishableKey) {
    Radar.initialize(publishableKey);
  }

  setRadarUserID(userId: string) {
    Radar.setUserId({userId});
  }

  setRadarUserMetaData(metadata: any) {
    Radar.setMetadata({metadata});
  }

  setRadarUserDescription(description: string) {
    Radar.setDescription({description});
  }

  requestLocationPermissionsOnce() {
    Radar.trackOnce((err, result) => {
      if (!err) {
        console.log(err);
        console.log(result.location, result.events, result.user);
      }
    });
  }

  getAutocompleteNear(query: string, near: Point, limit: number) {
    Radar.autocomplete({
      query,
      near,
      limit
    }, (err, result) => {
      if (!err) {
        console.log(result.addresses);
      }
    });
  }

  getGeofencesNearTag(radius: number, tag: string, limit: number) {
    Radar.searchGeofences({
      radius,
      tags: [tag],
      limit
    }, (err, result) => {
      if (!err) {
        console.log(result.geofences);
      }
    });
  }

  getPlacesNear(near: Point, radius: number) {
    Radar.searchPlaces({
      near,
      radius,
      chains: ['starbucks'],
      limit: 10
    }, (err, result) => {
      if (!err) {
        console.log(result.places);
      }
    });
  }

  getDistanceValues(origin: Point, destination: Point) {
    Radar.getDistance({
      origin,
      destination,
      modes: [
        'foot',
        'car'
      ],
      units: 'imperial'
    }, (err, result) => {
      if (!err) {
        console.log(result.routes);
      }
    });
  }

  getMatrixValues(origins: Point[], destinations: Point[]) {
    Radar.getMatrix({
      origins,
      destinations,
      modes: 'car',
      units: 'imperial'
    }, (err, result) => {
      if (!err) {
        console.log(result.matrix);
      }
    });
  }
}
