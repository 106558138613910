<ng-container
  *ngIf="
    displayMenu &&
    displayMenu.categories &&
    displayMenu.categories.length > 0 &&
    displayTheme
  ">
  <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
  <div
    class="w-100 mx-0 mx-lg-auto"
    style="margin-top: 4rem; margin-bottom: 4rem"
    [class.container]="navbarSettings.fixed_navbar_width"
    [class.px-lg-3]="!navbarSettings.fixed_navbar_width"
    [@fadeInOut]>
    <div class="w-100 mb-3" [class.px-3]="mobile.isMobile">
      <h2
        class="text-start fw-bolder header-font">
        Our Menu
      </h2>
      <ng-container *ngIf="mainSettings">
        <p class="text-start">{{ mainSettings.slogan }}</p>
      </ng-container>
    </div>

    <ng-container *ngIf="!isMobile; else mobileCarousel">
      <div
        class="w-100"
        style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          grid-gap: 16px;
        ">
        <ng-container
          *ngFor="let category of displayMenu.categories; index as index">
          <ng-container *ngIf="index < 8">
            <div
              style="
                display: grid;
                grid-template-rows: max-content auto;
                border-radius: var(--cms-card-radius);
                cursor: pointer;
              "
              class="category-card"
              [@fadeInOut]
              (click)="goToCategory(category)">
              <ng-container
                *ngIf="
                  category.thumbnailImageURL || category.standardImageURL;
                  else noImage
                ">
                <img
                  class="cms-card-radius"
                  style="object-fit: cover; aspect-ratio: 1/1;"
                  [alt]="category.name"
                  [src]="category | defaultCategoryImage" />
              </ng-container>
              <ng-template #noImage>
                <div
                  class="w-100 h-auto img-border-inherit"
                  style="position: relative">
                  <img
                    src="../../../../assets/img/600x600.png"
                    class="cms-card-radius"
                    [alt]="category.name"
                    [style.background-color]="
                      displayTheme?.card_background_color
                    " />
                  <div
                    class="w-100 text-center fw-bold header-font"
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                    [style.color]="displayTheme.card_text_color">
                    {{ category.name | uppercase }}
                  </div>
                </div>
              </ng-template>
                <ng-container *ngIf="mainSettings.show_category_name">
                  <h5 class="header-font fw-bold text-center mt-2 line-clamp-2 px-3">
                  {{ category.name }}
                </h5>
                </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #mobileCarousel>
      <ng-container *ngIf="!menuLoading">
        <!--          <carousel [cellWidth]="300" [margin]="16" [height]="168">-->
        <div
          class="px-3 pb-2 me-3"
          style="display: grid; grid-gap: 16px; overflow-x: scroll; width: 100vw;"
          [style.grid-template-columns]="
            'repeat(' + displayMenu.categories.length + ', 250px)'
          ">
          <ng-container
            *ngFor="let category of displayMenu.categories; index as index">
            <div style="width: 250px" [@fadeInOut] class="category-card">
              <div
                style="
                  display: grid;
                  grid-template-rows: max-content auto;
                  border-radius: var(--cms-card-radius);
                "
                class=""
                (click)="goToCategory(category)">
                <ng-container
                  *ngIf="
                    category.thumbnailImageURL || category.standardImageURL;
                    else noImage
                  ">
                  <img
                    class="cms-card-radius"
                    style="object-fit: cover; aspect-ratio: 1/1;"
                    [alt]="category.name"
                    [src]="category | defaultCategoryImage" />
                </ng-container>
                <ng-template #noImage>
                  <div
                    class="w-100 h-auto cms-card-radius"
                    style="position: relative">
                    <img
                      src="../../../../assets/img/600x600.png"
                      class="cms-card-radius"
                      [alt]="category.name"
                      [style.background-color]="
                        displayTheme.card_background_color
                      " />
                    <div
                      class="w-100 text-center fw-bold header-font"
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      "
                      [style.color]="displayTheme.card_text_color">
                      {{ category.name | uppercase }}
                    </div>
                  </div>
                </ng-template>
                <ng-container *ngIf="mainSettings.show_category_name">
                  <h5 class="header-font fw-bold text-center mt-2 line-clamp-2 px-3">
                    {{ category.name }}
                  </h5>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <!--          </carousel>-->
      </ng-container>
    </ng-template>
  </div>
  </ng-container>
</ng-container>
