<div class="card-body mt-n2 mb-n2">
  <form [formGroup]="userForm">
    <div class="de-section-padding text-start small">
      <div class="form-row">
        <div class="form-group col-6">
          <label for="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            class="form-control de-light de-border de-input"
            placeholder="Enter first name"
            formControlName="firstName" />
          <div
            *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
            class="de-form-error">
            <small
              *ngIf="firstName.errors.pattern"
              class="form-text text-danger"
              >Invalid Name</small
            >
            <small
              *ngIf="firstName.errors.required"
              class="form-text text-danger"
              >First Name Required</small
            >
          </div>
        </div>
        <div class="form-group col-6">
          <label for="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            class="de-light de-border de-input form-control"
            placeholder="Enter last name"
            formControlName="lastName" />
          <div
            *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
            class="de-form-error">
            <small *ngIf="lastName.errors.pattern" class="form-text text-danger"
              >Invalid Name</small
            >
            <small
              *ngIf="lastName.errors.required"
              class="form-text text-danger"
              >Last Name Required</small
            >
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label for="emailAddress">Email Address *</label>
          <input
            type="email"
            class="de-light de-border de-input form-control"
            id="emailAddress"
            placeholder="Enter email address"
            formControlName="emailAddress" />
          <div
            *ngIf="
              emailAddress.invalid &&
              (emailAddress.dirty || emailAddress.touched)
            "
            class="de-form-error">
            <small
              *ngIf="emailAddress.errors.email"
              class="form-text text-danger"
              >Invalid Email Address</small
            >
            <small
              *ngIf="emailAddress.errors.required"
              class="form-text text-danger"
              >Email Address Required</small
            >
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label for="phoneNumber">Phone Number</label>
          <input
            type="tel"
            class="de-light de-border de-input form-control"
            id="phoneNumber"
            placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
            formControlName="phoneNumber"
            mask="(000)000-0000" />
          <div
            *ngIf="
              phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)
            "
            class="de-form-error">
            <small
              *ngIf="phoneNumber.errors.pattern"
              class="form-text text-danger">
              Invalid Phone Number
            </small>
            <small
              *ngIf="phoneNumber.errors.required"
              class="form-text text-danger">
              Phone Number Required
            </small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <ion-item lines="none">
            <ion-checkbox
              color="dark"
              slot="start"
              class="me-1"
              formControlName="guestOptIn"></ion-checkbox>
            <ion-label>
              <small>{{ emailOptInCopy }}</small>
            </ion-label>
          </ion-item>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <ion-item lines="none">
            <ion-checkbox
              color="dark"
              slot="start"
              class="me-1"
              formControlName="signUpOptIn"></ion-checkbox>
            <ion-label>
              <small>{{ signUpOptInCopy }}</small>
            </ion-label>
          </ion-item>
        </div>
      </div>
      <!--      <ng-container [@growInOut]="this.signUpOptIn.value === true">-->
      <ng-container *ngIf="this.signUpOptIn.value === true">
        <div class="form-row">
          <div class="form-group col-12">
            <label for="password">Password *</label>
            <input
              type="password"
              class="de-light de-border de-input form-control"
              id="password"
              placeholder="Enter password"
              formControlName="password" />
            <div
              *ngIf="password.invalid && (password.dirty || password.touched)"
              class="de-form-error">
              <small
                *ngIf="password.errors.pattern"
                class="form-text text-danger">
                Invalid Password
              </small>
              <small
                *ngIf="password.errors.required"
                class="form-text text-danger">
                Phone Password
              </small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="password2">Confirm Password *</label>
            <input
              type="password"
              class="de-light de-border de-input form-control"
              id="password2"
              placeholder="Confirm password"
              formControlName="password2" />
            <div
              *ngIf="
                password2.invalid && (password2.dirty || password2.touched)
              "
              class="de-form-error">
              <small
                *ngIf="password2.errors.pattern"
                class="form-text text-danger">
                Invalid Confirm Password
              </small>
              <small
                *ngIf="password2.errors.required"
                class="form-text text-danger">
                Confirm Password Required
              </small>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
