import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InStoreRedemptionComponent } from '../in-store-redemption/in-store-redemption.page';
import { ModalController } from '@ionic/angular';
import { Reward } from 'src/interfaces/reward.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-mobile-redemption',
  templateUrl: './mobile-redemption.component.html',
  styleUrls: ['./mobile-redemption.component.scss'],
})
export class MobileRedemptionComponent {
  @Input() error;
  @Input() reward: Reward;
  @Input() canApply: boolean;

  @Output() clickedRedeem = new EventEmitter<any>();
  @Output() clickedRedeemInStore = new EventEmitter<any>();
  @Output() clickedRemove = new EventEmitter<any>();

  inStoreLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    public mobile: MobileService,
    private modalController: ModalController,
    private modalService: NgbModal
  ) {}

  async presentModal() {
    const modal = await this.modalController.create({
      component: MobileRedemptionComponent,
      componentProps: { reward: this.reward }
    });
    return await modal.present();
  }

  redeemClicked() {
    this.clickedRedeem.emit();
    // this.presentModal();
  }

  redeemInStoreClicked() {
    this.inStoreLoading = true;
    this.clickedRedeemInStore.emit();
    // this.presentModal();
  }

  removeClicked() {
    this.clickedRemove.emit();
  }
}
