<div class="mobile-modal-header-back">
  <button class="back-button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  </button>
  <div class="header-title header-font fw-bold">Reward Balances</div>
</div>
<ion-content class="mobile-modal-content">
  <ng-container *ngIf="rewardPoints$ | async as rewardsBalances">
    <div class="wrapper-grid overflow-hidden h-100">
      <div class="card-container overflow-auto d-flex flex-column">
        <ng-container *ngFor="let balance of rewardsBalances.pointBalances; last as last">
          <app-complete-reward-balances-card [balance]="balance" [isLast]="last"></app-complete-reward-balances-card>
        </ng-container>
      </div>
      <div class="button-grid">
        <button class="de-primary-btn-outline" (click)="close()">Back</button>
        <button class="de-primary-btn" (click)="rewardsRoute()">Rewards</button>
      </div>
    </div>
  </ng-container>

</ion-content>
