import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TablesideGuard  {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.queryParams.mode === 'tableside' && route.queryParams.tableNumber) {

      // if (the users location matches the restaurant location) {

        // sessionStorage.setItem('mode', 'tableside');
        sessionStorage.setItem('tableNumber', route.queryParams.tableNumber);

        this.router.navigate([state.url.split('?')[0]], { queryParamsHandling: 'merge' });

        return true;

      // } else {

        // return true;

      // }

    } else {

      return true;

    }
  }
}
