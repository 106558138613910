import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { Features } from '../../../../vendors/directus/interfaces/features.interface';

@Component({
  selector: 'app-home-news',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home-news.component.html',
  styleUrls: ['home-news.component.scss'],
})
export class HomeNewsComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.app.features) features$: Observable<Features[]>;
  constructor() {}
  ngOnInit() {}
}
