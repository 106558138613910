import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../interfaces/category.interface';

@Pipe({
  name: 'defaultCategoryImage',
})
export class DefaultCategoryImagePipe implements PipeTransform {
  transform(category: Category): string | null {
    if (!category) {
      return null;
    }
    if (category.thumbnailImageURL) {
      return category.thumbnailImageURL;
    } else {
      const firstProductWithImage = category.products.find(product => product.thumbnailImageURL || product.standardImageURL);
      if (firstProductWithImage) {
        return firstProductWithImage.thumbnailImageURL ?? firstProductWithImage.standardImageURL;
      } else {
        return null;
      }
    }
  }
}
