import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { interval, Observable, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { MobileAppSettings } from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';

@Component({
  selector: 'app-points-progress-dial',
  templateUrl: './points-progress-dial.component.html',
  styleUrls: ['./points-progress-dial.component.scss'],
})
export class PointsProgressDialComponent implements OnChanges, AfterViewInit {
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;

  @Input() points = 0;
  @Input() pointsThreshold = 100;
  @Input() size = 100; // Default size

  progressPercentage = 0;
  radius = 0;
  strokeWidth = 0;
  normalizedRadius = 0;
  circumference = 0;
  strokeDashoffset = 0;

  // progress-dial.component.ts
  progressColor = 'var(--ion-color-primary)';

  private actualProgressPercentage = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    this.calculateDimensions();
    this.actualProgressPercentage = Math.min((this.points / this.pointsThreshold) * 100, 100);
    // Initially set progressPercentage to 0
    this.progressPercentage = 0;
    this.updateProgress();
  }

  ngAfterViewInit() {
    // Animate the progress
    this.animateProgress();
  }

  private getProgressColor(percentage: number): string {
    if (percentage < 50) {
      return 'var(--ion-color-primary)';
    } else if (percentage < 75) {
      return 'var(--ion-color-primary)';
    } else {
      return 'var(--ion-color-primary)';
    }
  }

  private calculateDimensions() {
    this.radius = this.size / 2;
    this.strokeWidth = this.size * 0.1;
    this.normalizedRadius = this.radius - this.strokeWidth / 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  private updateProgress() {
    const progress = this.progressPercentage / 100;
    this.strokeDashoffset = this.circumference - progress * this.circumference;
  }

  private animateProgress() {
    const duration = 1000; // Animation duration in milliseconds
    const frameRate = 60; // Frames per second
    const totalFrames = Math.round((duration / 1000) * frameRate);
    const increment = this.actualProgressPercentage / totalFrames;

    const source = interval(duration / totalFrames);

    const subscription = source.pipe(takeUntil(interval(duration))).subscribe(() => {
      this.progressPercentage += increment;
      if (this.progressPercentage >= this.actualProgressPercentage) {
        this.progressPercentage = this.actualProgressPercentage;
        this.updateProgress();
        this.cdr.detectChanges();
        subscription.unsubscribe();
      } else {
        this.updateProgress();
        this.cdr.detectChanges();
      }
    });
  }
}
