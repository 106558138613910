import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {

  /**
   * Constructor for the address service
   */
  constructor() {
  }

  /**
   * gets the state name from the zip code
   */
  getStateFromZip(zip: string) {
    let st;
    let state;

    const zipcode = parseInt(zip, 10);

    /* Code cases alphabetized by state */
    if (zipcode >= 35000 && zipcode <= 36999) {
      st = 'AL';
      state = 'Alabama';
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      st = 'AK';
      state = 'Alaska';
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      st = 'AZ';
      state = 'Arizona';
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      st = 'AR';
      state = 'Arkansas';
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      st = 'CA';
      state = 'California';
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      st = 'CO';
      state = 'Colorado';
    } else if (zipcode >= 6000 && zipcode <= 6999) {
      st = 'CT';
      state = 'Connecticut';
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      st = 'DE';
      state = 'Delaware';
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      st = 'FL';
      state = 'Florida';
    } else if (zipcode >= 30000 && zipcode <= 31999) {
      st = 'GA';
      state = 'Georgia';
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      st = 'HI';
      state = 'Hawaii';
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      st = 'ID';
      state = 'Idaho';
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      st = 'IL';
      state = 'Illinois';
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      st = 'IN';
      state = 'Indiana';
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      st = 'IA';
      state = 'Iowa';
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      st = 'KS';
      state = 'Kansas';
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      st = 'KY';
      state = 'Kentucky';
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      st = 'LA';
      state = 'Louisiana';
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      st = 'ME';
      state = 'Maine';
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      st = 'MD';
      state = 'Maryland';
    } else if (zipcode >= 1000 && zipcode <= 2799) {
      st = 'MA';
      state = 'Massachusetts';
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      st = 'MI';
      state = 'Michigan';
    } else if (zipcode >= 55000 && zipcode <= 56999) {
      st = 'MN';
      state = 'Minnesota';
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      st = 'MS';
      state = 'Mississippi';
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      st = 'MO';
      state = 'Missouri';
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      st = 'MT';
      state = 'Montana';
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      st = 'NC';
      state = 'North Carolina';
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      st = 'ND';
      state = 'North Dakota';
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      st = 'NE';
      state = 'Nebraska';
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      st = 'NV';
      state = 'Nevada';
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      st = 'NH';
      state = 'New Hampshire';
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      st = 'NJ';
      state = 'New Jersey';
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      st = 'NM';
      state = 'New Mexico';
    } else if (zipcode >= 10000 && zipcode <= 14999) {
      st = 'NY';
      state = 'New York';
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      st = 'OH';
      state = 'Ohio';
    } else if (zipcode >= 73000 && zipcode <= 74999) {
      st = 'OK';
      state = 'Oklahoma';
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      st = 'OR';
      state = 'Oregon';
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      st = 'PA';
      state = 'Pennsylvania';
    } else if (zipcode >= 300 && zipcode <= 999) {
      st = 'PR';
      state = 'Puerto Rico';
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      st = 'RI';
      state = 'Rhode Island';
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      st = 'SC';
      state = 'South Carolina';
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      st = 'SD';
      state = 'South Dakota';
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      st = 'TN';
      state = 'Tennessee';
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 88500 && zipcode <= 88599)) {
      st = 'TX';
      state = 'Texas';
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      st = 'UT';
      state = 'Utah';
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      st = 'VT';
      state = 'Vermont';
    } else if (zipcode >= 22000 && zipcode <= 24699) {
      st = 'VA';
      state = 'Virgina';
    } else if (zipcode >= 20000 && zipcode <= 20599) {
      st = 'DC';
      state = 'Washington DC';
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      st = 'WA';
      state = 'Washington';
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      st = 'WV';
      state = 'West Virginia';
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      st = 'WI';
      state = 'Wisconsin';
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      st = 'WY';
      state = 'Wyoming';
    } else if (zip.startsWith('T')) {
      st = 'AB';
      state = 'Alberta';
    } else if (zip.startsWith('V')) {
      st = 'BC';
      state = 'British Columbia';
    } else if (zip.startsWith('R')) {
      st = 'MB';
      state = 'Manitoba';
    } else if (zip.startsWith('E')) {
      st = 'NB';
      state = 'New Brunswick';
    } else if (zip.startsWith('A')) {
      st = 'NL';
      state = 'Newfoundland & Laborador';
    } else if (zip.startsWith('B')) {
      st = 'NS';
      state = 'Nova Scotia';
    } else if (zip.startsWith('X')) {
      st = 'NT';
      state = 'Northwest Territories';
    } else if (zip.startsWith('K') || zip.startsWith('L') || zip.startsWith('M') || zip.startsWith('N') || zip.startsWith('P')) {
      st = 'ON';
      state = 'Ontario';
    } else if (zip.startsWith('G') || zip.startsWith('H') || zip.startsWith('J') || zip.startsWith('K')) {
      st = 'QB';
      state = 'Provence de Québec';
    } else if (zip.startsWith('S')) {
      st = 'SK';
      state = 'Saskatchewan';
    } else if (zip.startsWith('Y')) {
      st = 'YT';
      state = 'Yukon Territory';
    } else if (zip.startsWith('C')) {
      st = 'PE';
      state = 'Prince Edward Island';
    } else {
      st = '';
    }

    return st;
  }

  /**
   * gets the state code from the zip code
   */
  getStateCodeFromZip(zip: string) {
    let st;
    let state;

    const zipcode = parseInt(zip, 10);

    /* Code cases alphabetized by state */
    if (zipcode >= 35000 && zipcode <= 36999) {
      st = 'AL';
    } else if (zipcode >= 99500 && zipcode <= 99999) {
      st = 'AK';
    } else if (zipcode >= 85000 && zipcode <= 86999) {
      st = 'AZ';
    } else if (zipcode >= 71600 && zipcode <= 72999) {
      st = 'AR';
    } else if (zipcode >= 90000 && zipcode <= 96699) {
      st = 'CA';
    } else if (zipcode >= 80000 && zipcode <= 81999) {
      st = 'CO';
    } else if (zipcode >= 6000 && zipcode <= 6999) {
      st = 'CT';
    } else if (zipcode >= 19700 && zipcode <= 19999) {
      st = 'DE';
    } else if (zipcode >= 32000 && zipcode <= 34999) {
      st = 'FL';
    } else if (zipcode >= 30000 && zipcode <= 31999) {
      st = 'GA';
    } else if (zipcode >= 96700 && zipcode <= 96999) {
      st = 'HI';
    } else if (zipcode >= 83200 && zipcode <= 83999) {
      st = 'ID';
    } else if (zipcode >= 60000 && zipcode <= 62999) {
      st = 'IL';
    } else if (zipcode >= 46000 && zipcode <= 47999) {
      st = 'IN';
    } else if (zipcode >= 50000 && zipcode <= 52999) {
      st = 'IA';
    } else if (zipcode >= 66000 && zipcode <= 67999) {
      st = 'KS';
    } else if (zipcode >= 40000 && zipcode <= 42999) {
      st = 'KY';
    } else if (zipcode >= 70000 && zipcode <= 71599) {
      st = 'LA';
    } else if (zipcode >= 3900 && zipcode <= 4999) {
      st = 'ME';
    } else if (zipcode >= 20600 && zipcode <= 21999) {
      st = 'MD';
    } else if (zipcode >= 1000 && zipcode <= 2799) {
      st = 'MA';
    } else if (zipcode >= 48000 && zipcode <= 49999) {
      st = 'MI';
    } else if (zipcode >= 55000 && zipcode <= 56999) {
      st = 'MN';
    } else if (zipcode >= 38600 && zipcode <= 39999) {
      st = 'MS';
    } else if (zipcode >= 63000 && zipcode <= 65999) {
      st = 'MO';
    } else if (zipcode >= 59000 && zipcode <= 59999) {
      st = 'MT';
    } else if (zipcode >= 27000 && zipcode <= 28999) {
      st = 'NC';
    } else if (zipcode >= 58000 && zipcode <= 58999) {
      st = 'ND';
    } else if (zipcode >= 68000 && zipcode <= 69999) {
      st = 'NE';
    } else if (zipcode >= 88900 && zipcode <= 89999) {
      st = 'NV';
    } else if (zipcode >= 3000 && zipcode <= 3899) {
      st = 'NH';
    } else if (zipcode >= 7000 && zipcode <= 8999) {
      st = 'NJ';
    } else if (zipcode >= 87000 && zipcode <= 88499) {
      st = 'NM';
    } else if (zipcode >= 10000 && zipcode <= 14999) {
      st = 'NY';
    } else if (zipcode >= 43000 && zipcode <= 45999) {
      st = 'OH';
    } else if (zipcode >= 73000 && zipcode <= 74999) {
      st = 'OK';
    } else if (zipcode >= 97000 && zipcode <= 97999) {
      st = 'OR';
    } else if (zipcode >= 15000 && zipcode <= 19699) {
      st = 'PA';
    } else if (zipcode >= 300 && zipcode <= 999) {
      st = 'PR';
    } else if (zipcode >= 2800 && zipcode <= 2999) {
      st = 'RI';
    } else if (zipcode >= 29000 && zipcode <= 29999) {
      st = 'SC';
    } else if (zipcode >= 57000 && zipcode <= 57999) {
      st = 'SD';
    } else if (zipcode >= 37000 && zipcode <= 38599) {
      st = 'TN';
    } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 88500 && zipcode <= 88599)) {
      st = 'TX';
    } else if (zipcode >= 84000 && zipcode <= 84999) {
      st = 'UT';
    } else if (zipcode >= 5000 && zipcode <= 5999) {
      st = 'VT';
    } else if (zipcode >= 22000 && zipcode <= 24699) {
      st = 'VA';
    } else if (zipcode >= 20000 && zipcode <= 20599) {
      st = 'DC';
    } else if (zipcode >= 98000 && zipcode <= 99499) {
      st = 'WA';
    } else if (zipcode >= 24700 && zipcode <= 26999) {
      st = 'WV';
    } else if (zipcode >= 53000 && zipcode <= 54999) {
      st = 'WI';
    } else if (zipcode >= 82000 && zipcode <= 83199) {
      st = 'WY';
    } else if (zip.startsWith('T')) {
      st = 'AB';
    } else if (zip.startsWith('V')) {
      st = 'BC';
    } else if (zip.startsWith('R')) {
      st = 'MB';
    } else if (zip.startsWith('E')) {
      st = 'NB';
    } else if (zip.startsWith('A')) {
      st = 'NL';
    } else if (zip.startsWith('B')) {
      st = 'NS';
    } else if (zip.startsWith('X')) {
      st = 'NT';
    } else if (zip.startsWith('K') || zip.startsWith('L') || zip.startsWith('M') || zip.startsWith('N') || zip.startsWith('P')) {
      st = 'ON';
    } else if (zip.startsWith('G') || zip.startsWith('H') || zip.startsWith('J') || zip.startsWith('K')) {
      st = 'QB';
    } else if (zip.startsWith('S')) {
      st = 'SK';
    } else if (zip.startsWith('Y')) {
      st = 'YT';
    } else if (zip.startsWith('C')) {
      st = 'PE';
    } else {
      st = '';
    }

    return st;
  }
}
