import { Component } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Select} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {MobileAppSettings} from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';

@Component({
  selector: 'app-loyalty-instructions-modal',
  templateUrl: './loyalty-instructions-modal.component.html',
  styleUrls: ['loyalty-instructions-modal.component.scss'],
})
export class LoyaltyInstructionsModalComponent {
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;
  constructor(
      private modalController: ModalController
  ) {}
  close(): void {
      this.modalController.dismiss();
  }
}
