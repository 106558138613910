import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Order } from '../../../../interfaces/order.interface';
import moment, { Moment } from 'moment-timezone';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-ready-time-section',
  templateUrl: './ready-time-section.component.html',
  styleUrls: ['ready-time-section.component.scss'],
  animations: [
    // animation that starts at 104px height and expands to 100% height
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'closed',
        style({
          height: '112px',
        })
      ),
      transition('open => closed', [animate('0.3s ease-in-out')]),
      transition('closed => open', [animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class ReadyTimeSectionComponent implements OnChanges, OnInit {
  @Input() order: Order = null;
  @Input() isAsap = true;
  @Input() availableDates: Date[] = [];

  @Input()
  set availableTimes(val: Moment[]) {
    // create variable for offset
    // if offset between -12 and 12, multiply by 60 to get minutes
    const offset =
      Number(this.order.location.utcOffset) >= -12 && Number(this.order.location.utcOffset) <= 12
        ? Number(this.order.location.utcOffset) * 60
        : Number(this.order.location.utcOffset);

    // Assuming utcOffset is in hours, multiplying by 60 to get minutes
    this.actualTimes = (val ?? []).map(time => moment(time).utcOffset(offset).toDate());
  }

  @Input() dateIndex = 0;
  @Input() timeIndex = 0;
  @Input() loadingTimeMode: string = null;
  @Output() asapClick = new EventEmitter<void>();
  @Output() dateChange = new EventEmitter<Date>();
  @Output() timeChange = new EventEmitter<Date>();

  actualTimes: Date[] = [];

  showTimes = false; // Initialize the variable to true to show the buttons by default
  showDays = false; // Initialize the variable to true to show the buttons by default
  dateLoading = false;
  timeLoading = false;

  selectedDate: Date = null;
  today = moment().startOf('day').toDate();

  ngOnInit() {
    if (this.order) {
      if (this.order.orderReadyTimestamp) {
        this.selectedDate = this.order.orderReadyTimestamp;
      } else {
        this.selectedDate = this.actualTimes[0];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']) {
      this.timeLoading = false;
      this.dateLoading = false;
      // this.dateChange.emit(this.order.orderReadyTimestamp)
    }
    if (changes['availableTimes']) {
      this.dateLoading = false;
      if (this.actualTimes.length > 0) {
        this.selectedDate = this.actualTimes[0];
      }
    }
    if (changes['loadingTimeMode']) {
      if (this.loadingTimeMode === null) {
        this.timeLoading = false;
        this.dateLoading = false;
      }
    }
  }

  asapClicked() {
    this.selectedDate = null;
    this.timeLoading = true;
    this.dateLoading = true;
    this.asapClick.emit();
  }

  onDateChange(date: Date) {
    this.selectedDate = date;
    this.dateLoading = true;
    this.dateChange.emit(date);
  }

  onDateSelectChange(event: any) {
    this.dateChange.emit(new Date(event.target.value));
  }

  onTimeChange(value: any) {
    this.timeLoading = true;
    this.timeChange.emit(value);
  }

  toggleDays() {
    this.showDays = !this.showDays; // Toggle the value of showButtons
  }

  toggleTimes() {
    this.showTimes = !this.showTimes; // Toggle the value of showButtons
  }

  availableTimeTracker(index: number, item: Date) {
    return `${index}${item.toISOString()}`;
  }

  availableDateTracker(index: number, item: Date) {
    return `${index}${item.toISOString()}`;
  }
}
