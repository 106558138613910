import {Injectable} from '@angular/core';
import {PersonicaHttpService} from './personica-http.service';
import {MemberAccessTokenRequest} from './interfaces/member-access-token-request.interface';
import {Observable} from 'rxjs';
import {MemberAccessTokenResponse} from './interfaces/member-access-token-response.interface';
import {SignUpTokenResponse} from './interfaces/sign-up-token-response.interface';
import {CreateMemberRequest} from './interfaces/create-member-request.interface';
import {CreateMemberResponse} from './interfaces/create-member-response.interface';
import {GetActivityRequest} from './interfaces/get-activity-request.interface';
import {GetActivityResponse} from './interfaces/get-activity-response.interface';
import {GetOfferRewardInfoResponse} from './interfaces/get-offer-reward-info-response.interface';
import {GetPointRewardInfoResponse} from './interfaces/get-point-reward-info-response.interface';
import {GetMemberStatusResponse} from './interfaces/get-member-status-response.interface';
import {map, switchMap} from 'rxjs/operators';
import {SSOAuthorizationResponse} from './interfaces/sso-authorization-response.interface';
import {KeyValuePair} from '../olo/interfaces/key-value-pair.interface';
import {HttpParams} from '@angular/common/http';
import {FetchAccessTokenRequest} from './interfaces/fetch-access-token-request.interface';
import {FetchAccessTokenResponse} from './interfaces/fetch-access-token-response.interface';
import {GetMemberResponse} from './interfaces/get-member-response.interface';
import {RefreshTokenResponse} from './interfaces/refresh-token-response.interface';
import {DirectusService} from '../directus/directus.service';

@Injectable({
  providedIn: 'root'
})
export class PersonicaApiService {

  private web = 'web';
  private webeup = 'webeup';

  constructor(
      private personiceHTTP: PersonicaHttpService,
      private directus: DirectusService
  ) {}

  isOauthEnabled(): Observable<boolean> {
    return this.directus.getPersonicaSettings().pipe(map((res) => {
      return res.enable_oauth;
    }));
  }

  getSignUpToken(): Observable<SignUpTokenResponse> {
    return this.personiceHTTP.put<SignUpTokenResponse>('/mobile/getToken', {}, null, null, null);
  }

  getMemberAccessToken(body: MemberAccessTokenRequest): Observable<MemberAccessTokenResponse> {
    return this.personiceHTTP.post<MemberAccessTokenResponse>('/member/login', body, this.web, null, null);
  }

  createMember(body: CreateMemberRequest, accessToken: string): Observable<CreateMemberResponse> {
    return this.personiceHTTP.post<CreateMemberResponse>('/v1/fbmember/create', body, this.webeup, null, accessToken);
  }

  getMemberStatus(emailAddress: string, accessToken: string): Observable<GetMemberStatusResponse> {
    return this.personiceHTTP.get<GetMemberStatusResponse>(`/loyalty/getMemberStatus?email=${emailAddress}`, this.webeup, null, accessToken);
  }

  getMemberDetail(accessToken: string): Observable<GetMemberResponse> {
    return this.personiceHTTP.get<GetMemberResponse>('/v1/fbmember/getMember', this.webeup, null, accessToken);
  }

  getLoyaltyMemberInfo(memberID: string, accessToken: string) {
    return this.personiceHTTP.get(`/loyaltymember/getMemberLoyaltyData/${memberID}`, this.webeup, null, accessToken);
  }

  updateMember(body, accessToken: string) {
    return this.personiceHTTP.put('/v1/fbmember/update', body, this.webeup, null, accessToken);
  }

  refreshToken(accessToken: string): Observable<RefreshTokenResponse> {
    return this.personiceHTTP.post<RefreshTokenResponse>('/oauth2/refreshToken', {}, this.web, null, accessToken);
  }

  // LOYALTY

  getActivity(body: GetActivityRequest, accessToken: string): Observable<GetActivityResponse> {
    return this.personiceHTTP.post<GetActivityResponse>('/loyalty/getActivity', body, this.webeup, null, accessToken);
  }

  getOfferRewardInfo(memberID: string, accessToken: string): Observable<GetOfferRewardInfoResponse> {
    return this.personiceHTTP.get<GetOfferRewardInfoResponse>(`/mobile/getBasicOfferRewardInfo/${memberID}`, this.webeup, null, accessToken);
  }

  getPointRewardInfo(memberID: string, accessToken: string): Observable<GetPointRewardInfoResponse> {
    return this.personiceHTTP.get<GetPointRewardInfoResponse>(`/mobile/getPointRewardInfo/${memberID}`, this.webeup, null, accessToken);
  }

  getMemberLoyaltyRewards(memberID: string, accessToken: string) {
    return this.personiceHTTP.get(`/mobile/getrewards/${memberID}/100`, this.webeup, null, accessToken);
  }

  ssoAuthorization(username: string, password: string, state: string): Observable<SSOAuthorizationResponse> {
    return this.directus.getPersonicaSettings().pipe(switchMap(config => {
      const queryParams: KeyValuePair[] = [
        {key: 'client_id', value: config.client_id},
        {key: 'response_type', value: 'code'},
        {key: 'state', value: state},
        {key: 'scope', value: 'read'},
        {key: 'username', value: username},
        {key: 'password', value: password},
        {key: 'redirect_uri', value: 'https://mybistro.chepri.com/user/oauthcallback'}
      ];
      return this.personiceHTTP.get<SSOAuthorizationResponse>(`/oauth2/authorize_url${this.getQueryParamsString(queryParams)}`, this.web, null, null);
    }));
  }

  fetchSSOAccessToken(body: FetchAccessTokenRequest): Observable<FetchAccessTokenResponse> {
    return this.personiceHTTP.post<FetchAccessTokenResponse>('/oauth2/access_token_url', body, this.web, null, null);
  }

  private getQueryParamsString(pairs: KeyValuePair[]): string {
    let params = new HttpParams();
    pairs.forEach(p => {
      if (p.value !== undefined && p.value !== null) {
        params = params.set(p.key, p.value);
      }
    });
    return params && params.keys().length > 0 ?
        '?' + params.toString()
        : '';
  }
}
