<div class="pt-3 pb-5">
  <div class="d-flex justify-content-end w-100 px-3">
    <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="activeModal.close('Close click')"></i>
  </div>
  <div class="d-flex flex-row flex-wrap gap-3 w-100">
    <div class="w-100 modal-heading m-0 header-font px-3">
      <h1 class="font-size-h1 m-0 fw-bold pt-4 pt-md-0 text-center">Loyalty Balance</h1>
    </div>
    <div class="w-100 d-flex justify-content-center px-3">
      <div class="header-underline m-0"></div>
    </div>
    <div class="w-100 px-3 text-center">
      {{ instructionsCopy }}
    </div>
    <ng-container *ngIf="balanceTotals?.points || balanceTotals?.bankedRewards">
      <div class="rewards-banner p-3 w-100">
        <div class="d-flex flex-row flex-wrap gap-1">
          <ng-container *ngIf="balanceTotals?.points && !balanceTotals?.bankedRewards">
            <div class="d-flex flex-row flex-nowrap gap-2 w-100">
              <div>
                Points: <b>{{ balanceTotals?.points }}</b>
              </div>
              <span *ngIf="balanceTotals?.pointsThreshold">/{{ balanceTotals?.pointsThreshold }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="balanceTotals?.points && balanceTotals?.bankedRewards">
            <div class="d-flex flex-row flex-nowrap gap-2 w-100">
              <div>
                Points/Dollars: <b>{{ balanceTotals?.points }} points,
                  {{ balanceTotals?.bankedRewards | currency: (currencyCode$ | async) }}</b>
              </div>
              <span *ngIf="balanceTotals?.pointsThreshold">/{{ balanceTotals?.pointsThreshold }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!balanceTotals?.points && balanceTotals?.bankedRewards">
            <div class="d-flex flex-row flex-nowrap gap-2 w-100">
              <div>
                Banked Rewards Dollars: <b>{{ balanceTotals?.bankedRewards | currency: (currencyCode$ | async) }}</b>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-row flex-wrap w-100">
      <div class="d-flex flex-row flex-nowrap mx-3 pb-2 border-bottom w-100 fw-bold">
        <div class="grow-1 basis-0">
          Reward Type
        </div>
        <div class="grow-1 basis-0 text-end">
          Amount
        </div>
        <div class="grow-1 basis-0 text-end">
          Exp. Date
        </div>
      </div>
      <ng-container *ngIf="balanceTotals && balanceTotals.rewardAmounts && balanceTotals.rewardAmounts.length">
        <ng-container *ngFor="let balanceAmount of balanceTotals.rewardAmounts">
          <div class="d-flex flex-row flex-nowrap mx-3 py-2 w-100">
            <div class="grow-1 basis-0">
              {{ balanceAmount.description }}
            </div>
            <div class="grow-1 basis-0 text-end">
              {{ balanceAmount.amount }}
            </div>
            <div class="grow-1 basis-0 text-end">
              {{ balanceAmount.expDate | date: 'M/d/y' }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>