import { Component } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {MenuService} from '@modules/menu/services';
import {GlobalStateModel} from '../../../../store/state.model';

@Component({
  selector: 'app-menu-limit-toggle',
  templateUrl: './menu-limit-toggle.component.html',
  styleUrls: ['menu-limit-toggle.component.scss'],
})
export class MenuLimitToggleComponent {
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.menu.allProductsAlwaysAvailable) allProductsAlwaysAvailable$: Observable<boolean>;
  constructor(
    public menuService: MenuService
  ) {}
}
