import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {PurchaseableReward} from '../../../../interfaces/purchaseable-reward.interface';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {TextField} from '../../../../vendors/directus/interfaces/text-field.interface';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModeService} from '../../../../services/mode.service';

@Component({
  selector: 'app-purchaseable-reward-card',
  templateUrl: './purchaseable-reward-card.component.html',
  styleUrls: ['purchaseable-reward-card.component.scss'],
})
export class PurchaseableRewardCardComponent {
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Input() purchaseableReward: PurchaseableReward;
  @Input() availablePoints: number;
  @Input() pointsUnit = 'points';
  @Input() set setLoading(val: boolean) {
    if (!val) {
      this.loading = val;
    }
  }
  @Output() rewardClicked = new EventEmitter<void>();
  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;
  loading = false;

  constructor(
      public modalService: NgbModal,
      public modeService: ModeService,
  ) {
  }
  purchaseClicked() {
    if (this.availablePoints < this.purchaseableReward.pointCost) {
      return;
    }
    this.loading = true;
    this.rewardClicked.emit();
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal'
    });
  }
}
