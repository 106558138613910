import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationService} from '@modules/navigation/services';
import {Select, Store} from '@ngxs/store';
import {combineLatest, Observable} from 'rxjs';
import {GroupOrder} from '../../../../interfaces/group-order.interface';
import {ToastService} from '../../../../services/toast.service';
import {ModeService} from '../../../../services/mode.service';
import {Router} from '@angular/router';
import {Location as URLLocation} from '@angular/common';
import {
    CreateNewOrder,
    GetCurrentGroupOrder,
    StartGroupOrder
} from '../../../../store/actions/order.actions';
import {GlobalStateModel} from '../../../../store/state.model';
import {User} from '../../../../interfaces/user.interface';
import {SubscriptionComponent} from '@common/components';
import {MobileService} from '../../../../services/mobile.service';
import {SetRouteBack, UpdateTitle} from '../../../../store/actions/app.actions';
import {DateTimeService} from '../../../../services/date-time.service';
import {OLOProviderService} from '../../../../vendors/olo/olo-provider.service';
import {OrderService} from '../../../../services/vendor-config-service/order.service';
import {Order} from '../../../../interfaces/order.interface';
import {distinctUntilKeyChanged, filter, take} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-group-order-details',
    templateUrl: './group-order-details.component.html',
    styleUrls: ['group-order-details.component.scss'],
})
export class GroupOrderDetailsComponent extends SubscriptionComponent implements OnInit {
    @Select(state => state.order.order) order$: Observable<Order>;
    @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
    @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
    @ViewChild('infoModal') infoModalRef: TemplateRef<any>;
    title = 'group order details';
    joinUrl;
    menuID;
    groupOrderID: string;
    basketID: string;
    error: string;
    groupOrderInstructions = 'Once others have begun adding items you will see their orders appear in your cart. You can also see your deadline and note in the cart.\n' +
        'To place the order, proceed to checkout as usual.';

    constructor(
        public modalService: NgbModal,
        private store: Store,
        private router: Router,
        public mode: ModeService,
        public mobile: MobileService,
        private navigation: NavigationService,
        private toast: ToastService,
        private urlLocation: URLLocation,
        private dateTimeService: DateTimeService,
        private orderService: OrderService,
    ) {
        super();
    }

    ngOnInit() {
        console.log('group order details init');
        if (this.mode.mode === 'tableside') {
            this.subs.push(
                combineLatest([
                    this.orderService.getService(),
                    this.user$.pipe(filter(user => !!user && !user.isGuest), distinctUntilKeyChanged('userID')),
                    this.order$,
                    this.groupOrder$
                ]).pipe(take(1)).subscribe(([oService, user, order, groupOrder]) => {
                    if (oService instanceof OLOProviderService) {
                        oService.oloAuthToken$.subscribe(() => {
                            if (!groupOrder) {
                                const availableTimes = this.dateTimeService.getTimesToday();
                                const deadline = new Date(availableTimes[availableTimes.length - 1].value);
                                // tslint:disable-next-line:max-line-length
                                this.store.dispatch(new CreateNewOrder(order.location.locationID, order.handoffType, order.deliveryAddress)).subscribe(() => {
                                    // tslint:disable-next-line:max-line-length
                                    this.store.dispatch(new StartGroupOrder('', [], deadline, user.firstName + ' ' + user.lastName)).subscribe(() => {
                                        this.store.dispatch(new GetCurrentGroupOrder(user.firstName + ' ' + user.lastName));
                                    }, (error: any) => {
                                        this.error = error.error.message;
                                    });
                                });
                            }
                        });
                    }
                })
            );
        }
        this.subs.push(
            this.groupOrder$.subscribe(groupOrder => {
                if (groupOrder) {
                    this.groupOrderID = groupOrder.groupOrderID;
                    this.basketID = groupOrder.order.orderID;
                    // tslint:disable-next-line:max-line-length
                    const tablesideUrl = (this.mode.mode === 'tableside' ? '&mode=tableside&location=' + groupOrder.order.location.locationID : '');
                    // tslint:disable-next-line:max-line-length
                    this.joinUrl = 'https://' + window.location.hostname + '/group-order/join/' + this.groupOrderID + '/?basket=' + this.basketID + tablesideUrl;
                    this.menuID = groupOrder.order.location.locationID;
                    this.store.dispatch(new UpdateTitle(this.title));
                    this.store.dispatch(new SetRouteBack('menu/' + this.menuID));
                }
            })
        );
    }

    goToMenu() {
        this.navigation.navigateToMenuPage(this.menuID);
    }

    goToDetails() {
        this.navigation.navigateToUpdateGroupOrderPage(this.groupOrderID);
    }

    closePage() {
        // Allow fade out animation to play before navigating back
        setTimeout(
            // () => this.router.navigate(['..']),
            () => this.urlLocation.back(),
            100
        );
    }

    copyLink() {
        navigator.clipboard.writeText(this.joinUrl).then(() => {
            this.toast.success('Group Link Copied ✔');
        });
    }

    openInfoModal(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.modalService.dismissAll();
        this.modalService.open(this.infoModalRef, {
            centered: true,
            animation: true,
            windowClass: 'card-info-modal'
        });
    }
}
