<div class="p-3">
  <div class="d-flex flex-column p-3 bg-white de-card-radius">
    <div class="mb-3">
      <h2 class="fw-bold text-start">Nutrition Key</h2>
    </div>

    <div class="d-flex flex-wrap text-center">
      <div
        class="d-flex align-items-center col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0 px-0 px-sm-3">
        <img
          src="../../../../img/allergen-spicy-icon.svg"
          alt="spicy icon"
          class="me-1" />
        Spicy
      </div>

      <div
        class="d-flex align-items-center col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0 px-0 px-sm-3">
        <img
          src="../../../../img/allergen-vegan-icon.svg"
          alt="vegan icon"
          class="me-1" />
        Vegan
      </div>

      <div
        class="d-flex align-items-center col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0 px-0 px-sm-3">
        <img
          src="../../../../img/allergen-allergen-icon.svg"
          alt="allergen icon"
          class="me-1" />
        Allergen
      </div>

      <div
        class="d-flex align-items-center col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0 px-0 px-sm-3">
        <img
          src="../../../../img/allergen-glutenfree-icon.svg"
          alt="gluten-free icon"
          class="me-1" />
        Gluten-Free
      </div>
    </div>

    <ng-container *ngIf="textFields?.allergen_information">
      <ion-row class="mt-3">
        {{ textFields?.allergen_information }}
      </ion-row>
    </ng-container>
  </div>
</div>
