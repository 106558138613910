import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'timezoneConvert',
})
export class TimezoneConvertPipe implements PipeTransform {
  transform(value: Date, formatString = 'YYYY-MM-DD HH:mm:ss', targetTimezone: string): string {
    if (!value || !(value instanceof Date) || isNaN(value.getTime())) {
      return '';
    }
    if (!targetTimezone || !moment.tz.zone(targetTimezone)) {
      return '';
    }
    return moment.tz(value, targetTimezone).format(formatString);
  }
}
