import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MobileService } from 'src/services/mobile.service';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { filter, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { Router } from '@angular/router';
import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { Select, Store } from '@ngxs/store';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import { SetOrderReadyTime } from '../../../../store/actions/order.actions';
import { LogOut } from '../../../../store/actions/user.actions';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';

import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Browser } from '@capacitor/browser';
import { NavigationService } from '@modules/navigation/services';
import { Capacitor } from '@capacitor/core';
import { ModeService } from '../../../../services/mode.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.navbarSettings)
  navbarSettings$: Observable<NavbarSettings>;
  @Select(state => state.app.mainSettings) settings$: Observable<MainSettings>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;

  @Input() settings;
  @Input() handoffOverride: boolean;
  @Input() deliveryToggleLoading: boolean;
  @Input() order: Order;
  @Input() user: User;
  @Input() doneLoading: boolean;

  @Output() clickedLogout = new EventEmitter<any>();
  @Output() addNewOrderType = new EventEmitter<any>();
  @Output() startNewOrder = new EventEmitter<any>();
  @Output() startPickupOrder = new EventEmitter<any>();
  @Output() startCurbsideOrder = new EventEmitter<any>();
  @Output() startDineInOrder = new EventEmitter<any>();
  @Output() startDriveThruOrder = new EventEmitter<any>();
  @Output() startDeliveryOrder = new EventEmitter<any>();

  vendorSetup: VendorSetup;
  // order: Order;
  // user: User;
  branding: Branding;
  cartIcon: string;
  showPrice: boolean;
  showOrderButton: boolean;
  orderButtonText: string;
  timeSelectionLoading = false;
  private subs: Subscription[] = [];

  constructor(
    public mobile: MobileService,
    private store: Store,
    private router: Router,
    private navigation: NavigationService,
    private mode: ModeService
  ) {
    this.subs.push(
      this.order$.pipe(filter(o => o !== null)).subscribe(order => {
        if (order) {
          // this.order = order;
          this.timeSelectionLoading = false;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        const previous = this.user;
        // this.user = user;
        if (previous && !user) {
          this.navigation.navigateToHomePage();
        }
      })
    );
    this.subs.push(
      this.branding$.subscribe(branding => {
        if (branding) {
          this.branding = branding;
        }
      })
    );
    this.subs.push(
      this.navbarSettings$.subscribe(navbar => {
        if (navbar) {
          this.cartIcon = navbar.cart_type;
          this.showPrice = navbar.show_price;
          this.showOrderButton = navbar.show_order_button;
          this.orderButtonText = navbar.order_button_text;
        }
      })
    );
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  selectedDateTime(selectedTime: Date | 'asap') {
    this.timeSelectionLoading = true;
    this.store
      .dispatch(new SetOrderReadyTime(moment(selectedTime).utcOffset(this.order.location.utcOffset)))
      .toPromise()
      .then(() => {
        this.timeSelectionLoading = false;
      });
  }

  logoutClicked() {
    FacebookLogin.getCurrentAccessToken()
      .then(result => {
        if (result.accessToken) {
          FacebookLogin.logout();
        }
      })
      .catch(() => {});
    this.store
      .dispatch(new LogOut())
      .toPromise()
      .then(() => {
        this.navigation.navigateToLoginPage();
      });
  }

  newOrderTypeClicked() {
    this.addNewOrderType.emit();
  }

  startPickup() {
    this.startPickupOrder.emit();
  }

  startCurbside() {
    this.startCurbsideOrder.emit();
  }

  startDriveThru() {
    this.startDriveThruOrder.emit();
  }

  startDineIn() {
    this.startDineInOrder.emit();
  }

  startDelivery() {
    this.startDeliveryOrder.emit();
  }

  startOrder(order) {
    this.startNewOrder.emit(order);
  }

  homeClicked() {
    this.settings$
      .pipe(
        filter(s => s !== null),
        take(1)
      )
      .subscribe(settings => {
        if (
          settings.automatically_route_to_locations_page &&
          settings.brochure_site_url != null &&
          settings.brochure_site_url !== '' &&
          Capacitor.getPlatform() === 'web'
        ) {
          Browser.open({
            url: settings.brochure_site_url,
            windowName: '_parent',
          });
        } else {
          this.navigation.navigateToHomePage();
        }
      });
  }
}
