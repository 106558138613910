<div class="w-100">
  <ng-container *ngIf="displayPreviousOrders && displayPreviousOrders.length > 0">
    <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
      <div class="w-100 wrapper">
        <div class="w-100"
             style="margin-top: 4rem; margin-bottom: 4rem;"
             [class.container]="navbarSettings.fixed_navbar_width"
             [class.px-lg-3]="!navbarSettings.fixed_navbar_width">
          <div class="w-100" [class.px-3]="mobile.isMobile">
            <h2 class="text-start fw-bolder header-font">Order Again</h2>
            <p class="text-start">Reorder one of your recent orders</p>
          </div>
          <div style="display: grid; grid-gap: 16px">
            <div class="w-100 my-3"
                 [class.reorder-container-mobile]="isMobile"
                 [class.reorder-container-desktop]="!isMobile">

              <ng-container *ngIf="limitedPastOrders$ | async as pastOrders">
                <!-- Always display 5 cards -->
                <app-reorder-card
                  *ngFor="let order of pastOrders; let i = index"
                  [order]="order"
                  [isLoading]="isLoading[i]"
                  (cancelOrder)="cancelOrderClicked(order)"
                  (clickedReorder)="reorder(order, i)"
                  (clickedViewConfirmation)="viewConfirmation(order)"
                  (editOrder)="editOrderClicked(order)"
                  [class.placeholder]="!order"
                ></app-reorder-card>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
