// offers-section.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Offer } from 'src/interfaces/offer.interface';
import { NavigationService } from '@modules/navigation/services';

@Component({
  selector: 'app-offers-section',
  templateUrl: './offers-section.component.html',
  styleUrls: ['./offers-section.component.scss'],
})
export class OffersSectionComponent {
  @Input() offers: Offer[];

  @Output() redeemOffer = new EventEmitter<Offer>();

  locationsRoute = this.navigation.getLocationsPageSlug();

  constructor(private navigation: NavigationService) {}

  offerTrackBy(index: number, item: Offer): number {
    return item.id;
  }

  onRedeemOffer(offer: Offer): void {
    this.redeemOffer.emit(offer);
  }
}
