import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CheckoutPageService } from '@modules/checkout/containers/checkout/base/checkout-page.service';
import { CheckoutComponent } from '@modules/checkout/containers/checkout/base/checkout.component';
import { UntypedFormGroup } from '@angular/forms';
import { collapseAnimation } from 'angular-animations';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
  providers: [CheckoutPageService],
  animations: [collapseAnimation()],
})
export class TipComponent implements OnChanges {
  isCustom = false;

  @Input() tipForm: UntypedFormGroup;
  @Input() selectedTip: number;
  @Input() subTotalCents: number;
  @Input() currencyCode: string;
  @Input() kiosk = false;
  @Output() addTipClicked = new EventEmitter<any>();
  @Output() blurClicked = new EventEmitter();
  @Output() focusClicked = new EventEmitter();

  ngOnChanges() {
    this.isCustom = this.selectedTip === null;
  }

  addTip(amount: any) {
    this.addTipClicked.emit(amount);
    if (!isNaN(Number(amount))) {
      this.isCustom = false;
    }
  }

  emitBlur() {
    this.blurClicked.emit();
  }

  emitFocus() {
    this.focusClicked.emit();
  }

  customClick() {
    this.isCustom = true;
  }
}
