import { Component } from '@angular/core';
import {take} from 'rxjs/operators';
import {NavigationService} from '@modules/navigation/services';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Order} from '../../../../interfaces/order.interface';
import {OrderTypeService} from '@modules/cart/services/order-type.service';

@Component({
  selector: 'app-order-now-button',
  templateUrl: './order-now-button.component.html',
  styleUrls: ['order-now-button.component.scss'],
})
export class OrderNowButtonComponent {

  @Select(state => state.order.order) order$: Observable<Order>;

  orderNowLoading = false;

  constructor(
    private navigation: NavigationService,
    private orderTypeService: OrderTypeService
  ) {}

  orderNow() {
    this.orderNowLoading = true;
    this.order$.pipe(take(1)).toPromise().then(order => {
      if (order) {
        const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
        this.navigation.navigateToMenuPage(menuID).then(() => this.orderNowLoading = false);
      } else {
        this.orderTypeService.setNewOrderType(false);
        this.orderNowLoading = false;
      }
    });
  }

}
