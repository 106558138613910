import { Injectable } from '@angular/core';
import { FlybuyHttpService } from './flybuy-http.service';
import { OrdersRequestBase } from './interfaces/orders-request.interface';
import { Observable } from 'rxjs';
import { OrdersResponseBase } from './interfaces/orders-response.interface';
import {
  CustomerRatingEventRequest,
  LocationUpdateEventRequest,
  OrderEventsRequestBase,
  StateChangeEventRequest,
} from './interfaces/order-events-request.interface';
import { FlybuySite, GetSitesResponse } from './interfaces/get-sites-response.interface'
import { map } from 'rxjs/operators'
import { CustomerResponse, CustomersResponseBase } from './interfaces/customers-response.interface'
import { CreateCustomerRequest, CustomersRequestBase, UpdateCustomerRequest } from './interfaces/customers-request.interface'

@Injectable({
  providedIn: 'root'
})
export class FlybuyApiService {

  constructor(
    private http: FlybuyHttpService,
  ) {
  }

  // ORDERS

  createAnOrder(order: OrdersRequestBase): Observable<OrdersResponseBase> {
    const resource = `/orders`;
    return this.http.post<OrdersResponseBase>(resource, order);
  }

  createAnOrderWithTags(order: OrdersRequestBase): Observable<OrdersResponseBase> {
    const resource = `/orders?include=tags`;
    return this.http.post<OrdersResponseBase>(resource, order);
  }

  // createAnOrderWithDeliveryDispatchRequest(){}

  getOrderByFlybuyIdentifier(orderID: number): Observable<OrdersResponseBase> {
    const resource = `/orders/${orderID}?include=site,tags`;
    return this.http.get<OrdersResponseBase>(resource);
  }

  getOrderByPartnerIdentifier(partnerID: string): Observable<OrdersResponseBase> {
    const resource = `/orders?partner_identifier=${partnerID}`;
    return this.http.get<OrdersResponseBase>(resource);
  }

  getAllOrders(): Observable<OrdersResponseBase> {
    const resource = `/orders`;
    return this.http.get<OrdersResponseBase>(resource);
  }

  getAllOrdersForCustomer(customerID: number): Observable<OrdersResponseBase> {
    const resource = `/customers/${customerID}/orders`;
    return this.http.get<OrdersResponseBase>(resource);
  }

  // ORDER EVENTS

  sendStateChangeEvent(request: StateChangeEventRequest) {
    const resource = `/events`;
    const body: OrderEventsRequestBase = {
      data: request,
    };
    return this.http.post(resource, body);
  }

  sendLocationUpdateEvent(request: LocationUpdateEventRequest) {
    const resource = `/events`;
    const body: OrderEventsRequestBase = {
      data: request,
    };
    return this.http.post(resource, body);
  }

  sendCustomerRatingEvent(request: CustomerRatingEventRequest) {
    const resource = `/events`;
    const body: OrderEventsRequestBase = {
      data: request,
    };
    return this.http.post(resource, body);
  }

  // SITES

  getAllSites(): Observable<FlybuySite[]> {
    const resource = `/sites`;
    return this.http.get<GetSitesResponse>(resource).pipe(map(res => res.data));
  }

  getSiteByPartnerIdentifier(partnerIdentifier: string): Observable<FlybuySite> {
    const resource = `/sites?partner_identifier=${partnerIdentifier}`;
    return this.http.get<GetSitesResponse>(resource).pipe(map(res => res.data[0]));
  }

  // CUSTOMERS

  getCustomerDetails(customerID: number): Observable<CustomersResponseBase<CustomerResponse>> {
    const resource = `/customers/${customerID}`;
    return this.http.get<CustomersResponseBase<CustomerResponse>>(resource);
  }

  getAllCustomers(): Observable<CustomersResponseBase<CustomerResponse[]>> {
    const resource = `/customers`;
    return this.http.get<CustomersResponseBase<CustomerResponse[]>>(resource);
  }

  createCustomer(request: CreateCustomerRequest): Observable<CustomersResponseBase<CustomerResponse>> {
    const resource = '/customers'
    const body: CustomersResponseBase<CreateCustomerRequest> = {
      data: request
    };
    return this.http.post<CustomersResponseBase<CustomerResponse>>(resource, body);
  }

  updateCustomer(customerID: number, request: UpdateCustomerRequest): Observable<CustomersResponseBase<CustomerResponse>> {
    const resource = `/customers/${customerID}`;
    const body: CustomersRequestBase<UpdateCustomerRequest> = {
      data: request
    };
    return this.http.put<CustomersResponseBase<CustomerResponse>>(resource, body);
  }


}
