import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditcardtransform' })
export class CreditCardTransformPipe implements PipeTransform {
  transform(input: any) {
    if (input !== null) {
        if (input.length === 16) {
            return '**** **** **** ' + input.substr(12);
        } else if (input.length === 15) {
            return '**** ****** *' + input.substr(11);
        }
    }
  }
}