<ng-container *ngIf="vendorSetup$ | async as vendorSetup">
  <ng-container *ngIf="!chooseDelivery else chooseDeliveryLocation">
    <div class="d-flex flex-column gap-4 grow-1">
      <h4 class="text-center w-100 text-light fw-bold">
        Delivery Information
      </h4>

      <div class="w-100 d-flex justify-content-center pb-3">
        <div class="header-underline m-0"></div>
      </div>

      <form class="w-100 d-flex justify-content-center" [formGroup]="deliveryForm">
        <div class="w-100 delivery-info-fields d-flex flex-row flex-wrap gap-3">
          <div class="w-100">
            <label for="autocompletemodal" class="text-light form-label">Delivery Address*</label>
            <ng-container *ngIf="provider === 'google'">
              <input type="text" #autocompletemodal id="autocompletemodal" ngx-google-places-autocomplete
                     (onAddressChange)="placeChanged($event)" formControlName="deliveryAddress"
                     placeholder="Delivery Address" class="form-control de-light de-border de-input w-100"
                     animated="true" show-cancel-button no-padding [options]="autocompleteOptions"/>
            </ng-container>
            <ng-container *ngIf="provider !== 'google'">
              <input type="text" formControlName="deliveryAddress" placeholder="Delivery Address"
                     class="form-control de-light de-border de-input w-100" animated="true" show-cancel-button
                     no-padding/></ng-container>
          </div>
          <div class="w-100">
            <label for="secondDeliveryAddress" class="text-light form-label">Delivery Address Line 2</label>
            <input placeholder="Suite, apartment, building, floor, etc."
                   class="form-control de-light de-border de-input w-100" formControlName="secondDeliveryAddress"
                   id="secondDeliveryAddress">
          </div>
          <div class="w-100">
            <label for="deliveryInstructions" class="text-light form-label">Delivery Instructions</label>
            <textarea placeholder="Please leave any instructions for your driver..."
                      class="form-control de-light de-border de-inputw-100" formControlName="deliveryInstructions" id="deliveryInstructions"
                      maxlength="{{deliveryInstructionsLength}}"></textarea>
          </div>

          <div class="w-100 d-flex flex-row flex-wrap gap-3 font-size-body-small"
               *ngIf="displayOrder?.handoffType === handoffs.delivery || displayOrder?.handoffType === handoffs.dispatch">
            <div class="w-100">Changing your delivery address may cause your order to be delivered from a different
              store.
            </div>
            <div class="w-100">We cannot guarantee that all items can be transferred to your new location.</div>
          </div>

          <div class="w-100 text-danger" *ngIf="displayError">
            {{displayError}}
          </div>

          <div class="w-100">
            <ng-container *ngIf="mainSettings$ | async as mainSettings">
              <ng-container *ngIf="!mainSettings.allow_users_to_pick_delivery_location else allowDeliverySelection">
                <div class="d-flex w-100 justify-content-end gap-3">
                  <button *ngIf="!isLoading" class="de-primary-btn-outline color-button w-fit-content">
                    Back
                  </button>
                  <button *ngIf="!isLoading" class="de-primary-btn color-button w-fit-content"
                          [disabled]="deliveryForm.invalid || !fullAddress" expand="block" (click)="startDeliveryOrder()">
                    Continue
                  </button>
                </div>
                <button *ngIf="isLoading" class="de-primary-btn primary-btn w-100 w-fit-content" color="primary" expand="block">
                  <div class="de-btn-loader"></div>
                </button>
              </ng-container>
              <ng-template #allowDeliverySelection>
                <div class="d-flex w-100 justify-content-end gap-3">
                  <button *ngIf="!isLoading" class="de-primary-btn-outline color-button w-100 w-fit-content">
                    Back
                  </button>
                  <button *ngIf="!isLoading" class="de-primary-btn color-button w-100 w-fit-content"
                          [disabled]="deliveryForm.invalid || !fullAddress" (click)="checkAddress()">Continue
                  </button>
                  <button *ngIf="isLoading" class="de-primary-btn color-button w-100 w-fit-content">
                    <div class="de-btn-loader"></div>
                  </button>
                </div>
              </ng-template>
            </ng-container>
          </div>


          <div class="w-100 text-center" *ngIf="displayError">
            <div *ngIf="!isLoading" class="text-center dark-link w-100"
                 (click)="typeSelected(0)">
              Pickup Instead
            </div>
          </div>

        </div>
      </form>
    </div>
  </ng-container>
</ng-container>

<ng-template #chooseDeliveryLocation>
  <div class="d-flex flex-column gap-4 grow-1">
    <h4 class="text-center w-100 text-light fw-bold">
      Delivery Information
    </h4>
    <div class="w-100 d-flex justify-content-center pb-3">
      <div class="header-underline m-0"></div>
    </div>
    <div class="d-flex flex-row flex-wrap">
      <div class="fw-bold delivery-address w-100 text-light">Your Delivery Address - <span
          (click)="chooseDelivery = false" class="changeAddress">Change</span>
      </div>
      <div class="w-100 text-light">{{fullAddress.addressComponents.address1}}</div>
      <div class="w-100 text-light" *ngIf="address2.value">{{address2.value}}</div>
      <div class="w-100 text-light">{{fullAddress.addressComponents.city}}
        , {{fullAddress.addressComponents.state}} {{fullAddress.addressComponents.zipCode}}
      </div>
    </div>
    <div class="w-100" style="max-height: 80vh; overflow-y: auto">
      <ng-container *ngIf="deliveryLocations$ | async as deliveryLocations">
        <hr class="border-top my-3">
        <ng-container *ngFor="let location of deliveryLocations">
          <ng-container *ngIf="location.canDeliver">
            <div class="pointer bg-white border p-3"
                 style="display: grid; grid-template-columns: 1fr auto; place-items: center start; border-radius: var(--cms-card-radius);"
                 (click)="deliveryLocationSelect(location)">
              <div>
                <div class="fw-bold">{{location.name}}</div>
                <ng-container *ngIf="location.isOpen else closed">
                  <div><span class="badge bg-success">OPEN</span></div>
                </ng-container>
                <ng-template #closed>
                  <div><span class="badge bg-danger">CLOSED</span></div>
                </ng-template>
                <small class="d-block">{{location.address | streetAddress}}</small>
                <small class="d-block">{{location.address.city}}
                  , {{location.address.state}} {{location.address.zipCode}}</small>
                <ng-container *ngIf="location.supportsDelivery else dispatchHours">
                  <small class="d-block">{{location.deliveryHours[0].start | date: 'shortTime'}}
                    - {{location.deliveryHours[0].end | date: 'shortTime'}}</small>
                </ng-container>
                <ng-template #dispatchHours>
                  <small class="d-block">{{location.dispatchHours[0].start | date: 'shortTime'}}
                    - {{location.dispatchHours[0].end | date: 'shortTime'}}</small>
                </ng-template>
              </div>
              <div style="height: 60px; width: 60px; display: grid; place-items: center"
                   *ngIf="(!isLoading || location.locationID !== selectedLocation?.locationID) else startingOrder">
                <i class="bi bi-chevron-right font-size-subheading-1" style="color: var(--ion-color-primary);"></i>
              </div>
              <ng-template #startingOrder>
                <div style="height: 60px; width: 60px; display: grid; place-items: center">
                  <div class="de-btn-loader-secondary"></div>
                </div>
              </ng-template>
            </div>
            <hr class="border-top my-3">
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
