import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Address } from 'src/interfaces/address.interface';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressSearch } from '../../models/locations.address-search';
import { LocationSearchService } from '../../services/locations-search.service';
import { debounceTime } from 'rxjs/operators';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { MobileService } from '../../../../services/mobile.service';
import { Options } from '@barkhub/ngx-google-places-autocomplete';
import { LocationsService } from '@modules/locations/services';
import { Geolocation } from '@capacitor/geolocation';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';

declare let google: any;

@Component({
  selector: 'app-locations-search',
  templateUrl: './locations-search.component.html',
  styleUrls: ['locations-search.component.scss'],
  animations: [
    trigger('growInOut', [
      state('void', style({ height: '0' })),
      state('*', style({ height: '*' })),
      transition('void <=> *', animate('400ms ease')),
    ]),
  ],
})
export class LocationsSearchComponent implements OnInit {
  results: AddressSearch[] = [];

  selectedResult: any;
  hasSelected = false;
  showGeoDropdown = false;
  searchBarClicked = false;

  searchOptions: Options = {
    fields: ['address_components', 'formatted_address', 'geometry'],
    types: [
      'street_number',
      'street_address',
      'postal_code',
      'locality',
      'political',
    ],
    origin: null,
    componentRestrictions: null,
    bounds: null,
    strictBounds: false,
  };

  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl(''),
  });

  @Input() showListView: boolean;

  @Input() set guestDeliveryAddress(value: Address) {
    if (value) {
      const formattedAddress =
        value.address1 + ', ' + value.city + ', ' + value.state;
      this.searchForm.get('search').setValue(formattedAddress);
      this.searchForm.get('address2').setValue(value.address2);
    }
  }

  @ViewChild('autocomplete', { read: ElementRef }) searchElementRef: ElementRef;

  runToggleAnimation = false;
  overDropdown = false;

  fullAddress: AddressSearch;
  searchAttempt = false;

  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private locationsSearch: LocationSearchService,
    private geo: GeocodingService,
    public mobile: MobileService,
    public locations: LocationsService,
    private ngZone: NgZone,
    private store: Store
  ) {}

  ngOnInit() {
    this.locations.gettingGeolocation$.subscribe(gettingGeolocation => {
      if (gettingGeolocation) {
        this.search.disable();
      } else {
        this.search.enable();
      }
    });
    this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      if (this.locations.locationProvider !== 'google') {
        this.hasSelected =
          this.search?.value === this.fullAddress?.formattedAddress;
        try {
          // tslint:disable-next-line:max-line-length
          let addresses: any[] = await this.locationsSearch
            .getProvider(this.locations.locationProvider)
            .search({ query: this.search?.value });
          addresses = addresses.map(a =>
            this.locationsSearch.addressResultToAddressSearch(
              this.locations.locationProvider,
              a
            )
          );
          this.results = addresses.filter(a =>
            this.locationsSearch.validateAddress(a)
          );
        } catch (e) {
          if (this.locations.locationProvider === 'chepri') {
            this.locations.locationProvider = 'osm';
          } else if (this.locations.locationProvider === 'osm') {
            this.locations.locationProvider = 'google-geo';
          } else {
            this.locations.locationProvider = 'google';
          }
        }
      }
    });
    if (Capacitor.getPlatform() === 'web') {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(result => {
          result.onchange = () => {
            if (result.state === 'granted') {
              this.locations.updateGeolocationAllowed(true);
              if (
                !this.store.selectSnapshot(
                  (state: GlobalStateModel) => state.location.pickupLocations
                )?.length
              ) {
                this.locations.getUserLocation();
              }
            } else if (result.state === 'denied') {
              this.locations.updateGeolocationAllowed(false);
            }
          };
          if (result.state === 'granted') {
            this.locations.updateGeolocationAllowed(true);
            if (
              !this.store.selectSnapshot(
                (state: GlobalStateModel) => state.location.pickupLocations
              )?.length
            ) {
              this.locations.getUserLocation();
            }
          }
        });
      } else {
        if (
          !this.store.selectSnapshot(
            (state: GlobalStateModel) => state.location.pickupLocations
          )?.length
        ) {
          this.locations.getUserLocation();
        }
      }

      // }
      // if (!this.store.selectSnapshot((state: GlobalStateModel) => state.location.pickupLocations)?.length) {
      //   this.locations.getUserLocation();
      // }
    } else {
      Geolocation.checkPermissions()
        .then(result => {
          console.log(result);
          if (result.location === 'granted') {
            this.locations.updateGeolocationAllowed(true);
            if (
              !this.store.selectSnapshot(
                (state: GlobalStateModel) => state.location.pickupLocations
              )?.length
            ) {
              this.locations.getUserLocation();
            }
          } else if (result.location === 'denied') {
            this.locations.updateGeolocationAllowed(false);
          }
        })
        .catch(() => this.locations.updateGeolocationAllowed(false));
    }
  }

  switchView() {
    this.showListView = !this.showListView;
    this.runToggleAnimation = !this.runToggleAnimation;
  }

  searchTextChanged(search?: AddressSearch) {
    if (!search) {
      this.geo.geocode(this.search.value).then(res => {
        this.fullAddress = {
          addressComponents: null,
          formattedAddress: null,
          searchString: null,
        };
        this.fullAddress.addressComponents = {
          latitude: res.lat,
          longitude: res.lng,
          address1: null,
          address2: null,
          city: null,
          state: null,
          zipCode: null,
        };
        this.searchAttempt = true;
        this.pickupAddressChanged();
      });
    } else {
      this.searchAttempt = true;
      this.pickupAddressChanged();
    }
  }

  geolocationDropdownRequest(event) {
    event.stopPropagation();
    this.locations.getUserLocation();
    this.showGeoDropdown = false;
  }

  pickupAddressChanged() {
    this.locations.searchTextChanged({
      ...this.fullAddress,
      searchString: this.search?.value,
    });
    // if (this.search?.value !== '') {
    //   if (this.fullAddress && this.fullAddress.addressComponents && this.fullAddress.formattedAddress) {
    //     this.locations.searchTextChanged({...this.fullAddress, searchString: null});
    //   } else {
    //   }
    // }
  }

  resultSelected(result: AddressSearch) {
    this.fullAddress = result;
    if (this.searchForm.get('address2')?.value) {
      this.fullAddress.addressComponents.address2 =
        this.searchForm.get('address2')?.value;
    }
    this.hasSelected = true;
    this.search.setValue(this.fullAddress.formattedAddress);
    this.searchTextChanged(this.fullAddress);
  }

  placeChanged(place) {
    if (place.address_components) {
      this.fullAddress = this.locationsSearch.googlePlaceToAddressSearch(
        place,
        this.search.value
      );
      this.search.setValue(this.fullAddress.formattedAddress);
      this.searchTextChanged(this.fullAddress);
    }
  }

  showGeoLocation() {
    this.showGeoDropdown = this.searchForm.get('search').value.length === 0;
  }

  hideGeoLocation() {
    if (!this.overDropdown) {
      this.showGeoDropdown = false;
    }
  }

  fieldClicked() {
    this.searchBarClicked = true;
  }

  overList() {
    this.overDropdown = true;
  }

  leftList() {
    this.overDropdown = false;
  }

  getTypeOfGoogle() {
    return typeof google;
  }

  get search() {
    return this.searchForm.get('search');
  }

  protected readonly location = location;
}
