import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProvider } from 'src/providers/user-provider.interface';
import { UserService } from 'src/services/vendor-config-service/user.service';
import { User } from '../../../../../interfaces/user.interface';
import { Vehicle } from 'src/interfaces/vehicle.interface';
import { OrderService } from 'src/services/vendor-config-service/order.service';

@Injectable()
export class SavedVehiclesPageService {

    private userProvider: UserProvider;
    private currentUser: User;
    private savedVehicles: Vehicle[];

    private savedVehiclesSubject = new BehaviorSubject<Vehicle[]>(null);
    private errorSubject = new BehaviorSubject<Error>(null);

    savedVehicles$ = this.savedVehiclesSubject.asObservable();
    error$ = this.errorSubject.asObservable();

    constructor(private userService: UserService,
        private orderService: OrderService) {
        userService.getService().subscribe(provider => {
            this.userProvider = provider;
            this.userProvider.getLoggedInUserInfo().subscribe(user => {
                this.setAndEmitCurrentUser(user);
                // this.setAndEmitSavedVehicles(currentUser.vehicles);
            });
        });
    }

    deleteSavedVehicle(vehicle: Vehicle) {
        // this.userProvider.deleteSavedVehicle().subscribe(savedVehicles => {
        //     this.setAndEmitSavedVehicles(savedVehicles);
        // });
    }

    private setAndEmitCurrentUser(user: User) {
        this.currentUser = user;
        // this.currentUserSubject.next(user);
    }

    private setAndEmitSavedVehicles(vehicle: Vehicle[]) {
        this.savedVehicles = vehicle;
        this.savedVehiclesSubject.next(vehicle);
    }

}
