import {Injectable} from '@angular/core';
import {SpendgoHttpService} from './spendgo-http.service';
import {CreateMemberRequest, CreateMemberResponse} from './interfaces/create-member.interface';
import {Observable} from 'rxjs';
import {UpdateMemberRequest} from './interfaces/update-member.interface';
import {RetrieveMemberBalanceRequest, RetrieveMemberBalanceResponse} from './interfaces/retrieve-member-balance.interface';
import {SignInMemberRequest, SignInMemberResponse} from './interfaces/sign-in-member.interface';
import {RefreshTokenResponse} from './interfaces/refresh-token.interface';
import {RetrieveMemberRequest, RetrieveMemberResponse} from './interfaces/retrieve-member.interface';
import {ResetPasswordRequest} from './interfaces/reset-password.interface';
import {RetrieveMemberStatusRequest, RetrieveMemberStatusResponse} from './interfaces/retrieve-member-status.interface';

@Injectable({
  providedIn: 'root'
})
export class SpendgoAPIService {

  constructor(
      private http: SpendgoHttpService
  ) {}

  retrieveMemberStatus(body: RetrieveMemberStatusRequest): Observable<RetrieveMemberStatusResponse> {
    const resource = '/lookup';
    return this.http.post<RetrieveMemberStatusResponse>(resource, body);
  }

  createMember(body: CreateMemberRequest): Observable<CreateMemberResponse> {
    const resource = '/add';
    return this.http.post<CreateMemberResponse>(resource, body);
  }

  updateMember(body: UpdateMemberRequest, accessToken: string): Observable<{}> {
    const resource = '/update';
    return this.http.post<{}>(resource, body, accessToken);
  }

  retrieveMemberBalance(body: RetrieveMemberBalanceRequest, accessToken: string): Observable<RetrieveMemberBalanceResponse> {
    const resource = '/balance';
    return this.http.post<RetrieveMemberBalanceResponse>(resource, body, accessToken);
  }

  signInMember(body: SignInMemberRequest): Observable<SignInMemberResponse> {
    const resource = '/signin';
    return this.http.post<SignInMemberResponse>(resource, body);
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenResponse> {
    const resource = '/signin/refreshtoken';
    return this.http.post<RefreshTokenResponse>(resource, {}, refreshToken);
  }

  retrieveMember(body: RetrieveMemberRequest, accessToken: string): Observable<RetrieveMemberResponse> {
    const resource = '/get';
    return this.http.post<RetrieveMemberResponse>(resource, body, accessToken);
  }

  signOut(accessToken: string): Observable<{}> {
    const resource = '/signoff';
    return this.http.post(resource, {}, accessToken);
  }

  resetPassword(body: ResetPasswordRequest): Observable<{}> {
    const resource = '/forgotPassword';
    return this.http.post<{}>(resource, body);
  }
}
