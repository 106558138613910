import { Injectable } from '@angular/core';
import { DateTime } from '../models/chepri.date-time';
import moment from 'moment-timezone';
import { TimeFrame } from '../interfaces/time-frame.interface';
import { Order } from '../interfaces/order.interface';
import { HandoffType } from '../interfaces/handoff-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  order: Order;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  getDates(): { key: string; value: TimeFrame }[] {
    const today = moment();
    const newArr: { key: string; value: TimeFrame }[] = [];
    let pickupHours: TimeFrame[];
    switch (this.order.handoffType) {
      case HandoffType.pickup:
        pickupHours = this.order?.location?.pickupHours;
        break;
      case HandoffType.delivery:
        pickupHours = this.order?.location?.deliveryHours;
        break;
      case HandoffType.dispatch:
        pickupHours = this.order?.location?.dispatchHours;
        break;
      case HandoffType.curbside:
        pickupHours = this.order?.location?.curbsideHours;
        break;
      case HandoffType.dineIn:
        pickupHours = this.order?.location?.tablesideHours;
        break;
      case HandoffType.driveThru:
        pickupHours = this.order?.location?.driveThruHours;
        break;
      default:
        pickupHours = this.order?.location?.pickupHours;
        break;
    }
    newArr.push({
      key: 'Select a date',
      value: null,
    });
    if (pickupHours && pickupHours.length) {
      pickupHours.forEach(c => {
        const start = moment(c.start);
        if (start.isSameOrAfter(today, 'day')) {
          let suffix;
          if (start.isSame(today, 'day') && moment(today).isSameOrBefore(moment(c.end).subtract(15, 'minute'), 'minute')) {
            suffix = ' (Today)';
          } else if (moment(start).isSame(moment(today).add(1, 'day'), 'day')) {
            suffix = ' (Tomorrow)';
          } else {
            suffix = start.format(' (dddd)');
          }
          if (moment(today).isSameOrBefore(moment(c.end), 'minute')) {
            newArr.push({
              key: start.format('M/D') + suffix,
              value: c,
            });
          }
        }
      });
    }
    const todayTimeFrame = newArr.find(day => day.key.includes(' (Today)'));
    if (todayTimeFrame) {
      const index = newArr.indexOf(todayTimeFrame);
      if (moment().isAfter(moment(todayTimeFrame.value.end), 'minute')) {
        if (index > -1) {
          newArr.splice(index, 1);
        }
      }
    }
    return newArr;
  }

  getTimes(range): { key: string; value: string }[] {
    const newArr: { key: string; value: string }[] = [];

    if (range) {
      let startTime = DateTime.fromJavascriptDate(range.start);

      newArr.push({
        key: 'Select a time',
        value: '',
      });

      if (moment().isSame(startTime.moment, 'day')) {
        // If it's today's date
        // newArr.push({
        //   key: 'ASAP',
        //   value: 'asap'
        // });
        startTime = DateTime.fromJavascriptDate(this.order.earliestReadyTimestamp).roundTimeToNearest(15);
        // let newnew = DateTime.fromJavascriptDate(this.order.earliestReadyTimestamp);
        // startTime = newnew.roundTimeToNearest(15);
      }

      const endTime = DateTime.fromOlo(range.end);
      let timeToAdd = startTime;
      // timeToAdd = timeToAdd.addMinutes(15);

      while (timeToAdd.moment.isBefore(endTime.moment, 'minute')) {
        // if (timeToAdd.moment.isAfter(moment(this.order.earliestReadyTimestamp), 'minute')) {
        newArr.push({
          key: timeToAdd.time,
          value: timeToAdd.javascriptDateTime.toISOString(),
        });
        // }
        timeToAdd = timeToAdd.addMinutes(15);
      }
    }

    return newArr;
  }

  getTimesToday(): { key: string; value: string }[] {
    const newArr: { key: string; value: string }[] = [];
    let startTime = DateTime.fromJavascriptDate(new Date());
    newArr.push({
      key: 'Select a time',
      value: '',
    });
    if (moment().isSame(startTime.moment, 'day')) {
      startTime = DateTime.fromJavascriptDate(new Date()).roundTimeToNearest(15);
    }
    const tonight = new Date(new Date().setHours(24, 0, 0, 0));
    const endTime = DateTime.fromJavascriptDate(tonight);
    let timeToAdd = startTime;
    while (timeToAdd.moment.isBefore(endTime.moment, 'minute')) {
      newArr.push({
        key: timeToAdd.time,
        value: timeToAdd.javascriptDateTime.toISOString(),
      });
      timeToAdd = timeToAdd.addMinutes(15);
    }
    return newArr;
  }

  getGroupOrderTimesToday(): { key: string; value: string }[] {
    const newArr: { key: string; value: string }[] = [];

    // Define the time intervals in minutes and their respective labels
    const timeIntervals = [
      { minutes: 30, label: '30 minutes' },
      { minutes: 60, label: '60 minutes' },
      { minutes: 90, label: '90 minutes' }
    ];

    timeIntervals.forEach(interval => {
      const currentTime = DateTime.fromJavascriptDate(new Date());
      const timeToAdd = currentTime.addMinutes(interval.minutes);
      newArr.push({
        key: interval.label,
        value: timeToAdd.javascriptDateTime.toISOString(),
      });
    });

    return newArr;
  }




  isToday(date) {
    return moment(date).isSame(moment(), 'day');
  }
}
