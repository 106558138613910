import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possession'
})
export class PossessionPipe implements PipeTransform {

  transform(value: string): string {
    return value.toLowerCase().slice(-1).includes('s') ? value.concat('\'') : value.concat('\'s');
  }

}
