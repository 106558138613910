import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {NavigationService} from '@modules/navigation/services';
import {Store} from '@ngxs/store';
import {filter, map, take} from 'rxjs/operators';
import {User} from '../../../interfaces/user.interface';

@Injectable()
export class StartOrderGuard  {
  constructor(
    private router: Router,
    private navigation: NavigationService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(appState => appState.user.user).pipe(
      filter(u => u !== null), take(1), map((user: User) => {
        if (!user.isGuest) {
          this.router.navigateByUrl('/user/start-order');
          return false;
        }
        return true;
    }));
  }
}
