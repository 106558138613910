<div class="popup-body p-3">
  <div id="close" class="d-flex align-items-center justify-content-end" (click)="activeModal.close('Close click')">
    <i class="bi bi-x-circle font-size-h1 d-flex" style="cursor: pointer;"></i>
  </div>

  <div class="d-flex flex-column gap-3">
    <div class="header-font fw-bold modal-title">{{isCancelOrder ? 'Cancel Order?' : 'Edit Order?'}}</div>
    <div class="w-100 d-flex justify-content-center">
      <div class="header-underline m-0"></div>
    </div>
    <div class="modal-text ln-1">{{isCancelOrder ? cancelText : editText}}</div>
    <div class="d-flex flex-column gap-3 w-100">
      <button class="de-primary-btn-outline w-100"
              (click)="cancelOrEditOrder()">{{ isCancelOrder ? 'Cancel Order' : 'Edit Order' }}
      </button>
      <button class="de-primary-btn w-100"
              (click)="activeModal.close()">{{ isCancelOrder ? 'No, Don\'t Cancel' : 'No, Don\'t edit' }}
      </button>
    </div>
  </div>
</div>
