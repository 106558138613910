<div class="mobile-modal-header-back">
    <button class="back-button" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
    </button>
    <ng-container *ngIf="textFields$ | async as textFields">
        <div class="header-title header-font fw-bold">{{ textFields.purchase_rewards_copy }}</div>
    </ng-container>
</div>

<ion-content class="mobile-modal-content" *ngIf="branding$ | async as branding">
    <div class="modal-body">

        <!--  Use default icon from prototype, will be dynamic from CMS-->
        <!--  <img class="purchase-rewards-icon" src="http://placehold.it/" alt="">-->

        <div class="purchase-rewards-icon">
            <img style="max-height: 88px;" [src]="branding.loyalty_club_logo?.data ? branding.loyalty_club_logo.data.full_url : branding.main_logo.data.full_url" alt="brand logo">
        </div>


        <h4 class="purchase-rewards-title header-font text-center">
            {{ purchaseRewardsCopy }}
        </h4>


        <ng-container *ngIf="rewardPoints$ | async as balances">
            <ng-container *ngIf="mainSettings$ | async as settings">
                <h2 class="balance-amount header-font text-center">
                    {{ pointsBalanceCopy }}: {{balances.points}} Points
                </h2>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="textFields$ | async as textFields">
            <div class="purchase-info">
                <h5 class="header-font fw-bold">
                    {{ textFields.purchase_rewards_instructions_title_copy }}
                </h5>

                <p>
                    {{ textFields.purchase_rewards_instructions_copy }}
                </p>
            </div>
        </ng-container>

        <ng-container *ngIf="purchaseableRewards$ | async as pRewards">
            <ng-container *ngIf="rewardPoints$ | async as pointBalances">
                <div class="purchase-reward-grid">
                    <ng-container *ngFor="let reward of (pRewards | purchaseableRewardsSort)">
                        <app-purchaseable-reward-card
                            class="w-100"
                            [purchaseableReward]="reward"
                            [pointsUnit]="pointBalances.pointsUnit"
                            [availablePoints]="pointBalances.points"
                            (rewardClicked)="purchaseReward(reward)"
                            [setLoading]="purchasingReward"
                        ></app-purchaseable-reward-card>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

    </div>
</ion-content>
