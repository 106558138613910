import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Store } from '@ngxs/store';
import { NotificationService } from '../../../../services/notification.service';
import { DeleteMessage, MarkMessageAsRead } from '../../../../store/actions/user.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inbox-message-modal',
  templateUrl: './inbox-message-modal.component.html',
  styleUrls: ['inbox-message-modal.component.scss'],
})
export class InboxMessageModalComponent implements OnInit {
  @Input() message: InboxMessage;
  @Input() notification: PushNotificationSchema;

  constructor(
    private modal: NgbModal,
    private store: Store,
    private notificationService: NotificationService
  ) {}
  ngOnInit() {
    if (this.message) {
      this.store.dispatch(new MarkMessageAsRead(this.message.messageID));
    }
    if (this.notification) {
      this.notificationService.markDeviceNotificationAsRead(this.notification.id);
    }
  }

  deleteMessage() {
    this.store.dispatch(new DeleteMessage(this.message.messageID));
    this.close();
  }

  deleteNotification() {
    this.notificationService.deleteDeviceNotification(this.notification).subscribe(() => {});
    this.close();
  }

  close() {
    this.modal.dismissAll();
  }
}
