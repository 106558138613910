<ng-container *ngIf="announcementService.announcementMessage && !announcementService.bannerClosed">
  <ng-container *ngIf="promoContent$ | async as promo">
    <a type="button" tabindex="0" (click)="routeFromAnnouncement()" (keydown.enter)="routeFromAnnouncement()"
        class="announce-wrapper text-decoration-none cursor-default"
       [ngClass]="{'cursor-pointer': promo.announcement_bar_link && promo.announcement_bar_link.trim().length > 0}">
    <div class="ion-text-center announcement-bar p-3 gap-2 d-flex align-items-center"
         [class.app-welcome-bar]="router.url === '/'"
         [ngStyle]="{'background-color': announcementService.announcementColor, 'color': announcementService.announcementTextColor}">
      <div class="announcement-text d-flex flex-row flex-nowrap align-items-center">
        <div class="d-none d-md-block" style="width: 20px; height: 20px;"></div>
        <div
          class="d-flex flex-row flex-nowrap align-items-center flex-grow-1 flex-basis-0 justify-content-start justify-content-md-center px-0 px-md-3"
          style="flex-grow: 1; gap: 8px;">
          <span class="header-font fw-bold text-left text-md-center"
                [ngStyle]="{'color': announcementService.announcementTextColor}">{{ announcementService.announcementMessage }}</span>
        </div>
        <div class="d-flex align-items-center">
          <a type="button"
             tabindex="0"
             (click)="closeAnnouncement()"
             (keydown.enter)="closeAnnouncement()">
            <i class="bi bi-x-circle d-flex" style="font-size: 20px;"
               [class.app-welcome-close]="router.url === '/'"></i>
          </a>
        </div>
      </div>
    </div>
  </a></ng-container>
</ng-container>
