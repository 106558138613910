import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';
import {SetLoyaltyLocations} from '../../../../store/actions/user.actions';

@Component({
    selector: 'app-incomplete-account-gate',
    templateUrl: './incomplete-account-gate.component.html',
    styleUrls: ['incomplete-account-gate.component.scss'],
})
export class IncompleteAccountGateComponent implements OnInit {
    @Select(state => state.app.branding) branding$: Observable<Branding>;
    constructor(private store: Store) {}
    ngOnInit() {
        this.store.dispatch(new SetLoyaltyLocations());
    }
}
