import { Injectable, TemplateRef } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from '../../../interfaces/order.interface';
import { Select, Store } from '@ngxs/store';
import { NavigationService } from '../../navigation/services';
import { ClearOrder } from '../../../store/actions/order.actions';
import { CardTerminalService } from '../../../services/vendor-config-service/card-terminal.service';
import { GlobalStateModel } from '../../../store/state.model';

@Injectable({
  providedIn: 'root',
})
export class KioskTimeoutService {
  @Select((state: GlobalStateModel) => state.order.order) order$!: Observable<Order>;
  private kioskTimeout: any;
  private timeoutSub: Subscription;
  public timeLeft: number;
  public order: Order;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private navigation: NavigationService,
    private cardTerminal: CardTerminalService
  ) {
    this.order$.subscribe(order => {
      this.order = order;
    });
  }

  setKioskTimeout(timeoutInfo: TemplateRef<any>) {
    if (this.kioskTimeout) {
      clearTimeout(this.kioskTimeout);
    }
    this.kioskTimeout = setTimeout(() => {
      this.openTimeout(timeoutInfo);
    }, 60000);
  }

  openTimeout(timeoutInfo: TemplateRef<any>) {
    this.modalService.dismissAll();
    this.modalService
      .open(timeoutInfo, {
        size: 'lg',
        ariaLabelledBy: 'timeoutModal',
        centered: true,
        windowClass: 'kiosk-modal',
      })
      .result.then(
        result => {
          this.timeoutSub.unsubscribe();
          console.log(`Closed with: ${result}`);
        },
        reason => {
          this.timeoutSub.unsubscribe();
          console.log(`Closed with: ${reason}`);
        }
      );
    this.timeoutSub = interval(1000).subscribe(time => {
      this.timeLeft = 30 - time >= 0 ? 30 - time : 0;
      if (time > 30) {
        this.startOrderOver();
      }
    });
  }

  startOrderOver() {
    this.order = this.store.selectSnapshot(state => state.order.order);
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.closeModal();
        this.clearCardReader();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.clearCardReader();
      this.closeModal();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  resetTimeout(timeoutInfo: TemplateRef<any>) {
    clearTimeout(this.kioskTimeout);
    this.setKioskTimeout(timeoutInfo);
  }

  cancelTimeout() {
    clearTimeout(this.kioskTimeout);
    this.modalService.dismissAll();
    this.timeoutSub?.unsubscribe();
  }

  private clearCardReader() {
    console.log('clearCardReader');
    this.cardTerminal.getService().subscribe(terminal => {
      if (terminal) {
        terminal.cancelTransaction().subscribe({
          next: () => {
            console.log('Transaction cancelled');
            terminal.resetPinPad().subscribe();
          },
          error: () => {
            console.log('Transaction cancelled');
            terminal.resetPinPad().subscribe();
          },
        });
      }
    });
  }
}
