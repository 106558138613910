<ion-list>
  <ion-radio-group id="pickup_time" [(ngModel)]="datePicked">
    <ion-item lines="full ">
      <ion-label>ASAP</ion-label>
      <ion-radio
        (click)="setAsap()"
        slot="start"
        value="asap"
        [attr.disabled]="loc.advanceonly"
        [attr.checked]="
          timeSelection && !loc.advanceonly && timeSelection == 'asap'
        "
      ></ion-radio>
    </ion-item>

    <ion-item lines="none" (click)="openDate()">
      <ion-item hidden>
        <ion-label>date</ion-label>
        <ion-datetime
          #dateTime
          display-format="MMMM DD, YYYY"
          [min]="minDate"
          [max]="maxDate"
          [(ngModel)]="dateSelection"
          [ngModelOptions]="{ standalone: true }"
          (ionCancel)="dateCancel()"
          (ionChange)="dateChange($event)"
          minuteValues="0,15,30,45"
          [hourValues]="displayHours"
        ></ion-datetime>
      </ion-item>
      <ion-item hidden>
        <ion-label>time</ion-label>
        <ion-datetime
          #timeTime
          display-format="hh:mm A"
          [min]="minDate"
          [max]="maxDate"
          [(ngModel)]="timeSelection"
          [ngModelOptions]="{ standalone: true }"
          (ionCancel)="timeCancel()"
          (ionChange)="timeChange($event)"
          minuteValues="0,15,30,45"
        ></ion-datetime>
        <!-- [hourValues]="displayHours" -->
      </ion-item>
      <ion-label *ngIf="!timeSelection || datePicked == 'asap'"
        >Pick a Date & Time
      </ion-label>
      <ion-label
        *ngIf="
          displayTime &&
          timeSelection &&
          timeSelection != 'asap' &&
          datePicked != 'asap'
        "
        >{{ displayTime | date: "EEEE, MMMM d, y 'at' h:mm a" }}</ion-label
      >
      <ion-radio
        slot="start"
        id="picker"
        [attr.checked]="timeSelection && timeSelection != 'asap'"
        value="advanced"
      >
      </ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
