import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'timeComparison',
})
export class TimeComparisonPipe implements PipeTransform {
  transform(date: Date, compareTo: Date, precision: moment.unitOfTime.Base): boolean {
    return moment(date).isSame(compareTo, precision);
  }
}
