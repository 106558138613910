export interface AddReferralsByPrintedCardNumberRequest {
  authentication: string;
  merchantId: number;
  printedCardNumber: string;
  emails: string[];
  message: string;
}

export interface AddReferralsByPrintedCardNumberResponse {
  result: 'success' | 'failure';
  success: 'success' | 'failure';
  numberOfAvailableUserReferrals: number;
  userReferrals: UserReferralFields[];
  bulkReferrals: BulkReferralFields[];
}

export interface UserReferralFields {
  email: string;
  status: ReferralStatus;
  reward: string;
}

export enum ReferralStatus {
  INVITATION_SENT = 'INVITATION_SENT',
  ENROLLED = 'ENROLLED',
  REWARD_EARNED = 'REWARD_EARNED',
  CARD_LINKED = 'CARD_LINKED',
  CARD_USED = 'CARD_USED',
}

export interface BulkReferralFields {
  detail: string;
  status: ReferralStatus;
  reward: string;
}
