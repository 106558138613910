import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PayInStorePage } from '@modules/loyalty/components/pay-in-store/pay-in-store.page';
import { NavigationService } from '@modules/navigation/services';
import { ModalController } from '@ionic/angular';
import { User } from '../../../interfaces/user.interface';
import { Order } from '../../../interfaces/order.interface';
import { Select, Store } from '@ngxs/store';
import { MainSettings } from '../../../vendors/directus/interfaces/main-settings.interface';
import { VendorSetup } from '../../../vendors/directus/interfaces/vendor.interface';
import { RedeemPointsFromScanner } from '../../../store/actions/user.actions';
import { DineEngineError } from '../../../interfaces/dineengine-error.interface';
import { ToastService } from '../../../services/toast.service';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { TextField } from '../../../vendors/directus/interfaces/text-field.interface';

@Injectable()
export class LoyaltyService {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  user: User = null;
  order: Order;
  mobileAppLayout = 'classic';
  qrCode = new EventEmitter();
  isCheckin = false;
  redeemsCodeSuccessText = 'Code Redeemed';

  constructor(
    private navigation: NavigationService,
    private modalController: ModalController,
    private directus: DirectusService,
    private store: Store,
    private toast: ToastService
  ) {
    this.vendorSetup$.subscribe(vendorSetup => {
      if (vendorSetup) {
        if (vendorSetup.loyalty_provider === 'paytronix') {
          this.directus.getPaytronixSettings().subscribe(ptxConfig => {
            this.isCheckin = !!ptxConfig.enable_checkin_in_store;
          });
        }
      }
    });
    this.mainSettings$.subscribe(settings => {
      if (settings) {
        this.mobileAppLayout = settings.mobile_app_layout;
      }
    });
    this.user$.subscribe(user => {
      this.user = user ? user : null;
    });
    this.order$.subscribe(order => {
      this.order = order ? order : null;
    });
    this.qrCode.subscribe(qr => {
      this.store
        .dispatch(new RedeemPointsFromScanner(qr))
        .toPromise()
        .then(() => {
          this.modalController.dismiss();
          this.toast.showDismissableIonicToast(
            this.redeemsCodeSuccessText,
            'de-ionic-success-toast',
            8000
          );
        })
        .catch(error => {
          if (error instanceof DineEngineError) {
            if (error.message.includes('range')) {
              this.toast.showDismissableIonicToast(
                'Invalid Receipt Number',
                'de-ionic-error-toast',
                8000
              );
            } else {
              this.toast.showDismissableIonicToast(
                error.message,
                'de-ionic-error-toast',
                8000
              );
            }
          } else {
            this.toast.showDismissableIonicToast(
              error.message,
              'de-ionic-error-toast',
              8000
            );
          }
        });
    });
    this.textFields$.subscribe(textFields => {
      if (textFields) {
        this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
      }
    });
  }

  getLoyalty$(): Observable<{}> {
    return of({});
  }

  openEarn() {
    if (this.user.isGuest) {
      this.navigation.navigateToLoginPage();
    } else {
      this.modalController
        .create({
          animated: true,
          component: PayInStorePage,
          componentProps: {
            value: 123,
            displayUser: this.user,
            displayOrder: this.order,
            displayLoc: this.order?.location,
            qrEmitter: this.qrCode,
            isCheckin: this.isCheckin,
          },
          cssClass: 'pay-in-store',
          showBackdrop: false,
          mode: 'ios',
        })
        .then(modal => modal.present());
    }
  }
}
