import { Pipe, PipeTransform } from '@angular/core';
import {Category} from '../interfaces/category.interface';

@Pipe({
  name: 'daypartCategories',
  pure: false
})
export class DaypartCategoriesPipe implements PipeTransform {

  transform(categories: Category[], showingFullMenu: boolean): Category[] {
    return showingFullMenu ? categories : categories.filter(cat => cat.hasAvailableProducts);
  }

}
