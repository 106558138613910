import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';
import {VendorSetup} from '../../../../vendors/directus/interfaces/vendor.interface';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DollarReward} from '../../../../interfaces/dollar-reward.interface';
import {InStoreRedemptionComponent} from '@modules/loyalty/components/in-store-redemption/in-store-redemption.page';
import {ReturnRewardPoints} from '../../../../store/actions/user.actions';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-banked-dollars-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './banked-dollars-modal.component.html',
  styleUrls: ['banked-dollars-modal.component.scss'],
})
export class BankedDollarsModalComponent implements OnInit {

  @Output() clickedRedeem = new EventEmitter<number>();

  dollarConversion = new UntypedFormGroup({
    dollarAmount: new UntypedFormControl('', [Validators.required]),
  });

  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;

  displayVendorSetup: VendorSetup;
  displayRewardsDollars: DollarReward[] = [];
  areRewardsSelecting = false;

  modalRef: NgbModalRef;
  barcodeForm = new UntypedFormGroup({
    manualBarcode: new UntypedFormControl(),
  });


  constructor(
      private modalController: ModalController,
      private modalService: NgbModal,
      private store: Store,
  ) {}

  ngOnInit() {}

  close(): void {
    this.modalController.dismiss();
  }

  redeemClicked() {
    this.clickedRedeem.emit(this.dollarConversion.get('dollarAmount').value);
  }

  redeemedDollars(reward: DollarReward) { // The only thing keep this from being optimized is rewards not inheriting from a base class
    this.modalRef = this.modalService.open(InStoreRedemptionComponent, {centered: true});
    this.modalRef.componentInstance.reward = reward;
    this.modalRef.componentInstance.clickedVoidPoints.subscribe(() => {
      this.modalRef.dismiss();
      this.store.dispatch(new ReturnRewardPoints(reward));
    });
  }

  get dollarAmount() { return this.dollarConversion.get('dollarAmount'); }


}
