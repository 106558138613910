import { Pipe, PipeTransform } from '@angular/core';
import { OptionGroup } from '../interfaces/option-group.interface';

@Pipe({
  name: 'optionGroupFull',
  pure: false,
})
export class OptionGroupFullPipe implements PipeTransform {
  transform(optionGroup: OptionGroup): boolean {
    if (!optionGroup.maxAllowed) {
      return false; // Default, don't gray out
    }
    const selectedOptions = optionGroup.options.filter(o => o.isSelected);
    const totalSelectedQuantity = selectedOptions.reduce((acc, o) => acc + o.quantity, 0);

    if (optionGroup.maxAllowed === optionGroup.maxTotalQuantity) {
      return selectedOptions.length === optionGroup.maxAllowed || totalSelectedQuantity === optionGroup.maxTotalQuantity;
    } else if (optionGroup.maxAllowed < optionGroup.maxTotalQuantity) {
      return selectedOptions.length === optionGroup.maxAllowed;
    }
    return false; // Default, don't gray out
  }
}
