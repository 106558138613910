import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalController } from '@ionic/angular';
import { Reward } from 'src/interfaces/reward.interface';
import { DollarReward } from 'src/interfaces/dollar-reward.interface';
import moment from 'moment-timezone';
import { CodeDisplayMode } from '../../../../interfaces/code-display-mode.enum';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { DirectusService } from '../../../../vendors/directus/directus.service';

@Component({
  selector: 'app-in-store-redemption',
  templateUrl: './in-store-redemption.page.html',
  styleUrls: ['./in-store-redemption.page.scss'],
})
export class InStoreRedemptionComponent implements OnInit {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  @Input() set reward(rwrd: Reward | DollarReward) {
    this._reward = rwrd;
    if (moment().isSameOrAfter(moment.utc(this._reward.redemptionInfo.expiryDate))) {
      this.isExpired = true;
    }
    this.expirationDate = this._reward.redemptionInfo.expiryDate
      ? moment.utc(this._reward.redemptionInfo.expiryDate).local().format('MMM D hh:mm a')
      : this._reward.redemptionInfo.expiryHours
      ? moment().add(this._reward.redemptionInfo.expiryHours, 'hours').format('MMM D hh:mm a')
      : null;
    this.setExpiration();
  }

  @Output() clickedVoidPoints = new EventEmitter<any>();

  displayMode = CodeDisplayMode.none;
  displayModes = CodeDisplayMode; // For use in template

  _reward: Reward | DollarReward;
  intervalID: any;
  timer: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  } = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  };
  expirationDate;
  isExpired = false;
  instructionsCopy = 'Scan or provide this code at the register to redeem your reward';
  reactivationCopy = "You can activate a new code by clicking 'Redeem' again";

  constructor(
    public activeModal: NgbActiveModal,
    private modalController: ModalController,
    private directus: DirectusService
  ) {}

  ngOnInit() {
    this.setDisplayMode();
  }

  setExpiration() {
    const now = new Date();
    const expiration = moment.utc(this._reward.redemptionInfo.expiryDate).local().toDate();
    const totalSeconds = Math.floor((expiration.getTime() - now.getTime()) / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingDays = Math.floor(totalHours / 24);
    if (remainingDays < 0) {
      this.isExpired = true;
    }
    const remainingHours = totalHours - remainingDays * 24;
    const remainingMinutes = totalMinutes - remainingHours * 60 - remainingDays * 24 * 60;
    this.timer.days = remainingDays.toString();
    this.timer.hours = remainingHours < 10 ? '0' + remainingHours : remainingHours.toString();
    this.timer.minutes = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes.toString();
  }

  setDisplayMode() {
    this.vendorSetup$.subscribe(vs => {
      switch (vs.loyalty_provider) {
        case 'personica':
          this.directus.getPersonicaSettings().subscribe(res => {
            if (res.display_codes_as === 'qrcode') {
              this.displayMode = CodeDisplayMode.qr;
            } else if (res.display_codes_as === 'barcode') {
              this.displayMode = CodeDisplayMode.barcode;
            } else {
              this.displayMode = CodeDisplayMode.none;
            }
          });
          break;
        case 'punchh-olo':
          this.directus.getPunchhSettings().subscribe(res => {
            if (res.display_codes_as.toLowerCase().replace(' ', '') === 'qrcode') {
              this.displayMode = CodeDisplayMode.qr;
            } else if (res.display_codes_as.toLowerCase().replace(' ', '') === 'barcode') {
              this.displayMode = CodeDisplayMode.barcode;
            } else {
              this.displayMode = CodeDisplayMode.none;
            }
          });
      }
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  voidBankedPoints() {
    this.clickedVoidPoints.emit();
    this.modalController.dismiss();
  }
}
