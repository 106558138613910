<div class="realItem card border m-0" *ngIf="location">

  <div (click)="expand = !expand">
    <div class="px-2 pt-2 pb-0">

      <a *ngIf="location?.externalLink" [href]="location?.externalLink">
        <div id="infoLocName" class="loc header-font m-0" style="text-decoration: underline;">{{ location.name }}</div>
      </a>
      <div *ngIf="!location?.externalLink" id="infoLocName" class="loc header-font m-0">{{ location.name }}</div>

      <div class="addr" [ngClass]="location.isOpen ? 'location-open' : 'location-closed'">
        {{location.isOpen ? 'Open' : 'Closed'}}
      </div>

      <div class="addr loc">
        {{ location.address.address1 }}, {{ location.address.city }},
        {{ location.address.state }} {{ location.address.zip }}
      </div>

      <div [ngClass]="expand ? 'arrow-closed' : 'arrow-open'" class="de-transition-default">
        <i class="bi bi-chevron-down pointer de-transition-default" style="color: var(--ion-color-primary);"></i>
      </div>

    </div>
  </div>

  <div id="lower-info" class="card-bottom p-0 w-100" [ngClass]="{ expanded: !expand }">

    <div class="d-flex flex-row flex-wrap w-100">

      <div class="p-0 bg-light w-100">
          <div class="list-head">Location Info</div>
      </div>

        <div class="d-flex flex-row flex-nowrap w-100">

          <div class="d-flex flex-column grow-1 text-start p-3 w-100">
            <div class="w-100 mb-1">{{location.phoneNumber}}</div>
            <div class="w-100 mb-1">{{ momentStart }} - {{ momentEnd }}</div>
            <div class="de-secondary-link w-100 mb-1" [routerLink]="locationsRoute">Change Location</div>
            <div class="de-secondary-link w-100" *ngIf="location?.cateringLink"><a class="de-secondary-link"
                [href]="location?.cateringLink">Catering</a></div>
          </div>


          <div class="more-col-right d-flex align-items-center justify-content-center p-3">
            <a href="{{'tel:'+location.phoneNumber.replace(' ', '')}}">
              <i class="bi bi-telephone"></i>
            </a>
          </div>

        </div>

    </div>

  </div>

</div>
