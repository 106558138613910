import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Order} from '../../../../interfaces/order.interface';

@Component({
    selector: 'app-ready-time-select',
    templateUrl: './ready-time-select.component.html',
    styleUrls: ['ready-time-select.component.scss'],
})
export class ReadyTimeSelectComponent {
    @Input() order: Order = null;
    @Input() loadingTimeMode: string = null;
    @Input() availableTimes: any[] = [];
    @Input() timeIndex = 0;
    @Input() isAsap = true;
    @Output() asapClick = new EventEmitter<any>();
    @Output() timeChange = new EventEmitter<any>();

    showButtons: boolean = false; // Initialize the variable to true to show the buttons by default

    asapClicked() {
        this.asapClick.emit();
    }

    onTimeChange(value: any) {
        this.timeChange.emit(value);
    }

    toggleButtons() {
        this.showButtons = !this.showButtons; // Toggle the value of showButtons
    }
}
