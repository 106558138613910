import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '@modules/navigation/services';
import { Store } from '@ngxs/store';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { Order } from '../../../../interfaces/order.interface';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { interval, Subscription } from 'rxjs';
import { ClearOrder } from '../../../../store/actions/order.actions';
import { OrderItem } from '../../../../interfaces/product.interface';
import { Location } from '@angular/common';
import { CartService } from '@modules/cart/services';
import { CardTerminalService } from '../../../../services/vendor-config-service/card-terminal.service';
import {
  KioskStartOverModalComponent
} from '@modules/kiosk/components/kiosk-start-over-modal/kiosk-start-over-modal.component';
import { KioskConfigurationComponent } from '@modules/kiosk/components';

@Component({
  selector: 'app-kiosk-navbar',
  templateUrl: './kiosk-navbar.component.html',
  styleUrls: ['kiosk-navbar.component.scss'],
})
export class KioskNavbarComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: NgbModal,
    private navigation: NavigationService,
    private store: Store,
    private directus: DirectusService,
    private location: Location,
    public cartService: CartService,
    private cardTerminal: CardTerminalService
  ) {}

  @Input() set setOrder(val: Order) {
    this.order = val;
    if (!val?.items.length) {
      this.productCount = 0;
      this.modalService.dismissAll();
    } else {
      this.productCount = 0;
      let count = 0;
      val.items.forEach(item => {
        count = count + item.quantity;
      });
      this.productCount = count;
    }
  }

  kioskTimeout;

  @Input() branding: Branding;
  @Input() isStartOrderPage = false;
  @Input() isMainPage = false;
  @Input() canStartOver = false;
  @Input() canGoBack = true;
  @Output() startOver = new EventEmitter<any>();
  @Output() configureKiosk = new EventEmitter<any>();
  @Output() configurePinPad = new EventEmitter<any>();
  @Output() resetPinPad = new EventEmitter<any>();
  @Output() restartKiosk = new EventEmitter<any>();
  @Output() removeProduct = new EventEmitter<any>();
  @Output() editProduct = new EventEmitter<any>();
  @Output() incrementItem = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<any>();

  guest = 'Guest';
  order: Order;
  productCount = 0;

  timeoutSub: Subscription;
  timeLeft: number;

  configWatch = false;
  clickCount = 0;

  kioskConfigs = [];

  ngOnInit() {
    this.guest = sessionStorage.getItem('kioskGuest');
    this.directus.getKioskConfigs().subscribe(kioskConfigs => {
      this.kioskConfigs = kioskConfigs;
    });
  }

  ngOnDestroy(): void {
    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
    }
  }

  resetKioskClicked() {
    // send to home page
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.closeModal();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.closeModal();
      this.restartKiosk.emit();
    }
  }

  configureKioskClicked(config: any) {
    this.configureKiosk.emit(config);
  }

  configurePinPadClicked() {
    this.configurePinPad.emit();
  }

  resetPinPadClicked() {
    this.resetPinPad.emit();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  startOrderOver() {
    this.order = this.store.selectSnapshot(state => state.order.order);
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.closeModal();
        this.clearCardReader();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.clearCardReader();
      this.closeModal();
    }
  }

  openCart(content: TemplateRef<any>) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'cartModal',
        centered: true,
        windowClass: 'full-page-modal',
      })
      .result.then(
        result => {
          console.log(`Closed with: ${result}`);
        },
        reason => {
          console.log(`Closed with: ${reason}`);
        }
      );
  }

  startOverClicked() {
    this.order = this.store.selectSnapshot(state => state.order.order);
    this.modalService
      .open(KioskStartOverModalComponent, {
        size: 'lg',
        ariaLabelledBy: 'startOverModal',
        centered: true,
        windowClass: 'kiosk-modal'
      })
      .result.then(
      result => {
        console.log(`Closed with: ${result}`);
      },
      reason => {
        console.log(`Closed with: ${reason}`);
      }
    );
  }

  attemptConfigModal() {
    if (this.isMainPage) {
      if (this.configWatch) {
        this.clickCount += 1;
        if (this.clickCount === 9) {
          this.configWatch = false;
          this.clickCount = 0;
          this.modalService
            .open(KioskConfigurationComponent, {
              ariaLabelledBy: 'configurationModal',
              centered: true,
              windowClass: 'kiosk-modal',
            })
            .result.then(
            result => {
              console.log(`Closed with: ${result}`);
            },
            reason => {
              console.log(`Closed with: ${reason}`);
            }
          );
        }
      } else {
        this.configWatch = true;
        setTimeout(() => {
          this.configWatch = false;
          this.clickCount = 0;
        }, 5000);
      }
    }
  }

  removeProductClicked(product: OrderItem) {
    this.removeProduct.emit(product);
  }

  editProductClicked(product: OrderItem) {
    this.closeModal();
    this.editProduct.emit(product);
  }

  incrementItemClicked(item, quant) {
    this.incrementItem.emit({ product: item, quant });
  }

  trackByFn(index) {
    return index;
  }

  goToCheckoutClicked() {
    this.closeModal();
    this.navigation.navigateToCheckoutPage();
  }

  back() {
    this.location.back();
  }

  private clearCardReader() {
    this.cardTerminal.getService().subscribe(terminal => {
      if (terminal) {
        terminal.cancelTransaction().subscribe({
          next: () => {
            terminal.resetPinPad().subscribe();
          },
          error: () => {
            terminal.resetPinPad().subscribe();
          },
        });
      }
    });
  }
}
