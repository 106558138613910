import {Component, Input, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {CustomIcons} from '../../../../vendors/directus/interfaces/custom-icons.interface';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['custom-icon.component.scss'],
})
export class CustomIconComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.app.customIcons) customIcons$: Observable<CustomIcons>;
  @Input() default: string;
  @Input() color: string;
  @Input() size: string;
  @Input() customIconKey: string;
  @Input() altText: string;
  constructor() {}
  ngOnInit() {}
}
