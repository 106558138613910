<div class="d-flex flex-column flex-wrap gap-3 w-100">

  <div class="d-flex flex-column gap-2">
    <div class="fw-bold w-100 font-size-body-large">Current Balance: {{(storedValueCents / 100) | currency: currencyCode}} - <a
        class="de-primary-link font-size-body-large" routerLink="/rewards/balance-reload">Add Funds</a></div>

    <div class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center">
      <div class="fw-bold font-size-subheading-1 header-font">{{ transferBalanceCopy }}</div>
      <i class="bi bi-credit-card-2-back font-size-subheading-1 color-primary"></i>
    </div>
  </div>
<!--  <div class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center">-->
<!--    <div class="font-size-body-regular">or</div>-->
<!--  </div>-->
  <div class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center">
    <div class="font-size-body-regular">Transfer your eGift or physical gift card below for use in-store and online. By transferring your gift card(s), your physical/eGift card balance will be $0.00 and all funds will be available on your account. </div>
  </div>
  <form class="w-100" [formGroup]="cardNumberForm">
    <div class="d-flex flex-column flex-lg-row gap-2 w-100">
      <div class="input-group" style="height: 50px;">
        <input type="text" class="form-control h-100" placeholder="Card Number" aria-label="Card Number" aria-describedby="transfer-button"
               formControlName="cardNumber">
      </div>
      <div class="input-group" style="height: 50px;">
        <input type="text" class="form-control h-100" placeholder="Pin Number" aria-label="Pin Number" aria-describedby="transfer-button"
               formControlName="pinNumber">
        <div class="input-group-append">
          <button class="de-primary-btn rounded-field-right" style="width: 106px" type="button" id="transfer-button" (click)="startTransfer()">
            <ng-container *ngIf="!isLoading else loading">Transfer</ng-container>
            <ng-template #loading><div class="de-btn-loader"></div></ng-template>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="alert alert-danger text-center m-0" [class.p-0]="!errorMessage" [@collapse]="!errorOpen">{{errorMessage}}</div>
  <div class="alert alert-success text-center m-0" [@collapse]="!successOpen" [class.p-0]="!successMessage">{{successMessage}}</div>
</div>
