 <div class="product-card">
    <ng-container *ngTemplateOutlet="cardTop"></ng-container>
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
    <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
  </div>

  <ng-template #cardTop>
    <div class="product-card-top">

      <ng-container *ngIf="modeService.mode === 'desktop'">
        <button type="button" class="product-card-info" placement="bottom-left"
                [ngbTooltip]="purchaseableReward.description" *ngIf="purchaseableReward.description">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </button>
      </ng-container>

      <ng-container *ngIf="modeService.mode === 'mobile'">
        <button type="button" class="product-card-info" placement="bottom-left"
                (click)="openInfoModal($event);">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </button>
      </ng-container>

<!--      <ng-container *ngTemplateOutlet="customizeButton"></ng-container>-->

      <ng-template #infoModal>
        <h5 class="px-3 pt-3 header-font m-0 fw-bold">{{purchaseableReward.name}}</h5>
        <div class="px-3 pb-3 pt-2">{{purchaseableReward.description}}</div>
      </ng-template>

      <ng-container *ngIf="branding$ | async as branding">
        <img *ngIf="!branding.show_default_reward_imagery && purchaseableReward.imageURL"
             [defaultImage]="'assets/img/600x600.png'"
             [lazyLoad]="purchaseableReward.imageURL" [alt]="purchaseableReward.name" class="product-card-top-image"
             onerror="this.src='assets/img/600x600.png'">
        <div *ngIf="branding.show_default_reward_imagery || !purchaseableReward.imageURL" class="no-image-category">
          <img [alt]="purchaseableReward.name" class="image"
               [src]="branding.default_reward_background_image.data.full_url">
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #cardBody>
    <div class="product-card-body">
      <div class="product-card-body-title">
        {{ purchaseableReward.name }}
      </div>
      <div class="product-card-body-price">
        {{ purchaseableReward.pointCost }} {{ pointsUnit }}
      </div>
    </div>
  </ng-template>

  <ng-template #cardFooter>
    <div class="product-card-footer">

      <ng-container
        *ngIf="availablePoints >= purchaseableReward.pointCost; else lockedReward">
        <button type="button" class="de-primary-btn" (click)="$event.stopPropagation(); purchaseClicked()">
          <div *ngIf="loading" class="de-btn-loader"></div>
          <div *ngIf="!loading">
            <ng-container *ngIf="textFields$ | async as textFields">
              {{ textFields.purchase_rewards_button_copy }}
            </ng-container>
          </div>
        </button>
      </ng-container>

      <ng-template #lockedReward>
        <button type="button" class="de-primary-btn">
          <i class="bi bi-lock"></i>
        </button>
      </ng-template>

    </div>
  </ng-template>
