<ng-container *ngIf="theme$ | async as theme" class="w-100 h-100">
  <ng-container *ngIf="user$ | async as user">
<!--    <div class="d-md-flex grow-1 basis-0"-->
<!--         [ngStyle]="{backgroundImage:'url(' + theme[0]?.user_onboarding_background?.data?.full_url + ')'}">-->
      <div class="so-section">
        <div class="so-types-container gap-5 px-3 px-md-5 py-4 py-md-5 mx-auto">
          <ng-container *ngIf="user.isGuest">
            <h5 class="d-flex justify-content-center w-100 fw-bold" [ngStyle]="{'color': theme[0]?.user_onboarding_text_color}">
              Continue as Guest
            </h5>
          </ng-container>

          <div class="d-flex flex-row flex-wrap gap-3 w-100">

            <div class="d-flex flex-row flex-nowrap gap-3 justify-content-center w-100">
              <button class="de-primary-btn w-50" (click)="handoffTypeClicked(handoffTypes.pickup)">
                Pickup
              </button>
              <button class="de-primary-btn w-50" (click)="handoffTypeClicked(handoffTypes.delivery)">
                Delivery
              </button>
            </div>
            <div class="d-flex justify-content-center w-100 fw-bold" [ngStyle]="{'color': theme[0]?.user_onboarding_text_color}">
              or
            </div>
            <div class="d-flex flex-row flex-nowrap justify-content-center gap-2 w-100">
              <div class="d-flex flex-row flex-nowrap gap-2">
                <button type="button" class="de-primary-link" (click)="handoffTypeClicked(handoffTypes.curbside)">
                  Curbside
                </button>
                <div>|</div>
              </div>
              <div class="d-flex flex-row flex-nowrap gap-2">
                <button type="button" class="de-primary-link" (click)="handoffTypeClicked(handoffTypes.driveThru)">
                  Drive-Thru
                </button>
                <div>|</div>
              </div>
              <button type="button" class="de-primary-link" (click)="handoffTypeClicked(handoffTypes.dineIn)">
                Dine In
              </button>
            </div>
          </div>
        </div>
      </div>
<!--    </div>-->
  </ng-container>
</ng-container>
