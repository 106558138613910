import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Reward} from '../../../../interfaces/reward.interface';

@Component({
  selector: 'app-rewards-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './rewards-section.component.html',
  styleUrls: ['rewards-section.component.scss'],
})
export class RewardsSectionComponent implements OnInit {
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Input() rewards: Reward[];
  @Input() loadingReward = null;
  @Input() areRewardsLoading: boolean;
  @Input() areRewardsSelecting: boolean;
  @Output() redeemedRewardClicked = new EventEmitter<Reward>();
  @Output() removedRewardClicked = new EventEmitter<Reward>();
  constructor() {}
  ngOnInit() {}
  clickedRedeemedReward(reward: Reward) {
    this.redeemedRewardClicked.emit(reward);
  }
  clickedRemovedReward(reward: Reward) {
    this.removedRewardClicked.emit(reward);
  }
}
