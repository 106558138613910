<div class="d-flex flex-column gap-4">
  <h4 class="text-center w-100 text-light fw-bold">
    Curbside Vehicle Information
  </h4>

  <div class="w-100 d-flex justify-content-center pb-3">
    <div class="header-underline m-0"></div>
  </div>

  <form [formGroup]="curbsideForm"
        class="d-flex flex-column gap-3">
    <div class=" w-100"
         style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem">
      <ng-container
          *ngFor="let field of curbsideFields;">
          <div class="form-group w-100 m-0"
               style="grid-column: 1 / 3">
            <label [for]="field.label | slug"
                   class="form-label text-light">{{field.label}}</label>
            <input type="name"
                   class="form-control" placeholder="{{field.label}}"
                   [id]="field.label | slug"
                   [formControlName]="field.label | slug"
            >
          </div>
      </ng-container>
    </div>
  </form>

  <div class="d-flex w-100 justify-content-end gap-3">
    <button class="de-primary-btn-outline w-fit-content">
      Back
    </button>
    <button class="de-primary-btn w-fit-content">
      Continue
    </button>
  </div>
</div>
