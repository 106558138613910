<ng-container *ngIf="navbarSettings$ | async as navbarSettings">
<div class="category-jump-nav border-bottom">
  <nav class="jump-links-nav" [ngClass]="{ 'container' : modeService.mode === 'desktop' && navbarSettings.fixed_navbar_width }">
    <ul class="jump-links" [ngClass]="{ 'flex-wrap' : modeService.mode === 'desktop' }">
      <ng-container *ngIf="(user$ | async) as user">
        <ng-container
          *ngFor="let category of ((categories | secretCategories: (user.isGuest ? roles.guest : roles.all)) | daypartCategories: (showFullMenu$ | async)); let i = index; trackBy: categoryTrackBy">
          <ng-container *ngIf="!category.isHidden && category.products && category.products.length">
            <li class="jump-link" [id]="'nav-' + category.nameSlug">
              <a class="jump-link-button header-font" (click)="scroll(category.name, i)"
                 [class.active]="activeCategory.name === category.name">
                {{ category.name }}
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </nav>
</div>
</ng-container>
