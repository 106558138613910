export interface SaleConfigForAccountRequest {
  /**
   * Paytronix-assigned merchant ID to perform the operation in.
   */
  merchantId: number;
  /**
   * Paytronix-assigned code. See webSaleProgramType Values for values.
   */
  programType: WebSaleProgramType;
  /**
   * The card of the user.
   */
  printedCardNumber: string;
}

export interface SaleConfigForAccountResponse {
  result: 'success' | 'failure';
  program: Program;
}

export enum WebSaleProgramType {
  /**
   * Purchase Rewards using Points, only valid for account specific calls
   */
  REWARDS_FROM_POINTS = 'REWARDS_FROM_POINTS',
  /**
   * Purchase a gift card.
   */
  GIFT_CARD = 'GIFT_CARD',
  /**
   * Send Virtual Gift Cards (eGift) using a Credit Card, only valid for calls that aren’t account specific
   */
  EGIFT = 'EGIFT',
}

export enum WebSaleItemType {
  /**
   * A Gift or Loyalty Card item
   */
  GIFT_OR_LOYALTY_CARD = 'GIFT_OR_LOYALTY_CARD',
  /**
   * A Reward item that will be shipped
   */
  SHIPPED_REWARD = 'SHIPPED_REWARD',
  /**
   * A Reward item that added to user’s card
   */
  ON_CARD_REWARD = 'ON_CARD_REWARD',
  /**
   * A Gift of Stored Value
   */
  SV_GIFT = 'SV_GIFT',
  /**
   * An eGift item
   */
  EGIFT = 'EGIFT'
}

interface Program {
  programType: WebSaleProgramType;
  label: string;
  itemConfigs: ItemConfig[];
  paymentMethodTypes: PaymentMethodEntryType[];
  shippingOptions: ShippingOption[];
  cardTypes: CardTypeEntry[];
  currency: Currency;
}

export interface ItemConfig {
  /**
   * Paytronix-assigned code.
   */
  itemType: WebSaleItemType;
  /**
   * The unique code of the item.
   */
  code: string;
  /**
   * The name of the item (e.g., “Iced Tea”).
   */
  label: string;
  /**
   * The plural name of the item (e.g., “Iced Teas”).
   */
  plural: string;
  /**
   * The long description of the item.
   */
  description: string;
  /**
   * If available, a URL reference to retrieve the thumbnail image of the item.
   */
  thumbnailImageUrl: string;
  /**
   * If available, a URL reference to retrieve the (full size) image of the item.
   */
  imageUrl: string;
  /**
   * The price of the item.
   */
  price: number;
  /**
   * If true, allows end user to specify an arbitrary amount, within priceRangeLow and priceRangeHigh if defined. Supported by EGIFT program type only.
   */
  customerPriceEnabled: boolean;
  /**
   * If the price is not specified, then the item can have a varying price. In this case, this value specifies the minimum value of the price.
   */
  priceRangeLow: number;
  /**
   * If the price is not specified, then the item can have a varying price. In this case, this value specifies the maximum value of the price.
   */
  priceRangeHigh: number;
  /**
   * If the price is not specified, then the item can have a varying price. In this case, this value specifies the initial selection from one of the entries in the priceList list.
   */
  initialPrice: number;
  /**
   * If the price is not specified, then the item can have a varying price. In this case, this list specifies the permitted values for the price.
   */
  priceList: number[];
  /**
   *  See Style for format of the objects
   */
  styles: Style[];
  /**
   * The wallet code of the reward item (only present if itemType = ON_CARD_REWARD).
   */
  onCardRewardWalletCode: number;
  /**
   * The amount of the reward specified by the onCardRewardWalletCode above (only present if itemType = ON_CARD_REWARD).
   */
  onCardRewardAmount: number;
  /**
   * True if the reward specified by the onCardRewardWalletCode above can be “gifted” to another user (only present if itemType = ON_CARD_REWARD)
   */
  onCardRewardGiftable: number;
}

interface Style {
  /**
   * The unique code of the style.
   */
  code: string;
  /**
   * The name of the item (e.g., “Red”).
   */
  label: string;
  /**
   * The long description of the style.
   */
  description: string;
  /**
   * If available, a URL reference to retrieve the thumbnail image of the style.
   */
  thumbnailImageUrl: string;
  /**
   * If available, a URL reference to retrieve the (full size) image of the style.
   */
  imageUrl: string;
}

interface ShippingOption {
  /**
   * The unique code of the shipping method.
   */
  code: string;
  /**
   * The name of the shipping method.
   */
  label: string;
  /**
   * The price of the shipping method.
   */
  price: number;
}

interface PaymentMethodEntryType {
  code: string;
  label: string;
}

interface CardTypeEntry {
  code: string;
  label: string;
}

interface Currency {
  /**
   * Alphanumeric code according to the ISO 4217 currency codes.
   */
  code: string;
  /**
   * Symbol associated with currency.
   */
  symbol: string;
}
