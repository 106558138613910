<section class="d-flex flex-column border cms-card-radius">
  <div class="p-3 border-bottom bg-light rounded-card-top">
    <div class="d-flex flex-nowrap gap-2 align-items-center">
      <h4
        class="m-0 fw-bold header-font d-flex flex-row flex-nowrap align-items-center">
        {{ sectionTitle
        }}<small
          class="badge bg-success ms-2 w-fit-content font-size-body-small"
          *ngIf="sectionBadge"
          >{{ sectionBadge }}</small
        >
      </h4>
    </div>
  </div>
  <div class="p-3 de-card-body-bottom">
    <ng-content></ng-content>
  </div>
</section>
