import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Option } from 'src/interfaces/option.interface';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-slider-option-card',
  templateUrl: './slider-option-card.component.html',
  styleUrls: ['./slider-option-card.component.scss']
})
export class SliderOptionCardComponent {

  @Input() option: any;
  @Input() isProduct: boolean = false;
  @Input() isCategory: boolean = false;
  @Input() isLoading = false;

  @Output() clickedOption = new EventEmitter<any>();
  @Output() optionGroupClicked = new EventEmitter<any>();
  @Output() addItemClicked = new EventEmitter<any>();
  @Output() editItemClicked = new EventEmitter<any>();

  
  selectedItem = 0;
  heatList = ['Mild', 'Medium', 'Hot', 'Xtra Hot', 'Atomic'];
  heatColors = ['#1C8A5A', '#FBB021', '#F68838', '#EE3E32', '#710002'];

  constructor(public mobile: MobileService) {}

  optionClicked() {
    this.clickedOption.emit();
    if (this.option.isSelected) {
      this.emitOptGroup(0);
      this.selectedItem = 0;
    }
  }

  iconCount(num) {
    return Array(num + 1);
  }

  emitOptGroup(num) {
    if (this.option && this.option.optionGroups && this.option.optionGroups.length) {
      const selectedGroup = this.option.optionGroups[0].options[num];
      selectedGroup.isSelected = false;
      this.optionGroupClicked.emit(selectedGroup);
    }
  }

  addItemClick(event) {
    this.addItemClicked.emit(event);
  }

  editItemClick() {
    this.editItemClicked.emit();
  }

  optionGroupSelected(option) {
    this.selectedItem = option.detail.value - 1;
    this.emitOptGroup(option.detail.value - 1);
  }
}
