import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { VendorSetup } from '../../../../vendors/directus/interfaces/vendor.interface';
import { RedeemRewardPoints } from '../../../../store/actions/user.actions';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-banked-rewards-section',
  templateUrl: './banked-rewards-section.component.html',
  styleUrls: ['./banked-rewards-section.component.scss'],
})
export class BankedRewardsSectionComponent {
  @Select(state => state.user.rewardPoints) balances$: Observable<RewardsBalances>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  displayVendorSetup: VendorSetup;
  dollarConversion = new UntypedFormGroup({
    dollarAmount: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private store: Store,
    private toast: ToastService
  ) {}

  redeemClicked() {
    const amount = this.dollarConversion.get('dollarAmount').value;
    this.store.dispatch(new RedeemRewardPoints(amount));
  }

  get dollarAmount() {
    return this.dollarConversion.get('dollarAmount');
  }
}
