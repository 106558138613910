import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import {GiftCardConfig} from '@modules/gift-card/models';
import {GenericComponent} from '@modules/ecommerce/models';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-balance-reload-value',
  templateUrl: './balance-reload-value.component.html',
  styleUrls: ['balance-reload-value.component.scss'],
})
export class BalanceReloadValueComponent implements OnInit, GenericComponent {
  @Select(state => state.giftCardOrder.saleConfig) saleConfig$: Observable<GiftCardConfig>;
  @Input() flowType: string;
  @Input() hideCartFunctionality: boolean;
  @Input() set sectionComplete(val: boolean) {
    this.itemConfigured = val;
  }
  @Input() itemTotal = 0;
  @Input() currencyCode: string;
  @Output() sendItemTotal = new EventEmitter<number>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() passData = new EventEmitter<UntypedFormGroup>();
  @Output() createCard = new EventEmitter<number>();
  data = null;
  itemConfigured = false;
  cardValue = new UntypedFormGroup({
    value: new UntypedFormControl(null, [Validators.required, this.valueRangeValidator.bind(this)])
  });
  ngOnInit() {
    this.saleConfig$.subscribe((saleConfig: GiftCardConfig) => {
      this.data = saleConfig;
    });
    this.cardValue.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.isValid.emit(this.cardValue.valid);
      if (this.cardValue.valid) {
        this.passData.emit(this.cardValue);
      }
    });
  }
  setAmount(value: number) {
    setTimeout(() => {
      this.cardValue.get('value').setValue(value);
      this.isValid.emit(this.cardValue.valid);
      this.passData.emit(this.cardValue);
      this.sendItemTotal.emit(value);
    }, 300);
  }
  valueRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.data && control.value !== undefined) {
      const value = parseFloat(control.value);
      const lowerLimit = parseFloat(this.data.cardValues.lowerLimit);
      const upperLimit = parseFloat(this.data.cardValues.upperLimit);
      if (isNaN(value) || value < lowerLimit || value > upperLimit) {
        return { valueRange: true };
      }
    }
    return null;
  }
  createCardClicked(quantity: number) {
    this.createCard.emit(quantity);
  }
  get value() { return this.cardValue.get('value'); }
}

