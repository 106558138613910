import {Pipe, PipeTransform} from '@angular/core';
import {UserRoles} from '@common/components';
import {Product} from '../interfaces/product.interface';

@Pipe({
  name: 'secretProducts',
  pure: false
})
export class SecretProductsPipe implements PipeTransform {
  roles: UserRoles;

  transform(items: Product[], filter: UserRoles): any {
    if (!items || !filter) {
      return items;
    }
    switch (filter) {
      case UserRoles.member:
        return items.filter(item => item.hideFromGuests);
      case UserRoles.guest:
        return items.filter(item => !item.hideFromGuests);
      case UserRoles.all:
        return items;
      default:
        return items;
    }
  }

}
