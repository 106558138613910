import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {Onboarding} from '../../../../vendors/directus/interfaces/onboarding.interface';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-onboarding-slides',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './onboarding-slides.component.html',
    styleUrls: ['onboarding-slides.component.scss'],
})
export class OnboardingSlidesComponent {
    @Select((state: GlobalStateModel) => state.app.onboardingSlides) onboardingSlides$: Observable<Onboarding[]>
    constructor(
        private modalController: ModalController
    ) {
        this.onboardingSlides$.subscribe(o => console.log(o))
    }

    close() {
        this.modalController.dismiss()
    }
}
