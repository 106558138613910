import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {Router} from '@angular/router';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {NavbarSettings} from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-desktop-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class DesktopHeaderBarComponent implements OnInit {
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Input() routeBack: string;

  width = window.innerWidth;
  showNavbar = false;

  constructor(
    public router: Router,
    public menu: MenuController
  ) {}

  ngOnInit() {
    this.navbarSettings$.pipe(filter(ns => ns !== null)).subscribe(ns => this.showNavbar = !ns.disable_hamburger_menu);
  }

  backButton() {
    this.router.navigateByUrl(this.routeBack);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
  }
}
