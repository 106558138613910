import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Menu} from '../../../../interfaces/menu.interface';
import {MenuService} from '@modules/menu/services';
import {Category} from '../../../../interfaces/category.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../../../../interfaces/product.interface';
import {NavigationService} from '@modules/navigation/services';
import {UserRoles} from '@common/components';
import {User} from '../../../../interfaces/user.interface';

@Component({
  selector: 'app-category-block',
  templateUrl: './category-block.component.html',
  styleUrls: ['category-block.component.scss'],
})
export class CategoryBlockComponent {
  roles = UserRoles;
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.user.user) user$: Observable<User>;
  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;
  @Input() category: Category;
  constructor(
    private store: Store,
    public menuService: MenuService,
    public modalService: NgbModal,
    private navigation: NavigationService
  ) {}
  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }
  productTrackBy(index: number, product: Product): string {
    return `${index}${product.name}${product.productID}`;
  }
  startGroupOrder() {
    this.navigation.navigateToStartGroupOrderPage();
  }
}
