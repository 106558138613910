import { Component, Input, OnInit } from '@angular/core';
import { OrdersComponent } from '../../../profile/containers/orders/base/orders.component';
import { Order } from '../../../../interfaces/order.interface';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home-reorder',
  templateUrl: './home-reorder.component.html',
  styleUrls: ['./home-reorder.component.scss'],
})
export class HomeReorderComponent extends OrdersComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  limitedPastOrders$: Observable<(Order | null)[]>;

  context;

  @Input() isMobile: boolean;
  @Input() displayPreviousOrders: Order[];

  ngOnInit() {
    super.ngOnInit();

    this.isLoading = new Array(5).fill(false);

    this.limitedPastOrders$ = this.pastOrders$.pipe(
      map(orders => {
        const limitedOrders = [];
        for (let i = 0; i < 5; i++) {
          limitedOrders.push(orders[i] || null);
        }
        return limitedOrders;
      })
    );
  }
}
