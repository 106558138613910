import {Injectable} from '@angular/core';
import {CreditTransactionResponse} from './interfaces/credit-transaction-response.interface';
import {PaymentResponse} from '../../interfaces/payment-response.interface';


@Injectable({
    providedIn: 'root'
})
export class DatacapMappingService {

    creditResponseToPaymentResponse(response: CreditTransactionResponse): PaymentResponse {
        return ({
            status: response.Status,
            message: response.Message,
            account: response.Account,
            expiration: response.Expiration,
            cardBrand: response.Brand,
            authCode: response.AuthCode,
            authAmount: Number(response.Authorized),
            refNo: response.RefNo,
            token: response.Token,
            tipAmount: Number(response.Tip)
        } as PaymentResponse);
    }
}
