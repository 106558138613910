import { MoreTileComponent } from './more-tile/more-tile.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { HomeDealsComponent } from './home-deals/home-deals.component';
import { HomeNewsComponent } from './home-news/home-news.component';
import { HomeFeaturedComponent } from './home-featured/home-featured.component';
import { FeaturedContainerComponent } from './featured-container/featured-container.component';
import { FeaturedCardComponent } from './featured-card/featured-card.component';

export const components = [MoreTileComponent, ContactUsFormComponent, HomeDealsComponent, HomeNewsComponent, HomeFeaturedComponent, FeaturedContainerComponent, FeaturedCardComponent];

export * from './more-tile/more-tile.component';
export * from './contact-us-form/contact-us-form.component';
export * from './home-deals/home-deals.component';
export * from './home-news/home-news.component';
export * from './home-featured/home-featured.component';
export * from './featured-container/featured-container.component';
export * from './featured-card/featured-card.component';
