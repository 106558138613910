
<div class="w-100 p-3" style="display: grid; grid-gap: 16px;">
  <div class="w-100"
       style="display:grid; grid-gap: 16px; grid-template-columns: auto 1fr auto; place-items: center start">
       <img src="../../../../assets/icons/cts-icon-white.svg">
    <div class="text-start fw-bold header-font" style="font-size: 24px; color: var(--ion-text-color);">Need Help?</div>
    <div id="close" class="d-flex align-items-start" (click)="activeModal.close('Close click')"><i class="bi bi-x-circle font-size-h1 d-flex"></i></div>
  </div>
  <div>Whether you need a refill or need some help from one of our crew members we've got you covered.</div>
  <hr class="" style="width: 90px; border-width: 1px; margin: 0; border-top-color: var(--ion-color-primary);"/>
  <div class="de-border w-100" style="display:grid; padding: 16px; grid-gap: 16px; border-radius: var(--cms-card-radius)">
    <ng-container *ngFor="let item of callItemsCustomer; index as i">
        <div style="display: inline-flex">
          <div class="form-check col-12 col-lg-6 col-xl-6 my-2">
          <input class="form-check-input" type="checkbox"  [id]="item">
            <label class="form-check-label" [for]="item">
              {{item}}
            </label>
          </div>
        </div>
    </ng-container>
  </div>
  <form [formGroup]="addInformation">
    <div>
      <div>Additional Information for Server</div>
      <input class="form-control de-input tableside-input-field" formControlName="information" placeholder="Add Info Here">
    </div>
  </form>
  <button class="link w-100 de-primary-btn" (click)="submitClicked()">
    <ng-container *ngIf="!isLoading">Continue</ng-container>
    <ng-container *ngIf="isLoading"><div class="de-btn-loader"></div>
    </ng-container>
  </button>
</div>
