import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splashscreenBG',
})
export class SplashscreenBgPipe implements PipeTransform {
  transform(url: string, hexCode = '#FFFFFF'): string {
    if (!url && !hexCode) {
      return '';
    }

    let bgStyle = '';

    if (url) {
      bgStyle += `url('${url}') center / 2048px 2048px no-repeat`; // Setting the background size
    }

    if (hexCode) {
      if (bgStyle) bgStyle += ', '; // Separate multiple backgrounds with a comma
      bgStyle += `${hexCode}`;
    }

    return bgStyle.trim();
  }
}
