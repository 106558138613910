export const environment = {
  debug: false,
  adminUrl: 'https://{project}.dineengine.io',
  production: true,
  allowConsoleErrors: false,

  domainAPI: 'https://api.dineengine.io',
  domainLoyalty: 'https://mybistromobile.fishbowlcloud.com/clapi',

  API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyAXB_lR9mmazH6ePfbDBDzRGdHjBPaDa1A', // https://github.com/mapsplugin/cordova-plugin-googlemaps#why-two-api-keys
  API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyAXB_lR9mmazH6ePfbDBDzRGdHjBPaDa1A',

  punchClientId: '753d870975b915b4f1d69ed2ba7f1f1998db0faac810b6b5e0b3e8dad840c9d1',
  punchSecret: false,
  punchBaseUrl: 'https://punchh.com',
  punchhOauthPage: 'https://sandbox.punchh.com/oauth/authorize?',
  punchhOauthPageProd: 'https://api.punchh.com/oauth/authorize?',

  datacapSandboxID: 'ChepriTest',
  datacapSandboxCode: 'nZm6hYh7j2V4ZEnUnmqJzpjF',
  datacapProdID: 'Chepri',
  datacapProdCode: 'Y4Z6FMeVKV3ATf3SR5vW4ncC',

  hotjar: '1796213',
  sentryDsn: 'https://a301db28aca3449098680468035de05a@o553654.ingest.sentry.io/5681258',
};
