<div class="d-flex flex-row justify-content-between mb-2">
    <h6 class="flex-grow-1 header-font fw-bold p-0 m-0">
        Select a Time
    </h6>
    <button type="button" class="primary-link bg-transparent p-0" (click)="toggleButtons()">
        <ng-container *ngIf="!showButtons">Show More Times</ng-container>
        <ng-container *ngIf="showButtons">Collapse Times</ng-container>
    </button>
</div>

<form>
    <div class="select-time-grid" [class.grid-open]="showButtons">
        <button
                type="button"
                class="w-100 text-nowrap"
                (click)="asapClicked()"
                [class.de-primary-btn-outline]="!isAsap"
                [class.de-primary-btn]="isAsap"
                [class.disabled]="!order?.location?.isOpen"
                [ngClass]="!order?.location?.isOpen ? 'd-none' : ''">
            ASAP
            <div class="de-btn-mini-loader btn-loader m-0" *ngIf="loadingTimeMode === 'asap'"></div>
        </button>
        <ng-container *ngFor="let time of availableTimes; let i = index">
            <button
                    type="button"
                    class="w-100 text-nowrap"
                    value="{{ time.value }}"
                    [class.de-primary-btn-outline]="i !== timeIndex"
                    [class.de-primary-btn]="i === timeIndex"
                    (click)="onTimeChange($event)">
                {{ time.key }}
            </button>
        </ng-container>
    </div>
</form>

<ng-container *ngIf="showButtons">
    <div class="d-flex flex-grow-1 align-items-center justify-content-center mt-3">
        <button type="button" class="primary-link bg-transparent" (click)="toggleButtons()">
            <ng-container *ngIf="!showButtons">Show More Times</ng-container>
            <ng-container *ngIf="showButtons">Collapse Times</ng-container>
        </button>
    </div>
</ng-container>
