import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Features } from '../../../../vendors/directus/interfaces/features.interface';
import { MobileService } from '../../../../services/mobile.service';

@Component({
  selector: 'app-featured-container',
  templateUrl: './featured-container.component.html',
  styleUrls: ['featured-container.component.scss'],
})
export class FeaturedContainerComponent {
  @Input() items: Features[];
  constructor(public mobileService: MobileService) {}
}
