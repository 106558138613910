import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../vendors/directus/interfaces/main-settings.interface';
import { filter, take } from 'rxjs/operators';
import { ModeService } from './mode.service';
import { Capacitor } from '@capacitor/core';
import { DOCUMENT } from '@angular/common';
import * as SmartBanner from 'smart-app-banner';

export interface MetaData {
  title: string;
  description: string;
  keywords: string[];
}

enum Robots {
  ALLOW,
  DENY,
}

interface SmartBannerOptions {
  appleAppID: string;
  androidAppID: string;
  title?: string;
  author?: string;
  price?: string;
  priceSuffixApple?: string;
  priceSuffixGoogle?: string;
  iconAppleUrl?: string;
  iconGoogleUrl?: string;
  buttonLabel?: string;
  buttonUrlApple?: string;
  buttonUrlGoogle?: string;
  enabledPlatforms?: string;
  closeLabel?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  defaultMeta: MetaData;
  private renderer: Renderer2;

  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  constructor(
    private meta: Meta,
    private title: Title,
    private rendererFactory: RendererFactory2,
    private mode: ModeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
      this.defaultMeta = {
        title: ms.seo_title,
        description: ms.seo_description,
        keywords: ms.seo_keywords ? ms.seo_keywords : [],
      };
      this.resetToDefault();
      if (
        !ms.disable_smart_banners &&
        this.mode.mode !== 'kiosk' &&
        this.mode.mode !== 'tableside' &&
        Capacitor.getPlatform() === 'web' &&
        ms.apple_app_id &&
        ms.android_app_id &&
        ms.smart_banner_app_icon
      ) {
        this.addAppStoreMeta({
          appleAppID: ms.apple_app_id,
          androidAppID: ms.android_app_id,
          title: ms.title,
          author: 'DineEngine',
          price: 'FREE',
          priceSuffixApple: ' - On the App Store',
          priceSuffixGoogle: ' - In Google Play',
          iconAppleUrl: ms.smart_banner_app_icon?.data.full_url,
          iconGoogleUrl: ms.smart_banner_app_icon?.data.full_url,
          buttonLabel: 'VIEW',
          buttonUrlApple: `https://apps.apple.com/us/app/id${ms.apple_app_id}`,
          buttonUrlGoogle: `https://play.google.com/store/apps/details?id=${ms.android_app_id}`,
        });
      }
    });
  }

  /**
   * @description Reset to default CMS metadata (sets crawling to be allowed, call blockCrawling()
   * immediately after if crawling needs to be blocked)
   */
  resetToDefault() {
    this.mainSettings$
      .pipe(
        filter(ms => ms !== null),
        take(1)
      )
      .toPromise()
      .then(() => {
        this.updateTitle(null, false);
        this.updateDescription(this.defaultMeta.description);
        this.updateKeywords(this.defaultMeta.keywords);
        this.updateMisc();
        this.setCanonical();
        this.allowCrawling();
      });
  }

  /**
   * @description Set Page Metadata (sets crawling to be allowed, call blockCrawling() immediately after if crawling needs to be blocked)
   * @param metadata - set property to null to use default SEO data from CMS
   * @param titleOverride - set to true if you want to remove the brands name from the title
   */
  manualUpdate(metadata: MetaData, titleOverride = false) {
    this.mainSettings$
      .pipe(
        filter(ms => ms !== null),
        take(1)
      )
      .toPromise()
      .then(() => {
        this.updateTitle(metadata.title, titleOverride);
        this.updateDescription(metadata.description);
        this.updateKeywords(metadata.keywords);
        this.updateMisc();
        this.setCanonical();
        this.allowCrawling();
      });
  }

  /**
   * @description Sets robots metadata to allow crawling on page
   */
  allowCrawling() {
    this.updateRobots(Robots.ALLOW);
  }

  /**
   * @description Sets robots metadata to block crawling on page
   */
  blockCrawling() {
    this.updateRobots(Robots.DENY);
  }

  private updateTitle(title: string, titleOverride: boolean) {
    let formattedTitle;
    if (title) {
      formattedTitle = titleOverride ? title : `${title} | ${this.defaultMeta.title}`;
    } else {
      formattedTitle = this.defaultMeta.title;
    }
    this.title.setTitle(formattedTitle);
    this.checkAndUpdate({ name: 'title', content: formattedTitle });
    this.checkAndUpdate({ property: 'og:title', content: formattedTitle });
    this.checkAndUpdate({ name: 'twitter:title', content: formattedTitle });
  }

  private updateDescription(description: string): void {
    const content = description ? description : this.defaultMeta.description;
    this.checkAndUpdate({ name: 'description', content });
    this.checkAndUpdate({ property: 'og:description', content });
    this.checkAndUpdate({ name: 'twitter:description', content });
  }

  private updateKeywords(keywords: string[]): void {
    if (keywords && keywords.length > 0) {
      this.checkAndUpdate({ name: 'keywords', content: keywords.join(', ') });
    } else {
      this.checkAndUpdate({ name: 'keywords', content: this.defaultMeta.keywords.join(', ') });
    }
  }

  private updateRobots(mode: Robots) {
    switch (mode) {
      case Robots.ALLOW:
        this.checkAndUpdate({ name: 'robots', content: 'index, follow' });
        this.checkAndUpdate({ name: 'googlebot', content: 'all' });
        break;
      case Robots.DENY:
        this.checkAndUpdate({ name: 'robots', content: 'noindex, nofollow' });
        this.checkAndUpdate({ name: 'googlebot', content: 'none' });
        break;
    }
  }

  private updateMisc() {
    this.checkAndUpdate({ property: 'og:type', content: 'website' });
    this.checkAndUpdate({ property: 'og:url', content: `${window.location.origin}${window.location.pathname}` });
  }

  private setCanonical() {
    const canonical: HTMLLinkElement = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.href = `${window.location.origin}${window.location.pathname}`;
    } else {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', `${window.location.origin}${window.location.pathname}`);
      this.renderer.appendChild(document.querySelector('head'), link);
    }
  }

  private checkAndUpdate(tag: MetaDefinition) {
    if (this.meta.getTag(tag.name ? `name='${tag.name}'` : `property='${tag.property}'`)) {
      this.meta.updateTag(tag, tag.name ? `name='${tag.name}'` : `property='${tag.property}'`);
    } else {
      this.meta.addTag(tag);
    }
  }

  private addAppStoreMeta(options: SmartBannerOptions) {
    const { appleAppID, androidAppID } = options;

    // Existing meta tags
    this.meta.addTag({ name: 'apple-itunes-app', content: `app-id=${appleAppID}` });
    this.meta.addTag({ name: 'google-play-app', content: `app-id=${androidAppID}` });
    this.addSmartBannerScript(options);
  }

  private addSmartBannerScript(options: SmartBannerOptions) {
    new SmartBanner({
      daysHidden: 90, // days to hide banner after close button is clicked (defaults to 15)
      daysReminder: 180, // days to hide banner after "VIEW" button is clicked (defaults to 90)
      appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
      title: options.title,
      author: options.author,
      button: options.buttonLabel,
      store: {
        ios: 'On the App Store',
        android: 'In Google Play',
        windows: 'In Windows store',
      },
      price: {
        ios: options.price,
        android: options.price,
        windows: options.price,
      },
      // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
      icon: options.iconAppleUrl, // full path to icon image if not using website icon image
      // force: 'android', // Uncomment for platform emulation
    });
  }
}
