import { Pipe, PipeTransform } from '@angular/core';
import {PushNotificationSchema} from '@capacitor/push-notifications';
import {NotificationService} from '../services/notification.service';

@Pipe({
  name: 'checkIfUnreadNotification',
  pure: false,
})
export class CheckIfUnreadNotificationPipe implements PipeTransform {

  constructor(
      private notificationService: NotificationService
  ) {
  }

  transform(notification: PushNotificationSchema): boolean {
    return this.notificationService.checkIfNotificationIsRead(notification.id);
  }

}
