import { Injectable } from '@angular/core';
import { CustomerTrackingState } from '../../interfaces/customer-tracking-state.enum';
import { DECustomerLocationUpdate } from '../../interfaces/customer-location-update.interface';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import { CustomerState, PickUpType } from './interfaces/flybuy-types.enum';
import { Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root',
})
export class FlybuyMappingService {
  customerStateToFlybuyState(state: CustomerTrackingState): CustomerState {
    switch (state) {
      case CustomerTrackingState.CREATED:
        return CustomerState.CREATED;
      case CustomerTrackingState.IN_TRANSIT:
        return CustomerState.EN_ROUTE;
      case CustomerTrackingState.NEARBY:
        return CustomerState.NEARBY;
      case CustomerTrackingState.ARRIVED:
        return CustomerState.ARRIVED;
      case CustomerTrackingState.WAITING:
        return CustomerState.WAITING;
      case CustomerTrackingState.DEPARTED:
        return CustomerState.DEPARTED;
      case CustomerTrackingState.COMPLETE:
        return CustomerState.COMPLETED;
    }
  }

  domPositionToDELocationUpdate(position: Position): DECustomerLocationUpdate {
    return {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
      accuracy: position.coords.accuracy,
      speed: position.coords.speed,
    };
  }

  handoffToFlybuyPickupType(handoff: HandoffType): PickUpType {
    switch (handoff) {
      case HandoffType.curbside:
        return PickUpType.CURBSIDE;
      case HandoffType.delivery:
        return PickUpType.DELIVERY;
      case HandoffType.dispatch:
        return PickUpType.DISPATCH;
      case HandoffType.driveThru:
        return PickUpType.DRIVE_THRU;
      case HandoffType.pickup:
        return PickUpType.PICKUP;
      default:
        return PickUpType.PICKUP;
    }
  }

  flybuyPickupTypeToHandoffType(pickupType: PickUpType): HandoffType {
    switch (pickupType) {
      case PickUpType.CURBSIDE:
        return HandoffType.curbside;
      case PickUpType.DELIVERY:
        return HandoffType.delivery;
      case PickUpType.DISPATCH:
        return HandoffType.dispatch;
      case PickUpType.DRIVE_THRU:
        return HandoffType.driveThru;
      case PickUpType.PICKUP:
        return HandoffType.pickup;
    }
  }
}
