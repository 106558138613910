import { Component } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Menu} from '../../../../interfaces/menu.interface';
import {Category} from '../../../../interfaces/category.interface';
import {SubscriptionComponent, UserRoles} from '@common/components';
import {User} from '../../../../interfaces/user.interface';
import {MenuService} from '@modules/menu/services';

@Component({
  selector: 'app-kiosk-menu-block',
  templateUrl: './kiosk-menu-block.component.html',
  styleUrls: ['kiosk-menu-block.component.scss'],
})
export class KioskMenuBlockComponent extends SubscriptionComponent {
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.user.user) user$: Observable<User>;
  roles = UserRoles;
  constructor(
    public menuService: MenuService
  ) {
    super();
  }
  scrollToTop(element: HTMLElement) {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }
}
