import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs/operators';
import { VendorSetup } from '../../../interfaces/vendor.interface';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import {CapacitorIntegrationService} from '@common/services';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {

  constructor(
    private store: Store,
    private capacitorIntegration: CapacitorIntegrationService
  ) {}

  getClientLocation(successCallback: PositionCallback, errorCallback: PositionErrorCallback) {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 30000,
    };
    this.store.select(state => state.app.vendorSetup).pipe(filter(vs => !!vs), take(1)).subscribe((vs: VendorSetup) => {
      Geolocation.checkPermissions().then(value => {
        if (vs.customer_tracking_provider !== 'none' && Capacitor.getPlatform() === 'android' &&
          (value.location === 'prompt' || value.location === 'prompt-with-rationale')) {
          Dialog.alert({
            title: 'Location Usage',
            message: 'This app collects location data to enable location updates even when the app is closed or not in use. We use your location to find restaurants near you. We also allow you to send location updates to the restaurant so that your food can be ready for you when you arrive.',
          }).then(() => {
            // Storage.set({key: this.androidLocationCheckKey, value: JSON.stringify(true)})
            Geolocation.getCurrentPosition(options).
              then((result: Position) => {
                if (vs.customer_tracking_provider === 'radar') {
                  this.capacitorIntegration.requestLocationPermissionsOnce();
                }
                successCallback(result);
              }).
              catch(error => errorCallback(error));
          });
        } else {
          Geolocation.getCurrentPosition(options).
          then((result: Position) => {
              if (vs.customer_tracking_provider === 'radar') {
                this.capacitorIntegration.requestLocationPermissionsOnce();
              }
              successCallback(result);
            }).
            catch(error => errorCallback(error));
        }
      });
    });
  }
}
