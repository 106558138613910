import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Product } from '../../../../interfaces/product.interface';
import { MobileService } from '../../../../services/mobile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
  selector: 'app-kiosk-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './kiosk-card.component.html',
  styleUrls: ['kiosk-card.component.scss'],
})
export class KioskCardComponent {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  @Input() imageURL: string;
  @Input() title: string;
  @Input() description: string;
  @Input() isProduct: string;
  @Input() product: Product;
  @Input() productLoading: string;
  @Input() isSkeleton = false;
  @Output() clickedCard = new EventEmitter<any>();
  @Output() addToOrder = new EventEmitter<any>();
  @Output() modifyProduct = new EventEmitter<any>();
  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;

  constructor(public modalService: NgbModal) {}

  cardClicked() {
    this.clickedCard.emit();
  }
  addClicked(event) {
    event.stopPropagation();
    this.addToOrder.emit(event);
  }
  modifyClicked() {
    this.modifyProduct.emit();
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal',
    });
  }
}
