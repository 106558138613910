<ng-container *ngIf="mobile.isMobile; else largeScreenNavbar">
    <app-navbar-mobile
      [order]="order"
      [user]="user"
      (dateTimeSelected)="selectedDateTime($event)">
    </app-navbar-mobile>
</ng-container>

<ng-template #largeScreenNavbar>
    <ng-container *ngIf="mode.mode === 'kiosk'; else desktopNavbar">
    </ng-container>
</ng-template>

<ng-template #desktopNavbar>
    <app-navbar-desktop
        [order]="order"
        [user]="user"
        [cartIcon]="cartIcon"
        [showPrice]="showPrice"
        [branding]="branding"
        [handoffOverride]="handoffOverride"
        (dateTimeSelected)="selectedDateTime($event)"
        (clickedLogout)="logoutClicked()"
        [timeSelectionLoading]="timeSelectionLoading"
        [buttonText]="orderButtonText"
        [showOrder]="showOrderButton"
        (addNewOrderType)="newOrderTypeClicked()"
        (startNewOrder)="startOrder($event)"
        (homeButtonClicked)="homeClicked()"
        [currencyCode]="currencyCode$ | async"
        [navbarSettings]="navbarSettings$ | async"
        (startPickupOrder)="startPickup()"
        (startCurbsideOrder)="startCurbside()"
        (startDriveThruOrder)="startDriveThru()"
        (startDineInOrder)="startDineIn()"
        (startDeliveryOrder)="startDelivery()"
        [deliveryToggleLoading]="deliveryToggleLoading"
        [doneLoading]="doneLoading">
    </app-navbar-desktop>
</ng-template>
