import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { RedeemPointsFromScanner } from '../../../../store/actions/user.actions';
import { ToastService } from '../../../../services/toast.service';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface'; // Adjust the import path
import { TextField } from 'src/vendors/directus/interfaces/text-field.interface'; // Adjust the import path
import { Observable } from 'rxjs';
import { LoyaltySection } from '../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
  selector: 'app-load-points-section',
  templateUrl: './load-points-section.component.html',
  styleUrls: ['./load-points-section.component.scss'],
})
export class LoadPointsSectionComponent implements OnInit {
  @Select(state => state.app.mainSettings.enable_qr_scanner) enableQR$: Observable<boolean>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  @Input() section: LoyaltySection;

  barcodeForm = new UntypedFormGroup({
    manualBarcode: new UntypedFormControl(),
  });

  redeemsCodeSuccessText = 'Code Redeemed';

  constructor(
    private store: Store,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.textFields$.subscribe(textFields => {
      if (textFields) {
        this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
      }
    });
  }

  earnPointsFromReceiptCode() {
    const qr = this.barcodeForm.get('manualBarcode').value;
    if (qr) {
      this.store
        .dispatch(new RedeemPointsFromScanner(qr))
        .toPromise()
        .then(() => {
          this.toast.showDismissableIonicToast(this.redeemsCodeSuccessText, 'de-ionic-success-toast', 8000);
          this.barcodeForm.reset();
        })
        .catch(error => {
          if (error instanceof DineEngineError) {
            if (error.message.includes('range')) {
              this.toast.showDismissableIonicToast('Invalid Receipt Number', 'de-ionic-error-toast', 8000);
            } else {
              this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
            }
          } else {
            this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
          }
        });
    } else {
      this.toast.showDismissableIonicToast('Please enter in code', 'de-ionic-warning-toast', 8000);
    }
  }
}
