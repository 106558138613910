import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rewardsBackgroundImage'
})
export class RewardsBackgroundImagePipe implements PipeTransform {

  transform(imageURL: string): string {
    if (imageURL) {
      return `linear-gradient(90deg, rgba(var(--ion-color-dark-rgb), 0.9) 0%, rgba(var(--ion-color-secondary-rgb), 0.9) 100%), url("${imageURL}")`;
    } else {
      return `linear-gradient(90deg, rgba(var(--ion-color-dark-rgb), 0.9) 0%, rgba(var(--ion-color-primary-rgb), 0.9) 100%)`;
    }
  }

}
