import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {SentryService} from '@common/services/sentry.service';
import {CapacitorIntegrationService} from '@common/services/capacitor-integration.service';
import {User} from '../../../interfaces/user.interface';

@Injectable()
export class TrackingService {
    constructor(
      private sentry: SentryService,
      private capacitorIntegration: CapacitorIntegrationService
    ) {}

    getTracking$(): Observable<{}> {
        return of({});
    }

    pageInitialization(pageName: string) {
      this.sentry.setTransactionName(pageName);
    }

    userInitialization(user: User, trackingVendor: string) {
      if (trackingVendor === 'radar') {
        this.capacitorIntegration.setRadarUserID(user.userID);
      }
      this.sentry.setEmail(user.email);
    }
}
