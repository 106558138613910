import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import {
  CreditCardElements,
  DigitalWallets,
  DigitalWalletsOptions,
  PaymentMethod,
  PaymentMethodResult,
  SingleLineCardElement,
  SingleLineCardElementOptions,
} from '@olo/pay';
import { map, switchMap } from 'rxjs/operators';
import { Order } from '../../../interfaces/order.interface';
import { environment } from '../../../environments/environment';
import { PaymentRequestUpdateOptions } from '@stripe/stripe-js/types/stripe-js/payment-request';

@Injectable()
export class OloPayService {
  cardElements: CreditCardElements = new CreditCardElements(!environment.production ? 'development' : 'production');
  singleCardElement: SingleLineCardElement = new SingleLineCardElement(!environment.production ? 'development' : 'production');
  digitalWallets: DigitalWallets = new DigitalWallets(!environment.production ? 'development' : 'production');
  paymentMethod: PaymentMethod | undefined;
  basketPayment: any;

  private cardNumberCompleteSubject = new BehaviorSubject<boolean>(false);
  // tslint:disable-next-line:variable-name
  private _cardNumberComplete = false;

  readonly cardNumberComplete$ = this.cardNumberCompleteSubject.asObservable();

  getOloPay$(): Observable<{}> {
    return of({});
  }

  // tslint:disable-next-line:max-line-length
  initializeSingleField(): Observable<any> {
    const options: SingleLineCardElementOptions = {
      // elementsOptions: {
      //     fonts: [
      //         {
      //             cssSrc:
      //                 'https://fonts.googleapis.com/css2?family=Mr+Dafoe&family=Pacifico',
      //         },
      //     ],
      // },
      cardElementOptions: {
        classes: {
          base: 'form-control de-light de-border-i de-input',
          // complete: 'custom-name--complete',
          // empty: 'custom-name--empty',
          // focus: 'custom-name--focus',
          // invalid: 'custom-name--invalid',
          // webkitAutofill: 'custom-name--webkit-autofill',
        },
      },
      // mountTarget: '[data-card-input]',
    };
    return from(
      this.singleCardElement.create(options).then(() => {
        return 'initialized';
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  initializeMultipleFields(): Observable<any> {
    return from(
      this.cardElements.create().then(() => {
        return 'initialized';
      })
    );
  }

  initializeWallet(walletConfig: DigitalWalletsOptions, callback: (paymentMethod: PaymentMethod) => void): Observable<any> {
    return from(
      this.digitalWallets.initialize(walletConfig).then(() => {
        this.digitalWallets.mountButton(callback);
        return 'initialized';
      })
    );
  }

  userChanged(): Observable<void> {
    return from<Promise<void>>(
      new Promise((resolve, reject) => {
        if (this.digitalWallets) {
          this.digitalWallets?.unmount();
          setTimeout(() => {
            resolve();
          }, 1000);
        } else {
          resolve();
        }
      })
    );
  }

  tokenizeSingleField(): Observable<any> {
    // let paymentResponse: PaymentMethodResult | undefined;
    let paymentResponse: Observable<any>;
    if (this.singleCardElement) {
      paymentResponse = from(this.singleCardElement.createPaymentMethod());
    }
    return this.mapPaymentMethod(paymentResponse).pipe(
      map(paymentMethod => {
        return paymentMethod;
      })
    );
  }

  tokenizeMultipleField() {
    // let paymentResponse: PaymentMethodResult | undefined;
    let paymentResponse: Observable<any>;
    if (this.singleCardElement) {
      paymentResponse = from(this.singleCardElement.createPaymentMethod());
    }
    return this.mapPaymentMethod(paymentResponse).pipe(
      map(paymentMethod => {
        return paymentMethod;
      })
    );
  }

  mapPaymentMethod(paymentResponse: Observable<PaymentMethodResult>): Observable<any> {
    return paymentResponse.pipe(
      switchMap((paymentMethod: PaymentMethodResult | undefined) => {
        if (paymentMethod) {
          if (paymentMethod.error) {
            return throwError(paymentMethod.error);
          } else {
            this.paymentMethod = paymentMethod.paymentMethod;
            if (this.paymentMethod) {
              if (this.paymentMethod.card) {
                if (this.paymentMethod.billing_details) {
                  if (this.paymentMethod.billing_details.address) {
                    this.basketPayment = {
                      schemeId: 1,
                      token: this.paymentMethod.id,
                      cardType: this.paymentMethod.card.brand,
                      cardLastFour: this.paymentMethod.card.last4,
                      streetAddress: this.paymentMethod.billing_details.address.line1,
                      streetAddress2: this.paymentMethod.billing_details.address.line2,
                      zipCode: this.paymentMethod.billing_details.address.postal_code,
                      countryCode: this.paymentMethod.billing_details.address.country,
                      expirationMonth: this.paymentMethod.card.exp_month,
                      expirationYear: this.paymentMethod.card.exp_year,
                    };
                    return of(this.basketPayment);
                  } else {
                    return throwError('Payment Method billing details address value is undefined');
                  }
                } else {
                  return throwError('Payment Method billing details value is undefined');
                }
              } else {
                return throwError('Payment Method card value is undefined');
              }
            } else {
              return throwError('No Payment Method Submitted');
            }
          }
        } else {
          return throwError('PaymentMethodResult is undefined');
        }
      })
    );
  }

  generateOloPayReciept(order: Order): DigitalWalletsOptions {
    // Get name slug for 'receipt'
    const locationName = order.location.name;
    // Collect line items for 'receipt'
    const displayItems = [];
    // displayItems.push({label: 'Subtotal', amount: order.subTotalCents});
    // displayItems.push({ label: 'Discount', amount: order.discountCents });
    if (order.deliveryFee && order.deliveryFee.feeCents) {
      displayItems.push({
        label: 'Delivery Charge',
        amount: Math.round(order.deliveryFee.feeCents),
      });
    }
    displayItems.push({ label: 'Subtotal', amount: Math.round(order.subTotalCents) });
    // displayItems.push({ label: 'Estimated Taxes & Fees', amount: order.fee. });
    displayItems.push({ label: 'Tip', amount: Math.round(order.tipCents) });

    return {
      options: {
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Pay ' + locationName,
          amount: Math.round(order.totalCents),
        },
        displayItems,
        requestPayerName: true,
        requestPayerEmail: false,
      },
      style: {
        type: 'default',
        theme: 'dark',
        height: '38px',
      },
    };
  }

  generateUpdateOptions(order: Order): PaymentRequestUpdateOptions {
    // Get name slug for 'receipt'
    const locationName = order.location.name;
    // Collect line items for 'receipt'
    const displayItems = [];
    // displayItems.push({label: 'Subtotal', amount: order.subTotalCents});
    // displayItems.push({ label: 'Discount', amount: order.discountCents });
    if (order.deliveryFee && order.deliveryFee.feeCents) {
      displayItems.push({
        label: 'Delivery Charge',
        amount: Math.round(order.deliveryFee.feeCents),
      });
    }
    displayItems.push({ label: 'Subtotal', amount: Math.round(order.subTotalCents) });
    // displayItems.push({ label: 'Estimated Taxes & Fees', amount: order.fee. });
    displayItems.push({ label: 'Tip', amount: Math.round(order.tipCents) });
    return {
      currency: 'usd',
      total: {
        label: 'Pay ' + locationName,
        amount: Math.round(order.totalCents),
      },
      displayItems,
    };
  }
}
