import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Form, FormField } from '../../../../vendors/directus/interfaces/form.interface';
import { ContentService } from '../../../../services/vendor-config-service/content-provider.service';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { Location } from '../../../../interfaces/location.interface';
import { SetStaticLocations } from '../../../../store/actions/location.actions';
import { ProxyBaseURLService } from '../../../../services/proxy-base-url.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.location.staticLocations) staticLocations$: Observable<Location[]>;

  @Input() forminternalid: string;

  private fileStorage: { [key: string]: FileList | null } = {};

  forms: Form[] = [];
  formFields: FormField[] = [];
  formConfig: Form;
  dynamicForm: FormGroup;
  selectedFormId: string;

  // User feedback properties
  submissionSuccess = false;
  submissionError = false;
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private contentService: ContentService,
    private store: Store,
    private baseURL: ProxyBaseURLService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new SetStaticLocations());
    this.contentService.getService().subscribe(service => {
      service.getForms().subscribe(forms => {
        this.forms = forms;
        this.loadForm(this.forminternalid);
      });
    });
  }

  loadForm(formId: string): void {
    this.selectedFormId = formId;
    this.formConfig = this.forms.find(form => form.internal_id === formId);
    this.formFields = this.formConfig.form_fields;
    this.buildForm();
  }

  buildForm(): void {
    const group = {};
    this.formFields.forEach(field => {
      const validators = [];
      if (field.required) {
        validators.push(Validators.required);
      }
      // Additional validators based on field.validation can be added here

      if (field.type === 'upload') {
        group[field.name] = [null];
      } else if (field.type === 'hidden') {
        group[field.name] = [field.default_value, validators];
      } else {
        group[field.name] = ['', validators];
      }
    });
    this.dynamicForm = this.fb.group(group);
  }

  onSubmit(event: Event): void {
    if (this.formConfig.submission_type === 'email') {
      event.preventDefault();
      if (this.dynamicForm.valid) {
        this.handleEmailSubmission();
      } else {
        this.markAllAsTouched();
      }
    }
  }

  handleEmailSubmission(): void {
    const formData = new FormData();

    // Append form fields to FormData
    for (const key in this.dynamicForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.dynamicForm.value, key)) {
        const value = this.dynamicForm.get(key).value;

        // Use the stored files for file inputs
        if (this.fileStorage[key]) {
          const files = this.fileStorage[key];
          for (let i = 0; i < files.length; i++) {
            formData.append(key, files.item(i), files.item(i).name);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    this.isSubmitting = true;
    this.submissionSuccess = false;
    this.submissionError = false;
    this.baseURL.getBaseURL().subscribe(baseURL => {
      this.http.post(`${baseURL}form_submission/${this.forminternalid}`, formData).subscribe(
        response => {
          console.log('Email sent successfully:', response);
          this.submissionSuccess = true;
          this.isSubmitting = false;
          this.dynamicForm.reset();
          this.fileStorage = {}; // Clear stored files
        },
        error => {
          console.error('Error sending email:', error);
          this.submissionError = true;
          this.isSubmitting = false;
        }
      );
    });
  }

  markAllAsTouched(): void {
    Object.values(this.dynamicForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  onFileChange(event: Event, field: FormField): void {
    const input = event.target as HTMLInputElement;
    const files: FileList = input.files;

    const allowedTypes = this.getAllowedTypes(field);
    const maxSize = this.getMaxSize(field);

    // Validate files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!allowedTypes.includes(file.type)) {
        alert(`File type not allowed: ${file.type}`);
        input.value = ''; // Reset the file input
        return;
      }
      if (file.size > maxSize) {
        alert(`File size exceeds limit: ${file.size} bytes`);
        input.value = ''; // Reset the file input
        return;
      }
    }

    // Store the FileList in the local variable
    this.fileStorage[field.name] = files;

    // // Optionally, reset the form control if you still want some validation
    // this.dynamicForm.get(field.name).setValue(null);
  }

  // Placeholder methods for allowed types and max size
  getAllowedTypes(field: FormField): string[] {
    return field.file_upload_options.length ? field.file_upload_options[0].allowed_file_types : [];
  }

  getMaxSize(field: FormField): number {
    // Fetch max size based on field configurations
    // For example: 10 MB
    return (field.file_upload_options[0]?.max_file_size || 10) * 1024 * 1024;
  }

  // Method to map size to Bootstrap column class
  getColumnClass(size: string): string {
    switch (size) {
      case 'full':
        return 'col-12';
      case 'half':
        return 'col-sm-12 col-md-6';
      default:
        return 'col-12'; // default to full
    }
  }
}
