import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  Renderer2,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Directive({
  selector: '[appBgLoader]'
})
export class BgLoaderDirective implements OnInit, OnChanges {

  @Select(state => state.app.branding) branding$: Observable<Branding>;

  @Input('appBgLoader') image;
  @Input() shine;

  @Output() load = new EventEmitter();

  loadInitialize = false;
  backgroundSrc: string;
  intersection: IntersectionObserver;
  displayBranding: Branding;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    // private navbarService: NavBarService
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'backgroundImage');
    if (this.shine) {
      this.renderer.addClass(this.el.nativeElement, 'shine');
    }
    this.startObserver();
    this.branding$.subscribe(branding => {
      this.displayBranding = branding;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (
      changes.image.currentValue.src &&
      (!changes.image.previousValue || !changes.image.previousValue.src)
    ) {
      this.startObserver();
    }
  }

  startObserver() {
    this.intersection = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio > 0) {
        this.loadImage();
        this.intersection.unobserve(this.el.nativeElement);
      }
    }, {});
    this.intersection['POLL_INTERVAL'] = 100;
    this.intersection.observe(this.el.nativeElement);
  }

  loadImage() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (this.image.src == 'empty' || this.image.src === null) {
      this.backgroundSrc = this.displayBranding.default_image.data.full_url || 'null';
    } else if (!isSafari && this.image.webp) {
      this.backgroundSrc = this.image.webp;
    } else if (isSafari && this.image.jp2) {
      this.backgroundSrc = this.image.jp2;
    } else {
      this.backgroundSrc = this.image.src;
    }

    const curImg = new Image();
    curImg.src = this.backgroundSrc;
    curImg.onload = () => {
      this.el.nativeElement.style.backgroundImage = `url( ${
        this.backgroundSrc
      } )`;
      if (this.shine) {
        this.renderer.removeClass(this.el.nativeElement, 'shine');
      }

      this.renderer.addClass(this.el.nativeElement, 'backgroundImageLoaded');
      this.load.emit();
    };
  }
}
