import { Component, Input } from '@angular/core';
import { Order } from 'src/interfaces/order.interface';
import { MobileService } from 'src/services/mobile.service';
import { Router } from '@angular/router';
import { HandoffType } from '../../../../../interfaces/handoff-type.enum';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateModel } from '../../../../../store/state/app.state';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';

@Component({
  selector: 'app-desktop-nav-order-type',
  templateUrl: './nav-order-type.component.html',
  styleUrls: ['./nav-order-type.component.scss'],
})
export class DesktopNavOrderTypeComponent {
  @Select(state => state.app) app$: Observable<AppStateModel>;
  @Select(state => state.app.textField) textField$: Observable<TextField>;
  @Input() order: Order;
  handoffs = HandoffType;

  filterTags: string[];
  constructor(
    public mobile: MobileService,
    public router: Router
  ) {
    this.textField$.subscribe(textFields => {
      if (textFields) {
        this.filterTags = textFields.filter_brand_name_from_location;
      }
    });
  }
}
