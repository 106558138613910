import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OptionGroup} from '../../../../interfaces/option-group.interface';
import {OptionStack, OptionSubGroup, SubOption} from '@modules/menu/components';
import {Option} from '../../../../interfaces/option.interface';

@Component({
    selector: 'app-dropdown-selector',
    templateUrl: './dropdown-selector.component.html',
    styleUrls: ['dropdown-selector.component.scss'],
})
export class DropdownSelectorComponent {
  @Input() optionGroup: OptionGroup;
  @Input() optionSubGroup: OptionSubGroup;
  @Output() optionClick = new EventEmitter<Option>();
  @Output() subOptionClick = new EventEmitter<OptionStack>();
  selectedOption: Option;
  selectedSubOption: Option;
  optionClicked(optionID: string) {
    if (optionID) {
      const option = this.optionGroup.options.find((opt: Option) => opt.optionID === optionID);
      this.selectedOption = option;
      this.optionClick.emit(option);
    }
  }
  subOptionClicked(optionID: string) {
    if (optionID) {
      const subOption = this.optionSubGroup.subOptions.find((opt: SubOption) => opt.option.optionID === optionID);
      this.selectedSubOption = subOption.option;
      this.subOptionClick.emit({
        option: subOption.option,
        optionGroup: this.optionGroup,
        subOptionGroup: this.optionSubGroup
      });
    }
  }
}
