<ng-template #timeoutModal>
  <div
    id="timeoutModal"
    tabindex="-1"
    aria-labelledby="timeoutModalLabel"
    aria-hidden="true">
    <div class="d-flex flex-row flex-nowrap justify-content-between p-3">
      <h4 class="h4 modal-title" id="timeoutLabel" style="font-weight: bold">
        Do you need more time?
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16">
          <path
            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </button>
    </div>
    <div class="h5 modal-body">
      Your order will be canceled after
      <span class="fw-bold" id="inactiveTimeoutCountdown">{{
          timeoutService.timeLeft
        }}</span>
      seconds of inactivity.
    </div>
    <div class="d-flex flex-row flex-nowrap justify-content-end p-3">
      <button
        type="button"
        class="btn ki-buttons outline-primary me-3"
        data-dismiss="modal"
        (click)="startOrderOver()">
        Start Over
      </button>
      <button type="button" class="btn ki-buttons" (click)="closeModal()">
        Continue
      </button>
    </div>
  </div>
</ng-template>
