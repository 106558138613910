import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-section-frame',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './section-frame.component.html',
    styleUrls: ['section-frame.component.scss'],
})
export class SectionFrameComponent {
  @Input() sectionTitle: string;
  @Input() sectionBadge: string;
}
