import {Injectable} from '@angular/core';

// usage: <ngx-dynamic-hooks [content]="'Load a component here: <app-example></app-example>'"></ngx-dynamic-hooks>

/*
const root = parse('<ul id="list"><li>Hello World</li></ul>');
console.log(root.querySelector('li').toString());
console.log(root.toString());
*/

@Injectable({
  providedIn: 'root',
})

export class HtmlParserService {
  getBody(html: string): string {
    let toReturn = html ? html : '';
    if (toReturn.includes('<body')) {
      const outerWrapper = document.createElement('div');
      outerWrapper.innerHTML = html;
      toReturn = outerWrapper.innerHTML
      toReturn = toReturn.replace('&quot;', '\'');
      const parsedHTML = document.createElement('div');
      parsedHTML.innerHTML = toReturn;
      const pastOrdersBuilderEl = parsedHTML.querySelector('builder-block-past-orders');
      if (pastOrdersBuilderEl) {
        const appPastOrdersEl = document.createElement('app-home-reorder');
        appPastOrdersEl.style.display = 'none';
        const parent = pastOrdersBuilderEl.parentNode
        const wrapper = document.createElement('div')
        parent.replaceChild(wrapper, pastOrdersBuilderEl);
        wrapper.appendChild(appPastOrdersEl)
        // pastOrdersBuilderEl.parentNode.replaceChild(appPastOrdersEl, pastOrdersBuilderEl);
      }
      // this.createElement(parsedHTML, 'builder-block-past-orders', 'home-reorder');
      const menuBuilderEl = parsedHTML.querySelector('builder-block-menu');
      if (menuBuilderEl) {
        const appMenuEl = document.createElement('app-home-menu');
        appMenuEl.style.display = 'none';
        const parent = menuBuilderEl.parentNode
        const wrapper = document.createElement('div')
        parent.replaceChild(wrapper, menuBuilderEl);
        wrapper.appendChild(appMenuEl)
        // menuBuilderEl.parentNode.replaceChild(appMenuEl, menuBuilderEl)
      }
      // this.createElement(parsedHTML, 'builder-block-menu', 'home-menu');
      const instagramBuilderEl = parsedHTML.querySelector('builder-block-instagram');
      if (instagramBuilderEl) {
        const appInstagramEl = document.createElement('app-instafeed');
        appInstagramEl.id = 'instafeed'
        appInstagramEl.style.display = 'none';
        const parent = instagramBuilderEl.parentNode
        const wrapper = document.createElement('div')
        parent.replaceChild(wrapper, instagramBuilderEl);
        wrapper.appendChild(appInstagramEl)
        // instagramBuilderEl.parentNode.replaceChild(appInstagramEl, instagramBuilderEl);
      }
      // this.createElement(parsedHTML, 'builder-block-instagram', 'instafeed');
      const featuredItemsBuilderEl = parsedHTML.querySelector('builder-block-featured-items');
      if (featuredItemsBuilderEl) {
        const appFeaturedItemsEl = document.createElement('app-featured-items');
        appFeaturedItemsEl.style.display = 'none';
        const parent = featuredItemsBuilderEl.parentNode
        const wrapper = document.createElement('div')
        parent.replaceChild(wrapper, featuredItemsBuilderEl);
        wrapper.appendChild(appFeaturedItemsEl)
        // featuredItemsBuilderEl.parentNode.replaceChild(appFeaturedItemsEl, featuredItemsBuilderEl)
      }
      // this.createElement(parsedHTML, 'builder-block-featured-items', 'featured-items');
      this.createElement(parsedHTML, 'builder-block-static-locations', 'static-locations');
      toReturn = parsedHTML.innerHTML;
    }
    return toReturn;
  }
  getStyles(html: string): Promise<any> {
    let toReturn = html ? html : '';
    if (toReturn.includes('<body')) {
      const outerWrapper = document.createElement('div');
      outerWrapper.innerHTML = html;
      toReturn = outerWrapper.innerHTML;
      toReturn = toReturn.replace('&quot;', '\'');
      const parsedHTML = document.createElement('div');
      parsedHTML.innerHTML = toReturn;
      const styles = parsedHTML.getElementsByTagName('style');
      document.head.append(styles[0]);
      toReturn = parsedHTML.innerHTML;
    }
    return (document as any).fonts.ready;
  }
  createElement(parsedHTML: any, builderElementName: string, appElementID: string) {
    const builderElement = parsedHTML.querySelector(builderElementName);
    if (builderElement) {
      const appElement = document.createElement('app-' + appElementID);
      appElement.id = appElementID;
      // appElement.style.display = 'none';
      const parent = builderElement.parentNode;
      const wrapper = document.createElement('div');
      parent.replaceChild(wrapper, builderElement);
      wrapper.appendChild(appElement);
    }
  }
}
