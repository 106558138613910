<div class="card-type d-flex align-items-center justify-content-center">
  <ng-container *ngIf="cardType === 'mastercard'">
    <div class="mastercard-icon">
      <img [alt]="cardType + ' logo'" [height]="size" src="assets/icons/cc-mastercard-bw.svg">
    </div>
  </ng-container>

  <ng-container *ngIf="cardType === 'visa'">
    <div class="visa-icon">
      <img [alt]="cardType + ' logo'" [height]="size" src="assets/icons/cc-visa-bw.svg">
    </div>
  </ng-container>

  <ng-container *ngIf="cardType === 'amex'">
    <div class="amex-icon">
      <img [alt]="cardType + ' logo'" [height]="size" src="assets/icons/cc-amex-bw.svg">
    </div>
  </ng-container>

  <ng-container *ngIf="cardType === 'discover'">
    <div class="discover-icon">
      <img [alt]="cardType + ' logo'" [height]="size" src="assets/icons/cc-discover-bw.svg">
    </div>
  </ng-container>
</div>
