<ng-container *ngIf="order$ | async as order">
  <div class="d-flex flex-column p-3">
    <!-- small fees/subtotals -->
    <div class="d-flex flex-column mb-3">
      <ng-container *ngIf="order.appliedRewards.length">
        <ng-container *ngFor="let reward of order.appliedRewards; last as last">
<!--          <ng-container *ngIf="!reward.isCoupon">-->
            <app-reward-card
              [reward]="reward"
              [canApply]="!areRewardsSelecting"
              [currencyCode]="currencyCode$ | async"
              (clickedRedeem)="redeemedReward(reward)"
              (clickedRemove)="removedReward(reward)"
              [loadingReward]="loadingReward"
              [isCart]="true" [class.mb-2]="!last"></app-reward-card>
<!--          </ng-container>-->
        </ng-container>
      </ng-container>

      <div class="d-flex flex-nowrap mt-1">
        <div class="fees-small me-3">Subtotal</div>
        <div class="fees-small-right">
          {{ order?.subTotalCents / 100 | currency: (currencyCode$ | async) }}
        </div>
      </div>

      <ng-container *ngIf="order?.taxCents >= 0">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">Estimated Tax</div>
          <div class="fees-small-right">
            {{ order?.taxCents / 100 | currency: (currencyCode$ | async) }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="order?.deliveryFee">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">
            {{ order?.deliveryFee.description }}
          </div>
          <div class="fees-small-right">
            {{
              order?.deliveryFee.feeCents / 100
                | currency: (currencyCode$ | async)
            }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="order">
        <ng-container *ngFor="let fee of order?.fees">
          <ng-container *ngIf="fee.feeCents > 0">
            <div class="d-flex flex-nowrap mt-1">
              <div class="fees-small me-3">
                {{ fee.description }}
              </div>
              <div class="fees-small-right">
                {{ fee.feeCents / 100 | currency: (currencyCode$ | async) }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="order?.tipCents > 0">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">Tip Amount</div>
          <div class="fees-small-right">
            {{ order?.tipCents / 100 | currency: (currencyCode$ | async) }}
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="order?.appliedCouponCents && order?.appliedCouponCode"
        id="couponRow">
        <div class="d-flex flex-nowrap mt-1" id="couponRow">
          <div class="fees-small me-3">Coupon</div>
          <div class="fees-small-right">
            -
            {{
              order?.appliedCouponCents / 100
                | currency: (currencyCode$ | async)
            }}
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="order?.donationsTotal && order?.donationsTotal > 0"
        id="donationRow">
        <div class="d-flex flex-nowrap mt-1" id="donationRow">
          <div class="fees-small me-3">Donation</div>
          <div class="fees-small-right">
            {{ order?.donationsTotal | currency: (currencyCode$ | async) }}
          </div>
        </div>
      </ng-container>

      <!--      <ng-container *ngIf="order?.appliedCouponCents && order?.appliedRewards.length > 0">-->
      <!--        <div class="d-flex flex-nowrap">-->
      <!--          <div class="fees-small">-->
      <!--            Discount - {{ order.appliedRewards[0].name }}-->
      <!--          </div>-->
      <!--          <div class="fees-small-right">-->
      <!--            - {{ order?.appliedCouponCents / 100 | currency: (currencyCode$ | async) }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </ng-container>-->

      <ng-container
        *ngIf="appliedLoyaltyBalance && appliedLoyaltyBalance.balanceCents">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">Loyalty Balance</div>
          <div class="fees-small-right">
            -
            {{
              (order.totalCents - giftCardTotal() >
              appliedLoyaltyBalance.balanceCents
                ? appliedLoyaltyBalance.balanceCents
                : order.totalCents - giftCardTotal()) / 100
                | currency: (currencyCode$ | async)
            }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="giftCards[0]">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">
            Gift Card *{{
              giftCards[0].cardNumber.substr(giftCards[0].cardNumber.length - 4)
            }}
          </div>
          <ng-container *ngIf="giftCards[0].balanceCents > order?.totalCents">
            <div class="fees-small-right">
              -
              {{ order?.totalCents / 100 | currency: (currencyCode$ | async) }}
            </div>
          </ng-container>
          <ng-container *ngIf="giftCards[0].balanceCents < order?.totalCents">
            <div class="fees-small-right">
              -
              {{
                giftCards[0].balanceCents / 100
                  | currency: (currencyCode$ | async)
              }}
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="giftCards[1]">
        <div class="d-flex flex-nowrap mt-1">
          <div class="fees-small me-3">
            Gift Card *{{
              giftCards[1].cardNumber.substr(giftCards[1].cardNumber.length - 4)
            }}
          </div>
          <ng-container *ngIf="giftCardTotal() > order?.totalCents">
            <div class="fees-small-right">
              -
              {{
                (order?.totalCents - giftCards[0].balanceCents) / 100
                  | currency: (currencyCode$ | async)
              }}
            </div>
          </ng-container>
          <ng-container *ngIf="giftCardTotal() < order?.totalCents">
            <div class="fees-small-right">
              -
              {{
                giftCards[1].balanceCents / 100
                  | currency: (currencyCode$ | async)
              }}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <hr class="border-top w-100 m-0 mb-3" />

    <!-- total -->
    <div class="d-flex flex-nowrap mb-3">
      <div class="fees-total me-3">Total</div>
      <ng-container
        *ngIf="
          order && giftCardTotal() + appliedLoyaltyBalance.balanceCents > 0
        ">
        <div class="fees-total-right">
          <ng-container
            *ngIf="
              giftCardTotal() + appliedLoyaltyBalance.balanceCents >
              order?.totalCents
            ">
            <div>
              {{ 0 | currency: (currencyCode$ | async) }}
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              giftCardTotal() + appliedLoyaltyBalance.balanceCents <
              order?.totalCents
            ">
            <div>
              {{
                (order.totalCents -
                  (giftCardTotal() + appliedLoyaltyBalance.balanceCents)) /
                  100 | currency: (currencyCode$ | async)
              }}
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          order && giftCardTotal() + appliedLoyaltyBalance?.balanceCents === 0
        ">
        <div class="fees-total-right">
          {{ order.totalCents / 100 | currency: (currencyCode$ | async) }}
        </div>
      </ng-container>
    </div>

    <!-- submit -->
    <div class="d-flex flex-nowrap flex-column flex-sm-row" style="grid-gap: 1rem;">
      <ng-container [ngSwitch]="paymentType">
        <ng-container *ngSwitchCase="paymentTypes.applePay">
          <div
            style="
              display: inline-block;
              -webkit-appearance: -apple-pay-button;
              -apple-pay-button-type: order;
              -apple-pay-button-style: black;
              width: 100%;
              height: 45px;
            "
            lang="en"
            (click)="clickedSubmit()"></div>
          <!--          <button type="submit" class="de-primary-btn text-white w-100" id="submitApplePayOrder" size="block" style="background-color: black"-->
          <!--                  (click)="clickedSubmit()" [disabled]="submitBlocked">-->
          <!--            <div *ngIf="isLoading" style="margin: 0 43%;" class="de-btn-loader"></div>-->
          <!--            <div *ngIf="!isLoading">Order with <i class="bi bi-apple"></i> Pay</div>-->
          <!--          </button>-->
        </ng-container>
        <ng-container *ngSwitchCase="paymentTypes.googlePay">
          <button
            type="button"
            aria-label="Google Pay"
            class="gpay-button black order en"
            (click)="clickedSubmit()"></button>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <button
            (click)="cartService.continueOrderingClicked()"
            class="de-primary-btn-outline w-100">
            <ng-container *ngIf="!cartService.continueButtonLoading">
              Add More
            </ng-container>
            <ng-container *ngIf="cartService.continueButtonLoading">
              <div class="de-btn-loader continue"></div>
            </ng-container>
          </button>

          <button
            type="submit"
            class="de-success-btn w-100"
            id="submitOrder"
            size="block"
            (click)="clickedSubmit()">
            <div
              *ngIf="isLoading"
              style="margin: 0 43%"
              class="de-btn-loader"></div>
            <div *ngIf="!isLoading">Send Order</div>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
