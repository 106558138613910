import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Location } from '../../../../interfaces/location.interface';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { RecaptchaComponent, SubscriptionComponent } from '@common/components';
import { CustomPage } from '../../../../vendors/directus/interfaces/custom-page.interface';
import { LocationService } from '../../../../services/vendor-config-service/location.service';
import { filter } from 'rxjs/operators';
import { ContactForm } from '../../../../vendors/directus/interfaces/contact-form.interface';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { ToastService } from '../../../../services/toast.service';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { RecaptchaConfiguration } from '../../../../vendors/directus/interfaces/recaptcha-configuration.interface';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['contact-us-form.component.scss'],
})
export class ContactUsFormComponent extends SubscriptionComponent implements OnInit {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.customPages) customPages$: Observable<CustomPage[]>;
  @Select(state => state.app.contactForm) contactForm$: Observable<ContactForm[]>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  @Input() recaptchaConfig: RecaptchaConfiguration;

  @Output() submitContactFormClick = new EventEmitter<any>();

  @ViewChild('recaptchaRef') recaptchaComponent: RecaptchaComponent;

  contactForm = this.fb.group({
    firstName: this.fb.control('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    lastName: this.fb.control('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    emailAddress: this.fb.control('', [Validators.required, Validators.email]),
    phoneNumber: this.fb.control('', [Validators.pattern(/\d{10}/g)]),
    contactReason: this.fb.control('', Validators.required),
    eventDate: this.fb.control(''),
    location: this.fb.control(''),
    messageContent: this.fb.control('', Validators.required),
  });

  displayContactForm: ContactForm[];

  content: SafeHtml;
  contactLocations: Location[] = [];
  requireLocationSelection = false;
  contactStates: string[] = [];
  submitAttempt = false;
  successMessage = '';
  errorMessage = '';
  sendingForm = false;

  constructor(
    private fb: UntypedFormBuilder,
    private locationService: LocationService,
    private sanitizer: DomSanitizer,
    private directus: DirectusService,
    private toast: ToastService
  ) {
    super();
  }

  ngOnInit() {
    this.disableRequireLocation();
    this.subs.push(
      this.customPages$.subscribe(customPages => {
        if (customPages) {
          const unsafeContent = customPages.find(page => page.slug === 'contact-form')?.content;
          if (unsafeContent !== '') {
            if (unsafeContent) {
              this.content = this.sanitizer.bypassSecurityTrustHtml(unsafeContent);
            }
          }
        }
      }),
      this.locationService.getService().subscribe(lService => {
        lService.getLocations().subscribe(locations => {
          // tslint:disable-next-line:max-line-length
          // this.contactLocations = locations.filter(loc => !loc.isPrivate && loc.isLive).sort((a, b) => (a.address.city > b.address.city) ? 1 : -1);
          this.contactLocations = locations.sort((a, b) => (a.address.city > b.address.city ? 1 : -1));
          this.contactLocations.forEach(loc => {
            if (!this.contactStates.includes(loc.address.state)) {
              this.contactStates.push(loc.address.state);
            }
          });
          this.contactStates.sort();
        });
      }),
      this.contactForm$.subscribe(contactForm => {
        if (contactForm) {
          this.displayContactForm = contactForm;
        }
      })
    );
  }

  submitClick() {
    if (this.recaptchaConfig?.enabled) {
      this.recaptchaComponent.execute();
    } else {
      this.submitForm();
    }
  }

  handleToken(token: string) {
    sessionStorage.setItem('recaptchaToken', token);
    this.submitForm();
  }

  submitForm() {
    if (!this.sendingForm) {
      this.successMessage = '';
      this.errorMessage = '';
      this.submitAttempt = true;
      this.sendingForm = true;
      const errMsg = 'Something went wrong with the submission, please try again.';
      if (this.contactForm.valid) {
        const data = {
          inquiry_id: this.contactReason.value,
          Name: String(this.firstName.value).concat('', this.lastName.value),
          Email: this.emailAddress.value,
          Phone: this.phoneNumber.value,
          Date: this.eventDate.value,
          Location: this.location.value.split('&&')[1],
          Message: this.messageContent.value,
          location_id: this.location.value.split('&&')[0],
        };
        this.directus.postContactForm(data).subscribe(
          (res: any) => {
            if (res && res.success) {
              this.successMessage = 'Thank you for your feedback, we will get back to you as soon as possible.';
              this.toast.success(this.successMessage);
              this.contactForm.reset();
              this.sendingForm = false;
            } else {
              this.errorMessage = errMsg;
              this.toast.danger(this.errorMessage);
              this.sendingForm = false;
            }
            sessionStorage.removeItem('recaptchaToken');
          },
          err => {
            if (err.error?.error?.message) {
              this.errorMessage = errMsg + ' Message from server: ' + err.error.error.message;
            } else {
              this.errorMessage = errMsg + ' Message from server: ' + err.error.message;
            }
            this.toast.danger(this.errorMessage);
            this.sendingForm = false;
            sessionStorage.removeItem('recaptchaToken');
          }
        );
      } else {
        this.contactForm.markAllAsTouched();
        this.errorMessage = 'Please correct the errors in the form and try submitting again.';
        this.sendingForm = false;
      }
    }
  }

  disableRequireLocation() {
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => (this.requireLocationSelection = ms.require_location_selection));
  }

  onKey(e, nextElement) {
    if (e.key === 'Enter') {
      nextElement.setFocus();
    }
  }

  get contactReason() {
    return this.contactForm.get('contactReason');
  }
  get firstName() {
    return this.contactForm.get('firstName');
  }
  get lastName() {
    return this.contactForm.get('lastName');
  }
  get emailAddress() {
    return this.contactForm.get('emailAddress');
  }
  get phoneNumber() {
    return this.contactForm.get('phoneNumber');
  }
  get eventDate() {
    return this.contactForm.get('eventDate');
  }
  get location() {
    return this.contactForm.get('location');
  }
  get messageContent() {
    return this.contactForm.get('messageContent');
  }
}
