/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import * as SentryAngular from '@sentry/angular';
import '@capacitor/core';
import { Replay } from '@sentry/replay';
import { Capacitor } from '@capacitor/core';
import supportedBrowsers from '../scripts/supportedBrowsers';
import { Device } from '@capacitor/device';
const { version: appVersion } = require('../package.json');
import { register } from 'swiper/element/bundle';

function boot() {
  register();
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

if (environment.production) {
  enableProdMode();
}

if (!environment.debug) {
  console.group = console.info = console.debug = console.log = console.trace = console.warn = () => {};
}

// async function isSupportedBrowser(): Promise<boolean> {
//   if (Capacitor.getPlatform() === 'web') {
//     if (detectshun.browser().name === 'Safari') {
//       console.log(detectshun.browser().version);
//       return !(
//         Number(detectshun.browser().version) < 13 ||
//         (Number(detectshun.browser().version) >= 15.0 &&
//           Number(detectshun.browser().version) <= 15.4)
//       );
//     }
//     return (supportedBrowsers as RegExp).test(navigator.userAgent);
//   } else {
//     const info = await Device.getInfo();
//     if (info.platform === 'ios') {
//       return !(
//         info.iOSVersion < 13 ||
//         (info.iOSVersion >= 15.0 && info.iOSVersion <= 15.4)
//       );
//     } else {
//       return !(info.androidSDKVersion < 24);
//     }
//   }
// }

SentryAngular.init({
  environment: environment.debug ? 'stage' : 'production',
  release: appVersion,
  dsn: environment.sentryDsn,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.001,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: ['Non-Error exception captured', 'isProxied', 'ChunkLoadError', /Loading chunk [\d]+ failed/],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    // new SentryAngular.BrowserTracing({
    //   routingInstrumentation: SentryAngular.routingInstrumentation,
    // }),
    // SentryAngular.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: 'system',
    //   autoInject: false,
    //   showBranding: false,
    // }),
    new Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
      block: ['ion-content#main-content-end', 'ion-content.mobile-nav-scroll'],
    }),
  ],
  enableTracing: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['https://api.dineengine.io', 'https://api.stage.dineengine.io'],
});

boot();

// isSupportedBrowser().then(supported => {
//   if (supported) {
//     boot();
//   } else {
//     document.body.innerHTML = (`
// 				    <div style="display: flex; flex-direction: column; height: 100%; margin: auto; text-align: center; justify-content: center; align-items: center; font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif">
//         <h1>⚠</h1>
//         <h2>Unsupported Browser</h2>
//         <p class="desc">Your current browser version does not support features that are required by this application. Please update your browser to continue.</p>
//         <a class="btn btn-primary" href="https://www.wikihow.com/Update-Your-Browser" target="_blank">Learn How</a>
//     </div>
// 				`);
//   }
// }).catch(() => {
//   document.body.innerHTML = (`
// 				    <div style="display: flex; flex-direction: column; height: 100%; margin: auto; text-align: center; justify-content: center; align-items: center; font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif">
//         <h1>⚠</h1>
//         <h2>Unsupported Browser</h2>
//         <p class="desc">Your current browser version does not support features that are required by this application. Please update your browser to continue.</p>
//         <a class="btn btn-primary" href="https://www.wikihow.com/Update-Your-Browser" target="_blank">Learn How</a>
//     </div>
// 				`);
// });
