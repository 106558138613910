<div class="d-flex flex-column w-100 h-100">
  <!-- Header Section -->
  <div class="d-flex flex-row flex-nowrap justify-content-between gap-3 w-100 mb-3 px-3 px-lg-0">
    <h4 class="fw-bold header-font mb-0 d-flex flex-row align-items-center">
      <app-custom-icon
        class="color-primary me-2"
        default="bi-receipt-cutoff"
        customIconKey="orders_navigation_icon"
        altText="Orders">
      </app-custom-icon>
      {{ section?.section_title || 'Previous Orders' }}
    </h4>
  </div>

  <!-- Content Section -->
  <ng-container *ngIf="displayPastOrders$ | async as pastOrders; else ordersLoading">
    <ng-container *ngIf="pastOrders.length > 0; else noOrders">
      <div class="reorder-cards-container w-100">
        <div [class.cards-grid-vertical]="!isDashboard" class="cards-grid px-3 px-lg-0 w-100">
          <app-reorder-card
            *ngFor="let order of pastOrders; let i = index"
            [order]="order"
            [isLoading]="isReorderLoading[i]"
            (cancelOrder)="cancelOrder.emit(order)"
            (clickedReorder)="reorder.emit({ order, index: i })"
            (clickedViewConfirmation)="viewConfirmation.emit(order)"
            (editOrder)="editOrder.emit(order)">
          </app-reorder-card>
        </div>
      </div>
    </ng-container>

    <!-- No Orders Template -->
    <ng-template #noOrders>
      <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
        <app-custom-icon
          class="color-primary display-3"
          default="bi-receipt-cutoff"
          customIconKey="orders_navigation_icon"
          altText="Orders">
        </app-custom-icon>
        <div class="text-center d-flex flex-column">
          <h5 class="fw-bold">You currently have no previous orders.</h5>
          <div>Place your first order today.</div>
        </div>
        <button
          class="de-primary-link w-100"
          style="max-width: 264px"
          [routerLink]="locationsRoute">
          Order Now
        </button>
      </div>
    </ng-template>
  </ng-container>

  <!-- Loading Skeletons Template -->
  <ng-template #ordersLoading>
    <div class="reorder-cards-container w-100">
      <ion-list class="cards-grid w-100 px-3 px-lg-0 pb-3" [class.cards-grid-vertical]="!isDashboard">
        <ng-container *ngFor="let item of [].constructor(6)">
          <app-skeleton-card class="w-100" [image]="false"></app-skeleton-card>
        </ng-container>
      </ion-list>
    </div>
  </ng-template>
</div>
