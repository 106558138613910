import {HttpUrlEncodingCodec} from '@angular/common/http';

export class UrlEncoder implements HttpUrlEncodingCodec {
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
