import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';


@Component({
  selector: 'app-downloading-update-modal',
  templateUrl: './downloading-update-modal.component.html',
  styleUrls: ['./downloading-update-modal.component.scss'],
})
export class DownloadingUpdateModalComponent implements OnInit {

  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;

  @Input() receivedAmount = 0;
  @Input() totalAmount = 1;
  @Input() title = 'Checking for Update';

  constructor() { }

  ngOnInit() {}

}
