import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-frame',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss']
})
export class ModalFrameComponent {

  @Input() error;
  @Input() set open(value: boolean) {
    if (value === true) {
      this.openModal();
    } else if (value === false) {
      this.closeModal();
    }
  }
  @Output() modalClosed = new EventEmitter<any>();

  openModal() {
    document.getElementById('modalPopup').style.display = 'block';
  }

  closeModal() {
    document.getElementById('modalPopup').style.display = 'none';
    this.modalClosed.emit();
  }

}
