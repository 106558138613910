import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {User} from '../interfaces/user.interface';
import {map} from 'rxjs/operators';
import {NavigationService} from '../modules/navigation/services';
import {GlobalStateModel} from '../store/state.model';

@Injectable({
  providedIn: 'root',
})
export class CompleteProfileGuard  {

  constructor(
      private store: Store,
      private navigation: NavigationService,
  ) {

  }

  canActivate(): Observable<boolean> {
    return this.store.selectOnce((state: GlobalStateModel) => state.user.user).pipe(
      map((user: User) => {
        if (user) {
          if (user.isGuest) {
            return true;
          } else if (user.firstName && user.lastName && user.email && user.phoneNumber) {
            return true;
          } else {
            this.navigation.navigateToIncompleteProfilePage();
            return false;
          }
        }
        return true;
      }));
  }

}
