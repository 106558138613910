import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProxyBaseURLService} from '../../services/proxy-base-url.service';
import {Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DatacapHttpService {
    private vendorName = 'datacap';
    private headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient, private urlService: ProxyBaseURLService) {
    }

    get<T>(resource: string): Observable<T> {
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.get<T>(baseURL + resource, { headers: this.headers }).pipe(map(res => {
                return res;
            }, catchError(e => {
                return e;
            })));
        }));
    }

    put<T>(resource: string, body: any): Observable<T> {
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.put<T>(baseURL + resource, body, { headers: this.headers }).pipe(map(res => {
                return res;
            }, catchError(e => {
                return e;
            })));
        }));
    }

    post<T>(resource: string, body: any): Observable<T> {
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.post<T>(baseURL + resource, body, { headers: this.headers }).pipe(map(res => {
                return res;
            }, catchError(e => {
                return e;
            })));
        }));
    }

    patch<T>(resource: string, body: any): Observable<T> {
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.patch<T>(baseURL + resource, body, { headers: this.headers }).pipe(map(res => {
                return res;
            }, catchError(e => {
                return e;
            })));
        }));
    }

    delete<T>(resource: string): Observable<T> {
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.delete<T>(baseURL + resource, { headers: this.headers }).pipe(map(res => {
                return res;
            }, catchError(e => {
                return e;
            })));
        }));
    }
}
