<!--<div class="activity-card border">-->
<!--  <small class="mb-2">-->
<!--    {{ event.timeStamp | date: 'longDate' }} • {{ event.timeStamp | date: 'h:mm a' }}-->
<!--  </small>-->
<!--  <h6 class="header-font font-size-subheading-2 fw-bold">-->
<!--    {{ event.type }}-->
<!--  </h6>-->
<!--  <ng-container *ngIf="event.description">-->
<!--    <p>{{ event.description }}</p>-->
<!--  </ng-container>-->
<!--  <app-activity-event-details [event]="event"></app-activity-event-details>-->
<!--</div>-->
<ion-item detail="false" class="de-border-bottom" style="cursor: unset;">
  <ion-label class="ion-padding-vertical ion-no-margin">
    <strong class="line-clamp-1" style="display: -webkit-box !important;">{{ event.type }}</strong>
    <ion-note>
      {{ event.timeStamp | date: 'longDate' }} • {{ event.timeStamp | date: 'h:mm a' }}
    </ion-note>
    <ion-note class="line-clamp-1">
      {{ event.description }}
    </ion-note>
    <ion-note>
      <app-activity-event-details [event]="event"></app-activity-event-details>
    </ion-note>
  </ion-label>
</ion-item>