import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import ProviderConfig from '../../vendors/config/vendor.config';
import { KioskConfig } from '../../vendors/directus/interfaces/kiosk-config.interface';
import { CardTerminalProvider } from '../../providers/card-terminal-provider.interface';
import { StripeProviderService } from '../../vendors/stripe/stripe-provider.service';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../store/state.model';
import { filter, map, take } from 'rxjs/operators';
import { TrancloudProviderService } from '../../vendors/datacap-trancloud/services/trancloud-provider.service';

@Injectable({
  providedIn: 'root',
})
export class CardTerminalService {
  @Select((state: GlobalStateModel) => state.app.kioskConfig) kioskConfig$: Observable<KioskConfig>;

  constructor(private injector: Injector) {}

  getService(): Observable<CardTerminalProvider> {
    return this.kioskConfig$.pipe(take(1)).pipe(
      map(config => {
        return this.chooseService(config?.pin_pad_provider);
      })
    );
  }

  private chooseService(vendor: string): CardTerminalProvider {
    switch (vendor) {
      case ProviderConfig.stripe:
        return this.injector.get(StripeProviderService);
      case ProviderConfig.datacap:
        return this.injector.get(TrancloudProviderService);
      default:
        return null;
    }
  }
}
