import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {PurchaseableReward} from '../../../../interfaces/purchaseable-reward.interface';
import {ModalController} from '@ionic/angular';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {PurchaseReward, SetPurchaseableRewards, SetRewardsBalances} from '../../../../store/actions/user.actions';
import {User} from '../../../../interfaces/user.interface';
import {GlobalStateModel} from '../../../../store/state.model';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';
import {ToastService} from '../../../../services/toast.service';
import {TextField} from '../../../../vendors/directus/interfaces/text-field.interface';


@Component({
  selector: 'app-purchase-rewards-modal',
  templateUrl: './purchase-rewards-modal.component.html',
  styleUrls: ['purchase-rewards-modal.component.scss'],
})
export class PurchaseRewardsModalComponent {

  @Select(state => state.user.purchaseableRewards) purchaseableRewards$: Observable<PurchaseableReward[]>;
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  pointsBalanceCopy = 'Points Balance';
  purchaseRewardsCopy = 'Redeem Rewards';
  displayUser: User;
  purchasingReward = false;
  purchaseRewardsSuccessText = 'Reward Purchased';

  constructor(
    private modalController: ModalController,
    private store: Store,
    private toast: ToastService
  ) {
    this.textFields$.subscribe(textFields => {
      if (textFields) {
        this.purchaseRewardsSuccessText = textFields.purchase_rewards_success_text;
      }
    });
  }

  close(): void {
    this.modalController.dismiss();
  }

  purchaseReward(reward: PurchaseableReward) {
    this.purchasingReward = true;
    this.store.dispatch(new PurchaseReward(reward)).subscribe({
      next: () => {
        this.store.dispatch(new SetPurchaseableRewards());
        this.store.dispatch(new SetRewardsBalances(this.store.selectSnapshot((state: GlobalStateModel) => state.user.user).userID));
        this.purchasingReward = false;
        this.toast.showDismissableIonicToast(this.purchaseRewardsSuccessText, 'de-ionic-success-toast', 8000);
      },
      error: (err) => {
        this.toast.showDismissableIonicToast(err, 'de-ionic-error-toast', 8000);
        this.purchasingReward = false;
      },
      complete: () => this.close()
    });
  }
}
