import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {VendorSetup} from '../../../../../interfaces/vendor.interface';
import {filter} from 'rxjs/operators';
import {NavigationService} from '@modules/navigation/services';

@Component({
  selector: 'app-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent implements OnInit {

  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  @Input() order: Order;
  @Input() user: User;

  @Output() dateTimeSelected = new EventEmitter<Date>();

  providers;

  constructor(
    private router: Router,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    this.vendorSetup$.pipe(filter(vs => !!vs)).subscribe(vs => {
      this.providers = vs;
    });
  }

  locationClicked() {
    this.navigation.navigateToLocationsPage();
  }

  clickDropdown() {
    const root = document.documentElement;
    const arrow = document.getElementById('down-arrow-icon');
    console.log(arrow.offsetLeft.toString());
    // Plus 75 for the offset of the dropdown and minus 9.75 for the arrow icon size
    root.style.setProperty('--drop-down-arrow-left', (arrow.offsetLeft + 75 - 9.75).toString() + 'px');
  }

  routeToMenu() {
    if (this.order && this.order.location && this.order.handoffType) {
      const restaurantID = this.order.location.slugURL ? this.order.location.slugURL : this.order.location.locationID;
      this.navigation.navigateToMenuPage(restaurantID);
    } else {
      this.navigation.navigateToLocationsPage();
    }
  }

  routeToCart() {
    this.navigation.navigateToCartPage(this.order.location.locationID);
  }

  basketCount(products) {
    return products.reduce((total, p) => {
      return p.quantity + total;
    }, 0);
  }

  selectedDateTime(selectedTime: Date) {
    this.dateTimeSelected.emit(selectedTime);
  }
}
