import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../interfaces/address.interface';

@Pipe({
  name: 'streetAddress',
})
export class StreetAddressPipe implements PipeTransform {
  transform(address: Address): string {
    const addressComponents = [];
    if (address.address1) {
      addressComponents.push(address.address1);
    }
    if (address.address2) {
      addressComponents.push(address.address2);
    }
    return addressComponents.join(', ');
  }
}
