import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'getFormControlFromParent',
})
export class GetFormControlFromParentPipe implements PipeTransform {
  transform(name: string, parent: FormGroup): FormControl {
    return parent.get(name) as FormControl;
  }
}
