import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Location as DineEngineLocation } from '../../../../../interfaces/location.interface';
import { GeocodingService } from '../../../services/geocoding.service';
import { Geolocation } from '@capacitor/geolocation';
import { DistanceConfirmationComponent } from '@modules/locations/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../../../services/vendor-config-service/location.service';

@Component({
  selector: 'app-mobile-location-card',
  templateUrl: './mobile-location-card.component.html',
  styleUrls: ['./mobile-location-card.component.scss'],
})
export class MobileLocationCardComponent implements OnInit {
  location: DineEngineLocation;

  isLoading = true;

  @Input() locationID: string;
  @Input() isCurrentLocation = false;
  @Input() displayInfoButton = true;
  @Input() displayOrderButton = true;
  @Input() displayCateringButton = false;
  @Input() pageFrom: string;
  @Input() editHandoff;
  @Input() locationLoading;
  @Input() cardEmphasis: boolean;
  @Input() locationList = true;

  @Output() orderNowButtonClick = new EventEmitter<any>();
  @Output() locationInfoButtonClick = new EventEmitter<any>();
  @Output() hoveredIndex = new EventEmitter<number>();
  @Output() leftCard = new EventEmitter<any>();

  pickupStart: Date;
  pickupEnd: Date;
  deliveryStart: Date;
  deliveryEnd: Date;
  handoffMethods: string;
  distance: number;

  constructor(
    private geo: GeocodingService,
    private modalService: NgbModal,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    if (this.locationID) {
      this.locationService.getService().subscribe(service => {
        if (service) {
          service.getLocation(this.locationID).subscribe((location: DineEngineLocation) => {
            this.location = location;
            this.setTimes();
            this.determineHandoff();
            if (this.pageFrom === 'locations') {
              this.distance = parseInt(sessionStorage.getItem(this.location.locationID), 10);
            }
            this.isLoading = false;
          });
        }
      });
    }
  }

  orderNowButtonClicked(event: any) {
    event.stopPropagation();
    if (this.location.isLive) {
      if (this.distance > 25) {
        const modalRef = this.modalService.open(DistanceConfirmationComponent, { centered: true, modalDialogClass: 'condensed-modal' });
        modalRef.componentInstance.distance = this.distance;
        modalRef.componentInstance.continueEmit.subscribe(() => {
          this.orderNowButtonClick.emit(this.location);
          this.modalService.dismissAll();
        });
      } else {
        this.orderNowButtonClick.emit(this.location);
      }
    }
  }

  locationInfoButtonClicked(event: any) {
    event.stopPropagation();
    if (this.location.supportsPickup || this.location.supportsCurbside) {
      this.locationInfoButtonClick.emit(this.location);
    }
  }

  private setTimes() {
    if (!this.location) {
      return;
    }
    const now = new Date();
    if (this.location.pickupHours) {
      let pickupHours = this.location.pickupHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!pickupHours) {
        pickupHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!pickupHours) {
        this.pickupStart = null;
        this.pickupEnd = null;
      } else {
        this.pickupStart = pickupHours.start;
        this.pickupEnd = pickupHours.end;
      }
    }
    if (this.location.dispatchHours) {
      let deliveryHours = this.location.dispatchHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!deliveryHours) {
        deliveryHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!deliveryHours) {
        this.deliveryStart = null;
        this.deliveryEnd = null;
      } else {
        this.deliveryStart = deliveryHours.start;
        this.deliveryEnd = deliveryHours.end;
      }
    }
    if (this.pageFrom === 'details') {
      Geolocation.getCurrentPosition({ enableHighAccuracy: false, timeout: 10000 })
        .then(pos => {
          this.distance = this.geo.findDistance(
            this.location.address.latitude,
            this.location.address.longitude,
            pos.coords.latitude,
            pos.coords.longitude
          );
          this.distance = this.geo.precisionRound(this.distance, 1);
        })
        .catch(err => {
          console.log('Geolocation Error:', err);
        });
    }
  }

  private doesOverlap(start: Date, end: Date, query: Date): boolean {
    return new Date(start).getTime() <= new Date(query).getTime() && new Date(end).getTime() > new Date(query).getTime();
  }

  private isSameDay(dt1: Date, dt2: Date): boolean {
    return new Date(dt1).getDay() === new Date(dt2).getDay();
  }

  cateringClicked(event: any) {
    event.stopPropagation();
  }

  private determineHandoff() {
    this.handoffMethods = 'Offers ';
    const methods = [];
    if (this.location.supportsPickup) {
      methods.push('Pickup');
    }
    if (this.location.supportsCurbside) {
      methods.push('Curbside');
    }
    if (this.location.supportsDispatch || this.location.supportsDelivery) {
      methods.push('Delivery');
    }
    if (this.location.cateringLink) {
      methods.push('Catering');
    }
    if (methods.length === 1) {
      this.handoffMethods += methods[0];
    } else if (methods.length === 2) {
      this.handoffMethods += methods[0] + ' & ' + methods[1];
    } else if (methods.length >= 3) {
      this.handoffMethods += methods[0] + ', ' + methods[1] + ' & ' + methods[2];
    }
  }

  openNavigation(event: any) {
    event.stopPropagation();
    const preppedAddress = encodeURIComponent(this.location.address.address1.replace(/[ ]+/g, '+'));
    if (window) {
      window.open(
        'https://www.google.com/maps/dir/Current+Location/' +
          preppedAddress +
          '+' +
          this.location.address.state +
          '+' +
          this.location.address.zipCode,
        '_blank',
        'noopener=yes'
      );
    } else {
      document.location.href =
        'https://www.google.com/maps/dir/Current+Location/' +
        preppedAddress +
        '+' +
        this.location.address.state +
        '+' +
        this.location.address.zipCode;
    }
  }

  listCardLeft() {
    this.leftCard.emit();
  }

  cardHovered() {
    this.hoveredIndex.emit();
  }
}
