<main class="d-flex fg-1 flex-column align-self-stretch zi-main position-relative">
  <section class="fg-1 fb-0 flex-row align-items-stretch overflow-auto" #scrollSection>
    <div class="container-fluid d-flex flex-row flex-nowrap align-items-center py-3 kiosk-category-title">
      <h2 class="h2 header-font d-flex justify-content-center align-items-center m-0 fw-bold fg-3 fb-0 py-2 text-center" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        Categories
      </h2>
    </div>

    <section class="container-fluid">
      <ng-container *ngIf="(menu$ | async) as menu; else skeletonCards">
        <div id="changeGrid" class="d-grid-4 ps-0 pe-0 w-100 height-fit-content pb-3">
          <ng-container *ngIf="(user$ | async) as user">
            <ng-container *ngFor="let category of (menu.categories | secretCategories: (user.isGuest ? roles.guest : roles.all)); trackBy: categoryTrackBy">
              <ng-container *ngIf="!category.isHidden && category.products && category.products.length">
                <app-kiosk-category-card
                  [imageURL]="category?.thumbnailImageURL"
                  [description]="category.description"
                  [title]="category.name"
                  (clickedCard)="menuService.setCategory(category)"
                ></app-kiosk-category-card>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </section>
  </section>

  <app-kiosk-scroll-button
    [element]="scrollSection"
  ></app-kiosk-scroll-button>
</main>

<ng-template #skeletonCards>
  <div class="h-100 w-100" style="display: grid; place-content: center">
    <div class="app-loader">
      <svg class="circular m-0 position-relative" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" fill="none" r="20" stroke-miterlimit="10" stroke-width="3" />
      </svg>
    </div>
  </div>
  <!--  <app-kiosk-card *ngFor="let _ of [].constructor(8)" isSkeleton="true"></app-kiosk-card>-->
</ng-template>
