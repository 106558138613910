<ng-container *ngIf="(branding$ | async) as branding">
  <div class="brand-header border-bottom">
    <div class="logo-container mb-2">
      <img alt="Brand Logo" class="brand-logo"
           [src]="branding.loyalty_club_logo ? branding.loyalty_club_logo.data.full_url : branding.main_logo.data.full_url">
    </div>
    <div class="user-info">

      <!--    Show user's name from loyalty/ordering account-->
      <ng-container *ngIf="!(rewardPoints$ | async)?.homeBalance">
        <h5 class="user-name header-font" *ngIf="user$ | async as user">
          Hello {{user.firstName}}
        </h5>
      </ng-container>

      <!--    Show points balance even if they have 0-->
      <!--    Do not show section if loyalty provider is not set-->
      <ng-container *ngIf="rewardPoints$ | async as rewardsBalances">
        <small class="points-balance">
        {{rewardsBalances.points}} {{rewardsBalances.pointsUnit ? rewardsBalances.pointsUnit : 'Points' }}
      </small>
      </ng-container>

    </div>
  </div>
</ng-container>
