import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Product} from '../../../../vendors/directus/interfaces/product.interface';

@Component({
  selector: 'app-featured-items',
  templateUrl: './featured-items.component.html',
  styleUrls: ['./featured-items.component.scss'],
})
export class FeaturedItemsComponent implements OnInit {

  @Input() products: Product[];
  @Input() isMobile: boolean;

  @Output() productSelected = new EventEmitter<Product>();


  constructor() { }

  ngOnInit() {}

  productClicked(product: Product) {
    this.productSelected.emit(product);
  }
}
