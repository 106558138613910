import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { interval, Observable } from 'rxjs';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { SetRouteBack, UpdateTitle } from '../../../../store/actions/app.actions';
import { withLatestFrom } from 'rxjs/operators';
import { MetaService } from '../../../../services/meta.service';
import { NavigationService } from '@modules/navigation/services';
import { NotificationService } from '../../../../services/notification.service';
import { Location } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { Order } from '@dineengine/flybuy-capacitor';
import { User } from '@sentry/browser';
import { SubscriptionComponent } from '@common/components';
import { ModeService } from '../../../../services/mode.service';
import { MobileService } from '../../../../services/mobile.service';
import { Dialog } from '@capacitor/dialog';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-inbox-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inbox-section.component.html',
  styleUrls: ['inbox-section.component.scss'],
})
export class InboxSectionComponent extends SubscriptionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.inboxMessages) inboxMessages$: Observable<InboxMessage[]>;
  // Page details for SEO
  title = 'Messages';
  // Display variables for view
  displayVendorSetup: VendorSetup;

  displayActivities: any[];
  displayOrder: Order;
  displayUser: User;
  isLoading = false;
  locationsRoute = this.navigation.getLocationsPageSlug();
  active = 1;
  capacitor = Capacitor;

  constructor(
    private store: Store,
    private meta: MetaService,
    private navigation: NavigationService,
    public notificationService: NotificationService,
    private _location: Location,
    public modeService: ModeService,
    public mobile: MobileService,
    private alertController: AlertController
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.subs.push(
      interval(1000)
        .pipe(withLatestFrom(this.notificationService.updateNotifications()))
        .subscribe(() => {
        }),
    );
    // this.notificationService.updateNotifications().subscribe(() => {});
  }

  messageTrackByFn(index: number, message: InboxMessage) {
    return `${message.messageID}-${index}-${message.read}`;
  }

  sendLocalNotification() {
    Dialog.confirm({
      title: 'Confirm Test Notifications',
      message: 'This will send two notifications in 5 and 10 seconds. Are you sure?',
      okButtonTitle: 'Yes',
      cancelButtonTitle: 'No',
    }).then((result) => {
      if (result.value) {
        LocalNotifications.requestPermissions().then((pResult) => {
          if (pResult.display === 'granted') {
            LocalNotifications.schedule({
              notifications: [
                {
                  title: ['Title', this.notificationService.notifications.length + 1].join(' '),
                  body: ['Body', this.notificationService.notifications.length + 1].join(' '),
                  id: Math.round(Math.random() * 100000000),
                  schedule: {at: new Date(Date.now() + 1000 * 5)},
                  sound: null,
                  attachments: null,
                },
                {
                  title: ['Title', this.notificationService.notifications.length + 2].join(' '),
                  body: ['Body', this.notificationService.notifications.length + 2].join(' '),
                  id: Math.round(Math.random() * 100000000),
                  schedule: {at: new Date(Date.now() + 1000 * 10)},
                  sound: null,
                  attachments: null,
                },
              ],
            });
          }
        });
      }
    });
  }

  async testAlert() {
    const mockNotification: PushNotificationSchema = {
      id: 'test-notification',
      title: 'Test Notification Title',
      body: 'This is the body of the test notification.',
      data: {},
      // Add any other necessary properties
    };

    const headerText = mockNotification.title ? mockNotification.title : mockNotification.body;
    const truncatedHeader = headerText.length > 50 ? headerText.substring(0, 47) + '...' : headerText;

    const alert = await this.alertController.create({
      header: truncatedHeader,
      message: mockNotification.body,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            console.log('Mock deleteNotification called');
          },
        },
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  }
}
