import { DeleteAccountConfirmationComponent } from './delete-account-confirmation/delete-account-confirmation.component';
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component';
import { LoyaltyHeaderComponent } from './loyalty-header/loyalty-header.component';
import { OrdersSectionComponent } from './orders-section/orders-section.component';
import { RewardsSectionComponent } from './rewards-section/rewards-section.component';
import { GiftBalanceTransferSectionComponent } from './gift-balance-transfer-section/gift-balance-transfer-section.component';
import { OffersSectionComponent } from './offers-section/offers-section.component';
import { LoadPointsSectionComponent } from './load-points-section/load-points-section.component';
import { BankedRewardsSectionComponent } from './banked-rewards-section/banked-rewards-section.component';
import { SavedCardsSectionComponent } from './saved-cards-section/saved-cards-section.component';
import { ActivitySectionComponent } from './activity-section/activity-section.component';
import { InboxSectionComponent } from './inbox-section/inbox-section.component';
import { SavedAddressesSectionComponent } from './saved-addresses-section/saved-addresses-section.component';

export const components = [DeleteAccountConfirmationComponent, WelcomeHeaderComponent, OrdersSectionComponent, RewardsSectionComponent, GiftBalanceTransferSectionComponent, OffersSectionComponent, LoadPointsSectionComponent, BankedRewardsSectionComponent, SavedCardsSectionComponent, ActivitySectionComponent, InboxSectionComponent, SavedAddressesSectionComponent];

export * from './delete-account-confirmation/delete-account-confirmation.component';
export * from './welcome-header/welcome-header.component';
export * from './orders-section/orders-section.component';
export * from './rewards-section/rewards-section.component';
export * from './gift-balance-transfer-section/gift-balance-transfer-section.component';
export * from './offers-section/offers-section.component';
export * from './load-points-section/load-points-section.component';
export * from './banked-rewards-section/banked-rewards-section.component';
export * from './saved-cards-section/saved-cards-section.component';
export * from './activity-section/activity-section.component';
export * from './inbox-section/inbox-section.component';
export * from './saved-addresses-section/saved-addresses-section.component';
