import { Directive, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[appKeyboardHide]'
})
export class KeyboardHideDirective implements OnDestroy {
  private hidden = false;
  private height = 56;
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {
    window.addEventListener('keyboardDidHide', this.show);
    window.addEventListener('keyboardDidShow', this.hide);
  }

  ngOnDestroy() {
    window.removeEventListener('keyboardDidHide', this.show);
    window.removeEventListener('keyboardDidShow', this.hide);
  }

  hide = () => {
    this.height = this.element.nativeElement.offsetHeight;
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
      // this.renderer.setStyle(this.element.nativeElement, 'height', '0px');
      // this.renderer.setStyle(this.element.nativeElement, 'opacity', '0');
      // this.renderer.setStyle(this.element.nativeElement, 'padding', '0');
      // this.renderer.setStyle(
      //   this.element.nativeElement.children[0],
      //   'max-height',
      //   '0px'
      // );
    });

    this.hidden = true;
  }

  show = () => {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'block');
      // this.renderer.setStyle(
      //   this.element.nativeElement,
      //   'height',
      //   this.height + 'px'
      // );
      // this.renderer.removeStyle(this.element.nativeElement, 'opacity');
      // this.renderer.removeStyle(this.element.nativeElement, 'min-height');
      // this.renderer.removeStyle(this.element.nativeElement, 'padding');
      // this.renderer.removeStyle(
      //   this.element.nativeElement.children[0],
      //   'max-height'
      // );
    });

    this.hidden = false;
  }
}
