import { Component, OnInit } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { TextField } from 'src/vendors/directus/interfaces/text-field.interface';
import {Select} from '@ngxs/store';

@Component({
  selector: 'app-nutrition-key',
  templateUrl: './nutrition-key.component.html',
  styleUrls: ['./nutrition-key.component.scss']
})
export class NutritionKeyComponent implements OnInit {

  @Select(state => state.app.textField) textField$: Observable<TextField>

  private subs: Subscription[] = [];

  textFields: TextField;

  constructor(
    // private navBarService: NavBarService
  ) { 

    this.subs.push(this.textField$.subscribe(user => {
      if (user) {
        this.textFields = user;
      }
    }));
  }

  ngOnInit() {
  }

}
