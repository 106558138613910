<ng-container *ngIf="standardCard">
  <ion-card class="no-tap">
    <ng-container *ngIf="image">
      <ion-skeleton-text [animated]="true" class="skeleton-card-image"></ion-skeleton-text>
    </ng-container>
    <ng-container *ngIf="title || subtitle">
      <ion-card-header>
        <ng-container *ngIf="title">
          <ion-card-title>
            <ion-skeleton-text [animated]="true" class="skeleton-title"></ion-skeleton-text>
          </ion-card-title>
        </ng-container>
        <ng-container *ngIf="subtitle">
          <ion-card-subtitle>
            <ion-skeleton-text [animated]="true" class="skeleton-subtitle"></ion-skeleton-text>
          </ion-card-subtitle>
        </ng-container>
      </ion-card-header>
    </ng-container>
    <ng-container *ngIf="description || button">
      <ion-card-content>
        <ng-container *ngIf="description">
          <ion-skeleton-text [animated]="true" class="skeleton-description"></ion-skeleton-text>
          <ion-skeleton-text [animated]="true" class="skeleton-description"></ion-skeleton-text>
          <ion-skeleton-text [animated]="true" class="skeleton-description"></ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="button">
          <ion-skeleton-text [animated]="true" class="skeleton-button ion-margin-top"></ion-skeleton-text>
        </ng-container>
      </ion-card-content>
    </ng-container>
  </ion-card>
</ng-container>

<ng-container *ngIf="rewardCheckout || modifierCard">
  <ion-item>
    <ng-container *ngIf="image">
      <ion-avatar slot="start">
        <ion-skeleton-text class="skeleton-reward-image"></ion-skeleton-text>
      </ion-avatar>
    </ng-container>
    <ng-container *ngIf="title || subtitle">
      <ion-label>
        <ng-container *ngIf="title">
          <ion-skeleton-text [animated]="true" class="skeleton-reward-title"></ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="subtitle">
          <ion-skeleton-text [animated]="true" class="skeleton-reward-exp"></ion-skeleton-text>
        </ng-container>
      </ion-label>
    </ng-container>
    <ng-container *ngIf="button">
      <ion-skeleton-text [animated]="true" class="skeleton-button-small"></ion-skeleton-text>
    </ng-container>
  </ion-item>
</ng-container>

<ng-container *ngIf="paymentCheckout || cartProduct">
  <ion-item>
    <ng-container *ngIf="title || subtitle">
      <ion-label>
        <ng-container *ngIf="title">
          <ion-skeleton-text [animated]="true" class="skeleton-reward-title"></ion-skeleton-text>
        </ng-container>
        <ng-container *ngIf="subtitle">
          <ion-skeleton-text [animated]="true" class="skeleton-reward-exp"></ion-skeleton-text>
        </ng-container>
      </ion-label>
    </ng-container>
  </ion-item>
</ng-container>

<ng-container *ngIf="payment || productImage">
  <ion-card class="no-tap">
    <ng-container *ngIf="image">
      <ion-skeleton-text [animated]="true" class="skeleton-card-image"></ion-skeleton-text>
    </ng-container>
  </ion-card>
</ng-container>


<ng-container *ngIf="menuChip">
  <ion-row class="me-2">
    <ion-col class="ion-align-items-center">
      <ion-skeleton-text class="skeleton-menu-chip-image"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="w-100"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="w-100"></ion-skeleton-text>
    </ion-col>
  </ion-row>
</ng-container>

<ng-container *ngIf="messageNotification">
  <ion-item>
    <ion-skeleton-text [animated]="true" class="skeleton-subtitle"></ion-skeleton-text>
  </ion-item>
</ng-container>

<ng-container *ngIf="orderType">
  <ion-card class="ion-padding">

    <ion-row class="ion-nowrap ion-align-items-center">
      <ng-container *ngIf="image">
        <ion-avatar class="me-2">
          <ion-skeleton-text class="skeleton-reward-image"></ion-skeleton-text>
        </ion-avatar>
      </ng-container>

      <ng-container *ngIf="title || subtitle">
        <ion-col>
          <ng-container *ngIf="title">
            <ion-skeleton-text [animated]="true" class="skeleton-reward-title"></ion-skeleton-text>
          </ng-container>
          <ng-container *ngIf="subtitle">
            <ion-skeleton-text [animated]="true" class="skeleton-reward-exp"></ion-skeleton-text>
          </ng-container>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-card>
</ng-container>

<ng-container *ngIf="productCard">
  <ion-card class="product d-flex flex-nowrap flex-row m-0 mb-2">
    <ng-container *ngIf="image">
      <ion-skeleton-text [animated]="true" class="skeleton-product-image"></ion-skeleton-text>
    </ng-container>
    <ng-container *ngIf="title || subtitle">
      <ion-row class="flex-grow-1" style="flex-basis: 0;">
        <ion-col>
          <ion-card-header>
            <ng-container *ngIf="title">
              <ion-card-title>
                <ion-skeleton-text [animated]="true" class="skeleton-title"></ion-skeleton-text>
              </ion-card-title>
            </ng-container>
            <ng-container *ngIf="subtitle">
              <ion-card-subtitle>
                <ion-skeleton-text [animated]="true" class="skeleton-subtitle"></ion-skeleton-text>
              </ion-card-subtitle>
            </ng-container>
          </ion-card-header>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-card>
</ng-container>
