<ion-card tabindex="0" class="h-100 card-max-height" [ngClass]="{'product-card-padding': isProduct || isCategory, 'option-selected-card' : option.isSelected}" (click)="optionClicked()" (keydown.enter)="optionClicked()">
  <ng-container *ngIf="!isLoading; else skeleton">
    <div class="d-flex flex-row flex-nowrap">
      <div class="p-0 order-2 card-max-height">
          <img class="image" [alt]="option.name"
            [ngClass]="option.isSelected ? 'option-selected': ''" [defaultImage]="'assets/img/600x600.png'"
            [lazyLoad]="option.thumbnailImageURL" />
<!--          <div class="no-image-card" *ngIf="!option?.thumbnailImageURL"-->
<!--            [ngClass]="option.isSelected ? 'option-selected': ''">-->
<!--            <img class="image" src="../../../../assets/img/600x600.png" [alt]="option.name">-->
<!--            <div class="no-image-text">{{ option.name}}</div>-->
<!--          </div>-->
      </div>

      <div class="d-flex flex-column justify-content-center px-3 py-2 order-1 card-max-height flex-grow-1">
        <ng-container *ngIf="option.badge_color && option.badge_text_color && option.badge_text">
          <div class="badge rounded-pill w-fit-content mb-1" [ngStyle]="{'color': option.badge_text_color ? option.badge_text_color : null, 'background-color': option.badge_color ? option.badge_color : null }">
            {{option.badge_text}}
          </div>
        </ng-container>
        <ion-card-header class="p-0">
          <ion-card-title class="mobile-title">
            {{ option.name }}
          </ion-card-title>
        </ion-card-header>

        <ion-row *ngIf="!option?.optionGroups">
          <ion-col class="p-0">
            <ion-row *ngIf="option?.name.includes('Ghost Pepper')">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </ion-row>
            <ion-row *ngIf="option?.name.includes('Korean')">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </ion-row>
            <ion-row *ngIf="(option?.name.includes('Habanero') || option?.name.includes('Nashville'))">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </ion-row>
            <ion-row *ngIf="(option?.name.includes('Thai') || option?.name.includes('Cajun') || option?.name.includes('Blueberry'))">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </ion-row>
            <ion-row *ngIf="option?.name.includes('Teriyaki') || option?.name.includes('Lemon Pepper')">
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Flame</title><path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
            </ion-row>
          </ion-col>
        </ion-row>

      </div>
      <ion-col size="1.5" class="p-0 card-max-height" *ngIf="isProduct">
        <ion-row class="add-row"
            *ngIf="!option.requiresModification"
            [style.height]="option.canModify ? '50%' : '100%'"
            [ngClass]="{'border-bottom': !option.requiresModification}"
            (click)="addItemClick($event)">
          <ion-col class="p-0 text-center my-auto add-button fw-bold">
            <div>ADD</div>
          </ion-col>
        </ion-row>
        <ion-row class="edit-row"
          *ngIf="option.canModify"
          [style.height]="!option.requiresModification ? '50%' : '100%'"
          (click)="editItemClick()">
            <ion-col class="p-0 text-center my-auto edit-column edit-button fw-bold">
                <div>EDIT</div>
              </ion-col>
          </ion-row>
      </ion-col>
    </div>

    <div *ngIf="option.isSelected && option?.optionGroups" class="slider-container">
        <ion-row>
            <ion-col size="auto" class="text-start mt-2">
                <div class="flame-group">
                    <svg *ngFor="let heat of heatList; index as i"
                      [style.color]="i <= selectedItem ? heatColors[selectedItem] : 'grey'"
                      xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                      <title>Flame</title>
                      <path d="M112 320c0-93 124-165 96-272 66 0 192 96 192 272a144 144 0 01-288 0z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                      <path d="M320 368c0 57.71-32 80-64 80s-64-22.29-64-80 40-86 32-128c42 0 96 70.29 96 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                    </svg>
                </div>
              </ion-col>
          <ion-col class="mt-2">
          <div class="selected-heat-text" [style.border-color]="heatColors[selectedItem]">{{heatList[selectedItem]}}</div>
          </ion-col>
          </ion-row>
          <ion-row>
              <ion-col>
                  <ion-row class="px-3">
                    <ion-range min="1" max="5" step="1" value="1" snaps color="dark" (ionChange)="optionGroupSelected($event)">
                    </ion-range>
                  </ion-row>
                  <ion-row class="sauce-levels">
                    <ion-col size="2.4" *ngFor="let heat of heatList">
                      <ion-row class="justify-content-around">
                        <div class="title-name">{{heat}}</div>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-col>
          </ion-row>
        </div>
  </ng-container>

  <ng-template #skeleton>
    <div class="custom-skeleton">
      <ion-skeleton-text animated class="skeleton-menu-card"></ion-skeleton-text>
    </div>
  </ng-template>
</ion-card>
