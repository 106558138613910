<div class="d-flex flex-column h-100" xmlns="http://www.w3.org/1999/html">
  <ng-container *ngIf="product$ | async as product; else loading">
    <ng-container *ngIf="!parentOption?.name; else isOption">
      <div
        class="d-md-flex flex-row flex-nowrap border-bottom top-header-section bg-light">
        <div class="px-3 pt-3 pb-2 d-flex flex-row grow-1">
          <div
            class="product-description d-flex flex-column gap-3 text-start w-100">
            <div
              class="d-none d-md-flex justify-content-left header-font fw-bold font-size-subheading-1">
              {{ product?.name }}
            </div>
            <div class="d-flex d-md-none justify-content-between">
              <div
                class="d-flex justify-content-left header-font fw-bold font-size-subheading-1">
                {{ product?.name }}
              </div>
              <div class="d-flex mb-auto">
                <i
                  class="bi bi-x-circle modal-exit-button"
                  (click)="closeClicked()"></i>
              </div>
            </div>
            <div
              class="text-start font-size-body-small"
              [class.line-clamp]="!expandDescription"
              (click)="expandDescription = !expandDescription">
              {{ product?.longDesc }}
            </div>
          </div>
        </div>

        <div class="d-none d-md-flex mb-auto pt-3 pe-3">
          <i
            class="bi bi-x-circle modal-exit-button"
            (click)="closeClicked()"></i>
        </div>
      </div>
    </ng-container>

    <ng-template #isOption>
      <div
        class="d-flex flex-column px-3 pb-2 gap-3 border-bottom top-header-section bg-light">
        <div class="d-flex flex-row grow-1">
          <div class="product-description d-flex flex-row text-start w-100">
            <div class="d-flex gap-3 justify-content-between">
              <div class="d-flex gap-1 mb-auto">
                <i
                  class="bi bi-arrow-left modal-exit-button"
                  (click)="goToParent()"></i>
              </div>
              <div
                class="d-flex align-items-center justify-content-left font-size-subheading-1"
                style="font-size: 0.75rem">
                Back - {{ product?.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-left header-font fw-bold font-size-subheading-1">
          {{ parentOption?.name }}
        </div>
        <div
          class="text-start font-size-body-small"
          [class.line-clamp]="!expandDescription"
          (click)="expandDescription = !expandDescription">
          {{ product?.longDesc }}
        </div>
      </div>
    </ng-template>

    <div class="scroll-content">
      <ng-container *ngIf="!loadingNewGroup; else loadingGroup">
        <ng-container *ngIf="optionGroups?.length; else topLevelGroup">
          <ng-container
            *ngTemplateOutlet="
              optionGroupSection;
              context: { optionGroups: optionGroups }
            "></ng-container>
        </ng-container>

        <ng-template #topLevelGroup>
          <ng-container
            *ngTemplateOutlet="
              optionGroupSection;
              context: { optionGroups: product.optionGroups }
            "></ng-container>
        </ng-template>
      </ng-container>

      <ng-template #loadingGroup>
        <div
          class="d-flex flex-row ion-align-items-center"
          style="height: 199px">
          <div class="de-btn-loader"></div>
        </div>
      </ng-template>
    </div>

    <div class="m-0 w-100 bottom-submit-bar border-top gap-3">
      <app-modify-footer
        [parentStack]="parentStack"
        [parentOptionGroup]="parentOptionGroup"
        [parentOption]="parentOption"
        [depth]="depth"
        [multiselect]="multiselect"
        [hasSteps]="hasSteps"
        [currentStepIndex]="currentStepIndex"
        [nextStep]="nextStep"
        [quantity]="quantity"
        [canAdd]="canAdd"
        (continueClick)="continueClicked(product)"
        (incrementClick)="incrementClicked()"
        (decrementClick)="decrementClicked()"
        (goToGroup)="siblingStarted($event)"></app-modify-footer>
      <!--      (optionSelected)="addOptions($event)"-->
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div
    class="de-btn-loader-secondary"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "></div>
</ng-template>

<ng-template #optionGroupSection let-optionGroups="optionGroups">
  <div class="d-flex flex-column gap-3 py-2">
    <ng-container *ngFor="let optionGroup of optionGroups; let i = index">
      <div class="option-group-card">
        <div class="option-group-title">
          <div class="option-group-name">
            {{ optionGroup.name }}
          </div>
          <div class="option-group-details pb-3">
            <div class="option-group-status">
              <span
                class="badge rounded-pill d-flex align-items-center gap-1"
                [class.bg-success]="optionForms[i]?.form?.valid"
                [class.bg-warning]="
                  optionForms[i]?.form?.invalid && optionGroup.minRequired > 0
                "
                [class.bg-secondary]="
                  optionForms[i]?.form?.invalid && optionGroup.minRequired === 0
                ">
                <ng-container
                  *ngIf="optionGroup.maxAllowed !== 1; else singleSelect">
                  <ng-container *ngIf="true; else valid">
                    Optional
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      class="bi bi-question-circle"
                      viewBox="0 0 16 16">
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg>
                  </ng-container>
                </ng-container>
                <ng-template #singleSelect>
                  <ng-container
                    *ngIf="
                      optionGroup.minRequired > 0 &&
                        optionForms[i]?.form?.invalid;
                      else valid
                    ">
                    Choices Needed
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      class="bi bi-exclamation-circle"
                      viewBox="0 0 16 16">
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                    </svg>
                  </ng-container>
                </ng-template>
                <ng-template #valid>
                  Completed
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    class="bi bi-check-circle"
                    viewBox="0 0 16 16">
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                </ng-template>
              </span>
            </div>
            <ng-container *ngIf="optionGroup.options.length > 1">
              <div class="option-group-minimum">
                Choose
                {{
                  optionGroup.minRequired === 0
                    ? 'Up To ' +
                      (optionGroup.maxAllowed !== 0
                        ? optionGroup.maxAllowed
                        : optionGroup.options.length)
                    : optionGroup.minRequired
                }}
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container
          *ngIf="
            optionGroup.displayType === 'button-group' &&
              optionSubGroups.length > 0;
            else standardGroup
          ">
          <ng-container *ngFor="let optionSubGroup of optionSubGroups[i]">
            <app-ingredient-card
              style="width: 100%"
              [optionGroup]="optionGroup"
              [optionSubGroup]="optionSubGroup"
              [ingredientFormGroup]="optionForms[i]?.form"
              [depth]="depth"
              (optionClick)="addOrRemoveOptionAndUpdateView($event)"
              (editStarted)="editStarted($event)"
              (selectOptionAndEdit)="
                addOptionAndEdit($event)
              "></app-ingredient-card>
          </ng-container>
        </ng-container>
        <ng-template #standardGroup>
          <ng-container
            *ngIf="optionForms && optionForms.length && optionForms[i].form">
            <ng-container *ngFor="let option of optionGroup.options">
              <app-ingredient-card
                style="width: 100%"
                [option]="option"
                [optionGroup]="optionGroup"
                [ingredientFormGroup]="optionForms[i]?.form"
                [depth]="depth"
                [sliderUpperLimit]="aggregatedGroupLimits[i]"
                (optionClick)="addOrRemoveOptionAndUpdateView($event)"
                (editStarted)="editStarted($event)"
                (selectOptionAndEdit)="addOptionAndEdit($event)"
                (sliderValue)="
                  setNewSliderLimit($event, i, optionGroups)
                "></app-ingredient-card>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>
