import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { User } from 'src/interfaces/user.interface';
import * as Sentry from '@sentry/browser';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {MainSettings} from '../../../../../vendors/directus/interfaces/main-settings.interface';
import {NavigationService} from '@modules/navigation/services';
const { version } = require('../../../../../../package.json');

@Component({
  selector: 'app-desktop-account-subnav',
  templateUrl: './account-subnav.component.html',
  styleUrls: ['./account-subnav.component.scss'],
  styles: [`
  .active {
    background-color: var(--ion-color-primary) !important;
    color: var(--ion-color-primary-txt) !important;
  }`]
})
export class DesktopAccountSubnavComponent implements OnInit {

  mainSettings$: Observable<MainSettings>;

  @Input() vendorSetup: VendorSetup;
  @Input() displayUser: User;
  // @Input() subnavItems: any;

  subnavItems;

  version = version;

  providers;
  profileRoute = this.navigation.getProfilePageSlug();
  editProfileRoute = this.navigation.getEditProfilePageSlug();
  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  savedCardsRoute = this.navigation.getSavedCardsPageSlug();
  rewardsRoute = this.navigation.getRewardsPageSlug();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private directus: DirectusService,
    private store: Store,
    private navigation: NavigationService
  ) {
    this.mainSettings$ = this.store.select(state => state.app.mainSettings);
  }

  ngOnInit() {
    this.directus.getAccountSubnav().subscribe(navItems => {
      this.subnavItems = navItems;
    });
  }

  smoothScroll(elementId: string) {
    document.querySelector(elementId).scrollIntoView({
      behavior: 'smooth'
    });
  }

  /**
   * If a given link is to a loyalty page, hide the link if there is no loyalty provider selected.
   * @param link - Link to check. /rewards and /inbox will be hidden.
   */
  hideLinks(link: string): boolean {
    let toShow = true;
    if (this.vendorSetup.loyalty_provider === 'none' && (link === '/rewards' || link === '/inbox')) {
      toShow = false;
    }
    if (this.vendorSetup.order_history_provider === 'none' && (link === '/saved-cards' || link === '/orders')) {
      toShow = false;
    }
    if (this.vendorSetup.curbside_vehicle_provider === 'none' && (link === '/saved-vehicles')) {
      toShow = false;
    }
    return toShow;
  }

  navigate(url) {
    this.router.navigate([url])
      .catch(() => {
        this.document.location.href = url;
      });
    return false;
  }

  openFeedbackForm() {
    Sentry.showReportDialog({eventId: Sentry.captureMessage('User Submission')});
  }
}
