import { Injectable, Injector } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { VendorSetup } from '../../interfaces/vendor.interface';
import { MessagingProvider } from '../../providers/messaging-provider.interface';
import { filter, map, take } from 'rxjs/operators';
import ProviderConfig from '../../vendors/config/vendor.config';
import { PaytronixProviderService } from '../../vendors/paytronix/paytronix-provider.service';
import { OnesignalProviderService } from '../../vendors/onesignal/onesignal-provider.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  private currentService: MessagingProvider;

  constructor(
    private injector: Injector
  ) { }

  getService(): Observable<MessagingProvider> {
    if (this.currentService) {
      return of(this.currentService);
    } else {
      return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
        return this.chooseService(vs.messaging_provider);
      }));
    }
  }

  private chooseService(vendor: string): MessagingProvider {
    switch (vendor) {
      case ProviderConfig.paytronix: return this.currentService = this.injector.get(PaytronixProviderService);
      case ProviderConfig.onesignal: return this.currentService = this.injector.get(OnesignalProviderService);
    }
  }
}
