import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { OLOMappingService } from 'src/vendors/olo/olo-mapping.service';
import moment from 'moment-timezone';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { ThemeColor } from '../../../../vendors/directus/interfaces/theme-color.interface';

@Component({
  selector: 'app-credit-card-card',
  templateUrl: './credit-card-card.component.html',
  styleUrls: ['./credit-card-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in')),
    ]),
  ],
})
export class CreditCardCardComponent implements OnInit {
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;

  today;
  isExpired = false;
  now = moment();

  @Input() card: SavedCard;
  @Input() isLoyalty = false;
  @Input() storedValueCents = 0;
  @Input() displayMode: 'classic' | 'improved' = 'classic';
  @Input() index: number;

  @Output() clickedDeleteCard = new EventEmitter<any>();
  @Output() clickedDefaultCard = new EventEmitter<any>();

  ngOnInit() {
    this.isExpired = this.card?.cardExpiration ? this.now.isAfter(moment(this.card.cardExpiration), 'month') : false;
  }

  deleteCardClicked() {
    this.clickedDeleteCard.emit();
  }

  defaultCardClicked() {
    this.clickedDefaultCard.emit();
  }

  expiredCard() {
    // let isExpired = false;
    return moment().isAfter(moment(this.card.cardExpiration), 'day');
    // this.today = moment();
    // let date = this.today.toString();
    // if (this.card.cardExpiration < date) {
    //   isExpired = true;
    // }
    // return isExpired;
  }
}
