import { Pipe, PipeTransform } from '@angular/core';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';

@Pipe({
  name: 'savedDeliveryAddressFilter',
})
export class SavedDeliveryAddressFilterPipe implements PipeTransform {
  transform(addresses: ISavedAddress[]): ISavedAddress[] {
    return addresses.filter(address => address.orderType === 'delivery');
  }
}
