import {ICoordinates} from '@modules/locations/models/coordinates.interface';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { Location} from 'src/interfaces/location.interface';
import {Address} from '../../interfaces/address.interface';
import {AddressSearch} from '@modules/locations/models/locations.address-search';

export class SetLocation {
  static readonly type = '[Location] Set Location';

  constructor(public location: Location) {}
}

export class SetLocationByID {
  static readonly type = '[Location] Set Location by ID';

  constructor(public locationID: string) {}
}

export class SetLocationBySlug {
  static readonly type = '[Location] Set Location by Slug';

  constructor(public locationSlug: string) {}
}

export class SetDeliveryAddress {
  static readonly type = '[Location] Set Delivery Address';

  constructor(public address: Address) {}
}

export class SetPickupLocations {
  static readonly type = '[Location] Set Pickup Locations';

  constructor(public coordinates: ICoordinates) {}
}

export class SetDeliveryLocations {
  static readonly type = '[Location] Set Delivery Locations';

  constructor(public coordinates: ICoordinates, public milesRadius: number, public address: Address) {}
}

export class SetStaticLocations {
  static readonly type = '[Location] Set Static Locations';

  constructor() {}
}

export class SetUserCoordinates {
  static readonly type = '[Location] Set User Location';

  constructor(public coordinates: ICoordinates) {}
}

export class SetUserAddress {
  static readonly type = '[Location] Set User Location';

  constructor(public address: Address) {}
}

export class SetUserSavedAddress {
  static readonly type = '[Location] Set User Saved Address';

  constructor(public savedAddress: ISavedAddress[]) {}
}

export class SetAllPickupLocations {
  static readonly type = '[Location] Set All Pickup Locations';
  constructor() {}
}

export class SetAddressSearch {
  static readonly type = '[Location] Set Address Search';
  constructor(public addressSearch: AddressSearch) {}
}
