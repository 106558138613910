import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProxyBaseURLService } from 'src/services/proxy-base-url.service';
import moment from 'moment-timezone';
import { TokenResponse } from './interfaces/token-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ItwercsHttpService {
  private vendorName = 'itwercs';

  private headers;

  constructor(
    private http: HttpClient,
    private urlService: ProxyBaseURLService
  ) {
    let token = sessionStorage.getItem('ItwercsSessionToken');
    let expireDate = sessionStorage.getItem('ItwercsSessionExpire');
    const today = moment();
    if (token && expireDate) {
      if (moment(expireDate).isBefore(today)) {
        this.get<TokenResponse>('/token').subscribe(res => {
          token = res.access_token;
          expireDate = res['.expires'];
          sessionStorage.setItem('ItwercsSessionToken', token);
          sessionStorage.setItem('ItwercsSessionExpire', expireDate);
          this.headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          });
        });
      } else {
        this.headers = new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        });
      }
    } else {
      this.get<TokenResponse>('/token').subscribe(res => {
        token = res.access_token;
        expireDate = res['.expires'];
        sessionStorage.setItem('ItwercsSessionToken', token);
        sessionStorage.setItem('ItwercsSessionExpire', expireDate);
        this.headers = new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        });
      });
    }
    setTimeout(() => {}, 2000); // wait for token response before starting other calls
  }

  get<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.http.get<T>(baseURL + resource, { headers: this.headers }).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.http.put<T>(baseURL + resource, body, { headers: this.headers }).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.http.post<T>(baseURL + resource, body, { headers: this.headers }).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.http.patch<T>(baseURL + resource, body, { headers: this.headers }).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  delete<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.http.delete<T>(baseURL + resource, { headers: this.headers }).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }
}
