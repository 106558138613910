import { Pipe, PipeTransform } from '@angular/core';
import { LoyaltyLocation } from '../interfaces/location.interface';

@Pipe({
  name: 'favoriteLocationPicker',
})
export class FavoriteLocationPickerPipe implements PipeTransform {
  transform(locations: LoyaltyLocation[]): any {
    if (!locations) return [];
    return {
      name: 'location',
      options: locations.map(location => {
        return {
          text: location.name,
          value: location,
        };
      }),
    };
  }
}
