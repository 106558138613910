<div class="m-0 w-100 px-3 pt-3 pb-1 selected-mods-outer">
  <div class="options-title">
    <div class="added-mod-text">{{ productName }} - Options</div>
  </div>
  <div class="scroll-arrows">
    <i
      class="bi bi-arrow-left-circle"
      style="font-size: 32px; cursor: pointer"
      (click)="scroll('left')"></i>
    <i
      class="bi bi-arrow-right-circle"
      style="font-size: 32px; cursor: pointer"
      (click)="scroll('right')"></i>
  </div>
</div>
<div class="scroll-section" id="mod-scroll-section">
  <ng-container *ngFor="let optionGroup of selectedOptions; let last = last">
    <div [class.me-3]="last" class="modifier-pill">
      <span class="mod-text">{{ optionGroup.name }}</span>
    </div>
  </ng-container>
</div>
