import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductConfigs } from '../../../../interfaces/product.interface';
import { Category } from '../../../../interfaces/category.interface';
import { MenuService } from '@modules/menu/services';
import { MobileService } from '../../../../services/mobile.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { ModeService } from '../../../../services/mode.service';
import { UpsellProduct } from '../../../../interfaces/upsell-product.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select(state => state.app.product) product$: Observable<Product>;

  @Input() productID: string;
  @Input() set productDetails(val: Product) {
    if (this.cardLoading) {
      this.product = val;
      this.cardLoading = false;
    }
  }
  @Input() category: Category;
  @Input() modifyModalRef: TemplateRef<any>;
  @Input() isListCard: boolean;
  @Input() upsellProduct: Product | UpsellProduct;
  @Input() productLoading: boolean;
  @Input() currencyCode: string;
  @Input() allergenList: string[];
  @Input() isUpsell = false;

  @Output() modifyClicked = new EventEmitter<any>();
  @Output() addToOrderClicked = new EventEmitter<any>();
  @Output() infoClicked = new EventEmitter<any>();

  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;

  product: Product = null;
  cardLoading = false;
  customizeLoading = false;
  addLoading = false;
  addConfiguredLoading = [false, false];
  constructor(
    public menuService: MenuService,
    public mobile: MobileService,
    public mode: ModeService,
    public modalService: NgbModal
  ) {
    this.cardLoading = true;
  }
  ngOnInit() {
    if (!this.product) {
      if (this.category) {
        this.menuService.getProduct(this.category.categoryID, this.productID).subscribe((product: Product) => {
          this.product = product;
          this.cardLoading = false;
        });
      } else {
        this.menuService.getProductWithoutCategory(this.productID).subscribe((product: Product) => {
          this.product = product;
          this.cardLoading = false;
        });
      }
    }
  }
  addToCartClicked() {
    this.addLoading = true;
    this.menuService.addProductToCart(this.product).subscribe(() => {
      this.addLoading = false;
    });
  }
  addConfiguredClicked(optionIDs: string[], index: number) {
    this.addConfiguredLoading[index] = true;
    const configuredProduct = this.menuService.findConfiguredOptions(this.product, optionIDs);
    this.menuService.addConfiguredProductToCart(configuredProduct).subscribe(() => {
      this.addConfiguredLoading[index] = false;
    });
  }
  customizeClicked() {
    this.customizeLoading = true;
    this.menuService.customizeProduct(this.product, this.category, this.modifyModalRef);
    this.customizeLoading = false;
  }

  cardClicked() {
    if (this.mode.mode !== 'tableside' && this.mode.mode !== 'kiosk') {
    }
    if (this.product?.canModify) {
      this.customizeClicked();
    } else {
      this.addToCartClicked();
    }
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal',
    });
  }
}
