<ng-container *ngIf="mainSettings$ | async as mainSettings">
  <div
    [ngClass]="modeService.mode === 'mobile' ? 'dashboard-container-mobile' : modeService.mode === 'desktop' ? 'dashboard-container-desktop' : 'dashboard-container-improved'">
    <ng-container *ngIf="modeService.mode === 'mobile' && mainSettings.mobile_app_layout === 'classic'">
      <app-fab
        *ngIf="authService.vendorSetup?.loyalty_provider !== 'none' && mainSettings.show_loyalty_checkin"
        [dOrder]="displayOrder"
        [dUser]="displayUser"
        [isCheckin]="authService.isCheckin"
      ></app-fab>
    </ng-container>
    <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
      <ng-container *ngIf="modeService.mode === 'desktop'">
        <div class="mb-5"
             [class.mt-3]="navbarSettings.fixed_navbar_width && modeService.mode !== 'kiosk'"
             [class.container]="navbarSettings.fixed_navbar_width && modeService.mode !== 'kiosk'">
          <app-loyalty-header></app-loyalty-header>
        </div>
      </ng-container>
    </ng-container>

    <div class="w-100" [ngStyle]="mobile.isMobile ? {'overflow-x': 'hidden'} : null">
      <router-outlet></router-outlet>
    </div>

    <ng-container *ngIf="modeService.mode === 'mobile' && mainSettings.mobile_app_layout === 'classic'">
      <div class="w-100 p-3 bg-light border-top d-flex flex-row flex-nowrap gap-2"
           style="position: sticky; bottom: 0; z-index: 1000;">
        <!--        <app-fab-->
        <!--          *ngIf="authService.vendorSetup?.loyalty_provider !== 'none' && mainSettings.show_loyalty_checkin"-->
        <!--          [dOrder]="displayOrder"-->
        <!--          [dUser]="displayUser"-->
        <!--          [isCheckin]="authService.isCheckin"-->
        <!--          [isFab]="false"></app-fab>-->
        <app-order-now-button class="w-100"></app-order-now-button>
      </div>
    </ng-container>

    <ng-container *ngIf="modeService.mode === 'desktop'">
      <app-footer *ngIf="showFooter$ | async"></app-footer>
    </ng-container>
  </div>
</ng-container>


