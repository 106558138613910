import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModeService } from '../../../../services/mode.service';
import { GlobalStateModel } from '../../../../store/state.model';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Category } from '../../../../interfaces/category.interface';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent {
  @Select((state: GlobalStateModel) => state.menu.selectedCategory) category$: Observable<Category>;

  @Input() title: string;
  @Input() description: string;
  @Input() isUpsellPage = false;
  @Input() isLoading: boolean;
  @Output() continueClick = new EventEmitter<any>();

  constructor(protected mode: ModeService) {}

  continueClicked() {
    this.continueClick.emit();
  }
}
