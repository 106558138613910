<div class="d-flex flex-row flex-nowrap align-items-center">
  <ng-container *ngIf="balances.pointsThreshold">
    <app-points-progress-dial [points]="balances.points"
                              [pointsThreshold]="balances.pointsThreshold"
                              [size]="100"></app-points-progress-dial>
  </ng-container>
  <div [class.ps-3]="balances.pointsThreshold">
    <small>You have</small>
    <h1 class="fw-bold mb-1" [class.display-2]="!balances.pointsThreshold">{{ balances.points }}
      <span [class.h1]="!balances.pointsThreshold" class="fw-bold">{{ balances.pointsUnit }}</span></h1>
    <ng-container *ngIf="balances.pointsThreshold">
      <p>Next reward unlocked at <span
        class="fw-bold">{{ balances.pointsThreshold }} {{ balances.pointsUnit }}</span></p>
    </ng-container>
  </div>
</div>