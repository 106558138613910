import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '../interfaces/option.interface';
import { OptionGroup } from '../interfaces/option-group.interface';

@Pipe({
  name: 'maxChoiceQuantity',
  pure: false,
})
export class MaxChoiceQuantityPipe implements PipeTransform {
  transform(option: Option, optionGroup: OptionGroup, selectedOptions: Option[]): number {
    const maxAllowed = optionGroup.maxTotalQuantity;
    // get all options that are selected from the same option group
    const selectedOptionsFromSameGroup = selectedOptions.filter(
      selectedOption => optionGroup.options.find(option => option.optionID === selectedOption.optionID) !== undefined
    );
    // get quantity of all selected options from the same option group
    const selectedOptionsQuantity = selectedOptionsFromSameGroup.reduce((acc, selectedOption) => acc + selectedOption.quantity, 0);
    // if option is already selected, subtract its quantity from the total
    const quantity = option.quantity > 0 ? option.quantity : 0;
    const totalQuantity = selectedOptionsQuantity - quantity;
    // return the max allowed minus the total quantity
    return maxAllowed - totalQuantity < optionGroup.maxTotalQuantity ? maxAllowed - totalQuantity : optionGroup.maxTotalQuantity;
  }
}
