<div class="w-100 reward-container de-border my-2" *ngIf="!insideModal else updatedOfferCard">
    <div class="reward-content">
        <div class="fw-bold">{{offer.name}}</div>
        <div style="color: var(--ion-color-primary); text-decoration: underline" class="fw-bold"
             (click)="viewOffer()">View
        </div>
        <i class="bi bi-search font-size-subheading-1 color-primary" (click)="viewOffer()"></i>
    </div>
</div>

<ng-template #updatedOfferCard>
    <ng-container *ngIf="offer else emptyOffer">
        <div class="offer-card h-100">
            <div class="d-flex flex-column justify-content-center align-items-center p-3 offer-card-top">
                <div class="text-uppercase font-size-body-regular">
                    <ng-container *ngIf="offer.redeemInStore">In Store</ng-container>
                    <ng-container *ngIf="offer.redeemInStore && offer.redeemOnline">&nbsp;&amp;&nbsp;</ng-container>
                    <ng-container *ngIf="offer.redeemOnline">Online</ng-container>
                </div>
                <h3 class="header-font fw-bold m-0">{{offer.name}}</h3>
                <div *ngIf="offer.description" class="font-size-body-regular">{{offer.description}}</div>
            </div>
            <div class="position-relative h-100" style="min-height: 150px;">
                <div class="offer-bottom h-100" [@fadeInOnEnter] [@fadeOutOnLeave] *ngIf="!showingBarcode">
                    <div *ngIf="offer.expireDate">Valid Thru: {{offer.expireDate | date: 'shortDate'}}</div>
                    <small>Redemption Code: {{offer.couponCode}}</small>
                    <div class="d-flex flex-row flex-nowrap justify-content-between gap-2 w-100">
                        <ng-container *ngIf="offer.redeemInStore">
                            <button class="de-secondary-btn-outline-sm flex-grow-1 basis-0 font-size-body-small d-flex align-items-center justify-content-center"
                                    style="height: 2rem" (click)="toggleBarcode()">Barcode
                            </button>
                        </ng-container>
                        <ng-container *ngIf="offer.redeemOnline">
                            <button class="de-primary-btn-sm flex-grow-1 basis-0 font-size-body-small d-flex align-items-center justify-content-center"
                                    style="height: 2rem" (click)="copyToClipboard(offer.couponCode)">Copy
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="showingBarcode"
                     class="d-flex flex-column flex-nowrap gap-2 align-items-center px-3 w-100 justify-content-center"
                     style="height: 150px; top: 0; position:absolute;" [@fadeInOnEnter] [@fadeOutOnLeave]>
                    <ngx-barcode [bc-display-value]="true" [bc-value]="offer.couponCode" [bc-width]="1.5" [bc-height]="42"
                                 bc-element-type="svg"
                                 bc-background="rgba(0,0,0,0)"></ngx-barcode>
                    <button class="dark-link" (click)="toggleBarcode()">Go Back</button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #emptyOffer>

    <div class="offer-card-empty text-muted">
        <small class="text-center">
            Keep checking back here for your new offers
        </small>
        <div class="d-flex flex-column align-items-center justify-content-center h-100" style="gap: 0.5rem;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.63 76.88" nighteye="disabled" height="48" width="48"
                 fill="currentColor">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path d="M24.63,68.88v8h-8a8,8,0,0,1-8-8v-8h8v8Z"/>
                        <rect x="48.63" y="20.88" width="8" height="8"/>
                        <path d="M80.63,60.88v8a8,8,0,0,1-8,8h-8v-8h8v-8Z"/>
                        <rect x="72.63" y="44.88" width="8" height="8"/>
                        <path d="M80.63,28.88v8h-8v-8h-8v-8h8A8,8,0,0,1,80.63,28.88Z"/>
                        <rect x="48.63" y="68.88" width="8" height="8"/>
                        <rect x="32.63" y="68.88" width="8" height="8"/>
                        <path d="M48.9,4.88h-8l-16,16-4.28-4.28a11,11,0,1,0-4,4l4.28,4.28-4.28,4.26A11.17,11.17,0,0,0,8.48,28,10.93,10.93,0,0,0,.31,36.05,11.06,11.06,0,1,0,20.6,33.18l4.29-4.28,16,16h8l-20-20ZM11.1,14.75a4,4,0,1,1,4-4A4,4,0,0,1,11.1,14.75Zm0,27.55a4,4,0,1,1,4-4A4,4,0,0,1,11.1,42.3ZM24.81,25.38a1,1,0,1,1,1-1A1,1,0,0,1,24.81,25.38Z"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>

</ng-template>

<ng-template #offerModal let-modal>
    <div id="close" class="d-flex w-100 align-items-center justify-content-end" (click)="modal.dismiss()">
        <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
    <div class="w-100" style="display: grid; place-items: center; grid-gap: 16px;">
        <img src="../../../../assets/icons/coupon.svg" alt="coupon image" style="height: 96px; width: auto;">
        <div class="fw-bold text-center" style="font-size: 24px">{{offer.name | uppercase}}</div>
        <small class="d-block">Coupon Code:</small>
        <div class="header-font fw-bold"
             style="font-size: 32px; line-height: 32px;">{{offer.couponCode | uppercase}}</div>
        <ng-container *ngIf="offer.description">
            <div>{{offer.description}}</div>
        </ng-container>
        <ng-container *ngIf="offer.expireDate">
            <small>Expires:</small>
            <div>{{offer.expireDate | date: 'M/d/yyyy'}}</div>
        </ng-container>
        <div>Available In-Store
            <ng-container *ngIf="!offer.redeemOnline"> Only</ng-container>
            <ng-container *ngIf="offer.redeemInStore && offer.redeemOnline"> and</ng-container>
            Online
            <ng-container *ngIf="!offer.redeemInStore"> Only</ng-container>
        </div>
    </div>
</ng-template>
