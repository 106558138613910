<ng-container *ngIf="vendorSetup">
  <div class="w-100 d-flex flex-row account-subnav-shell">
    <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
      <div style="display: grid; place-items: center; padding: 10px;" class="w-100 h-100" [routerLink]="profileRoute"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" id="dashboardTab">
        <i class="bi bi-grid"></i>
        <div class="ensurePointerCursor fw-bold" style="font-size: 10px">Dashboard</div>
      </div>
    </ng-container>
    <div style="display: grid; place-items: center; padding: 10px;" class="w-100 h-100" [routerLink]="editProfileRoute"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" id="profileTab">
      <i class="bi bi-person"></i>
      <div class="ensurePointerCursor fw-bold" style="font-size: 10px">Profile</div>
    </div>
    <ng-container *ngIf="vendorSetup.order_history_provider !== 'none'">
      <div style="display: grid; place-items: center; padding: 10px; grid-gap: 5px" class="w-100 h-100"
        [routerLink]="previousOrdersRoute" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        id="ordersTab">
        <i class="bi bi-receipt-cutoff"></i>
        <div class="ensurePointerCursor fw-bold" style="font-size: 10px">Orders</div>
      </div>
      <div style="display: grid; place-items: center; padding: 10px; grid-gap: 5px" class="w-100 h-100"
        [routerLink]="savedCardsRoute" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        id="paymentsTab">
        <i class="bi bi-credit-card"></i>
        <div class="ensurePointerCursor fw-bold" style="font-size: 10px">Payments</div>
      </div>
    </ng-container>
    <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
      <div style="display: grid; place-items: center; padding: 10px" class="w-100 h-100" *ngIf="hideLinks('/rewards')"
        routerLinkActive="active" [routerLink]="rewardsRoute" id="rewardsTab">
        <i class="bi bi-award"></i>
        <div class="ensurePointerCursor fw-bold" style="font-size: 10px">Rewards</div>
      </div>
    </ng-container>
  </div>
</ng-container>