<div class="d-flex flex-row flex-nowrap justify-content-between gap-3 w-100 mb-3 px-3 px-lg-0">
  <h4 class="text-start fw-bold header-font mb-0 d-flex align-items-center">
    <app-custom-icon
      class="color-primary me-2"
      default="bi-envelope"
      customIconKey="inbox_navigation_icon"
      altText="Inbox"
    ></app-custom-icon>
    Inbox
  </h4>
</div>
<ng-container *ngIf="modeService.mode === 'desktop'">
  <ng-container *ngIf="inboxMessages$ | async as inboxMessages else emptyInbox">
    <ng-container *ngIf="inboxMessages.length else emptyInbox">
      <ion-list lines="none">
        <ng-container *ngFor="let message of inboxMessages; trackBy: messageTrackByFn">
          <app-inbox-card [message]="message"></app-inbox-card>
        </ng-container>
      </ion-list>
    </ng-container>
  </ng-container>
</ng-container>

<!--  <ng-container *ngIf="capacitor.getPlatform() !== 'web'">-->
<!--        <button (click)="sendLocalNotification()">Send Test Local Notifications</button>-->
<!--  </ng-container>-->

<ng-container *ngIf="modeService.mode === 'mobile' || modeService.mode === 'tableside'">
  <ul ngbNav #nav="ngbNav" class="nav-tabs px-3">
    <ng-container *ngIf="capacitor.getPlatform() !== 'web'">
      <li ngbNavItem="notifications">
        <a ngbNavLink>
          <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
            <h6 class="header-font fw-bold mb-0">Notifications</h6>
            <div
              class="badge text-bg-danger rounded-pill p-1 d-flex align-items-center justify-content-center"
              *ngIf="notificationService.getUnreadNotificationCount() as unreadMessages">
              {{ unreadMessages }}
            </div>
          </div>
        </a>
        <ng-template ngbNavContent>
          <ng-container
            *ngIf="notificationService.notifications; else emptyInbox">
            <ng-container
              *ngIf="notificationService.notifications.length; else emptyInbox">
              <ion-list lines="none">
                <ng-container
                  *ngFor="
                    let notification of notificationService.notifications$
                      | async
                  ">
                  <app-inbox-card class="mb-3" [notification]="notification">
                  </app-inbox-card>
                </ng-container>
              </ion-list>
            </ng-container>
          </ng-container>
        </ng-template>
      </li>
    </ng-container>
    <li ngbNavItem="messages">
      <a ngbNavLink>
        <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
          <h6 class="fw-bold header-font mb-0">Messages</h6>
          <ng-container
            *ngIf="inboxMessages$ | async | unreadMessage as unreadMessages">
            <div
              class="badge text-bg-danger rounded-pill p-1 d-flex align-items-center justify-content-center">
              {{unreadMessages}}
            </div>
          </ng-container>
        </div>
      </a>
      <ng-template ngbNavContent>
        <ng-container
          *ngIf="inboxMessages$ | async as inboxMessages; else emptyInbox">
          <ng-container *ngIf="inboxMessages?.length; else emptyInbox">
            <ion-list lines="none">
              <ng-container *ngFor="let message of inboxMessages; trackBy: messageTrackByFn">
                <app-inbox-card [message]="message"></app-inbox-card>
              </ng-container>
            </ion-list>
          </ng-container>
        </ng-container>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>

<ng-template #emptyInbox>
    <div
      class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
      <app-custom-icon
        class="color-primary display-3"
        default="bi-mailbox"
        altText="Saved Payment Card"
      ></app-custom-icon>
      <div class="text-center d-flex flex-column">
        <h5 class="fw-bold">Your inbox is empty.</h5>
        <div>Check back for new menu items, offers, and promotions.</div>
      </div>
    </div>
</ng-template>

