<div class="d-flex flex-column flex-nowrap gap-3 justify-content-between align-items-start">
  <h5 class="m-0 fw-bold">Add a Donation</h5>
  {{ this.order.donationType[0].description }}
  <div class="grow-1 basis-0 align-items-center">
    <form [formGroup]="donationForm">
      <div class="d-flex flex-column" [class.gap-3]="donationOptIn.value">
        <div class="form-check grow-1 basis-0 align-items-center">
          <input (change)="addDonationTypeToCart()" type="checkbox" class="form-check-input" id="donationOptIn" formControlName="donationOptIn">
          <label class="form-check-label pointer font-size-body-small"
                 [ngStyle]="{'color': theme$[0]?.user_onboarding_text_color}" for="donationOptIn">
            {{ this.order.donationType[0].note }}
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
