import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-guest-count',
  templateUrl: './guest-count.component.html',
  styleUrls: ['./guest-count.component.scss']
})
export class GuestCountComponent {
  @Input() error;

  constructor(public activeModal: NgbActiveModal) { }

}
