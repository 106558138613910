import {Injectable, Injector} from '@angular/core';
import { PaymentProcessingProvider } from '../../providers/payment-processing-provider.interface';
import { CmsVendorSetupService } from './cms-vendor-setup.service';
import {Observable, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import ProviderConfig from '../../vendors/config/vendor.config';
import {DatacapProviderService} from '../../vendors/datacap/datacap-provider.service';
import {Select} from '@ngxs/store';
import {VendorSetup} from '../../interfaces/vendor.interface';
import {OloPayProviderService} from '../../vendors/olo-pay/olo-pay-provider.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentProcessingService {

    @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

    private currentService: PaymentProcessingProvider;

    constructor(
        private vendorService: CmsVendorSetupService,
        private injector: Injector
    ) { }

    getService(): Observable<PaymentProcessingProvider> {
        if (this.currentService) {
            return of(this.currentService);
        } else {
            return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
                return this.chooseService(vs.payment_processing_provider);
            }));
            // return this.vendorService.getService().pipe(switchMap(service => {
            //     return service.getVendorSetup().pipe(map(setup => {
            //         return this.chooseService(setup.payment_processing_provider);
            //     }));
            // }));
        }
    }

    private chooseService(vendor: string): PaymentProcessingProvider {
        switch (vendor) {
            case ProviderConfig.datacap: return this.currentService = this.injector.get(DatacapProviderService);
            case ProviderConfig.oloPay: return this.currentService = this.injector.get(OloPayProviderService);
            default: return null;
        }
    }
}
