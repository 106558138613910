import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'src/services/vendor-config-service/user.service';
import { Observable, of } from 'rxjs';
import {switchMap, map, catchError, filter, take} from 'rxjs/operators';
import { DirectusService } from 'src/vendors/directus/directus.service';
import {Store} from '@ngxs/store';
import {MainSettings} from '../vendors/directus/interfaces/main-settings.interface';
import {User} from '../interfaces/user.interface';
import {NavigationService} from '@modules/navigation/services';

@Injectable({
  providedIn: 'root'
})
export class ForceLoginGuard  {

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private userService: UserService,
    private directus: DirectusService,
    private store: Store
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // tslint:disable-next-line:max-line-length
    return this.store.select(state => state.app.mainSettings).pipe(filter(ms => ms !== null), take(1), switchMap((mainSettings: MainSettings) => {
      return this.store.select(state => state.user.user).pipe(filter(u => u !== null), take(1), map((user: User) => {
        if (mainSettings.force_login) {
          if (user.isGuest) {
            this.navigation.navigateToLoginPage();
            return false;
          }
        }
        return true;
      }), catchError(() => {
        if (mainSettings.force_login) {
          this.navigation.navigateToLoginPage();
        }
        return of(!mainSettings.force_login);
      }));
    }));
  }
}
