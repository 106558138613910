import { Injectable } from '@angular/core';
import { CustomerTrackingProvider } from '../../providers/customer-tracking-provider.interface';
import { from, Observable, of } from 'rxjs';
import { Order } from '../../interfaces/order.interface';
import { CustomerTrackingState } from '../../interfaces/customer-tracking-state.enum';
import { RadarMappingService } from './radar-mapping.service';
import { DECustomerLocationUpdate } from '../../interfaces/customer-location-update.interface';
import { OrderService } from '../../services/vendor-config-service/order.service';
import { Capacitor } from '@capacitor/core';
import { CardDetails } from '../../interfaces/card-details.interface';
import { CapacitorIntegrationService } from '@common/services';
import { PickupTrip } from '@common/models';
import { ProjectKeyService } from '../../services/project-key.service';
import { switchMap } from 'rxjs/operators';
import VendorConfig from '../config/vendor.config';

@Injectable({
  providedIn: 'root',
})
export class RadarProviderService implements CustomerTrackingProvider {
  private pickupTripData: PickupTrip;
  private order: Order;

  providerName = VendorConfig.radar;

  constructor(
    private radarMapping: RadarMappingService,
    private oService: OrderService,
    private capacitorIntegration: CapacitorIntegrationService,
    private projectKey: ProjectKeyService
  ) {}

  connectOrder(order: Order, customerDetails: CardDetails): Observable<boolean> {
    return this.projectKey.getProjectKey().pipe(
      switchMap((key: string) => {
        this.order = order;
        this.pickupTripData = {
          orderID: order.customerFacingID,
          tagName: 'Restaurants',
          externalID: order.location.externalRef,
          date: order.orderReadyTimestamp,
          brandName: order.location.name,
          projectKey: key,
        };
        return of(true);
      })
    );
  }

  startOrderTracking(): Observable<string> {
    if (Capacitor.getPlatform() !== 'web') {
      console.log(this.pickupTripData);
      return from(this.capacitorIntegration.startCarTrip(this.pickupTripData));
    } else {
      return of('web');
    }
  }

  updateCustomerLocation(state: CustomerTrackingState, locationUpdate: DECustomerLocationUpdate) {}

  notifyOfArrival(orderGuid: string | number, extraMessage: string): Observable<Order> {
    return of(this.order);
  }

  stopTracking() {
    if (Capacitor.getPlatform() !== 'web') {
      this.capacitorIntegration.stopTracking();
    } else {
    }
  }
}
