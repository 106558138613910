<div class="px-3 pt-3 pb-5 position-relative">
  <div class="d-flex justify-content-end" style="position: sticky; top: 1rem;">
    <i class="bi bi-x-circle d-flex pointer bg-white rounded-circle" style="font-size: 30px;"
       (click)="activeModal.close('Close click')"></i>
  </div>
  <!--  <div class="d-flex align-items-center justify-content-center">-->
  <!--    <i class="bi bi-cash-stack" style="font-size: 100px; height: 100px; width: 100px;"></i>-->
  <!--  </div>-->
  <div class="w-100 modal-heading m-0 header-font py-2 text-center">
    <h1 class="font-size-h1 m-0 fw-bold">Convert Rewards Dollars to a Reward</h1>
  </div>
  <div class="w-100 d-flex justify-content-center pb-4">
    <div class="header-underline m-0"></div>
  </div>
  <h5 class="header-font fw-bold text-center">
    Current Dollars: {{ currentDollars | currency: currencyCode }}
  </h5>
  <p class="text-center mb-4">
    Choose how many banked rewards dollars you would like to convert into a reward. When you create a reward, your
    selected amount of dollars will be deducted from your dollar count and turned into a redeemable reward.
  </p>

  <form (ngSubmit)="redeemClicked()" [formGroup]="dollarConversion">
    <div class="form-group m-0 p-0 w-100">
      <label for="dollarAmount">Enter Dollar Amount</label>
      <input type="text" id="dollarAmount" formControlName="dollarAmount" class="form-control de-input m-0"
             [dropSpecialCharacters]="false" [specialCharacters]="['.']" mask="0*.00" placeholder="$0.00"
             inputmode="decimal">
    </div>
    <ng-container *ngIf="dollarAmount.invalid && (dollarAmount.dirty || dollarAmount.touched)">
      <small class="form-error" *ngIf="dollarAmount.errors.required">Amount Required</small>
    </ng-container>
    <button class="de-primary-btn w-100 mt-4" (click)="redeemClicked()">Convert to Reward</button>
  </form>
</div>
