import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {Menu} from '../../../../interfaces/menu.interface';
import {Category as DineEngineCategory} from '../../../../interfaces/category.interface';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';
import {MobileService} from '../../../../services/mobile.service';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in'))
    ])
  ]
})
export class HomeMenuComponent implements OnInit{

  // @Select(state => state.menu.menu) menu$: Observable<Menu>;
  // @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  // @Select(state => state.order.order) order$: Observable<Order>;
  // @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  @Input() displayMenu: Menu;
  @Input() displayTheme: ThemeColor;
  @Input() isMobile: boolean;
  @Input() mainSettings: MainSettings;
  @Output() categoryClick = new EventEmitter<DineEngineCategory>();

  carouselWidth: number;
  menuLoading = false;
  displayMenuCategories = '';

  subs: Subscription[] = [];

  constructor(
      public mobile: MobileService,
  ) {}

  ngOnInit() {
    this.carouselWidth = window.innerWidth;
  }

  goToCategory(category: DineEngineCategory) {
    this.categoryClick.emit(category);
  }

}
