
<div class="w-100 p-3" style="display: grid; grid-gap: 16px;">
  <div class="w-100"
      style="display:grid; grid-gap: 16px; grid-template-columns: auto 1fr auto; place-items: center start">
      <div  class="d-flex align-items-center">
        <i class="bi bi-emoji-smile-fill font-size-h1 d-flex"></i>
      </div>
    <div class="text-start fw-bold header-font" style="font-size: 24px">Help is On the Way</div>
    <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
      <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
  </div>
  <div>Our crew has been notified and someone will be on their way to assist you shortly.</div>
  <hr class="" style="width: 90px; border-width: 1px; margin: 0; border-top-color: var(--ion-color-primary);"/>
  <button class="link w-100 de-primary-btn" (click)="activeModal.close('Close click')">
    <ng-container>Continue</ng-container>
  </button>
</div>
