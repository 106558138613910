import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { SavedVehiclesPageService } from './saved-vehicles-page.service';
import { Vehicle } from 'src/interfaces/vehicle.interface';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';

import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import {Select, Store} from '@ngxs/store';
import {UpdateTitle} from '../../../../../store/actions/app.actions';

@Component({
  selector: 'saved-vehicles',
  template: ''
})
export class SavedVehiclesComponent implements OnInit, OnDestroy{

  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'saved vehicles';

  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;

  savedVehicles: Vehicle[];

  constructor(
    private pageService: SavedVehiclesPageService,
    // private navbarService: NavBarService,
    private store: Store
  ) {

  }

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    // Page service data
    this.subs.push(this.vendorSetup$.subscribe(vendorSetup => {
      if (vendorSetup) {
        this.displayVendorSetup = vendorSetup;
      }
    }));
    this.subs.push(this.order$.subscribe(order => {
      if (order) {
        this.displayOrder = order;
      }
    }));
    this.subs.push(this.user$.subscribe(user => {
      if (user) {
        this.displayUser = user;
      } else {
        this.displayUser = null;
      }
    }));
    // Page service data
    this.subs.push(this.pageService.savedVehicles$.subscribe(savedVehicles => {
      if (savedVehicles) {
        this.savedVehicles = savedVehicles;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  // UI Functions
  deleteVehicleButtonClicked(v: Vehicle) {
    // this.store.dispatch(new RemoveVehicle(v)).subscribe(value => {
    //   this.vehicles = this.store.selectSnapshot(UserState.vehicles);
    // });
  }

}
