import { Component, Input, OnInit } from '@angular/core';
import { Banner } from '../../../../vendors/directus/interfaces/banner.interface';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { UtilityService } from '@modules/utility/services';

@Component({
  selector: 'app-promotion-card',
  templateUrl: './promotion-card.component.html',
  styleUrls: ['promotion-card.component.scss'],
})
export class PromotionCardComponent implements OnInit {
  @Input() banner: Banner;
  constructor(private utilityService: UtilityService) {}
  ngOnInit() {}
  navigateTo(route: string) {
    this.utilityService.handleDynamicLink(route);
  }
}
