import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionGroup } from '../../../../interfaces/option-group.interface';
import { Option } from '../../../../interfaces/option.interface';
import { MenuService } from '@modules/menu/services';

@Component({
  selector: 'app-radio-checkbox-selector',
  templateUrl: './radio-checkbox-selector.component.html',
  styleUrls: ['radio-checkbox-selector.component.scss'],
})
export class RadioCheckboxSelectorComponent {
  @Input() optionGroup: OptionGroup;
  @Input() isModifierFlow: boolean;
  @Input() selectedOptionIndex: number;
  @Input() selectedOptions: Option[];
  @Input() displayOptionGroups: OptionGroup[];
  @Output() clickedOption = new EventEmitter<{ option: Option; quantity: number }>();
  optionSubGroup = null;
  constructor(public menuService: MenuService) {}

  optionClicked(event: { option: Option; quantity: number }) {
    // if option is not already selected, update all other options' quantities in this group to evenly distribute maxTotalQuantity across all options including the new one , if maxTotalQuantity exists
    // then emit the option with the new quantity
    // else if option is already selected, emit the option with the original quantity
    if (!event.option.isSelected && this.optionGroup.mandatorySelection && !!this.optionGroup.minTotalQuantity) {
      const selectedOptions = this.optionGroup.options.filter(option => option.isSelected);
      const selectedOptionsLength = selectedOptions.length;
      const maxTotalQuantity = this.optionGroup.maxTotalQuantity;
      if (maxTotalQuantity) {
        const newQuantity = Math.floor(maxTotalQuantity / (selectedOptionsLength + 1));
        selectedOptions.forEach(option => {
          option.quantity = newQuantity > this.optionGroup.maxChoiceQuantity ? this.optionGroup.maxChoiceQuantity : newQuantity;
        });
        this.clickedOption.emit({
          option: event.option,
          quantity: newQuantity > this.optionGroup.maxChoiceQuantity ? this.optionGroup.maxChoiceQuantity : newQuantity,
        });
      } else {
        this.clickedOption.emit(event);
      }
    } else {
      this.clickedOption.emit(event);
    }
  }

  optionTrackBy(index: number, option: Option) {
    return `${option.optionID}-${index}`;
  }
}
