<div class="d-flex flex-column my-4" style="overflow-y: auto">
    <ng-container *ngIf="branding$ | async as branding">
        <div *ngIf="branding.loyalty_club_logo?.data || branding.main_logo?.data"
             class="d-flex flex-row flex-nowrap w-100 justify-content-center mb-2 mb-lg-4 px-3">
            <img class="loyalty-logo w-50" alt="loyalty logo"
                 [src]="branding.loyalty_club_logo?.data?.full_url || branding.main_logo?.data?.full_url">
        </div>
    </ng-container>
<app-mobile-edit-page [isIncompleteProfile]="true"></app-mobile-edit-page>
</div>
