import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {filter, map, take} from 'rxjs/operators';

import {Store} from '@ngxs/store';
import {VendorSetup} from '../interfaces/vendor.interface';
import {NavigationService} from '@modules/navigation/services';

@Injectable({
  providedIn: 'root'
})
export class VendorGuard  {
  constructor(
    private router: Router,
    private navigation: NavigationService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(appState => appState.app.vendorSetup)
      .pipe(filter(vs => !!vs), take(1), map((vendorSetup: VendorSetup) => {
      let reroute = false;

      if (
          (vendorSetup.loyalty_provider === 'none' && (state.url === '/profile/rewards' || state.url === '/profile/activity')) ||
          (vendorSetup.order_history_provider === 'none' && (state.url === '/profile/saved-cards' || state.url === '/profile/orders')) ||
          (vendorSetup.curbside_vehicle_provider === 'none' && (state.url === '/profile/saved-vehicles'))
      ) {
        reroute = true;
      }

      if (reroute === true) {
        this.navigation.navigateToProfilePage();
        return false;
      } else {
        return true;
      }
    }));
  }
}
