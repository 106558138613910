<main
  class="d-flex fg-1 flex-column align-self-stretch zi-main position-relative">
  <section
    class="fg-1 fb-0 flex-row align-items-stretch overflow-auto"
    #scrollSection>
    <div
      class="container-fluid d-flex flex-row flex-nowrap align-items-center p-3 kiosk-category-title">
      <div class="fg-1 fb-0">
        <button
          type="button"
          (click)="backClicked()"
          class="btn ki-buttons outline-primary align-items-center py-2">
          <div class="d-flex align-items-center pe-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
          </div>
          Categories
        </button>
      </div>
      <h2
        class="h2 header-font justify-content-center align-items-center m-0 fw-bold fg-3 fb-0 py-2 text-center"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
        {{ category?.name }}
      </h2>
      <div class="fg-1 fb-0 h-100"></div>
    </div>
    <section class="container-fluid fg-1 fb-0">
      <div id="changeGrid" class="d-grid-4 w-100 pb-3 height-fit-content">
        <ng-container *ngIf="category$ | async as category; else skeletonCards">
          <ng-container *ngIf="user$ | async as user">
            <app-kiosk-product-card
              *ngFor="
                let product of category.products
                  | secretProducts: (user.isGuest ? roles.guest : roles.all);
                trackBy: productTrackBy
              "
              [imageURL]="product.thumbnailImageURL"
              [description]="category.description"
              [title]="category.name"
              isProduct="true"
              [product]="product"
              [productLoading]="productLoading"
              (addToOrder)="menuService.checkIfAlcohol(product)"
              (modifyProduct)="
                modifyClicked(product, modifyModalRef)
              "></app-kiosk-product-card>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </section>

  <app-kiosk-scroll-button [element]="scrollSection"></app-kiosk-scroll-button>
</main>

<ng-template #skeletonCards>
  <app-kiosk-product-card></app-kiosk-product-card>
  <!--  <app-kiosk-card *ngFor="let _ of [].constructor(8)" isSkeleton="true" isProduct="true"></app-kiosk-card>-->
</ng-template>

<ng-template #modifyModal let-modal>
  <app-modify-modal
    [basketProdID]="cartService.basketProdID"
    [isLoading]="menuService.settingProduct"
    (closeModal)="modal.close()"></app-modify-modal>
</ng-template>
