<div class="drawer-wrapper" [ngStyle]="{'height': cartHeight}">
  <div class="drawer-top" (click)="toggleCart()" #drawerHandle>
    <div class="d-flex flex-row">
      <div class="top-bar-line"></div>
    </div>
    <div class="d-flex flex-row top-bar-text justify-content-center">
      {{ title }}
    </div>
  </div>
  <ng-content></ng-content>
</div>
