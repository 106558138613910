import {Component, Input, Output, EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MobileService } from 'src/services/mobile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Offer} from '../../../../interfaces/offer.interface';
import {ToastService} from '../../../../services/toast.service';
import {collapseHorizontallyAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in'))
    ]),
      collapseHorizontallyAnimation(),
      fadeInOnEnterAnimation({
        duration: 200
      }),
      fadeOutOnLeaveAnimation({
        duration: 200
      })
  ]
})
export class OfferCardComponent {

  @Input() offer: Offer;
  @Input() insideModal: boolean = false

  @Output() clickedRedeem = new EventEmitter<any>();

  @ViewChild('offerModal') offerModalRef: TemplateRef<any>;

  showingBarcode: boolean = false;

  constructor(
    public mobile: MobileService,
    private modalService: NgbModal,
    private toast: ToastService
  ) { }

  redeemClicked() {
    this.clickedRedeem.emit();
  }

  viewOffer() {
    this.modalService.open(this.offerModalRef, {
      centered: true,
      animation: true,
      size: 'sm'
    })
  }

  copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value).then(() => {
      this.toast.success('Code copied to clipboard')
    }).catch(() => {
      this.toast.danger('Could not copy to clipboard')
    })
  }

  toggleBarcode(): void {
    this.showingBarcode = !this.showingBarcode
  }
}
