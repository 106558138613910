import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-allergen-box',
  templateUrl: './allergen-box.component.html',
  styleUrls: ['./allergen-box.component.scss']
})
export class AllergenBoxComponent {

  @Input() titleCopy = 'Allergen Information';
  @Input() bodyCopy = 'Allergen information cannot be found.';

  constructor() {}

}
