import { Pipe, PipeTransform } from '@angular/core';
import {Reward} from '../interfaces/reward.interface';

@Pipe({
  name: 'bankedRewardFilter'
})
export class BankedRewardFilterPipe implements PipeTransform {

  transform(rewards: Reward[]): Reward[] {
    return rewards.filter(reward => reward.rewardID !== 'banked');
  }

}
