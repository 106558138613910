import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexToRgbCss'
})
export class HexToRgbCssPipe implements PipeTransform {

  transform(hex: string): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : 'rgb(0, 0, 0)';
  }

}
