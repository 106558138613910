import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'timezoneConvert',
})
export class TimezoneConvertPipe implements PipeTransform {
  transform(value: Date, formatString = 'YYYY-MM-DD HH:mm:ss', targetTimezone: string): string {
    if (!value || !(value instanceof Date) || isNaN(value.getTime())) {
      return '';
    }
    if (!targetTimezone || !moment.tz.zone(targetTimezone)) {
      return '';
    }
    try {
      // Step 1: Extract the time components from the input date
      const inputTime = moment(value);

      // Step 2: Get today's date in the target timezone
      const todayInTargetTimezone = moment().tz(targetTimezone);

      // Step 3: Combine today's date with the input time components in the target timezone
      const combinedDateTime = moment
        .tz(targetTimezone)
        .year(inputTime.year())
        .month(inputTime.month())
        .date(inputTime.date())
        .hour(inputTime.hour())
        .minute(inputTime.minute())
        .second(inputTime.second())
        .millisecond(inputTime.millisecond());

      // Step 4: Format the date in the target timezone
      return combinedDateTime.format(formatString);
    } catch (error) {
      console.error('ConvertTimezonePipe error:', error);
      return '';
    }
  }
}
