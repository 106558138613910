<div class="input-group d-block w-100">

  <form [formGroup]="paymentInfo" id="payment-form">

    <input type="hidden"  name="payment_method_token" id="payment_method_token">

    <!-- card -->
    <div class="row m-0">
      <!-- name on card -->
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="full_name" class="w-100">Name On Card*</label>
        <input type="text" autocomplete="cc-name" class="form-control" id="full_name" name="full_name"
               formControlName="name" aria-describedby="full_name" placeholder="">
      </div>

      <!-- card number -->
      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="spreedly-number" class="w-100">Card Number*</label>
        <div class="form-control" id="spreedly-number" aria-describedby="spreedly-number"></div>
      </div>
    </div>

    <!-- exp. date & cvv -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="expirationDate" class="w-100">Exp. Date*</label>
        <input type="month" autocomplete="cc-exp" class="form-control" id="expirationDate"
               [min]="minDate" formControlName="expiration" aria-describedby="expirationDate" placeholder="">
      </div>


      <div class="form-group w-100 col-12 col-md-6 pe-0 ps-0 ps-md-2">
        <label for="spreedly-cvv" class="w-100">CVV*</label>
        <div class="form-control" id="spreedly-cvv" aria-describedby="spreedly-cvv"></div>
      </div>
    </div>

    <!-- card type & zip code -->
    <div class="row m-0">
      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 pe-md-2">
        <label for="cardType" class="w-100">Credit/Debit Card Type*</label>
        <select autocomplete="cc-type" class="form-control" id="cardType" (change)="changeCardType($event)"
                formControlName="cardType" aria-describedby="cardType">
          <option value="visa">Visa</option>
          <option value="mastercard">Mastercard</option>
          <option value="discover">Discover</option>
          <option value="american express">American Express</option>
        </select>
      </div>

      <div class="form-group w-100 col-12 col-md-6 ps-0 pe-0 ps-md-2 mb-0">
        <label for="zipCode" class="w-100">ZIP Code*</label>
        <input type="number" autocomplete="postal-code" class="form-control"
               formControlName="zipCode" id="zipCode" aria-describedby="zipCode" placeholder="">
      </div>
    </div>

  </form>

</div>
