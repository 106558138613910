import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProxyBaseURLService } from '../../services/proxy-base-url.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpendgoHttpService {
  constructor(
    private http: HttpClient,
    private url: ProxyBaseURLService
  ) {}

  private vendorName = 'spendgo';

  get<T>(resource: string, accessToken?: string): Observable<T> {
    return this.url.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return this.generateHeaders(accessToken, deviceID.identifier).pipe(
              switchMap(headers => {
                // tslint:disable-next-line:max-line-length
                return this.http.get<string>(baseURL.concat(resource), { headers, observe: 'body', responseType: 'text' as 'json' }).pipe(
                  map(res => {
                    return this.decryptBody<T>(res, deviceID.identifier);
                  }),
                  catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                      return throwError(
                        new HttpErrorResponse({
                          error: this.decryptBody(error.error, deviceID.identifier),
                        })
                      );
                    } else {
                      return throwError(error);
                    }
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  put<T>(resource: string, body: object, accessToken?: string): Observable<T> {
    return this.url.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return this.generateHeaders(accessToken, deviceID.identifier).pipe(
              switchMap(headers => {
                // tslint:disable-next-line:max-line-length
                return this.http
                  .put<string>(baseURL.concat(resource), body, { headers, observe: 'body', responseType: 'text' as 'json' })
                  .pipe(
                    map(res => {
                      return this.decryptBody<T>(res, deviceID.identifier);
                    }),
                    catchError(error => {
                      if (error instanceof HttpErrorResponse) {
                        return throwError(
                          new HttpErrorResponse({
                            error: this.decryptBody(error.error, deviceID.identifier),
                          })
                        );
                      } else {
                        return throwError(error);
                      }
                    })
                  );
              })
            );
          })
        );
      })
    );
  }

  post<T>(resource: string, body: object, accessToken?: string): Observable<T> {
    return this.url.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return this.generateHeaders(accessToken, deviceID.identifier).pipe(
              switchMap(headers => {
                if (sessionStorage.getItem('recaptchaToken')) {
                  headers = headers.append('X-Captcha-Token', sessionStorage.getItem('recaptchaToken'));
                }
                return this.http
                  .post<string>(baseURL.concat(resource), body, { headers, observe: 'body', responseType: 'text' as 'json' })
                  .pipe(
                    map(res => {
                      return this.decryptBody<T>(res, deviceID.identifier);
                    }),
                    catchError(error => {
                      if (error instanceof HttpErrorResponse) {
                        return throwError(
                          new HttpErrorResponse({
                            error: this.decryptBody(error.error, deviceID.identifier),
                          })
                        );
                      } else {
                        return throwError(error);
                      }
                    })
                  );
              })
            );
          })
        );
      })
    );
  }

  patch<T>(resource: string, body: object, accessToken?: string): Observable<T> {
    return this.url.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return this.generateHeaders(accessToken, deviceID.identifier).pipe(
              switchMap(headers => {
                // tslint:disable-next-line:max-line-length
                return this.http
                  .patch<string>(baseURL.concat(resource), body, { headers, observe: 'body', responseType: 'text' as 'json' })
                  .pipe(
                    map(res => {
                      return this.decryptBody<T>(res, deviceID.identifier);
                    }),
                    catchError(error => {
                      if (error instanceof HttpErrorResponse) {
                        return throwError(
                          new HttpErrorResponse({
                            error: this.decryptBody(error.error, deviceID.identifier),
                          })
                        );
                      } else {
                        return throwError(error);
                      }
                    })
                  );
              })
            );
          })
        );
      })
    );
  }

  delete<T>(resource: string, accessToken?: string): Observable<T> {
    return this.url.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return this.generateHeaders(accessToken, deviceID.identifier).pipe(
              switchMap(headers => {
                // tslint:disable-next-line:max-line-length
                return this.http
                  .delete<string>(baseURL.concat(resource), { headers, observe: 'body', responseType: 'text' as 'json' })
                  .pipe(
                    map(res => {
                      return this.decryptBody<T>(res, deviceID.identifier);
                    }),
                    catchError(error => {
                      if (error instanceof HttpErrorResponse) {
                        return throwError(
                          new HttpErrorResponse({
                            error: this.decryptBody(error.error, deviceID.identifier),
                          })
                        );
                      } else {
                        return throwError(error);
                      }
                    })
                  );
              })
            );
          })
        );
      })
    );
  }

  private generateHeaders(accessToken: string, deviceID: string): Observable<HttpHeaders> {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: btoa(this.xor(btoa(['Bearer', accessToken].join(' ')), deviceID)),
      'X-Device-ID': deviceID,
      'Referrer-Policy': 'origin-when-cross-origin',
    });
    return of(headers);
  }

  private decryptBody<T>(body: string, key: string): T {
    const parsedBody = atob(this.xor(atob(body), key));
    return JSON.parse(parsedBody) as T;
  }

  private xor(str: string, key: string): string {
    const strArray: string[] = str.split('');
    const keyArray: string[] = key.split('');
    const strLen: number = str.length;
    const keyLen: number = key.length;

    for (let i = 0; i < strLen; i++) {
      strArray[i] = String.fromCharCode(strArray[i].charCodeAt(0) ^ keyArray[i % keyLen].charCodeAt(0));
    }

    return strArray.join('');
  }
}
