import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { Option } from 'src/interfaces/option.interface';

@Component({
  selector: 'app-selected-mods-scroll',
  templateUrl: './selected-mods-scroll.component.html',
  styleUrls: ['./selected-mods-scroll.component.scss']
})
export class SelectedModsScrollComponent {

  @Input() selectedOptions: [];
  @Input() productName: string;
  @Output() clickedOption = new EventEmitter<any>();

  optionClicked(optionGroup) {
    this.clickedOption.emit(optionGroup);
  }

  scroll(direction: string) {
    const scrollItem = document.getElementById('mod-scroll-section');
      if (scrollItem) {
        scrollItem.scrollBy({
          top: 0,
          left: direction === 'right' ? scrollItem.clientWidth : -scrollItem.clientWidth,
          behavior: 'smooth',
        });
      }
  }
}
