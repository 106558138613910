import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Features } from '../../../../vendors/directus/interfaces/features.interface';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home-featured',
  templateUrl: './home-featured.component.html',
  styleUrls: ['home-featured.component.scss'],
})
export class HomeFeaturedComponent {
  @Select((state: GlobalStateModel) => state.app.features) features$: Observable<Features[]>;
}
