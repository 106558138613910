import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, debounce, Observable } from 'rxjs';
import { Product } from 'src/interfaces/product.interface';
import { Option } from 'src/interfaces/option.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { debounceTime, filter, take } from 'rxjs/operators';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { ErrorService } from '../../../../../services/error.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AnnouncementService } from '@modules/brochure/services/announcement.service';
import { ToastService } from '../../../../../services/toast.service';
import { Menu } from 'src/interfaces/menu.interface';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import { AddToOrder, CheckUpsell, UpdateOrderItem } from '../../../../../store/actions/order.actions';
import { SetMenu, SetProduct } from '../../../../../store/actions/menu.actions';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { MetaService } from '../../../../../services/meta.service';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { ModeService } from '../../../../../services/mode.service';
import { MobileService } from '../../../../../services/mobile.service';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';
import { NavigationService } from '@modules/navigation/services';
import { UtilityService } from '@modules/utility/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { MenuService, ProductCustomizationService } from '@modules/menu/services';
import { SubscriptionComponent } from '@common/components';
import { HttpErrorResponse } from '@angular/common/http';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';
import { SentryService } from '@common/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgeCheckComponent } from '@modules/cart/components/age-check/age-check.component';
import { GlobalStateModel } from '../../../../../store/state.model';
import { CartService } from '@modules/cart/services';

@Component({
  selector: 'app-product',
  template: '',
  animations: [
    trigger('animateItem', [
      transition(
        '* => forward',
        animate(
          600,
          keyframes([
            style({ transform: 'translateX(0)', offset: 0 }),
            style({ transform: 'translateX(-150%)', opacity: 1, offset: 0.5 }),
            style({ transform: 'translateX(0)', opacity: 1, offset: 0.51 }),
            style({ transform: 'translateX(0)', opacity: 1, offset: 1 }),
          ])
        )
      ),
      transition(
        '* => backward',
        animate(
          600,
          keyframes([
            style({ transform: 'translateX(0)', offset: 0 }),
            style({ transform: 'translateX(150%)', opacity: 1, offset: 0.5 }),
            style({ transform: 'translateX(0)', opacity: 1, offset: 0.51 }),
            style({ transform: 'translateX(0)', opacity: 1, offset: 1 }),
          ])
        )
      ),
    ]),
  ],
})
// TODO This component is priority #1 when we get a chance to rewrite this.
export class ProductComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Select(state => state.app.backRoute) backRoute$: Observable<string>;
  @Select(state => state.menu.selectedProduct) product$: Observable<Product>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  private displayOptionGroupsSubject = new BehaviorSubject<OptionGroup[]>([]);
  displayOptionGroups$ = this.displayOptionGroupsSubject.asObservable().pipe(debounceTime(150));
  protected _displayOptionGroups: OptionGroup[];

  canAddToOrder = false;
  isEdit = false;
  basketProd;
  productID;
  openList = [];
  productquant = 1;
  selectedItem;
  hasSelectedModifiers;
  selectedOptions: Option[] = [];

  restaurantID: string;
  categoryNameSlug: string;
  // Page details for SEO
  title = 'Modify';
  isLoading = true;
  productLoading = false;
  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  displayProduct: Product;
  displayBranding: Branding;

  displayProducts: Product[];
  displayMenu: Menu;
  nonImageSection = [];
  slideSection = [];
  sectionOpen = [];
  directusProd;
  displayAdjustedPrice = 0;
  errorMessage = '';
  showNutrition = false;
  productDetails = new UntypedFormGroup({
    recipient: new UntypedFormControl(),
    specInstr: new UntypedFormControl(),
  });
  currentOption: Option;
  isRootOptionGroup = true;
  isModifierFlow = this.mode.mode !== 'tableside';
  parentItem;
  animateName = 'start';
  displayBottom = true;
  pageScrollPositions = [];
  private productNameSlug: string;
  private selectedHandoffType: HandoffType;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected navigation: NavigationService,
    // private pageService: ProductPageService,
    // private navbarService: NavBarService,
    protected errorService: ErrorService,
    protected announcementService: AnnouncementService,
    protected toast: ToastService,
    protected directus: DirectusService,
    protected store: Store,
    protected meta: MetaService,
    public mode: ModeService,
    protected mobile: MobileService,
    private utility: UtilityService,
    private analytics: AnalyticsService,
    public menuService: MenuService,
    private sentry: SentryService,
    private productCustomization: ProductCustomizationService,
    public cartService: CartService,
    protected modal: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new UpdateTitle('Loading Product...'));
    // Path parameter data
    this.subs.push(
      this.route.params.subscribe((pathParams: any) => {
        this.menuService.checkMenuParameters(pathParams);
      }),
      combineLatest([this.route.params, this.route.queryParams, this.mainSettings$])
        .pipe(take(1))
        .subscribe(([pathParams, queryParams, ms]) => {
          if (pathParams.id) {
            this.restaurantID = pathParams.id;
            this.store
              .dispatch(new SetMenu(this.restaurantID, HandoffType.pickup))
              .toPromise()
              .catch(() => {
                this.navigation.navigateTo404Page();
              });
            if (pathParams.category) {
              this.categoryNameSlug = pathParams.category;
              if (ms.product_page_back_route) {
                this.store.dispatch(new SetRouteBack(`/menu/${this.restaurantID}/${this.categoryNameSlug}`));
              } else {
                this.store.dispatch(new SetRouteBack(`/menu/${this.restaurantID}`));
              }
              if (pathParams.product) {
                this.productNameSlug = pathParams.product;
                if (queryParams.basketProd && queryParams.basketProdID) {
                  this.isEdit = true;
                  this.basketProd = queryParams.basketProd;
                  this.productID = queryParams.basketProdID;
                }
              } else {
                this.navigation.navigateToMenuPage(this.restaurantID, this.categoryNameSlug);
              }
            } else {
              this.navigation.navigateToMenuPage(this.restaurantID);
            }
          } else {
            this.navigation.navigateToLocationsPage();
          }
        })
    );
    // Page service data
    this.subs.push(
      this.order$.pipe(filter(o => o !== null)).subscribe(order => {
        this.displayOrder = order;
        if (this.mode.mode !== 'tableside') {
          this.store.dispatch(new CheckUpsell(order.orderID));
        }
        this.selectedHandoffType = order.handoffType;
        this.store.dispatch(new SetMenu(order.location.locationID, this.selectedHandoffType));
        // tslint:disable-next-line:max-line-length
        this.store
          .dispatch(new SetProduct(this.restaurantID, this.categoryNameSlug, this.productNameSlug, this.selectedHandoffType))
          .toPromise()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.navigation.navigateToMenuPage(this.restaurantID, this.categoryNameSlug);
          });
      })
    );
    this.subs.push(
      combineLatest([
        this.order$.pipe(
          filter(order => order !== null),
          take(1)
        ),
        this.product$.pipe(filter(prod => prod !== null)),
      ]).subscribe(([order, product]) => {
        this.sentry.setTransactionName(product.name);
        this.meta.manualUpdate({
          title: product.name,
          description: product.seoDescription,
          keywords: null,
        });
        setTimeout(() => {
          (window as any).prerenderReady = true;
        }, 1000);
        this.analytics.analyticsOnProductCustomize(
          product,
          this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
        );
        this.productCustomization.clearSelections();
        const productCopy = JSON.parse(JSON.stringify(product)) as Product;
        if (this.isEdit) {
          const orderItem = order.items.find((basketProd: any) => basketProd.orderItemID === this.productID);
          this.productCustomization.clearSelections();
          if (orderItem && orderItem.options) {
            // orderItem.options.forEach(op => {
            //   this.productCustomization.addToSelectedOptions(op);
            // });
            this.menuService.recursivelySetSelectedOptions(
              productCopy,
              orderItem.options.map(op => op)
            );
          }
          this.productquant = orderItem.quantity;
        } else {
          this.menuService.recursivelySetSelectedOptions(productCopy);
        }
        this.displayProduct = productCopy;
        this.productquant = this.displayProduct?.minQuantity;
        this.title = product.name;
        this.store.dispatch(new UpdateTitle(this.title));
        this.directus.getContentProducts().subscribe(prod => {
          this.directusProd = prod.find(p => this.utility.toSlug(p.name) === this.utility.toSlug(this.displayProduct.name));
          this.setDisplayOptionGroups(this.getDisplayOptionGroups(this.displayProduct));
          this.selectedOptions = this.menuService.getSelectedOptions(this._displayOptionGroups);
          this.displayAdjustedPrice = this.displayProduct.priceCents + this.getSelectionsPrice(this._displayOptionGroups);
          this.canAddToOrder = this.getCanAddToOrderStatus(this._displayOptionGroups);
          this.getModifierGroupTypes(this.directusProd);
        });
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
      }),
      this.menu$.subscribe(menu => {
        if (menu) {
          this.displayMenu = menu;
        }
      }),
      this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
        this.showNutrition = ms.show_nutrition_key;
      }),
      this.backRoute$.subscribe(route => {
        localStorage.setItem('tempBackRoute', route);
      })
    );
    this.announcementService.adjustLocationsPadding('prodModCol');
  }

  getModifierGroupTypes(prod) {
    if (prod && prod.non_image_sections && prod.non_image_sections.length) {
      this.nonImageSection = [];
      prod.non_image_sections.forEach(nonImage => {
        this.nonImageSection.push(nonImage);
      });
    }
    if (prod && prod.slider_sections && prod.slider_sections.length) {
      this.slideSection = [];
      prod.slider_sections.forEach(slideSection => {
        this.slideSection.push(slideSection);
      });
    }
  }

  checkIfAlcohol() {
    // if product contains alcohol, open age check modal
    if (this.displayProduct.isAlcohol && !sessionStorage.getItem('ageCheck')) {
      this.modal.open(AgeCheckComponent, { centered: true }).result.then(result => {
        if (result === 'Yes') {
          sessionStorage.setItem('ageCheck', 'true');
          this.addToCart();
        } else {
          this.toast.danger('You must be 21 or older to order this item.');
          throw new Error('You must be 21 or older to order this item.');
        }
      });
    } else {
      this.addToCart();
    }
  }

  addToCart() {
    this.productLoading = true;
    this.canAddToOrder = false;
    this.errorMessage = '';
    const selectedOptions: Option[] = this.menuService.getSelectedOptions(this._displayOptionGroups);
    const orderItem = this.menuService.toOrderItem(
      this.displayProduct,
      selectedOptions,
      this.productquant,
      null,
      this.productDetails.get('recipient').value,
      this.productDetails.get('specInstr').value
    );
    orderItem.guestName = this.menuService.isGroupOrder ? this.menuService.userName : '';
    this.store.dispatch(new AddToOrder(orderItem)).subscribe({
      next: () => {
        this.menuService.addToCartSuccess(this.displayProduct);
        if (this.mode.mode === 'tableside') {
          this.menuService.checkUpsellAndNavigate(orderItem);
        } else {
          this.menuService.navigateAfterAdd();
        }
        this.productLoading = false;
      },
      error: error => this.cartSubmitError(error),
    });
  }

  updateItem() {
    this.productLoading = true;
    this.canAddToOrder = false;
    this.errorMessage = '';
    const selectedOptions: Option[] = this.menuService.getSelectedOptions(this._displayOptionGroups);
    console.log(selectedOptions);
    const orderItem = this.menuService.toOrderItem(
      this.displayProduct,
      selectedOptions,
      this.productquant,
      this.productID.toString(),
      this.productDetails.get('recipient').value,
      this.productDetails.get('specInstr').value
    );
    this.store
      .dispatch(new UpdateOrderItem(orderItem, this.productquant))
      .toPromise()
      .then(() => {
        this.toast.success('Item updated');
        if (this.mobile.isMobile && this.mode.mode !== 'tableside') {
          this.navigation.navigateToCartPageDeleteParams(this.restaurantID);
        } else {
          this.navigation.navigateToMenuPageDeleteParams(this.restaurantID);
        }
        this.productLoading = false;
      })
      .catch(error => this.cartSubmitError(error));
  }

  cartSubmitError(error: HttpErrorResponse) {
    this.errorMessage = this.errorService.productError(error);
    this.toast.danger(this.errorMessage);
    this.canAddToOrder = true;
    this.productLoading = false;
  }

  incrementClicked() {
    this.productquant++;
  }

  decrementClicked() {
    if (this.productquant !== 1) {
      this.productquant--;
    }
  }

  toggleClass(selected) {
    this.openList[selected.id] = !this.openList[selected.id];
  }

  toggleOpen(item) {
    if (item.optionID === this.selectedItem) {
      this.selectedItem = '';
    } else {
      this.selectedItem = item.optionID;
    }
  }

  optionClicked(event: { option: Option; quantity: number }, animationDirection?: string) {
    this.errorMessage = '';
    // const wasAlreadySelected = event.option.isSelected;
    // // if was not already selected, find option group and check if maxTotalQuantity is set
    // if (!wasAlreadySelected) {
    //   const optionGroup = this._displayOptionGroups.find(group => group.options.some(op => op === event.option));
    //   if (optionGroup && optionGroup.maxTotalQuantity) {
    //     event.option.isSelected = this.setItemIsSelected(event.option);
    //     // if maxTotalQuantity is set, check if the total quantity of all selected options is less than maxTotalQuantity
    //     const selectedOptions = optionGroup.options.filter(op => op.isSelected);
    //     // divide maxTotalQuantity by the number of selected options to get the max quantity for each option
    //     const maxQuantity = Math.floor(optionGroup.maxTotalQuantity / selectedOptions.length);
    //     // set the quantity of each selected option to maxQuantity
    //     selectedOptions.forEach(op => (op.quantity = maxQuantity));
    //     const selectedQuantity = selectedOptions.reduce((acc, op) => acc + op.quantity, 0);
    //     // if the total quantity of all selected options is greater than maxTotalQuantity, set the quantity of the clicked option to 1
    //     // this should never happen, but just in case
    //     if (selectedQuantity > optionGroup.maxTotalQuantity) {
    //       event.quantity = 1;
    //     }
    //   } else {
    //     event.option.isSelected = this.setItemIsSelected(event.option);
    //   }
    // } else {
    // }
    event.option.isSelected = this.setItemIsSelected(event.option);

    if (event.option.isSelected) {
      event.option.whenSelected = new Date();
      const optionGroup = this._displayOptionGroups.find(group => group.options.some(op => op === event.option));
      if (optionGroup) {
        // tslint:disable-next-line:max-line-length
        const selectedOptions = optionGroup.options
          .filter(op => op.isSelected)
          .sort((a, b) => a.whenSelected.getTime() - b.whenSelected.getTime());
        if (optionGroup.maxAllowed && selectedOptions.length > optionGroup.maxAllowed) {
          selectedOptions[0].isSelected = false; // remove the earliest added option
        }
      }
    } else {
      event.option.quantity = 0;
    }
    this.setDisplayOptionGroups(
      this.menuService.setSelectedOptionFromGroup(event.option, this.getDisplayOptionGroups(this.displayProduct), event.quantity)
    );
    // this.displayOptionGroups = this.getDisplayOptionGroups(this.displayProduct);
    // this.displayOptionGroups = this.menuService.setSelectedOptionFromGroup(option, this.displayOptionGroups);
    this.selectedOptions = this.menuService.getSelectedOptions(this._displayOptionGroups);
    this.displayAdjustedPrice = this.displayProduct.priceCents + this.getSelectionsPrice(this._displayOptionGroups);
    this.canAddToOrder = this.getCanAddToOrderStatus(this._displayOptionGroups);
    this.scrollSelectedMod();
    const isHalfAndHalfChild = this.menuService.isHalfAndHalfChild(event.option, this._displayOptionGroups);
    if (!isHalfAndHalfChild) {
      this.setCurrentOptionGroup(event.option);
    }
  }

  setCurrentOptionGroup(option: Option) {
    if (this.isModifierFlow && option.isSelected) {
      if (option && option.optionGroups && option.optionGroups.length) {
        // tslint:disable-next-line:max-line-length
        const scroll = this.mobile.isMobile
          ? document.getElementsByClassName('modal-content')[0]
          : document.getElementById('modify-scroll');
        if (scroll) {
          const scrollLength = scroll.scrollTop;
          this.pageScrollPositions.push(scrollLength);
        }
        this.modifyAnimation('forward', option);
        this.isRootOptionGroup = false;
        this.currentOption = option;
        this.findParentOption(option, this.displayProduct);
        const scrollSection = this.mobile.isMobile
          ? document.getElementsByClassName('modal-content')[0]
          : document.getElementById('modify-scroll');
        if (scrollSection) {
          scrollSection.scrollTo(0, 0);
        }
      }
    }
  }

  modifyAnimation(direction: string, option?: Option) {
    if (this.isModifierFlow) {
      if (direction !== 'backward') {
        if (!option || (option.optionGroups && option.optionGroups.length)) {
          this.displayBottom = true;
          this.animateName = direction;
        }
      } else {
        this.displayBottom = true;
        this.animateName = direction;
      }
    }
  }

  resetAnimationState() {
    this.animateName = '';
    this.displayBottom = true;
  }

  setItemIsSelected(option: Option) {
    // Can only uncheck a required item by selecting another
    const optionGroup = this._displayOptionGroups.find(group => group.options.some(op => op === option));
    if (option.isSelected) {
      if (optionGroup) {
        const selected = optionGroup.options.filter(op => op.isSelected).length;
        return optionGroup.minRequired === selected;
      } else {
        return true;
      }
    } else {
      if (optionGroup) {
        // if maxTotalQuantity is set, check if the total quantity of all selected options is less than maxTotalQuantity
        if (optionGroup.maxTotalQuantity) {
          const selectedOptions = optionGroup.options.filter(op => op.isSelected);
          const selectedQuantity = selectedOptions.reduce((acc, op) => acc + op.quantity, 0);
          return selectedQuantity <= optionGroup.maxTotalQuantity;
        }
      }
      return true;
    }
  }

  sortSelectedByTime() {
    const sortSelected = [];
    this._displayOptionGroups.forEach(og => {
      if (og.options) {
        og.options.forEach(op => {
          if (op.isSelected) {
            sortSelected.push(op);
          }
        });
      }
    });
    sortSelected.sort((a, b) => {
      return a.whenSelected.getTime() - b.whenSelected.getTime();
    });
    return sortSelected;
  }

  scrollSelectedMod() {
    setTimeout(() => {
      const scrollItem = document.getElementById('mod-scroll-section');
      if (scrollItem) {
        scrollItem.scroll({
          top: 0,
          left: scrollItem.scrollWidth,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  removeChipClicked(option: Option) {
    option.isSelected = false;

    this.setDisplayOptionGroups(this.menuService.setSelectedOptionFromGroup(option, this.getDisplayOptionGroups(this.displayProduct)));
    this.selectedOptions = this.menuService.getSelectedOptions(this._displayOptionGroups);
    this.displayAdjustedPrice = this.displayProduct.priceCents + this.getSelectionsPrice(this._displayOptionGroups);
    this.canAddToOrder = this.getCanAddToOrderStatus(this._displayOptionGroups);
  }

  orderModGroups(groups) {
    if (this.directusProd && this.directusProd.sort_modifier_groups) {
      const sortedgroups = [];
      const prod = this.directusProd.sort_modifier_groups;
      prod.forEach(p => {
        const item = groups.filter(g => g.optionGroupID === p.modGroupId.toString());
        if (item && item.length) {
          sortedgroups.push(item[0]);
        }
      });
      groups.forEach(g => {
        let numInGroups = 0;
        let sortNum = 0;
        groups.forEach(oGroup => {
          if (g.optionGroupID === oGroup.optionGroupID) {
            numInGroups = numInGroups + 1;
          }
        });
        sortedgroups.forEach(sg => {
          if (sg.optionGroupID === g.optionGroupID) {
            sortNum = sortNum + 1;
          }
        });
        if (sortNum < numInGroups) {
          sortedgroups.push(g);
        }
      });
      return sortedgroups;
    } else {
      return groups;
    }
  }

  saveChoices(option: Option) {
    this.findParentOption(option, this.displayProduct);
    const parent = this.parentItem;
    if (parent && parent.productID) {
      // Is product level
      this.currentOption = null;
      this.isRootOptionGroup = true;
    } else {
      // Is option
      this.currentOption = parent;
      this.isRootOptionGroup = false;
    }
    if (parent) {
      parent.optionGroups.forEach(gr => {
        if (gr.options.some(op => op === option)) {
          // scroll
          setTimeout(() => {
            const scrollLength = this.pageScrollPositions.pop();
            const scrollSection = this.mobile.isMobile
              ? document.getElementsByClassName('modal-content')[0]
              : document.getElementById('modify-scroll');
            scrollSection.scrollTo(0, scrollLength);
          }, 100);
        }
      });
    }
    this.findParentOption(parent, this.displayProduct);
  }

  toSlug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/ +/g, '-');
  }

  findParentOption(option: Option, parent: Option | Product) {
    parent.optionGroups.forEach(og => {
      if (og.options.some(op => op === option)) {
        this.parentItem = parent;
      } else {
        og.options.filter(opp => this.findParentOption(option, opp));
      }
    });
  }

  checkIfSelectedMods() {
    let bool = false;
    if (this._displayOptionGroups) {
      this._displayOptionGroups.forEach((display: any) => {
        if (display.options) {
          display.options.forEach((op: any) => {
            if (op.isSelected) {
              bool = true;
            }
          });
        }
      });
    }
    return bool;
  }

  protected getDisplayOptionGroups(displayProduct: Product): OptionGroup[] {
    const groups: OptionGroup[] = [].concat(displayProduct.optionGroups);
    let setgroups = [];
    groups.forEach(g => {
      setgroups = setgroups.concat(g);
      setgroups = setgroups.concat(this.getSelectedSubOptionGroups(g));
    });
    return this.mode.mode === 'tableside' ? this.orderModGroups(setgroups) : setgroups;
  }

  private getSelectedSubOptionGroups(optionGroup: OptionGroup): OptionGroup[] {
    let subGroups: OptionGroup[] = [];
    if (optionGroup.options && optionGroup.options.length) {
      optionGroup.options
        .filter(op => op.isSelected)
        .forEach(op => {
          if (op.optionGroups && op.optionGroups.length) {
            // subGroups = subGroups.concat(op.optionGroups);
            op.optionGroups.forEach(g => {
              subGroups = subGroups.concat(g);
              subGroups = subGroups.concat(this.getSelectedSubOptionGroups(g));
            });
          }
        });
    }
    return subGroups;
  }

  protected getSelectionsPrice(displayOptionGroups: OptionGroup[]): number {
    let addedCents = 0;
    displayOptionGroups.forEach(group => {
      group.options.forEach(op => {
        if (op.isSelected) {
          addedCents += op.addedCents;
        }
      });
    });
    return addedCents;
  }

  protected getCanAddToOrderStatus(displayOptionGroups: OptionGroup[]): boolean {
    return displayOptionGroups.every(group => this.menuService.optionGroupIsValid(group));
  }

  getSelectedAmount(index: number) {
    const optiongroup = this._displayOptionGroups[index];
    const opFilter = optiongroup.options.filter(op => op.isSelected);
    return opFilter.length;
  }

  selectedSection(section: any) {
    let toClose = null;
    this._displayOptionGroups.forEach(res => {
      if (res !== section) {
        const deselect = document.getElementById('review-' + res.optionGroupID) as HTMLInputElement;
        if (deselect) {
          if (deselect.checked) {
            toClose = res.optionGroupID;
          }
          deselect.checked = false;
        }
      }
      if (res === section) {
        this.sectionOpen.push(res.name);
      }
    });
    this.closeSubAccordions();
    if (toClose !== null) {
      const scrollzone = document.getElementById('scrollzone');
      const scrollItem = document.getElementById('optionitems-' + toClose);
      const scrollAmount = -scrollItem.offsetHeight;

      scrollzone.scrollBy({
        top: scrollAmount,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  subAccordionClicked(sub, i) {
    const arrow1 = document.getElementById('arrow1' + i) as HTMLInputElement;
    const arrow2 = document.getElementById('arrow2' + i) as HTMLInputElement;
    const sub1 = document.getElementById('subacor-1' + i) as HTMLInputElement;
    const sub2 = document.getElementById('subacor-2' + i) as HTMLInputElement;

    if (sub === 'subacor-1') {
      if (sub1 && sub1.checked) {
        arrow1.name = 'caret-up';
      } else {
        arrow1.name = 'caret-down';
      }
      if (sub2 && sub2.checked) {
        sub2.checked = false;
        arrow2.name = 'caret-down';
      }
    } else {
      if (sub2 && sub2.checked) {
        arrow2.name = 'caret-up';
      } else {
        arrow2.name = 'caret-down';
      }
      if (sub1 && sub1.checked) {
        sub1.checked = false;
        arrow1.name = 'caret-down';
        const scrollzone = document.getElementById('scrollzone');
        const scrollItem = document.getElementById('subacor-options-1' + i);
        const scrollAmount = -scrollItem.offsetHeight;

        scrollzone.scrollBy({
          top: scrollAmount,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  closeSubAccordions() {
    this._displayOptionGroups.forEach((res, i) => {
      const subacor1 = document.getElementById('subacor-1' + i) as HTMLInputElement;
      const subacor2 = document.getElementById('subacor-2' + i) as HTMLInputElement;
      const arrow1 = document.getElementById('arrow1' + i) as HTMLInputElement;
      const arrow2 = document.getElementById('arrow2' + i) as HTMLInputElement;

      if (subacor1 && subacor2) {
        subacor1.checked = false;
        subacor2.checked = false;
        arrow1.name = 'caret-down';
        arrow2.name = 'caret-down';
      }
    });
  }

  scrollToCenter(id: string) {
    this.utility.scrollToCenter(id);
  }

  protected setDisplayOptionGroups(optionGroups: OptionGroup[]) {
    this._displayOptionGroups = optionGroups;
    this.displayOptionGroupsSubject.next(optionGroups);
  }

  clickCart() {
    document.getElementById('cartButton').click();
  }
}
