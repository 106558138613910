import { Pipe, PipeTransform } from '@angular/core';
import { Reward } from '../interfaces/reward.interface';

@Pipe({
  name: 'removeCouponRewards',
  pure: false,
})
export class RemoveCouponRewardsPipe implements PipeTransform {
  transform(rewards: Reward[]): Reward[] {
    if (!rewards) {
      return [];
    }
    return rewards.filter(reward => !reward.isCoupon);
  }
}
