<div class="ingredient-dropdown">
  <ng-container *ngIf="!optionSubGroup; else subOptionGroupChoices">
    <ng-container *ngTemplateOutlet="optionGroupChoices"></ng-container>
  </ng-container>
</div>

<ng-template #subOptionGroupChoices>
  <label for="subOptionGroupSelect">{{ optionGroup.name }}</label>
  <select #options class="form-control" id="subOptionGroupSelect" (click)="$event.stopPropagation()" (change)="subOptionClicked(options.value)" [value]='selectedSubOption?.optionID'>
    <ng-container *ngFor="let childOption of optionGroup.options">
      <option (click)="$event.stopPropagation()" [value]="childOption.optionID">{{ childOption.name }}</option>
    </ng-container>
  </select>
</ng-template>

<ng-template #optionGroupChoices>
  <label for="optionGroupSelect">{{ optionGroup.name }}</label>
  <select #options class="form-control" id="optionGroupSelect" (click)="$event.stopPropagation()" (change)="optionClicked(options.value)" [value]='selectedOption?.optionID'>
    <ng-container *ngFor="let childOption of optionGroup.options">
      <option (click)="$event.stopPropagation()" [value]="childOption.optionID">{{ childOption.name }}</option>
    </ng-container>
  </select>
</ng-template>
