import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-balance-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './home-balance-card.component.html',
    styleUrls: ['home-balance-card.component.scss'],
})
export class HomeBalanceCardComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
