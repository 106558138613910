import { Pipe, PipeTransform } from '@angular/core';
import { Reward } from '../interfaces/reward.interface';

@Pipe({
  name: 'dedupeAppliedRewards',
  // pure: true,
})
export class DedupeAppliedRewardsPipe implements PipeTransform {
  transform(rewards: Reward[]): Reward[] {
    const mergedRewardsMap = new Map<string, Reward>();

    rewards.forEach(reward => {
      // Use name or externalRef as the key for merging
      const key = reward.name + '|' + reward.externalRef;
      if (mergedRewardsMap.has(key)) {
        const existingReward = mergedRewardsMap.get(key)!;
        // Create a new object with merged quantities
        const newReward: Reward = {
          ...existingReward,
          expDate: null,
          quantity: (existingReward.quantity || 1) + (reward.quantity || 1),
        };
        mergedRewardsMap.set(key, newReward);
      } else {
        // If the reward doesn't have a quantity defined, set it to 1.
        // Creating a new object that's a copy of the original
        const newReward: Reward = {
          ...reward,
          expDate: null,
          quantity: reward.quantity || 1,
        };
        mergedRewardsMap.set(key, newReward);
      }
    });

    return Array.from(mergedRewardsMap.values());
  }
}
