import { AppCommonService } from './app-common.service';
import { CapacitorIntegrationService } from './capacitor-integration.service';
import { RadarWebIntegrationService } from './radar-web-integration.service';
import { SlugPipe } from './slug.pipe';
import { TokenManagerService } from './token-manager.service';
import { SentryService } from './sentry.service';
import { TrackingService } from './tracking.service';
import { PageService } from './page.service';

// tslint:disable-next-line:max-line-length
export const services = [AppCommonService, CapacitorIntegrationService, RadarWebIntegrationService, SlugPipe, TokenManagerService, SentryService, TrackingService, PageService];

export * from './app-common.service';
export * from './capacitor-integration.service';
export * from './radar-web-integration.service';
export * from './token-manager.service';
export * from './sentry.service';
export * from './tracking.service';
export * from './page.service';
