import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-kiosk-scroll-button',
  templateUrl: './kiosk-scroll-button.component.html',
  styleUrls: ['kiosk-scroll-button.component.scss'],
})
export class KioskScrollButtonComponent {
  @Input() element: HTMLElement;
  scrollToTop() {
    this.element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
