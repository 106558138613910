import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Option, OrderItemModifier } from '../../../interfaces/option.interface';

@Injectable()
export class ProductCustomizationService {
  private currentlySelected: Array<Option | OrderItemModifier> = [];
  private currentlySelectedSubject = new BehaviorSubject<Array<Option | OrderItemModifier>>([]);
  currentlySelected$: Observable<Array<Option | OrderItemModifier>> = this.currentlySelectedSubject.asObservable();

  clearSelections() {
    this.currentlySelected = [];
    this.updateSubject();
  }
  addToSelectedOptions(ingredient: Option | OrderItemModifier) {
    this.currentlySelected.push(ingredient);
    this.updateSubject();
  }

  removeFromSelectedOptions(ingredient: Option | OrderItemModifier) {
    this.currentlySelected = this.currentlySelected.filter(item => item.optionID !== ingredient.optionID);
    this.updateSubject();
  }

  private updateSubject() {
    this.currentlySelectedSubject.next(this.currentlySelected);
  }
}
