<div class="popup-body p-3" style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
  <div style="display: grid; width: 100%; place-items: center end">
    <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
      <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
  </div>

  <ng-container *ngIf="!isExpired">
    <div class="text-center">{{ instructionsCopy }}</div>
    <ng-container *ngIf="displayMode !== displayModes.none">
      <ng-container *ngIf="displayMode === displayModes.qr">
        <qrcode id="rewardPinQR" [qrdata]="_reward.redemptionInfo.redemptionCode.toString()" [size]="150" [level]="'M'"
                margin="0">
        </qrcode>
      </ng-container>
      <ng-container *ngIf="displayMode === displayModes.barcode">
        <ngx-barcode [bc-display-value]="false" bc-element-type="svg"
                     [bc-value]="_reward.redemptionInfo.redemptionCode"></ngx-barcode>
      </ng-container>
    </ng-container>
    <div>{{ _reward.redemptionInfo.redemptionCode }}</div>
    <div class="header-font modal-title">{{_reward.name}}</div>
    <!--          <div class="modal-text ln-1" *ngIf="_reward.redemptionInfo.redemptionMessage">{{_reward.redemptionInfo.redemptionMessage}}</div>-->
    <ng-container *ngIf="expirationDate">
      <div class="header-font modal-subtitle">Please Redeem By:</div>
      <div *ngIf="_reward" class="header-font modal-timer">{{ expirationDate }}</div>
    </ng-container>
    <div class="text-center">{{reactivationCopy}}</div>
    <div *ngIf="_reward.isDollarReward" class="instore-redemption">
      <button class="de-secondary-btn w-100" (click)="voidBankedPoints()">Return Dollars to Banked Total</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isExpired">
    <div class="expired">
      <div class="header-font modal-title">
        Reward Expired
      </div>
    </div>
  </ng-container>
</div>
