import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-confirmation',
  templateUrl: './help-confirmation.component.html',
  styleUrls: ['./help-confirmation.component.scss']
})
export class HelpConfirmationComponent {
  @Input() error;

  constructor(public activeModal: NgbActiveModal) { }

}
