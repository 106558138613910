<ng-container *ngIf="loading; else loaded">
    <div class="w-100" style="display: grid; place-items: center;">
        <div class="de-btn-loader-secondary"></div>
    </div>
</ng-container>

<ng-template #loaded>
    <ng-container *ngFor="let country of countries">
        <h2 class="text-center header-font fw-bold w-100 m-0 py-5 text-uppercase" style="font-size: 36px">{{ country.name }}</h2>
        <div id="locations-list" class="container">
            <ng-container *ngFor="let state of country.states">
                <ul>
                    <li>
                        <h3 class="header-font fw-bold">{{ state.name }}</h3>
                        <ul>
                            <ng-container *ngFor="let city of state.cities">
                                <li>{{ city }}</li>
                            </ng-container>
                        </ul>
                    </li>
                </ul>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
