import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectKeyService } from './project-key.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProxyBaseURLService {

    constructor(private keyService: ProjectKeyService) { }

    getVendorBaseURL(vendorName: string): Observable<string> {
        return this.keyService.getProjectKey().pipe(map(key => {
            return environment.domainAPI + '/' + key + '/custom/dineengine/vendor/' + vendorName;
        }));
    }

    getBaseURL() {
      return this.keyService.getProjectKey().pipe(map(key => {
        return environment.domainAPI + '/' + key + '/custom/dineengine/';
      }));
    }
}
