import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slug',
})
export class SlugPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 \-]/g, '')
      .replace(/ +/g, '-');
  }
}
