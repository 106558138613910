<div class="w-100">
  <div class="card-grid">
    <div class="card-content">
      <div class="card-title header-font fw-bold cms-card">{{balance.longName ? balance.longName : balance.name}}</div>
      <div class="card-expiry-section" *ngIf="balance.expirations && balance.expirations.length">
        <ng-container *ngFor="let expiry of balance.expirations">
          <div>
            <ng-container *ngIf="balance.expirations.length > 1">{{expiry.amount}} - </ng-container>Exp. {{expiry.expirationDate | date: 'MM/dd/yy'}}
          </div>
        </ng-container>
      </div>
      <div class="card-description" *ngIf="balance.description">{{balance.description}}</div>
      <div class="card-amount" *ngIf="balance.value"><span class="amount-value">{{balance.value}}</span> {{balance.shortName ? balance.shortName : balance.name}}</div>
    </div>
    <div class="card-image">
      <img [src]="balance.imageURL" [alt]="balance.name" *ngIf="balance.imageURL">
    </div>
  </div>
  <hr>
</div>
