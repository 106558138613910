import { Component, OnInit } from '@angular/core';
import { DirectusService } from 'src/vendors/directus/directus.service';

@Component({
  selector: 'app-subnavigation',
  templateUrl: './subnavigation.component.html',
  styleUrls: ['./subnavigation.component.scss']
})
export class SubnavigationComponent implements OnInit {
  subnavItems;

  constructor(private directus: DirectusService) { }

  ngOnInit() {
    this.subnavItems = this.directus.getSubnav();
  }

  smoothScroll(elementId: string) {
    document.querySelector(elementId).scrollIntoView({
      behavior: 'smooth'
    });
  }

  setCurrent(event) {
    this.clearUnderline(event);
    // console.log(event.target.parentElement);
    event.target.parentElement.classList.add('subnavItemCurrent');
  }

  clearUnderline(event) {
    let subnavItems = Array.from(document.getElementsByClassName('subnavItem'));
    subnavItems.forEach(subnavItem => {
      subnavItem.classList.remove('subnavItemCurrent');
    });
  }

}
