<div class="p-3 pb-5">
    <div class="d-flex justify-content-end">
        <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="activeModal.close('Close click')"></i>
    </div>
    <div class="d-flex flex-row flex-nowrap">
        <div class="grow-1 basis-0 d-sm-block d-none"></div>
        <div class="grow-3 basis-0 d-flex flex-row flex-wrap gap-3">
            <div class="d-flex justify-content-center w-100">
                <h1 class="fw-bold font-size-h1 m-0 text-center pt-4 pt-md-0">Continue to {{ handoffMode | capitalize }}?</h1>
            </div>
            <div class="w-100 d-flex justify-content-center">
                <div class="header-underline"></div>
            </div>
            <div class="w-100 d-flex flex-row flex-nowrap pb-3">
                <div class="w-100 text-center">
                    The following products are not available for <strong>{{ handoffMode | capitalize }}</strong> ordering, if you would like to continue with <strong>{{ handoffMode | capitalize }}</strong> ordering these products will be removed from the basket.
                </div>
            </div>
            <div class="w-100 pb-3">
                <ng-container *ngFor="let product of invalidProducts">
                    <div class="w-100 d-flex flex-row flex-nowrap text-center justify-content-center">
                        &#8226; {{ product }}
                    </div>
                </ng-container>
            </div>
            <div class="d-flex flex-row flex-wrap gap-3 w-100">
                <div class="text-center w-100">
                    <button class="de-primary-btn px-4" (click)="continueClicked()">
                        <span>Continue</span>
                    </button>
                </div>
                <div class="text-center w-100">
                    <a class="de-secondary-link pointer" (click)="cancelClicked()">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
        <div class="grow-1 basis-0 d-sm-block d-none"></div>
    </div>
</div>
