import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Browser} from '../models/common.enum';
import supportedBrowsers from '../../scripts/supportedBrowsers.js';
import {Capacitor} from '@capacitor/core';
import {App} from '@capacitor/app';
import {Device} from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService extends DeviceDetectorService {


  getBrowser(): Browser {
    switch (true) {
      case navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1:
        return Browser.OPERA;
      case navigator.userAgent.indexOf('Chrome') !== -1:
        return Browser.CHROME;
      case navigator.userAgent.indexOf('Safari') !== -1:
        return Browser.SAFARI;
      case navigator.userAgent.indexOf('Firefox') !== -1:
        return Browser.FIREFOX;
      case navigator.userAgent.indexOf('MSIE') !== -1 || !!(document as any).documentMode === true:
        return Browser.IE;
      default:
        return Browser.UNKNOWN;
    }
  }

  getBrowserVersion(): string {
    const userAgent = navigator.userAgent;
    let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(matchTest[1])){
      tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome'){
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = userAgent.match(/version\/(\d+)/i);
    if (tem != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest[matchTest.length - 1];
  }

  async isSupportedBrowser(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'web') {
      return supportedBrowsers.test(navigator.userAgent);
    } else {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        return !(info.iOSVersion < 13 || (info.iOSVersion > 15.0 && info.iOSVersion < 15.4));
      } else {
        return !(info.androidSDKVersion < 24);
      }
    }
  }
}
