<ng-container *ngIf="option && optionGroup">
  <label
    [class.option-disabled]="hitMaxGroupQuantity && !option.isSelected"
    [for]="
            option.nameSlug +
            (optionGroup.maxAllowed !== 1 ? '-checkbox' : '-radio')
          "
    class="ingredient-card bg-white"
    [class.radio]="!optionGroup.maxAllowed !== 1"
    [class.checkbox]="optionGroup.maxAllowed !== 1"
    (click)="$event.stopPropagation(); optionClicked(option)">
    <div class="ingredient-card-visible">
      <ng-container *ngIf="optionGroup.maxAllowed !== 1; else radio">
        <input
          [id]="option.optionID"
          [attr.data-cy]="option.name"
          class="ingredient-checkbox"
          type="checkbox"
          [name]="option.nameSlug"
          [checked]="option.isSelected"
          (click)="optionClicked(option)"
          [value]="option.name" />

        <span class="checkbox-checkmark"></span>
      </ng-container>

      <ng-template #radio>
        <input
          [id]="option.optionID"
          [attr.data-cy]="option.name"
          class="ingredient-radio"
          type="radio"
          [name]="optionGroup.nameSlug"
          [checked]="option.isSelected"
          (click)="optionClicked(option)"
          [value]="option.name" />
        <span class="radio-dot"></span>
      </ng-template>

      <ng-container *ngTemplateOutlet="ingredientInfoTop"></ng-container>
      <ng-container *ngIf="option.isSelected">
        <div class="d-flex flex-column gap-2 option-checked justify-content-center align-items-center">
          <div class="p-0 d-flex flex-nowrap align-items-center" *ngIf="allowsOptionQuantity">
            <div class="crementers align-items-center">
              <i (click)="decreaseQuantity($event)" *ngIf="option.quantity > optionGroup.minChoiceQuantity"
                 class="bi bi-dash-circle text-dark qty_selector h-100 remove d-flex align-items-center"></i>
              <i *ngIf="option.quantity <= optionGroup.minChoiceQuantity"
                 class="bi bi-slash-circle text-muted qty_selector h-100 qty-one remove d-flex align-items-center"></i>

              <div class="number bg-white d-flex align-items-center text-center justify-content-center h-100 p-2">
                {{ quantity }}
              </div>
              <i (click)="increaseQuantity($event)" *ngIf="option.quantity < (optionGroup.maxChoiceQuantity < maxChoiceQuantity ? optionGroup.maxChoiceQuantity : maxChoiceQuantity)"
                 class="bi bi-plus-circle qty_selector text-dark h-100 add d-flex align-items-center"></i>
              <i *ngIf="option.quantity >= (optionGroup.maxChoiceQuantity < maxChoiceQuantity ? optionGroup.maxChoiceQuantity : maxChoiceQuantity)" (click)="$event.stopPropagation(); $event.preventDefault();"
                 class="bi bi-slash-circle text-muted qty_selector h-100 qty-one add d-flex align-items-center"></i>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="option.thumbnailImageURL || option.standardImageURL">
        <img class="ingredient-form-image rounded" *ngIf="option.thumbnailImageURL; else standardImage" [src]="option.thumbnailImageURL" />
        <ng-template #standardImage>
          <img class="ingredient-form-image rounded" *ngIf="option.standardImageURL" [src]="option.standardImageURL" />
        </ng-template>
      </ng-container>
    </div>

    <ng-container
      *ngIf="
              option.isSelected &&
              option.optionGroups[0] &&
              (option.optionGroups[0].displayType === 'button-group' ||
                option.optionGroups[0].displayType === 'half-and-half')
            ">
      <app-child-option-selector
        class="w-100"
        [optionGroup]="option.optionGroups[0]"
        [optionSubGroup]="optionSubGroup"
        (optionClick)="optionClicked($event)"
        (subOptionClick)="
                optionClicked($event.option)
              "></app-child-option-selector>
    </ng-container>

  </label>
</ng-container>

<ng-template #ingredientInfoTop>
  <div class="ingredient-info-top">
    <div class="d-flex flex-column grow-1 basis-0">
      <div class="ingredient-title" [class.fs-5]="mode.mode === 'kiosk'">
        {{ option.name }}
      </div>
      <span *ngIf="option.addedCents > 0" style="text-overflow: unset">
        <small class="text-muted" [class.fs-6]="mode.mode === 'kiosk'">+{{ option.addedCents / 100 | currency }}</small>
      </span>
    </div>
    <ng-container
      *ngIf="
              option.optionGroups &&
              option.optionGroups.length &&
              isModifierFlow
            ">
      <a class="ingredient-edit dark-link ps-2"> Edit </a>
    </ng-container>
  </div>
</ng-template>