<nav
  class="border-bottom d-flex justify-content-center bg-white ki-nav w-100 height-fit-content zi-nav">
  <div class="container-fluid d-flex flex-row m-0 py-3 justify-content-center position-relative">
    <ng-container *ngIf="canStartOver">
      <div class="fg-1 fb-0 d-flex align-items-center flex-gap p-3 position-absolute top-0 bottom-0 start-0">
        <div class="d-none d-md-flex">
          <button
                  type="button"
                  class="btn ki-buttons outline-secondary d-none d-md-flex"
                  data-toggle="modal"
                  (click)="startOverClicked()">
            Start Over
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="canGoBack && !canStartOver">
      <div class="fg-1 fb-0 d-flex align-items-center flex-gap p-3 position-absolute top-0 bottom-0 start-0" (click)="back()">
        <div class="d-none d-md-flex">
          <button
                  type="button"
                  class="btn ki-buttons outline-secondary d-none d-md-flex">
            Back
          </button>
        </div>
      </div>
    </ng-container>

    <div
      class="fs-1 d-flex justify-content-center align-items-center mx-3 mx-md-5"
      (click)="attemptConfigModal()">
      <img
        class="ki-nav-logo"
        *ngIf="branding?.main_logo"
        [src]="branding?.main_logo.data.full_url"
        alt="logo" />
    </div>

    <div class="fg-1 fb-0 d-flex align-items-center flex-gap p-3 position-absolute top-0 end-0 bottom-0">
      <div
        *ngIf="!isStartOrderPage"
        class="fg-1 fb-0 pe-0 d-flex flex-row m-0 justify-content-end gap-3">
<!--        <div class="d-flex flex-column justify-content-center align-items-end">-->
<!--          <div class="h4 fw-bold user-name">{{ guest }}'s Order</div>-->
<!--          <div class="h5 fw-normal mb-0">-->
<!--            Subtotal:-->
<!--            {{-->
<!--              (order && order.totalCents ? order.totalCents : 0) / 100-->
<!--                | currency-->
<!--            }}-->
<!--          </div>-->
<!--        </div>-->

        <button
          [disabled]="!order?.items?.length"
          id="cartButton"
          class="btn ki-buttons px-4 align-items-center"
          type="button"
          data-toggle="modal"
          (click)="cartService.checkoutButtonClicked(); $event.stopPropagation()">
          <div class="d-flex align-items-center justify-content-center">
            <i class="bi bi-bag"></i>
          </div>
          <div class="ps-2">
            {{ productCount }}
          </div>
        </button>
      </div>
    </div>
  </div>
</nav>

<app-kiosk-timeout-modal [order]="order"></app-kiosk-timeout-modal>

<!-- Timeout modal -->
<!--<ng-template #timeoutModal let-modal>-->
<!--  <div-->
<!--    id="timeoutModal"-->
<!--    tabindex="-1"-->
<!--    aria-labelledby="timeoutModalLabel"-->
<!--    aria-hidden="true">-->
<!--    <div class="d-flex flex-row flex-nowrap justify-content-between p-3">-->
<!--      <h4 class="h4 modal-title" id="timeoutLabel" style="font-weight: bold">-->
<!--        Do you need more time?-->
<!--      </h4>-->
<!--      <button-->
<!--        type="button"-->
<!--        class="close"-->
<!--        data-dismiss="modal"-->
<!--        aria-label="Close"-->
<!--        (click)="closeModal()">-->
<!--        <svg-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--          width="32"-->
<!--          height="32"-->
<!--          fill="currentColor"-->
<!--          class="bi bi-x-circle"-->
<!--          viewBox="0 0 16 16">-->
<!--          <path-->
<!--            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />-->
<!--          <path-->
<!--            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />-->
<!--        </svg>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="h5 modal-body">-->
<!--      Your order will be canceled after-->
<!--      <span class="fw-bold" id="inactiveTimeoutCountdown">{{-->
<!--        timeLeft-->
<!--      }}</span>-->
<!--      seconds of inactivity.-->
<!--    </div>-->
<!--    <div class="d-flex flex-row flex-nowrap justify-content-end p-3">-->
<!--      <button-->
<!--        type="button"-->
<!--        class="btn ki-buttons outline-primary me-3"-->
<!--        data-dismiss="modal"-->
<!--        (click)="startOrderOver()">-->
<!--        Start Over-->
<!--      </button>-->
<!--      <button type="button" class="btn ki-buttons" (click)="closeModal()">-->
<!--        Continue-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!-- Cart modal -->
<ng-template #cartModal let-modal>
  <div
    id="cartModal"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true">
    <div class="m-0">
      <div
        class="d-flex flex-column overflow-auto"
        style="flex-grow: 1; height: 100vh">
        <div class="modal-header bg-white zi-modal">
          <h2
            class="h2 modal-title fw-bold"
            id="exampleModalLabel"
            style="font-size: 2rem">
            Your Order
          </h2>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-x-circle"
              viewBox="0 0 16 16">
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>

        <div
          class="modal-body p-0 d-flex overflow-auto flex-column"
          style="flex-grow: 1">
          <ng-container
            *ngFor="let item of order.items; index as i; trackBy: trackByFn">
            <div class="p-3 d-flex flex-row flex-nowrap gap-2">
              <div class="fs-1">
                <img
                  [src]="
                    item.thumbnailImageURL
                      ? item.thumbnailImageURL
                      : '../../../../assets/img/1x1.png'
                  "
                  [alt]="item.name"
                  class="cart-images rounded"
                  [class.border]="!!item.thumbnailImageURL" />
              </div>
              <div class="fg-1 fb-0">
                <div class="d-flex flex-row flex-nowrap">
                  <div class="h5 d-flex fg-1 fb-0 fw-bold">
                    {{ item.quantity }}x - {{ item.name }}
                  </div>
                  <div class="h5 d-flex justify-content-end fg-1 fb-0">
                    {{ item.totalCents / 100 | currency }}
                  </div>
                </div>
                <div
                  class="pb-3 h6"
                  *ngIf="item.options && item.options.length; else noOptions">
                  Options:
                  <span *ngFor="let option of item.options; index as o"
                    >{{ option.quantity }} - {{ option.name }}
                    <span *ngIf="o !== item.options.length - 1">,</span>
                  </span>
                </div>
                <ng-template #noOptions>
                  <div class="pb-3 h6">Options: None</div>
                </ng-template>
                <div class="d-flex flex-row flex-nowrap">
                  <div class="d-flex flex-row flex-wrap fg-1 fb-0">
                    <button
                      type="button"
                      (click)="incrementItemClicked(item, -1)"
                      class="btn ki-buttons outline-primary border-end-0 py-2 rounded-start align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-dash"
                        viewBox="0 0 16 16">
                        <path
                          d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                    <div
                      class="h6 h-100 de-border-y-primary px-3 py-2 d-flex align-items-center de-text-primary">
                      {{ item.quantity }}
                    </div>
                    <button
                      type="button"
                      (click)="incrementItemClicked(item, 1)"
                      class="btn ki-buttons outline-primary border-start-0 py-2 rounded-end align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-plus"
                        viewBox="0 0 16 16">
                        <path
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    class="d-flex flex-row flex-nowrap justify-content-end gap-1 fg-1 fb-0">
                    <button
                      *ngIf="item?.options?.length > 0"
                      type="button"
                      class="btn ki-buttons outline-primary align-items-center py-2"
                      (click)="editProductClicked(item)">
                      Edit
                    </button>
                    <button
                      type="button"
                      class="btn ki-buttons outline-primary align-items-center py-2"
                      (click)="removeProductClicked(item)">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 px-3" *ngIf="i !== order.items.length - 1">
              <div class="border-top"></div>
            </div>
          </ng-container>
        </div>

        <div class="modal-footer p-0 m-0 sticky-bottom-cart">
          <div class="bg-light p-3 pb-3 m-0 w-100 border-top">
            <div class="d-flex flex-row flex-wrap gap-1">
              <div class="d-flex flex-row flex-nowrap w-100">
                <div class="h6 d-flex w-100">Subtotal:</div>
                <div class="h6 d-flex justify-content-end">
                  {{ order.subTotalCents / 100 | currency }}
                </div>
              </div>
              <div class="d-flex flex-row flex-nowrap w-100">
                <div class="h6 d-flex w-100">Tax:</div>
                <div class="h6 d-flex justify-content-end">
                  {{ order.taxCents / 100 | currency }}
                </div>
              </div>
              <ng-container *ngFor="let fee of order.fees">
                <div class="d-flex flex-row flex-nowrap w-100">
                  <div class="h6 d-flex w-100">
                    {{ fee.description
                    }}<ng-container *ngIf="fee.note"
                      >&nbsp;<i
                        placement="top"
                        [ngbPopover]="fee.note"
                        [popoverTitle]="popTitle"
                        class="bi bi-info-circle pointer"></i></ng-container
                    >:
                  </div>
                  <ng-template #popTitle>
                    <b>{{ fee.description }}</b>
                  </ng-template>
                  <div class="h6 d-flex justify-content-end">
                    {{ fee.feeCents / 100 | currency }}
                  </div>
                </div>
              </ng-container>
              <div class="d-flex flex-row flex-nowrap w-100 pb-2">
                <div class="d-flex fg-1 fb-0 h5 fw-bold">Total:</div>
                <div
                  class="d-flex justify-content-end h5 w-100 fw-bold">
                  {{ order.totalCents / 100 | currency }}
                </div>
              </div>
              <div class="d-flex justify-content-end w-100">
                <button
                  type="button"
                  (click)="goToCheckoutClicked()"
                  class="btn ki-buttons px-4 w-100">
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
