import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Product } from '../../../../interfaces/product.interface';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Category } from '../../../../interfaces/category.interface';
import { SubscriptionComponent, UserRoles } from '@common/components';
import { NavigationService } from '@modules/navigation/services';
import { MenuService } from '@modules/menu/services';
import { ToastService } from '../../../../services/toast.service';
import { CartService } from '@modules/cart/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../interfaces/user.interface';

@Component({
  selector: 'app-kiosk-category-block',
  templateUrl: './kiosk-category-block.component.html',
  styleUrls: ['kiosk-category-block.component.scss'],
})
export class KioskCategoryBlockComponent extends SubscriptionComponent implements OnInit {
  @Select(state => state.menu.selectedCategory) category$: Observable<Category>;
  @Select(state => state.user.user) user$: Observable<User>;
  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;
  category: Category;
  productLoading;
  roles = UserRoles;
  constructor(
    private toast: ToastService,
    private store: Store,
    public modalService: NgbModal,
    private navigation: NavigationService,
    public menuService: MenuService,
    public cartService: CartService
  ) {
    super();
  }
  ngOnInit() {
    this.subs.push(
      this.category$.subscribe(category => {
        if (category && category.products) {
          this.category = category;
        }
      })
    );
  }
  modifyClicked(product: Product, modifyModalRef: TemplateRef<any>) {
    // isEdit if there is a basketProdID
    this.cartService.basketProdID = '';
    this.menuService.modifyClicked(product, modifyModalRef);
  }
  backClicked() {
    this.navigation.navigateToMenuPage(this.menuService.restaurantSlug ? this.menuService.restaurantSlug : this.menuService.restaurantID);
  }
  scrollToTop(element: HTMLElement) {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  productTrackBy(index: number, product: Product): string {
    return `${index}${product.name}${product.productID}`;
  }
}
