import { Component, Input, OnInit } from '@angular/core';
import { OptionGroup } from '../../../../interfaces/option-group.interface';

@Component({
  selector: 'app-option-group-info',
  templateUrl: './option-group-info.component.html',
  styleUrls: ['option-group-info.component.scss'],
})
export class OptionGroupInfoComponent implements OnInit {
  @Input() optionGroup: OptionGroup;
  @Input() optionGroups: OptionGroup[];
  constructor() {}
  ngOnInit() {}
  toSlug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 \-]/g, '')
      .replace(/ +/g, '-');
  }
}
