<ng-container *ngIf="(groupOrder$ | async) as groupOrder">
    <!--  <app-route-modal-frame title="Update Group Order" (clickModalClose)="closePage()">-->
    <div [class.bg-light]="mode.mode === 'desktop'"style="overflow-y: scroll;">
        <div class="container d-flex justify-content-center align-items-start p-0">
            <form class="update-group-container mt-0 mt-sm-5 mb-0 mb-sm-5 align-items-center" action=""
                  autocomplete="on">

                <h2 class="header-font fw-bold">
                    <!--          Group Order Instructions-->
                    Update Group Order
                </h2>

                <p class="text-center mb-2">
                    Invite others to join by sending them a shareable link or by having them scan this QR code.
                </p>

                <div class="d-flex justify-content-center">
                    <div class="group-qr-code">
                        <qrcode id="joinUrlQR" [qrdata]="joinUrl" [width]="160" [level]="'M'" margin="0"></qrcode>
                    </div>
                </div>

                <div class="d-flex flex-column align-items-center pt-3">

                    <h5 class="header-font fw-bold text-center mb-1">
                        Share Your Link
                    </h5>
                    <p class="text-center mb-3">Copy the link below and share it with the other people that you'd like to
                        join your group order.</p>

                    <div class="d-flex flex-row gap-3 justify-content-center">

                        <!--      copies link to clipboard-->
                        <button type="button" class="de-dark-btn-outline-sm" (click)="copyLink()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-files"
                                 viewBox="0 0 16 16">
                                <path
                                        d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                            </svg>
                            Copy Group Link
                        </button>
                    </div>
                </div>

                <hr class="w-100 mx-3">

                <ng-container *ngIf="mode.mode !== 'tableside'">
                    <div class="d-flex flex-column gap-2 w-100">
                        <!--      pull group order deadline that was set and display here initially-->
                        <form class="form-group" [formGroup]="deadlineForm">
                            <label for="groupOrderDeadline">Group Order Deadline (required)*</label>
                            <div class="d-flex flex-row flex-nowrap gap-2 w-100" role="group" aria-label="Group Order Deadline">
                                <button
                                        *ngFor="let time of availableTimes; let i = index"
                                        type="button"
                                        class="text-nowrap font-size-body-small flex-grow-1 flex-basis-0"
                                        [ngClass]="{
                'de-primary-btn-outline': i !== timeIndex,
                'de-primary-btn': i === timeIndex
            }"
                                        (click)="onTimeButtonClick(i)">
                                    {{ time.key }}
                                </button>
                            </div>
                        </form>

                        <small>
                            *Others must add their food by the time of the deadline.
                        </small>
                    </div>
                </ng-container>

                <ng-container *ngIf="mode.mode !== 'tableside'">
                    <!--    pull note that was set and display here-->
                    <form class="form-group w-100" [formGroup]="noteForm">
                        <label for="updateNote">Change Your Note (optional)</label>
                        <textarea type="text" class="form-control" formControlName="note" maxlength="90"
                                  id="updateNote" name="updateNote" placeholder="ex. Group order on me!"></textarea>
                    </form>
                </ng-container>


                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                    <div class="text-center fw-bold de-danger-link" id="logout-item"
                         (click)="cancelGroupOrder()">
                        Cancel Group Order
                    </div>
                    <button type="submit" class="de-primary-btn" (click)="updateGroupOrder()">
                        {{ mode.mode !== 'tableside' ? 'Save Changes' : 'Back to Menu' }}
                    </button>
                </div>


            </form>
        </div>
    </div>
    <!--  </app-route-modal-frame>-->
</ng-container>
