<div class="d-flex flex-column w-100 h-100">
  <!-- Header Section -->
  <div class="d-flex flex-row flex-nowrap justify-content-between gap-3 w-100 mb-3 px-3 px-lg-0">
    <h4 class="fw-bold header-font mb-0 d-flex flex-row align-items-center">
      <app-custom-icon
        class="color-primary me-2"
        default="bi-pin-map"
        [altText]="section?.section_title || 'Saved Addresses'">
      </app-custom-icon>
      {{ section?.section_title || 'Saved Addresses' }}
    </h4>
  </div>
  <!-- Content Section -->
  <ng-container *ngIf="userSavedAddresses$ | async as savedAddresses else noAddresses">
    <ng-container *ngIf="(savedAddresses | savedDeliveryAddressFilter).length > 0; else noAddresses">
      <div class="address-card-container w-100">
        <div class="cards-grid px-3 px-lg-0 w-100">
          <ng-container *ngFor="let address of (savedAddresses | savedDeliveryAddressFilter)">
            <div class="card address-card w-100">
              <div class="card-body">
                <div class="d-flex flex-row flex-nowrap gap-2 justify-content-between align-items-start">
                  <div class="d-flex flex-column">
                    <div>{{ address?.address.address1 }}</div>
                    <div *ngIf="address.address.address2">{{ address?.address.address2 }}</div>
                    <div>{{ address?.address.city }}, {{ address?.address.state }} {{ address?.address.zipCode }}</div>
                    <small *ngIf="address.address.specialInstructions">{{ address.address.specialInstructions}}</small>
                    <small *ngIf="address.address.specialInst">{{ address.address.specialInst}}</small>
                  </div>
                  <div class="d-flex flex-column flex-nowrap justify-content-between align-items-center gap-3">
                    <button
                      class="de-danger-btn-sm"
                      (click)="deleteLocation(address)"
                      >
                      <i class="bi bi-trash"></i>
                    </button>
                    <button *ngIf="!address.isFavorite else favoriteAddress"
                      class="de-primary-btn-sm"
                            (click)="favoriteLocation(address)"
                      >
                      <i class="bi bi-star"></i>
                    </button>
                    <ng-template #favoriteAddress>
                      <button
                        class="de-primary-btn-sm"
                        (click)="unfavoriteLocation()"
                        >
                        <i class="bi bi-star-fill"></i>
                      </button>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>


  </ng-container>
  <!-- No Addresses Template -->
  <ng-template #noAddresses>
    <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
      <app-custom-icon
        class="color-primary display-3"
        default="bi-pin-map"
        [altText]="section?.section_title || 'Saved Addresses'">
      </app-custom-icon>
      <div class="text-center d-flex flex-column">
        <h5 class="fw-bold">You currently have no saved addresses.</h5>
        <div>Place your first order today.</div>
      </div>
      <button
        class="de-primary-link w-100"
        style="max-width: 264px"
        (click)="orderNowClicked()"
       >
        Order Now
      </button>
    </div>
  </ng-template>

</div>
