import {Injectable} from '@angular/core';
import {DatacapHttpService} from './datacap-http.service';
import {Observable} from 'rxjs';
import {CreditTransactionResponse} from './interfaces/credit-transaction-response.interface';
import {CreditSalePreAuthEncryptedRequest, CreditSalePreAuthTokenRequest} from './interfaces/credit-sale-preauth-request.interface';
import {GiftCardTransactionResponse} from './interfaces/gift-card-transaction-response.interface';
import {GiftCardSaleManualEntryRequest, GiftCardSaleSwipedRequest} from './interfaces/gift-card-sale-request.interface';
import {GiftCardBalanceManualEntryRequest, GiftCardBalanceSwipedRequest} from './interfaces/gift-card-balance-request.interface';
import {CreditCard} from '../../interfaces/creditcard.interface';
import {GiftCard} from '../../interfaces/giftcard.interface';


@Injectable({
    providedIn: 'root'
})
export class DatacapApiService {
    constructor(private datacapHttp: DatacapHttpService) {}

    // CREDIT CARD

    /**
     * Sends Card Info to be Processed
     * @param {CreditCard} card - Card Details Object from Checkout
     * @param {number} subTotalCents - Subtotal in Cents
     * @param {number} tipCents - Tip Amount in Cents
     * @param {number} taxCents - Tax Total in Cents
     * @param {string} token - Token from payment form
     * @returns {CreditTransactionResponse} - Observable containing response data
     */

    creditCardSale(card: CreditCard, subTotalCents: number, tipCents: number, taxCents: number, token: string): Observable<CreditTransactionResponse> {
        const resource = '/payapi/credit/sale';
        const body = ({
            Token: token,
            Amount: String(((subTotalCents + tipCents + taxCents) / 100).toFixed(2)),
            Tip: String((tipCents / 100).toFixed(2)),
            Tax: String((taxCents / 100).toFixed(2))
        } as CreditSalePreAuthTokenRequest);
        return this.datacapHttp.post<CreditTransactionResponse>(resource, body);
    }

    /**
     * Sends Card Info for Pre-Authorization Only
     * @param {CreditCard} card - Card Details Object from Checkout
     * @param {number} subTotalCents - Subtotal in Cents
     * @param {number} tipCents - Tip Amount in Cents
     * @param {number} taxCents - Tax Total in Cents
     * @param {string} token - Token from payment form
     * @returns {CreditTransactionResponse} - Observable containing response data
     */

    creditCardPreAuth(card: CreditCard, subTotalCents: number, tipCents: number = 0, taxCents: number = 0, token: string): Observable<CreditTransactionResponse> {
        const resource = '/payapi/credit/preauth';
        const body = ({
            Token: token,
            Amount: String(((subTotalCents + taxCents) / 100).toFixed(2)),
            Tip: String((tipCents / 100).toFixed(2)),
            Tax: String((taxCents / 100).toFixed(2))
        } as CreditSalePreAuthTokenRequest);
        return this.datacapHttp.post<CreditTransactionResponse>(resource, body);
    }

    // GIFT CARDS (STORED VALUES)

    /**
     * Used to process gift card sales that are manually typed in
     * @param {GiftCard} card - gift card details
     * @param {number} totalCents - amount to be charged to the gift card (in cents)
     * @returns {GiftCardTransactionResponse}
     */

    giftCardSaleManualEntry(card: GiftCard, totalCents: number): Observable<GiftCardTransactionResponse> {
        const resource = '/storedvalue/sale';
        const body = ({
            Account: 'test',
            CVV: 'test',
            Amount: String(totalCents / 100)
        } as GiftCardSaleManualEntryRequest);
        return this.datacapHttp.post<GiftCardTransactionResponse>(resource, body);
    }

    giftCardSaleSwiped(card: GiftCard, totalCents: number): Observable<GiftCardTransactionResponse> {
        const resource = '/storedvalue/sale';
        const body = ({
            Track2: 'test',
            Amount: String(totalCents / 100)
        } as GiftCardSaleSwipedRequest);
        return this.datacapHttp.post<GiftCardTransactionResponse>(resource, body);
    }

    giftCardBalanceManualEntry(card: GiftCard): Observable<GiftCardTransactionResponse> {
        const resource = '/storedvalue/balance';
        const body = ({
            Account: 'test',
            CVV: 'test'
        } as GiftCardBalanceManualEntryRequest);
        return this.datacapHttp.post<GiftCardTransactionResponse>(resource, body)
    }

    giftCardBalanceSwiped(card: CreditCard): Observable<GiftCardTransactionResponse> {
        const resource = '/storedvalue/balance';
        const body = ({
            Track2: 'test'
        } as GiftCardBalanceSwipedRequest);
        return this.datacapHttp.post<GiftCardTransactionResponse>(resource, body)
    }
}
