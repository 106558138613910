import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {NavigationService} from '@modules/navigation/services';
import {UserService} from '../../../services/vendor-config-service/user.service';
import {DirectusService} from '../../../vendors/directus/directus.service';
import {Store} from '@ngxs/store';
import {catchError, filter, map, take} from 'rxjs/operators';
import {User} from '../../../interfaces/user.interface';
import {ModeService} from '../../../services/mode.service';

@Injectable()
export class GroupOrderGuard  {

  page;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private userService: UserService,
    private directus: DirectusService,
    private modeService: ModeService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // tslint:disable-next-line:max-line-length
    return this.store.select(state => state.user.user).pipe(filter(u => u !== null), take(1), map((user: User) => {
      if (!user.isGuest) {
        return true;
      } else {
        if (this.modeService.mode === 'tableside') {
          this.router.navigate(
            ['user', 'login'],
            { queryParams: { returnUrl: 'group-order/details' }, queryParamsHandling: 'merge' }
          );
        } else {
          this.router.navigate(
            ['user', 'login'],
            { queryParams: { returnUrl: 'group-order/start' }, queryParamsHandling: 'merge' }
          );
        }
        return false;
      }
    }), catchError(() => {
      return of(false);
    }));
  }
}
