import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Order } from '../../../../interfaces/order.interface';
import { LoyaltySection } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import {BehaviorSubject, combineLatest, mapTo, Observable, of, Subscription, timer} from 'rxjs';
import { Select } from '@ngxs/store';
import {catchError, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-orders-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './orders-section.component.html',
  styleUrls: ['orders-section.component.scss'],
})
export class OrdersSectionComponent implements OnInit, OnDestroy {
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;

  @Input() pastOrders: Order[];
  @Input() isReorderLoading: boolean[];
  @Output() cancelOrder = new EventEmitter<Order>();
  @Output() reorder = new EventEmitter<{ order: Order; index: number }>();
  @Output() viewConfirmation = new EventEmitter<Order>();
  @Output() editOrder = new EventEmitter<Order>();
  @Output() orderNow = new EventEmitter<void>();

  @Input() section: LoyaltySection;
  @Input() isDashboard = false;

  private maxOrdersSubject = new BehaviorSubject<number>(null);
  maxOrders$ = this.maxOrdersSubject.asObservable();

  private _maxOrders: number;
  @Input()
  set maxOrders(value: number) {
    this._maxOrders = value;
    this.maxOrdersSubject.next(value);
  }
  get maxOrders(): number {
    return this._maxOrders;
  }

  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  locationsRoute = this.navigation.getLocationsPageSlug();

  displayPastOrders$: Observable<Order[]>;

  private subs: Subscription[] = [];

  constructor(private navigation: NavigationService) {}
  ngOnInit() {
    this.previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
    this.locationsRoute = this.navigation.getLocationsPageSlug();

    this.displayPastOrders$ = combineLatest([this.pastOrders$, this.maxOrders$]).pipe(
      map(([pastOrders, maxOrders]) => {
        if (pastOrders && pastOrders.length > 0) {
          const sortedOrders = [...pastOrders].sort((a, b) => b.orderReadyTimestamp.getTime() - a.orderReadyTimestamp.getTime());
          if (maxOrders != null) {
            return sortedOrders.slice(0, maxOrders);
          } else {
            return sortedOrders;
          }
        } else {
          return [];
        }
      }),
      catchError(() => of([]))
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
