import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNan'
})
export class IsNanPipe implements PipeTransform {

  transform(value: number): boolean {
    return isNaN(Number(value));
  }

}
