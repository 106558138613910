<ng-container *ngIf="features$ | async as features">
  <ng-container *ngIf="features.length">
    <ng-container *ngIf="features | featuresFilter: 'news' as filteredFeatures">
      <ng-container *ngIf="filteredFeatures.length">
        <div class="container">
          <app-featured-container [items]="filteredFeatures">
          </app-featured-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
