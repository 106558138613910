import { Pipe, PipeTransform } from '@angular/core';
import { CustomPage } from '../vendors/directus/interfaces/custom-page.interface';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '../store/state.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'customPageAuthFilter',
})
export class CustomPageAuthFilterPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(pages: CustomPage[]): Observable<CustomPage[]> {
    return this.store
      .select((state: GlobalStateModel) => state.user.user)
      .pipe(
        map(user => {
          if (!user) {
            return [];
          } else {
            if (user.isGuest) {
              return pages.filter(page => ['guests', 'both'].includes(page.display_to));
            } else {
              return pages.filter(page => ['users', 'both'].includes(page.display_to));
            }
          }
        })
      );
  }
}
