import { Injectable, NgZone } from '@angular/core';
import { CustomerTrackingProvider } from '../../providers/customer-tracking-provider.interface';
import { Observable, of } from 'rxjs';
import { Order } from '../../interfaces/order.interface';
import { FlybuyApiService } from './flybuy-api.service';
import { CustomerTrackingState } from '../../interfaces/customer-tracking-state.enum';
import { LocationUpdateEventRequest, StateChangeEventRequest } from './interfaces/order-events-request.interface';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FlybuyMappingService } from './flybuy-mapping.service';
import { DECustomerLocationUpdate } from '../../interfaces/customer-location-update.interface';
import { OrderService } from '../../services/vendor-config-service/order.service';
import { OrdersRequestBase } from './interfaces/orders-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { Preferences as Storage } from '@capacitor/preferences';
import { CardDetails } from '../../interfaces/card-details.interface';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import { CustomerState } from './interfaces/flybuy-types.enum';
import { Flybuy } from '@dineengine/flybuy-capacitor';
import { PushNotifications } from '@capacitor/push-notifications';
import VendorConfig from '../config/vendor.config';

@Injectable({
  providedIn: 'root',
})
export class FlybuyProviderService implements CustomerTrackingProvider {
  providerName = VendorConfig.flybuy;
  private flybuyOrderIDKey = 'FlybuyOrderID';
  private flybuyCustomerIDKey = 'FlybuyCustomerID';

  private trackingProcessID: string;

  constructor(
    private flybuyAPI: FlybuyApiService,
    private flybuyMapping: FlybuyMappingService,
    private oService: OrderService,
    private zone: NgZone
  ) {
    PushNotifications.addListener('registration', token => {
      Flybuy.registerForPushNotifications({ token: token.value });
    });
  }

  connectOrder(order: Order, customerDetails: CardDetails): Observable<boolean> {
    if (Capacitor.getPlatform() !== 'web') {
      return from(Flybuy.getCurrentCustomer()).pipe(
        switchMap(response => {
          if (response.customer) {
            console.log('Customer already exists', response.customer);
            return from(Flybuy.getSiteByPartnerID({ id: order.location.externalRef })).pipe(
              switchMap(site => {
                if (!site || !site.site || !site.site.id) {
                  return of(false);
                }
                return from(
                  Flybuy.createOrder({
                    siteID: site.site.id,
                    partnerIdentifier: order.customerFacingID,
                    customerInfo: {
                      ...response.customer.info,
                      carType:
                        order.handoffType === HandoffType.curbside
                          ? `${customerDetails.curbsideInfo.make} ${customerDetails.curbsideInfo.model}`
                          : null,
                      carColor: order.handoffType === HandoffType.curbside ? customerDetails.curbsideInfo.color : null,
                    },
                    pickupWindow: {
                      start: order.orderReadyTimestamp,
                      end: order.orderReadyTimestamp,
                    },
                    pickupType: this.flybuyMapping.handoffToFlybuyPickupType(order.handoffType),
                  })
                ).pipe(
                  tap(orderRes => this.storeFlybuyOrderID(String(orderRes.order.id))),
                  switchMap(() => this.registerForPushNotifications()),
                  map(() => true)
                );
              })
            );
          } else {
            return from(
              Flybuy.createCustomer({
                customerInfo: {
                  name: `${customerDetails.firstName} ${customerDetails.lastName}`,
                  phone: customerDetails.phoneNumber,
                  carType:
                    order.handoffType === HandoffType.curbside
                      ? `${customerDetails.curbsideInfo.make} ${customerDetails.curbsideInfo.model}`
                      : null,
                  carColor: order.handoffType === HandoffType.curbside ? customerDetails.curbsideInfo.color : null,
                },
                termsOfService: true,
                ageVerification: true,
              })
            ).pipe(
              switchMap(customerRes => {
                return this.flybuyAPI.getSiteByPartnerIdentifier(order.location.externalRef).pipe(
                  switchMap(site => {
                    if (!site || !site.id) {
                      return of(false);
                    }
                    return from(
                      Flybuy.createOrder({
                        siteID: site.id,
                        partnerIdentifier: order.customerFacingID,
                        customerInfo: customerRes.customer.info,
                        pickupWindow: {
                          start: order.orderReadyTimestamp,
                          end: order.orderReadyTimestamp,
                        },
                        pickupType: this.flybuyMapping.handoffToFlybuyPickupType(order.handoffType),
                      })
                    ).pipe(
                      tap(orderRes => this.storeFlybuyOrderID(String(orderRes.order.id))),
                      switchMap(() => this.registerForPushNotifications()),
                      map(() => true)
                    );
                  })
                );
              })
            );
          }
        })
      );

      // return this.getFlybuyCustomerID().pipe(switchMap(customerID => {
      //     if (customerID) {
      //         return this.flybuyAPI.getCustomerDetails(Number(customerID)).pipe(switchMap(fbCustomer => {
      //
      //           return this.flybuyAPI.getSiteByPartnerIdentifier(order.location.externalRef).pipe(switchMap(fbSite => {
      //             const createOrder: OrdersRequestBase = {
      //               data: {
      //                 site_id: fbSite.id,
      //                 partner_identifier: order.customerFacingID,
      //                 customer_token: fbCustomer.data.api_token,
      //                 pickup_window: order.orderReadyTimestamp.toISOString(),
      //                 pickup_type: this.flybuyMapping.handoffToFlybuyPickupType(order.handoffType)
      //               }
      //             };
      //             return this.flybuyAPI.createAnOrder(createOrder).pipe(map(fbOrder => {
      //               this.storeFlybuyOrderID('id' in fbOrder.data ? String(fbOrder.data.id) : String(fbOrder.data[0].id));
      //               return true;
      //             }));
      //           }));
      //
      //         }));
      //     } else {
      //         const customer: CreateCustomerRequest = {
      //           name: `${customerDetails.firstName} ${customerDetails.lastName}`,
      //           phone: customerDetails.phoneNumber,
      //           car_color: order.handoffType === HandoffType.curbside ? customerDetails.curbsideInfo.color : null,
      //           car_type: order.handoffType === HandoffType.curbside ? `${customerDetails.curbsideInfo.make} ${customerDetails.curbsideInfo.model}` : null,
      //           terms_of_service: true,
      //           age_verification: true
      //         };
      //         return this.flybuyAPI.createCustomer(customer).pipe(switchMap(fbCustomer => {
      //           return this.storeFlybuyCustomerID(fbCustomer.data.id).pipe(switchMap(() => {
      //             return this.flybuyAPI.getSiteByPartnerIdentifier(order.location.externalRef).pipe(switchMap(fbSite => {
      //               const createOrder: OrdersRequestBase = {
      //                 data: {
      //                   site_id: fbSite.id,
      //                   partner_identifier: order.customerFacingID,
      //                   customer_token: fbCustomer.data.api_token,
      //                   pickup_window: order.orderReadyTimestamp.toISOString(),
      //                   pickup_type: this.flybuyMapping.handoffToFlybuyPickupType(order.handoffType)
      //                 }
      //               };
      //               return this.flybuyAPI.createAnOrder(createOrder).pipe(map(fbOrder => {
      //                 this.storeFlybuyOrderID('id' in fbOrder.data ? String(fbOrder.data.id) : String(fbOrder.data[0].id));
      //                 return true;
      //               }));
      //             }));
      //           }));
      //         }));
      //
      //     }
      //
      // }), catchError(err => {
      //   return from(Dialog.alert({title: 'Error', message: JSON.stringify(err)})).pipe(map(() => false));
      // }));
    } else {
      return this.flybuyAPI.getSiteByPartnerIdentifier(order.location.externalRef).pipe(
        switchMap(fbSite => {
          const createOrder: OrdersRequestBase = {
            data: {
              site_id: fbSite.id,
              partner_identifier: order.customerFacingID,
              customer_name: `${customerDetails.firstName} ${customerDetails.lastName}`,
              customer_phone: customerDetails.phoneNumber,
              customer_car_color: order.handoffType === HandoffType.curbside ? customerDetails.curbsideInfo.color : null,
              customer_car_type:
                order.handoffType === HandoffType.curbside
                  ? `${customerDetails.curbsideInfo.make} ${customerDetails.curbsideInfo.model}`
                  : null,
              pickup_type: this.flybuyMapping.handoffToFlybuyPickupType(order.handoffType),
              pickup_window: order.orderReadyTimestamp.toISOString(),
            },
          };
          return this.flybuyAPI.createAnOrder(createOrder).pipe(
            map(fbOrder => {
              this.storeFlybuyOrderID('id' in fbOrder.data ? String(fbOrder.data.id) : String(fbOrder.data[0].id));
              return true;
            })
          );
        })
      );
    }
  }
  startOrderTracking(): Observable<string> {
    return from(
      Flybuy.updateCustomerState({
        orderID: Number(this.getFlybuyOrderID()),
        customerState: CustomerState.EN_ROUTE,
      })
    ).pipe(map(order => String(order.order.id)));

    // if (Capacitor.getPlatform() !== 'web') {
    //   return from(BackgroundGeolocation.addWatcher({
    //     backgroundMessage: 'We track your location to let the store know when you are nearby',
    //     backgroundTitle: 'Order Tracking',
    //     requestPermissions: true,
    //     stale: false,
    //     distanceFilter: 10,
    //   }, (position, error) => {
    //     if (error) {
    //       if (error.code === 'NOT_AUTHORIZED') {
    //         Dialog.confirm({
    //           title: 'Location Required',
    //           message: 'This app needs your location, but does not have permission.\n\nOpen Settings now?',
    //           okButtonTitle: 'Yes',
    //           cancelButtonTitle: 'No'
    //         }).then(result => {
    //           if (result.value) {
    //             BackgroundGeolocation.openSettings();
    //           } else {
    //             Dialog.alert({title: 'Location Error', message: 'Permissions not granted'});
    //           }
    //         });
    //       } else {
    //         Dialog.alert({title: 'Error', message: error.message});
    //       }
    //     } else {
    //       this.updateCustomerLocation(CustomerTrackingState.IN_TRANSIT, this.flybuyMapping.capPositionToDELocationUpdate(position));
    //
    //     }
    //   } )).pipe(tap(id => this.trackingProcessID = id));
    // } else {
    //   return;
    // }
  }

  // startOrderTracking(): Observable<string> {
  //   return from(Dialog.alert({title: 'Checking Platfrom', message: Capacitor.getPlatform()})).pipe(switchMap(() => {
  //     if (Capacitor.getPlatform() != 'web') {
  //       return from(Dialog.alert({title: 'Starting Background', message: 'Let\'s see if this works'})).pipe(switchMap(() => {
  //         return from(BackgroundGeolocation.addWatcher({
  //           backgroundMessage: 'We track your location to let the store know when you are nearby',
  //           backgroundTitle: 'Order Tracking',
  //           requestPermissions: true,
  //           stale: false,
  //           distanceFilter: 10,
  //         }, (position, error) => {
  //           if (error) {
  //             if (error.code === 'NOT_AUTHORIZED') {
  //               Dialog.confirm({
  //                 title: 'Location Required',
  //                 message: (
  //                   'This app needs your location, ' +
  //                   'but does not have permission.\n\n' +
  //                   'Open settings now?'
  //                 ),
  //               }).then(({value}) => {
  //                 if (value) {
  //                   BackgroundGeolocation.openSettings()
  //                 } else {
  //                   Dialog.alert({title: 'Location Error', message: 'Permissions not granted'})
  //                 }
  //               })
  //             }
  //           } else {
  //             this.updateCustomerLocation(CustomerTrackingState.IN_TRANSIT, this.flybuyMapping.capPositionToDELocationUpdate(position))
  //           }
  //         }).pipe(tap(id => this.trackingProcessID = id), map(id => id))
  //     } else {
  //       return of('');
  //     }
  //   }))
  //
  //
  // }

  updateCustomerLocation(state: CustomerTrackingState, locationUpdate: DECustomerLocationUpdate) {
    const orderID = this.getFlybuyOrderID();
    this.flybuyAPI.getOrderByFlybuyIdentifier(Number(orderID)).subscribe(fbOrder => {
      if ('id' in fbOrder.data && fbOrder.data.customer_state === CustomerState.COMPLETED) {
        this.stopTracking();
      } else {
        const locationUpdateEvent: LocationUpdateEventRequest = {
          order_id: Number(orderID),
          event_type: 'location_update',
          longitude: locationUpdate.longitude,
          latitude: locationUpdate.latitude,
          accuracy: locationUpdate.accuracy,
          speed: locationUpdate.speed,
        };
        this.flybuyAPI.sendLocationUpdateEvent(locationUpdateEvent).subscribe(
          () => {},
          (err: HttpErrorResponse) => {
            if (err.status === 410) {
              this.stopTracking();
            }
          }
        );
      }
    });
  }

  notifyOfArrival(orderGuid: string | number, extraMessage: string): Observable<Order> {
    const orderID = this.getFlybuyOrderID();
    const stateUpdate: StateChangeEventRequest = {
      order_id: Number(orderID),
      customer_state: CustomerState.ARRIVED,
      event_type: 'state_change',
      eta_seconds: 0,
    };
    return this.flybuyAPI.sendStateChangeEvent(stateUpdate).pipe(
      switchMap(() => {
        return this.oService.getService().pipe(
          switchMap(oService => {
            return oService.getCurrentOrder(null);
          })
        );
      })
    );
  }

  stopTracking() {
    if (Capacitor.getPlatform() === 'web') {
      navigator.geolocation.clearWatch(Number(this.trackingProcessID));
    }
    this.deleteFlybuyOrderID();
  }

  private storeFlybuyOrderID(id: string): void {
    sessionStorage.setItem(this.flybuyOrderIDKey, id);
  }

  private getFlybuyOrderID(): string {
    return sessionStorage.getItem(this.flybuyOrderIDKey);
  }

  private deleteFlybuyOrderID(): void {
    sessionStorage.removeItem(this.flybuyOrderIDKey);
  }

  private storeFlybuyCustomerID(customerID: number): Observable<void> {
    return from(Storage.set({ key: this.flybuyCustomerIDKey, value: JSON.stringify(customerID) }));
  }

  private getFlybuyCustomerID(): Observable<string | null> {
    return from(Storage.get({ key: this.flybuyCustomerIDKey })).pipe(
      map(data => {
        return data.value;
      })
    );
  }

  private registerForPushNotifications(): Observable<void> {
    return from(PushNotifications.requestPermissions()).pipe(
      switchMap(result => {
        if (result.receive === 'granted') {
          return from(PushNotifications.register());
        } else {
          return of<void>();
        }
      })
    );
  }
}
