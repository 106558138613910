import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { MobileService } from 'src/services/mobile.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallServerComponent } from '@modules/app-common/components/call-server/call-server.component';
import { HelpConfirmationComponent } from '@modules/app-common/components/help-confirmation/help-confirmation.component';
import {NavigationService} from '@modules/navigation/services';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import {Location} from '@angular/common';
import {VendorSetup} from '../../../../../interfaces/vendor.interface';

@Component({
  selector: 'app-tableside-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class TablesideHeaderBarComponent {

  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.user.user) user$: Observable<User>;

  @Input() title = 'back';
  @Input() routeBack: string;
  @Input() user: User = null;
  @Input() order: Order;

  constructor(
    public mobile: MobileService,
    private navCtrl: NavController,
    public router: Router,
    private navigation: NavigationService,
    private modalController: ModalController,
    private modalService: NgbModal,
    private location: Location
  ) {}

  backButton() {
    this.location.back();
  }

  openHelpModal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(CallServerComponent, {centered: true, animation: true});
    modalRef.componentInstance.locationID = this.order.location.locationID;
    modalRef.componentInstance.submitted.subscribe(() => {
      this.modalService.dismissAll();
      this.modalService.open(HelpConfirmationComponent, {centered: true, animation: true});
    });
  }

  goToLogin() {
    if (this.user.isGuest) {
      this.router.navigate(
        ['user', 'login'],
        { queryParams: { returnUrl: '/' }, queryParamsHandling: 'merge' }
      );
    } else {
      this.navigation.navigateToProfilePage();
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
