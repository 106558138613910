/// /node_modules/@types/googlemaps/index.d.ts"/>
import {Injectable} from '@angular/core';
import {Address} from '../../../interfaces/address.interface';
import {AddressSearch} from '@modules/locations/models/locations.address-search';
import {ChepriOsmService} from '@modules/locations/services/chepri-osm.service';
import {environment} from '../../../environments/environment';
import {GoogleProvider, OpenStreetMapProvider} from 'leaflet-geosearch';
import {Location as DineEngineLocation} from '../../../interfaces/location.interface';
import {GeocodingService} from '@modules/locations/services/geocoding.service';
import {ICoordinates} from '@modules/locations/models/coordinates.interface';
import {produce} from 'immer';

@Injectable({
  providedIn: 'root',
})

export class LocationSearchService {

  place: any; // This should be a 'place result' object probably
  private fullAddress: AddressSearch;
  private distances: any;

  private osmProvider = new OpenStreetMapProvider({
    params: {
      countrycodes: 'us', // limit search results to the US
      addressdetails: 1, // include additional address detail parts
      dedupe: 1,
      limit: 10
    },
  });

  private chepriProvider = new ChepriOsmService({
    params: {
      countrycodes: 'us', // limit search results to the US
      addressdetails: 1, // include additional address detail parts
      dedupe: 1,
      limit: 10
    },
  });

  private googleGeoProvider = new GoogleProvider({
    params: {
      key: environment.API_KEY_FOR_BROWSER_RELEASE,
      region: 'us',
    }
  });

  constructor(private geo: GeocodingService) {}

  addressResultToAddressSearch(provider: string, result: any) {
    switch (provider) {
      case 'google-geo':
        return this.googleGeoPlaceToAddressSearch(result.raw);
      case 'osm':
        return this.osmPlaceToAddressSearch(result);
      case 'chepri':
        return this.osmPlaceToAddressSearch(result);
    }
  }

  googlePlaceToAddressSearch(place, searchedAddress: string): AddressSearch {
    const streetNumber = place.address_components.find((component) => {
      return component.types.includes('street_number');
    });
    const routeName = place.address_components.find((component) => {
      return component.types.includes('route');
    });
    let localityName = place.address_components.find((component) => {
      return component.types.includes('locality');
    });
    if (typeof localityName === 'undefined') {
      localityName = place.address_components.find((component) => {
        return component.types.includes('political');
      });
    }
    const countryName = place.address_components.find((component) => {
      return (component.types.includes('administrative_area_level_1') ||
          component.types.includes('country'));
    });
    const zipNumber = place.address_components.find((component) => {
      return component.types.includes('postal_code');
    });
    const address: Address = {
      address1: streetNumber && routeName ?
          streetNumber.long_name + ' ' + routeName.long_name : searchedAddress.slice(0, searchedAddress.indexOf(',')),
      address2: null,
      city: localityName ? localityName.long_name : null,
      state: countryName ? countryName.long_name : null,
      zipCode: zipNumber ? zipNumber.long_name.replace(' ', '') : null,
      latitude: place.geometry?.location ? place.geometry.location.lat() : null,
      longitude: place.geometry?.location ? place.geometry.location.lng() : null,
    };
    return {
      formattedAddress: place.formatted_address,
      addressComponents: address
    };
  }

  googleGeoPlaceToAddressSearch(place): AddressSearch {
    const streetNumber = place.address_components.find((component) => {
      return component.types.includes('street_number');
    });
    const routeName = place.address_components.find((component) => {
      return component.types.includes('route');
    });
    const localityName = place.address_components.find((component) => {
      return component.types.includes('locality');
    });
    const countryName = place.address_components.find((component) => {
      return (component.types.includes('administrative_area_level_1') ||
          component.types.includes('country'));
    });
    const zipNumber = place.address_components.find((component) => {
      return component.types.includes('postal_code');
    });
    const address: Address = {
      address1: streetNumber && routeName ?
          streetNumber.long_name + ' ' + routeName.long_name :
          (routeName ? routeName.long_name : null),
      address2: null,
      city: localityName ? localityName.long_name : null,
      state: countryName ? countryName.long_name : null,
      zipCode: zipNumber ? zipNumber.long_name.replace(' ', '') : null,
      latitude: place.geometry?.location ? place.geometry.location.lat : null,
      longitude: place.geometry?.location ? place.geometry.location.lng : null,
    };
    return {
      formattedAddress: place.formatted_address,
      addressComponents: address
    };
  }

  osmPlaceToAddressSearch(result: any): AddressSearch {
    return ({
      addressComponents: {
        address1: result.raw.address.house_number && result.raw.address.road ?
            `${result.raw.address.house_number} ${result.raw.address.road}` :
            null,
        address2: null,
        city: result.raw.address?.city ?
            result.raw.address?.city :
            result.raw.address?.town ?
                result.raw.address?.town :
                // tslint:disable-next-line:max-line-length
                result.raw.address?.village ? result.raw.address?.village : result.raw.address?.municipality ? result.raw.address?.municipality : result.raw.address?.county ? result.raw.address?.county : null,
        state: result.raw.address.state ? result.raw.address.state : null,
        zipCode: result.raw.address.postcode ?
            result.raw.address.postcode.replace(' ', '') :
            null,
        latitude: result.y,
        longitude: result.x,
      },
      formattedAddress: result.label,
    } as AddressSearch);
  }

  validateAddress(address: AddressSearch) {
    return (address.addressComponents.latitude && address.addressComponents.longitude);
  }

  // 7879 Malton Ln, Columbus, OH 43085, USA

  // googlePlaceToAddress(): Address {
  //     const streetNumber = this.findPlaceComponent('street_number');
  //     const routeName = this.findPlaceComponent('route');
  //     const localityName = this.findPlaceComponent('locality');
  //     const countryName = this.findPlaceComponent('administrative_area_level_1') || this.findPlaceComponent('country');
  //     const zipNumber = this.findPlaceComponent('postal_code');
  //     const address: Address = {
  //       address1: streetNumber && routeName ? streetNumber.long_name + ' ' + routeName.long_name : null,
  //       address2: null,
  //       city: localityName ? localityName.long_name : null,
  //       state: countryName ? countryName.long_name : null,
  //       zipCode: zipNumber ? zipNumber.long_name : null,
  //       latitude: null,
  //       longitude: null
  //     };
  //     return address;
  // }

  getDistances(locations: DineEngineLocation[]) {
    this.distances = [];
    for (let i = 0; i < locations.length; i++) {
      const dis = parseInt(sessionStorage.getItem(locations[i].locationID), 10);
      const id = locations[i].locationID;
      if (!this.distances.includes(id)) {
        this.distances.push({ id, dis });
      }
    }
  }

  clearDistances(locations: DineEngineLocation[]) {
    this.distances = [];
    for (let i = 0; i < locations.length; i++) {
      sessionStorage.removeItem(locations[i].locationID);
    }
  }

  storeLocationDistances(locations: DineEngineLocation[], userCoordinates: ICoordinates) {
    locations.map((location: DineEngineLocation) => {
      const dis = this.geo.findDistance(
          location.address.latitude,
          location.address.longitude,
          userCoordinates.latitude,
          userCoordinates.longitude
      );
      const disInt = this.geo.precisionRound(dis, 1);
      const distance = disInt < 9999 ? disInt : 0;
      sessionStorage.setItem(location.locationID, String(distance));
    });
  }

  sortIndividualLocations(locations: DineEngineLocation[]): DineEngineLocation[] {
    return produce(locations, draft => {
      draft.sort((a: any, b: any) => {
        const a_dis = parseInt(sessionStorage.getItem(a.locationID), 10);
        const b_dis = parseInt(sessionStorage.getItem(b.locationID), 10);
        if (isFinite(a_dis - b_dis)) {
          return a_dis - b_dis;
        } else {
          return isFinite(a_dis) ? -1 : 1;
        }
      });
    })
  }

  findPlaceComponent(key: string): any { // This should be a google place component
    return this.place.address_components.find((component) => {
      return component.types.includes(key);
    });
  }

  get getFullAddress(): AddressSearch {
    return this.fullAddress;
  }

  scoreSearchString(searchText: string, location: DineEngineLocation): number {
    let score = 0;
    const search = searchText.toLowerCase();
    const name = location.name.toLowerCase();
    const address = location.address.address1.toLowerCase();
    const city = location.address.city.toLowerCase();
    const state = location.address.state.toLowerCase();
    const zip = location.address.zipCode.toLowerCase();
    if (name.includes(search)) { score++; }
    if (address.includes(search)) { score++; }
    if (city.includes(search)) { score++; }
    if (state.includes(search)) { score++; }
    if (zip.includes(search)) { score++; }
    return score;
  }

  getProvider(provider: string) {
    switch (provider) {
      case 'chepri':
        return this.chepriProvider;
      case 'osm':
        return this.osmProvider;
      case 'google-geo':
        return this.googleGeoProvider;
    }
  }

}
