import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { TransferStoredValueToLoyaltyAccount } from '../../../../store/actions/user.actions';
import { Reward } from 'src/interfaces/reward.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { GiftCard } from 'src/interfaces/giftcard.interface';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-gift-balance-transfer-section',
  templateUrl: './gift-balance-transfer-section.component.html',
  styleUrls: ['./gift-balance-transfer-section.component.scss'],
})
export class GiftBalanceTransferSectionComponent implements OnInit {
  @Select(state => state.user.allowsTransferToLoyalty) allowsTransfer$: Observable<boolean>;
  @Select(state => state.user.rewards) rewards$: Observable<Reward[]>;
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;

  cardNumberForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(null, [Validators.required]),
    pinNumber: new UntypedFormControl(null, [Validators.required]),
  });

  isTransferLoading = false;
  transferBalanceCopy = 'Transfer Gift Card';
  errorMessage: string;
  successMessage: string;

  constructor(
    private store: Store,
    private toast: ToastService
  ) {}

  ngOnInit() {}

  startTransfer(): void {
    if (this.isTransferLoading) {
      return;
    }
    if (this.cardNumberForm.valid) {
      const card: GiftCard = {
        cardNumber: this.cardNumber.value,
        cardPin: this.pinNumber.value,
        balance: null,
      };
      this.isTransferLoading = true;
      this.store.dispatch(new TransferStoredValueToLoyaltyAccount(card)).subscribe(
        () => {
          this.toast.success('Balance Transferred');
          this.successMessage = 'Balance Transfer Successful';
          this.cardNumberForm.reset();
          this.isTransferLoading = false;
          setTimeout(() => {
            this.successMessage = null;
          }, 5000);
        },
        error => {
          this.isTransferLoading = false;
          this.errorMessage = error.message;
          this.toast.danger(this.errorMessage);
        }
      );
    } else {
      this.errorMessage = 'Please enter a valid card number and pin';
      this.toast.danger(this.errorMessage);
    }
  }

  get cardNumber() {
    return this.cardNumberForm.get('cardNumber');
  }

  get pinNumber() {
    return this.cardNumberForm.get('pinNumber');
  }
}
