<ng-container *ngIf="allowsTransfer$ | async">
  <ng-container *ngIf="rewards$ | async as rewards">
    <div class="rewards-category-item">
      <div class="w-100 h-100">
        <div class="d-flex flex-column gap-3 w-100 h-100">
          <div
            class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-start"
            style="min-height: 60px"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-row flex-nowrap gap-2">
                <h4 class="text-start fw-bold header-font">
                  <i class="bi bi-wallet2 color-primary me-2"></i>
                  {{ transferBalanceCopy }}
                </h4>
              </div>
              <ng-container *ngIf="rewardPoints$ | async as balances">
                <div class="fw-bold">
                  Current Balance:
                  {{
                    balances.storedValueCents / 100 | currency: (currencyCode$ | async)
                  }}
                </div>
              </ng-container>
            </div>
            <div class="ms-auto">
              <button
                type="button"
                class="pill-btn-default pill-btn-sm text-nowrap"
                routerLink="/rewards/balance-reload"
              >
                Add Funds
              </button>
            </div>
          </div>

          <div
            class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center"
          >
            <div class="font-size-body-regular">
              Transfer your eGift or physical gift card below for use in-store
              and online. By transferring your gift card(s), your physical/eGift
              card balance will be $0.00 and all funds will be available on your
              account.
            </div>
          </div>
          <form class="w-100" [formGroup]="cardNumberForm">
            <div class="d-flex flex-row flex-wrap gap-2">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  style="height: unset;"
                  placeholder="Card Number"
                  aria-label="Card Number"
                  aria-describedby="transfer-button"
                  formControlName="cardNumber"
                />
              </div>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control"
                  style="min-width: 200px; height: unset;"
                  placeholder="Pin Number"
                  aria-label="Pin Number"
                  aria-describedby="transfer-button"
                  formControlName="pinNumber"
                />
                <div class="input-group-append">
                  <button
                    class="btn de-primary-btn text-nowrap"
                    style="width: 106px"
                    type="button"
                    id="transfer-button"
                    (click)="startTransfer()"
                  >
                    <ng-container *ngIf="!isTransferLoading; else loading">
                      Transfer
                    </ng-container>
                    <ng-template #loading>
                      <div class="de-btn-loader"></div>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <ng-container *ngIf="errorMessage">
            <div class="alert alert-danger text-center m-0">
              {{ errorMessage }}
            </div>
          </ng-container>
          <ng-container *ngIf="successMessage">
            <div class="alert alert-success text-center m-0">
              {{ successMessage }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
