import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'selectedOptions' })
export class SelectedOptionsPipe implements PipeTransform {
  transform(input: any, displayOptionGroups: any) {
    if (displayOptionGroups) {
      return displayOptionGroups.some(g => g.options.some(o => o.isSelected));
    } else {
      return false;
    }
  }
}
