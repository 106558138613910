import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {SetRouteBack, UpdateTitle} from '../../../store/actions/app.actions';
import {MetaService} from '../../../services/meta.service';
import {Store} from '@ngxs/store';
import {TrackingService} from '@common/services/tracking.service';

@Injectable()
export class PageService {
  constructor(
    private meta: MetaService,
    private store: Store,
    private tracking: TrackingService
  ) {}

  getPage$(): Observable<{}> {
      return of({});
  }

  initialization(seoName: string, backRoute: string) {
    // SEO
    this.meta.manualUpdate({
      title: seoName,
      description: null,
      keywords: null
    });
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.store.dispatch(new UpdateTitle(seoName));

    // UX
    this.store.dispatch(new SetRouteBack(backRoute));

    // User Tracking
    this.tracking.pageInitialization(seoName);
  }
}
