import { Pipe, PipeTransform } from '@angular/core';
import { Features } from '../vendors/directus/interfaces/features.interface';

@Pipe({
  name: 'featuresFilter',
})
export class FeaturesFilterPipe implements PipeTransform {
  transform(features: Features[], section: 'deals' | 'news' | 'featured'): Features[] {
    return features.filter(feature => feature.section === section);
  }
}
