/* Error handling service
HTTP status 200 indicates success. Theoretically, no 200 messages should ever hit this script.
HTTP status 400 indicates a bad request. Olo will use this for most API request errors. In this case, it is usually something the developer
or user can address. Olo will usually return a error code and customer-facing error message for a 400 error.
HTTP status 401 is a subset of 403 errors, just for login issues. Olo uses this for a failed login (and most likely for other cases too).
HTTP status 403 means the server is refusing action because of some missing or incorrect info, such as a problem with the API key used.
HTTP status 404 is, of course, not found. This may indicate that either the client or server is offline, or the link is incorrect.
HTTP status 500 is SUPPOSED to be an internal server error, but Novadine uses 500 for client errors, like how Olo correctly uses 400 for the
same. Novadine tends not to return user-friendly messages, or even error codes, so we need to use default error messages.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DineEngineError } from '../interfaces/dineengine-error.interface';

@Injectable()
export class ErrorService {
  constructor() {}

  /**
   * Attempt to get provider information based on the format of the message.
   * @param er: HttpErrorResponse object to be examined.
   */
  getProvider(er: HttpErrorResponse) {
    let provider;
    if (er && er.error && er.error.error) {
      provider = 'novadine';
    } else if (er && er.error && er.error.num && er.error.message) {
      provider = 'olo';
    } else if (er && er.error && er.error.conflictingFields) {
      provider = 'paytronix';
    } else {
      provider = 'other';
    }
    return provider;
  }

  loginError(er: HttpErrorResponse): string {
    const provider = this.getProvider(er);
    let erString = 'Error logging in. Please check your username and password and try again. If the issue persists, please contact us.'; // Default page error in lieu of more specific message.
    if (provider === 'olo' && er.status === 400) {
      erString = er.error.message;
    }

    if (er.status === 401) {
      // 401 means authentication error.
      erString = 'The username and/or password entered is incorrect.';
    } else if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach login server. Please try again momentarily.';
    }

    return erString;
  }

  registerError(er: HttpErrorResponse): string {
    console.log(er);
    const provider = this.getProvider(er);
    let erString =
      'Error creating an account. There may already be an account with this information. In that case, try logging in. If the issue persists, please contact us.'; // Default page error in lieu of more specific message.
    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }
    if (provider === 'paytronix') {
      if (er.error.result === 'uniquenessConflict') {
        erString =
          'Error creating an account, there is already an account with this information. The following are conflicting with an existing account: ';
        er.error.conflictingFields.forEach((field: string, index: number) => {
          console.log(field);
          if (field === 'mobilePhone') {
            erString = erString.concat('phone number');
          }
          if (field === 'email') {
            erString = erString.concat('email address');
          }
          if (er.error.conflictingFields.length - 1 > index) {
            erString = erString.concat(', ');
          }
        });
      }
    }
    if (er.status === 403 && er.error.code === 6) {
      // Olo-specific. The API key does not support registration. Which is bad.
      erString = 'Sorry, we are not able to accept new registrations at this time. Please order as a guest.';
    } else if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach login server. Please try again momentarily.';
    } else if (er.status === 422 && provider === 'other') {
      // Punchh uses this code for username/password already taken.
      erString = 'An account with this info already exists. Either sign in instead, or else use different info.';
    }
    if (er.message?.includes('Please select a location to see your rewards')) {
      erString = '';
    }
    if (er instanceof DineEngineError) {
      erString = er.message;
    }
    return erString;
  }

  checkoutError(er: HttpErrorResponse): string {
    const provider = this.getProvider(er);
    let erString =
      'There was an error submitting your order. Please check your details and try again. If the issue persists, please contact us.'; // Default page error in lieu of more specific message.
    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    if (
      er.message &&
      (er.message === 'A duplicate transaction has been submitted.' || er.message === 'This transaction has been declined.')
    ) {
      erString =
        'There was an error submitting your order, your card may have been declined or the information submitted could be incorrect. Please check your details and try again. If the issue persists, please contact the store.';
    }

    return erString.concat(' ', String(er.status));
  }

  rewardApplyError(er: HttpErrorResponse): string {
    const provider = this.getProvider(er);
    let erString = 'There was an error applying your reward. Please wait a minute and try again. If the issue persists please contact us.'; // Default page error in lieu of more specific message.
    if (provider === 'olo') {
      if (er.error.message) {
        erString = er.error.message;
      }
      if (er.error.num === 218) {
        erString = 'The store is currently is currently closed, please select a time for your order before continuing.';
      }
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    return erString;
  }

  productError(er: HttpErrorResponse): string {
    const provider = this.getProvider(er);
    let erString =
      typeof er === 'string' ? er : 'There was an error loading this product. Please try again. If the issue persists please contact us.'; // Default page error in lieu of more specific message.
    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    return erString;
  }

  locationRewardsError(er: HttpErrorResponse): string {
    console.log(er.message);

    const provider = this.getProvider(er);
    let erString = 'Please select a location to see your rewards'; // Default page error in lieu of more specific message.
    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    if (er.message === 'Please select a location to see your rewards.') {
      // Not found, may be offline. Any vendor.
      erString = 'Please select a location to see your rewards.';
    }

    return erString;
  }

  // Olo supplies nice error codes that we can base programming on
  /* diagnoseOloMessage(er: HttpErrorResponse): string {
        let erString: string = er.error.message;
        if (er.error.num === 200 || er.error.num === 201 || er.error.num === 208 || er.error.num === 211 || er.error.num === 212 || er.error.num === 216 || er.error.num === 219) {
            // These error codes return messages with instructions to the user.
            erString = er.message;
        } else if (er.error.num === 203) {
            erString = 'The payment has been declined. Please choose a new payment method.';
        } else if (er.error.num === 204) {
            erString = 'The billing address verification has failed. Please make sure your address is correct, or choose a new payment method.';
        } else if (er.error.num === 205) {
            erString = 'CCV verification has failed. Please make sure the CCV given is correct, or choose a new payment method.';
        } else if (er.error.num === 206) {
            // TODO: Consider developer automation of order ready time adjustment
            erString = 'The order cannot be prepared for the specified time. Please choose a later time.';
        } else if (er.error.num === 207) {
            erString = 'Unsupported version. Please refresh if on a web browser, or update your app if on mobile.';
        } else if (er.error.num === 209) {
            // TODO: Consider redirection to confirmation, because why is the user still on the order screen?
            erString = 'This order has already been submitted.';
        } else if (er.error.num === 210) {
            // TODO: Consider developer automation of order ready time adjustment
            erString = 'The order cannot be prepared for the specified time as the restaurant has reached capacity. Please choose a different time.';
        } else if (er.error.num === 213) {
            erString = 'An error occurred and your order could not be sent. Please wait a minute and try again.';
        } else if (er.error.num === 215) {
            erString = 'Insufficient payment balance. Please choose a new payment method.';
        } else if (er.error.num === 217) {
            erString = 'Restaurant cannot be reached. Please wait a minute and try again. If this problem persists, contact the restaurant.';
        } else if (er.error.num === 218) {
            // TODO: Consider developer automation of order ready time adjustment
            erString = 'The restaurant is not open at the desired order ready time. Please choose a different time.';
        } else if (er.error.num === 220) {
            erString = 'The selected restaurant is unavailable for online ordering at this time. Please call in your order instead, or select a different location.';
        } else if (er.error.num === 221) {
            erString = 'Error submitting coupon. Remove the applied coupon and try submitting the order again.';
        } else if (er.error.num === 222) {
            if (er.error.message) {
                erString = 'The order could not be submitted because there is a problem with ' + er.message + '. Please remove it from the order and try again.';
            } else {
                erString = 'The order could not be submitted because there is a problem with at least one of the products in the basket. Please remove it from the order and try again.';
            }
        } else if (er.error.num === 223) {
            // TODO: Consider automatic retry (up to 2 more times). Olo documentation suggests this.
            erString = 'The order could not be submitted because the system is busy. Please try again in a few seconds.';
        } else if (er.error.num === 224) {
            erString = 'At least one of the specified products cannot be ordered at this restaurant. Please remove the item(s) and try again.';
        } else if (er.error.num === 225) {
            // TODO: Consider automatic retry (up to 2 more times). Olo documentation suggests this.
            erString = 'The order was not submitted because of a server issue. Please try again in a few seconds.';
        } else if (er.error.num === 226) {
            erString = 'The email address does not correspond with any loyalty accounts. Please check for correct spelling, or create an account.';
        } else if (er.error.num === 227) {
            // TODO: Consider automatic redirection to change password here
            erString = 'Your password will need to be reset. Please select the \'Change Password\' link on the \'My Account\' page.';
        }
        return erString;
    } */
}
