import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionGroup} from '../../../../interfaces/option-group.interface';
import {Option} from '../../../../interfaces/option.interface';
import {AnimationController, ModalController} from '@ionic/angular';
import {MobileService} from '../../../../services/mobile.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface OptionStack {
  option: Option;
  optionGroup: OptionGroup;
  subOptionGroup?: OptionSubGroup;
}

export interface OptionSubGroup {
  name: string;
  type: 'half-and-half' | 'button-group';
  subOptions: SubOption[];
}

export interface SubOption {
  name: string;
  option: Option;
}

@Component({
  selector: 'app-ingredient-card',
  templateUrl: './ingredient-card.component.html',
  styleUrls: ['ingredient-card.component.scss'],
})
export class IngredientCardComponent implements OnInit {
  @Input() option: Option;
  @Input() optionGroup: OptionGroup;
  @Input() optionSubGroup: OptionSubGroup;
  @Input() ingredientFormGroup: UntypedFormGroup;
  @Input() depth: number;
  @Input() sliderUpperLimit: number;
  @Output() optionClick = new EventEmitter<OptionStack>();
  @Output() editStarted = new EventEmitter<OptionStack>();
  @Output() selectOptionAndEdit = new EventEmitter<OptionStack>();
  @Output() sliderValue = new EventEmitter<number>();
  multiselect = false;
  choicesNeeded = false;
  buttonGroupChildren = false;
  sliderForm: UntypedFormGroup;
  selectedSubOption: Option;
  previousSliderValue = 0;
  constructor(
    private modal: ModalController,
    public animation: AnimationController,
    private mobileService: MobileService,
    private fb: UntypedFormBuilder
  ) {}
  ngOnInit() {
    this.multiselect = this.optionGroup.maxAllowed !== 1;
    if (this.option) {
      this.buttonGroupChildren = this.setButtonGroupChildren();
      if (!this.multiselect) {
        const defaultValue = this.option.isDefault ? this.option.nameSlug : null;
        this.ingredientFormGroup.addControl(this.optionGroup.nameSlug, new UntypedFormControl(defaultValue, Validators.required));
        if (this.option.isDefault) {
          this.optionClick.emit({
            option: this.option,
            optionGroup: this.optionGroup
          });
        }
      } else {
        this.optionGroup.options.forEach((option: Option) => {
          const defaultValue = this.option.isDefault ? this.option.optionID : null;
          this.ingredientFormGroup.addControl(option.nameSlug, new UntypedFormControl(defaultValue));
          if (this.option.isDefault) {
            this.optionClick.emit({
              option: this.option,
              optionGroup: this.optionGroup
            });
          }
        });
      }
      if (this.option.hasChildren) {
        this.checkChildrenRequirements(this.option);
      }
    }
    if (this.optionSubGroup) {
      this.ingredientFormGroup.addControl(this.optionGroup.nameSlug, new UntypedFormControl(null, Validators.required));
    }
    this.previousSliderValue = this.optionGroup.minChoiceQuantity;
    this.sliderForm = this.fb.group({
      slider: [this.optionGroup.minChoiceQuantity, Validators.min(this.optionGroup.minChoiceQuantity)]
    });
    this.sliderForm.valueChanges.subscribe((value: any) => {
      if (this.sliderUpperLimit === 0) {
      } else {
        this.sliderValue.emit(this.previousSliderValue - value.slider);
        this.previousSliderValue = value.slider;
      }
    });
  }
  optionClicked() {
    if (this.option) {
      if (this.option.hasChildren && this.choicesNeeded && !this.buttonGroupChildren) {
        this.selectOptionAndEdit.emit({
          option: this.option,
          optionGroup: this.optionGroup
        });
      } else {
        this.optionClick.emit({
          option: this.option,
          optionGroup: this.optionGroup
        });
      }
    }
  }
  subOptionClicked(option: Option, subOptionGroup: OptionSubGroup) {
    this.selectedSubOption = option;
    if (option) {
      this.optionClick.emit({
        option,
        optionGroup: this.optionGroup,
        subOptionGroup
      });
    }
  }
  editClicked() {
    this.edit();
  }
  edit() {
    this.editStarted.emit({
      option: this.option,
      optionGroup: this.optionGroup,
    });
  }
  setButtonGroupChildren() {
    let setButtonGroup = false;
    if (this.option.optionGroups.length === 1 && this.option.optionGroups[0]?.options.length <= 3) {
      setButtonGroup = true;
      this.option.optionGroups[0]?.options.forEach((option: Option) => {
        setButtonGroup = !option.hasChildren;
      });
    }
    return setButtonGroup;
  }
  increaseSlider(currentValue: number, max: number) {
    if (currentValue < max) {
      this.slider.setValue(currentValue + 1);
    }
  }
  decreaseSlider(currentValue: number, min: number) {
    if (currentValue > min) {
      this.slider.setValue(currentValue - 1);
    }
  }
  checkChildrenRequirements(option: Option) {
    option.optionGroups.forEach((optionGroup: OptionGroup) => {
      if (optionGroup.mandatorySelection) {
        this.choicesNeeded = true;
      }
      optionGroup.options.forEach((childOption: Option) => {
        this.checkChildrenRequirements(childOption);
      });
    });
  }
  get slider() {
    return this.sliderForm.get('slider');
  }
  get currentIngredient() {
    return this.ingredientFormGroup?.get(this.multiselect ? this.option?.nameSlug : this.optionGroup?.nameSlug);
  }
  get currentSubGroup() {
    return this.ingredientFormGroup?.get(this.optionGroup.nameSlug);
  }
}
