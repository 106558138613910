import {Component, Input} from '@angular/core';
import { ModeService } from 'src/services/mode.service';
import {Order} from '../../../../interfaces/order.interface';
import {HandoffType} from '../../../../interfaces/handoff-type.enum';

@Component({
    selector: 'app-nav-order-type',
    template: `
        <ng-container [ngSwitch]="modeService.mode">
            <app-mobile-nav-order-type
              *ngSwitchCase="'mobile'"
              [order]="order"
            ></app-mobile-nav-order-type>
            <app-desktop-nav-order-type
              *ngSwitchCase="'desktop'"
              [order]="order"
            ></app-desktop-nav-order-type>
            <app-mobile-nav-order-type
              *ngSwitchCase="'tableside'"
              [order]="order"
            ></app-mobile-nav-order-type>
        </ng-container>
    `
})
export class NavOrderTypeComponent {
    @Input() order: Order;
    handoffs = HandoffType;
    constructor(
      public modeService: ModeService
    ) {}
}



