import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Option } from '../../../../interfaces/option.interface';
import { OptionGroup } from '../../../../interfaces/option-group.interface';
import { MobileService } from '../../../../services/mobile.service';
import { OptionStack } from '@modules/menu/components';
import {ModeService} from "../../../../services/mode.service";

@Component({
  selector: 'app-radio-button-checkbox-card',
  templateUrl: './radio-button-checkbox-card.component.html',
  styleUrls: ['radio-button-checkbox-card.component.scss'],
})
export class RadioButtonCheckboxCardComponent implements OnInit, OnChanges {
  @Input() option: Option;
  @Input() optionGroup: OptionGroup;
  @Input() isValid: boolean;
  @Input() isModifierFlow: boolean;
  @Input() currencyCode: string;
  @Input() showPricing: boolean;
  @Input() selectedOptionIndex: number;
  @Input() maxChoiceQuantity: number;
  @Input() selectedOptions: Option[];
  @Input() hitMaxGroupQuantity: boolean;
  @Input() allowsOptionQuantity: boolean;

  @Output() clickedOption = new EventEmitter<{ option: Option; quantity: number }>();
  @Output() clickedSubOption = new EventEmitter<{ option: Option; quantity: number }>();
  @Input() isLoading = false;

  optionSubGroup = null;
  showSubgroup = false;
  quantity = 1;

  constructor(public mobile: MobileService, public mode: ModeService,) {}

  ngOnInit() {
    if (this.optionGroup.minChoiceQuantity) {
      this.quantity = this.optionGroup.minChoiceQuantity;
    }
    if (this.option.quantity) {
      this.quantity = this.option.quantity;
    }
    if (this.option.isDefault) {
      this.optionClicked(this.option);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if option is not previously selected and is now selected, set quantity to 1
    if (changes.selectedOptions && changes.selectedOptions.currentValue) {
      if (
        !changes.selectedOptions.previousValue ||
        changes.selectedOptions.previousValue.length !== changes.selectedOptions.currentValue.length
      ) {
        if (
          changes.selectedOptions.currentValue?.find((option: Option) => option.optionID === this.option.optionID) &&
          !changes.selectedOptions.previousValue?.find((option: Option) => option.optionID === this.option.optionID) &&
          !this.optionGroup.mandatorySelection
        ) {
          this.option.quantity < this.optionGroup.minChoiceQuantity ? (this.quantity = this.optionGroup.minChoiceQuantity) : null;
        }
      }
    }
    if (changes.selectedOptions && changes.selectedOptions.currentValue && this.optionGroup.mandatorySelection) {
      this.quantity = this.option.quantity;
    }
  }

  optionClicked(option: Option) {
    if (this.hitMaxGroupQuantity && this.allowsOptionQuantity && !option.isSelected) {
      return;
    }
    if (this.quantity > this.maxChoiceQuantity) {
      this.quantity = this.maxChoiceQuantity;
    }
    if (this.quantity < this.optionGroup.minChoiceQuantity) {
      this.quantity = this.optionGroup.minChoiceQuantity;
    }
    // if (this.option.optionGroups[0]) {
    //   this.clickedSubOption.emit(this.option.optionGroups[0].options[1]);
    // } else {
    this.clickedOption.emit({ option, quantity: this.quantity });
    // }
  }

  subOptionClicked(option: Option, quantity = 1) {
    // console.log(option);
    this.clickedSubOption.emit({ option, quantity });
  }

  addOrRemoveOptionAndUpdateView(optionStack: OptionStack) {
    const multiselect = optionStack.optionGroup.maxAllowed !== 1;
    if (!multiselect) {
      optionStack.optionGroup.options.forEach((selectedOption: Option, i: number) => {
        const oldValue = this.optionGroup.options.findIndex((option: Option) => option.optionID === selectedOption.optionID);
        if (oldValue >= 0) {
          this.optionGroup.options.splice(oldValue, 1);
        }
      });
      this.addOrRemoveSelectedOption(optionStack.option);
    } else {
      const groupLimit =
        optionStack.optionGroup.maxAllowed !== 0 ? optionStack.optionGroup.maxAllowed === 0 : optionStack.optionGroup.options.length;
      this.addOrRemoveSelectedOption(optionStack.option);
    }
    // console.log(this.optionForms);
    // console.log(this.optionGroup.options);
  }

  addOrRemoveSelectedOption(option: Option) {
    if (this.optionGroup.options.find((opt: Option) => opt.optionID === option.optionID)) {
      this.removeSelectedOption(option);
    } else {
      this.optionGroup.options.push(option);
    }
  }

  removeSelectedOption(option: Option) {
    const index = this.optionGroup.options.findIndex((opt: Option) => opt.optionID === option.optionID);
    if (index > -1) {
      this.optionGroup.options.splice(index, 1);
    }
  }

  animationStart() {
    // if (this.option.isSelected) {
    //   this.showSubgroup = true;
    // }
  }

  animationDone() {
    // if (!this.option.isSelected) {
    //   this.showSubgroup = false;
    // }
  }

  increaseQuantity(event: Event) {
    event.stopPropagation();
    if (this.quantity < this.maxChoiceQuantity) {
      this.quantity++;
    }
    this.updateOptionQuantity();
  }

  decreaseQuantity(event: Event) {
    event.stopPropagation();
    if (this.quantity > 1) {
      this.quantity--;
    }
    this.updateOptionQuantity();
  }

  updateOptionQuantity() {
    this.option.quantity = this.quantity;
  }
}
