import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RewardsBalances } from '../../../../interfaces/rewards-balances.interface';

@Component({
  selector: 'app-loyalty-points-balance-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './loyalty-points-balance-info.component.html',
  styleUrls: ['loyalty-points-balance-info.component.scss'],
})
export class LoyaltyPointsBalanceInfoComponent implements OnInit {
  @Input() balances: RewardsBalances;
  constructor() {}
  ngOnInit() {}
}
