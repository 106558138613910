<ng-container *ngIf="textFields$ | async as textFields">
  <div class="safe-area h-100 text-bg-light">
    <div class="pt-3 pb-5 position-relative h-100 text-bg-light">
      <div class="d-flex justify-content-end mx-3" style="position: sticky; top: 1rem;">
        <i class="bi bi-x-circle text-dark d-flex pointer bg-white rounded-circle" style="font-size: 30px;"
           (click)="close()"></i>
      </div>
      <ion-content class="mobile-modal-content h-100">

        <div class="d-flex flex-column gap-3 h-100 text-bg-light">
          <div class="mx-3">
            <h1 class="header-font fw-bold pb-2">
              {{ textFields.earn_page_heading }}
            </h1>
            <p class="mb-0">
              Scan this code or provide your phone number at the register to earn points.
            </p>
          </div>

          <div class="d-flex flex-column bg-white align-items-center rounded shadow mx-3">
            <div class="w-100 d-flex ion-justify-content-end pt-3 px-3"
                 *ngIf="(mobileAppSettings$ | async)?.loyalty_program_details">
              <div class="program-details-icon rounded-circle">
                <button type="button" class="program-details-button bg-white" (click)="openInstructions()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       class="bi bi-question-circle"
                       viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              class="loyalty-qr-code w-100 rounded d-flex flex-column align-items-center justify-content-center px-3 pb-3"
              [class.pt-3]="!(mobileAppSettings$ | async)?.loyalty_program_details">
              <p class="text-center pb-2" *ngIf="!changeCode">
                Tap to change between QR and Barcode
              </p>
              <qrcode
                *ngIf="displayMode === displayModes.qr"
                [width]="260"
                [qrdata]="displayUser.userAsQrCode"
                [level]="'H'"
                (click)="!changeCode ? switchDisplayMode() : null"
              ></qrcode>
              <ngx-ivy-barcode
                *ngIf="displayMode === displayModes.barcode"
                [bc-display-value]="false"
                bc-element-type="svg"
                [bc-height]="146"
                [bc-value]="displayUser.userAsBarcode"
                (click)="!changeCode ? switchDisplayMode() : null"
              ></ngx-ivy-barcode>

              <h5 class="fw-bold header-font pb-1">Loyalty Card</h5>
              <p class="font-size-body-regular pb-1">{{ displayUser.firstName }} {{ displayUser.lastName }}</p>
              <p class="font-size-body-regular">#{{ displayUser.userAsBarcode }}</p>
              <ng-container *ngIf="googleWalletData$ || applePassbookURL$">
                <ng-container *ngIf="googleWalletData$ | async as googlePass">
                  <save-to-google-pay-button class="wallet-button mt-3" [jwt]="googlePass.jwt"
                                             [theme]="googlePass.theme"
                                             [height]="googlePass.size"
                                             [successCallback]="googlePaySuccessCallback"></save-to-google-pay-button>
                </ng-container>
                <ng-container *ngIf="applePassbookURL$ | async as applePassbookURL">
                  <img class="wallet-button mt-3" alt="Add to Apple Wallet"
                       src="assets/images/add_to_Apple_Wallet_RGB.svg"
                       (click)="addToAppleWalletClick(applePassbookURL)">
                </ng-container>
              </ng-container>
            </div>

          </div>

          <ng-container *ngIf="isCheckin">
            <div class="d-flex justify-content-center mx-3" *ngIf="!(quickCode$ | async)">
              <button class="de-primary-btn w-75" (click)="generateQuickCode()">
                <!-- Loader and Button Text -->
                <ng-container *ngIf="!isLoading; else btnLoading">Create Quick Code</ng-container>
              </button>
            </div>

            <ng-template #btnLoading>
              <div class="de-btn-loader"></div>
            </ng-template>

            <ng-container *ngIf="quickCode$ | async as quickCode">
              <div class="d-flex flex-column align-items-center text-center mt-4">
                <p class="fw-bold header-font mb-1">Quick Code</p>
                <h2 *ngIf="quickCode.code" class="display-3 quick-code fw-bold">{{ quickCode.code }}</h2>
                <p *ngIf="quickCode.expirationDate" class="text-black-50">Expires at: {{ quickCode.expirationDate | date: 'short' }}</p>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!enableQR">
            <form [formGroup]="barcodeForm" class="mx-3 mt-auto">
              <ng-container *ngIf="textFields$ | async as textFields">
                <label for="barcodeInput">{{ textFields.scan_barcode_title_copy }}</label>
                <div class="d-flex flex-row flex-nowrap de-form-group">
                  <input formControlName="manualBarcode" class="form-control de-input-w-btn grow-1 basis-0"
                         id="barcodeInput"
                         [placeholder]="textFields.scan_barcode_placeholder_copy">
                  <button
                    class="de-primary-btn-sm de-btn-w-input text-center d-flex align-items-center justify-content-center py-0 px-2"
                    type="button" (click)="manuallySubmitBarcode()">Apply
                  </button>
                </div>
              </ng-container>
              <div class="invalid-feedback">

              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="textFields.earn_page_description; else defaultInstructions">
            <div class="mx-3" [class.mt-auto]="!enableQR">
              <p class="font-size-small fst-italix" [innerHTML]="textFields.earn_page_description | sanitizeHtml"></p>
            </div>
          </ng-container>
          <ng-template #defaultInstructions>
            <div class="mx-3" [class.mt-auto]="enableQR">
              <p class="font-size-small fst-italic">
                Disclaimer: Code scanning may not be available at all locations. Please provide phone number or email
                address to team member if code scanning is not available.
              </p>
            </div>
          </ng-template>
        </div>
      </ion-content>
    </div>
  </div>
</ng-container>

<ng-template #checkIn>
  <ng-container *ngIf="checkInCode$ | async as checkInCode else checkingIn">
    <div class="d-flex flex-column gap-3 p-3">
      <h1 class="header-font text-uppercase m-0">In-Store Check-In</h1>
      <div class="my-3" style="font-size: 64px">{{ checkInCode.code }}</div>
      <p class="fst-italic m-0">Give this code to your server to receive points for this order.</p>
      <ng-container *ngIf="checkInCode.expirationDate">
        <div>Code Expires At:</div>
        <div>{{ checkInCode.expirationDate | date: 'short' }}</div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #checkingIn>
    <div class="w-100 h-100" style="display: grid; place-items: center">
      <div style="display: grid; place-items: center; grid-template-rows: 50px auto; width: max-content;">
        <div class="de-btn-loader-secondary"></div>
        <div>Checking you in...</div>
      </div>
    </div>
  </ng-template>
</ng-template>
