<div *ngIf="!isSkeleton; else skeletonCard" class="bg-white w-100 h-100 rounded d-flex border"
     [class.pointer]="!isProduct" [class.m-0]="!isProduct" [class.flex-row]="isProduct" [class.flex-wrap]="isProduct"
     [class.row]="!isProduct" (click)="isProduct ? null : cardClicked()">
  <div class="d-flex flex-row flex-wrap align-content-start">
    <div class="w-25 w-md-100 rounded-top position-relative height-fit-content"
         [class.height-fit-content]="isProduct">
      <div class="product-card-info border-0 p-0 bg-transparent d-none d-md-flex"
           *ngIf="product?.shortDesc || description">
        <a (click)="stopPropagation($event)" class="h6 product-card-bg pointer" popoverClass="h3" style="z-index: 1;" tabindex="0"
           role="button" placement="bottom auto" [ngbPopover]="isProduct ? product?.shortDesc : description"
           [popoverTitle]="isProduct ? product?.name : title">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000"
               class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
          <div>Info</div>
        </a>
      </div>
      <img *ngIf="imageURL" [defaultImage]="'assets/img/600x600.png'" [lazyLoad]="imageURL"
           onerror="this.src='assets/img/600x600.png'" [src]="imageURL"
           [alt]="isProduct ? 'product image' : 'category image'" class="product-card-img">
      <div *ngIf="!imageURL" class="w-100 product-card-img no-image-category no-image">
        <img [alt]=" isProduct ? product?.name : title" class="image" src="assets/img/600x600.png">
        <div class="no-image-text h4">{{ isProduct ? product?.name : title }}</div>
      </div>
    </div>

    <div class="d-flex flex-wrap p-3 w-75 w-md-100">
      <div
        class="h4 m-0 mb-md-1 text-limiter-3 w-100 fw-bold d-flex align-items-center align-items-md-start">
        {{isProduct ? product.name : title }}
      </div>
      <ng-container *ngIf="isProduct">
        <div class="h5 mb-0 w-100 d-flex align-items-center align-items-md-start">
          <ng-container *ngIf="product.priceCents > 0 else noPrice">{{ product.priceCents / 100 | currency }}
          </ng-container>
          <ng-template #noPrice>PRICE VARIES</ng-template>
        </div>
      </ng-container>
    </div>

  </div>

  <ng-container *ngIf="isProduct">
    <div class="d-none d-md-flex flex-wrap flex-xl-nowrap flex-gap-product px-3 pb-3 pt-0 w-100">
      <ng-container *ngIf="product.canModify">
        <div class="d-flex justify-content-start p-0 w-100 align-self-end">
          <button
            class="btn ki-buttons outline-primary d-flex align-items-center w-100 justify-content-center px-0"
            (click)="modifyClicked()">
            Customize
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!product.requiresModification && product.priceCents > 0">
        <div class="d-flex justify-content-start p-0 w-100 align-self-end">
          <button *ngIf="productLoading !== product.productID"
                  class="btn ki-buttons d-flex align-items-center w-100 justify-content-center px-0"
                  (click)="addClicked()">
            Add
          </button>
          <button *ngIf="productLoading === product.productID"
                  class="btn ki-buttons d-flex align-items-center w-100 justify-content-center px-0">
            <div class="de-btn-loader"></div>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #skeletonCard>
  <div class="bg-white w-100 rounded d-flex flex-row flex-wrap skeleton-item">
    <div class="d-flex flex-row w-100 flex-wrap align-content-start">
      <div class="w-100 rounded-top position-relative skeleton-image skeleton-animation">
        <img src="assets/img/600x600.png">
      </div>
      <div class="d-flex flex-wrap p-3 w-75 w-md-100">
        <div
          class="h5 m-0 w-100 mb-md-1 text-limiter-3 fw-bold d-flex align-items-center align-items-md-start">
          <div class="skeleton-text-md skeleton-animation"></div>
        </div>
        <div class="mb-0 w-100 d-flex align-items-center align-items-md-start skeleton-text-sm skeleton-animation">
          <div class="skeleton-text-sm skeleton-animation"></div>
        </div>
      </div>
    </div>
    <div *ngIf="isProduct" class="d-none d-md-flex flex-wrap flex-xl-nowrap flex-gap-product px-3 pb-3 pt-0 w-100">
      <div class="d-flex justify-content-start p-0 w-100 h-100 align-self-end">
        <button type="button" data-toggle="modal" data-target="#exampleModal"
                class="btn d-flex align-items-center w-100 justify-content-center px-0 skeleton-btn skeleton-animation">
        </button>
      </div>

    </div>
  </div>
</ng-template>
