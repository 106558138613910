import { Location as DineEngineLocation, Location } from '../../interfaces/location.interface';
import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { LocationService } from '../../services/vendor-config-service/location.service';
import {
  SetAddressSearch,
  SetAllPickupLocations,
  SetDeliveryAddress,
  SetDeliveryLocations,
  SetLocation,
  SetLocationByID,
  SetLocationBySlug,
  SetPickupLocations,
  SetStaticLocations,
  SetUserAddress,
  SetUserCoordinates,
  SetUserSavedAddress,
} from '../actions/location.actions';
import { map, retry, switchMap } from 'rxjs/operators';
import { Address } from '../../interfaces/address.interface';
import { forkJoin, of, throwError } from 'rxjs';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import moment from 'moment-timezone';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { AddressSearch } from '@modules/locations/models/locations.address-search';

export interface LocationStateModel {
  location: Location;
  locations: Location[];
  selectedLocation: Location;
  deliveryAddress: Address;
  pickupLocations: Location[];
  deliveryLocations: Location[];
  staticLocations: Location[];
  userAddress: Address;
  userCoordinates: ICoordinates;
  userSavedAddress: ISavedAddress[];
  addressSearch: AddressSearch;
}

@State<LocationStateModel>({
  name: 'location',
  defaults: {
    location: null,
    locations: null,
    selectedLocation: null,
    deliveryAddress: null,
    pickupLocations: null,
    deliveryLocations: null,
    staticLocations: null,
    userAddress: null,
    userCoordinates: null,
    userSavedAddress: null,
    addressSearch: null,
  },
})
@Injectable()
export class LocationState implements NgxsOnInit {
  constructor(private locationService: LocationService) {}

  ngxsOnInit(ctx: StateContext<LocationStateModel>) {
    return ctx.dispatch(new SetStaticLocations());
  }

  @Action(SetLocation)
  setLocation(ctx: StateContext<LocationStateModel>, action: SetLocation) {
    return ctx.patchState({
      selectedLocation: action.location,
    });
  }

  @Action(SetLocationByID)
  setLocationByID(ctx: StateContext<LocationStateModel>, action: SetLocationByID) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        return lService.getLocation(action.locationID).pipe(
          map(location => {
            return ctx.patchState({
              selectedLocation: location,
            });
          })
        );
      })
    );
  }

  @Action(SetLocationBySlug)
  setLocationBySlug(ctx: StateContext<LocationStateModel>, action: SetLocationBySlug) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        return lService.getLocationBySlug(action.locationSlug).pipe(
          map(location => {
            return ctx.patchState({
              selectedLocation: location,
            });
          })
        );
      })
    );
  }

  @Action(SetDeliveryAddress)
  setDeliveryAddress(ctx: StateContext<LocationStateModel>, action: SetDeliveryAddress) {
    return ctx.patchState({
      deliveryAddress: action.address,
    });
  }

  @Action(SetPickupLocations)
  setPickupLocations(ctx: StateContext<LocationStateModel>, action: SetPickupLocations) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        return lService.getLocationsNear(action.coordinates.latitude, action.coordinates.longitude, 35, 10).pipe(
          switchMap(locations => {
            if (locations.length > 0) {
              return of(
                ctx.patchState({
                  pickupLocations: locations.filter(loc => !loc.isPrivate).filter(loc => !loc.isHidden),
                })
              );
            } else {
              // return of(ctx.patchState({
              //   pickupLocations: []
              // }));
              return lService.getLocations(true).pipe(
                map(locations2 => {
                  return ctx.patchState({
                    pickupLocations: locations2.filter(loc => !loc.isPrivate).filter(loc => !loc.isHidden),
                  });
                })
              );
            }
          })
        );
      })
    );
  }

  @Action(SetDeliveryLocations)
  setDeliveryLocations(ctx: StateContext<LocationStateModel>, action: SetDeliveryLocations) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        // tslint:disable-next-line:max-line-length
        return lService.getLocationsNear(action.coordinates.latitude, action.coordinates.longitude, action.milesRadius, 10).pipe(
          switchMap(locations => {
            const deliveryLocations: Location[] = [];
            if (locations && locations.length > 0) {
              const liveLocations = locations.filter(loc => loc.isLive).filter(loc => !loc.isHidden);
              return forkJoin(
                liveLocations.map(location => {
                  return forkJoin([
                    // tslint:disable-next-line:max-line-length
                    location.supportsDelivery
                      ? location.deliveryHours && location.deliveryHours.length
                        ? lService.checkForDelivery(
                            location.locationID,
                            HandoffType.delivery,
                            this.calculateTimeWanted(location, HandoffType.delivery),
                            action.address
                          )
                        : of({
                            canDeliver: false,
                            failureReason: 'Delivery not available',
                          })
                      : of({
                          canDeliver: false,
                          failureReason: 'Delivery not available',
                        }),
                    // tslint:disable-next-line:max-line-length
                    location.supportsDispatch
                      ? location.dispatchHours && location.dispatchHours.length > 0
                        ? lService.checkForDelivery(
                            location.locationID,
                            HandoffType.dispatch,
                            this.calculateTimeWanted(location, HandoffType.dispatch),
                            action.address
                          )
                        : of({
                            canDeliver: false,
                            failureReason: 'Delivery not available',
                          })
                      : of({
                          canDeliver: false,
                          failureReason: 'Delivery not available',
                        }),
                  ]).pipe(
                    map(([deliveryResponse, dispatchResponse]) => {
                      return { deliveryResponse, dispatchResponse, location };
                    })
                  );
                })
              ).pipe(
                map(responses => {
                  responses.forEach(res => {
                    if (!deliveryLocations.some(delLoc => delLoc.locationID === res.location.locationID)) {
                      res.location.canDeliver = res.deliveryResponse.canDeliver || res.dispatchResponse.canDeliver;
                      deliveryLocations.push(res.location);
                    }
                  });
                  if (deliveryLocations.every(loc => !loc.canDeliver)) {
                    const firstFailure = responses.find(res => res.deliveryResponse.failureReason || res.dispatchResponse.failureReason);
                    throw new Error(
                      firstFailure.dispatchResponse.failureReason
                        ? firstFailure.dispatchResponse.failureReason
                        : firstFailure.deliveryResponse.failureReason
                    );
                  }
                  return ctx.patchState({
                    deliveryLocations: deliveryLocations.filter(loc => loc.isLive && !loc.isPrivate).filter(loc => !loc.isHidden),
                  });
                })
              );
            } else {
              ctx.patchState({
                deliveryLocations: locations.filter(loc => !loc.isHidden),
              });
              return throwError('There are no locations near you.');
            }
          })
        );
      })
    );
  }

  @Action(SetStaticLocations)
  setStaticLocations(ctx: StateContext<LocationStateModel>, action: SetPickupLocations) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        return lService.getLocations(false).pipe(
          retry(5),
          map(locations => {
            return ctx.patchState({
              staticLocations: locations.filter(item => item.isLive).filter(loc => !loc.isHidden),
            });
          })
        );
      })
    );
  }

  @Action(SetUserCoordinates)
  setUserCoordinates(ctx: StateContext<LocationStateModel>, action: SetUserCoordinates) {
    return ctx.patchState({
      userCoordinates: action.coordinates,
    });
  }

  @Action(SetUserSavedAddress)
  setUserSavedAddress(ctx: StateContext<LocationStateModel>, action: SetUserSavedAddress) {
    return ctx.patchState({
      userSavedAddress: action.savedAddress,
    });
  }

  @Action(SetUserAddress)
  setUserAddress(ctx: StateContext<LocationStateModel>, action: SetUserAddress) {
    return ctx.patchState({
      userAddress: action.address,
    });
  }

  @Action(SetAllPickupLocations)
  setAllPickupLocations(ctx: StateContext<LocationStateModel>, action: SetAllPickupLocations) {
    return this.locationService.getService().pipe(
      switchMap(lService => {
        return lService.getLocations(false).pipe(
          map(locations => {
            return ctx.patchState({
              pickupLocations: locations.filter(loc => !loc.isHidden),
            });
          })
        );
      })
    );
  }

  @Action(SetAddressSearch)
  setAddressSearch(ctx: StateContext<LocationStateModel>, action: SetAddressSearch) {
    return ctx.patchState({
      addressSearch: action.addressSearch,
    });
  }

  private calculateTimeWanted(location: DineEngineLocation, handoffType: HandoffType) {
    // TODO: Make a private function to reduce the code
    const now = moment();
    if (handoffType === HandoffType.delivery) {
      if (now.isBetween(moment(location.deliveryHours[0].start), moment(location.deliveryHours[0].end))) {
        return now.toDate();
      } else if (now.isBefore(moment(location.deliveryHours[0].start))) {
        if (now.isAfter(moment(location.deliveryHours[0].start).subtract(45, 'minutes'))) {
          return moment(now).add(45, 'minutes').toDate();
        } else {
          return moment(location.deliveryHours[0].start).toDate();
        }
      } else if (location.deliveryHours.length > 1) {
        if (now.isBefore(moment(location.deliveryHours[1].start)) && now.isAfter(moment(location.deliveryHours[0].end))) {
          if (now.isAfter(moment(location.deliveryHours[1].start).subtract(45, 'minutes'))) {
            return moment(now).add(45, 'minutes').toDate();
          } else {
            return moment(location.deliveryHours[1].start).toDate();
          }
        } else if (now.isAfter(moment(location.deliveryHours[1].end), 'minute')) {
          return moment(location.deliveryHours[2].start).toDate();
        } else {
          return now.toDate();
        }
      } else {
        return now.toDate();
      }
    } else if (handoffType === HandoffType.dispatch) {
      if (location.dispatchHours && location.dispatchHours.length) {
        if (now.isBetween(moment(location.dispatchHours[0].start), moment(location.dispatchHours[0].end))) {
          return now.toDate();
        } else if (now.isBefore(moment(location.dispatchHours[0].start))) {
          if (now.isAfter(moment(location.dispatchHours[0].start).subtract(45, 'minutes'))) {
            return moment(now).add(45, 'minutes').toDate();
          } else {
            return moment(location.dispatchHours[0].start).toDate();
          }
        } else if (location.dispatchHours.length > 1) {
          if (now.isBefore(moment(location.dispatchHours[1].start)) && now.isAfter(moment(location.dispatchHours[0].end))) {
            if (now.isAfter(moment(location.dispatchHours[1].start).subtract(45, 'minutes'))) {
              return moment(now).add(45, 'minutes').toDate();
            } else {
              return moment(location.dispatchHours[1].start).toDate();
            }
          } else if (now.isAfter(moment(location.dispatchHours[1].end), 'minute')) {
            return moment(location.dispatchHours[2].start).toDate();
          } else {
            return now.toDate();
          }
        } else {
          return now.toDate();
        }
      } else {
        return now.toDate();
      }
    }
  }
}
