import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationsPageService } from './locations-page.service';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { Address } from 'src/interfaces/address.interface';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { DineEngineMapMarker } from '@modules/locations/components/simple-map/map-marker.interface';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { SimpleMapComponent } from '@modules/locations/components/simple-map/simple-map.component';
import { filter, take } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementService } from '@modules/brochure/services/announcement.service';
import { VendorSetup } from 'src/interfaces/vendor.interface';
import { LocationSearchService } from '@modules/locations/services/locations-search.service';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import { MobileService } from '../../../../../services/mobile.service';
import { LocationsService } from '@modules/locations/services/locations.service';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { CreateNewOrder, SetOrCreateOrder, UpdateDelivery } from '../../../../../store/actions/order.actions';
import { MetaService } from '../../../../../services/meta.service';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import { Capacitor } from '@capacitor/core';
import { SentryService } from '@common/services';
import { SubscriptionComponent } from '@common/components';
import { GlobalStateModel } from '../../../../../store/state.model';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';

@Component({
  selector: 'app-locations-component',
  template: '',
})
export class LocationsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Select(state => state.location.pickupLocations) pickupLocations$: Observable<DineEngineLocation[]>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.location.selectedLocation) selectedLocation$: Observable<DineEngineLocation>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.location.deliveryAddress) deliveryAddress$: Observable<Address>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;

  @Select(state => state.location.deliveryLocations) deliveryLocations$: Observable<DineEngineLocation[]>;
  @Select(state => state.location.userCoordinates) userCoordinates$: Observable<Address>;
  @Select(state => state.location.userAddress) userAddress$: Observable<Address>;
  @Select(state => state.app.mainSettings) settings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.location.userSavedAddress) savedAddresses$: Observable<ISavedAddress[]>;

  // Page details for SEO
  title = 'locations';
  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  displayDeliveryLocations: DineEngineLocation[];
  allLocations: DineEngineLocation[];
  deliveryAddress: Address;
  isLoaded = true;
  openModal = false;
  genericError: string;
  desktopView: boolean;
  showListView: boolean;
  showListViewMobile = true;
  selectedLocation: DineEngineLocation;
  displayLocations: DineEngineLocation[] = [];
  displayAllLocations: DineEngineLocation[];
  displayVendorSetup: VendorSetup;
  guestDeliveryAddress: Address;
  branding: Branding;
  markers: DineEngineMapMarker[];
  centerLat = 39.8283;
  centerLong = -98.5795;
  initialZoom = 5;
  initialZoomMobile = 3;
  lat: number;
  lng: number;
  editHandoff = false;
  gmapsKey: string;
  transferLoading = false;
  allLocationsShowing = false;
  locToTransferTo: DineEngineLocation;
  searchScores;
  highestScore = 0;
  errorText =
    'We were unable to transfer the items in your basket. If you change to this location you will have to re-add your items to your cart-container-container. Do you still want to switch locations?';
  inputError = '';
  hoveredIndex: number;
  cardEmphasis = true;
  @ViewChild(SimpleMapComponent) gmap: SimpleMapComponent;
  private selectedHandoffType: HandoffType;
  private searchText: string;

  constructor(
    private location: Location,
    private router: Router,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private geo: GeocodingService,
    private pageService: LocationsPageService,
    // private navbarService: NavBarService,
    private modalService: NgbModal,
    private announcementService: AnnouncementService,
    private locationsSearch: LocationSearchService,
    private store: Store,
    private mobile: MobileService,
    public locations: LocationsService,
    private orderType: OrderTypeService,
    private meta: MetaService,
    private sentry: SentryService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.route.fragment.subscribe(fragment => {
        this.subs.push(
          this.settings$.pipe(filter(x => x != null)).subscribe(settings => {
            this.subs.push(
              this.order$.subscribe(order => {
                if (settings.automatically_route_to_locations_page && order == null && Capacitor.getPlatform() === 'web') {
                  if (fragment === 'home') {
                    this.orderType.setNewOrderType(false);
                  }
                }
              })
            );
            if (settings) {
              this.cardEmphasis = settings.location_card_emphasis;
            }
          })
        );
      })
    );
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.sentry.setTransactionName('Locations');
    this.meta.manualUpdate({
      title: 'Locations',
      description: null,
      keywords: null,
    });
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.subs.push(
      this.pickupLocations$.pipe(filter(pl => pl !== null)).subscribe(locations => {
        if (this.locations.userCoords || this.locations.addressSearch) {
          this.displayLocations = this.sortLocations(locations);
          this.locations.findingLocations = false;
        } else {
          this.setMapInfo(locations);
          this.displayLocations = locations;
          this.locations.findingLocations = false;
        }
        this.locations.isLoading = false;
      })
    );
    // Navbar service data
    this.subs.push(
      this.order$.subscribe(order => {
        this.displayOrder = order ? order : null;
        if (order) {
          this.selectedLocation = order.location;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
      })
    );
    this.subs.push(
      this.vendorSetup$.subscribe(setup => {
        if (setup) {
          this.displayVendorSetup = setup;
        }
      })
    );
    this.subs.push(
      this.branding$.subscribe(branding => {
        this.branding = branding;
      })
    );
    this.announcementService.adjustLocationsPadding('locCol');
    this.announcementService.adjustLocationsPadding('mapCol');
  }

  changeList(value: boolean) {
    this.showListViewMobile = value;
  }

  indexHovered(index: number) {
    this.hoveredIndex = index;
  }

  orderNowButtonClicked(loc: DineEngineLocation) {
    this.locations.locationSelected(loc);
  }

  proceedWithBasketTransfer() {
    this.transferLoading = true;
    // tslint:disable-next-line:max-line-length
    this.store
      .dispatch(new CreateNewOrder(this.locToTransferTo.locationID, this.selectedHandoffType, this.deliveryAddress))
      .toPromise()
      .then(() => {
        this.openModal = false;
      })
      .catch(err => {
        if (typeof err === 'string') {
          this.genericError = err;
        } else if (typeof err === 'object') {
          this.genericError = err.error.message;
        }
      });
  }

  keepOldLocation() {
    this.openModal = false;
    this.store
      .dispatch(new SetOrCreateOrder(this.displayOrder.location.locationID, this.displayOrder.handoffType))
      .toPromise()
      .then(() => {
        const restaurantID = this.displayOrder.location.slugURL
          ? this.displayOrder.location.slugURL
          : this.displayOrder.location.locationID;
        this.navigation.navigateToMenuPage(restaurantID);
      });
  }

  closedModal() {
    this.openModal = false;
    this.genericError = null;
  }

  locationInfoButtonClicked(loc: DineEngineLocation) {
    this.store.dispatch(new UpdateDelivery(this.deliveryAddress));
    this.navigation.navigateToLocationDetailsPage(loc.slugURL ? loc.slugURL : loc.locationID);
  }

  populateScores(locations: DineEngineLocation[]) {
    const scores = [];
    let highScore = 0;
    locations.forEach((location: DineEngineLocation) => {
      if (this.searchText) {
        const score = this.locationsSearch.scoreSearchString(this.searchText, location);
        scores.push(score);
        if (highScore < score) {
          highScore = score;
        }
      } else {
        scores.push(0);
      }
    });
    this.highestScore = highScore;
    return scores;
  }

  sortLocations(locations: DineEngineLocation[]): DineEngineLocation[] {
    // tslint:disable-next-line:max-line-length
    const sortedLocations = this.sortLocationsByDistance(
      locations,
      this.locations.addressSearch ? this.locations.addressSearch.addressComponents.longitude : this.locations.userCoords.longitude,
      this.locations.addressSearch ? this.locations.addressSearch.addressComponents.latitude : this.locations.userCoords.latitude
    );
    this.setMapInfo(sortedLocations);
    return sortedLocations;
  }

  private setMapInfo(markerLocations: DineEngineLocation[]) {
    // TODO: non-view behavior, abstract to a service
    if (!markerLocations || markerLocations.length === 0) {
      this.markers = [];
      this.initialZoom = this.initialZoomMobile = 10;
    } else {
      this.markers = markerLocations
        .filter(loc => loc.isPrivate === false)
        .map(
          loc =>
            ({
              location: loc,
              iconURL: loc.mapIconURL,
              latitude: loc.address.latitude,
              longitude: loc.address.longitude,
            }) as DineEngineMapMarker
        );
      const firstLoc: ICoordinates = {
        longitude: this.markers[0].longitude,
        latitude: this.markers[0].latitude,
      };
      const secondLoc: ICoordinates = {
        longitude: this.markers[this.markers.length - 1].longitude,
        latitude: this.markers[this.markers.length - 1].latitude,
      };
      if (markerLocations.length <= 10) {
        // tslint:disable-next-line:max-line-length
        if (
          this.locations.addressSearch &&
          this.locations.addressSearch.addressComponents &&
          this.locations.addressSearch.addressComponents.latitude &&
          this.locations.addressSearch.addressComponents.longitude
        ) {
          // tslint:disable-next-line:max-line-length
          this.initialZoom = this.initialZoomMobile = this.geo.getZoomLevel(
            {
              latitude: this.locations.addressSearch.addressComponents.latitude,
              longitude: this.locations.addressSearch.addressComponents.longitude,
            },
            secondLoc
          );
          this.centerLat = this.locations.addressSearch.addressComponents.latitude;
          this.centerLong = this.locations.addressSearch.addressComponents.longitude;
        } else if (this.locations.userCoords) {
          this.initialZoom = this.initialZoomMobile = this.geo.getZoomLevel(this.locations.userCoords, secondLoc);
          this.centerLat = this.locations.userCoords.latitude;
          this.centerLong = this.locations.userCoords.longitude;
        } else {
          this.initialZoom = this.initialZoomMobile = this.geo.getZoomLevel(firstLoc, secondLoc);
          this.centerLat = firstLoc.latitude;
          this.centerLong = firstLoc.longitude;
        }
      } else {
        if (this.locations.userCoords) {
          this.initialZoom = this.initialZoomMobile = this.geo.getTwoPinZoomLevel(this.locations.userCoords, firstLoc);
          this.centerLat = (firstLoc.latitude + this.locations.userCoords.latitude) / 2;
          this.centerLong = (firstLoc.longitude + this.locations.userCoords.longitude) / 2;
        } else {
          this.initialZoom = this.initialZoomMobile = this.geo.getZoomLevel(firstLoc, secondLoc);
          this.centerLat = firstLoc.latitude;
          this.centerLong = firstLoc.longitude;
        }
      }
    }
  }

  private sortLocationsByDistance(locations: DineEngineLocation[], lng, lat) {
    // TODO: non-view behavior, abstract to a service
    if (locations.length > 0) {
      this.lng = lng;
      this.lat = lat;
      const userCoords: ICoordinates = {
        latitude: lat,
        longitude: lng,
      };
      this.locationsSearch.storeLocationDistances(locations, userCoords);
      locations = this.locationsSearch.sortIndividualLocations(locations);
      this.locationsSearch.getDistances(locations);
    }
    return locations;
  }
}
