import { Injectable } from '@angular/core';
import { User } from 'src/interfaces/user.interface';
import { UserLogInResponse as PunchhUserLoginResponse } from './interfaces/user-log-in-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { HistoryEvent } from 'src/interfaces/history-event.interface';
import { AccountHistoryItem } from './interfaces/account-history-item.interface';
import { Redeemable } from './interfaces/redeemable.interface';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import moment from 'moment-timezone';
import { Message } from './interfaces/mobile-fetch-user-balance.interface';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { LoyaltyLocation } from '../../interfaces/location.interface';
import { PunchhLocation } from './interfaces/get-meta-response.interface';

@Injectable({
  providedIn: 'root',
})
export class PunchhMappingService {
  toUser(user: PunchhUserLoginResponse, loyaltyLocations: LoyaltyLocation[]): User {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone,
      email: user.email,
      userID: user.id.toString(),
      orderingToken: user.authentication_token,
      userAsBarcode: user.user_as_barcode,
      userAsQrCode: user.user_as_qrcode,
      orderingTokenProvider: 'punchh',
      favoriteLocation: user.favourite_locations ? loyaltyLocations.find(l => l.locationID === user.favourite_locations) : null,
      birthday: user.birthday ? moment(user.birthday).toDate() : null,
      providerSpecificFields: user,
    } as unknown as User;
  }

  accountHistoryToHistoryEvents(punchhHistory: AccountHistoryItem[]): HistoryEvent[] {
    return punchhHistory.map(t => {
      const eventValue = t.event_value;
      const dollarCheck = eventValue.substring(1, eventValue.length - 1);
      let rewardValue = 0;
      if (!eventValue.includes('Item')) {
        if (eventValue.charAt(0) === '+' && eventValue.substring(eventValue.length - 6, eventValue.length) !== 'points') {
          // console.log(eventValue, eventValue.substring(2, eventValue.length), parseFloat(eventValue.substring(2, eventValue.length)));
          // earnedValue = parseFloat(eventValue.substring(2, eventValue.length - 1));
        } else if (dollarCheck.charAt(0) === '$') {
          rewardValue = parseFloat(eventValue.substring(2, eventValue.length - 1));
        }
      }

      const historyEvent: HistoryEvent = {
        type: t.event_name,
        title: t.title,
        description: t.description,
        pointsEarned: null,
        pointsOff: null,
        dollarsEarned: null,
        dollarsOff: rewardValue,
        timeStamp: t.date,
        externalID:
          t.event_name === 'Redemption' && t.event_details && t.event_details?.redeemable_id ? t.event_details.redeemable_id : null,
        redemptionInfo:
          t.event_name === 'Redemption' && t.event_details
            ? {
                redemptionCode: t.event_details.internal_tracking_code,
                redemptionMessage: t.event_details.redemption_message,
                expiryDate: t.event_details.expiring_on,
              }
            : null,
      };
      return historyEvent;
    });
  }

  loginError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      let defMsg = 'Error logging in. Please check your username and password and try again.';
      if (err && err.error) {
        defMsg += ' Message from server: ' + err.error.error;
      }
      switch (err.status) {
        case 401:
          msg = err.error.error;
          break;
        case 503:
          msg = defMsg;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  createAccountError(error: Error): DineEngineError {
    let msg = error.message;
    if (error instanceof HttpErrorResponse) {
      let defMsg = '';
      Object.keys(error.error.errors).forEach(key => {
        defMsg = defMsg.concat(`${key.toUpperCase()} ${error.error.errors[key].join(' ,')}. `);
      });
      msg = defMsg;
    }
    return new DineEngineError(msg);
  }

  barCodeErr(err: Error): DineEngineError {
    let msg = '';
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 422:
          {
            if (err.error && err.error.errors && err.error.errors.base) {
              let errors = err.error.errors.base;
              if (!Array.isArray(errors)) {
                errors = [];
              }
              msg = errors.join(', ');
            }
          }
          break;
        case 412:
          {
            if (err.error && err.error.errors && err.error.errors.invalid_signature) {
              let errors = err.error.errors.invalid_signature;
              if (!Array.isArray(errors)) {
                errors = [];
              }
              msg = errors.join(', ');
            }
          }
          break;
        case 400:
          {
            if (err.error && err.error.errors && err.error.errors.missing_required_params) {
              let errors = err.error.errors.missing_required_params;
              if (!Array.isArray(errors)) {
                errors = [];
              }
              msg = errors.join(', ');
            }
          }
          break;
        default:
          msg = 'There was an issue processing your receipt.';
      }
    }
    return new DineEngineError(msg);
  }

  redeemableToLoyaltyReward(redeemable: Redeemable): LoyaltyReward {
    return <LoyaltyReward>{
      name: redeemable.name,
      description: redeemable.description,
      imageURL: redeemable.image,
      pointCost: redeemable.points,
      expDate: redeemable.expiry_date ? moment(redeemable.expiry_date).toDate() : null,
      externalReferenceID: String(redeemable.redeemable_id),
    };
  }

  messageToInboxMessage(message: Message): InboxMessage {
    return <InboxMessage>{
      messageID: message.message_id.toString(),
      date: moment(message.created_at).toDate(),
      title: message.title,
      body: message.body,
      imageURL: message.hero_content?.url,
      read: !!message.read_at,
    };
  }

  punchhLocationToLoyaltyLocation(punchhLocation: PunchhLocation): LoyaltyLocation {
    return {
      locationID: punchhLocation.location_id.toString(),
      name: punchhLocation.name,
      address: {
        address1: punchhLocation.address,
        address2: null,
        city: punchhLocation.city,
        state: punchhLocation.state,
        zipCode: punchhLocation.post_code,
        country: punchhLocation.country,
        latitude: Number(punchhLocation.latitude),
        longitude: Number(punchhLocation.longitude),
      },
    };
  }
}
