<!-- dynamic-form.component.html -->
<form
  *ngIf="formConfig"
  [formGroup]="dynamicForm"
  (ngSubmit)="onSubmit($event)"
  [attr.action]="formConfig?.submission_type === 'traditional' ? formConfig.traditional_settings[0]?.action_url : null"
  [attr.method]="formConfig?.submission_type === 'traditional' ? formConfig.traditional_settings[0]?.method : null"
  enctype="multipart/form-data"
  id="dynamic-form"
>
  <!-- User Feedback Messages -->
  <div *ngIf="submissionSuccess" class="alert alert-success alert-dismissible fade show" role="alert">
    Form submitted successfully!
    <button type="button" class="btn-close" aria-label="Close" (click)="submissionSuccess = false"></button>
  </div>
  <div *ngIf="submissionError" class="alert alert-danger alert-dismissible fade show" role="alert">
    Failed to submit the form. Please try again.
    <button type="button" class="btn-close" aria-label="Close" (click)="submissionError = false"></button>
  </div>

  <div class="row">
    <div
      *ngFor="let field of formFields"
      [ngClass]="getColumnClass(field.size)"
      class="mb-3"
      [class.d-none]="field.type === 'hidden'"
    >
      <!-- ngSwitch Directive to Render Fields Based on Type -->
      <div [ngSwitch]="field.type">
        <!-- Input Field -->
        <div *ngSwitchCase="'input'">
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <input
            type="text"
            class="form-control"
            [id]="field.name"
            [formControlName]="field.name"
            [placeholder]="field.select_options?.[0]?.label || ''"
            [mask]="field.mask || undefined"
          />
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>

        <!-- Textarea Field -->
        <div *ngSwitchCase="'textarea'">
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <textarea
            class="form-control"
            [id]="field.name"
            [formControlName]="field.name"
            [mask]="field.mask || undefined"
            [placeholder]="field.select_options?.[0]?.label || ''">
          </textarea>
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>

        <!-- Select Field -->
        <div *ngSwitchCase="'select'">
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <select class="form-select" [id]="field.name" [formControlName]="field.name">
            <option value="" selected disabled>{{ field.default_value}}</option>
            <option *ngFor="let option of field.select_options" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>

        <!-- Upload (File) Field -->
        <div *ngSwitchCase="'upload'">
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <input
            type="file"
            class="form-control"
            [id]="field.name"
            [formControlName]="field.name"
            (change)="onFileChange($event, field)"
            multiple
          />
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>

        <!-- Locations Field -->
        <div *ngSwitchCase="'locations'">
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <select class="form-select" [id]="field.name" [formControlName]="field.name">
            <option value="" selected disabled>{{ field.default_value}}</option>
            <ng-container *ngFor="let location of staticLocations$ | async">
              <option  [value]="location.locationID">
                {{ location.name }}
              </option>
            </ng-container>

          </select>
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>

        <!-- Hidden Field -->
        <div *ngSwitchCase="'hidden'">
          <input
            type="hidden"
            [id]="field.name"
            [formControlName]="field.name"
          />
        </div>

        <!-- Default Case: Render as Text Input -->
        <div *ngSwitchDefault>
          <label [for]="field.name" class="form-label">{{ field.label }}</label>
          <input
            type="text"
            class="form-control"
            [id]="field.name"
            [formControlName]="field.name"
            [placeholder]="field.select_options?.[0]?.label || ''"
            [mask]="field.mask || undefined"
          />
          <div *ngIf="dynamicForm.get(field.name)?.invalid && dynamicForm.get(field.name)?.touched" class="invalid-feedback d-block">
            <small *ngIf="dynamicForm.get(field.name)?.errors?.required">This field is required.</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Submit Button -->
  <button type="submit" class="de-primary-btn" [disabled]="!dynamicForm.valid || isSubmitting">
    <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span *ngIf="!isSubmitting"><i class="bi bi-send"></i> Submit</span>
  </button>
</form>
