import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomPage} from '../../../../vendors/directus/interfaces/custom-page.interface';
import {FauxCustomPage} from '@modules/brochure/models';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';


@Component({
    selector: 'app-more-tile',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './more-tile.component.html',
    styleUrls: ['more-tile.component.scss'],
})
export class MoreTileComponent {
    @Input() customPage: FauxCustomPage;
    @Input() branding: Branding;

    @Output() tileClick = new EventEmitter<void>()
}
