import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-ready-date-select',
  templateUrl: './ready-date-select.component.html',
  styleUrls: ['ready-date-select.component.scss'],
})
export class ReadyDateSelectComponent {
  @Input() availableDates: any[] = [];
  @Input() dateIndex = 0;
  @Output() dateChange = new EventEmitter<any>();
  onDateChange(value: any) {
    this.dateChange.emit(value);
  }
}
