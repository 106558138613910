import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { InboxMessageModalComponent } from '@modules/loyalty/components';

@Component({
  selector: 'app-inbox-modal',
  templateUrl: './inbox-modal.component.html',
  styleUrls: ['inbox-modal.component.scss'],
})
export class InboxModalComponent implements OnInit {
  @Select(state => state.user.inboxMessages) inboxMessages$: Observable<InboxMessage[]>;

  constructor(
    private modalController: ModalController,
    private elRef: ElementRef
  ) {}
  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  openMessage(message: InboxMessage) {
    this.modalController
      .create({
        component: InboxMessageModalComponent,
        componentProps: {
          message,
        },
        presentingElement: this.elRef.nativeElement,
        animated: true,
      })
      .then(modal => modal.present());
  }
}
