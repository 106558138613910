import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';

@Component({
  selector: 'app-back-to-profile',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './back-to-profile.component.html',
  styleUrls: ['back-to-profile.component.scss'],
})
export class BackToProfileComponent implements OnInit {
  constructor(private navigation: NavigationService) {}
  ngOnInit() {}

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }
}
