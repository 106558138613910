import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent {
    showBarcode = false;

    showBarcodeClick() {
        this.showBarcode = true;
    }
}