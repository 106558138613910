import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SetStaticLocations} from '../../../../store/actions/location.actions';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Location as DineEngineLocation} from '../../../../interfaces/location.interface';

@Component({
    selector: 'app-static-locations',
    templateUrl: './static-locations.component.html',
    styleUrls: ['static-locations.component.scss'],
})
export class StaticLocationsComponent implements OnInit, OnDestroy {
    @Select(state => state.location.staticLocations) staticLocations$: Observable<DineEngineLocation[]>;
    @Input() additionalLocations: Location[];
    countries = [];
    loading = false;
    subs = [];
    constructor(
        private store: Store
    ) {}
    ngOnInit() {
        this.loading = true;
        this.subs.push(this.staticLocations$.subscribe(staticLocations => {
            if (staticLocations) {
                this.loading = false;
                this.setLocations(staticLocations);
                this.sortLocations();
            }
        }));
    }
    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
    setLocations(restaurants) {
        this.countries = [];
        restaurants.forEach((restaurant: DineEngineLocation) => {
            let countryIndex = this.countries.findIndex(country => country.code === restaurant.address.country);
            if (countryIndex === -1) {
                this.countries.push({
                    code: restaurant.address.country,
                    name: this.getCountryName(restaurant.address.country),
                    states: []
                });
                countryIndex = this.countries.findIndex(country => country.code === restaurant.address.country);
            }
            let stateIndex = this.countries[countryIndex].states.findIndex(state => state.code === restaurant.address.state);
            if (stateIndex === -1) {
                this.countries[countryIndex].states.push({
                    code: restaurant.address.state,
                    name: this.getStateName(restaurant.address.state),
                    cities: []
                });
                stateIndex = this.countries[countryIndex].states.findIndex(state => state.code === restaurant.address.state);
            }
            const cityIndex = this.countries[countryIndex].states[stateIndex].cities.findIndex(city => city === restaurant.address.city);
            if (cityIndex === -1) {
                this.countries[countryIndex].states[stateIndex].cities.push(restaurant.address.city);
            }
        });
    }
    sortLocations() {
        this.countries = this.countries.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
        this.countries.forEach(country => {
            country.states = country.states.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            country.states.forEach(state => {
                state.cities = state.cities.sort((a, b) => {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                    return 0;
                });
            });
        });
        this.countries = this.countries.sort((a, b) => {
            if (a.name === 'United States') { return -1; }
            else { return 0; }
        });
    }
    getCountryName(abbr) {
        let countryName = '';
        switch (abbr.toLowerCase()) {
            case 'ca':
                countryName = 'Canada';
                break;
            case 'us':
                countryName = 'United States';
                break;
            default:
                countryName = abbr;
                break;
        }
        return countryName;
    }
    getStateName(abbr) {
        let stateName = '';
        switch (abbr.toLowerCase()) {

            // United States
            case 'al':
                stateName = 'Alabama';
                break;
            case 'ak':
                stateName = 'Alaska';
                break;
            case 'az':
                stateName = 'Arizona';
                break;
            case 'ar':
                stateName = 'Arkansas';
                break;
            case 'ca':
                stateName = 'California';
                break;
            case 'co':
                stateName = 'Colorado';
                break;
            case 'ct':
                stateName = 'Connecticut';
                break;
            case 'dc':
                stateName = 'District of Columbia';
                break;
            case 'de':
                stateName = 'Delaware';
                break;
            case 'fl':
                stateName = 'Florida';
                break;
            case 'ga':
                stateName = 'Georgia';
                break;
            case 'hi':
                stateName = 'Hawaii';
                break;
            case 'id':
                stateName = 'Idaho';
                break;
            case 'il':
                stateName = 'Illinois';
                break;
            case 'in':
                stateName = 'Indiana';
                break;
            case 'ia':
                stateName = 'Iowa';
                break;
            case 'ks':
                stateName = 'Kansas';
                break;
            case 'ky':
                stateName = 'Kentucky';
                break;
            case 'la':
                stateName = 'Louisiana';
                break;
            case 'me':
                stateName = 'Maine';
                break;
            case 'md':
                stateName = 'Maryland';
                break;
            case 'ma':
                stateName = 'Massachusetts';
                break;
            case 'mi':
                stateName = 'Michigan';
                break;
            case 'mn':
                stateName = 'Minnesota';
                break;
            case 'ms':
                stateName = 'Mississippi';
                break;
            case 'mo':
                stateName = 'Missouri';
                break;
            case 'mt':
                stateName = 'Montana';
                break;
            case 'ne':
                stateName = 'Nebraska';
                break;
            case 'nv':
                stateName = 'Nevada';
                break;
            case 'nh':
                stateName = 'New Hampshire';
                break;
            case 'nj':
                stateName = 'New Jersey';
                break;
            case 'nm':
                stateName = 'New Mexico';
                break;
            case 'ny':
                stateName = 'New York';
                break;
            case 'nc':
                stateName = 'North Carolina';
                break;
            case 'nd':
                stateName = 'North Dakota';
                break;
            case 'oh':
                stateName = 'Ohio';
                break;
            case 'ok':
                stateName = 'Oklahoma';
                break;
            case 'or':
                stateName = 'Oregon';
                break;
            case 'pa':
                stateName = 'Pennsylvania';
                break;
            case 'ri':
                stateName = 'Rhode Island';
                break;
            case 'sc':
                stateName = 'South Carolina';
                break;
            case 'sd':
                stateName = 'South Dakota';
                break;
            case 'tn':
                stateName = 'Tennessee';
                break;
            case 'tx':
                stateName = 'Texas';
                break;
            case 'ut':
                stateName = 'Utah';
                break;
            case 'vt':
                stateName = 'Vermont';
                break;
            case 'va':
                stateName = 'Virginia';
                break;
            case 'wa':
                stateName = 'Washington';
                break;
            case 'wv':
                stateName = 'West Virginia';
                break;
            case 'wi':
                stateName = 'Wisconsin';
                break;
            case 'wy':
                stateName = 'Wyoming';
                break;

            // Canada
            case 'ab':
                stateName = 'Alberta';
                break;
            case 'bc':
                stateName = 'British Columbia';
                break;
            case 'mb':
                stateName = 'Manitoba';
                break;
            case 'ns':
                stateName = 'Nova Scotia';
                break;
            case 'on':
                stateName = 'Ontario';
                break;
            case 'qc':
                stateName = 'Quebec';
                break;
            case 'sk':
                stateName = 'Saskatchewan';
                break;

            default:
                stateName = abbr;
                break;
        }
        return stateName;
    }
}
