import { Injectable } from '@angular/core';
import { VendorSetupProvider } from '../../providers/vendor-setup-provider.interface';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { of, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CmsVendorSetupService {

    constructor(private directus: DirectusService) { } // should be setup like others...

    getService(): Observable<VendorSetupProvider> {
        return of(this.directus);
    }
}
