import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Balance} from '../../../../interfaces/rewards-balances.interface';

@Component({
    selector: 'app-complete-reward-balances-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './complete-reward-balances-card.component.html',
    styleUrls: ['complete-reward-balances-card.component.scss'],
})
export class CompleteRewardBalancesCardComponent implements OnInit {
    @Input() balance: Balance;
    @Input() isLast: boolean;

    constructor() {}
    ngOnInit() {}
}
