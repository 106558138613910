import { Component, Input, OnInit } from '@angular/core';
import { InboxMessageModalComponent } from '@modules/loyalty/components';
import { AlertController } from '@ionic/angular';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../../services/notification.service';
import { Dialog } from '@capacitor/dialog';

@Component({
  selector: 'app-inbox-card',
  templateUrl: './inbox-card.component.html',
  styleUrls: ['inbox-card.component.scss'],
})
export class InboxCardComponent implements OnInit {
  @Input() message: InboxMessage;
  @Input() notification: PushNotificationSchema;

  constructor(
    private modal: NgbModal,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  openInboxMessage() {
    if (this.notification) {
      this.openNotificationAlert();
    } else if (this.message) {
      this.openMessageModal();
    }
  }

  openMessageModal() {
    const modalRef = this.modal.open(InboxMessageModalComponent, {
      modalDialogClass: this.message ? 'condensed-modal' : 'notification h-auto px-3',
      centered: true,
    });
    modalRef.componentInstance.message = this.message;
    modalRef.componentInstance.notification = this.notification;
  }

  async openNotificationAlert() {
    const headerText = this.notification.title ? this.notification.title : this.notification.body;
    const truncatedHeader = headerText.length > 50 ? headerText.substring(0, 47) + '...' : headerText;

    const result = await Dialog.confirm({
      title: truncatedHeader,
      message: this.notification.body,
      okButtonTitle: 'Delete',
      cancelButtonTitle: 'Close',
    });

    if (result.value) {
      // User clicked 'Delete'
      this.deleteNotification();
    } else {
      // User clicked 'Close' or dismissed the dialog
    }
  }

  deleteNotification() {
    this.notificationService.deleteDeviceNotification(this.notification).subscribe(() => {
      // Optionally, you can refresh the list or provide feedback to the user
    });
  }
}
