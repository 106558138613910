import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-allergen-icons',
  templateUrl: './allergen-icons.component.html',
  styleUrls: ['./allergen-icons.component.scss']
})
export class AllergenIconsComponent {

  @Input() allergenList: string[];
  @Input() isListCard: boolean = false;

}
