import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-user-info-form',
  templateUrl: './user-info-form.component.html',
  styleUrls: ['user-info-form.component.scss'],
  // animations: [
  //   trigger('growInOut', [
  //     state('false', style({ height: '0', padding: '5px', overflow: 'hidden', opacity: '0'})),
  //     state('true', style({ height: '*', padding: '10px', opacity: '1'})),
  //     transition('false => true', animate('400ms 200ms ease')),
  //     transition('true => false', animate('400ms ease'))
  //   ])
  // ]
})
export class UserInfoFormComponent implements OnInit, OnDestroy {
  @Input() emailOptInCopy = 'Send me emails about future deals and features.';
  @Input() signUpOptInCopy = 'Join Now to earn rewards when you order.';
  @Input() allowSignUp = true;
  @Output() isValid = new EventEmitter<boolean>();
  @Output() passData = new EventEmitter<UntypedFormGroup>();

  userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    phoneNumber: new UntypedFormControl('', [Validators.required]),
    guestOptIn: new UntypedFormControl(false),
    signUpOptIn: new UntypedFormControl(false),
    password: new UntypedFormControl(''),
    password2: new UntypedFormControl(''),
  });
  private subs: Subscription[] = [];

  ngOnInit() {
    this.subs.push(
      this.userForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
        this.isValid.emit(this.userForm.valid);
        if (this.userForm.valid) {
          this.passData.emit(this.userForm);
        }
        if (this.signUpOptIn.value === true) {
          this.password.setValidators(Validators.required);
          this.password.setValidators(Validators.minLength(8));
          this.password2.setValidators(Validators.required);
          this.password2.setValidators(Validators.minLength(8));
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  setValue(value: any) {
    setTimeout(() => {
      this.firstName.setValue(value.firstName);
      this.lastName.setValue(value.lastName);
      this.emailAddress.setValue(value.emailAddress);
      this.phoneNumber.setValue(value.phoneNumber);
      this.isValid.emit(this.userForm.valid);
      this.passData.emit(this.userForm);
    }, 300);
  }

  get firstName() {
    return this.userForm.get('firstName');
  }

  get lastName() {
    return this.userForm.get('lastName');
  }

  get emailAddress() {
    return this.userForm.get('emailAddress');
  }

  get phoneNumber() {
    return this.userForm.get('phoneNumber');
  }

  get guestOptIn() {
    return this.userForm.get('guestOptIn');
  }

  get signUpOptIn() {
    return this.userForm.get('signUpOptIn');
  }

  get password() {
    return this.userForm.get('password');
  }

  get password2() {
    return this.userForm.get('password2');
  }
}
