import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ContentService } from 'src/services/vendor-config-service/content-provider.service';
import { PunchhSettings } from '../directus/interfaces/punchh-settings.interface';

@Injectable({
    providedIn: 'root'
})
export class PunchhSettingsContentService {

    constructor(private contentService: ContentService) { }

    getLoyaltyBrandSettings(): Observable<PunchhSettings> {
        return this.contentService.getService().pipe(switchMap(service => {
            return service.getPunchhSettings().pipe(map(settings => {
                const punchhSettings = ({
                    displayCodesAs: settings.display_codes_as,
                    displayPointsAs: settings.display_points_as,
                    enableOauth: settings.enable_oauth,
                    enableCatering: settings.enable_catering,
                    cateringLink: settings.catering_link,
                    guestCateringLink: settings.guest_catering_link,
                } as PunchhSettings);
                return punchhSettings;
            }));
        }));
    }

}
