<div class="mobile-modal-header-back">
  <button class="back-button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  </button>
  <div class="header-title fw-bold header-font">Loyalty Program</div>
</div>
<ion-content class="mobile-modal-content">
  <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
    <div class="container" [innerHTML]="mobileAppSettings.loyalty_program_details | sanitizeHtml"></div>
  </ng-container>
</ion-content>
