export default {
  directus: 'directus',
  datacap: 'datacap',
  itwercs: 'itwercs',
  punchhOlo: 'punchh-olo',
  olo: 'olo',
  novadine: 'novadine',
  mock: 'mock',
  paytronix: 'paytronix',
  punchh: 'punchh',
  personica: 'personica',
  onesignal: 'onesignal',
  tastyIgniter: 'tastyigniter',
  flybuy: 'flybuy',
  spendgo: 'spendgo',
  oloPay: 'olo-pay',
  radar: 'radar',
  stripe: 'stripe',
};
