<div class="mobile-modal-header-back">
  <button class="back-button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  </button>
  <div class="header-title fw-bold header-font">Rewards</div>
</div>
<ion-content class="mobile-modal-content">
  <div class="inbox-container">

    <h2 class="header-font fw-bold">
      Inbox
    </h2>

    <!--  populated state-->
    <ng-container *ngIf="inboxMessages$ | async as inboxMessages else noInbox">
      <div class="inbox-cards-container" *ngIf="inboxMessages.length else noInbox">

        <ng-container *ngFor="let message of inboxMessages">
          <app-inbox-message-card [message]="message" (click)="openMessage(message)"></app-inbox-message-card>
        </ng-container>

      </div>
    </ng-container>

    <!--  empty state-->
    <ng-template #noInbox>
      <div class="inbox-cards-empty">

        <div class="inbox-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
            <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
          </svg>
        </div>

        <div class="d-flex flex-column gap-1 text-center">
          <h4 class="header-font fw-bold">
            You don't have any messages
          </h4>
          <p>
            Check back later to see if you have received any messages.
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</ion-content>
