<div class="horizontal-scroll-outer">
  <div class="horizontal-scroll-wrapper squares">
    <div *ngFor="let category of categories">
      <app-menu-card [name]="category.name" [description]="category.shortDesc"
        [imageUrl]="category.standardImageURL ? category.standardImageURL: '/assets/images/placeholder.png'"
        (clickedCard)="menuService.setCategory(category)" style="margin: 10px;">
      </app-menu-card>
    </div>
  </div>
</div>
