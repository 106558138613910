<ng-container *ngIf="products && products.length > 0">
  <ng-container *ngFor="let product of products; index as index">
    <ng-container *ngIf="!isMobile; else mobile">
      <div
        class="row m-0 p-0 d-none d-lg-block w-100"
        style="position: relative; min-height: 30vh; z-index: 1">
        <div
          class="row w-100 h-100 m-0 p-0"
          style="position: absolute; top: 0; z-index: 0">
          <div
            class="col-7 p-0 h-100 w-100"
            [ngClass]="index % 2 === 0 ? 'ms-auto' : 'me-auto'">
            <img
              class=""
              [src]="
                product.featured_item_image
                  ? product.featured_item_image.data.full_url
                  : product.image.data.full_url
              "
              [alt]="product.name"
              style="object-fit: cover; width: 100%; height: 100%" />
          </div>
        </div>
        <div class="container h-100">
          <div class="row m-0 p-0 h-100 align-items-center">
            <div
              class="col-4 col-xl-3 px-0 py-5"
              style="
                display: grid;
                width: max-content;
                height: max-content;
                grid-gap: 8px;
              "
              [ngClass]="index % 2 === 0 ? 'me-auto' : 'ms-auto'">
              <h3 *ngIf="product.featured_item_subheading" class="m-0">
                {{ product.featured_item_subheading }}
              </h3>
              <h2
                class="header-font m-0"
                *ngIf="product.featured_item_main_heading">
                {{ product.featured_item_main_heading }}
              </h2>
              <div *ngIf="product.featured_item_description">
                {{ product.featured_item_description }}
              </div>
              <button
                class="de-primary-btn w-100 mt-2"
                (click)="productClicked(product)">
                Order Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #mobile>
      <div
        style="display: grid; place-items: center; grid-gap: 8px"
        class="p-3 d-lg-none">
        <img
          class="w-100 h-auto"
          *ngIf="product.featured_item_image || product.image"
          [src]="
            product.featured_item_image
              ? product.featured_item_image.data.full_url
              : product.image.data.full_url
          "
          [alt]="product.name" />
        <h5 *ngIf="product.featured_item_subheading" class="m-0">
          {{ product.featured_item_subheading }}
        </h5>
        <h3 class="header-font m-0" *ngIf="product.featured_item_main_heading">
          {{ product.featured_item_main_heading }}
        </h3>
        <div *ngIf="product.featured_item_description">
          {{ product.featured_item_description }}
        </div>
        <button
          class="de-primary-btn w-100 mt-2"
          (click)="productClicked(product)">
          Order Now
        </button>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
