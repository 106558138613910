import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-credit-card-type',
  templateUrl: './credit-card-type.component.html',
  styleUrls: ['credit-card-type.component.scss'],
})
export class CreditCardTypeComponent implements OnInit {
  @Input() cardType = 'unknown';
  @Input() size = '8';
  constructor() {}
  ngOnInit() {}
}
