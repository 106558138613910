import { Component, Input } from '@angular/core';
import { ModeService } from 'src/services/mode.service';
import { User } from '@sentry/browser';

@Component({
    selector: 'app-account-subnav',
    template: `
        <ng-container [ngSwitch]="modeService.mode">
            <app-mobile-account-subnav *ngSwitchCase="'mobile'">
            </app-mobile-account-subnav>
            <app-desktop-account-subnav *ngSwitchCase="'desktop'">
            </app-desktop-account-subnav>
        </ng-container>
    `
})
export class AccountSubnavComponent {

    @Input() title = 'back';
    @Input() routeBack: string;
    @Input() loyaltyBar = false;
    @Input() user: User = null;
    @Input() order;

    constructor(public modeService: ModeService) { }
}
