import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HookService {
  private actions = [];
  private filters = [];
  private actionHistory = [];

  private checkFilters(type: string, priority: number) {
    let f = this.filters;
    if(!Array.isArray(f[type])) f[type] = [];
    if(!Array.isArray(f[type][priority])) f[type][priority] = [];
    return f;
  }

  private checkActions(type:string, priority:number) {
    let a = this.actions;
    if(!Array.isArray(a[type])) a[type] = [];
    if(!Array.isArray(a[type][priority])) a[type][priority] = [];
    return a;
  }

  private applyCB(cb, ...args) {
    if(!Array.isArray(cb) || cb.length < 2 || 
      ((cb[0] === null && typeof cb[1] !== 'function') && (typeof cb[0] !== 'object' && typeof cb[1] !== 'string'))
    ) throw new Error('Invalid HookService argument.');
    if(cb[0] === null) return cb[1].apply(null, args);
    return cb[0][cb[1]].apply(cb[0], args);
  }

  private doActionHistory(type:string, cb) {
    if(Array.isArray(this.actionHistory[type]))
      this.applyCB.apply(this, [cb, ...this.actionHistory[type]]);
  }

  actionExists(type:string, cb, priority:number) {
    let a = this.checkActions(type, priority);
    for(let acb of a[type][priority]) {
      if(acb===cb) return true;
    }
    return false;
  }

  filterExists(type:string, cb, priority:number) {
    let f = this.checkFilters(type, priority);
    for(let fcb of f[type][priority]) {
      if(fcb===cb) return true;
    }
    return false;
  }

  addFilter(type:string, cb, priority:number = 10, past:boolean = true) {
    if(this.filterExists(type, cb, priority)) return;
    if(!Array.isArray(cb)) cb = [null, cb];
    let f = this.checkFilters(type, priority);
    f[type][priority].push(cb);
  }

  addAction(type:string, cb, priority:number = 10, hist:boolean = true) {
    if(this.actionExists(type, cb, priority)) return;
    if(!Array.isArray(cb)) cb = [null, cb];
    let a = this.checkActions(type, priority);
    a[type][priority].push(cb);
    if(hist) this.doActionHistory(type, cb);
  }

  applyFilters(type:string, ...args) {
    let f = this.filters;
    if(!Array.isArray(f[type])) return args[0];
    for(let priority in f[type]) {
      if(!Array.isArray(f[type][priority])) return args[0];
      for(let cb of f[type][priority]) {
        args[0] = this.applyCB.apply(this, [cb, ...args]);
      }
    }
    return args[0];
  }

  doAction(type:string, ...args) {
    this.actionHistory[type] = args;
    let a = this.actions;
    if(!Array.isArray(a[type])) return;
    for(let priority in a[type]) {
      if(!Array.isArray(a[type][priority])) return;
      for(let cb of a[type][priority]) {
        this.applyCB.apply(this, [cb, ...args]);
      }
    }
  }
}
