import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSelectShowMoreCheck',
  pure: false
})
export class TimeSelectShowMoreCheckPipe implements PipeTransform {

  transform(length: number): boolean {
    switch (true) {
      case window.innerWidth > 1200:
        return length > 8;
      case window.innerWidth > 768:
        return length > 6;
      default:
        return length > 4;

    }
  }

}
