import { Pipe, PipeTransform } from '@angular/core';
import {Reward} from '../interfaces/reward.interface';

@Pipe({
  name: 'appliedRewardsFilter'
})
export class AppliedRewardsFilterPipe implements PipeTransform {

  transform(rewards: Reward[]): boolean {
    return rewards.some((reward) => reward.isApplied);
  }

}
