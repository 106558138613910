import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnnouncementService } from '@modules/brochure/services/announcement.service';
import { Browser } from '@capacitor/browser';
import { UtilityService } from '@modules/utility/services';
import { GlobalStateModel } from '../../../../store/state.model';
import { Select, Store } from '@ngxs/store';
import { NavigationService } from '@modules/navigation/services';
import { Observable } from 'rxjs';
import { PromotionalContent } from '../../../../vendors/directus/interfaces/promotional-content.interface';

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.scss'],
})
export class AnnouncementBarComponent {

  @Select((state: GlobalStateModel) => state.app.promotionalContent) promoContent$: Observable<PromotionalContent>;

  constructor(
    public router: Router,
    public announcementService: AnnouncementService,
    private utilityService: UtilityService,
    private navigation: NavigationService,
    private store: Store
  ) {}

  closeAnnouncement() {
    this.announcementService.closeAnnouncement();
  }

  routeFromAnnouncement() {
    if (this.announcementService.announcmentLink) {
      this.utilityService.handleDynamicLink(this.announcementService.announcmentLink);
    }
  }
}
