<div class="option-card-container">
  <div *ngFor="let option of optionGroup.options; trackBy: trackByFn">
    <app-option-card
      class="h-100 d-flex"
      [attr.data-cy]="option.name"
      [option]="option"
      [isModifierFlow]="isModifierFlow"
      [showPricing]="optionGroup.showPricing"
      (clickedOption)="optionClicked($event)"
      (clickedSubOption)="optionClicked($event)"
      [optionGroup]="optionGroup"
      [isValid]="menuService.isOptionValid(option, displayOptionGroups ?? [])"
      [selectedOptions]="selectedOptions"
      [maxChoiceQuantity]="option | maxChoiceQuantity: optionGroup : selectedOptions"
      [hitMaxGroupQuantity]="optionGroup | optionGroupFull"
      [allowsOptionQuantity]="!!optionGroup.maxChoiceQuantity"
    ></app-option-card>
  </div>
</div>
