import { Pipe, PipeTransform } from '@angular/core';

export interface RGB {
  r: number;
  g: number;
  b: number;
}

@Pipe({
  name: 'hexToRgbObject'
})
export class HexToRgbObjectPipe implements PipeTransform {

  transform(hex: string): RGB {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

}
