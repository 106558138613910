import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomField} from '../../../../interfaces/custom-fields.interface';
import {filter, take} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {Order} from '../../../../interfaces/order.interface';
import {UtilityService} from '@modules/utility/services';
import {ModeService} from '../../../../services/mode.service';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';

@Component({
  selector: 'app-curbside-info-form',
  templateUrl: './curbside-info-form.component.html',
  styleUrls: ['curbside-info-form.component.scss'],
})
export class CurbsideInfoFormComponent implements OnInit {

  @Select((state) => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select((state) => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;

  curbsideForm = new UntypedFormGroup({});
  curbsideFields: CustomField[] = [];

  constructor(
      private utility: UtilityService,
  ) {}

  ngOnInit() {
    combineLatest([
      this.mainSettings$,
      this.order$.pipe(filter((o) => o !== null)),
    ]).pipe(take(1)).subscribe(([ms, order]) => {
      order.location.curbsideCustomFields?.forEach((cCF) => {
        this.curbsideForm.addControl(this.utility.toSlug(cCF.label),
            new UntypedFormControl(null, cCF.required ? [Validators.required] : []));
      });
      this.curbsideFields = order.location.curbsideCustomFields;
    });
  }
}
