<div class="d-flex flex-row flex-nowrap align-items-center">
  <div class="option-group-description d-inline-block text-start">
    <span
      *ngIf="optionGroup?.minRequired > 0"
      class="badge rounded-pill d-flex flex-row flex-nowrap py-1 align-items-center font-size-small"
      [ngClass]="
        menuService.groupCheck(optionGroup, optionGroups)
          ? 'bg-success'
          : 'bg-warning'
      ">
      {{
        menuService.groupCheck(optionGroup, optionGroups)
          ? 'Complete'
          : 'Required'
      }}&nbsp;<i
        class="bi"
        [ngClass]="
          menuService.groupCheck(optionGroup, optionGroups)
            ? 'bi-check-circle'
            : 'bi-exclamation-circle'
        "></i>
    </span>
    <span
      *ngIf="optionGroup?.minRequired === 0"
      class="badge rounded-pill d-flex flex-row flex-nowrap py-1 align-items-center font-size-small"
      [ngClass]="
        menuService.groupCheck(optionGroup, optionGroups)
          ? 'bg-success'
          : 'bg-secondary'
      ">
      {{
        menuService.groupCheck(optionGroup, optionGroups)
          ? 'Complete'
          : 'Optional'
      }}
      <ng-container *ngIf="menuService.groupCheck(optionGroup, optionGroups)"
        >&nbsp;<i class="bi bi-check-circle"></i
      ></ng-container>
    </span>
  </div>
  <div
    *ngIf="
      optionGroup?.maxAllowed > 0 &&
      optionGroup?.maxAllowed === optionGroup.minRequired
    "
    class="option-group-description d-inline-block text-end ms-auto">
    Choose {{ optionGroup?.maxAllowed }}
  </div>
  <div
    *ngIf="optionGroup?.maxAllowed === 0 && optionGroup?.minRequired === 0"
    class="option-group-description d-inline-block text-end ms-auto">
    Choose Any Amount
  </div>
  <div
    *ngIf="optionGroup?.maxAllowed !== optionGroup?.minRequired"
    class="option-group-description d-inline-block text-end ms-auto">
    Choose Up To {{ optionGroup?.maxAllowed }}
  </div>
</div>
