import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../../services/vendor-config-service/user.service';
import { Observable } from 'rxjs';
import { switchMap, map, filter, take } from 'rxjs/operators';
import { OrderService } from '../../../services/vendor-config-service/order.service';
import { Store } from '@ngxs/store';
import { User } from '../../../interfaces/user.interface';
import { Order } from '../../../interfaces/order.interface';
import { NavigationService } from '@modules/navigation/services';
import { ModeService } from '../../../services/mode.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard {
  page;

  constructor(
    private navigation: NavigationService,
    private mode: ModeService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
      .select(state => state.order.order)
      .pipe(
        take(1),
        map((order: Order) => {
          if (order) {
            if (order.items.length > 0) {
              return true;
            } else {
              const locationID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
              this.navigation.navigateToMenuPage(locationID);
              return false;
            }
          } else {
            if (this.mode.mode === 'kiosk') {
              this.navigation.navigateToHomePage('kiosk');
            } else {
              this.navigation.navigateToLocationsPage();
            }
            return false;
          }
        })
      );
  }
}
