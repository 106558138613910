<ng-container *ngIf="branding$ | async as branding">
  <ng-container *ngIf="navbarSettings$ | async as navSettings">
    <div
      [class.rounded]="navSettings.fixed_navbar_width"
      class="loyalty-header py-4"
      id="cyLoyaltyHeader"
      [ngStyle]="{
      'background-image': branding.loyalty_background_image
        ? 'linear-gradient(270.49deg, rgba(var(--ion-color-primary-rgb), 0.8) 10.51%, rgba(var(--ion-color-dark-rgb), 0.8) 62.07%, rgba(var(--ion-color-dark-rgb), 0.8) 95.99%), url(' +
          branding.loyalty_background_image?.data?.full_url +
          ')'
        : 'none'
    }">
      <div class="d-flex flex-row flex-nowrap position-relative container"
           [class.px-3]="!navSettings?.fixed_navbar_width">
        <div class="d-flex justify-content-start flex-grow-1 ps-0 ps-lg-2">
          <div class="d-flex flex-column w-100">
            <ng-container *ngIf="user$ | async as user">
              <div class="d-flex flex-column w-100 mb-3">
                <ng-container *ngIf="user && user.firstName">
                  <div class="text-white">Hello,</div>
                  <h1 class="text-white m-0 fw-bold header-font text-capitalize">
                    {{ user.firstName | titlecase }}
                    {{ user.lastName | titlecase }}
                  </h1>
                </ng-container>
              </div>
              <ng-container *ngIf="user">
                <div
                  class="d-flex flex-column w-100 text-white font-size-body-small mb-3">
                  <ng-container *ngIf="user.cardNumber">
                    <div>
                      Rewards #
                      <span class="fw-bold">{{ user.cardNumber }}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="user.email">
                    <div>
                      {{ user.email }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="user.birthday">
                    <div class="d-flex flex-row flex-nowrap align-items-center">
                      {{ user.birthday | date: 'mediumDate' }}
                      <div
                        class="d-flex ms-2"
                        style="fill: #ffffff; -webkit-transform: scaleX(-1)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 514 423"
                          fill="inherit">
                          <path
                            d="M513.32 200.27C513.32 199.87 513.29 199.47 513.24 199.07C513.24 199.03 513.24 199 513.23 198.96C512.73 195.05 510.26 191.64 506.71 189.94L274.31 51.19C272.51 50.11 270.44 49.55 268.34 49.55L232.67 49.64C228.47 21.55 204.25 0 174.99 0C142.78 0 116.67 26.12 116.67 58.33C116.67 60.03 116.74 61.71 116.89 63.37C75.2298 73.06 47.8798 92.03 21.3798 110.43C16.0598 114.14 10.7098 117.85 5.20977 121.51C3.77977 122.46 2.62977 123.69 1.75977 125.1C1.70977 125.18 1.64977 125.27 1.59977 125.35C1.45977 125.6 1.31977 125.85 1.19977 126.1C1.12977 126.23 1.07977 126.37 1.01977 126.5C0.909766 126.74 0.809766 126.99 0.709766 127.24C0.649766 127.4 0.609766 127.56 0.559766 127.72C0.439766 128.12 0.329766 128.52 0.249766 128.92C0.209766 129.14 0.149766 129.36 0.119766 129.59C0.119766 129.61 0.119766 129.62 0.119766 129.64C0.0897656 129.83 0.0797656 130.02 0.0697656 130.2C0.0497656 130.47 0.0297656 130.74 0.0197656 131.01C0.0197656 131.08 0.00976562 131.15 0.00976562 131.22V336.56C0.00976562 342.32 4.23977 347.22 9.92977 348.08L499.93 422.75C500.51 422.84 501.1 422.89 501.68 422.89C504.46 422.89 507.14 421.91 509.29 420.06C511.88 417.84 513.35 414.62 513.35 411.22V200.52C513.35 200.43 513.34 200.35 513.33 200.27H513.32ZM23.3398 144.65L490.01 210.58V294.13L23.3398 225.51V144.65ZM174.99 23C192.21 23 206.59 35.39 209.69 51.72C210.1 53.86 210.31 56.07 210.31 58.33C210.31 62.94 209.42 67.34 207.81 71.38C202.61 84.41 189.86 93.65 174.99 93.65C164.26 93.65 154.63 88.84 148.16 81.26C143.12 75.38 139.99 67.85 139.7 59.6C139.68 59.18 139.67 58.75 139.67 58.33C139.67 38.85 155.51 23 174.99 23ZM123.52 85.77C133.33 104.15 152.7 116.65 174.99 116.65C201.97 116.65 224.67 98.33 231.32 73.45L265.12 72.89L445.84 180.78L42.9898 123.86C66.2898 107.87 89.7098 93.3 123.52 85.77ZM23.3398 326.52V248.76L490.01 317.39V397.64L23.3398 326.52Z"
                            fill="inherit" />
                        </svg>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
<!--            <div class="d-flex flex-wrap align-items-center">-->
<!--              <div class="d-flex flex-nowrap align-items-center mb-3">-->
<!--                <button-->
<!--                  type="button"-->
<!--                  class="pill-btn-default pill-btn-sm text-nowrap ion-text-capitalize"-->
<!--                  style="width: fit-content"-->
<!--                  [routerLink]="profilePageRoute"-->
<!--                  [routerLinkActiveOptions]="{ exact: true }"-->
<!--                  routerLinkActive="pill-btn-default-active"-->
<!--                  id="cyDashboardTab">-->
<!--                  Dashboard-->
<!--                </button>-->
<!--                <ng-container *ngIf="inboxMessages$ | async">-->
<!--                  <button type="button"-->
<!--                          class="pill-btn-default pill-btn-sm text-nowrap ms-2 ion-text-capitalize"-->
<!--                          style="width: fit-content"-->
<!--                          [routerLink]="inboxRoute"-->
<!--                          routerLinkActive="pill-btn-default-active">-->
<!--                    Inbox-->
<!--                  </button>-->
<!--                </ng-container>-->
<!--                <button type="button"-->
<!--                        class="pill-btn-default pill-btn-sm text-nowrap ms-2 ion-text-capitalize"-->
<!--                        style="width: fit-content"-->
<!--                        [routerLink]="savedCardsRoute"-->
<!--                        routerLinkActive="pill-btn-default-active">-->
<!--                  Saved Payments-->
<!--                </button>-->
<!--                <button type="button"-->
<!--                        class="pill-btn-default pill-btn-sm text-nowrap ms-2 ion-text-capitalize"-->
<!--                        style="width: fit-content"-->
<!--                        [routerLink]="editProfileRoute"-->
<!--                        routerLinkActive="pill-btn-default-active"-->
<!--                        id="cyProfileTab">-->
<!--                  Edit Profile-->
<!--                </button>-->
<!--              </div>-->
<!--              <ng-container-->
<!--                *ngIf="(activity$ | async) || (purchaseableRewards$ | async)">-->
<!--                <div-->
<!--                  class="d-flex flex-row flex-nowrap align-items-center ms-2 mb-3">-->
<!--                  <ng-container *ngIf="activity$ | async">-->
<!--                    <span class="text-white">|</span>-->
<!--                    <ng-container *ngIf="textFields$ | async as textFields">-->
<!--                      <a-->
<!--                        tabindex="0"-->
<!--                        class="de-light-link text-nowrap ms-2"-->
<!--                        style="width: fit-content"-->
<!--                        (click)="openAccountActivity()"-->
<!--                        (keydown.enter)="openAccountActivity()">-->
<!--                        {{ textFields.rewards_activity_title }}-->
<!--                      </a>-->
<!--                    </ng-container>-->
<!--                  </ng-container>-->
<!--                  <ng-container *ngIf="purchaseableRewards$ | async as pRewards">-->
<!--                    <span class="text-white ms-2">|</span>-->
<!--                    <ng-container *ngIf="textFields$ | async as textFields">-->
<!--                      <a-->
<!--                        tabindex="0"-->
<!--                        class="de-light-link text-nowrap ms-2"-->
<!--                        style="width: fit-content"-->
<!--                        (click)="openPurchaseRewards()"-->
<!--                        (keydown.enter)="openPurchaseRewards()">-->
<!--                        {{ textFields.purchase_rewards_copy }}-->
<!--                      </a>-->
<!--                    </ng-container>-->
<!--                  </ng-container>-->

<!--                  <ng-container-->
<!--                    *ngIf="(rewardPoints$ | async)?.pointBalances?.length > 1">-->
<!--                    <span class="text-white ms-2">|</span>-->
<!--                    <ng-container *ngIf="textFields$ | async as textFields">-->
<!--                      <a-->
<!--                        tabindex="0"-->
<!--                        class="de-light-link text-nowrap ms-2"-->
<!--                        style="width: fit-content"-->
<!--                        (click)="openBalances()"-->
<!--                        (keydown.enter)="openBalances()">-->
<!--                        Rewards Balances-->
<!--                      </a>-->
<!--                    </ng-container>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--            </div>-->
          </div>
        </div>

        <ng-container *ngIf="rewardPoints$ | async as balances">
          <div class="d-flex flex-column justify-content-start ms-3">
            <div
              class="d-flex flex-column h-100 w-100 justify-content-start mb-1">
              <ng-container *ngIf="showTiers">
                <app-loyalty-tier-progress
                  [layout]="'desktop'"></app-loyalty-tier-progress>
              </ng-container>
            </div>
            <div class="d-flex flex-column justify-content-end align-content-end">
              <h5 class="m-0 text-white fw-bold text-end">
                <ng-container *ngIf="balances.bankedRewards">
                  Loyalty Balance:
                  {{ balances.bankedRewards | currency: (currencyCode$ | async) }}
                </ng-container>
              </h5>
              <ng-container *ngIf="balances.pointsThreshold">
                <div
                  class="d-flex flex-row flex-nowrap mt-1 justify-content-end py-1">
                  <i class="bi bi-award text-white d-flex"></i>
                  <small
                    class="ms-1 fw-bold text-white m-0 d-flex align-items-center text-end w-fit-content">
                    Next Reward Unlocked at {{ balances.pointsThreshold }} Points
                  </small>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="d-flex ms-4 dial-container">
            <div class="rewards-dial">
              <ng-container *ngIf="theme$ | async as themeColors">
                <round-progress
                  style="
                  height: 216px;
                  width: 216px;
                  position: absolute;
                  left: -3px;
                "
                  [current]="balances.points"
                  [max]="
                  balances?.pointsThreshold
                    ? balances.pointsThreshold
                    : balances.points > 0
                    ? balances.points
                    : 1
                "
                  [responsive]="true"
                  [color]="themeColors[0].primary_color"></round-progress>
              </ng-container>
              <div class="dial-center">
                <h5 class="header-font fw-bold m-0 text-uppercase">
                  You Have
                </h5>
                <h1
                  class="display-4 header-font de-primary-text text-uppercase m-0 fw-bold">
                  {{ balances.points }}
                </h1>
                <h4
                  class="header-font m-0 text-uppercase fw-bold"
                  style="color: var(--ion-color-primary)">
                  {{ balances.pointsUnit ? balances.pointsUnit : 'Points' }}
                </h4>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
