import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaymentTypes } from '../../../interfaces/payment-types.enum';
import { Select, Store } from '@ngxs/store';
import { Order } from '../../../interfaces/order.interface';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { NavigationService } from '@modules/navigation/services';
import { User } from '../../../interfaces/user.interface';
import { ClearOrder, SubmitPayment, UpdateDelivery } from '../../../store/actions/order.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../../../interfaces/address.interface';
import { SetUserSavedAddress } from '../../../store/actions/location.actions';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { DineEngineError } from '../../../interfaces/dineengine-error.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '../../../services/toast.service';
import { ErrorService } from '../../../services/error.service';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { CardDetails } from '../../../interfaces/card-details.interface';
import { HandoffType } from '../../../interfaces/handoff-type.enum';

@Injectable()
export class CheckoutService {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;

  // State Data
  order: Order;
  user: User;
  guestUser: User;

  // Collected Data
  token = '';
  specialInstructions = '';
  details: CardDetails;
  shouldSave: boolean;

  // Functionality
  paymentTypes = PaymentTypes;
  orderType;
  errorMessage = '';
  isLoading = false;
  loadingHandoff: HandoffType = -1;
  promoLoading = false;
  goToMenuLoader = false;
  constructor(
    private store: Store,
    private navigation: NavigationService,
    private modalService: NgbModal,
    private toast: ToastService,
    private errorService: ErrorService,
    private userLocation: UserLocationService
  ) {
    this.order$.pipe(filter(o => o !== null)).subscribe(order => {
      if (order) {
        this.order = order;
        this.orderType = this.order.handoffType;
        if (this.orderType === this.loadingHandoff) {
          this.loadingHandoff = HandoffType.loading;
        }
      }
    });
    this.user$
      .pipe(
        filter(u => u !== null),
        distinctUntilChanged()
      )
      .subscribe(user => {
        if (user && !user.isGuest) {
          this.user = user;
          this.guestUser = null;
        } else {
          this.user = null;
          this.guestUser = user;
        }
      });
  }
  getCheckout$(): Observable<{}> {
    return of({});
  }
  backToMenu() {
    this.goToMenuLoader = true;
    const restaurantID = this.order.location.slugURL ? this.order.location.slugURL : this.order.location.locationID;
    this.navigation.navigateToMenuPage(restaurantID).then(() => {
      this.goToMenuLoader = false;
    });
  }
  navigateToConfirmation(confirmationGuid: string) {
    this.modalService.dismissAll();
    sessionStorage.setItem('previousOrder', JSON.stringify(this.order));
    sessionStorage.removeItem('giftCards');
    this.store.dispatch(new ClearOrder());
    this.navigation.navigateToConfirmationPage(confirmationGuid);
  }

  submitWithInstructions(address: Address) {
    this.store
      .dispatch(new UpdateDelivery(address))
      .toPromise()
      .then(() => this.submitPayment());
  }

  submitPayment() {
    this.store
      .dispatch(new SubmitPayment(this.details, this.specialInstructions, this.order.balanceCents, this.shouldSave, this.token))
      .toPromise()
      .then(newState => this.runPaymentSuccess(newState))
      .catch(er => this.runPaymentError(er));
  }

  runPaymentSuccess(state: any) {
    this.storeNewUserAddress();
    if (Capacitor.getPlatform() !== 'web') {
      Haptics.notification({
        type: NotificationType.Success,
      })
        .then()
        .catch(console.log);
    }
    this.modalService.dismissAll();
    this.navigateToConfirmation(state.order.previousOrder.orderID);
  }

  storeNewUserAddress() {
    if (this.user && !this.user.isGuest) {
      if (this.orderType === 2 || this.orderType === 3) {
        this.userLocation.storeSavedAddress(this.order.deliveryAddress, 'delivery', this.order.location);
      } else {
        this.userLocation.storeSavedAddress(this.order.location.address, 'pickup', this.order.location);
      }
      const savedAddress = this.userLocation.getSavedAddresses();
      if (savedAddress && savedAddress.length) {
        this.store.dispatch(new SetUserSavedAddress(savedAddress));
      }
    }
  }

  runPaymentError(error) {
    if (Capacitor.getPlatform() !== 'web') {
      Haptics.notification({
        type: NotificationType.Error,
      })
        .then()
        .catch(console.log);
    }
    this.modalService.dismissAll();
    this.errorMessage = error instanceof DineEngineError ? error.message : this.errorService.checkoutError(error as HttpErrorResponse);
    this.toast.danger(this.errorMessage);
    this.promoLoading = false;
    this.isLoading = false;
  }
}
