import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InStoreRedemptionComponent } from '../../../loyalty/components/in-store-redemption/in-store-redemption.page';
import { ModalController } from '@ionic/angular';
import { Reward } from 'src/interfaces/reward.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-cancel-edit-order-modal',
  templateUrl: './cancel-edit-order-modal.component.html',
  styleUrls: ['./cancel-edit-order-modal.component.scss'],
})
export class CancelEditOrderModalComponent {

  @Input() isCancelOrder;

  @Output() cancelOrEditClicked = new EventEmitter<any>();

  cancelText = 'Are you sure that you want to cancel your order?';
  editText = 'If you choose to edit your order you will have to resubmit the order through the checkout process. If you do not resubmit then the original order will still be placed.';

  constructor(
    public activeModal: NgbActiveModal,
    public mobile: MobileService,
    private modalController: ModalController,
    private modalService: NgbModal
  ) {}

  async presentModal() {
    const modal = await this.modalController.create({
      component: CancelEditOrderModalComponent,
      cssClass: 'condensed-modal',
    });
    return await modal.present();
  }

  cancelOrEditOrder() {
    this.cancelOrEditClicked.emit()
  }
}
