import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Category } from 'src/interfaces/category.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
  selector: 'app-mobile-menu-card',
  templateUrl: './mobile-menu-card.component.html',
  styleUrls: ['./mobile-menu-card.component.scss'],
})
export class MobileMenuCardComponent {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  @Input() category: Category;
  @Input() cardBorder = false;
  @Input() imageUrl: string;
  @Output() clickedCategory = new EventEmitter<any>();

  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;

  constructor(public modalService: NgbModal) {}

  categoryClicked() {
    this.clickedCategory.emit();
  }

  calculateTruncate() {
    return Math.floor(0.11 * window.innerWidth - 8);
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal',
    });
  }
}
