import {Injectable, TemplateRef} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {MobileService} from './mobile.service';


@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  /**
   * Show standard toast message
   * @param {string | TemplateRef<any>} textOrTpl
   * @param {number} delay
   */

  constructor(
    private toastController: ToastController,
    private mobile: MobileService
  ) {
  }

  showDismissableIonicToast(text: string, className: string, duration: number) {
    this.toastController.create({
      message: text,
      cssClass: className,
      duration,
      position: this.mobile.isMobile ? 'top' : 'bottom',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        }
      ]
    }).then((toastMessage) => {
      toastMessage.present().then();
    });
  }

  standard(textOrTpl: string | TemplateRef<any>, delay?: number) {
    this.show(textOrTpl);
  }

  success(textOrTpl: string | TemplateRef<any>, delay?: number) {
    this.show(textOrTpl, {classname: 'bg-success text-light', delay});
  }

  info(textOrTpl: string | TemplateRef<any>, delay?: number) {
    this.show(textOrTpl, {classname: 'bg-info text-light', delay});
  }

  warning(textOrTpl: string | TemplateRef<any>, delay?: number) {
    this.show(textOrTpl, {classname: 'bg-warning', delay});
  }

  danger(textOrTpl: string | TemplateRef<any>, delay?: number) {
    this.show(textOrTpl, {classname: 'bg-danger text-light', delay});
  }

  /**
   * @deprecated DO NOT USE
   * @param toast
   */

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if (this.toasts.length > 1) {
      this.remove(this.toasts[0]);
    }
    this.toasts.push({textOrTpl, ...options});
  }
}
