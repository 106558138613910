import { Pipe, PipeTransform } from '@angular/core';
import {ThemeColor} from '../vendors/directus/interfaces/theme-color.interface';

@Pipe({
  name: 'radialGradient'
})
export class RadialGradientPipe implements PipeTransform {

  /**
   * for use with [style.background]
   * @param theme - send theme from state
   */
  transform(theme: ThemeColor): string {
    return `radial-gradient(52.6% 88.88% at 81.67% 77.59%, ${theme.tertiary_color} 0%, ${theme.primary_color} 100%)`;
  }

}
