<ng-container *ngIf="enableQR$ | async">
  <div class="rewards-category-item">
    <div class="w-100 h-100">
      <div class="d-flex flex-column gap-3 w-100 h-100">
        <div class="w-100 d-flex flex-column gap-2">
          <h4 class="fw-bold header-font mb-0">
            <i class="bi bi-{{ section?.section_icon || 'star' }} color-primary me-2"></i>
            {{ section?.section_title || 'Load Points' }}
          </h4>
          <div>
            Enter your receipt barcode here to earn points for your visit.
          </div>
        </div>

        <form class="w-100" [formGroup]="barcodeForm">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              style="height: unset"
              placeholder="Enter Receipt Barcode Number"
              formControlName="manualBarcode"
              aria-label="Enter Receipt Barcode Number"
              aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button
                class="btn de-primary-btn"
                type="button"
                id="button-addon2"
                (click)="earnPointsFromReceiptCode()">
                Earn Points
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
