import { Injectable } from '@angular/core';
import {Observable, Subscription} from 'rxjs';

import { DirectusService } from 'src/vendors/directus/directus.service';
import {Select, Store} from '@ngxs/store';
import {filter} from 'rxjs/operators';
import {CloseAnnouncementBar} from '../../../store/actions/app.actions';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  @Select(state => state.app.announcementBar) announcementBar$: Observable<boolean>;
  @Select(state => state.app.promotionalContent) promoContent$: Observable<any>;

  // Sets the announcement bar size for the padding to account for padding (could eventually be pulled from the CMS)
  announcementBarSize = '60px';

  announcementPage;
  announcementMessage;
  announcementColor;
  announcementTextColor;
  announcmentLink;
  bannerClosed = false;

  paddingIDList: string[] = [];
  marginIDList: string[] = [];

  private subs: Subscription[] = [];

  private root = document.documentElement;

  /**
   * Constructor for the announcement service
   * @constructor
   */
  constructor(
    private directus: DirectusService,
    private store: Store
    // private navBarService: NavBarService
  ) {

    document.documentElement.style.setProperty('--announceheight', '0px');

    if (JSON.parse(sessionStorage.getItem('announcementBannerClosed'))) {
      document.documentElement.style.setProperty('--announceheight', '0px');
    }

    this.subs.push(this.announcementBar$.subscribe(announcement => {
      if (announcement === false) {
        this.bannerClosed = true;
        document.documentElement.style.setProperty('--announceheight', '0px');
      }
      this.promoContent$.pipe(filter(pc => pc !== null)).subscribe(pages => {
        this.announcementPage = pages;
        if (this.announcementPage?.announcement_bar) {
          this.announcementMessage = this.announcementPage?.announcement_bar;
          this.announcementColor = this.announcementPage?.announcement_bar_color;
          this.announcementTextColor = this.announcementPage?.announcement_bar_text_color;
          this.announcmentLink = this.announcementPage?.announcement_bar_link;
          if (JSON.parse(sessionStorage.getItem('announcementBannerClosed'))) {
            document.documentElement.style.setProperty('--announceheight', '0px');
          } else {
            // document.documentElement.style.setProperty('--announceheight', this.announcementBarSize);

          }

        } else {
          document.documentElement.style.setProperty('--announceheight', '0px');
        }
        if (this.announcementMessage && !this.bannerClosed) {
          this.paddingIDList.forEach(id => {
            const elementReference = document.getElementById(id);
            if (elementReference) {
              elementReference.style.paddingBottom = this.announcementBarSize;
            }
          });
          this.marginIDList.forEach(id => {
            const elementReference = document.getElementById(id);
            if (elementReference) {
              elementReference.style.marginBottom = this.announcementBarSize;
            }
          });
        }
      });
    }));

    // Sets the announcement closed if applicable
    const announcementClosed = sessionStorage.getItem('announcementBannerClosed');
    if (announcementClosed === 'true') {
      this.bannerClosed = true;
      document.documentElement.style.setProperty('--announceheight', '0px');
    }

  }

  /**
   * Adjust a given element for the announcement bar
   */

  closeAnnouncement() {
    this.store.dispatch(new CloseAnnouncementBar());
    sessionStorage.setItem('announcementBannerClosed', 'true');
    this.removeAdjustedHeight();
    document.documentElement.style.setProperty('--announceheight', '0px');

  }

  /**
   * Adjust a given element for the announcement bar
   */
  adjustLocationsPadding(elementID: string) {
    this.paddingIDList.push(elementID);
    if (this.announcementMessage && !this.bannerClosed) {
      const elementReference = document.getElementById(elementID);
      if (elementReference) {
        elementReference.style.paddingBottom = this.announcementBarSize;
      }
    }
  }

  adjustLocationsMargin(elementID: string) {
    this.marginIDList.push(elementID);
    if (this.announcementMessage && !this.bannerClosed) {
      const elementReference = document.getElementById(elementID);
      if (elementReference) {
        elementReference.style.marginBottom = this.announcementBarSize;
      }
    }
  }

  /**
   * Remove adjusted height from the list of elements it was added to
   */
  removeAdjustedHeight() {
    this.paddingIDList.forEach(id => {
      const elementReference = document.getElementById(id);
      if (elementReference) {
        elementReference.style.paddingBottom = '0px';
      }
    });
    this.marginIDList.forEach(id => {
      const elementReference = document.getElementById(id);
      if (elementReference) {
        elementReference.style.marginBottom = '0px';
      }
    });
  }

}
