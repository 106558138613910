import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location as DineEngineLocation } from '../../../../../interfaces/location.interface';
import { LocationService } from '../../../../../services/vendor-config-service/location.service';
import { Geolocation } from '@capacitor/geolocation';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { DistanceConfirmationComponent } from '@modules/locations/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delivery-location-card',
  templateUrl: './delivery-location-card.component.html',
  styleUrls: ['delivery-location-card.component.scss'],
})
export class DeliveryLocationCardComponent implements OnInit {
  @Input() locationID: string;
  @Input() cardEmphasis: boolean;
  @Input() isCurrentLocation = false;
  @Input() locationLoading;
  @Input() pageFrom: string;

  @Output() orderNowButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() hoveredIndex = new EventEmitter<number>();

  location: DineEngineLocation;
  pickupStart: Date;
  pickupEnd: Date;
  deliveryStart: Date;
  deliveryEnd: Date;
  distance;

  handoffMethods: string;
  isLoading = true;

  constructor(
    private locationService: LocationService,
    private modalService: NgbModal,
    private geo: GeocodingService
  ) {}

  ngOnInit() {
    if (this.locationID) {
      this.locationService.getService().subscribe(service => {
        if (service) {
          service.getLocation(this.locationID).subscribe((location: DineEngineLocation) => {
            this.location = location;
            this.setTimes();
            this.determineHandoff();
            if (this.pageFrom === 'locations') {
              this.distance = parseInt(sessionStorage.getItem(this.location.locationID), 10);
            }
            this.isLoading = false;
          });
        }
      });
    }
  }

  orderNowButtonClicked(event: any) {
    event.stopPropagation();
    if (this.location.isLive) {
      if (this.distance > 25) {
        const modalRef = this.modalService.open(DistanceConfirmationComponent, { centered: true, modalDialogClass: 'condensed-modal' });
        modalRef.componentInstance.distance = this.distance;
        modalRef.componentInstance.continueEmit.subscribe(() => {
          this.orderNowButtonClick.emit(this.location);
          this.modalService.dismissAll();
        });
      } else {
        this.orderNowButtonClick.emit(this.location);
      }
    }
  }

  private determineHandoff() {
    this.handoffMethods = 'Offers ';
    const methods = [];
    if (this.location.supportsPickup) {
      methods.push('Pickup');
    }
    if (this.location.supportsCurbside) {
      methods.push('Curbside');
    }
    if (this.location.supportsDispatch || this.location.supportsDelivery) {
      methods.push('Delivery');
    }
    if (this.location.supportsDriveThru) {
      methods.push('Drive-Thru');
    }
    if (this.location.supportsTableside) {
      methods.push('Dine-In');
    }
    if (this.location.cateringLink) {
      methods.push('Catering');
    }
    if (methods.length === 1) {
      this.handoffMethods += methods[0];
    } else if (methods.length === 2) {
      this.handoffMethods += methods[0] + ' & ' + methods[1];
    } else if (methods.length >= 3) {
      methods.forEach((method: string, index: number) => {
        if (index < methods.length - 2) {
          this.handoffMethods += method + ', ';
        }
      });
      this.handoffMethods += methods[methods.length - 2] + ' & ' + methods[methods.length - 1];
    }
  }

  private setTimes() {
    if (!this.location) {
      return;
    }
    const now = new Date();
    if (this.location.pickupHours) {
      let pickupHours = this.location.pickupHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!pickupHours) {
        pickupHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!pickupHours) {
        this.pickupStart = null;
        this.pickupEnd = null;
      } else {
        this.pickupStart = pickupHours.start;
        this.pickupEnd = pickupHours.end;
      }
    }
    if (this.location.dispatchHours) {
      let deliveryHours = this.location.dispatchHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!deliveryHours) {
        deliveryHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!deliveryHours) {
        this.deliveryStart = null;
        this.deliveryEnd = null;
      } else {
        this.deliveryStart = deliveryHours.start;
        this.deliveryEnd = deliveryHours.end;
      }
    }
    if (this.pageFrom === 'details') {
      Geolocation.getCurrentPosition({ enableHighAccuracy: false, timeout: 10000 })
        .then(pos => {
          this.distance = this.geo.findDistance(
            this.location.address.latitude,
            this.location.address.longitude,
            pos.coords.latitude,
            pos.coords.longitude
          );
          this.distance = this.geo.precisionRound(this.distance, 1);
        })
        .catch(err => {
          console.log('Geolocation Error:', err);
        });
    }
  }

  private doesOverlap(start: Date, end: Date, query: Date): boolean {
    return new Date(start).getTime() <= new Date(query).getTime() && new Date(end).getTime() > new Date(query).getTime();
  }

  private isSameDay(dt1: Date, dt2: Date): boolean {
    return new Date(dt1).getDay() === new Date(dt2).getDay();
  }
}
