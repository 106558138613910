import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-remove-invalid-products',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './remove-invalid-products.component.html',
    styleUrls: ['remove-invalid-products.component.scss'],
})
export class RemoveInvalidProductsComponent {
    @Input() invalidProducts = [];
    @Input() handoffMode = '';
    @Output() continueEmit = new EventEmitter<any>();
    @Output() cancelEmit = new EventEmitter<any>();

    constructor(
        public activeModal: NgbActiveModal
    ) {}

    continueClicked() {
        this.continueEmit.emit();
        this.activeModal.close('Cancel click');
    }

    cancelClicked() {
        this.cancelEmit.emit();
        this.activeModal.close('Cancel click');
    }
}
