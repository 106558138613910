import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MobileService } from 'src/services/mobile.service';
import moment from 'moment-timezone';
import { NavigationService } from '@modules/navigation/services';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnChanges, OnDestroy {
  @Input() expand;
  @Input() location;

  subs = [];

  noLocation;
  noLocation1;
  noLocation2;
  loc;
  handoffType;
  hours;
  momentStart;
  momentEnd;
  locationsRoute = this.navigation.getLocationsPageSlug();

  constructor(
    private navCtrl: NavController,
    public mobile: MobileService,
    private navigation: NavigationService
  ) {}

  ngOnChanges(changes) {
    let startHour: Date;
    let endHour: Date;
    if (changes.location.currentValue) {
      const location = changes.location.currentValue;
      if (moment().isAfter(moment(location.pickupHours[0].end), 'day')) {
        startHour = location.pickupHours[1].start;
        endHour = location.pickupHours[1].end;
      } else {
        startHour = location.pickupHours[0].start;
        endHour = location.pickupHours[0].end;
      }
      this.momentStart = moment(startHour).format('LT');
      this.momentEnd = moment(endHour).format('LT');
    }
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  orderType(event) {
    // this.store.dispatch(new SetTypePreference(event));
  }

  navigateTo(route) {
    this.navCtrl.navigateRoot(route);
  }
}
