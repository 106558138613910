import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {UntypedFormBuilder} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {ThemeColor} from '../../../../../vendors/directus/interfaces/theme-color.interface';
import {Branding} from 'src/vendors/directus/interfaces/branding.interface';
import {Select, Store} from '@ngxs/store';
import {SetRouteBack, UpdateTitle} from '../../../../../store/actions/app.actions';
import {MetaService} from '../../../../../services/meta.service';
import {NavigationService} from '@modules/navigation/services';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  template: '',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'reset password';

  loginRoute = this.navigation.getLoginPageSlug();
  displaySuccess = 'Your password has been updated.';
  hasCode = false;
  success = false;

  constructor(
      private navCtrl: NavController,
      private fb: UntypedFormBuilder,
      private store: Store,
      private meta: MetaService,
      private navigation: NavigationService,
      private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack('user/login'));
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.subs.push(this.route.queryParams.subscribe((params: any) => {
      if (params && params.resetcode && params.resetcode !== '') {
        sessionStorage.setItem('resetPasswordCode', params.resetcode);
        this.hasCode = true;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  doneClicked() {
    this.navigation.navigateToLoginPage();
  }
}
