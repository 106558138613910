<div class="d-flex flex-row m-0 mb-3">
    <span class="w-100 pb-2">
        Thank you, your order
        <!--        <ng-container *ngIf="(confirmation$ | async) as id">-->
        <!--            <span class="fw-bold">#{{ id }}</span>-->
        <!--        </ng-container>-->
        has been sent to our processor.
    </span>
    <span class="w-100 pb-2">
        You will receive an email
        in about five to ten minutes, either confirming your
        eGift order or notifying you of an error in your
        credit card transaction. Please note that if the
        purchase was completed, the balance has been applied
        directly to your account. If you do not receive an
        email, please check your Spam filter and add our email address to your safe senders list.
        You can check your updated balance on the
        <a class="de-primary-link" routerLink="/profile/rewards">rewards page</a> in your user profile.
    </span>
    <!--    <span class="w-100">-->
    <!--        Please add guest@paytronix.com to your safe senders list.-->
    <!--    </span>-->
</div>

<hr class="bg-light">

<div class="d-flex flex-row m-0">
    <span class="w-100 h5">
        Billing Information
    </span>
    <ng-container *ngIf="(previousOrder$ | async)?.billingInfo as info">
        <span class="w-100 pb-2">
            {{ info.firstName }} {{ info.lastName }}
        </span>
        <span class="w-100">
            {{ info.address.streetAddress }}
        </span>
        <span class="w-100">
            {{ info.address.city }}, {{ info.address.state }} {{ info.address.zipCode }}
        </span>
        <span class="w-100 pb-2">
            {{ info.address.country }}
        </span>
        <span class="w-100 pb-2">
            {{ info.phoneNumber }}
        </span>
        <span class="w-100">
            {{ info.emailAddress }}
        </span>
    </ng-container>
</div>
