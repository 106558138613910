import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrderService} from 'src/services/vendor-config-service/order.service';
import {Menu} from 'src/interfaces/menu.interface';
import {MenuService} from 'src/services/vendor-config-service/menu.service';
import {filter, switchMap, take} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {GiftCardItem} from '../../../../../interfaces/gift-card-item.interface';
import {Order} from '../../../../../interfaces/order.interface';
import {LoyaltyService} from '../../../../../services/vendor-config-service/loyalty.service';
import {GiftCard} from '../../../../../interfaces/giftcard.interface';
import {User} from '../../../../../interfaces/user.interface';

@Injectable()
export class CheckoutPageService {

  private paymentStatusSubject = new BehaviorSubject<string>(null);
  paymentStatus$ = this.paymentStatusSubject.asObservable();
  private confirmationIDSubject = new BehaviorSubject<string>(null);
  confirmationID$ = this.confirmationIDSubject.asObservable();
  private menuSubject = new BehaviorSubject<Menu>(null);
  menu$ = this.menuSubject.asObservable();
  private errorSubject = new BehaviorSubject<Error>(null);
  error$ = this.errorSubject.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private orderProviderSubject = new BehaviorSubject<any>('');
  orderProvider$ = this.orderProviderSubject.asObservable();

  constructor(
      private orderService: OrderService,
      private menuService: MenuService,
      private store: Store,
      private loyaltyService: LoyaltyService
  ) {
    this.orderService.getService().pipe(take(1)).subscribe(provider => {
      this.orderProviderSubject.next(provider);
    });
  }

  checkGiftCardBalance(cardNumber: string, pin: string): Observable<GiftCardItem> {
    return this.store.select(state => state.order.order).pipe(filter(u => u !== null), take(1), switchMap((order: Order) => {
      return this.orderService.getService().pipe(switchMap(oService => {
        return oService.getGiftCardBalance(order.orderID, cardNumber, pin);
      }));
    }));
  }

  retrieveLoyaltyGiftCard(): Observable<GiftCard> {
    return this.store.select(state => state.user.user).pipe(filter(u => u !== null), take(1), switchMap((user: User) => {
      return this.loyaltyService.getService().pipe(switchMap(lService => {
        return lService.getLoyaltyStoredValueCardInfo(user.userID)
      }))
    }))
  }
}
