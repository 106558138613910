import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textColor',
})
export class TextColorPipe implements PipeTransform {
  transform(backgroundColor: string): string {
    // Function to convert hex color to RGB
    const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
      // Remove the hash symbol if present
      hex = hex.replace('#', '');

      // Handle shorthand hex colors (e.g., #FFF)
      if (hex.length === 3) {
        hex = hex
          .split('')
          .map(char => char + char)
          .join('');
      }

      // Ensure the hex string is valid
      if (hex.length !== 6) {
        return null;
      }

      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return { r, g, b };
    };

    // Attempt to parse the background color
    let rgb: { r: number; g: number; b: number } | null = null;

    if (backgroundColor.startsWith('#')) {
      rgb = hexToRgb(backgroundColor);
    } else {
      // Optional: Handle other color formats like rgb(), named colors, etc.
      // For simplicity, we'll default to black if the format is unsupported
      return 'black';
    }

    // If parsing failed, default to black
    if (!rgb) {
      return 'black';
    }

    const { r, g, b } = rgb;

    // Calculate the YIQ value to determine brightness
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Return 'black' for light backgrounds and 'white' for dark backgrounds
    return yiq >= 128 ? 'black' : 'white';
  }
}
