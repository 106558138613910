<ng-container *ngIf="isOloWrapped; else notWrapped">
  <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
    <ion-header style="z-index: 10001;">
      <ion-toolbar class="contents"
                   style="padding-top: env(safe-area-inset-top) !important; --background: var(--nav-mobile-background-color); color: var(--nav-mobile-text-color);"
                   mode="ios">
        <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
          <ng-container *ngIf="user$ | async as user">
            <button type="button" class="tab" routerLinkActive #rlaHome="routerLinkActive"
                    [routerLink]="user.isGuest ? '/' :'/welcome'"
                    [routerLinkActiveOptions]="{exact: true}"
                    [style.background-color]="mobileAppSettings.background_color">
              <div class="tab-icon">
                <img
                  [src]="rlaHome.isActive ? mobileAppSettings.active_home_icon?.data?.full_url : mobileAppSettings.inactive_home_icon?.data?.full_url"
                  [alt]="mobileAppSettings.home_section_copy">
              </div>

              <!--              <div class="tab-title"-->
              <!--                   [style.color]="rlaHome.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">-->
              <!--                {{mobileAppSettings.home_section_copy}}-->
              <!--              </div>-->
            </button>
          </ng-container>
        </ng-container>

        <ion-title class="text-center" mode="ios">{{ title | capitalize }}</ion-title>

        <ion-buttons slot="end" class="back-btn">
          <div style="height: 48px; width: 48px">
            <ng-container *ngIf="mainSettings$ | async as mainSettings">
              <ng-container *ngIf="routeBack !== ''; else menu">
                <ion-back-button [defaultHref]="null" class="show-back-button menu-icon" mode="md"
                                 style="color: var(--nav-mobile-icon-color);"
                                 (click)="backButton()">
                </ion-back-button>
              </ng-container>
              <ng-template #menu>
                <ng-container
                  *ngIf="!(mainSettings.mobile_app_layout === 'improved' && capacitor.getPlatform() !== 'web') else improvedEarn">
                  <ion-buttons class="menu-icon" slot="start">
                    <ion-menu-button style="color: var(--nav-mobile-icon-color);"
                    ></ion-menu-button>
                  </ion-buttons>
                </ng-container>
              </ng-template>
              <ng-template #improvedEarn>
                <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                  <div *ngIf="vendorSetup.loyalty_provider !== 'none'"
                       class="d-flex flex-column justify-content-center align-items-center h-100 menu-icon"
                       (click)="openEarn()"
                       style="color: var(--nav-mobile-icon-color); fill: var(--nav-mobile-icon-color);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-qr-code-scan"
                         viewBox="0 0 16 16">
                      <path
                        d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z" />
                      <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z" />
                      <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z" />
                      <path
                        d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z" />
                      <path d="M12 9h2V8h-2v1Z" />
                    </svg>
                    <small
                      style="font-size: 10px; color: var(--nav-mobile-icon-color);">{{ isCheckin ? 'Check-In' : 'Earn' }}</small>

                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
          <ng-content></ng-content>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  </ng-container>
</ng-container>
<ng-template #notWrapped>
  <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
    <div ngbDropdown #headerDrop="ngbDropdown">
      <ion-header style="z-index: 10001;">
        <ion-toolbar class="contents"
                     style="padding-top: env(safe-area-inset-top) !important; --background: var(--nav-mobile-background-color); color: var(--nav-mobile-text-color);"
                     mode="ios"
        >
          <ion-buttons slot="start" class="back-btn">

            <div style="height: 48px; width: 48px">
              <ng-container *ngIf="mainSettings$ | async as mainSettings">
                <ng-container *ngIf="routeBack !== ''; else menu">
                  <ion-back-button [defaultHref]="null" tabindex="0" class="show-back-button menu-icon" mode="md"
                                   style="color: var(--nav-mobile-icon-color);" (click)="backButton()"
                                   (keydown.enter)="backButton()">
                  </ion-back-button>
                </ng-container>
                <ng-template #menu>
                  <ng-container
                    *ngIf="!(mainSettings.mobile_app_layout === 'improved' && capacitor.getPlatform() !== 'web') else improvedEarn">
                    <ion-buttons class="menu-icon position-relative" slot="start">
                      <ng-container
                        *ngIf="(inboxMessages | unreadMessage) || (notificationService.getUnreadNotificationCount())">
                        <div class="position-absolute"
                             style="top: 0.5rem; right: 0.5rem; border-radius: 1rem; border: 1px solid var(--ion-color-primary); height: 8px; width: 8px; background-color: var(--ion-color-primary);"><span
                          class="visually-hidden">You have unread messages</span></div>
                      </ng-container>
                      <ion-menu-button tabindex="0" style="color: var(--nav-mobile-icon-color);"
                                       (click)="this.menuController.open()"
                                       (keydown.enter)="this.menuController.open()">

                      </ion-menu-button>
                    </ion-buttons>
                  </ng-container>
                </ng-template>
                <ng-template #improvedEarn>
                  <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                    <div *ngIf="vendorSetup.loyalty_provider !== 'none'"
                         class="d-flex flex-column justify-content-center align-items-center h-100 menu-icon"
                         (click)="openEarn()"
                         style="color: var(--nav-mobile-icon-color); fill: var(--nav-mobile-icon-color);">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           class="bi bi-qr-code-scan"
                           viewBox="0 0 16 16">
                        <path
                          d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z" />
                        <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z" />
                        <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z" />
                        <path
                          d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z" />
                        <path d="M12 9h2V8h-2v1Z" />
                      </svg>
                      <small
                        style="font-size: 10px; color: var(--nav-mobile-icon-color);">{{ isCheckin ? 'Check-In' : 'Earn' }}</small>

                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
            <ng-content></ng-content>
          </ion-buttons>
          <div class="d-flex w-100 justify-content-center align-items-center">
            <div class="w-75">
              <app-nav-order-type tabindex="0" [order]="order" id="location-dropdown"
                                  (click)="startNewOrderClick($event)"
                                  (keydown.enter)="startNewOrderClick($event)"
                                  ngbDropdownToggle></app-nav-order-type>
            </div>
          </div>

          <ion-buttons tabindex="0" slot="end" class="nav-icon" id="cartIcon" (click)="routeToCart()"
                       (keydown.enter)="routeToCart()">
            <ng-container *ngIf="customIcons$ | async as customIcons">
              <ng-container
                *ngIf="customIcons.empty_cart_icon?.data?.full_url && customIcons.filled_cart_icon?.data?.full_url else bootstrapCart">
                <ng-container *ngIf="order && order?.items && order?.items?.length > 0 else emptyCart">
                  <div class="w-100 h-100" style="display:grid;place-items: center"><img alt="Filled Cart"
                                                                                         [src]="customIcons.filled_cart_icon.data.full_url">
                  </div>
                </ng-container>
                <ng-template #emptyCart>
                  <div class="w-100 h-100" style="display:grid;place-items: center"><img alt="Empty Cart"
                                                                                         [src]="customIcons.empty_cart_icon.data.full_url">
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #bootstrapCart><i class="{{ cartIcon | bootstrapIcon }}"
                                             style="font-size: 1.5rem; color: var(--nav-mobile-icon-color);"></i>
              </ng-template>

            </ng-container>
            <div class="cart-count" *ngIf="order && order?.items && order?.items?.length > 0"
                 [ngStyle]="{'background-color': navbarSettings.cart_badge_color, 'color': navbarSettings.cart_badge_text_color}">
              {{ basketCount(order?.items) }}
            </div>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <app-order-type-list
        class="order-type-dropdown drop-shadow"
        ngbDropdownMenu
        (addNewOrderType)="newOrderTypeClicked(); headerDrop.close();"
        (startNewOrder)="startOrder($event); headerDrop.close();"
      ></app-order-type-list>
    </div>
  </ng-container>
</ng-template>
