import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PinPadDeviceService {
  // public pinPadMACAddress = 'B40016CCFECE'; // this default ID is the Chepri Link/2500
  public pinPadMACAddress = '38EFE38A4EED'; // this default ID is the Chepri Lane/3000
  public pinPadType = 'EMV_LANE3000';
  public pinPadProvider = 'DATACAP';
  public pinPadAddressType = 'MAC';
  public terminalID: '03499411';
  // secureDevice: 'CloudEMV2',
  private pinPadMACAddressKey = 'pinPadMacAddress';
  private pinPadTypeKey = 'pinPadType';
  private pinPadProviderKey = 'pinPadProvider';
  private pinPadAddressTypeKey = 'pinPadAddressType';

  constructor() {
    const storedType = this.getStoredPinPadType();
    if (storedType) {
      this.pinPadType = storedType;
    }
    const storedProvider = this.getStoredPinPadProvider();
    if (storedProvider) {
      this.pinPadProvider = storedProvider;
    }
    const storedAddress = this.getStoredPinPadMACAddress();
    if (storedAddress) {
      this.pinPadMACAddress = storedAddress;
    }
    const storedAddressType = this.getStoredPinPadAddressType();
    if (storedAddressType) {
      this.pinPadAddressType = storedAddressType;
    }
  }

  updateStoredPinPadMACAddress(newPinPadMACAddress: string) {
    this.pinPadMACAddress = newPinPadMACAddress;
    localStorage.setItem(this.pinPadMACAddressKey, newPinPadMACAddress);
  }

  updateStoredPinPadType(newPinPadType: string) {
    this.pinPadType = newPinPadType === 'lane3000' ? 'EMV_LANE3000' : 'EMV_LINK2500';
    localStorage.setItem(this.pinPadTypeKey,  this.pinPadType);
  }

  updateStoredPinPadProvider(newPinPadProvider: string) {
    this.pinPadProvider = newPinPadProvider === 'datacap' ? 'DATACAP_E2E' : 'HEARTLAND';
    localStorage.setItem(this.pinPadProviderKey, this.pinPadProvider);
  }

  updateStoredPinPadAddressType(newPinPadAddressType: string) {
    this.pinPadAddressType = newPinPadAddressType;
    localStorage.setItem(this.pinPadAddressTypeKey, newPinPadAddressType);
  }

  getStoredPinPadMACAddress(): string {
    return localStorage.getItem(this.pinPadMACAddressKey);
  }

  getStoredPinPadType(): string {
    return localStorage.getItem(this.pinPadTypeKey);
  }

  getStoredPinPadProvider(): string {
    return localStorage.getItem(this.pinPadProviderKey);
  }

  getStoredPinPadAddressType(): string {
    return localStorage.getItem(this.pinPadAddressTypeKey);
  }

  clearStoredPinPadMACAddress() {
    localStorage.removeItem(this.pinPadMACAddressKey);
  }

  clearStoredPinPadType() {
    localStorage.removeItem(this.pinPadTypeKey);
  }

  clearStoredPinPadProvider() {
    localStorage.removeItem(this.pinPadProvider);
  }

  clearStoredPinPadAddressType() {
    localStorage.removeItem(this.pinPadAddressTypeKey);
  }
}
