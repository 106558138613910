import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../interfaces/product.interface';
import { Option } from '../interfaces/option.interface';
import { OptionGroup } from '../interfaces/option-group.interface';

@Pipe({
  name: 'productCustomizationCompletion',
  pure: false,
})
export class ProductCustomizationCompletionPipe implements PipeTransform {
  private incompleteSections: string[] = [];

  transform(product: Product, selectedModifiers: Option[]): { complete: boolean; incompleteSections: string[] } {
    this.incompleteSections = [];
    const complete = product?.optionGroups
      ? product.optionGroups.every(optionGroup => this.optionGroupValid(optionGroup, selectedModifiers))
      : true;
    return {
      complete,
      incompleteSections: this.incompleteSections,
    };
  }

  private optionGroupValid(ingredientGroup: OptionGroup, selectedOptions: Option[]): boolean {
    const selectedChildren = selectedOptions.filter(option =>
      ingredientGroup.options.find(gOption => gOption.optionID === option.optionID)
    );
    if (
      (!ingredientGroup.mandatorySelection && !ingredientGroup.minRequired) ||
      (ingredientGroup.mandatorySelection && !ingredientGroup.minRequired && selectedChildren.length === 1) ||
      (ingredientGroup.minRequired && selectedChildren.length >= Number(ingredientGroup.minRequired))
    ) {
      return selectedChildren.every(child => child.optionGroups.every(mod => this.optionGroupValid(mod as OptionGroup, selectedOptions)));
    } else {
      this.incompleteSections.push(ingredientGroup.description);
      return false;
    }
  }
}
