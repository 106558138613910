<ng-container *ngIf="customPage.header_image?.data else noImage">
    <img (click)="tileClick.emit()" class="cms-card-radius card more-card-img shadow-sm" [src]="customPage.header_image?.data?.full_url">
</ng-container>

<ng-template
  #noImage>
  <div class="more-card card shadow-sm p-3" (click)="tileClick.emit()" [style.background-image]="branding.default_more_tile_background_image?.data?.full_url | cssUrl">
    <div class="icon-container pb-2">
      <!--        image should be set to this for default, and then pull from cms if there is an entry-->
      <!--    TODO: switch to image with page icon-->
      <i [class]="(customPage.icon | bootstrapIcon)" class="d-flex align-item-center" style="font-size: 2rem"></i>
    </div>
    <div class="more-title text-break">
      {{ customPage.title }}
    </div>
  </div>
</ng-template>