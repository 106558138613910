<ng-container *ngIf="!(allProductsAlwaysAvailable$ | async)">
  <div class="menu-limit">
    <small>{{!(showFullMenu$ | async) ? 'Current' : 'Full'}} Menu</small>
    <button type="button" [class.map-bg]="!(showFullMenu$ | async)"
            [class.list-bg]="(showFullMenu$ | async)"
            class="d-flex align-items-center m-0 p-1 toggle-switch border" (click)="menuService.toggleFullMenu()">
      <div class="d-flex justify-content-center align-items-center toggle-circle"
           [class.map-toggle]="(showFullMenu$ | async)" [class.list-toggle]="!(showFullMenu$ | async)">
        <i class="bi d-flex" [ngClass]="(showFullMenu$ | async) ? 'bi-clock' : 'bi-clock-history'"></i>
      </div>
    </button>
  </div>
</ng-container>
