<!-- offers-section.component.html -->
<div class="offers-section">
  <div class="w-100 h-100">
    <div class="d-flex flex-column gap-3 w-100 h-100">
      <!-- Header -->
      <div class="w-100 d-flex flex-row flex-nowrap gap-2">
        <h4 class="text-start fw-bold header-font d-flex flex-row align-items-center">
          <app-custom-icon
            class="color-primary me-2"
            default="bi-tag"
            customIconKey="offers_navigation_icon"
            altText="Offers"
          ></app-custom-icon>
          Offers
        </h4>
      </div>
      <!-- Offers List -->
      <ng-container *ngIf="offers?.length > 0; else noOffers">
        <div class="offers-cards-container">
          <app-offer-card
            *ngFor="let offer of offers; trackBy: offerTrackBy"
            [offer]="offer"
            (clickedRedeem)="onRedeemOffer(offer)"
          ></app-offer-card>
        </div>
      </ng-container>
      <!-- No Offers Template -->
      <ng-template #noOffers>
        <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-5">
          <app-custom-icon
            class="color-primary"
            default="bi-tag"
            customIconKey="offers_navigation_icon"
            size="58"
            altText="Offers"
          ></app-custom-icon>
          <div class="text-center d-flex flex-column">
            <h5 class="fw-bold">You currently have no offers.</h5>
            <div>Check back later for new offers!</div>
          </div>
        </div>
        <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
          <app-custom-icon
            class="color-primary display-3"
            default="bi-tag"
            customIconKey="offers_navigation_icon"
            altText="Offers"
          ></app-custom-icon>
          <div class="text-center d-flex flex-column">
            <h5 class="fw-bold">You currently have no offers.</h5>
            <div>Check back later for new offers!</div>
          </div>
          <button
            class="de-primary-link w-100"
            style="max-width: 264px"
            [routerLink]="locationsRoute">
            Order Now
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
