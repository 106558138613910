<ng-container *ngIf="balances$ | async as balances">
  <ng-container
    *ngIf="
                balances.bankedRewards &&
                displayVendorSetup?.loyalty_provider === 'punchh-olo'
              ">
    <div class="rewards-category-item">
      <div class="p-3 bg-light w-100 h-100 de-border cms-card-radius">
        <div class="d-flex flex-column gap-3 w-100 h-100">
          <div class="w-100 d-flex flex-row flex-nowrap gap-2">
            <div class="d-flex flex-column">
              <div class="d-flex flex-row flex-nowrap gap-2">
                <div
                  class="text-start fw-bold font-size-subheading-1 header-font"
                >
                  Convert Reward Dollars to a Reward
                </div>
              </div>
              <div class="fw-bold">
                Choose how many banked rewards dollars you would like
                to convert into a reward. When you create a reward,
                your selected amount of dollars will be deducted from
                your dollar count and turned into a redeemable reward.
              </div>
              <h4 class="m-0 pt-3 fw-bold">
                Current Dollars:
                {{
                  balances.bankedRewards | currency: (currencyCode$ | async)
                }}
              </h4>
            </div>
          </div>

          <form
            (ngSubmit)="redeemClicked()"
            [formGroup]="dollarConversion"
            style="display: grid; width: 100%; grid-gap: 1rem"
          >
            <div class="form-group m-0 p-0 w-100">
              <label for="dollarAmount">Enter Dollar Amount</label>
              <div class="input-group">
                <input
                  type="text"
                  id="dollarAmount"
                  class="form-control"
                  style="height: unset"
                  formControlName="dollarAmount"
                  [dropSpecialCharacters]="false"
                  [specialCharacters]="['.']"
                  mask="0*.00"
                  placeholder="$0.00"
                  inputmode="decimal"
                />
                <div class="input-group-append">
                  <button
                    class="btn de-primary-btn"
                    type="button"
                    (click)="redeemClicked()"
                  >
                    Convert
                  </button>
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="
                dollarAmount.invalid &&
                (dollarAmount.dirty || dollarAmount.touched)
              "
            >
              <small class="text-danger" *ngIf="dollarAmount.errors.required"
              >Amount Required</small
              >
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
