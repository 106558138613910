import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionGroup } from '../../../../interfaces/option-group.interface';
import { Option } from '../../../../interfaces/option.interface';
import {
  CustomizeProductBlockComponent,
  OptionStack,
} from '@modules/menu/components';
import { AnimationController, ModalController } from '@ionic/angular';
import { MobileService } from '../../../../services/mobile.service';

@Component({
  selector: 'app-modify-footer',
  templateUrl: './modify-footer.component.html',
  styleUrls: ['modify-footer.component.scss'],
})
export class ModifyFooterComponent implements OnInit {
  @Input() parentStack: OptionStack;
  @Input() parentOptionGroup: OptionGroup;
  @Input() parentOption: Option;
  @Input() depth = 0;
  @Input() multiselect = false;
  @Input() hasSteps = false;
  @Input() currentStepIndex = 0;
  @Input() nextStep: Option;
  @Input() isEditing = false;
  @Input() quantity: number;
  @Input() canAdd = false;
  @Output() continueClick = new EventEmitter<any>();
  @Output() incrementClick = new EventEmitter<any>();
  @Output() decrementClick = new EventEmitter<any>();
  @Output() optionSelected = new EventEmitter<Option[]>();
  @Output() goToGroup = new EventEmitter<OptionStack>();
  modalSteps: HTMLIonModalElement[] = [];
  constructor(
    private modal: ModalController,
    public animation: AnimationController,
    private mobileService: MobileService
  ) {}
  ngOnInit() {}
  clickedContinue() {
    this.continueClick.emit();
  }
  clickedIncrement() {
    this.incrementClick.emit();
  }
  clickedDecrement() {
    this.decrementClick.emit();
  }
  goToSiblingGroup(stepIndex: number) {
    if (stepIndex > 0 && stepIndex !== this.parentOptionGroup.options.length) {
      const enterAnimation = (baseEl: any) => {
        const backdropAnimation = this.animation
          .create()
          .addElement(baseEl.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', '0.20');

        const wrapperAnimation = this.animation
          .create()
          .addElement(baseEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0.01', transform: 'translate(199%,0)' },
            { offset: 1, opacity: '1', transform: 'translate(0,0)' },
          ]);

        return this.animation
          .create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(500)
          .addAnimation([backdropAnimation, wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: any) => {
        return enterAnimation(baseEl).direction('reverse');
      };

      this.modal
        .create({
          component: CustomizeProductBlockComponent,
          componentProps: {
            parentOptionGroup: this.parentOptionGroup,
            parentOption: this.parentOptionGroup.options[stepIndex],
            optionGroups:
              this.parentOptionGroup.options[stepIndex].optionGroups,
            depth: this.depth,
          },
          enterAnimation: this.mobileService.isMobile
            ? enterAnimation
            : undefined,
          leaveAnimation: this.mobileService.isMobile
            ? leaveAnimation
            : undefined,
          cssClass: 'new-modal-content',
        })
        .then((modal: HTMLIonModalElement) => {
          modal.onDidDismiss().then(data => {
            const options = data['data'];
            if (options && options.length) {
              this.optionSelected.emit(options);
            }
          });
          modal.present();
          this.modalSteps.push(modal);
        });
    } else {
      this.modal.dismiss().then(r => {
        console.log('modal close', r);
      });
    }
  }
  goToNextParentGroup() {
    if (
      this.parentOptionGroup?.options &&
      this.parentOptionGroup?.options.length &&
      this.multiselect
    ) {
      this.hasSteps = true;
      this.parentStack.option.optionGroups.forEach(
        (parentOptionGroup: OptionGroup, parentIndex: number) => {
          if (
            this.parentOptionGroup.optionGroupID ===
            parentOptionGroup.optionGroupID
          ) {
            if (parentIndex + 1 < this.parentStack.option.optionGroups.length) {
              this.goToGroup.emit({
                option:
                  this.parentStack.option.optionGroups[parentIndex + 1]
                    .options[0],
                optionGroup:
                  this.parentStack.option.optionGroups[parentIndex + 1],
              });
            } else {
              this.goToGroup.emit({
                option: this.parentStack.option,
                optionGroup: this.parentStack.optionGroup,
              });
            }
          }
        }
      );
    }
  }
}
