import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(input: any) {
    if (input) {
      return input.replace(/[\w']+\b/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return input;
  }
}

// from https://gist.github.com/paulakreuger/b2af1958f3d67f46447e
