import { AfterContentInit, Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { UtilityService } from '@modules/utility/services/utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectusService } from '../../../../../vendors/directus/directus.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HandoffType } from '../../../../../interfaces/handoff-type.enum';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import {
  CancelOrder,
  CheckDeliveryStatus,
  CreateNewOrder,
  FireOrder,
  NotifyOfArrival,
  SetPreviousOrder,
  StageReward,
} from '../../../../../store/actions/order.actions';
import { VendorSetup } from '../../../../../interfaces/vendor.interface';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { MetaService } from '../../../../../services/meta.service';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { Geolocation } from '@capacitor/geolocation';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { KioskContent } from 'src/vendors/directus/interfaces/kiosk-content.interface';
import { GlobalStateModel } from '../../../../../store/state.model';
import { PromotionalContent } from '../../../../../vendors/directus/interfaces/promotional-content.interface';
import { Capacitor } from '@capacitor/core';

let google;

@Component({
  selector: 'app-confirmation-component',
  template: '',
})
export class ConfirmationComponent implements OnInit, OnDestroy, AfterContentInit {
  @Select(state => state.order.previousOrder) previousOrder$: Observable<Order>;
  @Select(state => state.order.deliveryStatus) deliveryStatus$: Observable<any>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.kioskContent) kioskContent$: Observable<KioskContent>;
  @Select(state => state.app.showFooter) showFooter$: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.app.googleMapsLoaded) googleMapsLoaded$: Observable<boolean>;
  @Select(state => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'order confirmation';

  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  kioskContent: KioskContent;
  isPickup = true;
  gmapsKey: string;
  origin;
  destination;
  locLoaded = false;
  loadMap = false;
  unsubscribe$ = new Subject<void>();
  orderGuid;
  trackedDelivery;
  trackerLoading;
  orderReceived = true;
  orderPickup;
  outForDelivery;
  streetaddress;
  zip;
  stepInProgress;
  experienceSelected = null;
  submitted = false;
  sentRating = false;
  feedbackComplete = false;
  orderName: string = sessionStorage.getItem('tablesideGuest');
  orderCompleted = false;
  arriveIsLoading = false;
  pickupInstructions;
  displayBranding: Branding;
  isPayInStore = true;
  kioskGuestName;
  kioskEmailAddress;
  kioskTableNumber;
  feedbackMessage = '';

  displayConfirmation: Order;
  displayReward;
  errorMessage;
  pickupArrival;
  curbsideArrival;
  extraMessage;

  handoffs = HandoffType; // for use in template

  mapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    gestureHandling: 'none',
    disableDefaultUI: true,
  };

  iconSettings: google.maps.MarkerOptions = {
    icon: {
      scaledSize: {
        width: 40,
        height: 40,
      },
      url: '',
    },
  } as google.maps.MarkerOptions;

  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();

  previousOrder = true;

  capacitor = Capacitor; // for template use

  cannotLoadOrder = false;
  paramOrderID;
  reloading = false;

  constructor(
    // private pageService: ConfirmationPageService,
    private util: UtilityService,
    // private navbarService: NavBarService,
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService,
    private directus: DirectusService,
    private sanitizer: DomSanitizer,
    private store: Store,
    private geo: GeocodingService,
    private meta: MetaService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.store.dispatch(new StageReward(null));
    this.isPayInStore = sessionStorage.getItem('kioskPayment') === 'cash';
    this.kioskGuestName = sessionStorage.getItem('kioskGuest');
    this.kioskEmailAddress = sessionStorage.getItem('kioskEmail');
    this.kioskTableNumber = sessionStorage.getItem('kioskTable');
    this.meta.resetToDefault();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.orderGuid = sessionStorage.getItem('deliveryBasketGuid');
    this.displayReward = sessionStorage.getItem('previousOrder');

    this.subs.push(
      this.route.params.subscribe(pathParams => {
        if (pathParams.guid) {
          this.paramOrderID = pathParams.guid;
          this.store
            .dispatch(new SetPreviousOrder(pathParams.guid))
            .toPromise()
            .then(() => {
              this.cannotLoadOrder = false;
            })
            .catch(() => {
              if (!this.store.selectSnapshot((state: GlobalStateModel) => state.order.previousOrder)) {
                this.cannotLoadOrder = true;
              }
            });
          // this.pageService.getPreviousOrder(pathParams.guid);
        } else {
          this.navigation.navigateTo404Page();
        }
      })
    );
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
      })
    );
    this.subs.push(
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
    this.subs.push(
      this.kioskContent$.subscribe(content => {
        if (content) {
          this.kioskContent = content;
        }
      })
    );
    // Page service data
    this.subs.push(
      this.deliveryStatus$.subscribe(tracker => {
        if (tracker && tracker.deliveries) {
          this.trackedDelivery = tracker.deliveries[0];
        }
      })
    );
    this.subs.push(
      this.previousOrder$
        .pipe(
          filter(o => o !== null),
          distinctUntilChanged((prev, curr) => prev?.orderID === curr.orderID && prev?.orderStatus === curr.orderStatus)
        )
        .subscribe(order => {
          if (order) {
            this.arriveIsLoading = false;
            this.displayConfirmation = order;
            // tslint:disable-next-line:no-string-literal
            if (window['dataLayer']) {
              const products = this.displayConfirmation.items.map(item => {
                return {
                  name: item.name,
                  sku: item.name.toLowerCase().trim(),
                  price: item.totalCents / 100,
                  quantity: item.quantity,
                };
              });
              const body = {
                event: 'order-confirmation',
                transactionId: this.displayConfirmation.customerFacingID
                  ? this.displayConfirmation.customerFacingID
                  : this.displayConfirmation.orderID,
                transactionTotal: this.displayConfirmation.totalCents / 100,
                transactionTax: this.displayConfirmation.taxCents / 100,
                transactionProducts: products,
              };
              // tslint:disable-next-line:no-string-literal
              window['dataLayer'].push(body);
            }
            this.iconSettings = {
              icon: {
                scaledSize: {
                  width: 40,
                  height: 40,
                },
                url: this.displayConfirmation.location?.mapIconURL,
              },
            } as google.maps.MarkerOptions;
            if (this.displayConfirmation && this.displayConfirmation.orderStatus.toLowerCase() === 'completed') {
              this.orderCompleted = true;
            }
            // if (!this.orderCompleted) {
            //   const previousOrder: Order = this.displayConfirmation;
            // tslint:disable-next-line:max-line-length
            //   const address = previousOrder.handoffType === HandoffType.delivery || previousOrder.handoffType === HandoffType.dispatch ? previousOrder.deliveryAddress : null;
            //   this.store.dispatch(new CreateNewOrder(previousOrder.location.locationID, previousOrder.handoffType, address));
            // }
            // if (!this.orderCompleted) {
            //   const previousOrder: Order = this.displayConfirmation;
            //   tslint:disable-next-line:max-line-length
            //   const address = previousOrder.handoffType === HandoffType.delivery || previousOrder.handoffType === HandoffType.dispatch ? previousOrder.deliveryAddress : null;
            //   this.store.dispatch(new CreateNewOrder(previousOrder.location.locationID, previousOrder.handoffType, address));
            // }
            // tslint:disable-next-line:max-line-length
            this.isPickup =
              this.displayConfirmation.handoffType !== HandoffType.dispatch &&
              this.displayConfirmation.handoffType !== HandoffType.delivery;
            this.destination = {
              lat: this.displayConfirmation.location.address.latitude,
              lng: this.displayConfirmation.location.address.longitude,
            };
            this.directus
              .getSingleLocationByID(this.displayConfirmation.location.locationID)
              .pipe(take(1))
              .subscribe(res => {
                if (res.pickup_instructions) {
                  this.pickupInstructions = res.pickup_instructions;
                }
              });
            // tslint:disable-next-line:max-line-length
            // if (this.displayConfirmation.handoffType === HandoffType.dispatch) {
            //   this.updateDeliveryTracking();
            // }
          }
        })
    );
    // this.subs.push(this.pageService.error$.subscribe(error => {
    //   if (error) {
    //     this.arriveIsLoading = false;
    //     if (error.error.message) {
    //       this.errorMessage = error.error.message;
    //     }
    //   }
    //
    // }));
    /* If we want to edit customer arrival from cms
    this.directus.getOloSettings().subscribe(olo => {
      if (olo) {
        this.pickupArrival = olo.pickup_arrival_notification;
        this.curbsideArrival = olo.curbside_arrival_notification;
      }
    });
    */
    Geolocation.getCurrentPosition({ enableHighAccuracy: false, timeout: 10000 })
      .then(pos => {
        this.origin = { lat: +pos.coords.latitude, lng: +pos.coords.longitude };
        this.locLoaded = true;
      })
      .catch(err => {
        console.log('Geolocation Error:', err);
      });
  }

  getTypeOfGoogle() {
    return typeof google;
  }

  customerArrivalClicked() {
    this.arriveIsLoading = true;
    this.store
      .dispatch(new NotifyOfArrival(this.orderGuid, this.extraMessage))
      .toPromise()
      .then(() => {
        this.arriveIsLoading = false;
      })
      .catch(error => {
        this.arriveIsLoading = false;
        if (error.error.message) {
          this.errorMessage = error.error.message;
        }
      });
    // this.pageService.notifyOfArrival(this.orderGuid, this.extraMessage);
  }

  updateDeliveryTracking() {
    this.store.dispatch(new CheckDeliveryStatus(this.displayConfirmation.orderID));
  }

  ngAfterContentInit() {
    setTimeout(() => (this.loadMap = true), 0); // Fix for grey map if you switch between desktop and mobile
  }

  ngOnDestroy() {
    sessionStorage.removeItem('kioskPayment');
    this.subs.forEach(sub => sub.unsubscribe());
  }

  openNavigation() {
    const preppedAddress = encodeURIComponent(this.displayConfirmation.location.address.address1.replace(/[ ]+/g, '+'));
    if (window) {
      window.open(
        'https://www.google.com/maps/dir/Current+Location/' +
          preppedAddress +
          '+' +
          this.displayConfirmation.location.address.state +
          '+' +
          this.displayConfirmation.location.address.zipCode,
        '_blank',
        'noopener=yes'
      );
    } else {
      document.location.href =
        'https://www.google.com/maps/dir/Current+Location/' +
        preppedAddress +
        '+' +
        this.displayConfirmation.location.address.state +
        '+' +
        this.displayConfirmation.location.address.zipCode;
    }
  }

  // openDirections() {
  //   this.util.openDirections(`${this.streetaddress}, ${this.zip}`);
  // }

  selectedRating(index: number) {
    this.experienceSelected = index;
    this.submitted = false;
  }

  orderTableSideAgainClicked() {}

  newOrderClicked() {
    const previousOrder: Order = this.displayConfirmation;
    const address =
      previousOrder.handoffType === HandoffType.dispatch || previousOrder.handoffType === HandoffType.delivery
        ? previousOrder.deliveryAddress
        : null;
    this.store
      .dispatch(new CreateNewOrder(previousOrder.location.locationID, previousOrder.handoffType, address))
      .toPromise()
      .then(() => {
        const path = previousOrder.location.slugURL ? previousOrder.location.slugURL : previousOrder.location.locationID;
        this.navigation.navigateToMenuPage(path);
      });
  }

  newTablesideOrderClicked() {
    // this.navigation.navigateToHomePage();
    this.router
      .navigate(['/'], { queryParams: { mode: 'tableside', location: this.displayConfirmation.location.locationID } })
      .then(() => window.location.reload());
  }

  reportRating() {
    this.submitted = true;
    if (!this.sentRating) {
      this.sentRating = true;
      this.directus
        .sendFeedback({
          feedback: this.feedbackMessage,
          star_rating: this.experienceSelected + 1,
          restaurant_id: this.displayConfirmation.location.locationID,
          order_info: this.displayConfirmation,
        })
        .subscribe(
          () => {
            console.log('Feedback sent!');
            this.feedbackComplete = true;
            this.experienceSelected = null;
          },
          () => {
            console.log('Could not send feedback');
          }
        );
    }
  }

  cancelOrderClicked(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'cancelOrderModal', centered: true, windowClass: 'kiosk-modal' }).result.then(
      result => {
        console.log(`Closed with: ${result}`);
      },
      reason => {
        console.log(`Closed with: ${reason}`);
      }
    );
  }

  cancelKioskOrder(order: Order) {
    this.store
      .dispatch(new CancelOrder(order))
      .toPromise()
      .then(() => {
        this.modalService.dismissAll();
        const locationId = localStorage.getItem('kiosklocation');
        this.navigation.navigateToKioskStartOrderPage(locationId);
      })
      .catch(() => {
        console.log('there was an error canceling');
      });
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  startOverKiosk() {
    const locationId = localStorage.getItem('kiosklocation');
    this.navigation.navigateToKioskStartOrderPage(locationId);
  }

  reloadOrder() {
    this.reloading = true;
    this.store
      .dispatch(new SetPreviousOrder(this.paramOrderID))
      .toPromise()
      .then(() => {
        this.reloading = false;
        this.cannotLoadOrder = false;
      })
      .catch(() => {
        this.reloading = false;
        if (!this.store.selectSnapshot((state: GlobalStateModel) => state.order.previousOrder)) {
          this.cannotLoadOrder = true;
        }
      });
  }

  fireOrder() {
    if (this.displayConfirmation.isManualFire) {
      console.log('Firing order');
      this.store.dispatch(new FireOrder());
    }
  }
}
