import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ProxyBaseURLService} from 'src/services/proxy-base-url.service';
import {from} from 'rxjs';
import {Preferences as Storage} from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class NovaDineHttpService {

  private vendorName = 'novadine';
  private cookiesKey = 'NovadineCookies';
  private cookiesHeader = 'X-Novadine-Cookies';

  private headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient, private urlService: ProxyBaseURLService) {
  }

  protected handleResponse(resp: HttpResponse<any>): Observable<any> {
    return this.getCookies().pipe(switchMap(savedCookies => {
      let cookies = resp.headers.get(this.cookiesHeader);
      if (cookies) {
        cookies = JSON.parse(atob(cookies));
        const stored = savedCookies || {};
        // @ts-ignore
        cookies = {...stored, ...cookies};
        return this.setCookies(cookies).pipe(map(() => {
          return resp.body;
        }));
      }
      return of(resp.body);
    }));
  }

  protected setCookies(cookies): Observable<void> {
    return from(Storage.set({key: this.cookiesKey, value: JSON.stringify(cookies)}));
    // cookies = JSON.stringify(cookies);
    // localStorage.setItem(this.cookiesKey, cookies);
  }

  protected getCookies(): Observable<any> {
    return from(Storage.get({key: this.cookiesKey})).pipe(map(data => {
      return JSON.parse(data.value);
    }));
    // const cookies = localStorage.getItem(this.cookiesKey);
    // return JSON.parse(cookies);
  }

  protected reqHeaders(): Observable<HttpHeaders> {
    return this.getCookies().pipe(map(savedCookies => {
      let headers = this.headers;
      let cookies = savedCookies;
      if (cookies) {
        cookies = btoa(JSON.stringify(cookies));
        headers = this.headers.set(this.cookiesHeader, cookies);
      }
      return headers;
    }));
  }

  get<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.reqHeaders().pipe(switchMap(headers => {
        return this.http.get<T>(baseURL + resource, {headers, withCredentials: true, observe: 'response'}).pipe(switchMap(res => {
          return this.handleResponse(res);
        }), catchError(e => {
          return throwError(e);
        }));
      }));
    }));
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.reqHeaders().pipe(switchMap(headers => {
        return this.http.put<T>(baseURL + resource, body, {headers, withCredentials: true, observe: 'response'}).
            pipe(switchMap(res => {
              return this.handleResponse(res);
            }), catchError(e => {
              return throwError(e);
            }));
      }));
    }));
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.reqHeaders().pipe(switchMap(headers => {
        return this.http.post<T>(baseURL + resource, body, {headers, withCredentials: true, observe: 'response'}).
            pipe(switchMap(res => {
              return this.handleResponse(res);
            }), catchError(e => {
              return throwError(e);
            }));
      }));

    }));
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.reqHeaders().pipe(switchMap(headers => {
        return this.http.patch<T>(baseURL + resource, body, {headers, withCredentials: true, observe: 'response'}).
            pipe(switchMap(res => {
              return this.handleResponse(res);
            }), catchError(e => {
              return throwError(e);
            }));
      }));

    }));
  }

  delete<T>(resource: string, params?: HttpParams): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.reqHeaders().pipe(switchMap(headers => {
        return this.http.delete<T>(baseURL + resource, {params, headers, withCredentials: true, observe: 'response'}).
            pipe(switchMap(res => {
              return this.handleResponse(res);
            }), catchError(e => {
              return throwError(e);
            }));
      }));
    }));
  }
}
