<div tabindex="0" class="card-shell h-100 d-flex flex-column border overflow-hidden" style="cursor: pointer;"
     (click)="cardClicked(name)" (keydown.enter)="cardClicked(name)">
    <ng-container>
        <!-- card top -->
        <div class="card-top d-flex w-100 h-auto bg-transparent border-bottom">
            <!-- inner shell -->
            <div class="d-flex flex-column justify-content-between w-100 h-100 position-relative">
                <!-- info button -->
                <ng-container *ngIf="mode.mode !== 'kiosk'">
                    <div class="card-info-button bg-light border-end border-bottom d-flex align-items-center align-self-start"
                         [ngbTooltip]="description" placement="bottom-left" *ngIf="description">
                        <i class="bi bi-info-circle" width="16" height="16"></i>
                        <!-- <div class="fw-bold">
                        Info
                      </div> -->
                    </div>
                </ng-container>
                <!-- product image -->
                <img *ngIf="imageUrl" [alt]="name" [defaultImage]="'assets/img/600x600.png'" [lazyLoad]="imageUrl"
                     onerror="this.src='assets/img/600x600.png'" style="object-fit: cover">
                <div class="no-image-category" *ngIf="!imageUrl">
                    <img class="image" src="../../../../assets/img/600x600.png" [alt]="name">
                    <div class="no-image-text header-font card-text-color">{{ name }}</div>
                </div>
            </div>
        </div>

        <!-- card bottom -->
        <ng-container *ngIf="mainSettings$ | async as mainSettings">
            <ng-container *ngIf="mainSettings.show_category_name">
                <div class="card-bottom d-flex w-100 align-items-start bg-white">
                    <!-- inner shell -->
                    <div class="card-bottom-inner-shell w-100 h-100 d-flex flex-wrap">
                        <!-- menu item info -->
                        <div class="d-flex flex-column h-auto w-100">
                            <ng-container *ngIf="badgeColor && badgeTextColor && badgeText">
                                <div class="badge rounded-pill w-fit-content mb-1"
                                     [ngStyle]="{'color': badgeTextColor ? badgeTextColor : null, 'background-color': badgeColor ? badgeColor : null }">
                                    {{badgeText}}
                                </div>
                            </ng-container>
                            <!-- menu item title -->
                            <div class="category-title w-100 fw-bold" [style.max-height]="mode.mode === 'kiosk' ? '' : '48px'">
                                {{ name }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #skeleton>

        <div class="custom-skeleton">
            <ion-skeleton-text animated class="skeleton-menu-card"></ion-skeleton-text>
        </div>

    </ng-template>

</div>
