<ng-container *ngIf="(groupOrder$ | async) as groupOrder">
    <!--  <app-route-modal-frame [title]="groupOrder.groupOrderOwner + '\'s Group'" (clickModalClose)="closePage()">-->
    <div [class.bg-light]="mode.mode === 'desktop'" class="h-100" style="overflow-y: scroll;">
        <div class="container d-flex justify-content-center align-items-start p-0">
            <div class="group-created-container mt-0 mt-sm-5 mb-0 mb-sm-5">
                <ng-container *ngIf="mode.mode !== 'tableside'">
                    <div class="d-flex flex-row gap-2">
                        <h2 class="header-font fw-bold grow-1 text-center">
                            Group Order Instructions
                        </h2>
                    </div>
                </ng-container>

                <div class="d-flex flex-column justify-content-between gap-4 h-100 w-100">

                    <div class="d-flex flex-column gap-4 w-100 align-items-center text-center">
                        <ng-container *ngIf="mode.mode !== 'tableside'">
                            <div class="d-flex flex-column border-bottom gap-2 pb-3">

                                <div class="d-flex flex-column text-center">
                                    <h4 class="header-font fw-bold">
                                        Order Deadline
                                    </h4>
                                    <h1 class="text-danger fw-bold">
                                        {{ groupOrder.groupOrderDeadline | date:"shortTime" }}
                                    </h1>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <button type="button" class="dark-link bg-transparent" (click)="goToDetails()">
                                        Edit Details
                                    </button>
                                </div>

                            </div>
                        </ng-container>

                        <p class="text-center mb-2">
                            Invite others to join by sending them a shareable link or by having them scan this QR code.
                        </p>

                        <div class="d-flex justify-content-center">
                            <div class="group-qr-code">
                                <qrcode id="joinUrlQR" [qrdata]="joinUrl" [width]="160" [level]="'M'"
                                        margin="0"></qrcode>
                            </div>
                        </div>


                        <div class="d-flex flex-column align-items-center pt-3">

                            <h5 class="header-font fw-bold text-center mb-1">
                                Share Your Link
                            </h5>
                            <p class="text-center mb-3">Copy the link below and share it with the other people that
                                you'd like to
                                join your group order.</p>

                            <div class="d-flex flex-row gap-3 justify-content-center">

                                <!--      copies link to clipboard-->
                                <button type="button" class="de-dark-btn-outline-sm" (click)="copyLink()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-files"
                                         viewBox="0 0 16 16">
                                        <path
                                                d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                                    </svg>
                                    Copy Group Link
                                </button>
                            </div>
                        </div>

                        <hr class="w-100 mx-3">

                    </div>
                </div>

                <div class="d-flex justify-content-center mb-sm-3">
                    <div class="w-100 pb-4 pb-sm-0">
                        <button type="button" class="de-primary-btn w-100" (click)="goToMenu()">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  </app-route-modal-frame>-->
</ng-container>
