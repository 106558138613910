import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment-timezone';

import { ImageContentService } from '../../../directus/content.service';

import { Order } from '../../../../interfaces/order.interface';
import { HandoffType } from '../../../../interfaces/handoff-type.enum';
import { Location } from '../../../../interfaces/location.interface';
import { OrderItem } from '../../../../interfaces/product.interface';
import { Address } from '../../../../interfaces/address.interface';
import { PaymentTypes } from '../../../../interfaces/payment-types.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalBasketService {
  orderKey = 'LocalOrder';
  private basketGuidKey = 'LocalBasketGuid';

  constructor(private contentService: ImageContentService) {}

  getCurrentOrder(): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return of(order);
  }

  // Creation

  // tslint:disable-next-line:max-line-length
  startOrder(location: Location, handoffType: HandoffType, tableNumber?: string): Observable<Order> {
    sessionStorage.setItem(this.orderKey, JSON.stringify(this.newOrder(location, handoffType)));
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.tableNumber = tableNumber;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(order);
  }

  clearOrder(): Observable<string> {
    sessionStorage.removeItem(this.orderKey);
    this.removeBasketGuid();
    return of(this.getBasketGuid());
  }

  // Location

  setOrderLocation(location: Location): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    if (order) {
      order.location = location;
      sessionStorage.setItem(this.orderKey, JSON.stringify(order));
      return JSON.parse(sessionStorage.getItem(this.orderKey));
    } else {
      sessionStorage.setItem(this.orderKey, JSON.stringify(this.newOrder(location, order.handoffType)));
      return JSON.parse(sessionStorage.getItem(this.orderKey));
    }
  }

  transferBasket(location: Location): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    sessionStorage.removeItem(this.orderKey);
    sessionStorage.setItem(this.orderKey, JSON.stringify(this.newOrder(location, order.handoffType)));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  // Products

  addToOrder(orderItem: OrderItem): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    orderItem.orderItemID = this.generateGUID();
    orderItem.baseCents = orderItem.totalCents;
    orderItem.options.forEach(option => {
      orderItem.totalCents += option.addedCents;
    });
    order.items.push(orderItem);
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    return this.contentService.getOrderItemsWithSlugsandImages(order).pipe(
      switchMap((res: Order) => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(res));
        return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
      })
    );
  }

  removeFromOrder(orderItem: OrderItem): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    const itemToRemove = order.items.filter(item => {
      if (item.orderItemID === orderItem.orderItemID) {
        return item;
      }
    });
    order.items.splice(order.items.indexOf(itemToRemove[0]), 1);
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  updateBasketItem(orderItem: OrderItem): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    const oldItem = order.items.filter(item => {
      if (item.orderItemID === orderItem.orderItemID) {
        return item;
      }
    });
    orderItem.baseCents = orderItem.totalCents;
    orderItem.options.forEach(option => {
      orderItem.totalCents += option.addedCents;
    });
    order.items[order.items.indexOf(oldItem[0])] = orderItem;
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    return this.contentService.getOrderItemsWithSlugsandImages(order).pipe(
      switchMap(res => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(res));
        return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
      })
    );
  }

  // Details

  setHandoffType(handoffType: HandoffType): Observable<Order> {
    const mode = sessionStorage.getItem('mode');
    if (mode === 'tableside') {
      handoffType = 5;
    }
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    if (isNaN(handoffType)) {
      handoffType = order.handoffType;
    }
    order.handoffType = Number(handoffType);
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  // tslint:disable-next-line:max-line-length
  setDispatchOrDeliveryAddress(address: Address, handoffType: HandoffType): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.deliveryAddress = address;
    order.handoffType = handoffType;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)));
  }

  setSpecialInstructions(specialInst: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.specialInstructions = specialInst;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)));
  }

  setTimePreference(time: Date | 'asap'): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    if (typeof time === 'string' && time === 'asap') {
      order.isASAP = true;
      order.earliestReadyTimestamp = moment().add(order.location.orderLeadTime, 'minutes').toDate();
      order.orderReadyTimestamp = moment().add(order.location.orderLeadTime, 'minutes').toDate();
    } else {
      order.isASAP = false;
      order.orderReadyTimestamp = moment(time).toDate();
    }
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  setTip(userID: string | number, orderID: string, tipCents: number): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.tipCents = tipCents * 100;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return this.validateOrder(userID, Number(order.location.locationID), orderID).pipe(
      map(orderRes => {
        orderRes.totalCents += orderRes.tipCents;
        return orderRes;
      })
    );
  }

  setBasketCustomfield(customId: number | string, value: any): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.customFields[customId] = value;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(order);
  }

  // tslint:disable-next-line:max-line-length
  // TODO: This would be hard to implement because we need to validate against some kind of back end database of valid coupons and we would need to send basket data along with the call
  addCoupon(orderID: string | number, couponCode: string | number): Observable<Order> {
    return undefined;
  }

  // TODO: Same here ^^^
  removeCoupon(orderID: string | number): Observable<Order> {
    return undefined;
  }

  // Submission

  // tslint:disable-next-line:max-line-length
  // TODO: Need to formulate a list of requirements for front end validation (i.e. are all the fields filled out) Let backend worry about making sure that that the fields are valid
  validateOrder(userID: string | number, locationID: number, orderID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return of(order);
  }

  // submitPayment(orderID: string, cardDetails: CardDetails, applyCents: number, shouldSave: boolean, token?: string): Observable<Order> {
  //   return undefined;
  // }

  // tslint:disable-next-line:max-line-length
  // submitPaymentAsGuest(orderID: string, cardDetails: CardDetails, applyCents: number, shouldSave: boolean, token?: string): Observable<Order> {
  //   return undefined;
  // }

  private storeBasketGuid(basketGuid: string) {
    sessionStorage.setItem(this.basketGuidKey, basketGuid);
  }

  private getBasketGuid(): string {
    return sessionStorage.getItem(this.basketGuidKey);
  }

  private removeBasketGuid(): void {
    sessionStorage.removeItem(this.basketGuidKey);
  }

  newOrder(location: Location, handoff: HandoffType): Order {
    return {
      orderID: '',
      orderReference: '',
      taxCents: 0,
      subTotalCents: 0,
      tipCents: 0,
      totalCents: 0,
      orderPlacedTimestamp: null,
      appliedCouponCents: 0,
      handoffType: isNaN(handoff) ? 0 : handoff,
      orderReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
      location,
      orderStatus: null,
      items: [],
      deliveryAddress: null,
      earliestReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
      isASAP: true,
      customFields: null,
      dineinCustomFields: null,
      curbsideCustomFields: null,
      appliedCouponCode: null,
      appliedReward: null,
      appliedRewards: [],
      balanceCents: 0,
      isGroup: null,
      paidCents: 0,
      specialInstructions: null,
      tableNumber: sessionStorage.getItem('tablenumber'),
      fees: [],
      deliveryFee: null,
      canTip: true,
      arrivalstatus: null,
      isEditable: false,
      supportedPaymentTypes: location.supportedPaymentTypes ? location.supportedPaymentTypes : [],
      requiresFullBillingAddress: false,
      donationType: [],
      donationsTotal: 0,
    } as Order;
  }

  generateGUID() {
    // tslint:disable-next-line:max-line-length
    return (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();
  }

  private S4() {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
}
