import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rewardCardBackground'
})
export class RewardCardBackgroundPipe implements PipeTransform {

  transform(imageURL: string | null): string {
    if (imageURL) {
      return `linear-gradient(90.49deg, rgba(var(--ion-color-dark-rgb), 0.8) 10.51%, rgba(var(--ion-color-dark-rgb), 0.463412) 62.07%, rgba(var(--ion-color-dark-rgb), 0) 95.99%), url("${imageURL}")`;
    } else {
      return 'var(--ion-color-primary)';
    }
  }

}
