import { Injectable, TemplateRef } from '@angular/core';
import { from, interval, Observable, of } from 'rxjs';
import { SetLoading, SetRouteBack } from '../../../store/actions/app.actions';
import { AddToOrder, CheckProductUpsell, SetOrCreateOrder, UpdateToCurrentBasket } from '../../../store/actions/order.actions';
import { HandoffType } from '../../../interfaces/handoff-type.enum';
import { SetCategory, SetMenu, SetProduct, ToggleFullMenu } from '../../../store/actions/menu.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { HandoffTypeService } from '@modules/cart/services/handoff-type.service';
import { ModeService } from '../../../services/mode.service';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { Location } from '@angular/common';
import { NavigationService } from '@modules/navigation/services';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { Order } from '../../../interfaces/order.interface';
import { OrderItem, Product } from '../../../interfaces/product.interface';
import { Category } from '../../../interfaces/category.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { ToastService } from '../../../services/toast.service';
import { UtilityService } from '@modules/utility/services';
import { distinctUntilKeyChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { MenuService as MenuProviderService } from '../../../services/vendor-config-service/menu.service';
import { Option, OrderItemModifier } from '../../../interfaces/option.interface';
import { OptionGroup } from '../../../interfaces/option-group.interface';
import { produce } from 'immer';
import { MainSettings } from '../../../vendors/directus/interfaces/main-settings.interface';
import { ModalController } from '@ionic/angular';
import { CustomizeProductBlockComponent } from '@modules/menu/components';
import { User } from '../../../interfaces/user.interface';
import { Menu } from '../../../interfaces/menu.interface';
import { GroupOrder } from '../../../interfaces/group-order.interface';
import { MobileService } from '../../../services/mobile.service';
import { AgeCheckComponent } from '@modules/cart/components/age-check/age-check.component';
import { GlobalStateModel } from '../../../store/state.model';
// import {CartService} from '@modules/cart/services';

export interface ConfiguredProduct {
  product: Product;
  options: Option[];
}

@Injectable()
export class MenuService {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  @Select(state => state.order.category) category$: Observable<Category>;
  @Select(state => state.order.product) product$: Observable<Product>;
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;

  restaurantID: string;
  restaurantSlug: string;
  selectedHandoffType = HandoffType.pickup;
  categoryNameSlug: string;
  customize = false;
  settings: MainSettings = null;
  private _userName: string = null;
  menu: Menu;
  isGroupOrder = false;

  // Loaders
  productLoading;
  settingProduct = true;

  get userName() {
    const user = this.store.selectSnapshot((state: GlobalStateModel) => state.user.user);
    const groupOrder = this.store.selectSnapshot((state: GlobalStateModel) => state.order.groupOrder);
    if (!groupOrder) {
      this._userName = null;
      return null;
    }
    this._userName = user && !user.isGuest ? user.firstName + ' ' + user.lastName : sessionStorage.getItem('guestName');
    return this._userName;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private handoffTypeService: HandoffTypeService,
    private orderType: OrderTypeService,
    private modeService: ModeService,
    private location: Location,
    private navigation: NavigationService,
    private directus: DirectusService,
    private mode: ModeService,
    protected mobile: MobileService,
    private modalService: NgbModal,
    private toast: ToastService,
    private utility: UtilityService,
    private menuProvider: MenuProviderService,
    private modal: ModalController // private cartService: CartService
  ) {
    this.order$
      .pipe(
        filter(order => !!order),
        distinctUntilKeyChanged('handoffType')
      )
      .subscribe(order => {
        this.store.dispatch(new SetMenu(order.location.locationID, order.handoffType));
      });
    this.order$.subscribe(order => {
      if (order) {
        this.restaurantID = order.location?.locationID;
        this.restaurantSlug = order.location?.slugURL ? order.location?.slugURL : order.location?.locationID;
        this.productLoading = '';
        this.selectedHandoffType = order.handoffType;
      }
    });
    this.groupOrder$.subscribe(groupOrder => {
      if (groupOrder && groupOrder.order) {
        this.isGroupOrder = true;
      }
    });
    interval(5000)
      .pipe(withLatestFrom(this.user$))
      .subscribe(([i, user]) => {
        if (user && !user.isGuest) {
          this._userName = user.firstName + ' ' + user.lastName;
        } else {
          this._userName = sessionStorage.getItem('guestName');
        }
      });
    this.mainSettings$.subscribe(settings => {
      if (settings) {
        this.settings = settings;
      }
    });
    this.menu$.subscribe(menu => {
      if (menu) {
        this.menu = menu;
      }
    });
  }

  getMenu$(): Observable<{}> {
    return of({});
  }

  // Routing functions TODO: Make a guard?

  checkMenuParameters(pathParams: any) {
    this.store.dispatch(new UpdateToCurrentBasket(null)).subscribe(() => {
      // this.selectedHandoffType = HandoffType[this.handoffTypeService.getHandoffFromLocalStorage()];
      // this.selectedHandoffType = this.handoffTypeService.setHandoffAndGetOrder(queryParams.handoffType, this.restaurantID);
      const order = this.store.selectSnapshot(state => state.order.order);
      this.selectedHandoffType = order ? order.handoffType : HandoffType.pickup;
      if (pathParams) {
        if (pathParams.id) {
          this.restaurantID = pathParams.id;
          if (!order && this.modeService.mode !== 'kiosk') {
            this.orderType.setNewOrderType(true, pathParams.id);
            // this.orderType.startPickupOrder(pathParams.id);
          } else {
            // this.modalService.dismissAll();
          }
          if (isNaN(this.restaurantID as any)) {
            this.directus.getSingleLocationBySlug(this.restaurantID).subscribe(
              res => {
                if (res.is_hidden) {
                  this.navigation.navigateTo404Page();
                  return;
                }
              },
              () => {
                this.navigation.navigateTo404Page();
              }
            );
          } else {
            this.directus.getSingleLocationByID(this.restaurantID).subscribe(res => {
              if (res.is_hidden) {
                this.navigation.navigateTo404Page();
                return;
              }
            });
          }
          if (pathParams.category) {
            // if (!pathParams.product) {
            //   this.store.dispatch(new SetRouteBack(`/menu/${this.restaurantSlug ? this.restaurantSlug : this.restaurantID}`));
            // }
            this.categoryNameSlug = pathParams.category;
            // this.selectedHandoffType = order ? order.handoffType : HandoffType[this.handoffTypeService.getHandoffFromLocalStorage()];
            this.selectedHandoffType = order ? order.handoffType : HandoffType.pickup;
            if (isNaN(this.restaurantID as any)) {
              this.directus.getSingleLocationBySlug(this.restaurantID).subscribe(
                res => {
                  if (res.is_hidden) {
                    this.navigation.navigateTo404Page();
                    return;
                  }
                  this.setMenuAndCategory(
                    this.restaurantSlug ? this.restaurantSlug : this.restaurantID,
                    this.selectedHandoffType,
                    this.categoryNameSlug,
                    res.menu_id
                  );
                },
                () => {
                  this.navigation.navigateTo404Page();
                }
              );
            } else {
              this.directus.getSingleLocationByID(this.restaurantID).subscribe(res => {
                if (res.is_hidden) {
                  this.navigation.navigateTo404Page();
                  return;
                }
                this.setMenuAndCategory(
                  this.restaurantSlug ? this.restaurantSlug : this.restaurantID,
                  this.selectedHandoffType,
                  this.categoryNameSlug
                );
              });
            }
          }
          if (this.restaurantID && order?.location.locationID !== this.restaurantID && order?.location.slugURL !== this.restaurantID) {
            this.store.dispatch(new SetOrCreateOrder(this.restaurantID, order?.handoffType));
          }
        } else if (order) {
          this.location.go('menu/' + order.location.slugURL);
          this.restaurantID = pathParams.id;
          this.selectedHandoffType = order.handoffType;
        } else {
          this.navigation.navigateToLocationsPage();
        }
      }
    });
  }

  checkIfAlcohol(product: Product) {
    // if product contains alcohol, open age check modal
    if (product.isAlcohol && !sessionStorage.getItem('ageCheck')) {
      this.modalService.open(AgeCheckComponent, { centered: true }).result.then(result => {
        if (result === 'Yes') {
          sessionStorage.setItem('ageCheck', 'true');
          this.addToOrderClicked(product);
        } else {
          this.productLoading = '';
          this.toast.danger('You must be 21 or older to order this item.');
          throw new Error('You must be 21 or older to order this item.');
        }
      });
    } else {
      this.addToOrderClicked(product);
    }
  }

  // Cart actions

  addProductToCart(product: Product) {
    this.productLoading = product.productID;
    const orderItem = this.utility.toOrderItem(product, [], 1, null);
    return this.cartActionCheckIfAlcohol(product, orderItem);
  }

  addToOrderClicked(product: Product) {
    this.productLoading = product.productID;
    const orderItem = this.utility.toOrderItem(product, [], 1, null);
    orderItem.guestName = this.isGroupOrder ? this.userName : null;
    this.store
      .dispatch(new AddToOrder(orderItem))
      .toPromise()
      .then(() => {
        if (Capacitor.getPlatform() !== 'web') {
          Haptics.notification({
            type: NotificationType.Success,
          })
            .then()
            .catch(console.log);
        }
        this.toast.success(`${product.name} added to order`);
        this.productLoading = '';
      });
  }

  // addUpsellToOrderClicked(product: any) {
  // this.success = '';
  // this.productLoading = product.productID;
  // if (this.isProviderUpsell) {
  //   const body = {
  //     items: [
  //       {
  //         id: product.productID,
  //         quantity: 1,
  //       },
  //     ],
  //   };
  //   this.store.dispatch(new AddProviderUpsell(this.displayOrder.orderID, body)).toPromise().then(() => {
  //     this.toast.success(`${product.name} added to order`);
  //   }).catch(error => {
  //     document.getElementById('scroll-section').scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //     this.productLoading = '';
  //     if (typeof error === 'string') {
  //       this.toast.danger(error);
  //       this.error = error;
  //
  //     } else {
  //       this.toast.danger(error.error.message);
  //       this.error = error.error.message;
  //     }
  //   });
  // } else {
  //   this.productLoading = product.productID;
  //   this.store.dispatch(new AddToOrder(this.utility.toOrderItem(product, [], 1, null))).toPromise().then(() => {
  //     if (Capacitor.getPlatform() !== 'web') {
  //       Haptics.notification({
  //         type: NotificationType.Success
  //       }).then().catch(console.log);
  //     }
  //     this.toast.success(`${product.name} added to order`);
  //   }).catch(error => {
  //     document.getElementById('scroll-section').scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //     this.productLoading = '';
  //     if (typeof error === 'string') {
  //       this.toast.danger(error);
  //       this.error = error;
  //
  //     } else {
  //       this.toast.danger(error.error.message);
  //       this.error = error.error.message;
  //     }
  //   });
  // }
  // }

  addConfiguredProductToCart(product: ConfiguredProduct, quantity = 1) {
    this.productLoading = product.product.productID;
    const orderItem = this.utility.toOrderItem(product.product, product.options, quantity, null);
    return this.cartActionCheckIfAlcohol(product.product, orderItem);
  }

  cartActionCheckIfAlcohol(product: Product, orderItem: OrderItem) {
    // if product contains alcohol, open age check modal
    if (product.isAlcohol && !sessionStorage.getItem('ageCheck')) {
      return from(
        this.modalService.open(AgeCheckComponent, { centered: true }).result.then(result => {
          if (result === 'Yes') {
            sessionStorage.setItem('ageCheck', 'true');
            this.runAddToCartAction(product, orderItem);
          } else {
            this.productLoading = '';
            this.toast.danger('You must be 21 or older to order this item.');
            throw new Error('Must be 21 or older to order alcohol');
          }
        })
      );
    } else {
      return this.runAddToCartAction(product, orderItem);
    }
  }

  runAddToCartAction(product: Product, orderItem: OrderItem) {
    orderItem.guestName = this.isGroupOrder ? this.userName : null;
    return this.store.dispatch(new AddToOrder(orderItem)).pipe(
      tap(() => {
        if (Capacitor.getPlatform() !== 'web') {
          Haptics.notification({
            type: NotificationType.Success,
          })
            .then()
            .catch(console.log);
        }
        this.toast.success(`${product.name} added to order`);
        this.productLoading = '';
      })
    );
  }

  // addCustomizedToCart(product: Product, orderItem: OrderItem) {
  //   this.store.dispatch(new AddToOrder(orderItem)).toPromise().then(
  //     (state) => {
  //       if (Capacitor.getPlatform() !== 'web') {
  //         Haptics.notification({
  //           type: NotificationType.Success
  //         }).then().catch(console.log);
  //       }
  //       this.toast.success(`${product.name} added to order`);
  //       if (this.mode.mode === 'tableside') {
  //         this.store.dispatch(new CheckProductUpsell(orderItem.name)).toPromise().then((newState) => {
  //           if (newState.order.upsells && newState.order.upsells.items.length > 0) {
  //             this.navigation.navigateToUpsellPage();
  //           } else {
  //             this.navigation.navigateToMenuPage(this.restaurantID, this.categoryNameSlug);
  //           }
  //         });
  //       } else {
  //         if (this.mobile.isMobile) {
  //           this.navigation.navigateToCartPage(this.restaurantID);
  //         } else {
  //           this.navigation.navigateToMenuPage(this.restaurantID);
  //         }
  //         this.productLoading = false;
  //       }
  //     }).catch(
  //     (error) => {
  //       this.errorMessage = this.errorService.productError(error);
  //       this.toast.danger(this.errorMessage);
  //       this.canAddToOrder = true;
  //       this.productLoading = false;
  //     },
  //   );
  // }

  customizeProduct(product: Product, category: Category, modifyModalRef: TemplateRef<any>) {
    this.customize = true;
    if (!category) {
      category = this.getCategoryFromProduct(product);
    }
    const categorySlug = category.nameSlug ? category.nameSlug : category.categoryID;
    const productSlug = product.nameSlug ? product.nameSlug : product.productID;
    this.store
      .selectOnce(state => state.order.order)
      .subscribe((order: Order) => {
        this.store.dispatch(new SetCategory(order.location.locationID, order.handoffType, categorySlug));
      });
    this.setProduct(product, categorySlug);
    const canModify = product.optionGroups && product.optionGroups.length > 0;
    if (canModify) {
      if (!product.showAsModal || this.mode.mode === 'kiosk') {
        // if (this.settings.menu_layout === 'improved') {
        //   this.useMobileCustomizationModal();
        // } else {
        this.navigation.navigateToMenuPage(
          this.restaurantSlug ? this.restaurantSlug : this.restaurantID,
          categorySlug,
          productSlug,
          'modify'
        );
        // }
      } else {
        if (this.settings.customization_layout === 'improved' && this.mode.mode !== 'kiosk') {
          this.useMobileCustomizationModal();
        } else {
          if (product.showAsModal) {
            this.useWebCustomizationModal(modifyModalRef);
          } else {
            this.navigation.navigateToMenuPage(
              this.restaurantSlug ? this.restaurantSlug : this.restaurantID,
              categorySlug,
              productSlug,
              'modify'
            );
          }
        }
      }
    }
  }

  modifyClicked(product: Product, modifyModalRef: TemplateRef<any>) {
    if (product.canModify) {
      if (!product.showAsModal && this.mode.mode !== 'kiosk') {
        const productSlug = product.nameSlug ? product.nameSlug : product.productID;
        this.navigation
          .navigateToMenuPage(this.restaurantSlug ? this.restaurantSlug : this.restaurantID, this.categoryNameSlug, productSlug, 'modify')
          .then();
      } else {
        this.settingProduct = true;
        // tslint:disable-next-line:max-line-length
        this.store
          .dispatch(
            new SetProduct(
              this.restaurantID,
              this.categoryNameSlug,
              product.nameSlug ? product.nameSlug : product.productID,
              this.selectedHandoffType
            )
          )
          .subscribe(() => (this.settingProduct = false));
        this.modalService.dismissAll();
        this.modalService.open(modifyModalRef, {
          centered: true,
          animation: true,
          windowClass: 'modal-fullscreen',
        });
      }
    }
  }

  editProduct(product: Product, category: Category, modifyModalRef: TemplateRef<any>) {
    this.customize = true;
    const categorySlug = category.nameSlug ? category.nameSlug : category.categoryID;
    const productSlug = product.nameSlug ? product.nameSlug : product.productID;
    this.store
      .selectOnce(state => state.order.order)
      .subscribe((order: Order) => {
        this.store.dispatch(new SetCategory(order.location.locationID, order.handoffType, categorySlug));
      });
    this.setProduct(product, categorySlug);
    const canModify = product.optionGroups && product.optionGroups.length > 0;
    if (canModify) {
      if (!product.showAsModal && this.mode.mode !== 'kiosk') {
        if (this.settings.menu_layout === 'improved') {
          this.useMobileCustomizationModal();
        } else {
          this.navigation.navigateToMenuPage(
            this.restaurantSlug ? this.restaurantSlug : this.restaurantID,
            categorySlug,
            productSlug,
            'modify'
          );
        }
      } else {
        this.setProduct(product, categorySlug);
        if (this.settings.menu_layout === 'improved' && this.mode.mode !== 'kiosk') {
          this.useMobileCustomizationModal();
        } else {
          if (product.showAsModal) {
            this.useWebCustomizationModal(modifyModalRef);
          }
        }
      }
    }
  }

  // Utility functions

  checkUpsellAndNavigate(orderItem: OrderItem) {
    this.store
      .dispatch(new CheckProductUpsell(orderItem.name))
      .toPromise()
      .then(newState => {
        if (newState.order.upsells && newState.order.upsells.items.length > 0) {
          this.navigation.navigateToUpsellPage();
        } else {
          this.navigation.navigateToMenuPage(this.restaurantSlug ? this.restaurantSlug : this.restaurantID, this.categoryNameSlug);
        }
      });
  }

  navigateAfterAdd() {
    if (this.mobile.isMobile && this.mode.mode !== 'kiosk') {
      this.navigation.navigateToCartPage(this.restaurantID);
    } else {
      this.navigation.navigateToMenuPage(this.restaurantSlug ? this.restaurantSlug : this.restaurantID);
    }
  }

  // Menu setters

  setMenuAndCategory(restaurantID: string, handoffType: HandoffType, categoryName: string, restaurantSlug?: string) {
    this.store.dispatch(new SetLoading(true));
    const menuID = restaurantSlug ? restaurantSlug : restaurantID;
    this.store
      .dispatch(new SetMenu(restaurantID, handoffType))
      .toPromise()
      .catch(() => {
        this.navigation.navigateTo404Page();
        this.store.dispatch(new SetLoading(false));
      });
    this.store
      .dispatch(new SetCategory(menuID, handoffType, categoryName))
      .toPromise()
      .then(() => {
        this.store.dispatch(new SetLoading(false));
      })
      .catch(() => {
        this.store.dispatch(new SetLoading(false));
        this.navigation.navigateToMenuPage(restaurantID);
      });
  }

  setCategory(category: Category) {
    this.store.dispatch(new SetLoading(true));
    const categorySlug = category.nameSlug ? category.nameSlug : category.categoryID;
    this.store
      .selectOnce(state => state.order.order)
      .subscribe((order: Order) => {
        if (order) {
          this.store
            .dispatch(
              new SetCategory(order.location.slugURL ? order.location.slugURL : order.location.locationID, order.handoffType, categorySlug)
            )
            .subscribe(() => {
              this.store.dispatch(new SetLoading(false));
            });
          this.navigation
            .navigateToMenuPage(order.location.slugURL ? order.location.slugURL : order.location.locationID, categorySlug)
            .then();
        } else {
          this.store.dispatch(new SetLoading(false));
          this.navigation.navigateToLocationsPage().then();
        }
      });
  }

  setProduct(product: Product, categorySlug: string) {
    this.settingProduct = true;
    this.store.dispatch(new SetLoading(true));
    const productName = product.nameSlug ? product.nameSlug : product.productID;
    this.store
      .selectOnce(state => state.order.order)
      .subscribe((order: Order) => {
        this.store
          .dispatch(new SetProduct(order?.location.locationID, categorySlug, productName, this.selectedHandoffType))
          .subscribe(() => {
            this.settingProduct = false;
            this.store.dispatch(new SetLoading(false));
          });
      });
  }

  getProduct(categoryID: string | number, productID: string | number): Observable<Product> {
    return this.menuProvider.getService().pipe(
      switchMap(provider => {
        return this.menu$.pipe(
          filter(m => !!m),
          take(1),
          switchMap((menu: Menu) => {
            if (!categoryID) {
              categoryID =
                menu.categories.find(c => c.products.find(p => p.productID === productID))?.categoryID ||
                menu.singleUseProducts?.categoryID;
            }
            return provider.getProduct(this.restaurantID, this.selectedHandoffType, menu?.menuID, categoryID, productID, false).pipe(
              map(product => {
                return produce(product, draft => {
                  draft.requiresModification =
                    draft.optionGroups &&
                    draft.optionGroups.length &&
                    draft.optionGroups.some(og => this.utility.checkIfModificationRequired(og));
                  draft.canModify = draft.optionGroups && draft.optionGroups.length > 0;
                });
              })
            );
          })
        );
      })
    );
  }

  getProductWithoutCategory(productID: string | number): Observable<Product> {
    // get menu
    // find category that contains product
    // get product
    return this.menuProvider.getService().pipe(
      switchMap(provider => {
        return this.menu$.pipe(
          filter(m => !!m),
          take(1),
          switchMap((menu: Menu) => {
            const categoryID =
              menu.categories.find(c => c.products.find(p => p.productID === productID))?.categoryID || menu.singleUseProducts?.categoryID;
            return provider.getProduct(this.restaurantID, this.selectedHandoffType, menu?.menuID, categoryID, productID, false).pipe(
              map(product => {
                return produce(product, draft => {
                  draft.requiresModification =
                    draft.optionGroups &&
                    draft.optionGroups.length &&
                    draft.optionGroups.some(og => this.utility.checkIfModificationRequired(og));
                  draft.canModify = draft.optionGroups && draft.optionGroups.length > 0;
                });
              })
            );
          })
        );
      })
    );
  }

  findConfiguredOptions(product: Product, chainOptionIDs: string[]) {
    let options: Option[] = [];
    chainOptionIDs.forEach(chainOptionID => {
      product?.optionGroups?.forEach((optionGroup: OptionGroup) => {
        optionGroup?.options.forEach((option: Option) => {
          if (option.brandOptionID === chainOptionID) {
            options.push(option);
          }
          const subOptions = this.findConfiguredSubOptions(option, chainOptionID);
          options = options.concat(subOptions);
        });
      });
    });
    return { product, options };
  }

  findConfiguredSubOptions(parentOption: Option, chainOptionID: string) {
    let options: Option[] = [];
    parentOption?.optionGroups?.forEach((optionGroup: OptionGroup) => {
      optionGroup?.options.forEach((option: Option) => {
        if (option.brandOptionID === chainOptionID) {
          options.push(option);
        }
        const subOptions = this.findConfiguredSubOptions(option, chainOptionID);
        options = options.concat(subOptions);
      });
    });
    return options;
  }

  toggleFullMenu() {
    this.store.dispatch(new ToggleFullMenu());
  }

  // Option Group Functions

  groupCheck(group: OptionGroup, optionGroups: OptionGroup[]): boolean {
    let isValid = false;
    group.options.forEach((op: Option) => {
      if (op.isSelected && this.isOptionValid(op, optionGroups ?? [])) {
        isValid = true;
      }
    });
    return isValid;
  }

  isOptionValid(option: Option, optionGroups: OptionGroup[] = []): boolean {
    let isValid = true;
    optionGroups.forEach((group: OptionGroup) => {
      if (!this.optionGroupIsValid(group)) {
        if (!this.optionCheck(option, group)) {
          isValid = false;
        }
      }
    });
    return isValid;
  }

  optionCheck(option: Option, group: OptionGroup) {
    if (this.truthCheck(option, group)) {
      return false;
    } else if (option.optionGroups && option.optionGroups.length) {
      return option.optionGroups.every(gr => gr.options.every(op => this.optionCheck(op, group)));
    } else {
      return true;
    }
  }

  truthCheck(option: Option, group: OptionGroup) {
    return option.optionGroups.some(gr => gr === group);
  }

  optionGroupIsValid(group: OptionGroup): boolean {
    const selectedOptions = group.options.filter(op => op.isSelected);
    return !group.minRequired || selectedOptions.length >= group.minRequired;
  }

  checkNestedLayer(option: Option, group: OptionGroup): boolean {
    let isValid = true;
    option.optionGroups.forEach((og: OptionGroup) => {
      og.options.forEach((op: Option) => {
        if (op.optionGroups.some((gr: OptionGroup) => gr === group)) {
          isValid = false;
        }
      });
    });
    return isValid;
  }

  addAdditionalOptions(options: Option[]) {
    const expandedOptions: Option[] = [];
    options.forEach((option: Option) => {
      if (option.additionalOptions && option.additionalOptions.length > 0) {
        option.additionalOptions.forEach((additionalOption: Option) => {
          expandedOptions.push(additionalOption);
        });
      }
    });
    return expandedOptions;
  }

  dedupeOptions(options: Option[]) {
    const consolidatedOptions: Option[] = [];
    options.forEach((option: Option) => {
      const optionAlreadyExists = consolidatedOptions.some((op: Option) => option.optionID === op.optionID);
      if (!optionAlreadyExists) {
        consolidatedOptions.push(option);
      }
    });
    return consolidatedOptions;
  }

  /**
   * Removes fake options from the list.
   * If a fake option has a quantity, assigns that quantity to its selected child option within its single nested OptionGroup.
   * @param options Array of Option objects to process.
   * @returns Array of Option objects without fake options.
   */
  removeFakeOptions(options: Option[]): Option[] {
    // Identify fake options in the selected options list
    const fakeOptions = options.filter(option => this.isFakeOption(option));

    fakeOptions.forEach(fakeOption => {
      if (fakeOption.quantity && fakeOption.optionGroups && fakeOption.optionGroups.length > 0) {
        // As per the user's statement, each fake option has a single nested OptionGroup
        const childOptionGroup = fakeOption.optionGroups[0];

        if (childOptionGroup) {
          // Find the selected child option within this OptionGroup
          const selectedChildOption = childOptionGroup.options.find(child => child.isSelected);

          if (selectedChildOption) {
            // Locate the corresponding child option in the main options list to set its quantity
            const mainChildOption = options.find(opt => opt.optionID === selectedChildOption.optionID);

            if (mainChildOption) {
              mainChildOption.quantity = fakeOption.quantity;
            } else {
              // If the child option is not in the selectedOptions list, you may need to handle it accordingly
              console.warn(`Selected child option with ID ${selectedChildOption.optionID} not found in the main options list.`);
            }
          } else {
            console.warn(`No selected child option found within the OptionGroup of fake option ID ${fakeOption.optionID}.`);
          }
        } else {
          console.warn(`Fake option ID ${fakeOption.optionID} does not have a nested OptionGroup.`);
        }
      }
    });

    // Finally, filter out the fake options from the main list
    const filteredOptions = options.filter(option => !this.isFakeOption(option));

    return filteredOptions;
  }

  /**
   * Determines if an option is fake based on its optionID.
   * @param option The Option object to evaluate.
   * @returns True if the option is fake; otherwise, false.
   */
  private isFakeOption(option: Option): boolean {
    return option.optionID.includes('-fake') || option.optionID.includes('-combined');
  }

  removeParentOptions(options: Option[]) {
    const realOptions: Option[] = [];
    options.forEach((option: Option) => {
      const optionIsFake = option.optionID.includes('-fake');
      if (!optionIsFake) {
        realOptions.push(option);
      }
    });
    return realOptions;
  }

  getSelectedOptions(optionGroups: OptionGroup[]) {
    let selectedOptions: Option[] = [].concat(
      ...optionGroups.map(group => group.options.filter(op => (!op.isInverted && op.isSelected) || (op.isInverted && !op.isSelected)))
    );
    selectedOptions = selectedOptions.concat(this.addAdditionalOptions(selectedOptions));
    selectedOptions = this.dedupeOptions(selectedOptions);
    selectedOptions = this.removeFakeOptions(selectedOptions);
    return selectedOptions;
  }

  setSelectedOptionFromGroup(option: Option, optionGroups: OptionGroup[], quantity = 1) {
    optionGroups.forEach((opGroup: OptionGroup) => {
      opGroup.options.forEach((op: Option) => {
        if (option.optionID === op.optionID) {
          op.isSelected = option.isSelected;
          op.quantity = quantity;
        } else {
          op = this.setSelectedOption(option, op, quantity);
        }
      });
    });
    return optionGroups;
  }

  setSelectedOption(option: Option, parentOption: Option, quantity = 1) {
    parentOption.optionGroups.forEach((opGroup: OptionGroup) => {
      opGroup.options.forEach((op: Option) => {
        if (option.optionID === op.optionID) {
          if (opGroup.displayType === 'half-and-half' && option.isSelected === false) {
            parentOption.isSelected = false;
            op.isSelected = false;
          } else {
            op.isSelected = option.isSelected;
          }
          op.quantity = quantity;
        } else {
          op = this.setSelectedOption(option, op);
        }
      });
    });
    return parentOption;
  }

  isHalfAndHalfChild(option: Option, optionGroups: OptionGroup[]) {
    let isHalfAndHalf = false;
    optionGroups.forEach((opGroup: OptionGroup) => {
      if (!isHalfAndHalf) {
        opGroup.options.forEach((op: Option) => {
          if (!isHalfAndHalf) {
            if (option.optionID === op.optionID) {
              isHalfAndHalf = opGroup.displayType === 'button-group';
            } else {
              if (option.optionGroups && option.optionGroups.length > 0) {
                isHalfAndHalf = this.isHalfAndHalfChild(option, op.optionGroups);
              }
            }
          }
        });
      }
    });
    return isHalfAndHalf;
  }

  /**
   * Recursively sets selected options for a product based on selectedOrderItemModifiers.
   * @param product The product containing option groups.
   * @param selectedOptions Array of selected OrderItemModifiers.
   */
  recursivelySetSelectedOptions(product: Product, selectedOptions: OrderItemModifier[] = []) {
    product.optionGroups.forEach(opGroup => {
      this.setOptionGroupsSelectedOptions(opGroup, selectedOptions);
    });
  }

  /**
   * Recursively sets selected options within an OptionGroup.
   * Also sets the quantity of parent options based on selected child options.
   * @param group The OptionGroup to process.
   * @param selectedOptions Array of selected OrderItemModifiers.
   */
  setOptionGroupsSelectedOptions(group: OptionGroup, selectedOptions: OrderItemModifier[] = []) {
    if (group && group.options && group.options.length) {
      group.options.forEach(op => {
        let isCurrentlySelected = false;
        let hasSelectedChild = false;

        if (selectedOptions) {
          // Check if this option is directly selected
          isCurrentlySelected = this.isSelected(op, selectedOptions);

          // Check if any of its child options are selected
          hasSelectedChild = this.hasSelectedChildOption(op, selectedOptions);

          // Set option as selected if it's either directly selected or any of its children are selected
          op.isSelected = (!op.isInverted && (isCurrentlySelected || hasSelectedChild)) || (op.isInverted && !isCurrentlySelected);
        } else {
          // If no selectedOptions provided, use default selection
          op.isSelected = op.isDefault ? true : op.isInverted;
        }

        // Set quantity based on selection
        if (op.isSelected) {
          if (hasSelectedChild) {
            // Parent is selected because a child is selected
            const selectedChildOption = this.getSelectedChildOption(op);
            if (selectedChildOption) {
              // Set parent's quantity to match the selected child's quantity
              op.quantity = selectedChildOption.quantity;
            } else {
              // No selected child found; default to 1 or handle accordingly
              op.quantity = 1;
              console.warn(`Parent option "${op.optionID}" is selected due to a child being selected, but no child is found.`);
            }
          } else {
            // Parent is directly selected; set quantity from selectedOptions or default to 1
            op.quantity = selectedOptions.find(selectedOption => selectedOption.optionID === op.optionID)?.quantity ?? 1;
          }

          // Record the selection time
          op.whenSelected = new Date();
        } else {
          // If not selected, reset quantity if necessary
          op.quantity = undefined;
        }

        // Recursively process child option groups
        if (op.optionGroups) {
          op.optionGroups.forEach(childGroup => this.setOptionGroupsSelectedOptions(childGroup, selectedOptions));
        }
      });
    }
  }

  /**
   * Retrieves the selected child option from a parent option.
   * Assumes only one child is selected. If multiple can be selected, modify accordingly.
   * @param option The parent Option.
   * @returns The selected child Option or undefined.
   */
  private getSelectedChildOption(option: Option): Option | undefined {
    if (option.optionGroups) {
      for (const group of option.optionGroups) {
        const selectedChild = group.options.find(child => child.isSelected);
        if (selectedChild) {
          return selectedChild;
        }
      }
    }
    return undefined;
  }

  /**
   * Checks if an option or any of its children are selected.
   * @param option The Option to check.
   * @param selectedOptions Array of selected OrderItemModifiers.
   * @returns True if the option or any of its children are selected; otherwise, false.
   */
  hasSelectedChildOption(option: Option, selectedOptions: OrderItemModifier[]): boolean {
    if (option.optionGroups) {
      return option.optionGroups.some(group =>
        group.options.some(
          childOption => this.isSelected(childOption, selectedOptions) || this.hasSelectedChildOption(childOption, selectedOptions)
        )
      );
    }
    return false;
  }

  /**
   * Determines if an option is selected based on selectedOptions.
   * @param option The Option to evaluate.
   * @param selectedOptions Array of selected OrderItemModifiers.
   * @returns True if the option is selected; otherwise, false.
   */
  isSelected(option: Option, selectedOptions: OrderItemModifier[]): boolean {
    return selectedOptions.some(
      selectedOption =>
        option.optionID === selectedOption.optionID ||
        (this.hasSelectedSuboption(option, selectedOption) &&
          (option.parentOptionGroupID ? option.parentOptionGroupID === selectedOption.optionGroupID : true))
    );
  }

  /**
   * Determines if an option has a selected suboption based on selection triggers.
   * @param option The parent Option.
   * @param selectedOption The selected OrderItemModifier.
   * @returns True if a suboption is selected via selectionTriggers; otherwise, false.
   */
  hasSelectedSuboption(option: Option, selectedOption: OrderItemModifier): boolean {
    const hasSelectedSuboption = option.selectionTriggers?.some(
      selectionTrigger => selectionTrigger.optionID === selectedOption.optionID && selectionTrigger.name === option.name
    );
    return hasSelectedSuboption ?? false;
  }
  // UI setters

  useWebCustomizationModal(modifyModalRef: TemplateRef<any>) {
    this.modalService.dismissAll();
    this.modalService.open(modifyModalRef, {
      centered: true,
      animation: true,
    });
  }

  useMobileCustomizationModal() {
    this.modal
      .create({
        component: CustomizeProductBlockComponent,
        cssClass: 'new-modal-content',
      })
      .then(modal => modal.present());
  }

  // Mapping

  // tslint:disable-next-line:max-line-length
  toOrderItem(
    prod: Product,
    selectedOptions: Option[],
    quantity: number,
    orderItem: string,
    guestName: string,
    instructions: string
  ): OrderItem {
    return {
      categoryID: prod.categoryID,
      guestName,
      instructions,
      longDesc: prod.longDesc,
      menuID: prod.menuID,
      name: prod.name,
      options: selectedOptions.map(op => this.toOrderItemModifier(op)),
      orderItemID: orderItem,
      productID: prod.productID,
      quantity,
      shortDesc: prod.shortDesc,
      standardImageURL: prod.standardImageURL,
      thumbnailImageURL: prod.thumbnailImageURL,
      totalCents: prod.priceCents,
      userID: null,
      isAlcohol: prod.isAlcohol,
    } as OrderItem;
  }

  toOrderItemModifier(op: Option): OrderItemModifier {
    return {
      name: op.name,
      addedCents: op.addedCents,
      nutritionInfo: op.nutritionInfo,
      optionID: op.optionID,
      quantity: op.quantity ?? 1,
      modifierCategoryID: op.modifierCategoryID,
      optionGroupID: op.parentOptionGroupID,
      brandOptionID: op.brandOptionID,
    } as OrderItemModifier;
  }

  // UX Setters

  addToCartSuccess(product: Product) {
    if (Capacitor.getPlatform() !== 'web') {
      Haptics.notification({
        type: NotificationType.Success,
      })
        .then()
        .catch(console.log);
    }
    this.toast.success(`${product.name} added to order`);
  }

  // Navigation

  backToMenu() {
    this.navigation.navigateToMenuPage(this.restaurantSlug).then();
  }

  getCategoryFromProduct(product: Product): Category {
    return this.menu.categories.find(category => category.products.find(prod => prod.productID === product.productID));
  }
}
