<ion-content>
  <swiper-container class="h-100 w-100" pagination="true" style="--swiper-pagination-color: var(--ion-color-primary);">
    <ng-container
      *ngFor="
        let slide of onboardingSlides$ | async;
        index as index;
        last as last
      ">
      <swiper-slide>
        <div
          class="h-100 w-100"
          style="
            display: grid;
            position: relative;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            place-items: center;
            --swiper-pagination-color: var(--ion-color-primary);
          ">
          <img
            class="slide-stack"
            [src]="slide.page_background?.data?.full_url"
            alt="" />
          <div
            *ngIf="slide.page_content"
            class="slide-stack"
            [innerHTML]="slide.page_content | sanitizeHtml"></div>
          <small
            class="skip font-size-body-regular"
            *ngIf="last"
            tabindex="1"
            (click)="close()"
            >Continue</small
          >
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>
</ion-content>
