import { Component, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent {
  @Output() dateSelected = new EventEmitter<Date>();
  @Input() set maxDate(date: Date) {
    this._maxDate = moment(date);
  }
  get maxDate(): Date {
    return this._maxDate.toDate();
  }
  private _maxDate: moment.Moment = moment();

  @ViewChild('year1', { read: ElementRef }) yearColumn: ElementRef;

  breakpoint: number = 354 / window.innerHeight;

  selectedDate: Date;

  selectedYear: number = moment().year();
  selectedMonth: number = moment().month() + 1; // months are 0 indexed in moment.js
  selectedDay: number = moment().date();

  years: number[] = this.generateRange(moment().year() - 100, this._maxDate.year());
  months: string[] = moment.months();
  days: number[] = this.generateRange(1, moment({ year: this.selectedYear, month: this.selectedMonth - 1 }).daysInMonth());

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // Function to scroll the year column
  scrollToSelectedYear(year: number): void {
    console.log(this.yearColumn);
    // Find the index of the selected year
    const index = this.years.indexOf(year);

    // Exit if year not found
    if (index === -1) return;

    // Calculate the height of each item
    const itemHeight = this.yearColumn.nativeElement.firstChild.offsetHeight;

    // Calculate the offset to scroll to, so the selected item is in the middle
    const offset = index * itemHeight - this.yearColumn.nativeElement.offsetHeight / 2 + itemHeight / 2;

    const interval = setInterval(() => {
      if (this.yearColumn.nativeElement) {
        // Use this offset to scroll the container
        this.yearColumn.nativeElement.scrollTop = offset;
        clearInterval(interval);
      }
    }, 50);
  }

  // Call this function when the modal opens and whenever the year is updated
  // If you have an initial default year, you can call it in ngOnInit too
  modalOpened(): void {
    this.scrollToSelectedYear(this.selectedYear);
  }

  generateRange(start: number, end: number): number[] {
    const range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  }

  updateYear(event: Event) {
    event.stopPropagation();
    const newYear = this.detectColumnChange(event, this.years);
    if (newYear !== this.selectedYear) {
      this.selectedYear = newYear;
      this.updateMonths();
      this.updateDays();
    }
  }

  updateMonth(event: Event) {
    event.stopPropagation();
    const newMonth = this.detectColumnChange(event, this.months, true);
    if (newMonth !== this.selectedMonth) {
      this.selectedMonth = newMonth;
      this.updateDays();
    }
  }

  updateDay(event: Event) {
    event.stopPropagation();
    this.selectedDay = this.detectColumnChange(event, this.days);
  }

  updateMonths() {
    if (this.selectedYear === this._maxDate.year()) {
      this.months = moment.months().slice(0, this._maxDate.month() + 1);
    } else {
      this.months = moment.months();
    }
  }

  updateDays() {
    if (this.selectedYear === this._maxDate.year() && this.selectedMonth === this._maxDate.month() + 1) {
      this.days = this.generateRange(1, this._maxDate.date());
    } else {
      this.days = this.generateRange(1, moment({ year: this.selectedYear, month: this.selectedMonth - 1 }).daysInMonth());
    }
  }

  detectColumnChange(event: any, array: any[], isMonth = false): any {
    const elementHeight = event.target.children[0]?.offsetHeight;
    const scrollPosition = event.target.scrollTop;
    const index = Math.round(scrollPosition / elementHeight);

    return isMonth ? index + 1 : array[index];
  }

  confirmDate() {
    this.selectedDate = moment({ year: this.selectedYear, month: this.selectedMonth - 1, day: this.selectedDay }).toDate();
    this.dateSelected.emit(this.selectedDate);
    // Logic to close the modal or perform any other action
  }

  dismissModal(reason: string) {
    // Logic to close the modal or perform any other action
  }
}
