<div class="modify-footer">

    <div class="add-quantity">
      <ng-container *ngIf="depth === 0">
        <!--  Turn quantity ticker into a component-->
        <div class="quantity-ticker">
          <!--    Decrement button should be disabled if the quanitity is at minum allowed amount-->
          <button type="button" class="button-minus" [disabled]="quantity === 1" (click)="clickedDecrement()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </button>
          <!--    This info should reflect the amount of quantity-->
          <div class="quantity-count">
            {{ quantity }}
          </div>
          <!--    Increment button should be disabled if the quanitity is at maximum allowed amount-->
          <button type="button" class="button-plus" (click)="clickedIncrement()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </button>
        </div>
      </ng-container>

    <ng-container *ngIf="!hasSteps">
      <button class="de-primary-btn grow-1" (click)="clickedContinue()" [disabled]="!canAdd && !depth > 0">
        <!--  Use the add to cart button at the top level of customization on a new customize-->
        <ng-container *ngIf="depth === 0 && !isEditing">
          Add to Cart
        </ng-container>
        <!--  Use the add to cart button at the top level of customization when editing a customize-->
        <ng-container *ngIf="depth === 0 && isEditing">
          Save Updates
        </ng-container>
        <!--  Save button will show on a customize route that will lead the user back to the first customize selection-->
        <ng-container *ngIf="depth > 0">
          Save Choices
        </ng-container>
      </button>
    </ng-container>

    <ng-container *ngIf="hasSteps">
      <!--    This version will show if they user is inside of an option group that has multiple customizable choices deeper than the first level-->
      <div class="previous-next">
        <button class="de-primary-btn-outline" (click)="goToNextParentGroup()">
          {{ currentStepIndex === 0 ? 'Cancel' : 'Previous' }}
        </button>

        <button class="de-primary-btn w-100" (click)="goToNextParentGroup()">
          {{ nextStep ? nextStep.name : 'Done' }}
        </button>
      </div>
    </ng-container>
  </div>

</div>
