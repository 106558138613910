<ng-container *ngIf="vendorSetup$ | async as vendorSetup">
  <div class="px-3 pt-3 pb-5 position-relative">
    <div class="d-flex justify-content-between" style="position: sticky; top: 1rem;">
      <i *ngIf="currentView !== 'default'" class="bi bi-arrow-left-short pointer d-flex" (click)="handleBackNavigation()"
         aria-label="Go Back" style="font-size: 30px;"></i>
      <i class="bi bi-x-circle d-flex pointer bg-white rounded-circle ms-auto" style="font-size: 30px;"
         (click)="activeModal.close('Close click')"></i>
    </div>
    <div class="d-flex flex-row flex-nowrap justify-content-center">
      <!--            <div class="grow-1 d-flex flex-column align-items-center" style="max-width: 550px;">-->

      <ng-container *ngIf="!orderType; else deliveryInfo">

        <div class="mx-auto">
          <ng-container *ngIf="branding$ | async as branding">
            <div class="mb-4 d-flex justify-content-center ion-align-items-center mt-4 mt-md-0 logo-shell">
              <img class="logo" alt="logo" *ngIf="branding.favicon"
                   [src]="branding.favicon.data.full_url">
            </div>
          </ng-container>

          <div
            *ngIf="currentView === 'default'"
            class="p-3 cms-card-radius card shadow mx-auto modal-body w-100 d-flex justify-content-center overflow-visible">
            <h4 class="w-100 fw-bold header-font mb-2">Start Your Order</h4>
            <p class="mb-3 w-100">Select an order method to begin your order.</p>

            <div class="d-flex flex-column gap-2 modal-body overflow-visible">

              <!-- Pickup Option -->
              <button *ngIf="vendorSetup.pickup_provider !== 'none'"
                      (click)="typeSelected($event,0)"
                      class="handoff-btn
                     card p-3 d-flex flex-row align-items-center shadow-sm position-relative">
                <!-- Replace with your desired icon -->
                <div class="handoff-icon-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-handbag handoff-icon me-3" viewBox="0 0 16 16">
                    <path
                      d="M8 1a2 2 0 0 1 2 2v2H6V3a2 2 0 0 1 2-2m3 4V3a3 3 0 1 0-6 0v2H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5zm-1 1v1.5a.5.5 0 0 0 1 0V6h1.639a.5.5 0 0 1 .494.426l1.028 6.851A1.5 1.5 0 0 1 12.678 15H3.322a1.5 1.5 0 0 1-1.483-1.723l1.028-6.851A.5.5 0 0 1 3.36 6H5v1.5a.5.5 0 1 0 1 0V6z" />
                  </svg>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="fw-semibold card-title header-font mb-1">Pickup</h5>
                  <small class="mb-1">Collect your order in-restaurant at the designated area.</small>
                </div>
                <div *ngIf="pickupLoading" class="spinner-border spinner-border-sm de-text-primary" style="position: absolute; bottom: 0.5rem; right: 0.5rem"></div>
              </button>

              <!-- Delivery Option -->
              <button *ngIf="vendorSetup.delivery_provider !== 'none'"
                      (click)="typeSelected($event, 1)"
                      class="handoff-btn card p-3 d-flex flex-row align-items-center shadow-sm">
                <!-- Replace with your desired icon -->
                <div class="handoff-icon-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-bicycle handoff-icon me-3" viewBox="0 0 16 16">
                    <path
                      d="M4 4.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1v.5h4.14l.386-1.158A.5.5 0 0 1 11 4h1a.5.5 0 0 1 0 1h-.64l-.311.935.807 1.29a3 3 0 1 1-.848.53l-.508-.812-2.076 3.322A.5.5 0 0 1 8 10.5H5.959a3 3 0 1 1-1.815-3.274L5 5.856V5h-.5a.5.5 0 0 1-.5-.5m1.5 2.443-.508.814c.5.444.85 1.054.967 1.743h1.139zM8 9.057 9.598 6.5H6.402zM4.937 9.5a2 2 0 0 0-.487-.877l-.548.877zM3.603 8.092A2 2 0 1 0 4.937 10.5H3a.5.5 0 0 1-.424-.765zm7.947.53a2 2 0 1 0 .848-.53l1.026 1.643a.5.5 0 1 1-.848.53z" />
                  </svg>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="fw-semibold card-title header-font mb-1">Delivery</h5>
                  <small class="mb-1">Your order will be delivered to you at your selected address.</small>
                </div>
              </button>

            </div>
          </div>

        </div>
      </ng-container>
    </div>

    <ng-template #deliveryInfo>
      <ng-container *ngIf="!chooseDelivery else chooseDeliveryLocation">
        <div class="d-flex flex-column align-items-center w-100">
          <ng-container *ngIf="branding$ | async as branding">
            <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0">
              <img class="logo" alt="logo" *ngIf="branding.favicon"
                   [src]="branding.favicon.data.full_url">
            </div>
          </ng-container>
          <div class="p-3 cms-card-radius card shadow mx-auto modal-body">
            <h4 class="fw-bold header-font mb-2">Your Delivery Address</h4>
            <p class="w-100 mb-3">Please enter your delivery address, or select from your list of saved addresses.</p>

            <!-- Show saved delivery addresses if any -->
            <div *ngIf="savedDeliveryAddresses.length > 0" class="d-flex flex-column">
              <div class="d-flex flex-row flex-nowrap gap-3 justify-content-between align-items-center mb-3">
                <h5 class="fw-bold mb-0">
                  <ng-container *ngIf="!showDeliveryForm">Saved Addresses</ng-container>
                  <ng-container *ngIf="showDeliveryForm">New Address</ng-container>
                </h5>
                <!-- Add New Delivery Address Button -->
                <ng-container *ngIf="!showDeliveryForm else showSavedAddresses">
                  <button class="de-primary-link" (click)="showDeliveryForm = true">
                    Add New Address
                  </button>
                </ng-container>
                <ng-template #showSavedAddresses>
                  <button class="de-primary-link" (click)="showDeliveryForm = false">
                    Saved Addresses
                  </button>
                </ng-template>
              </div>
              <ng-container *ngIf="!showDeliveryForm">
                <button *ngFor="let address of savedDeliveryAddresses; index as i; last as last"
                        class="handoff-btn card cms-card-radius p-3"
                        [class.mb-2]="!last"
                        (click)="selectSavedDeliveryAddress(address)"
                        (keydown.enter)="selectSavedDeliveryAddress(address)">
                  <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center gap-2 w-100">
                    <div class="d-flex flex-column">
                      <div class="font-size-body fw-bold header-font">{{ address?.address.address1 }}</div>
                      <div *ngIf="address.address.address2" class="font-size-small">{{ address?.address.address2 }}
                      </div>
                      <div class="font-size-small">{{ address?.address.city }}
                        , {{ address?.address.state }} {{ address?.address.zipCode }}
                      </div>
                      <small class="font-size-xsmall"
                             *ngIf="address.address.specialInstructions">{{ address.address.specialInstructions }}</small>
                      <small class="font-size-xsmall"
                             *ngIf="address.address.specialInst">{{ address.address.specialInst }}</small>
                    </div>
                    <div class="spinner-border de-text-primary"
                         *ngIf="loadingDeliveryLocation && userLocationsService.isSameSavedAddress(address, loadingDeliveryLocation)"
                         role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </ng-container>
            </div>

            <!-- Show delivery address form if no saved addresses or if "Add New Delivery Address" is clicked -->
            <form *ngIf="savedDeliveryAddresses.length === 0 || showDeliveryForm"
                  class="w-100 d-flex justify-content-center overflow-visible"
                  [formGroup]="deliveryForm">
              <div class="w-100 d-flex flex-column overflow-visible">
                <!-- Delivery Address Input -->
                <div class="w-100 pb-3">
                  <label for="autocompletemodal">Delivery Address*</label>
                  <ng-container *ngIf="provider === 'google' else otherMapProvider">
                    <input type="text" #autocompletemodal id="autocompletemodal"
                           ngx-google-places-autocomplete
                           (onAddressChange)="placeChanged($event)" formControlName="deliveryAddress"
                           placeholder="Delivery Address"
                           class="form-control de-light de-border de-input w-100"
                           [options]="autocompleteOptions" />
                  </ng-container>
                  <ng-template #otherMapProvider>
                    <input type="text" formControlName="deliveryAddress" placeholder="Delivery Address"
                           class="form-control de-light de-border de-input w-100" />
                  </ng-template>
                </div>
                <!-- Additional Form Fields -->
                <div class="w-100 pb-3">
                  <label for="deliveryaddress2">Delivery Address Line 2</label>
                  <input id="deliveryaddress2"
                         placeholder="Suite, apartment, building, floor, etc."
                         class="form-control de-light de-border de-input w-100"
                         formControlName="secondDeliveryAddress">
                </div>
                <div class="w-100 pb-3">
                  <label for="deliveryinstructions">Delivery Instructions</label>
                  <textarea id="deliveryinstructions"
                            [placeholder]="'Max ' + deliveryInstructionsLength + ' characters'"
                            class="form-control de-light de-border de-input w-100"
                            formControlName="deliveryInstructions"
                            [maxlength]="deliveryInstructionsLength"></textarea>
                </div>

                <!-- Error Message -->
                <div class="w-100 h-auto text-danger form-error mt-0 mb-3" *ngIf="displayError">
                  {{ displayError }}
                </div>

                <!-- Start Order Button -->
                <div class="w-100">
                  <ng-container *ngIf="mainSettings$ | async as mainSettings">
                    <ng-container
                      *ngIf="!mainSettings.allow_users_to_pick_delivery_location else allowDeliverySelection">
                      <button class="de-primary-btn color-button w-100"
                              [disabled]="deliveryForm.invalid || !fullAddress"
                              (click)="startDeliveryOrder()">
                        <div *ngIf="!isLoading else btnLoading">Start Order</div>
                      </button>
                    </ng-container>
                    <ng-template #allowDeliverySelection>
                      <button class="de-primary-btn color-button w-100"
                              [disabled]="deliveryForm.invalid || !fullAddress"
                              (click)="checkAddress()">
                        <ng-container *ngIf="!isLoading else btnLoading">
                          <ng-container *ngIf="!fullAddress">Check Delivery Address</ng-container>
                          <ng-container *ngIf="fullAddress">Select Location</ng-container>
                        </ng-container>
                      </button>
                    </ng-template>
                    <ng-template #btnLoading>
                      <div class="de-btn-loader"></div>
                    </ng-template>
                  </ng-container>
                </div>

                <!-- Pickup Instead Link -->
                <div class="w-100 text-center d-flex justify-content-center" *ngIf="displayError">
                  <button type="button" tabindex="0" *ngIf="!isLoading"
                          class="de-secondary-btn-outline w-100 mt-3"
                          (click)="typeSelected($event, 0)"
                          (keydown.enter)="typeSelected($event, 0)">
                    Pickup Instead
                  </button>
                </div>

                <!-- Warning Messages -->
                <p class="w-100 mt-3"
                   *ngIf="displayOrder?.handoffType === handoffs.delivery || displayOrder?.handoffType === handoffs.dispatch && displayOrder?.items?.length">
                  Changing your delivery address may cause your order to be delivered from a different store. We cannot
                  guarantee that all items can be transferred to your new location.
                </p>

              </div>
            </form>
          </div>
        </div>
      </ng-container>
    </ng-template>


    <ng-template #chooseDeliveryLocation>
      <div class="w-100 d-flex flex-column align-items-center grow-3 basis-0">

        <ng-container *ngIf="branding$ | async as branding">
          <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0">
            <img class="logo" alt="logo" *ngIf="branding.favicon"
                 [src]="branding.favicon.data.full_url">
          </div>
        </ng-container>

        <div class="p-3 card cms-card-radius shadow mx-auto modal-body">
          <h4 class="fw-bold header-font mb-2">Select Delivery Location</h4>
          <p class="w-100 mb-3">Please choose which restaurant you would like to be delivered to from.</p>
          <div class="d-flex flex-column pb-3">
            <p class="fw-bold header-font w-100">Your Delivery Address - <span
              (click)="chooseDelivery = false" class="changeAddress">Change</span>
            </p>
            <p class="w-100">{{ fullAddress.addressComponents.address1 }}</p>
            <p class="w-100" *ngIf="address2.value">{{ address2.value }}</p>
            <p class="w-100">{{ fullAddress.addressComponents.city }}
              , {{ fullAddress.addressComponents.state }} {{ fullAddress.addressComponents.zipCode }}
            </p>
          </div>
          <div class="w-100">
            <ng-container *ngIf="deliveryLocations$ | async as deliveryLocations">
              <ng-container
                *ngFor="let location of deliveryLocations; index as i; first as first; last as last">
                <ng-container *ngIf="location.canDeliver">
                  <div [class.mb-3]="!last">
                    <!--                    <app-desktop-location-card-->
                    <!--                      *ngIf="location.isPrivate === false"-->
                    <!--                      (orderNowButtonClick)="deliveryLocationSelect($event)"-->
                    <!--                      [locationID]="location.locationID"-->
                    <!--                      [isCurrentLocation]="selectedLocation && location === selectedLocation"-->
                    <!--                      [locationLoading]="isLoading? location.locationID: null"-->
                    <!--                      [showLocationInfoButton]="false"-->
                    <!--                      (hoveredIndex)="cardHovered(i)"-->
                    <!--                      [cardEmphasis]="cardEmphasis"-->
                    <!--                    >-->
                    <!--                    </app-desktop-location-card>-->

                    <app-delivery-location-card
                      *ngIf="location.isPrivate === false"
                      (orderNowButtonClick)="deliveryLocationSelect($event)"
                      [locationID]="location.locationID"
                      [isCurrentLocation]="selectedLocation && location === selectedLocation"
                      [locationLoading]="isLoading ? selectedLocation?.locationID : null"
                      (hoveredIndex)="cardHovered(i)"
                      [cardEmphasis]="cardEmphasis"
                    ></app-delivery-location-card>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
