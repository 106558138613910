import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TextTruncatePipe implements PipeTransform {
  transform(input: any, length: number = 50) {
    if (input) {
      let txt = input;
      if(input && input.length > length) {
        txt = input.substring(0, length)+'…';
      }
      return txt;
    }
    return input;
  }
}
