<ng-container *ngIf="optionGroup.displayType === 'button-group' && (optionGroup.options.length === 2 || optionGroup.options.length === 3)">
  <app-button-group-selector
    [optionGroup]="optionGroup"
    [optionSubGroup]="optionSubGroup"
    [selectedOptionIndex]="selectedOptionIndex"
    (optionClick)="optionClicked($event)"
    (subOptionClick)="subOptionClicked($event)"
  ></app-button-group-selector>
</ng-container>

<ng-container *ngIf="optionGroup.displayType === 'button-group' && optionGroup.options.length > 3">
  <app-dropdown-selector
    [optionGroup]="optionGroup"
    [optionSubGroup]="optionSubGroup"
    (optionClick)="optionClicked($event)"
    (subOptionClick)="subOptionClicked($event)"
  ></app-dropdown-selector>
</ng-container>

<ng-container *ngIf="optionGroup.displayType === 'half-and-half'">
  <app-half-and-half-selector
    [optionGroup]="optionGroup"
    [optionSubGroup]="optionSubGroup"
    [selectedOptionIndex]="selectedOptionIndex"
    (optionClick)="optionClicked($event)"
    (subOptionClick)="subOptionClicked($event)"
  ></app-half-and-half-selector>
</ng-container>
