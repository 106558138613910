<ng-container *ngIf="!optionSubGroup; else optionSubgroupDisplay">
  <ng-container *ngIf="ingredientFormGroup && option && optionGroup">
    <label [for]="option.nameSlug + (multiselect ? '-checkbox' : '-radio')" class="ingredient-card"
           [class.radio]="!multiselect" [class.checkbox]="multiselect">
      <div class="ingredient-card-visible" [formGroup]="ingredientFormGroup">
        <ng-container *ngIf="multiselect; else radio">
          <input [id]="option.nameSlug + '-checkbox'"
                 class="ingredient-checkbox" type="checkbox"
                 [name]="option.nameSlug"
                 [formControlName]="option.nameSlug"
                 [value]="!currentIngredient?.value"
                 (click)="optionClicked()">
          <span class="checkbox-checkmark"></span>
        </ng-container>
        <ng-template #radio>
          <input [id]="option.nameSlug + '-radio'"
                 class="ingredient-radio" type="radio"
                 [name]="optionGroup.nameSlug"
                 [formControlName]="optionGroup.nameSlug"
                 [value]="option.nameSlug"
                 (click)="optionClicked()">
          <span class="radio-dot"></span>
        </ng-template>
        <ng-container *ngTemplateOutlet="ingredientInfoTop"></ng-container>
      </div>

      <ng-container *ngIf="currentIngredient?.value === true || currentIngredient?.value === option.nameSlug">
        <ng-container *ngIf="!buttonGroupChildren && ((option.hasChildren && choicesNeeded) || option.addedCents !== 0)">
          <ng-container *ngTemplateOutlet="ingredientStatus"></ng-container>
        </ng-container>

        <ng-container *ngIf="optionGroup.minTotalQuantity && optionGroup.maxTotalQuantity">
          <div class="ingredient-card-hidden">
            <ng-container *ngTemplateOutlet="quantitySelector"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="buttonGroupChildren">
          <div class="ingredient-card-hidden">
            <ng-container *ngTemplateOutlet="buttonGroup"></ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!--  <div class="ingredient-card-hidden">-->
      <!--    <ng-container *ngIf="option?.optionGroups[0]?.options.length > 3">-->
      <!--      <ng-container *ngTemplateOutlet="dropdown"></ng-container>-->
      <!--    </ng-container>-->
      <!--    <ng-container *ngIf="option?.optionGroups[0].options.length > 3">-->
      <!--      <ng-container *ngTemplateOutlet="quantityTicker"></ng-container>-->
      <!--    </ng-container>-->
      <!--  </div>-->

    </label>
  </ng-container>
</ng-container>

<ng-template #optionSubgroupDisplay>
  <label [for]="optionSubGroup.name + (multiselect ? '-checkbox' : '-radio')" class="ingredient-card"
         [class.radio]="!multiselect" [class.checkbox]="multiselect">
    <div class="ingredient-card-visible" [formGroup]="ingredientFormGroup">
      <ng-container *ngIf="multiselect; else radio">
<!--        <input [id]="optionSubGroup.name + '-checkbox'"-->
<!--               class="ingredient-checkbox" type="checkbox"-->
<!--               [name]="optionSubGroup.name"-->
<!--               [formControlName]="optionSubGroup.name"-->
<!--               [value]="!currentIngredient?.value"-->
<!--               (click)="optionClicked()">-->
<!--        <span class="checkbox-checkmark"></span>-->
      </ng-container>
      <ng-template #radio>
        <input [id]="optionSubGroup.name + '-radio'"
               class="ingredient-radio" type="radio"
               [name]="optionGroup.nameSlug"
               [formControlName]="optionGroup.nameSlug"
               [value]="optionSubGroup.name"
               (click)="optionClicked()">
        <span class="radio-dot"></span>
      </ng-template>
      <ng-container *ngTemplateOutlet="subGroupInfoTop"></ng-container>
    </div>

    <ng-container *ngIf="currentSubGroup?.value === optionSubGroup.name">
      <ng-container *ngIf="optionSubGroup.type === 'button-group'; else halfAndHalfSubGroup">
        <div class="ingredient-card-hidden">
          <ng-container *ngTemplateOutlet="subGroupButtonGroup"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #halfAndHalfSubGroup>
      <div class="ingredient-card-hidden">
        <ng-container *ngTemplateOutlet="subGroupHalfAndHalf"></ng-container>
      </div>
    </ng-template>

    <!--  <div class="ingredient-card-hidden">-->
    <!--    <ng-container *ngIf="option?.optionGroups[0]?.options.length > 3">-->
    <!--      <ng-container *ngTemplateOutlet="dropdown"></ng-container>-->
    <!--    </ng-container>-->
    <!--    <ng-container *ngIf="option?.optionGroups[0].options.length > 3">-->
    <!--      <ng-container *ngTemplateOutlet="quantityTicker"></ng-container>-->
    <!--    </ng-container>-->
    <!--  </div>-->

  </label>
</ng-template>

<ng-template #subGroupInfoTop>
  <div class="ingredient-info-top">
    <div class="ingredient-title">
      {{ optionSubGroup.name }}
    </div>
<!--    <ng-container *ngIf="optionSubGroup.subOptions[0].option.addedCents !== 0">-->
<!--      <div class="ingredient-price">-->
<!--        {{ optionSubGroup.subOptions[0].option.addedCents / 100 | currency }}-->
<!--      </div>-->
<!--    </ng-container>-->
  </div>
</ng-template>

<ng-template #ingredientInfoTop>
  <div class="ingredient-info-top">
    <div class="ingredient-title">
      {{ option.name }}
    </div>
    <ng-container *ngIf="option.hasChildren && !buttonGroupChildren">
      <a class="ingredient-edit dark-link" (click)="editClicked()">
        Edit
      </a>
    </ng-container>
  </div>
</ng-template>

<ng-template #ingredientStatus>
  <div class="ingredient-info-bottom">
    <div class="ingredient-status">
      <ng-container *ngIf="option.hasChildren && choicesNeeded">
        <span class="badge rounded-pill d-flex align-items-center"
              [class.bg-warning]="choicesNeeded" [class.bg-secondary]="!choicesNeeded">
          {{ choicesNeeded ? 'Choices Needed' : 'Optional' }}
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16"
               class="bi" [class.bi-exclamation-circle]="choicesNeeded" [class.bi-question-circle]="!choicesNeeded">
            <ng-container *ngIf="choicesNeeded; else optional">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </ng-container>
            <ng-template #optional>
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
            </ng-template>
          </svg>
        </span>
      </ng-container>
    </div>
    <ng-container *ngIf="option.addedCents !== 0">
      <div class="ingredient-price">
        {{ option.addedCents / 100 | currency }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #subGroupButtonGroup>
  <div class="ingredient-button-group">
    <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
      <button type="button" class="group-button-primary" [class.active]="childOption.option.optionID === selectedSubOption?.optionID"
              (click)="subOptionClicked(childOption.option, optionSubGroup)">
        {{ childOption.name }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #buttonGroup>
  <div class="ingredient-button-group">
    <ng-container *ngFor="let childOption of option.optionGroups[0].options">
      <button type="button" class="group-button-primary" [class.active]="childOption.optionID === selectedSubOption?.optionID"
              (click)="subOptionClicked(childOption, optionSubGroup)">
        {{ childOption.name }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #subGroupHalfAndHalf>
  <div class="ingredient-sides">
    <span class="ingredient-sides-selected">
      Choose a Side:
      <span class="selected">
        <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
          <ng-container *ngIf="childOption.option.optionID === selectedSubOption?.optionID">
            {{ childOption.name }}
          </ng-container>
        </ng-container>
      </span>
    </span>
    <span class="ingredient-sides-shell">
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Left Half'">
          <button type="button" class="side side-left" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Whole Pie'">
          <button type="button" class="side side-whole" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Right Half'">
          <button type="button" class="side side-right" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #halfAndHalf>
  <div class="ingredient-sides">
    <span class="ingredient-sides-selected">
      Choose a Side:
      <span class="selected">
        <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
          <ng-container *ngIf="childOption.option.optionID === selectedSubOption?.optionID">
            {{ childOption.name }}
          </ng-container>
        </ng-container>
      </span>
    </span>
    <span class="ingredient-sides-shell">
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Left Half'">
          <button type="button" class="side side-left" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Whole Pie'">
          <button type="button" class="side side-whole" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
        <ng-container *ngIf="childOption.name === 'Right Half'">
          <button type="button" class="side side-right" (click)="subOptionClicked(childOption.option, optionSubGroup)"
                  [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
          </button>
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #dropdown>
  <div class="ingredient-dropdown">
    <label for="exampleFormControlSelect1">Example select</label>
    <select class="form-control" id="exampleFormControlSelect1">
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </select>
  </div>
</ng-template>

<ng-template #quantitySelector>
  <form [formGroup]="sliderForm">
    <ion-range formControlName="slider" [min]="optionGroup.minChoiceQuantity ? optionGroup.minChoiceQuantity : optionGroup.minTotalQuantity"
               [max]="optionGroup.maxTotalQuantity" [step]="optionGroup.incrementValue ? optionGroup.incrementValue : 1" class="p-0"
               (click)="$event.preventDefault(); $event.stopPropagation();" [snaps]="true" [ticks]="false" [pin]="true" mode="ios">
      <ion-icon name="remove-circle-outline" color="primary" slot="start" size="large" (click)="decreaseSlider(slider.value, (optionGroup.minChoiceQuantity ? optionGroup.minChoiceQuantity : optionGroup.minTotalQuantity))"></ion-icon>
      <ion-icon name="add-circle-outline" color="primary" slot="end" size="large" (click)="increaseSlider(slider.value, sliderUpperLimit)"></ion-icon>
    </ion-range>
  </form>
<!--  <div class="ingredient-quantity">-->
<!--    <button class="quantity-control">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">-->
<!--        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>-->
<!--      </svg>-->
<!--    </button>-->

<!--    <form class="quantity-input-shell" [formGroup]="sliderForm">-->

<!--        <input class="quantity-input" inputmode="numeric" formControlName="slider"-->
<!--               [max]="optionGroup.maxTotalQuantity" value="1" type="range"-->
<!--               [min]="optionGroup.minChoiceQuantity ? optionGroup.minChoiceQuantity : optionGroup.minTotalQuantity"-->
<!--               [step]="optionGroup.incrementValue ? optionGroup.incrementValue : 1">-->
<!--      <span class="quantity-display" [style.left.%]="((slider.value - 1) / (optionGroup.maxTotalQuantity - (optionGroup.minChoiceQuantity ? optionGroup.minChoiceQuantity : optionGroup.minTotalQuantity)) * 100)">-->
<!--        {{ slider.value }}-->
<!--      </span>-->
<!--    </form>-->

<!--    <button class="quantity-control">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">-->
<!--        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>-->
<!--      </svg>-->
<!--    </button>-->
<!--  </div>-->
</ng-template>

<ng-template #quantityTicker>
  <div class="ingredient-ticker">
    <div class="quantity-ticker">
      <!--    Decrement button should be disabled if the quanitity is at minum allowed amount-->
      <button type="button" class="button-minus" disabled>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
        </svg>
      </button>
      <!--    This info should reflect the amount of quantity-->
      <div class="quantity-count">
        123
      </div>
      <!--    Increment button should be disabled if the quanitity is at maximum allowed amount-->
      <button type="button" class="button-plus">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
      </button>
    </div>
  </div>
</ng-template>
