import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-filter-sort-bar',
  templateUrl: './filter-sort-bar.component.html',
  styleUrls: ['./filter-sort-bar.component.scss']
})
export class FilterSortBarComponent {

  selectedDropdown: string;

  @Input() gridView: any;

  @Output() filterClicked = new EventEmitter<any>();
  @Output() gridToggle = new EventEmitter<any>();

  filterItemClicked(item: string) {
    if (!this.selectedDropdown || item !== this.selectedDropdown) {
    this.selectedDropdown = item;
    } else {
      this.selectedDropdown = null;
    }
  }

  gridViewToggle(display: string) {
    this.gridToggle.emit(display);
  }
}
