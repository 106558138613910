import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Address } from '../../../interfaces/address.interface';
import { HttpClient } from '@angular/common/http';
import { DirectusClientService } from '../../../vendors/directus/directus-client.service';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../store/state.model';

declare let google;

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  @Select(state => state.app.googleMapsLoaded)
  googleMapsLoaded$: Observable<boolean>;

  private geoCoder;
  private googleSubject = new BehaviorSubject<any>(null);
  google$ = this.googleSubject.asObservable();

  constructor(
    private http: HttpClient,
    private clientService: DirectusClientService,
    private store: Store
  ) {
    this.initGoogle();
  }

  initGoogle() {
    this.googleMapsLoaded$.subscribe(loaded => {
      if (loaded) {
        this.geoCoder = new google.maps.Geocoder();
      }
    });
  }

  /**
   * Returns distance in miles between two points
   * @param lat1 - Latitude of point 1
   * @param lon1 - Longitude of point 1
   * @param lat2 - Latitude of point 2
   * @param lon2 - Longitude of point 2
   */
  findDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    if (
      !this.store.selectSnapshot(
        (state: GlobalStateModel) => state.app.googleMapsLoaded
      ) ||
      !google
    ) {
      return NaN;
    }
    const latLng1 = new google.maps.LatLng(lat1, lon1);
    const latLng2 = new google.maps.LatLng(lat2, lon2);
    return (
      0.000621371 *
      google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2)
    );
  }

  /**
   * Given an address, returns latitude and longitude of that address
   * @param add - An address in plain text
   */
  geocode(add: string): Promise<google.maps.LatLngLiteral> {
    return new Promise(
      function (resolve, reject) {
        if (this.geoCoder) {
          this.geoCoder.geocode({ address: add }, function (results) {
            if (
              !results ||
              !results[0] ||
              !results[0].geometry ||
              !results[0].geometry.location
            ) {
              reject('Could not geocode address');
            }
            try {
              // tslint:disable-next-line:max-line-length
              const geocodeRes: google.maps.LatLngLiteral = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };
              resolve(geocodeRes);
            } catch (e) {
              reject('Could not geocode address');
            }
          });
        } else {
          reject('Google maps not loaded');
        }
      }.bind(this)
    );
  }

  /**
   * Given a lat/long pair, returns address most associated with that lat/long.
   * @param lat - Latitude of point
   * @param lng - Longitude of point
   */
  reverseGeocode(lat: number, lng: number) {
    return new Promise(
      function (resolve, reject) {
        this.geoCoder.geocode({ location: { lat, lng } }, results => {
          if (!results || !results[0] || !results[0].formatted_address) {
            reject('Could not reverse geocode address');
          }
          try {
            resolve(results[0]);
          } catch (e) {
            reject('Could not reverse geocode address');
          }
        });
      }.bind(this)
    );
  }

  googlePlaceToAddress(place): Address {
    const streetNumber = place.address_components.find(component => {
      return component.types.includes('street_number');
    });
    const routeName = place.address_components.find(component => {
      return component.types.includes('route');
    });
    const localityName = place.address_components.find(component => {
      return component.types.includes('locality');
    });
    const countryName = place.address_components.find(component => {
      return (
        component.types.includes('administrative_area_level_1') ||
        component.types.includes('country')
      );
    });
    const zipNumber = place.address_components.find(component => {
      return component.types.includes('postal_code');
    });
    return {
      // tslint:disable-next-line:max-line-length
      address1:
        streetNumber && routeName
          ? streetNumber.long_name + ' ' + routeName.long_name
          : routeName
          ? routeName.long_name
          : null,
      address2: null,
      city: localityName ? localityName.long_name : null,
      state: countryName ? countryName.long_name : null,
      zipCode: zipNumber ? zipNumber.long_name : null,
      latitude: null,
      longitude: null,
    };
  }

  geocodeByIp() {
    return this.clientService.getClient().pipe(
      switchMap(client => {
        return this.http.get<{ lat: number; lon: number }>(
          environment.domainAPI +
            '/' +
            client.project +
            '/custom/dineengine/get-my-coords'
        );
      })
    );
  }

  getZoomLevel(location: ICoordinates, destination: ICoordinates): number {
    // TODO: Not sure if this is the appropriate service
    const dist = this.findDistance(
      location.latitude,
      location.longitude,
      destination.latitude,
      destination.longitude
    );
    let zoom: number;
    if (dist < 25) {
      zoom = 11;
    } else if (dist < 50) {
      zoom = 10;
    } else if (dist < 100) {
      zoom = 8;
    } else if (dist < 300) {
      zoom = 6;
    } else {
      zoom = 5;
    }
    return zoom;
  }

  getTwoPinZoomLevel(
    location: ICoordinates,
    destination: ICoordinates
  ): number {
    const dist = this.findDistance(
      location.latitude,
      location.longitude,
      destination.latitude,
      destination.longitude
    );
    let zoom: number;
    if (dist < 25) {
      zoom = 11;
    } else if (dist < 50) {
      zoom = 10;
    } else if (dist < 100) {
      zoom = 9;
    } else if (dist < 300) {
      zoom = 8;
    } else {
      zoom = 5;
    }
    return zoom;
  }

  precisionRound(number: any, precision: any) {
    // TODO: Not sure if this is the appropriate service
    if (number > 10) {
      return Math.round(number);
    } else {
      const factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    }
  }
}
