import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { User } from '../../../../../interfaces/user.interface';
import { VendorSetup } from 'src/interfaces/vendor.interface';
import { LocationsService } from '@modules/locations/services';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';

// import { MapMarker } from './map-marker.interface';

@Component({
  selector: 'app-mobile-location-list',
  templateUrl: './mobile-location-list.component.html',
  styleUrls: ['./mobile-location-list.component.scss'],
})
export class MobileLocationListComponent {
  @Input() sortedLocations: DineEngineLocation[];
  @Input() deliveryLocations: DineEngineLocation[];
  @Input() selectedLocation: DineEngineLocation;
  @Input() showDesktopView: boolean;
  @Input() showListView: boolean;
  @Input() displayUser: User;

  @Input() isLoaded: boolean;
  @Input() distances;
  @Input() editHandoff;
  @Input() centerLat: number;
  @Input() centerLong: number;
  @Input() zoom: number;
  @Input() markers;
  @Input() apiKey: string;
  @Input() markerIcon: string;
  @Input() vendorSetup: VendorSetup;
  @Input() guestDeliveryAddress;
  @Input() cardEmphasis: boolean;
  @Input() branding: Branding;
  @Input() savedUserLocations: ISavedAddress[] = [];

  @Input() lat: number;
  @Input() lng: number;
  @Input() error;

  @Output() orderNowButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() locationInfoButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() deliveryButtonClick = new EventEmitter<any>();
  @Output() pickupButtonClick = new EventEmitter<any>();
  @Output() displayMobileMap = new EventEmitter<any>();
  @Output() displayMobileList = new EventEmitter<any>();
  @Output() markerClick = new EventEmitter<any>();
  @Output() showAllLocations = new EventEmitter<any>();

  allLocationsShowing = false;
  runToggleAnimation = false;

  constructor(public locations: LocationsService) {}

  orderNowButtonClicked(loc: DineEngineLocation) {
    this.orderNowButtonClick.emit(loc);
  }

  locationInfoButtonClicked(loc: DineEngineLocation) {
    this.locationInfoButtonClick.emit(loc);
  }

  switchView() {
    this.showListView = !this.showListView;
    this.runToggleAnimation = !this.runToggleAnimation;
  }

  deliveryButtonClicked() {
    this.deliveryButtonClick.emit();
    this.displayMobileListClicked();
  }

  pickupButtonClicked() {
    this.pickupButtonClick.emit();
  }

  displayMobileMapClicked() {
    this.displayMobileMap.emit();
  }

  displayMobileListClicked() {
    this.displayMobileList.emit();
  }

  showAllLocationsClicked() {
    this.showAllLocations.emit();
    this.allLocationsShowing = true;
  }
}
