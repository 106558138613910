<div *ngIf="nutritionInfo?.calories || nutritionInfo?.caloriesFromFat || nutritionInfo?.totalFatGrams || nutritionInfo?.saturatedFatGrams || nutritionInfo?.transFatGrams || nutritionInfo?.proteinGrams || nutritionInfo?.carbohydrateGrams || nutritionInfo?.dietaryFiberGrams || nutritionInfo?.sugarGrams || nutritionInfo?.vitaminAPercent || nutritionInfo?.vitaminCPercent || nutritionInfo?.calciumPercent || nutritionInfo?.ironPercent"
  class="summary-heading-container">
  <ion-row class="summary-heading header-font">
    Nutrition Info
  </ion-row>
</div>

<div *ngIf="nutritionInfo?.calories || nutritionInfo?.caloriesFromFat || nutritionInfo?.totalFatGrams || nutritionInfo?.saturatedFatGrams || nutritionInfo?.transFatGrams"
  class="nutrition-container-dark">

  <ion-row *ngIf="nutritionInfo?.calories">
    <ion-col size="10" class="nutrition-primary-title">
      Calories
    </ion-col>
    <ion-col size="2" class="nutrition-primary-amount">
      {{ nutritionInfo?.calories }}
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.caloriesFromFat">
    <ion-col size="10" class="nutrition-secondary-title">
      Calories from Fat
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.caloriesFromFat }}
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.totalFatGrams">
    <ion-col size="10" class="nutrition-primary-title">
      Total Fat
    </ion-col>
    <ion-col size="2" class="nutrition-primary-amount">
      {{ nutritionInfo?.totalFatGrams }}g
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.saturatedFatGrams">
    <ion-col size="10" class="nutrition-secondary-title">
      Saturated Fat
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.saturatedFatGrams }}g
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.transFatGrams">
    <ion-col size="10" class="nutrition-secondary-title">
      Trans Fat
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.transFatGrams }}g
    </ion-col>
  </ion-row>

</div>

<div *ngIf="nutritionInfo?.proteinGrams || nutritionInfo?.carbohydrateGrams || nutritionInfo?.dietaryFiberGrams || nutritionInfo?.sugarGrams"
  class="nutrition-container-light">

  <ion-row *ngIf="nutritionInfo?.proteinGrams">
    <ion-col size="10" class="nutrition-primary-title">
      Protein
    </ion-col>
    <ion-col size="2" class="nutrition-primary-amount">
      {{ nutritionInfo?.proteinGrams }}g
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.carbohydrateGrams">
    <ion-col size="10" class="nutrition-primary-title">
      Carbohydrates
    </ion-col>
    <ion-col size="2" class="nutrition-primary-amount">
      {{ nutritionInfo?.carbohydrateGrams }}g
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.dietaryFiberGrams">
    <ion-col size="10" class="nutrition-secondary-title">
      Dietary Fiber
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.dietaryFiberGrams }}g
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.sugarGrams">
    <ion-col size="10" class="nutrition-secondary-title">
      Sugar
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.sugarGrams }}g
    </ion-col>
  </ion-row>

</div>

<div *ngIf="nutritionInfo?.vitaminAPercent || nutritionInfo?.vitaminCPercent || nutritionInfo?.calciumPercent || nutritionInfo?.ironPercent"
  class="nutrition-container-dark">

  <ion-row>
    <ion-col size="12" class="nutrition-primary-title">
      Vitamins & Minerals
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.vitaminAPercent">
    <ion-col size="10" class="nutrition-secondary-title">
      Vitamins A
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.vitaminAPercent }}%
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.vitaminCPercent">
    <ion-col size="10" class="nutrition-secondary-title">
      Vitamins C
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.vitaminCPercent }}%
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.calciumPercent">
    <ion-col size="10" class="nutrition-secondary-title">
      Calcium
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.calciumPercent }}%
    </ion-col>
  </ion-row>

  <ion-row *ngIf="nutritionInfo?.ironPercent">
    <ion-col size="10" class="nutrition-secondary-title">
      Iron
    </ion-col>
    <ion-col size="2" class="nutrition-secondary-amount">
      {{ nutritionInfo?.ironPercent }}%
    </ion-col>
  </ion-row>

</div>
