import { KioskConfig } from '../../vendors/directus/interfaces/kiosk-config.interface';

export class InitializeAppState {
  static readonly type = '[App] Initialize App State';

  constructor(public mode: string) {}
}

export class UpdateTitle {
  static readonly type = '[App] Update Title';

  constructor(public title: string) {}
}

export class SetRouteBack {
  static readonly type = '[App] Set Route Back';

  constructor(public route: string) {}
}

export class CloseAnnouncementBar {
  static readonly type = '[App] Close Announcement Bar';

  constructor() {}
}

export class InitializeGoogleMaps {
  static readonly type = '[App] Initialize Google Maps';

  constructor(public key: string) {}
}

export class SetShowFooter {
  static readonly type = '[App] Set Show Footer';

  constructor(public show: boolean) {}
}

export class SwitchMenuView {
  static readonly type = '[App] Switch Menu View';

  constructor(public view: 'list' | 'card') {}
}

export class SetLoading {
  static readonly type = '[App] Set Loading';

  constructor(public loading: boolean) {}
}

export class SetKioskConfig {
  static readonly type = '[App] Set Kiosk Config';

  constructor(public config: KioskConfig) {}
}
