import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '../../../interfaces/order.interface';
import { CancelOrder, EditOrder, ReorderPastOrder } from '../../../store/actions/order.actions';
import { CancelEditOrderModalComponent } from '@modules/profile/components/cancel-edit-order-modal/cancel-edit-order-modal.component';
import { SetPastOrders } from '../../../store/actions/user.actions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NavigationService } from '@modules/navigation/services';
import { ToastService } from '../../../services/toast.service';

@Injectable()
export class AppCommonService {
  modalRef: NgbModalRef;
  orderNowLoading = false;
  isLoading;
  error;
  successMessage;
  orderLoading: string = null;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private navigation: NavigationService,
    private toast: ToastService
  ) {}

  getAppCommon$(): Observable<{}> {
    return of({});
  }

  reorder(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.orderLoading = order.orderID;
    this.modalService.dismissAll();
    this.store
      .dispatch(new ReorderPastOrder(order))
      .toPromise()
      .then(() => {
        this.navigation.navigateToCheckoutPage().then(() => {
          this.orderLoading = null;
        });
      })
      .catch(error => {
        this.orderLoading = null;
        if (error && error.error && error.error.message) {
          this.error = error.error.message;
          this.toast.danger(error.error.message);
        } else {
          this.error = 'There was an error starting your reorder';
          this.toast.danger('There was an error starting your reorder');
        }
      });
  }

  editOrderClicked(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, { centered: true, modalDialogClass: 'condensed-modal' });
    this.modalRef.componentInstance.isCancelOrder = false;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store
        .dispatch(new EditOrder(order))
        .toPromise()
        .then(newState => {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          const menuID = newState.order.order.location.slugURL
            ? newState.order.order.location.slugURL
            : newState.order.order.location.locationID;
          this.navigation.navigateToMenuPage(menuID);
        })
        .catch(error => {
          if (error && error.error && error.error.message) {
            this.error = error.error.message;
            this.toast.danger(error.error.message);
          } else {
            this.error = 'There was an error editing this order';
            this.toast.danger('There was an error editing this order');
          }
          this.isLoading = false;
          this.store.dispatch(new SetPastOrders());
        });
    });
  }

  cancelOrderClicked(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, { centered: true, modalDialogClass: 'condensed-modal' });
    this.modalRef.componentInstance.isCancelOrder = true;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store
        .dispatch(new CancelOrder(order))
        .toPromise()
        .then(() => {
          this.isLoading = false;
          this.toast.success('Order Canceled');
          this.successMessage = 'Order Canceled';
          this.store.dispatch(new SetPastOrders());
        })
        .catch(error => {
          if (error && error.error && error.error.message) {
            this.error = error.error.message;
            this.toast.danger(error.error.message);
          } else {
            this.error = 'There was an error canceling this order';
            this.toast.danger('There was an error canceling this order');
          }
          this.isLoading = false;
          this.store.dispatch(new SetPastOrders());
        });
    });
  }

  viewConfirmation(order: Order) {
    this.navigation.navigateToConfirmationPage(order.orderID).then(() => this.modalService.dismissAll());
  }
}
