import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { KioskTimeoutService } from '@modules/kiosk/services';
import { SubscriptionComponent } from '@common/components';
import { ClearOrder } from '../../../../store/actions/order.actions';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Order } from '../../../../interfaces/order.interface';
import { Observable } from 'rxjs';
import { NavigationService } from '@modules/navigation/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardTerminalService } from '../../../../services/vendor-config-service/card-terminal.service';

@Component({
  selector: 'app-kiosk-timeout-modal',
  templateUrl: './kiosk-timeout-modal.component.html',
  styleUrls: ['./kiosk-timeout-modal.component.css'],
})
export class KioskTimeoutModalComponent implements AfterViewInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.order.order) order$!: Observable<Order>;
  @ViewChild('timeoutModal') timeoutInfo: TemplateRef<any>;

  @Input() order: Order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);

  constructor(
    public timeoutService: KioskTimeoutService,
    private store: Store,
    private navigation: NavigationService,
    private modalService: NgbModal,
    private cardTerminal: CardTerminalService
  ) {}

  @HostListener('window:click') onWindowClick() {
    this.timeoutService.resetTimeout(this.timeoutInfo);
  }

  ngAfterViewInit() {
    this.timeoutService.setKioskTimeout(this.timeoutInfo);
  }

  ngOnDestroy() {
    this.timeoutService.cancelTimeout();
  }

  startOrderOver() {
    // send to home page
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.clearCardReader();
        this.closeModal();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.clearCardReader();
      this.closeModal();
    }
  }

  private clearCardReader() {
    this.cardTerminal.getService().subscribe(terminal => {
      if (terminal) {
        terminal.cancelTransaction().subscribe({
          next: () => {
            terminal.resetPinPad().subscribe();
          },
          error: () => {
            terminal.resetPinPad().subscribe();
          },
        });
      }
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
