import { Pipe, PipeTransform } from '@angular/core';
import {Location} from '../interfaces/location.interface';
import {Branding} from '../vendors/directus/interfaces/branding.interface';

@Pipe({
  name: 'locationMarker'
})
export class LocationMarkerPipe implements PipeTransform {

  transform(location: Location): google.maps.MarkerOptions {
    return {
      icon: {
        scaledSize: {
          equals(other: google.maps.Size): boolean {
            return 60 === other.width && 60 === other.height;
          },
          width: 60,
          height: 60,
        },
        url: location.mapIconURL
      },
    };
  }

}
