import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category} from '../../../../interfaces/category.interface';
import {Router} from '@angular/router';
import {SlugPipe} from '@common/services/slug.pipe';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../interfaces/user.interface';
import {UserRoles} from '@common/components';
import {ModeService} from '../../../../services/mode.service';
import {NavbarSettings} from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-category-jump-nav',
  templateUrl: './category-jump-nav.component.html',
  styleUrls: ['category-jump-nav.component.scss'],
})
export class CategoryJumpNavComponent implements OnInit {
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Input() categories: Category[];
  @Output() clickCategory = new EventEmitter<Category>();
  activeCategory: Category;
  pathURL = '';
  roles = UserRoles;
  constructor(
    private router: Router,
    private slugPipe: SlugPipe,
    public modeService: ModeService,
  ) {}
  ngOnInit() {
    // var scrollTimeout;
    // addEventListener('scroll', (e) => {
    //   clearTimeout(scrollTimeout);
    //   scrollTimeout = setTimeout(() => {
    //     console.log('Scroll ended');
    //   }, 100);
    // });
    this.activeCategory = this.categories[0];
    this.pathURL = this.router.url.split('#')[0];
  }
  scroll(id: string, index: number) {
    this.activeCategory = this.categories[index];
    const el = document.getElementById(this.slugPipe.transform(id));
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // const el2 = document.getElementById(this.slugPipe.transform('nav-' + id));
      // if (el2) {
      //   el2.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      // }
    }
    const category = this.categories.find((cat: Category) => {
      return cat.name === id;
    });
    this.clickedCategory(category);
  }
  clickedCategory(category: Category) {
    this.clickCategory.emit(category);
  }
  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }
}
