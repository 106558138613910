import { OpenStreetMapProvider } from 'leaflet-geosearch';

export class ChepriOsmService extends OpenStreetMapProvider {
  constructor(options) {
    super({
      ...options,
      searchUrl: 'https://nominatim.chepri.io/search',
      reverseUrl: 'https://nominatim.chepri.io/reverse'
    });
  }
}
