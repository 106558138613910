import { Component, Input } from '@angular/core';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-nutrition-card',
  templateUrl: './nutrition-card.component.html',
  styleUrls: ['./nutrition-card.component.scss']
})
export class NutritionCardComponent {

  @Input() data;

  constructor(public mobile: MobileService) {}

}
