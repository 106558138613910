import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

/* guards */
// import * as locationsGuards from '../guards';
import { AuthGuard } from '../guards/auth.guard';
import { MobileGuard } from '../guards/mobile.guard';
// import {VendorGuard} from '../guards/vendor.guard';
import { TablesideGuard } from '../guards/tableside.guard';
import { ForceLoginGuard } from '../guards/force-login.guard';
import { HomePageGuard } from '@modules/auth/guards/home-page.guard';
import { TablesideHiddenGuard } from '../guards/tableside-hidden.guard';
import { SitemapGuard } from '../guards/sitemap.guard';
import { MenuGuard } from '@modules/menu/guards';
import { ProviderGuard } from '@common/guards';
import { IncompleteAccountGateComponent } from '@modules/profile/containers';
import { CompleteProfileGuard } from '../guards/complete-profile.guard';
// import {CustomPreloadingStrategy} from '@common/services/custom-preloading-strategy.service';

const routes: Routes = [
  // LOCATIONS
  {
    path: 'locations',
    canActivate: [ProviderGuard, ForceLoginGuard, TablesideHiddenGuard, MenuGuard],
    loadChildren: () => import('@modules/locations/locations-routing.module').then(m => m.LocationsRoutingModule),
  },
  // MENU
  {
    path: 'menu',
    canActivate: [ProviderGuard, ForceLoginGuard, TablesideGuard, MenuGuard, CompleteProfileGuard],
    loadChildren: () => import('@modules/menu/menu-routing.module').then(m => m.MenuRoutingModule),
  },
  // OLO SERVE
  {
    path: 'browser-wrapper',
    loadChildren: () => import('@modules/wrapped-browser/wrapped-browser-routing.module').then(m => m.WrappedBrowserRoutingModule),
  },
  // ORDER
  {
    path: 'cart/:orderID',
    canActivate: [ProviderGuard],
    loadChildren: () => import('@modules/cart/cart-routing.module').then(m => m.CartRoutingModule),
  },
  // GROUP ORDER
  {
    path: 'group-order',
    canActivate: [ProviderGuard],
    loadChildren: () => import('@modules/group-order/group-order-routing.module').then(m => m.GroupOrderRoutingModule),
  },
  // CHECKOUT
  {
    path: 'checkout',
    canActivate: [ProviderGuard, CompleteProfileGuard],
    loadChildren: () => import('@modules/checkout/checkout-routing.module').then(m => m.CheckoutRoutingModule),
  },
  // USERS
  {
    path: 'user',
    canActivate: [ProviderGuard],
    // canLoad: [UsersGuard], // This could prevent loading of this module if it's not being used
    loadChildren: () => import('@modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
  },
  {
    path: 'profile',
    canActivate: [ProviderGuard, AuthGuard, CompleteProfileGuard],
    // canLoad: [UsersGuard], // This could prevent loading of this module if it's not being used
    loadChildren: () => import('@modules/profile/profile-routing.module').then(m => m.ProfileRoutingModule),
  },
  {
    path: 'incomplete-profile',
    // tslint:disable-next-line:max-line-length
    component: IncompleteAccountGateComponent,
  },
  // REWARDS
  {
    path: 'rewards',
    // canActivate: [AuthGuard, VendorGuard],
    canActivate: [ProviderGuard, AuthGuard],
    loadChildren: () => import('@modules/loyalty/loyalty-routing.module').then(m => m.LoyaltyRoutingModule),
  },
  // MOBILE
  {
    path: 'welcome',
    loadChildren: () => import('@modules/auth/containers/guest-landing/guest-landing.module').then(m => m.GuestLandingPageModule),
    data: { preload: true },
    canActivate: [HomePageGuard, TablesideHiddenGuard],
  },
  // ERROR
  {
    path: '404',
    loadChildren: () => import('@modules/error/containers/missing-page/missing-page.module').then(m => m.MissingPagePageModule),
  },

  // CMS TODO: Eventually this should be built into a redirect guard, the current implementation adds an unneeded page
  {
    path: 'admin',
    loadChildren: () => import('@modules/admin/admin.module').then(m => m.AdminModule),
  },

  {
    path: 'gift-cards',
    loadChildren: () => import('@modules/gift-card/gift-card-routing.module').then(m => m.GiftCardRoutingModule),
  },

  {
    path: 'sitemap.xml',
    canActivate: [SitemapGuard],
    component: SitemapGuard,
    data: {
      externalUrl: 'https://public.test/frontend/sitemap.xml',
    },
  },

  // BROCHURE
  {
    path: '',
    loadChildren: () => import('@modules/brochure/brochure-routing.module').then(m => m.BrochureRoutingModule),
    data: { preload: true },
  },
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', preloadingStrategy: CustomPreloadingStrategy})
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
})
export class AppRoutingModule {}
