import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import moment from 'moment-timezone';

import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Preferences as Storage } from '@capacitor/preferences';

import { UserProvider } from '../../providers/user-provider.interface';
import { LoyaltyProvider } from '../../providers/loyalty-provider.interface';
import { PersonicaApiService } from './personica-api.service';
import { PersonicaMappingService } from './personica-mapping.service';
import { OLOProviderService } from '../olo/olo-provider.service';
import { OrderService } from '../../services/vendor-config-service/order.service';

import { SSOLogin } from '../../interfaces/sso-login.model';
import { PassResetResponse } from '../../interfaces/pass-reset-response.interface';
import { User } from '../../interfaces/user.interface';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { CateringLink } from '../../interfaces/catering-link.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { HistoryEvent } from '../../interfaces/history-event.interface';
import { Reward } from '../../interfaces/reward.interface';
import { Order } from '../../interfaces/order.interface';
import { DollarReward } from '../../interfaces/dollar-reward.interface';
import { Offer } from '../../interfaces/offer.interface';
import { GetActivityRequest } from './interfaces/get-activity-request.interface';
import { MemberAccessTokenRequest } from './interfaces/member-access-token-request.interface';
import { CreateMemberRequest } from './interfaces/create-member-request.interface';
import { SSOProvider } from '../../interfaces/sso-provider.enum';
import { UpdateMemberRequest } from './interfaces/update-member-request.interface';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { DirectusService } from '../directus/directus.service';
import { InboxMessage } from 'src/interfaces/inbox-message.interface';
import { UserField } from '../../interfaces/user-field';
import { GooglePass } from '../../interfaces/google-pass';
import { Referral } from '../../interfaces/referral.interface';
import VendorConfig from '../config/vendor.config';

@Injectable({
  providedIn: 'root',
})
export class PersonicaProviderService implements UserProvider, LoyaltyProvider {
  private personicaAccessTokenKey = LocalStorageKey.PERSONICA_ACCESS_TOKEN;
  private personicaMemberIDKey = LocalStorageKey.PERSONICA_MEMBER_ID;
  private personicaAccessTokenExpirationKey = LocalStorageKey.PERSONICA_ACCESS_TOKEN_EXPIRATION;
  private clientID: string;

  private ssoLoginSubject = new BehaviorSubject<SSOLogin>(null);
  ssoLogin$ = this.ssoLoginSubject.asObservable();

  providerName = VendorConfig.personica;

  constructor(
    private personicaAPI: PersonicaApiService,
    private personicaMapping: PersonicaMappingService,
    private orderService: OrderService,
    private directus: DirectusService
  ) {
    this.directus.getPersonicaSettings().subscribe(() => {});
  }

  getMessages(userID: string): Observable<InboxMessage[]> {
    throw new Error('Method not implemented.');
  }
  deleteMessage(userID: string, messageID: string): Observable<InboxMessage[]> {
    throw new Error('Method not implemented.');
  }
  markMessageAsRead(userID: string, messageID: string): Observable<InboxMessage[]> {
    throw new Error('Method not implemented.');
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  deleteAccount(userID: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  // USER

  getSSOLoginSubject(): Observable<SSOLogin> {
    return this.ssoLogin$;
  }

  logIn(username: string, password: string): Observable<string> {
    const body = {
      username,
      password,
    } as MemberAccessTokenRequest;
    return this.personicaAPI.getMemberAccessToken(body).pipe(
      switchMap(user => {
        return forkJoin({
          accessToken: this.setAccessToken(user.accessToken),
          accessExp: this.setAccessTokenExpiration(moment().add(user.expires, 'seconds').toDate()),
        }).pipe(
          switchMap(() => {
            return this.getAccessToken();
          })
        );
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    this.deleteAccessToken();
    this.deleteMemberID();
    return this.getMemberID();
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    throw new Error('No');
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return throwError('No');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('No');
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  forgotPassword(email: string): Observable<any> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return oService.forgotPassword(email);
        } else {
          throw new Error('No');
        }
      })
    );
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    throw new Error('No');
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('No');
  }

  updateUserInfo(user: User): Observable<User> {
    const body = {
      memberID: user.userID,
      emailAddress: user.email,
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      emailOptIn: user.emailOptIn,
      sMSOptIn: user.sMSOptIn,
      isRequestFromJoinPage: true,
    } as UpdateMemberRequest;
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        return this.personicaAPI.updateMember(body, accessToken).pipe(
          switchMap(() => {
            return this.getMemberID().pipe(
              switchMap(memberID => {
                return this.getUserInfo(memberID);
              })
            );
          })
        );
      })
    );
  }

  createAccount(newAccount: CreateAccount, additionalFields: UserField[]): Observable<User> {
    return this.personicaAPI.getSignUpToken().pipe(
      switchMap(token => {
        const body = {
          firstName: newAccount.firstName,
          lastName: newAccount.lastName,
          password: newAccount.password,
          emailAddress: newAccount.email,
          dOB: moment(newAccount.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          phoneNumber: newAccount.phone,
          emailOptIn: newAccount.emailOptIn,
          loyaltyOptIn: true,
          sMSOptIn: newAccount.smsOptIn,
          isRequestFromJoinPage: true,
        } as CreateMemberRequest;
        if (additionalFields && additionalFields.length > 0) {
          additionalFields.forEach(field => {
            body[field.providerFieldName] = field.value;
          });
        }
        return this.personicaAPI.createMember(body, token.message).pipe(
          switchMap(response => {
            return this.setMemberID(String(response.memberID)).pipe(
              switchMap(() => {
                return this.getLoggedInUserInfo();
              })
            );
          })
        );
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        return this.personicaAPI.getMemberDetail(accessToken).pipe(
          switchMap(member => {
            return this.setMemberID(String(member.memberId)).pipe(
              map(() => {
                return this.personicaMapping.memberResponseToUser(member);
              })
            );
          })
        );
        // return this.personicaAPI.getLoyaltyMemberInfo(userID, accessToken).pipe(map(user => {
        //       console.log(user);
        //       return <User>user;
        //     }),
        // );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        if (accessToken) {
          return this.isAccessTokenExpired().pipe(
            switchMap(tokenExpired => {
              if (tokenExpired) {
                return this.personicaAPI.refreshToken(accessToken).pipe(
                  switchMap(res => {
                    return forkJoin({
                      accessToken: this.setAccessToken(res.accessToken),
                      accessExp: this.setAccessTokenExpiration(moment(res.expires).toDate()),
                    }).pipe(
                      switchMap(() => {
                        return this.getUserInfo(accessToken);
                      })
                    );
                  })
                );
              } else {
                this.ssoLoginSubject.next({
                  provider: SSOProvider.personica,
                  token: accessToken,
                });
                return this.getUserInfo(accessToken);
              }
            })
          );
        } else {
          return of({
            userID: null,
            isGuest: true,
          } as User);
        }
      })
    );
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  isOauthEnabled(): Observable<boolean> {
    return this.personicaAPI.isOauthEnabled();
  }

  redirectToOauthPage() {
    throw new Error('No');
  }

  cateringLink(): Observable<CateringLink> {
    throw new Error('No');
  }

  // LOYALTY

  getPointsBalance(userID: string | number): Observable<RewardsBalances> {
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        return this.personicaAPI.getPointRewardInfo(String(userID), accessToken).pipe(
          map(balances => {
            return this.personicaMapping.toRewardsBalances(balances);
          })
        );
      })
    );
  }

  getOffers(userID: string | number): Observable<Offer[]> {
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        return this.personicaAPI.getOfferRewardInfo(String(userID), accessToken).pipe(
          map(allRewards => {
            return allRewards.rewardList.map(o => this.personicaMapping.toOffer(o));
          })
        );
      })
    );
  }

  getLoyaltyActivity(): Observable<HistoryEvent[]> {
    return this.getMemberID().pipe(
      switchMap(memberID => {
        const body: GetActivityRequest = {
          memberId: Number(memberID),
          // startIndex: 0,
          count: 50,
          sort: [
            {
              fieldName: 'EventDate',
              direction: 'desc',
            },
          ],
        } as GetActivityRequest;
        return this.getAccessToken().pipe(
          switchMap(accessToken => {
            return this.personicaAPI.getActivity(body, accessToken).pipe(
              map(response => {
                return response.loyaltyActivityList.map(a => this.personicaMapping.toHistoryEvent(a));
              }),
              catchError(error => of([]))
            );
          })
        );
      })
    );
  }

  earnPoints(userID: string | number): Observable<any> {
    throw new Error('No');
  }

  getRewards(userID: string | number, locationID: string | number): Observable<Reward[]> {
    return forkJoin({
      memberID: this.getMemberID(),
      accessToken: this.getAccessToken(),
    }).pipe(
      switchMap(tokens => {
        return this.personicaAPI.getOfferRewardInfo(tokens.memberID, tokens.accessToken).pipe(
          map(allRewards => {
            return allRewards.rewardList.map(r => this.personicaMapping.toReward(r));
          })
        );
      })
    );
  }

  getAvailableLoyaltyRewards(userID: string): Observable<LoyaltyReward[]> {
    return forkJoin({
      memberID: this.getMemberID(),
      accessToken: this.getAccessToken(),
    }).pipe(
      switchMap(tokens => {
        return this.personicaAPI.getOfferRewardInfo(tokens.memberID, tokens.accessToken).pipe(
          map(allRewards => {
            return allRewards.rewardList.map(r => this.personicaMapping.toLoyaltyReward(r));
          })
        );
      })
    );
  }

  redeemReward(reward: Reward): Observable<Order> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return oService.getCurrentOrder(null).pipe(
            switchMap(order => {
              return oService.addCoupon(order.orderID, String(reward.redemptionInfo.redemptionCode));
            })
          );
        }
      })
    );
  }

  redeemInStoreReward(reward: Reward): Observable<Reward> {
    return of(reward);
  }

  redeemBankedPoints(points: number): Observable<Reward> {
    throw new Error('No');
  }

  voidBankedPoints(reward: DollarReward): Observable<any> {
    throw new Error('No');
  }

  removeAppliedReward(reward: Reward): Observable<Order> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return oService.getCurrentOrder(null).pipe(
            switchMap(order => {
              return oService.removeCoupon(order.orderID);
            })
          );
        }
      })
    );
  }

  redeemPointsFromScanner(barCode: string): Observable<any> {
    throw new Error('No');
  }

  checkInAtStore(): Observable<CheckInCode> {
    return of(null);
  }

  createQuickCode(): Observable<CheckInCode> {
    return of(null);
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return of(null);
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return of(false);
  }

  canTransferGiftCardToAccount(): Observable<boolean> {
    return of(false);
  }

  transferGiftCardBalanceToAccount(from: GiftCard): Observable<{ success: boolean }> {
    throw new Error('No');
  }

  getLoyaltyStoredValueCardInfo(userID: string): Observable<GiftCard> {
    throw new Error('No');
  }

  private getAccessToken(): Observable<string> {
    return from(Storage.get({ key: this.personicaAccessTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.personicaAccessTokenKey);
  }

  private setAccessToken(accessToken: string): Observable<void> {
    // this.ssoLoginSubject.next({
    //   provider: SSOProvider.personica,
    //   token: this.getAccessToken(),
    // });
    return from(Storage.set({ key: this.personicaAccessTokenKey, value: accessToken }));
    // localStorage.setItem(this.personicaAccessTokenKey, accessToken);
  }

  private deleteAccessToken(): void {
    Storage.remove({ key: this.personicaAccessTokenKey });
    // localStorage.removeItem(this.personicaAccessTokenKey);
  }

  private setAccessTokenExpiration(date: Date): Observable<void> {
    return from(
      Storage.set({
        key: this.personicaAccessTokenExpirationKey,
        value: date.toString(),
      })
    );
    // localStorage.setItem(this.personicaAccessTokenExpirationKey, date.toString());
  }

  private isAccessTokenExpired(): Observable<boolean> {
    return from(Storage.get({ key: this.personicaAccessTokenExpirationKey })).pipe(
      map(data => {
        return data.value && moment(new Date(data.value)).isBefore(moment());
      })
    );
    // return localStorage.getItem(this.personicaAccessTokenExpirationKey) &&
    //     moment(new Date(localStorage.getItem(this.personicaAccessTokenExpirationKey))).isBefore(moment());
  }

  private getMemberID(): Observable<string> {
    return from(Storage.get({ key: this.personicaMemberIDKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.personicaMemberIDKey);
  }

  private setMemberID(memberID: string): Observable<void> {
    return from(Storage.set({ key: this.personicaMemberIDKey, value: memberID }));
    // localStorage.setItem(this.personicaMemberIDKey, memberID);
  }

  private deleteMemberID(): void {
    Storage.remove({ key: this.personicaMemberIDKey });
    // localStorage.removeItem(this.personicaMemberIDKey);
  }

  private getClientID(): Observable<string> {
    return this.clientID
      ? of(this.clientID)
      : this.directus.getPersonicaSettings().pipe(
          tap(res => (this.clientID = res.client_id)),
          map(res => res.client_id)
        );
  }

  private generateGUID() {
    // tslint:disable-next-line:max-line-length
    return (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();
  }

  private S4() {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  getLoyaltyLocations(): Observable<any> {
    return undefined;
  }

  getApplePassUrl(userID: string): Observable<string> {
    return of(null);
  }

  getGooglePassData(userID: string): Observable<GooglePass> {
    return of(null);
  }

  markGooglePassAsSaved(userID: string, objectCode: string): Observable<boolean> {
    return of(true);
  }

  getReferrals(userID: string): Observable<Referral[]> {
    return of([]);
  }

  sendReferrals(userID: string, emails: string[], message: string): Observable<Referral[]> {
    return of([]);
  }

  supportsReferrals(): boolean {
    return false;
  }
}
