import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return (
        this.includes(it.name, searchText) ||
        this.includes(it.address, searchText) ||
        this.includes(it.city, searchText) ||
        this.includes(it.zip, searchText)
      );
    });
  }

  includes(hay, needle) {
    return hay.toLowerCase().includes(needle);
  }
}
