import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProxyBaseURLService} from '../services/proxy-base-url.service';
import {ProjectKeyService} from '../services/project-key.service';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SitemapGuard  {

  constructor(
    private router: Router,
    private baseURLService: ProxyBaseURLService,
    private keyService: ProjectKeyService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.keyService.getProjectKey().pipe(map((key) => {
      window.location.href = environment.domainAPI + '/frontend/' + key + '/sitemap.xml';
      return true;
    }));
  }
}
