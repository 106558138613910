import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Order } from '../../../../interfaces/order.interface';
import { GiftCardItem } from '../../../../interfaces/gift-card-item.interface';
import { PaymentTypes } from '../../../../interfaces/payment-types.enum';
import { ModeService } from '../../../../services/mode.service';
import { CartService } from '@modules/cart/services';
import { Reward } from '../../../../interfaces/reward.interface';
import { RemoveReward, StageReward } from '../../../../store/actions/order.actions';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { DineEngineError } from '../../../../interfaces/dineengine-error.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../../services/error.service';
import { ToastService } from '../../../../services/toast.service';
import { GlobalStateModel } from '../../../../store/state.model';

@Component({
  selector: 'app-mobile-cart-totals',
  templateUrl: './mobile-cart-totals.component.html',
  styleUrls: ['mobile-cart-totals.component.scss'],
})
export class MobileCartTotalsComponent {
  paymentTypes = PaymentTypes; // for template

  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Input() giftCards: GiftCardItem[];
  @Input() appliedLoyaltyBalance: { balanceCents: number; cardNumber: any; cardPin: any } = {
    balanceCents: 0,
    cardNumber: null,
    cardPin: null,
  };
  @Input() isLoading: boolean;
  @Input() submitBlocked = false;
  @Input() paymentType: PaymentTypes = null;
  @Input() rewards: Reward[];

  @Output() submitClicked = new EventEmitter<any>();
  errorMessage = '';
  loadingReward: number | string = null;
  areRewardsSelecting = false;

  constructor(
    public modeService: ModeService,
    public cartService: CartService,
    private analytics: AnalyticsService,
    private store: Store,
    private errorService: ErrorService,
    private toast: ToastService
  ) {}
  clickedSubmit() {
    this.submitClicked.emit();
  }
  giftCardTotal() {
    let total = 0;
    this.giftCards.forEach(res => {
      total = total + res.balanceCents;
    });
    return total;
  }

  redeemedReward(reward: Reward) {
    this.errorMessage = '';
    this.cartService.errorMessage = '';
    this.loadingReward = reward.rewardID;
    this.areRewardsSelecting = true;
    this.analytics.analyticsOnRewardSelect(
      reward,
      this.rewards.indexOf(reward),
      this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
    );
    this.store.dispatch(new StageReward(reward));
  }

  removedReward(reward: Reward) {
    this.errorMessage = '';
    this.cartService.errorMessage = '';
    this.loadingReward = reward.rewardID;
    this.areRewardsSelecting = true;
    this.store
      .dispatch(new RemoveReward(reward))
      .toPromise()
      .then(() => {
        // this.store
        //   .dispatch(new UpdateAppliedRewards(reward))
        //   .toPromise()
        //   .then(() => {
        this.loadingReward = null;
        this.areRewardsSelecting = false;
        // });
      })
      .catch(er => {
        this.loadingReward = null;
        this.errorMessage = er instanceof DineEngineError ? er.message : this.errorService.checkoutError(er as HttpErrorResponse);
        this.toast.danger(this.errorMessage);
        this.areRewardsSelecting = false;
      });
  }
}
