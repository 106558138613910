/* tslint:disable: ordered-imports*/
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/* Third Party */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const thirdParty = [NgbModule, IonicModule];

/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import * as appCommonComponents from './components';

/* Guards */
import * as appCommonGuards from './guards';

/* Services */
import * as appCommonServices from './services';
import * as authServices from '@modules/auth/services';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  imports: [CommonModule, RouterModule, ...thirdParty, PipesModule, ReactiveFormsModule, NgxMaskDirective],
  providers: [...appCommonServices.services, ...authServices.services, ...appCommonGuards.guards],
  declarations: [...appCommonContainers.containers, ...appCommonComponents.components],
  exports: [...appCommonContainers.containers, ...appCommonComponents.components, ...thirdParty],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppCommonModule {}
