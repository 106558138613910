<ng-container *ngIf="(navbarSettings$ | async) as navbarSettings">
  <ng-container *ngIf="navbarSettings.additional_links">
      <div [ngStyle]="{'background-color':navbarSettings.desktop_brand_background_color}">
          <div class="d-flex flex-row flex-nowrap justify-content-between" [class.container]="navbarSettings?.fixed_navbar_width"
               [class.px-3]="!navbarSettings?.fixed_navbar_width">
            <div class="d-flex flex-row">
              <ng-container *ngFor="let link of navbarSettings.additional_links">
                <ng-container *ngIf="link.is_brand_link">
                  <ng-container *ngIf="!link.is_current_site; else noLink">
                    <a [href]="link.link" class="m-0 additional-link" style="display: grid; place-items: center"
                       [ngStyle]="{
              'background-color':(link.is_current_site ? navbarSettings.desktop_brand_active_color : 'transparent'),
              'color':(link.is_current_site ? link.desktop_active_color : 'white')
            }">
                      <ng-container *ngIf="link.desktop_image && link.desktop_active_image; else noImage">
                        <img class="px-3 py-2 button-image"
                             [src]="link.is_current_site ? link.desktop_active_image : link.desktop_image"
                             [alt]="link.title">
                      </ng-container>
                      <ng-template #noImage>
                        {{ link.title }}
                      </ng-template>
                    </a>
                  </ng-container>
                  <ng-template #noLink>
                    <div class="m-0 additional-link" style="display: grid; place-items: center" [ngStyle]="{
              'background-color':(link.is_current_site ? navbarSettings.desktop_brand_active_color : 'transparent'),
              'color':(link.is_current_site ? link.desktop_active_color : 'white')
            }">
                      <ng-container *ngIf="link.desktop_image && link.desktop_active_image; else noImage">
                        <img class="px-3 py-2 button-image"
                             [src]="link.is_current_site ? link.desktop_active_image : link.desktop_image"
                             [alt]="link.title">
                      </ng-container>
                      <ng-template #noImage>
                        {{ link.title }}
                      </ng-template>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </div>
        <div class="d-flex flex-row align-items-center" *ngIf="navbarSettings.subnav_links && navbarSettings.subnav_links.length">
          <ng-container *ngFor="let link of navbarSettings.subnav_links; first as first">
            <div class="cursor-pointer px-3 font-size-small underline-on-hover" (click)="subnavClicked(link)" [style.color]="navbarSettings.desktop_brand_background_color | textColor">{{link.name}}</div>
          </ng-container>
        </div>
          </div>

      </div>
  </ng-container>
</ng-container>
