import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MobileService } from 'src/services/mobile.service';
import { PromotionalContent } from '../../../../vendors/directus/interfaces/promotional-content.interface';
import { Router } from '@angular/router';
import { UtilityService } from '@modules/utility/services';

@Component({
  selector: 'app-promotional-content',
  templateUrl: './promotional-content.component.html',
  styleUrls: ['promotional-content.component.scss'],
})
export class PromotionalContentComponent implements OnInit, OnDestroy {
  @Select(state => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;

  @Input() promoSection: 'cart' | 'upsell' | 'confirm' | 'category' | 'menu' | 'giftcard';

  promotionalImage: SafeUrl;
  promotionalImageMobile: SafeUrl;
  promotionalText: SafeHtml;

  private subs: Subscription[] = [];

  constructor(
    public sanitizer: DomSanitizer,
    public mobileService: MobileService,
    private router: Router,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.promotionalContent$.pipe(filter(pc => !!pc)).subscribe(pages => {
        if (pages) {
          switch (this.promoSection) {
            case 'cart':
              if (pages.cart_promotion_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.cart_promotion_background?.data.full_url);
              }
              if (pages.cart_promotion_background_mobile?.data.full_url) {
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(pages.cart_promotion_background_mobile?.data.full_url);
              }
              if (pages.cart_promotion_text) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.cart_promotion_text);
              }
              break;

            case 'upsell':
              if (pages.upsell_promotion_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.upsell_promotion_background?.data.full_url);
              }
              if (pages.upsell_promotion_background_mobile?.data.full_url) {
                // tslint:disable-next-line:max-line-length
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(
                  pages.upsell_promotion_background_mobile?.data.full_url
                );
              }
              if (pages.upsell_promotion_text) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.upsell_promotion_text);
              }
              break;
            case 'confirm':
              if (pages.confirm_promotion_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.confirm_promotion_background?.data.full_url);
              }
              if (pages.confirm_promotion_background_mobile?.data.full_url) {
                // tslint:disable-next-line:max-line-length
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(
                  pages.confirm_promotion_background_mobile?.data.full_url
                );
              }
              if (pages.confirm_promotion_text) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.confirm_promotion_text);
              }
              break;
            case 'category':
              if (pages.category_card_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.category_card_background?.data.full_url);
              }
              if (pages.category_card_background_mobile?.data.full_url) {
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(pages.category_card_background_mobile?.data.full_url);
              }
              if (pages.category_card_promotion) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.category_card_promotion);
              }
              break;
            case 'menu':
              if (pages.menu_promotion_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.menu_promotion_background?.data.full_url);
              }
              if (pages.menu_promotion_background_mobile?.data.full_url) {
                // tslint:disable-next-line:max-line-length
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(pages.menu_promotion_background_mobile?.data.full_url);
              }
              if (pages.menu_promotion_text) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.menu_promotion_text);
              }
              break;
            case 'giftcard':
              if (pages.giftcard_promotion_background?.data.full_url) {
                this.promotionalImage = this.sanitizer.bypassSecurityTrustUrl(pages.giftcard_promotion_background?.data.full_url);
              }
              if (pages.giftcard_promotion_background_mobile?.data.full_url) {
                // tslint:disable-next-line:max-line-length
                this.promotionalImageMobile = this.sanitizer.bypassSecurityTrustUrl(
                  pages.giftcard_promotion_background_mobile?.data.full_url
                );
              }
              if (pages.giftcard_promotion_text) {
                this.promotionalText = this.sanitizer.sanitize(SecurityContext.HTML, pages.giftcard_promotion_text);
              }
              break;
            default:
              this.promotionalText = this.sanitizer.bypassSecurityTrustHtml('');
              break;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }

  onAnchorClick(event: Event) {
    let target = event.target as HTMLElement;
    while (target && target !== event.currentTarget) {
      if (target.tagName.toLowerCase() === 'a') {
        const href = target.getAttribute('href');

        if (href) {
          event.preventDefault();
          this.utilityService.handleDynamicLink(href);
        }
        break;
      }
      target = target.parentElement;
    }
  }
}
