import { User } from '../../interfaces/user.interface';
import { SavedCard } from '../../interfaces/saved-card.interface';
import { HistoryEvent } from '../../interfaces/history-event.interface';
import { Reward } from '../../interfaces/reward.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/vendor-config-service/user.service';
import { OrderService } from '../../services/vendor-config-service/order.service';
import { LoyaltyService } from '../../services/vendor-config-service/loyalty.service';
import {
  ConnectWithApple,
  ConnectWithFacebook,
  CreateAccount,
  CreateQuickCode,
  DeleteAccount,
  DeleteMessage,
  DeleteSavedCard,
  InitializeUser,
  LogOut,
  MarkMessageAsRead,
  PurchaseReward,
  RedeemInStoreReward,
  RedeemPointsFromScanner,
  RedeemRewardPoints,
  RegisterForPush,
  ReturnRewardPoints,
  SaveCardAsDefault,
  SendPasswordChangeRequest,
  SendPasswordResetCode,
  SendPasswordResetRequest,
  SendReferrals,
  SetAdditionalUserFields,
  SetAllowLoyaltyTransfer,
  SetApplePassbookURL,
  SetGoogleWalletData,
  SetGoogleWalletSaved,
  SetInboxMessages,
  SetLoyaltyActivity,
  SetLoyaltyLocations,
  SetLoyaltyRewards,
  SetOffers,
  SetPastOrders,
  SetPurchaseableRewards,
  SetReferrals,
  SetRewards,
  SetRewardsBalances,
  SetSavedCards,
  SetThirdPartyWrapped,
  SetupCateringLink,
  SignIn,
  SignInWithApple,
  SignInWithFacebook,
  SignInWithToken,
  TransferStoredValueToLoyaltyAccount,
  UpdateAppliedRewards,
  UpdateProfileInfo,
  UserCheckIn,
} from '../actions/user.actions';
import { catchError, delay, filter, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { from, iif, of, throwError } from 'rxjs';
import { Order } from '../../interfaces/order.interface';
import { produce } from 'immer';
import { Offer } from '../../interfaces/offer.interface';
import { OLOProviderService } from '../../vendors/olo/olo-provider.service';
import { OrderHistoryService } from '../../services/vendor-config-service/order-history.service';
import { ClearOrder, InitializeOrder, StageReward } from '../actions/order.actions';
import { PunchhOLOProviderService } from '../../vendors/punchh-olo/punchh-olo-provider.service';
import { PunchhProviderService } from '../../vendors/punchh/punchh-provider.service';
import { CateringLink } from '../../interfaces/catering-link.interface';
import { PaytronixProviderService } from '../../vendors/paytronix/paytronix-provider.service';
import { PersonicaProviderService } from '../../vendors/personica/personica-provider.service';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { MessagingService } from '../../services/vendor-config-service/messaging.service';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { LoyaltyProvider } from '../../providers/loyalty-provider.interface';
import { PurchaseableReward } from '../../interfaces/purchaseable-reward.interface';
import { DineEngineError } from '../../interfaces/dineengine-error.interface';
import { ContentService } from '../../services/vendor-config-service/content-provider.service';
import { ToastService } from '../../services/toast.service';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { Preferences as Storage } from '@capacitor/preferences';
import moment from 'moment-timezone';
import { GlobalStateModel } from '../state.model';
import { CapacitorIntegrationService, SentryService, TokenManagerService } from '@common/services';
import { Device, DeviceId } from '@capacitor/device';
import { AuthService } from '@modules/auth/services';
import { NovaDineProviderService } from '../../vendors/novadine/novadine-provider.service';
import { LoyaltyLocation } from '../../interfaces/location.interface';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { UserField } from '../../interfaces/user-field';
import { CustomUserField } from '../../vendors/directus/interfaces/main-settings.interface';
import { GooglePass } from '../../interfaces/google-pass';
import { Referral } from '../../interfaces/referral.interface';
import { Dialog } from '@capacitor/dialog';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

export interface UserStateModel {
  user: User;
  savedCards: SavedCard[];
  activity: HistoryEvent[];
  rewards: Reward[];
  rewardPoints: RewardsBalances;
  purchaseableRewards: PurchaseableReward[];
  offers: Offer[];
  pastOrders: Order[];
  inStoreReward: Reward;
  thirdPartyWrapped: boolean;
  cateringLink: CateringLink;
  checkInCode: CheckInCode;
  quickCode: CheckInCode;
  loyaltyLocations: LoyaltyLocation[];
  allowsTransferToLoyalty: boolean;
  loyaltyRewards: LoyaltyReward[];
  inboxMessages: InboxMessage[];
  additionalUserFields: UserField[];
  applePassbookURL: string;
  googleWalletData: GooglePass;
  referrals: Referral[];
  referralsSupported: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    savedCards: null,
    activity: null,
    rewards: null,
    rewardPoints: null,
    purchaseableRewards: null,
    offers: null,
    pastOrders: null,
    inStoreReward: null,
    thirdPartyWrapped: null,
    cateringLink: null,
    checkInCode: null,
    quickCode: null,
    loyaltyLocations: null,
    allowsTransferToLoyalty: false,
    loyaltyRewards: null,
    inboxMessages: null,
    additionalUserFields: [],
    applePassbookURL: null,
    googleWalletData: null,
    referrals: [],
    referralsSupported: false,
  },
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(
    private userService: UserService,
    private orderService: OrderService,
    private loyaltyService: LoyaltyService,
    private contentService: ContentService,
    private orderHistoryService: OrderHistoryService,
    private store: Store,
    private oneSignal: OneSignal,
    private messagingService: MessagingService,
    private analytics: AnalyticsService,
    private toast: ToastService,
    private capacitorIntegration: CapacitorIntegrationService,
    private tokenManager: TokenManagerService,
    private authService: AuthService,
    private sentry: SentryService
  ) {}

  async ngxsOnInit(ctx: StateContext<UserStateModel>) {
    if (Capacitor.getPlatform() === 'web') {
      return ctx.dispatch([new InitializeUser(), new SetAdditionalUserFields()]);
    }
  }

  @Action(InitializeUser)
  initializeUser(ctx: StateContext<UserStateModel>, action: InitializeUser) {
    return from(Device.getId()).pipe(
      switchMap((deviceID: DeviceId) => {
        return this.userService.getService().pipe(
          switchMap(uService => {
            if (uService) {
              return uService.getLoggedInUserInfo().pipe(
                switchMap(info => {
                  if (info) {
                    if (!info.isGuest) {
                      this.oneSignal.sendTags({
                        first_name: info.firstName,
                        last_name: info.lastName,
                        email: info.email,
                        'email_opt-in': info.emailOptIn,
                        phone: info.phoneNumber,
                        'sms_opt-in': info.sMSOptIn,
                      });
                      this.capacitorIntegration.setRadarUserID(info.userID);
                      this.sentry.setEmail(info.email);
                    } else {
                      this.capacitorIntegration.setRadarUserID(deviceID.identifier);
                    }
                    ctx.dispatch(new InitializeOrder(info.userID));
                    ctx.dispatch(new SetPastOrders());
                    return this.contentService.getService().pipe(
                      switchMap(cService => {
                        return cService.getLocations().pipe(
                          switchMap(cLocations => {
                            return this.loyaltyService.getService().pipe(
                              switchMap(lService => {
                                if (lService && !info.isGuest) {
                                  this.authService.refreshSSO().subscribe();
                                  ctx.dispatch([
                                    new SetLoyaltyActivity(),
                                    new SetRewardsBalances(info.userID),
                                    new SetLoyaltyRewards(),
                                    new SetInboxMessages(),
                                    new SetOffers(info.userID),
                                    new SetSavedCards(info.userID, true),
                                    new SetAllowLoyaltyTransfer(),
                                    new SetReferrals(),
                                  ]);
                                  // const menuLocation = cLocations.find(l => l.is_static_menu);
                                  // if (menuLocation) {
                                  //   if (lService instanceof PersonicaProviderService) {
                                  //     ctx.dispatch(new SetRewards(info.userID, null));
                                  //   } else {
                                  //     ctx.dispatch(new SetRewards(info.userID, menuLocation.menu_id));
                                  //   }
                                  // }
                                }
                                return this.messagingService.getService().pipe(
                                  map(mService => {
                                    if (mService) {
                                      ctx.dispatch(new RegisterForPush());
                                    }
                                    Storage.set({
                                      key: 'seenOnboarding',
                                      value: JSON.stringify(true),
                                    });
                                    this.analytics.analyticsOnLogin(info);
                                    return ctx.patchState({
                                      user: info,
                                    });
                                  })
                                );
                              })
                            );
                          })
                        );
                      })
                    );
                  } else {
                    return uService.logInAsGuest().pipe(
                      switchMap(userID => {
                        ctx.dispatch([new InitializeOrder(userID), new RegisterForPush()]);
                        return this.tokenManager.clearProviderTokens().pipe(
                          map(() => {
                            return ctx.patchState({
                              user: {
                                userID,
                                isGuest: true,
                              } as User,
                              savedCards: null,
                              activity: null,
                              rewards: null,
                              rewardPoints: null,
                              offers: null,
                              pastOrders: null,
                              inStoreReward: null,
                              checkInCode: null,
                            });
                          })
                        );
                      })
                    );
                  }
                }),
                catchError(err => {
                  return this.tokenManager.clearProviderTokens().pipe(
                    map(() => {
                      location.reload();
                    })
                  );
                })
              );
            } else {
              return this.tokenManager.clearProviderTokens().pipe(
                map(() => {
                  return ctx.patchState({
                    user: {
                      userID: null,
                      isGuest: true,
                    } as User,
                    savedCards: null,
                    activity: null,
                    rewards: null,
                    rewardPoints: null,
                    offers: null,
                    pastOrders: null,
                    inStoreReward: null,
                    checkInCode: null,
                  });
                })
              );
            }
          })
        );
      })
    );
  }

  @Action(SetAdditionalUserFields)
  setAdditionalUserFields(ctx: StateContext<UserStateModel>, action: SetAdditionalUserFields) {
    // get custom user fields from main settings and map them to user fields
    return this.store
      .select((state: GlobalStateModel) => state.app.mainSettings)
      .pipe(
        filter(mainSettings => mainSettings !== null),
        take(1),
        map(mainSettings => {
          if (mainSettings.custom_user_fields) {
            const userFields: UserField[] = [];
            mainSettings.custom_user_fields.forEach((field: CustomUserField) => {
              userFields.push({
                providerFieldName: field['Provider Field Name'],
                displayName: field['Display Name'],
                inputType: field['Input Type'],
                inputMode: field['Input Mode'],
                required: typeof field['Required'] === 'boolean' ? field['Required'] : !!Number(field['Required']),
                forceFullWidth:
                  typeof field['Force Full Width'] === 'boolean' ? field['Force Full Width'] : !!Number(field['Force Full Width']),
                maxLength: field['Max Length'],
                minLength: field['Min Length'],
              });
            });
            return ctx.patchState({
              additionalUserFields: userFields,
            });
          }
        })
      );
  }

  @Action(SetRewards)
  setRewards(ctx: StateContext<UserStateModel>, action: SetRewards) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return this.orderService.getService().pipe(
          switchMap(oService => {
            return this.store
              .select(state => state.order.order)
              .pipe(
                take(1),
                switchMap((order: Order) => {
                  return this.store
                    .select(state => state.user.rewards)
                    .pipe(
                      take(1),
                      switchMap(() => {
                        if (lService) {
                          if (oService instanceof OLOProviderService && lService instanceof PaytronixProviderService) {
                            return oService.oloAuthToken$.pipe(
                              filter(token => token !== null),
                              take(1),
                              switchMap(token => {
                                return lService.getRewards(token, action.locationID).pipe(
                                  switchMap(rewards => {
                                    if (lService.getAvailableLoyaltyRewards) {
                                      return lService.getAvailableLoyaltyRewards(ctx.getState().user?.userID).pipe(
                                        map(lRewards => {
                                          return this.updateRewards(order, ctx, this.mapRewardImages(rewards, lRewards));
                                        })
                                      );
                                    } else {
                                      return this.updateRewards(order, ctx, rewards);
                                    }
                                  })
                                );
                              })
                            );
                          } else {
                            return lService.getRewards(action.userID, action.locationID).pipe(
                              map(rewards => {
                                return this.updateRewards(order, ctx, rewards);
                              })
                            );
                          }
                        } else {
                          return of<void>(undefined);
                        }
                      })
                    );
                })
              );
          })
        );
      })
    );
  }

  mapRewardImages(rewards: Reward[], loyaltyRewards: LoyaltyReward[]) {
    const mappedRewards: Reward[] = [];
    loyaltyRewards.forEach((loyaltyReward: LoyaltyReward) => {
      rewards.forEach((reward: Reward) => {
        // find matching reward and populate with reward data
        if (
          String(reward.externalRef) === String(loyaltyReward.externalReferenceID) ||
          String(reward.name) === String(loyaltyReward.name)
        ) {
          mappedRewards.push({ ...reward, ...loyaltyReward });
        }
      });
    });
    return mappedRewards;
    // rewards.forEach((reward: Reward) => {
    //   loyaltyRewards.forEach((loyaltyReward: LoyaltyReward) => {
    //     if (
    //       String(reward.externalRef) ===
    //         String(loyaltyReward.externalReferenceID) ||
    //       String(reward.name) === String(loyaltyReward.name)
    //     ) {
    //       reward.imageURL = loyaltyReward.imageURL;
    //     }
    //   });
    // });
    // return rewards;
  }

  updateRewards(order: Order, ctx: StateContext<any>, rewards: Reward[]) {
    if (order && order.appliedRewards && order.appliedRewards.length > 0) {
      ctx.dispatch(new UpdateAppliedRewards(order.appliedRewards[0]));
    }
    if (sessionStorage.getItem('reward')) {
      const reward = rewards.find(r => r.externalRef === sessionStorage.getItem('reward'));
      if (reward) {
        ctx.dispatch(new StageReward(reward));
        sessionStorage.removeItem('reward');
      }
    }
    return ctx.patchState({
      rewards,
    });
  }

  @Action(SetLoyaltyActivity)
  setLoyaltyActivity(ctx: StateContext<UserStateModel>, action: SetLoyaltyActivity) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.getLoyaltyActivity().pipe(
          map(activity => {
            const hideTheseActivityTypes = ['Denied Accrual / Redemption', 'Register', 'Activate', 'Denied Transfer Balances To Card'];
            let filteredActivity = [];
            filteredActivity = activity.filter(event => {
              let keepEvent = true;
              hideTheseActivityTypes.forEach((type: string) => {
                if (keepEvent) {
                  keepEvent = event.type !== type;
                }
              });
              return keepEvent;
            });
            return ctx.patchState({
              activity: filteredActivity,
            });
          })
        );
      })
    );
  }

  @Action(SetRewardsBalances)
  setRewardsBalances(ctx: StateContext<UserStateModel>, action: SetRewardsBalances) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.getPointsBalance(action.userID).pipe(
            map(points => {
              return ctx.patchState({
                rewardPoints: points,
              });
            })
          );
        }
      })
    );
  }

  @Action(SetOffers)
  setOffers(ctx: StateContext<UserStateModel>, action: SetOffers) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.getOffers(action.userID).pipe(
          map(offers => {
            return ctx.patchState({
              offers,
            });
          })
        );
      })
    );
  }

  @Action(SetSavedCards)
  setSavedCards(ctx: StateContext<UserStateModel>, action: SetSavedCards) {
    return this.orderHistoryService.getService().pipe(
      switchMap(ohService => {
        if (ohService instanceof OLOProviderService) {
          return this.userService.getService().pipe(
            switchMap(uService => {
              if (
                !(
                  uService instanceof OLOProviderService ||
                  uService instanceof PunchhOLOProviderService ||
                  uService instanceof NovaDineProviderService
                )
              ) {
                // tslint:disable-next-line:max-line-length
                return ohService.oloAuthToken$.pipe(
                  filter(t => t !== null),
                  take(1),
                  mergeMap(token =>
                    iif(
                      () => !token,
                      this.authService.refreshSSO().pipe(
                        delay(1000),
                        switchMap(() => ohService.oloAuthToken$)
                      ),
                      ohService.oloAuthToken$
                    )
                  ),
                  filter(t => t !== null),
                  take(1),
                  switchMap(token => {
                    return ohService.getSavedCards(token, action.isAccountPage).pipe(
                      map(cards => {
                        return ctx.patchState({
                          savedCards: this.dedupeCards(cards),
                        });
                      })
                    );
                  })
                );
              } else {
                return ohService.getSavedCards(action.userID, action.isAccountPage).pipe(
                  map(cards => {
                    return ctx.patchState({
                      savedCards: this.dedupeCards(cards),
                    });
                  })
                );
              }
            })
          );
        } else {
          if (ohService) {
            return ohService.getSavedCards(action.userID, action.isAccountPage).pipe(
              map(cards => {
                return ctx.patchState({
                  savedCards: this.dedupeCards(cards),
                });
              })
            );
          }
        }
      })
    );
  }

  @Action(SetPastOrders)
  setPastOrders(ctx: StateContext<UserStateModel>, action: SetPastOrders) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderHistoryService.getService().pipe(
            switchMap(ohService => {
              if (ohService instanceof OLOProviderService) {
                return this.userService.getService().pipe(
                  switchMap(uService => {
                    if (
                      !(
                        uService instanceof OLOProviderService ||
                        uService instanceof PunchhOLOProviderService ||
                        uService instanceof NovaDineProviderService
                      )
                    ) {
                      return ohService.oloAuthToken$.pipe(
                        filter(t => t !== null),
                        take(1),
                        switchMap(token => {
                          return ohService.getOrderHistory(token).pipe(
                            map(orders => {
                              return ctx.patchState({
                                pastOrders: orders?.filter(o => o.items.length > 0),
                              });
                            })
                          );
                        })
                      );
                    } else {
                      return ohService.getOrderHistory(user.userID).pipe(
                        map(orders => {
                          return ctx.patchState({
                            pastOrders: orders.filter(o => o.items.length > 0),
                          });
                        })
                      );
                    }
                  })
                );
              } else {
                return ohService.getOrderHistory(user.userID).pipe(
                  map(orders => {
                    return ctx.patchState({
                      pastOrders: orders.filter(o => o.items.length > 0),
                    });
                  })
                );
              }
            })
          );
        })
      );
  }

  @Action(CreateAccount)
  createAccount(ctx: StateContext<UserStateModel>, action: CreateAccount) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.createAccount(action.newAccount, action.additionalFields).pipe(
          map(user => {
            ctx.dispatch(new InitializeUser());
            const options = {
              name: 'sign_up',
              params: {
                method: 'email',
              },
            };
            this.analytics.firebaseGenericLog(options);
            return ctx.patchState({
              user,
            });
          })
        );
      })
    );
  }

  @Action(SignIn)
  signIn(ctx: StateContext<UserStateModel>, action: SignIn) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.logIn(action.email, action.password).pipe(
          switchMap(userID => {
            return uService.getUserInfo(userID).pipe(
              switchMap(user => {
                const options = {
                  name: 'login',
                  params: {
                    method: 'email',
                  },
                };
                this.analytics.firebaseGenericLog(options);

                // Patch state first
                ctx.patchState({ user });

                // Dispatch the InitializeUser action
                ctx.dispatch(new InitializeUser());

                if (Capacitor.getPlatform() === 'ios') {
                  return from(
                    SavePassword.promptDialog({
                      username: action.email,
                      password: action.password,
                    })
                  );
                }

                return of(null);
              })
            );
          })
        );
      })
    );
  }

  @Action(SignInWithToken)
  signInWithToken(ctx: StateContext<UserStateModel>, action: SignInWithToken) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.logInWithToken(action.token, action.redirectURL).pipe(
          switchMap(userID => {
            return uService.getUserInfo(userID).pipe(
              map(user => {
                const options = {
                  name: 'login',
                  params: {
                    method: '3rdPartyWrapped',
                  },
                };
                this.analytics.firebaseGenericLog(options);
                ctx.dispatch(new SetThirdPartyWrapped(true));
                ctx.dispatch(new InitializeUser());
                return ctx.patchState({
                  user,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(SignInWithFacebook)
  signInWithFacebook(ctx: StateContext<UserStateModel>, action: SignInWithFacebook) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.logInWithFacebook(action.email, action.accessToken, action.userID).pipe(
          switchMap(userID => {
            return uService.getUserInfo(userID).pipe(
              map(user => {
                const options = {
                  name: 'login',
                  params: {
                    method: 'Facebook',
                  },
                };
                this.analytics.firebaseGenericLog(options);
                ctx.dispatch(new InitializeUser());
                return ctx.patchState({
                  user,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(ConnectWithFacebook)
  connectWithFacebook(ctx: StateContext<UserStateModel>, action: ConnectWithFacebook) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.connectWithFacebook(action.email, action.accessToken, action.userID).pipe(
          switchMap(() => {
            return ctx.dispatch(new InitializeUser());
          })
        );
      })
    );
  }

  @Action(SignInWithApple)
  signInWithApple(ctx: StateContext<UserStateModel>, action: SignInWithApple) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.logInWithApple(action.appleResponse, action.redirectURI).pipe(
          switchMap(userID => {
            return uService.getUserInfo(userID).pipe(
              map(user => {
                const options = {
                  name: 'login',
                  params: {
                    method: 'Apple',
                  },
                };
                this.analytics.firebaseGenericLog(options);
                ctx.dispatch(new InitializeUser());
                return ctx.patchState({
                  user,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(ConnectWithApple)
  connectWithApple(ctx: StateContext<UserStateModel>, action: ConnectWithApple) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.connectWithApple(action.appleResponse, action.redirectURI).pipe(
          switchMap(() => {
            return ctx.dispatch(new InitializeUser());
          })
        );
      })
    );
  }

  @Action(UpdateProfileInfo)
  updateProfileInfo(ctx: StateContext<UserStateModel>, action: UpdateProfileInfo) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.updateUserInfo(action.user, action.additionalFields).pipe(
          map(user => {
            return ctx.patchState({
              user,
            });
          })
        );
      })
    );
  }

  @Action(LogOut)
  logOut(ctx: StateContext<UserStateModel>, action: LogOut) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.userService.getService().pipe(
            switchMap(uService => {
              return uService.logOut(user.userID).pipe(
                switchMap(() => {
                  ctx.dispatch(new ClearOrder());
                  return this.tokenManager.clearProviderTokens().pipe(
                    switchMap(() => {
                      return ctx.dispatch(new InitializeUser()).pipe(
                        map(() => {
                          if (Capacitor.getPlatform() !== 'web') {
                            try {
                              SecureStoragePlugin.remove({ key: 'auth.username' });
                              SecureStoragePlugin.remove({ key: 'auth.password' });
                              SecureStoragePlugin.remove({ key: 'auth.biometrics' });
                              SecureStoragePlugin.remove({ key: 'auth.remember' });
                            } catch (e) {
                              console.error(e);
                            }
                          }
                          Storage.remove({ key: 'seenOnboarding' });
                          return ctx.patchState({
                            savedCards: null,
                            activity: null,
                            rewards: null,
                            rewardPoints: null,
                            offers: null,
                            pastOrders: null,
                            inStoreReward: null,
                          });
                        })
                      );
                    })
                  );
                })
              );
            })
          );
        })
      );
  }

  @Action(RedeemInStoreReward)
  redeemInStoreReward(ctx: StateContext<UserStateModel>, action: RedeemInStoreReward) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService instanceof PunchhOLOProviderService || lService instanceof PunchhProviderService) {
          return ctx.dispatch(new SetLoyaltyActivity()).pipe(
            switchMap(newState => {
              // tslint:disable-next-line:max-line-length
              return this.store
                .select(state => state.user.activity)
                .pipe(
                  filter(u => u !== null),
                  take(1),
                  switchMap((activity: HistoryEvent[]) => {
                    const matchedRedemption = activity.find(
                      record => record.type === 'Redemption' && record.externalID && record.externalID.toString() === action.reward.rewardID
                    );
                    if (matchedRedemption) {
                      const newReward: Reward = {
                        ...action.reward,
                        redemptionInfo: matchedRedemption.redemptionInfo,
                      };
                      return of(
                        ctx.patchState({
                          inStoreReward: newReward,
                        })
                      );
                    } else {
                      return lService.redeemInStoreReward(action.reward).pipe(
                        map(reward => {
                          return ctx.patchState({
                            inStoreReward: reward,
                          });
                        })
                      );
                    }
                  })
                );
            })
          );
        } else {
          return of(
            ctx.patchState({
              inStoreReward: action.reward,
            })
          );
        }
      })
    );
  }

  @Action(RedeemRewardPoints)
  redeemRewardPoints(ctx: StateContext<UserStateModel>, action: RedeemRewardPoints) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.redeemBankedPoints(action.points).pipe(
          switchMap(() => {
            return ctx.dispatch(new SetLoyaltyActivity());
          })
        );
      })
    );
  }

  @Action(ReturnRewardPoints)
  returnRewardPoints(ctx: StateContext<UserStateModel>, action: ReturnRewardPoints) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.voidBankedPoints(action.reward).pipe(
          switchMap(() => {
            return ctx.dispatch(new SetLoyaltyActivity());
          })
        );
      })
    );
  }

  @Action(RedeemPointsFromScanner)
  redeemPointsFromScanner(ctx: StateContext<UserStateModel>, action: RedeemPointsFromScanner) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.redeemPointsFromScanner(action.barcode).pipe(
          switchMap(() => {
            return this.store
              .select(state => state.user.user)
              .pipe(
                filter(u => u !== null),
                take(1),
                switchMap((user: User) => {
                  return ctx.dispatch(new SetRewardsBalances(user.userID));
                })
              );
          })
        );
      })
    );
  }

  @Action(DeleteSavedCard)
  deleteSavedCard(ctx: StateContext<UserStateModel>, action: DeleteSavedCard) {
    return this.orderHistoryService.getService().pipe(
      switchMap(ohService => {
        return ohService.getSavedCards(ctx.getState().user.userID, false).pipe(
          switchMap(cards => {
            // tslint:disable-next-line:max-line-length
            return from(
              cards.filter(
                card =>
                  card.lastFourDigits === action.card.lastFourDigits &&
                  (action.card.cardExpiration && action.card.cardExpiration instanceof Date && !isNaN(action.card.cardExpiration.getTime())
                    ? moment(card.cardExpiration).isSame(moment(action.card.cardExpiration), 'month')
                    : true)
              )
            )
              .pipe(
                mergeMap(card => {
                  return ohService.removeCard(card);
                })
              )
              .pipe(finalize(() => ctx.dispatch(new SetSavedCards(ctx.getState().user.userID, true))));
          })
        );
      })
    );
  }

  @Action(SaveCardAsDefault)
  saveCardAsDefault(ctx: StateContext<UserStateModel>, action: DeleteSavedCard) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderHistoryService.getService().pipe(
            switchMap(ohService => {
              return ohService.saveCardAsDefault(action.card).pipe(
                switchMap(() => {
                  return ctx.dispatch(new SetSavedCards(user.userID, true));
                })
              );
            })
          );
        })
      );
  }

  @Action(UpdateAppliedRewards)
  updateAppliedRewards(ctx: StateContext<UserStateModel>, action: UpdateAppliedRewards) {
    return this.store
      .select((state: GlobalStateModel) => state.user.rewards)
      .pipe(
        filter(r => r !== null),
        take(1),
        map(rewards => {
          const order: Order = this.store.selectSnapshot(state => state.order.order);
          if (order && order.appliedRewards && order.appliedRewards.length > 0) {
            const newRewards = produce(rewards, draft => {
              // Reset all rewards to not applied and clear rewardID
              draft.forEach(r => {
                r.isApplied = false;
                r.rewardID = undefined; // Clear existing rewardID
              });

              draft.forEach(r => {
                // Find if any of the appliedRewards match the reward's externalRef
                const matchedAppliedReward = order.appliedRewards.find(appliedReward => appliedReward.externalRef === r.externalRef);

                // Check if the appliedCouponCode matches the reward's redemption code
                const couponMatchesRedemption = order.appliedCouponCode && order.appliedCouponCode === r.redemptionInfo?.redemptionCode;

                // Set isApplied and rewardID if there's a match
                if (matchedAppliedReward || couponMatchesRedemption) {
                  r.isApplied = true;

                  if (matchedAppliedReward) {
                    r.rewardID = matchedAppliedReward.rewardID;
                  }
                }
              });
            });

            return ctx.patchState({
              rewards: newRewards,
            });
          } else {
            // If no rewards are applied, set isApplied to false for all rewards
            const noRewardsApplied = produce(rewards, draft => {
              draft.forEach(reward => (reward.isApplied = false));
            });

            return ctx.patchState({
              rewards: noRewardsApplied,
            });
          }
        })
      );
  }

  @Action(SendPasswordChangeRequest)
  sendPasswordChangeRequest(ctx: StateContext<UserStateModel>, action: SendPasswordChangeRequest) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.forgotPassword(action.email).pipe(
          map(() => {
            return ctx.getState();
          })
        );
      })
    );
  }

  @Action(SendPasswordResetRequest)
  sendPasswordResetRequest(ctx: StateContext<UserStateModel>, action: SendPasswordResetRequest) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.resetPassword(action.password);
      })
    );
  }

  @Action(SendPasswordResetCode)
  sendPasswordResetCode(ctx: StateContext<UserStateModel>, action: SendPasswordResetCode) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.getResetPasswordCode(action.email);
      })
    );
  }

  @Action(SetupCateringLink)
  setupCateringLink(ctx: StateContext<UserStateModel>, action: SetupCateringLink) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return uService.cateringLink().pipe(
          map(link => {
            return ctx.patchState({
              cateringLink: link,
            });
          })
        );
      })
    );
  }

  @Action(UserCheckIn)
  userCheckIn(ctx: StateContext<UserStateModel>, action: UserCheckIn) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.checkInAtStore().pipe(
          map(code => {
            return ctx.patchState({
              checkInCode: code,
            });
          })
        );
      })
    );
  }

  @Action(CreateQuickCode)
  createQuickCode(ctx: StateContext<UserStateModel>, action: CreateQuickCode) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.checkInAtStore().pipe(
          map(code => {
            return ctx.patchState({
              quickCode: code,
            });
          })
        );
      })
    );
  }

  @Action(RegisterForPush)
  registerForPush(ctx: StateContext<UserStateModel>, action: RegisterForPush) {
    return this.messagingService.getService().pipe(
      switchMap(mService => {
        if (mService) {
          return mService.registerApp();
        }
      })
    );
  }

  @Action(SetThirdPartyWrapped)
  setThirdPartyWrapped(ctx: StateContext<UserStateModel>, action: SetThirdPartyWrapped) {
    return ctx.patchState({
      thirdPartyWrapped: action.isThirdPartyWrapped,
    });
  }

  @Action(SetLoyaltyLocations)
  setLoyaltyLocations(ctx: StateContext<UserStateModel>, action: SetLoyaltyLocations) {
    return this.loyaltyService.getService().pipe(
      switchMap((lService: LoyaltyProvider) => {
        if (lService) {
          return lService.getLoyaltyLocations().pipe(
            map(locations => {
              // Use Immer's produce to create a sorted copy
              const sortedLocations = produce(locations, draft => {
                draft.sort((a, b) => (a.name > b.name ? 1 : -1));
              });

              // Patch the state with the sorted locations
              ctx.patchState({
                loyaltyLocations: sortedLocations,
              });
            })
          );
        } else {
          // Optionally handle the case where lService is undefined or null
          return of(ctx.getState());
        }
      })
    );
  }

  @Action(SetPurchaseableRewards)
  setPurchaseableRewards(ctx: StateContext<UserStateModel>, action: SetPurchaseableRewards) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.getPurchaseableRewards().pipe(
            map(pRewards => {
              return ctx.patchState({
                purchaseableRewards: pRewards,
              });
            })
          );
        }
      })
    );
  }

  @Action(PurchaseReward)
  purchaseReward(ctx: StateContext<UserStateModel>, action: PurchaseReward) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.purchaseReward(action.reward, action.variation).pipe(
            switchMap(result => {
              ctx.dispatch(new SetLoyaltyRewards());
              if (result) {
                return of(null);
              } else {
                return throwError(Error('Purchase Unsuccessful'));
              }
            })
          );
        }
      })
    );
  }

  @Action(SetAllowLoyaltyTransfer)
  setAllowLoyaltyTransfer(ctx: StateContext<UserStateModel>, action: SetAllowLoyaltyTransfer) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.canTransferGiftCardToAccount().pipe(
            map(result => {
              return ctx.patchState({
                allowsTransferToLoyalty: result,
              });
            })
          );
        }
      })
    );
  }

  @Action(TransferStoredValueToLoyaltyAccount)
  transferStoredValueToLoyaltyAccount(ctx: StateContext<UserStateModel>, action: TransferStoredValueToLoyaltyAccount) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.transferGiftCardBalanceToAccount(action.from).pipe(
            switchMap(result => {
              if (result.success) {
                return this.store
                  .select(state => state.user.user)
                  .pipe(
                    filter(u => u !== null),
                    take(1)
                  )
                  .pipe(
                    switchMap((user: User) => {
                      return ctx.dispatch(new SetRewardsBalances(user.userID));
                    })
                  );
              } else {
                const error: DineEngineError = {
                  name: 'Transfer Error',
                  message: 'Transfer Unsuccessful',
                };
                return throwError(error);
              }
            })
          );
        }
      })
    );
  }

  @Action(DeleteAccount)
  deleteAccount(ctx: StateContext<UserStateModel>, action: DeleteAccount) {
    return this.userService.getService().pipe(
      switchMap(uService => {
        return this.store
          .selectOnce(state => state.user.user)
          .pipe(
            switchMap((user: User) => {
              return uService.deleteAccount(user.userID).pipe(
                switchMap(res => {
                  ctx.dispatch(new ClearOrder());
                  return ctx.dispatch(new LogOut()).pipe(
                    switchMap(() => {
                      return ctx.dispatch(new InitializeUser()).pipe(
                        map(() => {
                          return ctx.patchState({
                            savedCards: null,
                            activity: null,
                            rewards: null,
                            rewardPoints: null,
                            offers: null,
                            pastOrders: null,
                            inStoreReward: null,
                          });
                        })
                      );
                    })
                  );
                })
              );
            })
          );
      })
    );
  }

  @Action(SetLoyaltyRewards)
  setLoyaltyRewards(ctx: StateContext<UserStateModel>, action: SetLoyaltyRewards) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService && lService.getAvailableLoyaltyRewards) {
          return lService.getAvailableLoyaltyRewards(ctx.getState().user?.userID).pipe(
            map(lRewards => {
              return ctx.patchState({
                loyaltyRewards: lRewards,
              });
            })
          );
        }
      })
    );
  }

  @Action(SetInboxMessages)
  setInboxMessages(ctx: StateContext<UserStateModel>, action: SetInboxMessages) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService && lService.getMessages) {
          return lService.getMessages(ctx.getState().user?.userID).pipe(
            map(messages => {
              return ctx.patchState({
                inboxMessages: messages,
              });
            })
          );
        }
      })
    );
  }

  @Action(MarkMessageAsRead)
  markMessageAsRead(ctx: StateContext<UserStateModel>, action: MarkMessageAsRead) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.markMessageAsRead(ctx.getState().user?.userID, action.messageId).pipe(
            map(result => {
              return ctx.patchState({
                inboxMessages: result,
              });
            })
          );
        }
      })
    );
  }

  @Action(DeleteMessage)
  deleteMessage(ctx: StateContext<UserStateModel>, action: DeleteMessage) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.deleteMessage(ctx.getState().user?.userID, action.messageId).pipe(
            map(result => {
              return ctx.patchState({
                inboxMessages: result,
              });
            })
          );
        }
      })
    );
  }

  @Action(SetApplePassbookURL)
  setApplePassbookURL(ctx: StateContext<UserStateModel>, action: SetApplePassbookURL) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.getApplePassUrl(ctx.getState().user.userID).pipe(
            map(url => {
              return ctx.patchState({
                applePassbookURL: url,
              });
            })
          );
        }
      })
    );
  }

  @Action(SetGoogleWalletData)
  setGoogleWalletData(ctx: StateContext<UserStateModel>, action: SetGoogleWalletData) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.getGooglePassData(ctx.getState().user.userID).pipe(
            map(data => {
              return ctx.patchState({
                googleWalletData: data,
              });
            })
          );
        }
      })
    );
  }

  @Action(SetGoogleWalletSaved)
  setGoogleWalletSaved(ctx: StateContext<UserStateModel>, action: SetGoogleWalletSaved) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService) {
          return lService.markGooglePassAsSaved(ctx.getState().user.userID, action.objectCode);
        }
      })
    );
  }

  @Action(SetReferrals)
  setReferrals(ctx: StateContext<UserStateModel>, action: SetReferrals) {
    if (ctx.getState().user?.isGuest) {
      return;
    }
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService && lService.supportsReferrals()) {
          return lService.getReferrals(ctx.getState().user.userID).pipe(
            map(referrals => {
              return ctx.patchState({
                referrals,
                referralsSupported: true,
              });
            })
          );
        }
        return;
      })
    );
  }

  @Action(SendReferrals)
  sendReferrals(ctx: StateContext<UserStateModel>, action: SendReferrals) {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        if (lService && lService.supportsReferrals()) {
          return lService.getReferrals(ctx.getState().user.userID).pipe(
            switchMap(referrals => {
              const newReferrals = action.emails.filter(email => !referrals.find(r => r.email === email));
              if (newReferrals.length) {
                return lService.sendReferrals(ctx.getState().user.userID, newReferrals, action.message).pipe(
                  map(referrals => {
                    return ctx.patchState({
                      referrals,
                    });
                  })
                );
              }
              return of<void>(undefined);
            })
          );
        }
        return;
      })
    );
  }

  private dedupeCards(cards: SavedCard[]): SavedCard[] {
    const cardNames = [];
    const newCards = [];
    cards.forEach(card => {
      if (!cardNames.includes(card.type + card.lastFourDigits + card.cardExpiration.getMonth() + card.cardExpiration.getFullYear())) {
        cardNames.push(card.type + card.lastFourDigits + card.cardExpiration.getMonth() + card.cardExpiration.getFullYear());
        newCards.push(card);
      }
    });
    return newCards;
  }
}
