<ng-container *ngIf="order?.location.conceptLogoURL; else mainLogo">
  <img
    id="appNavbarHeader"
    class="logo"
    alt="logo"
    tabindex="0"
    (click)="onHomeClicked()"
    (keydown.enter)="onHomeClicked()"
    [class.m-3]="!fixedNavbarWidth"
    *ngIf="branding?.main_logo"
    [src]="order.location.conceptLogoURL" />
</ng-container>

<ng-template #mainLogo>
  <img
    id="appNavbarHeader"
    class="logo"
    alt="logo"
    tabindex="0"
    (click)="onHomeClicked()"
    (keydown.enter)="onHomeClicked()"
    [class.m-3]="!fixedNavbarWidth"
    *ngIf="branding?.main_logo"
    [src]="branding?.main_logo.data.full_url" />
</ng-template>
