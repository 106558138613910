import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-route-modal-frame',
  template: `<div id="backdrop" class="modal-backdrop fade" [ngClass]="{ show: showModal }"></div>
  <div class="modal d-block fade"
       [ngClass]="{ show: showModal }"
       (click)="onClose()"
       id="listing-dialog"
       tabindex="-1" role="dialog" aria-labelledby="modalIdLabel">
    <div class="modal-dialog" role="document" (click)="onDialogClick($event)">
      <div class="modal-content">
        <div class="modal-header p-0">
          <div class="mobile-modal-header-close">
            <div class="header-font header-title">{{ title }}</div>
            <button class="back-button" (click)="onClose()" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </div>
        </div>

        <div class="modal-body p-0">
          <ng-content></ng-content>
        </div>

<!--          <div class="modal-footer p-0">-->
<!--            <button type="button" class="btn btn-primary" (click)="onClose()">Close</button>-->
<!--          </div>-->
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./route-modal-frame.component.scss']
})
export class RouteModalFrameComponent implements AfterViewInit {
  @Input() title = 'Missing Modal Title';
  @Output() clickModalClose = new EventEmitter<any>();

  showModal = false;

  ngAfterViewInit() {
    this.showModal = true;
  }

  onClose() {
    this.showModal = false;
    this.clickModalClose.emit();
  }

  onDialogClick(event: UIEvent) {
    // Capture click on dialog and prevent it from bubbling to the modal background.
    event.stopPropagation();
    event.cancelBubble = true;
  }
}
