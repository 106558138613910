import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {
  CancelGroupOrder,
  GetCurrentGroupOrder,
  GetGroupOrder,
  JoinGroupOrder
} from '../../../../store/actions/order.actions';
import {combineLatest, Observable} from 'rxjs';
import {GroupOrder} from '../../../../interfaces/group-order.interface';
import {NavigationService} from '@modules/navigation/services';
import {User} from '../../../../interfaces/user.interface';
import {Location as URLLocation} from '@angular/common';
import {SubscriptionComponent} from '@common/components';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Order} from '../../../../interfaces/order.interface';
import {take} from 'rxjs/operators';
import {SetRouteBack, UpdateTitle} from '../../../../store/actions/app.actions';
import {ModeService} from '../../../../services/mode.service';

@Component({
  selector: 'app-join-group-order',
  templateUrl: './join-group-order.component.html',
  styleUrls: ['join-group-order.component.scss'],
})
export class JoinGroupOrderComponent extends SubscriptionComponent implements OnInit {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  title = 'join group order';
  groupOrderID: string;
  basketID: string;
  menuID;
  displayUser: User;
  guestName = '';
  loading = false;
  nameForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    public mode: ModeService,
    private route: ActivatedRoute,
    private store: Store,
    private navigation: NavigationService,
    private urlLocation: URLLocation
  ) {
    super();
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.subs.push(
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
        this.guestName = '';
        if (!this.displayUser || this.displayUser?.isGuest) {
          this.guestName = this.name.value;
        } else {
          this.guestName = this.displayUser.firstName + ' ' + this.displayUser.lastName;
          this.name.clearValidators();
          this.name.updateValueAndValidity();
        }
      })
    );
  }
  ngOnInit() {
    this.subs.push(
      combineLatest([this.route.params, this.route.queryParams]).pipe(take(1)).subscribe(([pathParams, queryParams]) => {
        if (pathParams['id'] && queryParams['basket']) {
          this.groupOrderID = pathParams['id'];
          this.basketID = queryParams['basket'];
          this.store.dispatch(new GetGroupOrder(this.groupOrderID, this.guestName, this.basketID));
        }
      }),
      this.groupOrder$.subscribe((groupOrder) => {
        if (groupOrder) {
          if (groupOrder.groupOrderDeadline < new Date()) {
            this.store.dispatch(new CancelGroupOrder());
          }
          this.groupOrderID = groupOrder.groupOrderID;
          this.menuID = groupOrder.order.location.locationID;
        }
      })
    );
  }
  joinGroupOrder() {
    this.loading = true;
    if (!this.displayUser || this.displayUser?.isGuest) {
      this.guestName = this.name.value + ' #' + Math.floor(10 + Math.random() * 90);
      sessionStorage.setItem('guestName', this.guestName);
    } else {
      this.guestName = this.displayUser.firstName + ' ' + this.displayUser.lastName;
    }
    this.store.dispatch(new JoinGroupOrder(this.groupOrderID, this.guestName, this.basketID)).subscribe(() => {
      this.store.dispatch(new GetCurrentGroupOrder(this.guestName));
      this.loading = false;
      this.navigation.navigateToMenuPage(this.menuID);
    });
  }
  closePage() {
    // Allow fade out animation to play before navigating back
    setTimeout(
      // () => this.router.navigate(['..']),
      () => this.urlLocation.back(),
      100
    );
  }
  get name() {
    return this.nameForm.get('name');
  }
}
