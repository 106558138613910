import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';

import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {Select, Store} from '@ngxs/store';
import {SetRouteBack, UpdateTitle} from '../../../../../store/actions/app.actions';
import {MetaService} from '../../../../../services/meta.service';
import {NavigationService} from '@modules/navigation/services';
import {Branding} from '../../../../../vendors/directus/interfaces/branding.interface';

@Component({
  selector: 'app-saved-cards',
  template: '',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in'))
    ])
  ]
})
export class MissingPageComponent implements OnInit, OnDestroy {

  // tslint:disable:no-shadowed-variable
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'missing page';

  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displayBranding: Branding;

  displayBaseUrl: string;

  constructor(
    // private navbarService: NavBarService,
    // private pageService: MissingPagePageService,
      private store: Store,
      private meta: MetaService,
      private navigation: NavigationService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // Navbar service data
    this.subs.push(this.vendorSetup$.subscribe(vendorSetup => {
      if (vendorSetup) {
        this.displayVendorSetup = vendorSetup;
      }
    }));
    this.subs.push(this.order$.subscribe(order => {
      if (order) {
        this.displayOrder = order;
      }
    }));
    this.subs.push(this.user$.subscribe(user => {
      if (user) {
        this.displayUser = user;
      } else {
        this.displayUser = null;
      }
    }));
    this.subs.push(this.branding$.subscribe(branding => {
      if (branding) {
        this.displayBranding = branding;
      }
    }));

    this.displayBaseUrl = window.location.origin;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  startOverKiosk() {
    const locationId = localStorage.getItem('kiosklocation');
    this.navigation.navigateToKioskStartOrderPage(locationId);
  }

}
