import { Component, Input } from '@angular/core';
import { Category } from 'src/interfaces/category.interface';
import {MenuService} from '@modules/menu/services';

@Component({
  selector: 'app-horizontal-category-scroll',
  templateUrl: './horizontal-category-scroll.component.html',
  styleUrls: ['./horizontal-category-scroll.component.scss']
})
export class HorizontalCategoryScrollComponent {
  @Input() categories: Category[];
  constructor(
    public menuService: MenuService
  ) {}
}
