import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bootstrapIcon'
})
export class BootstrapIconPipe implements PipeTransform {
  transform(value: string): string {
    return value ? 'bi bi-'.concat(value) : '';
  }
}
