import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {Category} from '../../../../interfaces/category.interface';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Order} from '../../../../interfaces/order.interface';
import {MenuService} from '@modules/menu/services';
import {Product} from '../../../../interfaces/product.interface';
import {UserRoles} from '@common/components';
import {User} from '../../../../interfaces/user.interface';
import {NavbarSettings} from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-category-scroller',
  templateUrl: './category-scroller.component.html',
  styleUrls: ['category-scroller.component.scss'],
})
export class CategoryScrollerComponent {
  roles = UserRoles;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Input() category: Category;
  @Input() modifyModalRef: TemplateRef<any>;
  @Input() showFullMenu: boolean;
  @Input() showOrderControls: boolean;
  @Input() productLoading: string;
  @Output() clickCategory = new EventEmitter<any>();
  @Output() clickToggleMenu = new EventEmitter<boolean>();
  constructor(
    public menuService: MenuService
  ) {}
  selectCategory() {
    this.clickCategory.emit();
  }
  productTrackBy(index: number, product: Product): string {
    return `${index}${product.name}${product.productID}`;
  }
}
