<div class="d-flex flex-column gap-3 w-100 h-100">

  <h4 class="text-start fw-bold header-font mb-0 d-flex align-items-center">
    <app-custom-icon
      class="color-primary me-2"
      default="bi-wallet2"
      customIconKey="payments_navigation_icon"
      altText="Payments"
    ></app-custom-icon>
    {{ section?.section_title || 'Saved Payments' }}
  </h4>

  <ng-container *ngIf="displaySavedCards$ | async as savedCards; else savedCardsLoading">
    <ng-container *ngIf="savedCards.length > 0; else noSavedCards">
      <div class="w-100" style="overflow-x: hidden">
        <div class="payment-container" [class.responsive]="isResponsive">
          <ng-container *ngIf="rewardPoints$ | async as rewardsBalances">
            <ng-container *ngIf="rewardsBalances.storedValueCents !== null">
              <app-credit-card-card class="h-100" [isLoyalty]="true"
                                    [storedValueCents]="rewardsBalances.storedValueCents"></app-credit-card-card>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let card of savedCards; index as index">
            <app-credit-card-card
              [card]="card"
              displayMode="improved"
              (clickedDefaultCard)="defaultCard(card)"
              (clickedDeleteCard)="deleteCard(card)"
              [index]="index"
            ></app-credit-card-card>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #savedCardsLoading>
    <ion-list class="payment-container w-100 px-3">
      <ng-container *ngFor="let item of [].constructor(6)">
        <app-skeleton-card class="w-100" [payment]="true"></app-skeleton-card>
      </ng-container>
    </ion-list>
  </ng-template>
</div>

<ng-template #noSavedCards>
  <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
    <app-custom-icon
      class="color-primary display-3"
      default="bi-wallet2"
      customIconKey="payments_navigation_icon"
      altText="Payments"
    ></app-custom-icon>
    <div class="text-center d-flex flex-column">
      <h5 class="fw-bold">You currently have no saved payments.</h5>
      <div>Your saved payment methods appear here after placing orders.</div>
    </div>
    <button
      class="de-primary-link w-100"
      style="max-width: 264px"
      [routerLink]="locationsRoute">
      Order Now
    </button>
  </div>
</ng-template>