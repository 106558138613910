<div id="modalPopup" class="popup-overlay">
  <ion-row class="popup-container">
    <ion-col class="modal-contents">
      <ng-content></ng-content>
    </ion-col>
    <ion-row size="2">
      <ion-button expand="full" fill="clear" (click)="closeModal()">
        <div class="d-flex align-items-center">
          <i class="bi bi-x-circle font-size-h1 d-flex"></i>
        </div>
      </ion-button>
    </ion-row>
  </ion-row>
</div>