import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AddDonation} from '../../../../store/actions/order.actions';
import {Observable} from 'rxjs';
import {Order} from '../../../../interfaces/order.interface';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';

@Component({
  selector: 'app-donation-check',
  templateUrl: './donation-check.component.html',
  styleUrls: ['donation-check.component.scss'],
})
export class DonationCheckComponent implements OnInit{
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select((state) => state.app.theme) theme$: Observable<ThemeColor[]>;
  donationForm = new UntypedFormGroup({
    donationOptIn: new UntypedFormControl(false),
  });
  order: Order = null;
  constructor(
    private store: Store
  ) {}
  ngOnInit() {
    this.order$.subscribe((order: Order) => {
      this.order = order;
      console.log(this.order);
      this.donationOptIn.setValue(order.donationsTotal > 0);
    });
  }
  addDonationTypeToCart() {
    const set = this.donationForm.get('donationOptIn').value;
    this.store.dispatch(new AddDonation(this.order.donationType[0].id, set ? this.order.donationType[0].amount : 0));
  }
  get donationOptIn() {
    return this.donationForm.get('donationOptIn');
  }
}
