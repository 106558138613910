import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {GiftCardOrder} from '@modules/gift-card/models';

@Component({
    selector: 'app-add-to-cart',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './add-to-cart.component.html',
    styleUrls: ['add-to-cart.component.scss'],
})
export class AddToCartComponent implements OnInit {
    @Select(state => state.giftCardOrder.order) order$: Observable<GiftCardOrder>;
    @Input() hideCartFunctionality: boolean;
    @Input() itemConfigured = false;
    @Input() itemTotal = 0;
    @Input() currencyCode: string;
    @Output() createCard = new EventEmitter<number>();
    order: GiftCardOrder = null;
    disableButton = false;
    quantity = 1;
    ngOnInit() {
      this.order$.subscribe((order: GiftCardOrder) => {
        this.order = order;
        this.disableButton = order.giftCards.length > 0 && this.hideCartFunctionality;
      });
    }
    createCardClicked() {
        this.createCard.emit(this.quantity);
    }
    decrementQuantity() {
        this.quantity -= 1;
        if (this.quantity < 1) {
            this.quantity = 1;
        }
    }
    incrementQuantity() {
        this.quantity += 1;
        if (this.quantity > 2147483647) {
            this.quantity = 2147483647;
        }
    }
}
