import {Injectable, Injector} from '@angular/core';
import {UserProvider} from '../../providers/user-provider.interface';
import {CmsVendorSetupService} from './cms-vendor-setup.service';
import {Observable, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {NovaDineProviderService} from 'src/vendors/novadine/novadine-provider.service';
import {OLOProviderService} from 'src/vendors/olo/olo-provider.service';
import {PunchhProviderService} from 'src/vendors/punchh/punchh-provider.service';
import {PunchhOLOProviderService} from 'src/vendors/punchh-olo/punchh-olo-provider.service';
import {ItwercsProviderService} from '../../vendors/itwercs/itwercs-provider.service';
import {PaytronixProviderService} from '../../vendors/paytronix/paytronix-provider.service';
import ProviderConfig from '../../vendors/config/vendor.config';
import {Select} from '@ngxs/store';
import {VendorSetup} from '../../interfaces/vendor.interface';
import {PersonicaProviderService} from '../../vendors/personica/personica-provider.service';
import {TastyIgniterProviderService} from '../../vendors/tasty-igniter/tasty-igniter-provider.service';
import {SpendgoProviderService} from '../../vendors/spendgo/spendgo-provider.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

    private currentService: UserProvider;

    constructor(
        private vendorService: CmsVendorSetupService,
        private injector: Injector
    ) {}

    getService(): Observable<UserProvider> {
        if (this.currentService) {
            return of(this.currentService);
        } else {
            return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
                return this.chooseService(vs.user_provider);
            }));
            // return this.vendorService.getService().pipe(switchMap(service => {
            //     return service.getVendorSetup().pipe(map(setup => {
            //         return this.chooseService(setup.user_provider);
            //     }));
            // }));
        }
    }

    private chooseService(vendor: string): UserProvider {
        switch (vendor) {
            case ProviderConfig.novadine: return this.currentService = this.injector.get(NovaDineProviderService);
            case ProviderConfig.olo: return this.currentService = this.injector.get(OLOProviderService);
            case ProviderConfig.punchhOlo: return this.currentService = this.injector.get(PunchhOLOProviderService);
            case ProviderConfig.itwercs: return this.currentService = this.injector.get(ItwercsProviderService);
            case ProviderConfig.paytronix: return this.currentService = this.injector.get(PaytronixProviderService);
            case ProviderConfig.punchh: return this.currentService = this.injector.get(PunchhProviderService);
            case ProviderConfig.personica: return this.currentService = this.injector.get(PersonicaProviderService);
            case ProviderConfig.tastyIgniter: return this.currentService = this.injector.get(TastyIgniterProviderService);
            case ProviderConfig.spendgo: return this.currentService = this.injector.get(SpendgoProviderService);
            default: return null;
        }
    }
}
