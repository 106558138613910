<div class="px-3 pt-3 pb-5">
  <div class="d-flex justify-content-end">
    <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="activeModal.close('Close click')"></i>
  </div>

  <div class="d-flex flex-row flex-nowrap">
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
    <div class="d-flex flex-column grow-3 basis-0">
      <div class="w-100 m-0 header-font text-center mb-3">
        <h1 class="font-size-h1 m-0 fw-bold pt-4 pt-md-0">Change Location</h1>
      </div>
      <div class="w-100 mb-3">
        <div class="header-underline mx-auto my-0"></div>
      </div>
      <!-- <div class="header-font modal-title">Change Location</div> -->
      <div class="modal-text w-100 mb-3">{{text}}</div>
      <div class="w-100 d-flex justify-content-center mb-3">
        <button class="de-primary-btn handoff-btn" (click)="confirmChange()">
          <ng-container *ngIf="!isLoading">Confirm Change</ng-container>
          <ng-container *ngIf="isLoading">
            <div class="de-btn-loader" role="status"></div>
          </ng-container>
        </button>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <button class="de-secondary-link handoff-btn" (click)="keepOldLocation()">Cancel</button>
      </div>
    </div>
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
  </div>

  <div class="text-center text-danger mt-4" *ngIf="errorMessage">{{errorMessage}}</div>
</div>
