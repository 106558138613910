import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnInit,
  HostBinding
} from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[appHideFab]'
})
export class HideFabDirective implements OnInit {
  @HostBinding('style.transition') transition = '0.3s linear';
  @Input('appHideFab') scrollArea;

  private scrollElement;
  private scrollObservable: Observable<CustomEvent>;
  private hidden = false;
  private triggerDistance = 20;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {}

  ngOnInit() {
    // Wait until 'ion-scroll' element is added to 'ion-content'
    const mutationObserver = new MutationObserver(() => {
      this.scrollElement = this.scrollArea.getScrollElement();

      if (this.scrollElement !== null) {
        this.initStyles();
        setTimeout(() => {
          this.scrollObservable = this.scrollArea.ionScroll;

          this.scrollObservable.subscribe((scrollEvent: CustomEvent) => {
            const delta = scrollEvent.detail.deltaY;

            if (scrollEvent.detail.currentY === 0 && this.hidden) {
              this.show();
            } else if (!this.hidden && delta > this.triggerDistance) {
              this.hide();
            } else if (this.hidden && delta < -this.triggerDistance) {
              this.show();
            }
          });
        }, 1);
      }
    });

    mutationObserver.observe(this.scrollArea.el.shadowRoot, {
      childList: true
    });
  }

  initStyles() {
    this.domCtrl.write(() => {
      if(this.element.nativeElement.children[0]) {
        this.renderer.setStyle(
          this.element.nativeElement.children[0],
          'transition',
          '0.2s linear'
        );
      }
    });
  }

  hide() {
    this.domCtrl.write(() => {
      if(this.element.nativeElement.children[0]) {
        this.renderer.setStyle(
          this.element.nativeElement.children[0],
          'transform',
          'scale3d(0,0,0)'
        );
        this.renderer.setStyle(
          this.element.nativeElement.children[0],
          'opacity',
          '0'
        );
      }
    });

    this.hidden = true;
  }

  show() {
    this.domCtrl.write(() => {
      if(this.element.nativeElement.children[0]) {
        this.renderer.setStyle(
          this.element.nativeElement.children[0],
          'transform',
          'scale3d(1,1,1)'
        );

        this.renderer.setStyle(
          this.element.nativeElement.children[0],
          'opacity',
          '1'
        );
      }
    });

    this.hidden = false;
  }
}
