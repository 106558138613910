import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss'],
})
export class SkeletonCardComponent implements OnInit {
  // card components
  @Input() image = true;
  @Input() title = true;
  @Input() subtitle = true;
  @Input() description = true;
  @Input() button = true;
  // card styles (standard is default, new styles should be false initially)
  @Input() standardCard = true;
  @Input() rewardCheckout = false;
  @Input() paymentCheckout = false;
  @Input() payment = false;
  @Input() productImage = false;
  @Input() menuChip = false;
  @Input() cartProduct = false;
  @Input() messageNotification = false;
  @Input() orderType = false;
  @Input() productCard = false;
  @Input() rewardCard = false;
  @Input() modifierCard = false;

  constructor() {}

  ngOnInit() {
    if (
      this.rewardCheckout ||
      this.paymentCheckout ||
      this.payment ||
      this.menuChip ||
      this.productImage ||
      this.cartProduct ||
      this.messageNotification ||
      this.orderType ||
      this.productCard ||
      this.modifierCard
    ) {
      this.standardCard = false;
    }
  }
}
