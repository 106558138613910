import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { TastyIgniterHttpService } from './tasty-igniter-http.service';
import { KeyValuePair } from './interfaces/key-value-pair.interface';
import { CalendarResponse } from './interfaces/calendar-response.interface';
import { ProductModifiersResponse } from './interfaces/product-modifiers-response.interface';
import { CreateBasketResponse } from './interfaces/create-basket-response.interface';
import { GroupOrder } from './interfaces/group-order.interface';
import { AddProductRequest } from './interfaces/add-product-request.interface';
import { AddProductsRequest } from './interfaces/add-products-request.interface';
import { BasketResponse } from './interfaces/basket-response.interface';
import { UpsellItemsResponse } from './interfaces/upsell-items-response.interface';
import { RewardsResponse } from './interfaces/rewards-response.interface';
import { GiftCardBalance } from './interfaces/gift-card-balance.interface';
import { BillingSchemesAndAccountsResponse, Billingscheme } from './interfaces/billing-schemes-and-account-response.interface';
import { SubmitOrderMultiPaymentRequest } from './interfaces/submit-order-multi-payment-request.interface copy';
import { SubmitOrderMultiPaymentResponse } from './interfaces/submit-order-multi-payment-response.interface';
import { ValidateBasketResponse } from './interfaces/validate-basket-response.interface';
import { DispatchStatusResponse } from './interfaces/dispatch-status-response.interface';
import { ManualFireResponse } from './interfaces/manual-fire-response.interface';
import { ManualFireRequest } from './interfaces/manual-fire-request.intferface';
import { CreateUserRequest } from './interfaces/create-user-request.interface';
import { CreateUserFromGuestOrderResponse } from './interfaces/create-user-from-guest-order-response.interface';
import { UserResponse } from './interfaces/user-response.interface';
import { UserCommunicationPreferences } from './interfaces/user-communication-preferences.interface';
import { BillingAccountsResponse } from './interfaces/billing-accounts-response.interface';
import { GiftCardBalancesResponse, Cardbalance } from './interfaces/gitf-card-balances-response.interface';
import { CreateUserResponse } from './interfaces/create-user-response.interface';
import { OrderResponseInterface } from './interfaces/order-response.interface';
import {Cacheable} from 'ts-cacheable';
import {RetrieveBrandLevelSettingsResponse} from './interfaces/retrieve-brand-level-settings-response.interface';

@Injectable({
    providedIn: 'root'
})
export class TastyIgniterAPIService {

    constructor(
        private tastyIgniterHttp: TastyIgniterHttpService
    ) { }

    getBrandLevelSettings(): Observable<RetrieveBrandLevelSettingsResponse> {
        return this.tastyIgniterHttp.get<RetrieveBrandLevelSettingsResponse>('/brand');
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    getAllParticipatingRestaurants(includePrivate: boolean): Observable<any> {
        const paramsStr = this.getQueryParamsString([
            { key: 'includePrivate', value: includePrivate }
        ]);
        const resource = '/locations';
        return this.tastyIgniterHttp.get<any>(resource + paramsStr);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    // tslint:disable-next-line: max-line-length
    getNearbyParticipatingRestaurants(latitude: number, longitude: number, milesRadius: number, maxResults: number, from: Date, to: Date): Observable<any> {
        // const paramsStr = this.getQueryParamsString([
        //     { key: 'lat', value: latitude },
        //     { key: 'long', value: longitude },
        //     { key: 'radius', value: milesRadius },
        //     { key: 'limit', value: maxResults },
        //     { key: 'calendarstart', value: moment(from).format('YYYYMMDD') },
        //     { key: 'calendarend', value: moment(to).format('YYYYMMDD') },
        // ]);
        const resource = '/locations?include=working_hours,media';
        // return this.tastyIgniterHttp.get<AllRestaurantsResponse>(resource + paramsStr);
        return this.tastyIgniterHttp.get<any>(resource);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    // tslint:disable-next-line: max-line-length
    getNearbyParticipatingRestaurantsWithInHouseDelivery(latitude: number, longitude: number, maxResults: number): Observable<any> {
        const paramsStr = this.getQueryParamsString([
            { key: 'lat', value: latitude },
            { key: 'long', value: longitude },
            { key: 'limit', value: maxResults }
        ]);
        const resource = '/restaurants/deliveringto';
        return this.tastyIgniterHttp.get<any>(resource + paramsStr);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    getRestaurantsWithPhoneNumber(phone: string): Observable<any> {
        const resource = `/restaurants/bytelephone/${this.getNumbersOnlyString(phone)}`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000),
        maxCacheCount: 100
    })
    getRestaurant(restaurantID: number): Observable<any> {
        const resource = `/locations/${restaurantID}?include=working_hours`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    getRestaurantsByExternalID(externalID: string): Observable<any> {
        const resource = `/restaurants/${externalID}`; // the docs indicate this^ object instead of single restaurant
        return this.tastyIgniterHttp.get<any>(resource);
    }

    @Cacheable({
        maxAge: (5 * 60 * 1000)
    })
    getRestaurantsBySlug(slug: string): Observable<any> {
        const resource = `/restaurants/byslug/${slug}`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    @Cacheable({
        maxAge: (15 * 60 * 1000),
        maxCacheCount: 100
    })
    getRestaurantOperatingHours(restaurantID: number, from: Date, to: Date): Observable<CalendarResponse> {
        const paramsStr = this.getQueryParamsString([
            { key: 'from', value: formatDate(from, 'yyyyMMdd', 'en-US') },
            { key: 'to', value: formatDate(to, 'yyyyMMdd', 'en-US') }
        ]);
        const resource = `/restaurants/${restaurantID}/calendars`;
        return this.tastyIgniterHttp.get<CalendarResponse>(resource + paramsStr);
    }

    @Cacheable({
        maxAge: (15 * 60 * 1000)
    })
    getRestaurantOperatingHoursForThisWeek(restaurantID: number): Observable<CalendarResponse> {
        const yesterday = new Date();
        const nextWeek = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        nextWeek.setDate(nextWeek.getDate() + 6);
        return this.getRestaurantOperatingHours(restaurantID, yesterday, nextWeek);
    }

    @Cacheable({
        maxAge: (15 * 60 * 1000),
        maxCacheCount: 5
    })
    getRestaurantCategories(locationID: string): Observable<any> {
        console.log(locationID);
        const resource = `/categories?include=media,locations`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    // @Cacheable({
    //     maxAge: (15 * 60 * 1000),
    //     maxCacheCount: 5
    // })
    getRestaurantMenu(locationID: string): Observable<any> {
        console.log(locationID);
        const resource = `/menus?location=` + locationID + `&include=categories,menu_options.menu_option_values,media&page_limit=100`;
        return this.tastyIgniterHttp.get<any>(resource);
    }


    @Cacheable({
        maxAge: (15 * 60 * 1000)
    })
    getProductModifiersAndOptions(productID: number): Observable<ProductModifiersResponse> {
        const resource = `/products/${productID}/modifiers`;
        return this.tastyIgniterHttp.get<ProductModifiersResponse>(resource);
    }

    @Cacheable({
        maxAge: (15 * 60 * 1000)
    })
    getTopLevelModifiersAndOptions(productID: number): Observable<ProductModifiersResponse> {
        const resource = `/products/${productID}/options`;
        return this.tastyIgniterHttp.get<ProductModifiersResponse>(resource);
    }

    createBasket(restaurantID: number, authToken: string): Observable<CreateBasketResponse> {
        const body = {
            vendorid: restaurantID,
            // authtoken: authToken
        };
        if (authToken) {
            body['authtoken'] = authToken;
        }
        const resource = `/baskets/create`;
        return this.tastyIgniterHttp.post<CreateBasketResponse>(resource, body);
    }

    // tslint:disable-next-line: max-line-length
    createBasketFromPreviousOrder(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<CreateBasketResponse> {
        const paramsStr = this.getQueryParamsString([
            { key: 'authtoken', value: authToken }
        ]);
        const body = {
            id: orderID,
            ignoreunavailableproducts: ignoreUnavailableProducts
        };
        const resource = `/baskets/createfromorder`;
        return this.tastyIgniterHttp.post<CreateBasketResponse>(resource + paramsStr, body);
    }

    getBasket(basketGuid: string): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}`;
        return this.tastyIgniterHttp.get<CreateBasketResponse>(resource);
    }

    // tslint:disable-next-line: max-line-length
    createGroupOrder(authToken: string, restaurantID: number, basketGuid: string, deadline: Date, note: string): Observable<GroupOrder> {
        const paramsStr = this.getQueryParamsString([
            { key: 'authtoken', value: authToken }
        ]);
        const body = {
            restaurantid: restaurantID,
            basketid: basketGuid,
            deadline: formatDate(deadline, 'yyyyMMdd hh:mm', 'en-US'),
            note
        };
        const resource = `/grouporders`;
        return this.tastyIgniterHttp.put<GroupOrder>(resource + paramsStr, body);
    }

    updateGroupOrder(authToken: string, groupOrderGuid: string, deadline: Date, note: string): Observable<GroupOrder> {
        // const paramsStr = this.getQueryParamsString([
        //     { key: 'authtoken', value: authToken }
        // ]);
        // const body = {
        //     grouporderid: groupOrderGuid,
        //     deadline: formatDate(deadline, 'yyyyMMdd hh:mm', 'en-US'),
        //     note
        // };
        const resource = `/grouporders`;
        return this.tastyIgniterHttp.get<GroupOrder>(resource);
    }

    sendGroupOrderInvites(groupOrderGuid: string, authToken: string, emails: string[]) {
        const paramsStr = this.getQueryParamsString([
            { key: 'authtoken', value: authToken }
        ]);
        const body = {
            emailaddresses: emails
        };
        const resource = `/grouporders/${groupOrderGuid}/invite`;
        return this.tastyIgniterHttp.post<any>(resource + paramsStr, body).subscribe(() => { });
    }

    getGroupOrder(groupOrderGuid: string, authToken: string): Observable<GroupOrder> {
        const paramsStr = this.getQueryParamsString([
            { key: 'authtoken', value: authToken }
        ]);
        const resource = `/grouporders/${groupOrderGuid}`;
        return this.tastyIgniterHttp.get<GroupOrder>(resource + paramsStr);
    }

    addProductToBasket(basketGuid: string, body: AddProductRequest): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/products`;
        return this.tastyIgniterHttp.post<CreateBasketResponse>(resource, body);
    }

    addProductToBasketByChainID(basketGuid: string, body: any): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/products/bychainid`;
        return this.tastyIgniterHttp.post<CreateBasketResponse>(resource, body);
    }

    updateProductInBasket(basketGuid: string, basketProductID: number, body: AddProductRequest): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/products/${basketProductID}`;
        return this.tastyIgniterHttp.put<CreateBasketResponse>(resource, body);
    }

    removeProductFromBasket(basketGuid: string, basketProductID: number): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/products/${basketProductID}`;
        return this.tastyIgniterHttp.delete<CreateBasketResponse>(resource);
    }

    updateProductsInBasket(basketGuid: string, body: AddProductsRequest): Observable<BasketResponse> {
        const resource = `/baskets/${basketGuid}/products/batch`;
        return this.tastyIgniterHttp.put<BasketResponse>(resource, body);
    }

    addProductsToBasket(basketGuid: string, body: AddProductsRequest): Observable<BasketResponse> {
        const resource = `/baskets/${basketGuid}/products/batch`;
        return this.tastyIgniterHttp.post<BasketResponse>(resource, body);
    }

    getEligibleUpsellItems(basketGuid: string | number): Observable<UpsellItemsResponse> {
        const resource = `/baskets/${basketGuid}/upsell`;
        return this.tastyIgniterHttp.get<UpsellItemsResponse>(resource);
    }

    setTip(basketGuid: string, amount: number): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/tip`;
        return this.tastyIgniterHttp.put<CreateBasketResponse>(resource, { amount });
    }

    removeCoupon(basketGuid: string): Observable<any> {
        const resource = `/baskets/${basketGuid}/coupon`;
        return this.tastyIgniterHttp.delete<any>(resource);
    }

    removeAppliedReward(basketGuid: string, rewardID: number): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/loyaltyrewards/${rewardID}`;
        return this.tastyIgniterHttp.delete<CreateBasketResponse>(resource);
    }

    setBasketTimeModeToASAP(basketGuid: string): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/timewanted`;
        return this.tastyIgniterHttp.delete<CreateBasketResponse>(resource);
    }

    setBasketCustomFieldValue(basketGuid: string, customId: number | string, value: any): Observable<CreateBasketResponse> {
        const body = {
            id: customId,
            value
        };
        const resource = `/baskets/${basketGuid}/customfields`;
        return this.tastyIgniterHttp.put<CreateBasketResponse>(resource, body);
    }

    removeBasketCustomFieldValue(basketGuid: string, customFieldID: number): Observable<CreateBasketResponse> {
        const resource = `/baskets/${basketGuid}/customfields/${customFieldID}`;
        return this.tastyIgniterHttp.delete<CreateBasketResponse>(resource);
    }

    transferBasketToOLOWebsite(basketGuid: string): Observable<{ web: string; mobileweb: string }> {
        const resource = `/baskets/${basketGuid}/platformtransfer`;
        return this.tastyIgniterHttp.post<{ web: string; mobileweb: string }>(resource, {});
    }

    @Cacheable()
    getAllBillingSchemesAndAccounts(basketGuid: string): Observable<BillingSchemesAndAccountsResponse> {
        const resource = `/baskets/${basketGuid}/billingschemes`;
        return this.tastyIgniterHttp.get<BillingSchemesAndAccountsResponse>(resource);
    }

    getBillingScheme(basketGuid: string, billingSchemeID: number): Observable<Billingscheme> {
        const resource = `/baskets/${basketGuid}/billingschemes/${billingSchemeID}`;
        return this.tastyIgniterHttp.get<Billingscheme>(resource);
    }

    getGiftCardBalance(basketGuid: string, billingSchemeID: number, cardNumber: string, pin: string): Observable<GiftCardBalance> {
        const body = {
            cardnumber: cardNumber,
            pin
        };
        const resource = `/baskets/${basketGuid}/billingschemes/${billingSchemeID}/retrievebalance`;
        return this.tastyIgniterHttp.post<GiftCardBalance>(resource, body);
    }

    verifyGiftCardPINRequirement(billingSchemeID: number, cardNumber: string): Observable<{ ispinrequired: boolean }> {
        const resource = `/billingschemes/${billingSchemeID}/binvalidation`;
        return this.tastyIgniterHttp.post<{ ispinrequired: boolean }>(resource, { cardnumber: cardNumber });
    }

    validateBasket(basketGuid: string, checkUpsell: boolean): Observable<ValidateBasketResponse> {
        const paramsStr = this.getQueryParamsString([
            { key: 'checkupsell', value: checkUpsell }
        ]);
        const resource = `/baskets/${basketGuid}/validate`;
        return this.tastyIgniterHttp.post<ValidateBasketResponse>(resource + paramsStr, {});
    }

    // tslint:disable-next-line: max-line-length
    submitOrderPayment(body: any, orderID: number): Observable<any /* OrderResponseInterface */> {
        const resource = `/payments/${orderID}`;
        return this.tastyIgniterHttp.patch<OrderResponseInterface>(resource, body);
    }

    // tslint:disable-next-line: max-line-length
    submitOrder(body: OrderResponseInterface): Observable<any /* OrderResponseInterface */> {
        const resource = `/orders`;
        return this.tastyIgniterHttp.post<OrderResponseInterface>(resource, body);
    }

    // tslint:disable-next-line: max-line-length
    completeOrder(body: any /* OrderCompletion */, orderID: number): Observable<OrderResponseInterface> {
        const resource = `/submit/${orderID}`;
        return this.tastyIgniterHttp.patch<OrderResponseInterface>(resource, body);
    }

    checkOrderStatus(orderGuid: string): Observable<any /* OrderResponseInterface */> {
        const resource = `/orders/${orderGuid}`;
        return this.tastyIgniterHttp.get<OrderResponseInterface>(resource);
    }


    submitOrderWithMultiplePayments(basketGuid: string, body: SubmitOrderMultiPaymentRequest): Observable<SubmitOrderMultiPaymentResponse> {
        const resource = `/baskets/${basketGuid}/submit/multiplepayments`;
        return this.tastyIgniterHttp.post<SubmitOrderMultiPaymentResponse>(resource, body);
    }

    editOrder(orderGuid: string): Observable<CreateBasketResponse> { // this endpoint makes no sense.
        const resource = `/orders/${orderGuid}/edit`;
        return this.tastyIgniterHttp.post<CreateBasketResponse>(resource, {});
    }

    cancelOrder(orderGuid: string): Observable<OrderResponseInterface> {
        const resource = `/orders/${orderGuid}/cancel`;
        return this.tastyIgniterHttp.post<OrderResponseInterface>(resource, {});
    }

    checkOrderDispatchStatus(orderGuid: string, authToken: string): Observable<DispatchStatusResponse> {
        const paramsStr = this.getQueryParamsString([
            { key: 'authtoken', value: authToken }
        ]);
        const resource = `/orders/${orderGuid}/deliverystatus`;
        return this.tastyIgniterHttp.get<DispatchStatusResponse>(resource + paramsStr);
    }

    checkGuestOrderDispatchStatus(orderGuid: string): Observable<DispatchStatusResponse> {
        const resource = `/orders/${orderGuid}/deliverystatus`;
        return this.tastyIgniterHttp.get<DispatchStatusResponse>(resource);
    }

    submitManualFireOrder(orderGuid: string, body: ManualFireRequest): Observable<ManualFireResponse> {
        const resource = `/orders/byid/${orderGuid}/manualfire`;
        return this.tastyIgniterHttp.post<ManualFireResponse>(resource, body);
    }

    notifyOfArrival(orderGuid: string, extraMessage: string): Observable<OrderResponseInterface> {
        const body = {
            message: extraMessage
        };
        const resource = `/orders/${orderGuid}/arrival`;
        return this.tastyIgniterHttp.post<OrderResponseInterface>(resource, body);
    }

    createUser(body: CreateUserRequest): Observable<CreateUserResponse> {
        const resource = `/users/create`;
        return this.tastyIgniterHttp.post<CreateUserResponse>(resource, body); // it uh...returns the same thing as the request.
    }

    createOrGetSSOLinkedOLO(provider: string, token: string, basketID: string): Observable<CreateUserResponse> {
        const body = {
            provider,
            providertoken: token,
            basketid: basketID
        };
        const resource = `/users/getorcreate`;
        return this.tastyIgniterHttp.post<CreateUserResponse>(resource, body);
    }

    createUserFromGuestOrder(orderGuid: string, password: string, emailOptIn: boolean): Observable<CreateUserFromGuestOrderResponse> {
        const body = {
            password,
            optin: emailOptIn
        };
        const resource = `/orders/${orderGuid}/createuser`;
        return this.tastyIgniterHttp.post<CreateUserFromGuestOrderResponse>(resource, body);
    }

    authenticateUser(username: string, password: string): Observable<UserResponse> {
        const body = {
            login: username,
            password
        };
        const resource = `/users/authenticate`;
        return this.tastyIgniterHttp.post<UserResponse>(resource, body);
    }

    changePassword(authToken: string, oldPassword: string, newPassword: string): Observable<any> {
        const body = {
            currentpassword: oldPassword,
            newpassword: newPassword // min 7 characters
        };
        const resource = `/users/${authToken}/password`;
        return this.tastyIgniterHttp.post<any>(resource, body); // docs don't specify a response
    }

    startForgotPasswordProcess(email: string): Observable<any> {
        const body = {
            emailaddress: email
        };
        const resource = `/users/forgotpassword`;
        return this.tastyIgniterHttp.post<any>(resource, body); // docs don't specify a response
    }

    deleteUserAccount(authToken: string): Observable<any> {
        const resource = `/users/${authToken}/account`;
        return this.tastyIgniterHttp.delete<any>(resource);
    }

    updateUserCommunicationPreferences(authToken: string, body: UserCommunicationPreferences): Observable<UserCommunicationPreferences> {
        const resource = `/users/${authToken}/contactoptions`;
        return this.tastyIgniterHttp.put<UserCommunicationPreferences>(resource, body);
    }

    deleteUserDeliveryAddresses(authToken: string, addressID: number): Observable<any> {
        const resource = `/users/${authToken}/userdeliveryaddresses/${addressID}`;
        return this.tastyIgniterHttp.delete<any>(resource);
    }

    getUserBillingAccounts(authToken: string): Observable<BillingAccountsResponse> {
        const resource = `/users/${authToken}/billingaccounts`;
        return this.tastyIgniterHttp.get<BillingAccountsResponse>(resource);
    }

    deleteUserBillingAccount(authToken: string, billingAccountID: number): Observable<any> {
        const resource = `/users/${authToken}/billingaccounts/${billingAccountID}`;
        return this.tastyIgniterHttp.delete<any>(resource);
    }

    getUserGiftCardBalances(authToken: string): Observable<GiftCardBalancesResponse> {
        const resource = `/users/${authToken}/billingaccounts/storedvalue`;
        return this.tastyIgniterHttp.get<GiftCardBalancesResponse>(resource);
    }

    getUserGiftCardBalance(authToken: string, billingAccountID: number): Observable<Cardbalance> {
        const resource = `/users/${authToken}/billingaccounts/storedvalue/${billingAccountID}`;
        return this.tastyIgniterHttp.get<Cardbalance>(resource);
    }

    setUserDefaultCreditCard(authToken: string, billingAccountID: number): Observable<any> {
        const resource = `/users/${authToken}/creditcards/${billingAccountID}`;
        return this.tastyIgniterHttp.put<any>(resource, { isdefault: true });
    }

    getUserRecentOrders(authToken: string): Observable<any> {
        const resource = `/users/${authToken}/recentorders`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    getUserRecentOrdersByExternalRef(externalRef: string): Observable<any> {
        const resource = `/users/byref/${externalRef}/recentorders`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    getUsersOpenManualFireOrders(authToken: string): Observable<any> {
        const resource = `/users/${authToken}/openorders/manualfire`;
        return this.tastyIgniterHttp.get<any>(resource);
    }

    getUsersQualifyingRewards(authToken: string, restaurantID: string): Observable<RewardsResponse> {
        const paramsStr = this.getQueryParamsString([
            { key: 'vendorid', value: restaurantID }
        ]);
        const resource = `/users/${authToken}/qualifyingrewards`;
        return this.tastyIgniterHttp.get<RewardsResponse>(resource + paramsStr);
    }

    getPaymentMethods(): Observable<any> {
        const resource = '/payment-methods';
        return this.tastyIgniterHttp.get<any>(resource);
    }

    confirmOrder(orderID: string): Observable<any> {
        const resource = '/submit';
        return this.tastyIgniterHttp.patch<any>(resource + '/' + orderID, {});
    }

    private getQueryParamsString(pairs: KeyValuePair[]): string {
        let params = new HttpParams();
        pairs.forEach(p => {
            if (p.value !== undefined && p.value !== null) {
                params = params.set(p.key, p.value);
            }
        });
        return params && params.keys().length > 0 ?
            '?' + params.toString()
            : '';
    }

    private getNumbersOnlyString(str: string): string {
        return str.replace(/\D/g, '');
    }
}
