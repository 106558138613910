<div class="d-flex flex-row flex-nowrap align-items-center pointer">
  <div
    class="d-flex bi bi-geo-alt"
    [ngStyle]="{ color: (app$ | async).navbarSettings?.icon_color }"></div>

  <div
    *ngIf="order; else noorder"
    class="flex-column w-100 m-0 d-flex justify-content-center align-items-start ps-2"
    [ngStyle]="{ color: (app$ | async).navbarSettings?.text_color }">
    <div class="text-nowrap">
      <span
        class="fw-bold font-size-body-small align-middle text-truncate">
        <ng-container [ngSwitch]="order.handoffType">
          <div
            *ngSwitchCase="handoffs.pickup"
            title="{{ order.location.name }}">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 20
            }}
          </div>
          <div
            *ngSwitchCase="handoffs.curbside"
            title="{{ order.location.name }}">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 20
            }}
          </div>
          <div
            *ngSwitchCase="handoffs.driveThru"
            title="{{ order.location.name }}">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 20
            }}
          </div>
          <div *ngSwitchDefault title="{{ order.deliveryAddress?.address1 }}">
            {{
              order.deliveryAddress?.address1
                | truncate: 20
            }}
          </div>
        </ng-container>
      </span>
    </div>
  </div>

  <ng-template #noorder>
    <div class="w-100 my-auto ps-2 d-flex align-items-center">
      <span
        class="fw-bold font-size-body-small d-inline-block align-middle text-truncate">
        Start New Order
      </span>
    </div>
  </ng-template>

  <div
    class="d-flex bi bi-chevron-down text-center ps-2"
    [ngStyle]="{ color: (app$ | async).navbarSettings?.icon_color }"></div>
</div>
