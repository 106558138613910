import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/interfaces/category.interface';
import { Product } from 'src/interfaces/product.interface';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { HandoffTypeService } from '@modules/cart/services/handoff-type.service';
import { ToastService } from '../../../../../services/toast.service';
import { Select, Store } from '@ngxs/store';
import { SetLoading, SetRouteBack, SwitchMenuView, UpdateTitle } from '../../../../../store/actions/app.actions';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { UtilityService } from '@modules/utility/services/utility.service';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { ThemeColor } from '../../../../../vendors/directus/interfaces/theme-color.interface';
import { MetaService } from '../../../../../services/meta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '@modules/navigation/services';
import { ModeService } from '../../../../../services/mode.service';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { MenuService } from '@modules/menu/services';
import { CartService } from '@modules/cart/services';
import { SubscriptionComponent, UserRoles } from '@common/components';
import { SentryService } from '@common/services';
import { PromotionalContent } from '../../../../../vendors/directus/interfaces/promotional-content.interface';
import { GlobalStateModel } from '../../../../../store/state.model';
import { GroupOrder } from '../../../../../interfaces/group-order.interface';
import { Breadcrumb } from 'src/modules/menu/components/menu-breadcrumbs/menu-breadcrumbs.component';

@Component({
  selector: 'app-category',
  template: '',
})
export class CategoryComponent extends SubscriptionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.menu.selectedCategory) category$: Observable<Category>;
  @Select((state: GlobalStateModel) => state.order.order) order$: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.menu.menu) menu$: Observable<Menu>;
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;
  @Select((state: GlobalStateModel) => state.app.theme) themeColors$: Observable<ThemeColor[]>;
  @Select((state: GlobalStateModel) => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select((state: GlobalStateModel) => state.app.branding) branding$: Observable<Branding>;
  @Select((state: GlobalStateModel) => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.menu.allProductsAlwaysAvailable) allProductsAlwaysAvailable$: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) settings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.menuView) menuView$: Observable<string>;

  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;
  category: Category;
  // Page details for SEO
  title = 'Loading Menu...';
  // Display variables for view
  displayProducts: Product[];
  displayOrder: Order;
  displayUser: User;
  displayMenu: Menu;
  displayBranding: Branding;
  isLoading = false;
  hidePrice = true;
  page;
  catPromotionalImage: any;
  catPromotionalImageMobile: any;
  catPromotionalText: SafeHtml;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  gridColSize = null;
  singleColCard = false;
  showMenuDisplayToggle = false;
  productLoading;
  skeletons = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  descriptionText = 'Loading description…';
  showNutrition = false;
  roles = UserRoles;
  breadcrumbs: Breadcrumb[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService,
    private directus: DirectusService,
    private sanitizer: DomSanitizer,
    private handoffTypeService: HandoffTypeService,
    private toast: ToastService,
    private store: Store,
    private utility: UtilityService,
    private meta: MetaService,
    public modalService: NgbModal,
    protected mode: ModeService,
    public menuService: MenuService,
    public cartService: CartService,
    private sentry: SentryService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.gridCheck();
    });
    this.subs.push(
      this.route.params.subscribe((pathParams: any) => {
        this.menuService.checkMenuParameters(pathParams);
      }),
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
          this.menuService.restaurantID = order.location.locationID;
          this.productLoading = '';
          this.menuService.selectedHandoffType = order.handoffType;
        }
      }),
      this.user$.subscribe(user => {
        this.displayUser = user ? user : null;
      }),
      this.menu$.subscribe(menu => {
        if (menu) {
          this.displayMenu = menu;
        }
      }),
      this.category$.subscribe(category => {
        if (category && category.products) {
          this.category = category;
          this.title = category.name;
          this.store.dispatch(new UpdateTitle(this.title));
          this.store.dispatch(new SetRouteBack('/'.concat(this.navigation.getMenuPageSlug())));
          this.displayProducts = this.category.products;
          this.descriptionText = this.category.description ? this.category.description : '';
          this.sentry.setTransactionName(category.name);
          this.meta.manualUpdate({
            title: category.name,
            description: category.seoDescription,
            keywords: null,
          });
          setTimeout(() => {
            (window as any).prerenderReady = true;
          }, 1000);
          this.gridCheck();

          this.breadcrumbs = [
            { label: 'Menu', url: '/menu' },
            { label: category.name }
          ];
        }
      }),
      this.navbarSettings$.subscribe(navbar => {
        if (navbar) {
          this.hidePrice = !navbar.show_price_in_cart;
        }
      }),
      this.promotionalContent$.pipe(filter(p => !!p)).subscribe(pages => {
        this.page = pages[0];
        if (!this.page?.category_card_background && !this.page?.category_card_background_mobile) {
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.catPromotionalImage = this.page?.category_card_background?.data.full_url;
          this.catPromotionalImageMobile = this.page?.category_card_background_mobile?.data.full_url;
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.category_card_promotion);
        }
      }),
      this.mainSettings$.pipe(filter(p => p !== null)).subscribe(settings => {
        this.showNutrition = settings.show_nutrition_key;
      }),
      this.themeColors$.pipe(filter(p => p !== null)).subscribe(colors => {
        this.showMenuDisplayToggle = colors[0].menu_display_toggle ? colors[0].menu_display_toggle : this.showMenuDisplayToggle;
      }),
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
  }

  modifyClicked(product: Product, modifyModalRef: TemplateRef<any>) {
    // isEdit if there is a basketProdID
    this.cartService.basketProdID = '';
    this.menuService.modifyClicked(product, modifyModalRef);
  }

  // Gridcheck for promotional cards
  gridCheck() {
    const width = window.innerWidth;
    if (this.displayProducts) {
      const catCount = this.displayProducts.length;
      if (width < 992) {
        this.gridColSize = 12;
        this.singleColCard = true;
      } else if (992 <= width && width < 1200) {
        this.singleColCard = false;
        const num = catCount / 2;
        if (!Number.isInteger(num)) {
          this.gridColSize = 6;
        } else {
          this.gridColSize = null;
        }
      } else if (width >= 1200) {
        this.singleColCard = false;
        const num = catCount / 4;
        if (Number.isInteger(num)) {
          this.gridColSize = null;
        } else if (num.toString().split('.')[1] === '25') {
          this.gridColSize = 9;
        } else if (num.toString().split('.')[1] === '5') {
          this.gridColSize = 6;
        } else if (num.toString().split('.')[1] === '75') {
          this.gridColSize = 3;
        }
      }
    }
  }

  updateMenuDisplay(display: 'list' | 'card') {
    this.store.dispatch(new SwitchMenuView(display));
  }

  backClicked() {
    this.navigation
      .navigateToMenuPage(this.menuService.restaurantSlug ? this.menuService.restaurantSlug : this.menuService.restaurantID)
      .then();
  }

  clickCart() {
    document.getElementById('cartButton').click();
  }

  scrollToTop(element: HTMLElement) {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  productTrackBy(index: number, product: Product): string {
    return `${index}${product.name}${product.productID}`;
  }
}
