<ng-container *ngIf="theme$ | async as theme">
  <div class="h-100 w-100 d-flex flex-column justify-content-center grow-1 bg-white"
       [ngStyle]="{backgroundImage:'url(' + theme[0]?.background_image?.data?.full_url + ')'}"
       [class.downloading-background]="theme[0]?.background_image?.data?.full_url">
    <ng-container *ngIf="branding$ | async as branding">
      <div class="px-3 py-5 m-4 bg-white cms-card-radius"
           [class.de-border]="theme[0]?.background_image?.data?.full_url"
           [class.downloading-card]="theme[0]?.background_image?.data?.full_url">
        <div class="d-flex flex-column gap-5 justify-content-center align-items-center">
          <img class="h-auto w-100" [src]="branding.main_logo.data.full_url" alt="Main Logo" style="max-width: 200px; object-fit: contain; max-height: 140px;">
          <!--      <div class="header-font" style="font-size: 32px">{{(receivedAmount/totalAmount) | percent}}</div>-->
          <div class="updating">{{title}}</div>
          <ngb-progressbar type="primary" class="w-100 mx-3" [value]="(receivedAmount/totalAmount) * 100" [striped]="true"
                           [animated]="true">
            <i>{{(receivedAmount / totalAmount) | percent}}</i></ngb-progressbar>
          <div>App may restart after installing update.</div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
