<div class="mobile-modal-header-back">
    <button class="back-button" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
    </button>
    <div class="header-title fw-bold header-font">Banked Rewards Dollars</div>
</div>

<ion-content class="mobile-modal-content">

    <div class="modal-body">

        <!--  Use default icon from prototype, will be dynamic from CMS-->
        <!--  <img class="purchase-rewards-icon" src="http://placehold.it/" alt="">-->

        <div class="banked-dollars-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
            </svg>
        </div>


        <h4 class="banked-dollars-title header-font">
            Banked Rewards Dollars
        </h4>

        <ng-container *ngIf="rewardPoints$ | async as balances">
            <ng-container *ngIf="balances.bankedRewards && displayVendorSetup?.loyalty_provider === 'punchh-olo'">
                <h2 class="balance-amount header-font">
                    Balance: {{balances.bankedRewards | currency: (currencyCode$ | async)}}
                </h2>
            </ng-container>
        </ng-container>

        <form (ngSubmit)="redeemClicked()" [formGroup]="dollarConversion"
              style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
            <div class="form-group m-0 p-0 w-100">
                <label for="dollarAmount">Enter Dollar Amount</label>
                <input type="text" id="dollarAmount" formControlName="dollarAmount" class="form-control de-input m-0"
                       [dropSpecialCharacters]="false"
                       [specialCharacters]="['.']" mask="0*.00" placeholder="$0.00" inputmode="decimal">
            </div>
            <ng-container *ngIf="dollarAmount.invalid && (dollarAmount.dirty || dollarAmount.touched)">
                <small class="text-danger" *ngIf="dollarAmount.errors.required">Amount Required</small>
            </ng-container>
            <button class="de-primary-btn w-100" (click)="redeemClicked()">Convert to Reward</button>
        </form>

        <div class="create-reward-info">
            <h5>
                How to Create a Dollar Reward
            </h5>

            <p>
                Choose how many banked rewards dollars you would like to convert into a reward.
            </p>

            <p>
                When you create a reward, your selected amount of dollars will be deducted from your dollar count and
                turned into a redeemable reward.
            </p>

            <p>
                To redeem this new reward, please check the rewards section.
            </p>
        </div>

        <ng-container *ngIf="rewardPoints$ | async as balances">
            <ng-container *ngIf="balances.bankedRewards && displayVendorSetup?.loyalty_provider === 'punchh-olo'">
                <ng-container *ngFor="let reward of displayRewardsDollars">
                    <app-reward-card (clickedRedeem)="redeemedDollars(reward)" [canApply]="!areRewardsSelecting"
                                     [currencyCode]="currencyCode$ | async" [reward]="reward">
                    </app-reward-card>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
</ion-content>
