import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-location-info-display',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './location-info-display.component.html',
    styleUrls: ['location-info-display.component.scss'],
})
export class LocationInfoDisplayComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
