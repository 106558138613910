import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {NavigationService} from '@modules/navigation/services';
import {Store} from '@ngxs/store';
import {filter, map, take} from 'rxjs/operators';
import {VendorSetup} from '../../../interfaces/vendor.interface';

@Injectable()
export class ProviderGuard  {
  constructor(
    private router: Router,
    private navigation: NavigationService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(appState => appState.app.vendorSetup)
      .pipe(filter(vs => !!vs), take(1), map((vendorSetup: VendorSetup) => {
        if (
          (vendorSetup.order_provider === 'none' && (state.url === '/menu')) ||
          (vendorSetup.order_provider === 'none' && (state.url === '/cart')) ||
          (vendorSetup.order_provider === 'none' && (state.url === '/checkout/details')) ||
          (vendorSetup.order_provider === 'none' && (state.url === '/confirmation'))
        ) {
          this.navigation.navigateToHomePage();
          return false;
        }
        if (vendorSetup.location_provider === 'none' && (state.url === '/locations')) {
          this.navigation.navigateToHomePage();
          return false;
        }
        if (vendorSetup.menu_provider === 'none' && (state.url === '/menu')) {
          this.navigation.navigateToHomePage();
          return false;
        }

        return true;
      }));
  }
}
