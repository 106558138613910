import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Features } from '../../../../vendors/directus/interfaces/features.interface';
import { UtilityService } from '@modules/utility/services';

@Component({
  selector: 'app-featured-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './featured-card.component.html',
  styleUrls: ['featured-card.component.scss'],
})
export class FeaturedCardComponent {
  @Input() item: Features;
  constructor(private utilityService: UtilityService) {}

  buttonClick(link: string) {
    this.utilityService.handleDynamicLink(link);
  }
}
