<ion-row class="menu-controls">

  <!-- REMOVE FILTER AND SORT ABILITY FOR NOW -->

  <!--
    <ion-col size="5" [ngClass]="selectedDropdown === 'filter' ? 'selected-item' : 'nonselected-item'" class="filter-text" (click)="filterItemClicked('filter')">
      Filter
      <i class="bi bi-filter"></i>
    </ion-col>
    <ion-col class="control-item filter-text" [ngClass]="selectedDropdown === 'sort' ? 'selected-item' : 'nonselected-item'" size="5" (click)="filterItemClicked('sort')">
      Sort
      <i class="bi bi-chevron-down"></i>
    </ion-col>
  -->
    <ion-col class="ion-no-padding m-0" size="12" [ngClass]="selectedDropdown === 'gridToggle' ? 'selected-item' : 'nonselected-item'">
      <ion-row>
        <ion-col tabindex="0" class="border-end gap-2 d-flex flex-row align-items-center justify-content-center flex-nowrap" [ngClass]="{'non-selected': gridView !== 'card'}" (click)="gridViewToggle('card')" (keydown.enter)="gridViewToggle('card')">
          <span class="view-text text-uppercase">Grid</span>
          <i class="bi bi-grid d-flex"></i>
        </ion-col>
        <ion-col tabindex="0" class="gap-2 d-flex flex-row align-items-center justify-content-center flex-nowrap" [ngClass]="{'non-selected': gridView !== 'list'}" (click)="gridViewToggle('list')" (keydown.enter)="gridViewToggle('list')">
          <span class="view-text text-uppercase">List</span>
          <i class="bi bi-view-list d-flex"></i>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>

  <div *ngIf="selectedDropdown" class="filter-options">

  </div>
