import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionStack, OptionSubGroup} from '@modules/menu/components';
import {Option} from '../../../../interfaces/option.interface';
import {OptionGroup} from '../../../../interfaces/option-group.interface';

@Component({
  selector: 'app-half-and-half-selector',
  templateUrl: './half-and-half-selector.component.html',
  styleUrls: ['half-and-half-selector.component.scss'],
})
export class HalfAndHalfSelectorComponent implements OnInit {
  @Input() optionGroup: OptionGroup;
  @Input() optionSubGroup: OptionSubGroup;
  @Input() selectedOptionIndex: number;
  @Output() optionClick = new EventEmitter<Option>();
  @Output() subOptionClick = new EventEmitter<OptionStack>();
  selectedOption: Option;
  selectedSubOption: Option;
  ngOnInit() {
    if (this.selectedOptionIndex !== undefined) {
      this.selectedOption = this.optionGroup.options[this.selectedOptionIndex];
    } else {
      this.selectedOption = this.optionGroup.options[1];
      this.optionClicked(this.selectedOption);
    }
  }
  optionClicked(option: Option) {
    this.selectedOption = option;
    if (option) {
      this.optionClick.emit(option);
    }
  }
  subOptionClicked(option: Option) {
    this.selectedSubOption = option;
    if (option) {
      this.subOptionClick.emit({
        option,
        optionGroup: this.optionGroup,
        subOptionGroup: this.optionSubGroup
      });
    }
  }
}
