import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['subscription.component.scss'],
})
export class SubscriptionComponent implements OnDestroy {
  public subs: Subscription[] = [];
  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }
}
