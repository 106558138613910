<div id="configurationModal" aria-labelledby="configurationModalLabel">
  <div class="modal-header">
    <h4 class="h3 modal-title" id="configurationModalLabel" style="font-weight: bold;">Configuration</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle"
           viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </button>
  </div>
  <div class="h5 modal-body">
    <ng-container *ngFor="let config of kioskConfigs">
      <div class="fg-1 fb-0 align-self-stretch">
        <div class="d-flex flex-row justify-content-between align-items-center p-2 py-3 mb-2 w-100 btn outline-primary config-item">
          <div class="h5 fw-bold text-start px-3">
            {{ config.kiosk_id }}
          </div>
          <div class="d-flex flex-row gap-3">
            <button (click)="configureKioskClicked(config)" class="config-button ki-buttons">
              <i class="bi bi-gear"></i>
            </button>
            <button
              (click)="configurePinPadClicked()" class="config-button ki-buttons d-flex align-items-center justify-content-center">
              <i *ngIf="!configurePinPadLoading || config.device_address !== pinPad.pinPadMACAddress" class="bi bi-cloud-download"></i>
              <div *ngIf="configurePinPadLoading && config.device_address === pinPad.pinPadMACAddress" class="de-md-loader loader-spacing"></div>
            </button>
            <button
              (click)="resetPinPadClicked()" class="config-button ki-buttons d-flex align-items-center justify-content-center">
              <i *ngIf="!resetPinPadLoading || config.device_address !== pinPad.pinPadMACAddress" class="bi bi-arrow-clockwise"></i>
              <div *ngIf="resetPinPadLoading && config.device_address === pinPad.pinPadMACAddress" class="de-md-loader loader-spacing"></div>
            </button>
            <button
              (click)="cancelTransactionClicked()" class="config-button ki-buttons d-flex align-items-center justify-content-center">
              <i *ngIf="!cancelTransactionLoading || config.device_address !== pinPad.pinPadMACAddress" class="bi bi-bag-x"></i>
              <div *ngIf="cancelTransactionLoading && config.device_address === pinPad.pinPadMACAddress" class="de-md-loader loader-spacing"></div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer justify-content-start">
    <ng-container *ngIf="mainSettings$ | async as mainSettings">
      <div class="d-flex flex-row flex-nowrap gap-2 flex-grow-1">
        <img
              [ngClass]="
              mainSettings.powered_by_logo ? 'footer-logo' : 'de-footer-logo'
            "
              [src]="
              mainSettings.powered_by_logo
                ? mainSettings.powered_by_logo.data.full_url
                : 'assets/images/de-logomark-red-dark.svg'
            "
              alt="Powered by {{
              mainSettings.powered_by_name
                ? mainSettings.powered_by_name
                : 'DineEngine'
            }}"/>
        <div class="w-100 d-flex text-nowrap text-dark">
          Version: {{ version }}
        </div>
      </div>
    </ng-container>
    <button type="button" class="btn ki-buttons" (click)="resetKioskClicked()">Restart</button>
  </div>
</div>
