<ng-container *ngIf="navbarSettings$ | async as navbarSettings">
<div class="navigation-anchor" [id]="category.nameSlug"></div>

<div class="category">

    <div class="category-header">
        <div class="category-info">
            <h4 class="category-title header-font">
                {{ category.name }}
            </h4>

            <div class="category-details">
                <div class="category-product-amount">
                    ({{ category.products.length }} Products)
                </div>
                <a class="category-view-all dark-link" (click)="selectCategory()">
                    View All
                </a>
            </div>

            <ng-container *ngIf="category.description !== ''">
                <div class="category-description">
                    {{ category.description }}
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="!navbarSettings.fixed_navbar_width">
        <div class="category-container">
            <ng-container *ngIf="(user$ | async) as user">
                <ng-container
                        *ngFor="let product of category.products | secretProducts: (user.isGuest ? roles.guest : roles.all); trackBy: productTrackBy">
                    <app-product-card
                            [productID]="product.productID"
                            [productDetails]="product"
                            [category]="category"
                            [modifyModalRef]="modifyModalRef"
                    ></app-product-card>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="navbarSettings.fixed_navbar_width">
        <div class="category-container-fixed">
            <ng-container *ngIf="(user$ | async) as user">
                <ng-container
                        *ngFor="let product of category.products | secretProducts: (user.isGuest ? roles.guest : roles.all); trackBy: productTrackBy">
                    <app-product-card
                            [productID]="product.productID"
                            [productDetails]="product"
                            [category]="category"
                            [modifyModalRef]="modifyModalRef"
                    ></app-product-card>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

</div>
</ng-container>
