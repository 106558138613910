import { Injectable, Injector } from '@angular/core';
import { GiftCardPurchaseProvider } from '../../providers/gift-card-purchase-provider.interface';
import { CmsVendorSetupService } from './cms-vendor-setup.service';
import { Observable, of } from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {Select} from '@ngxs/store';
import {VendorSetup} from '../../interfaces/vendor.interface';
import { PaytronixProviderService } from 'src/vendors/paytronix/paytronix-provider.service';

@Injectable({
    providedIn: 'root'
})
export class GiftCardPurchaseService {

    @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

    private currentService: GiftCardPurchaseProvider;

    constructor(
        private vendorService: CmsVendorSetupService,
        private injector: Injector
    ) {}

    getService(): Observable<GiftCardPurchaseProvider> {
        if (this.currentService) {
            return of(this.currentService);
        } else {
            return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
                return this.chooseService(vs.gift_card_purchase_provider);
            }));
            // return this.vendorService.getService().pipe(switchMap(service => {
            //     return service.getVendorSetup().pipe(map(setup => {
            //         return this.chooseService(setup.gift_card_provider);
            //     }));
            // }));
        }
    }

    private chooseService(vendor: string): GiftCardPurchaseProvider {
        switch (vendor) {
            default: return this.currentService = this.injector.get(PaytronixProviderService);
            // case ProviderConfig.paytronix: return this.currentService = this.injector.get(PaytronixProviderService);
        }
    }
}
