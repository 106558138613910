import { Pipe, PipeTransform } from '@angular/core';
import {Product} from '../interfaces/product.interface';
import {Category} from '../interfaces/category.interface';
import {MenuService} from '@modules/menu/services';

@Pipe({
  name: 'categoryFromProduct'
})
export class CategoryFromProductPipe implements PipeTransform {

  constructor(
      private menu: MenuService
  ) {}
  transform(product: Product): Category {
    return this.menu.getCategoryFromProduct(product);
  }

}
