import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBrandNameFromLocation',
})
export class FilterBrandNameFromLocationPipe implements PipeTransform {
  transform(value: string, filterTags: string[]): string {
    if (!filterTags || !value) {
      return value;
    }

    const sortedFilterTags = [...filterTags].sort((a, b) => b.length - a.length);

    let newName = value;
    sortedFilterTags.forEach((tag: string) => {
      const escapedTag = this.escapeRegExp(tag);
      const regex = new RegExp(escapedTag, 'gi');
      newName = newName.replace(regex, '');
    });

    return newName.trim();
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
