import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  // Sets the brochure view changing breakpoint (this could be modified to have multiple e.g. match bootstrap or ionic)
  mobileBreakpoint = 991;

  // Sets a flag based on the the breakpoint above
  isMobile: boolean;

  /**
   * Constructor for the mobile service
   * @constructor
   */
  constructor() {
    // Sets the mobile breakpoint as a CSS property variable
    document.documentElement.style.setProperty('--mobile-breakpoint', this.mobileBreakpoint.toString(10) + 'px');
    // Set breakpoint on initialization
    this.mobileCheck();

    // Adds an event listener to the resize window event to help trigger value changes
    window.addEventListener('resize', event => {
      this.mobileCheck();
    });
  }

  /**
   * Checks the window width against the break point to determine which views to use (mobile or desktop)
   */
  mobileCheck() {
    // Grab the inner width of the window
    const width = window.innerWidth;

    // Sets the flag based on the breakpoint set above
    this.isMobile = width <= this.mobileBreakpoint || Capacitor.getPlatform() !== 'web';
  }
}
