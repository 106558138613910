<div
        appKeyboardHide
        class="desktop-header"
        *ngIf="showNavbar || this.width <= 1100">
    <div
            class="de-nav-shadow p-0 d-flex"
            style="
      background-color: var(--ion-color-secondary);
      height: 40px;
      position: relative;
    ">
        <ng-container *ngIf="routeBack !== ''">
            <a type="button" tabindex="0"
               (click)="backButton()"
               (keydown.enter)="backButton()"
               class="ms-3 text-decoration-none">
                <i class="menu-back-btn sub-nav-btn bi bi-arrow-left-short m-0"></i>
            </a>
        </ng-container>

        <a type="button"
           tabindex="0"
           (click)="this.menu.open()"
           (keydown.enter)="this.menu.open()"
           class="ms-auto me-3 text-decoration-none">
            <i class="sub-nav-btn ham-menu-btn bi bi-list m-0"></i>
        </a>
    </div>
</div>
