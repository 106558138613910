import {Pipe, PipeTransform} from '@angular/core';
import {OptionGroup} from '../interfaces/option-group.interface';
import {OrderItemModifier} from '../interfaces/option.interface';

@Pipe({name: 'optionsList'})
export class OptionsListPipe implements PipeTransform {
  options = [];

  transform(value: OptionGroup[] | OrderItemModifier[]): string {
    value.forEach(op => {
      if (op.hasOwnProperty('options')) {
        this.addToGroup(op);
      } else {
        this.options.push(op.name);
      }
    });
    return this.options.join(', ');
  }

  addToGroup(og: OptionGroup) {
    og.options.forEach(option => {
      this.options.push(option.name);
      if (option.optionGroups && option.optionGroups.length > 0) {
        option.optionGroups.forEach(op => this.addToGroup(op));
      }
    });
  }
}
