import { Pipe, PipeTransform } from '@angular/core';
import {InboxMessage} from '../interfaces/inbox-message.interface';

@Pipe({
  name: 'unreadMessage',
  pure: false,
})
export class UnreadMessagePipe implements PipeTransform {

  transform(messages: InboxMessage[]): number {
    // return number of unread messages
    if (!messages) {
      return 0;
    }
    return messages?.filter(message => !message.read).length;
  }

}
