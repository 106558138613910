<div [@fadeInOut] class="card">

  Make: {{ vehicle.make }}<br />
  Model: {{ vehicle.model }}<br />
  Vehicle Color: {{ vehicle.color }}<br />
 <!--  License Plate Number: {{ vehicle.license }}<br /> -->
  <br />

  <button type="button" class="de-primary-btn w-100" (click)='deleteVehicleClicked()'>
    Delete Vehicle
  </button>

</div>
