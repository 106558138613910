<ng-container *ngIf="!noTiers">
  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="'standard'">
      <div
        style="
          background-color: var(--ion-color-dark);
          color: var(--ion-color-light);
          padding: 1rem;">
          <ng-container *ngTemplateOutlet="loyaltyTierContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'mobileCard'">
      <div class="d-flex justify-content-center">
        <div
          class="rounded position-relative d-flex flex-column w-100"
          style="
          max-width: 400px;
        ">
          <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
            <div
              (click)="mobileAppSettings?.loyalty_program_details && openLoyaltyInfo()"
              class="p-3 rounded border d-flex flex-column position-relative"
              style="color: var(--ion-color-dark-contrast); box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    background-image: linear-gradient(to bottom right, var(--ion-color-dark-tint), var(--ion-color-dark)); min-height: 220px;">
              <ng-container *ngTemplateOutlet="mobileCard"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="rewardPoints$ | async as balances">
            <div class="d-flex flex-row flex-nowrap ion-align-items-center p-3 mx-3 rounded-bottom border-bottom border-start border-end" style="box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .15) !important;">
              <app-loyalty-points-balance-info [balances]="balances"></app-loyalty-points-balance-info>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'compact'">
      <div class="pt-1">
        <ng-container *ngTemplateOutlet="loyaltyTierContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'desktop'">
      <div
        style="color: var(--ion-color-light)"
        class="pt-1 d-flex flex-column align-items-end text-end gap-1">
        <ng-container *ngTemplateOutlet="loyaltyTierContent"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'responsive'">
      <ng-container *ngTemplateOutlet="responsiveMobile"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #mobileCard>
  <ng-container *ngIf="rewardPoints$ | async as pointsBalance">
    <ng-container *ngIf="textFields$ | async as textField">
      <ng-container *ngIf="!noTiers">
        <small class="fw-bold mb-1 badge rounded-pill w-fit-content" style="
            background-color: var(--ion-color-primary);
            color: var(--ion-color-primary-txt);
          ">
            {{ titleFieldCopy }}
        </small>
        <h1 class="fw-bold mb-1">
          {{ currentTierLabel }}
        </h1>
        <ng-container *ngIf="displayUser && displayUser.cardNumber">
          <small class="text-white">#{{ displayUser.cardNumber }}</small>
        </ng-container>
        <div class="text-lowercase mt-auto pb-1">
          {{ currentTierValue }} / {{ nextTierLimit }} {{ tierValueType }}
        </div>
        <ng-container *ngIf="!lastTier">
          <ngb-progressbar
            *ngIf="layout !== 'standard'"
            type="primary"
            class="w-100"
            [value]="currentTierValue"
            [max]="nextTierLimit"
            [striped]="false"
            [animated]="true"></ngb-progressbar>
          <div class="py-1 f-12">
            {{ nextTierLimit - currentTierValue }} More
            <span class="text-lowercase">{{ tierValueType }}</span> until
            <span class="fw-bold">{{ nextTierLabel }}</span> Unlocked
          </div>
        </ng-container>
        <ng-container *ngIf="layout === 'standard'">
          <ngb-progressbar
            type="primary"
            class="w-100"
            [value]="(currentTierValue / nextTierLimit) * 100"
            [striped]="false"
            [animated]="true"></ngb-progressbar>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loyaltyTierContent>
  <ng-container *ngIf="rewardPoints$ | async as pointsBalance">
    <ng-container *ngIf="textFields$ | async as textField">
      <ng-container *ngIf="!noTiers">
        <div class="d-flex flex-row">
          <span class="grow-1 fw-bold f-12 text-uppercase">
            {{ titleFieldCopy }}
          </span>
          <ng-container *ngIf="layout === 'standard'">
            <ng-container *ngIf="(mobileAppSettings$ | async)?.loyalty_program_details">
              <div
                   class="fw-bold f-14 link-underline d-flex flex-row"
                   (click)="openLoyaltyInfo()">
                <button
                  type="button"
                  class="p-0"
                  style="
                  background-color: var(--ion-color-dark);
                  color: var(--ion-color-light);
                ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-info-circle"
                    viewBox="0 0 16 16">
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </button>
                <div class="ps-1">{{ tierDetailsCopy }}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div
          class="badge rounded-pill"
          style="
            background-color: var(--ion-color-primary);
            color: var(--ion-color-primary-txt);
          ">
          {{ currentTierLabel }}
        </div>
        <div>
          <span style="font-size: 24px; font-weight: bold"
            >{{ currentTierValue }} / </span
          ><span
            class="text-lowercase"
            [ngStyle]="
              layout !== 'desktop'
                ? { color: 'var(--ion-color-primary)' }
                : { color: 'var(--ion-color-light)' }
            "
            >{{ nextTierLimit }} {{ tierValueType }}</span
          >
        </div>
        <ng-container *ngIf="layout === 'desktop'">
          <ng-container *ngIf="(mobileAppSettings$ | async)?.loyalty_program_details">
            <div
              class="fw-bold f-14 link-underline pt-1 pb-3"
              (click)="openLoyaltyInfo()">
              <button
                type="button"
                class="ps-0"
                style="
                background-color: transparent;
                color: var(--ion-color-light);
              ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </button>
              {{ tierDetailsCopy }}
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!lastTier">
          <ngb-progressbar
            *ngIf="layout !== 'standard'"
            type="primary"
            class="w-100"
            [value]="currentTierValue"
            [max]="nextTierLimit"
            [striped]="false"
            [animated]="true"></ngb-progressbar>
          <div class="pb-1 f-12">
            {{ nextTierLimit - currentTierValue }} More
            <span class="text-lowercase">{{ tierValueType }}</span> until
            <span class="fw-bold">{{ nextTierLabel }}</span> Unlocked
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="layout === 'standard' && !lastTier">
        <ngb-progressbar
          type="primary"
          class="w-100"
          [value]="(currentTierValue / nextTierLimit) * 100"
          [striped]="false"
          [animated]="true"></ngb-progressbar>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #responsiveMobile>
  <div
    class="h-100 px-3 pb-3 pt-0"
    style="
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;
    ">
    <div style="grid-column: 1 / 2; grid-row: 1 / 2" class="pe-2">
      <div class="grow-1 fw-bold f-12 text-uppercase text-white">
        {{ titleFieldCopy }}
      </div>
      <div
        class="badge rounded-pill"
        style="
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-txt);
        ">
        {{ currentTierLabel }}
      </div>
    </div>
    <div style="grid-column: 1 / 3; grid-row: 2 / 3">
      <div>
        <span class="text-white" style="font-size: 24px; font-weight: bold"
          >{{ currentTierValue }} / </span
        ><span
          class="text-lowercase fw-bold"
          [ngStyle]="
            layout !== 'desktop'
              ? { color: 'var(--ion-color-primary)' }
              : { color: 'var(--ion-color-light)' }
          "
          >{{ nextTierLimit }} {{ tierValueType }}</span
        >
      </div>
      <ng-container *ngIf="!lastTier">
        <ngb-progressbar
          type="primary"
          class="w-100"
          [value]="currentTierValue"
          [max]="nextTierLimit"
          [striped]="false"
          [animated]="true"></ngb-progressbar>
        <div class="pb-1 f-12 text-white mt-1">
          {{ nextTierLimit - currentTierValue }} More
          <span class="text-lowercase">{{ tierValueType }}</span> until
          <span class="fw-bold text-white">{{ nextTierLabel }}</span>
          Unlocked
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
