<div class="popup-body p-3" style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
  <div style="display: grid; width: 100%; place-items: center end">
    <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
      <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
  </div>
  <div class="header-font modal-title">Warning</div>
  <div class="modal-text ln-1">
    Your account will be deleted and all of your data, including rewards, account profile information will be lost. Please confirm you want to do this?
  </div>
  <button class="w-100 de-primary-btn" (click)="cancelClicked()">
    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
      <div class="">Cancel</div>
    </div>
  </button>
  <button class="de-secondary-btn w-100" (click)="deleteAccountClicked()">
      <ng-container *ngIf="!deleteAccountLoading">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <div class="">Delete Account</div>
        </div>
      </ng-container>
      <ng-container *ngIf="deleteAccountLoading">
        <div class="de-btn-loader-secondary"></div>
      </ng-container>
  </button>
</div>
