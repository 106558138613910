<ng-template #infoModal>
    <div class="p-3">{{category.description}}</div>
</ng-template>

<div tabindex="0" class="card-shell h-100 d-flex flex-column border overflow-hidden" (click)="categoryClicked()"
     (keydown.enter)="categoryClicked()">
    <!-- card top -->
    <div class="card-top d-flex w-100 h-auto bg-transparent border-bottom">
        <!-- inner shell -->
        <div class="d-flex flex-column justify-content-between w-100 h-100 position-relative">
            <!-- info button -->
            <button class="card-info-button bg-light border-end border-bottom d-flex align-items-center align-self-start"
                    (click)="openInfoModal($event)"
                    *ngIf="category.description">
                <i class="bi bi-info-circle" width="16" height="16"></i>
                <!-- <div class="fw-bold">
                  Info
                </div> -->
            </button>
            <!-- product image -->
            <img *ngIf="imageUrl" [alt]="category.name" [defaultImage]="'assets/img/600x600.png'"
                 [lazyLoad]="imageUrl" onerror="this.src='assets/img/600x600.png'" style="object-fit: cover">
            <div class="no-image-category" *ngIf="!imageUrl">
                <img class="image" src="../../../../../assets/img/600x600.png" [alt]="category.name">
                <div class="no-image-text header-font card-text-color">{{ category.name}}</div>
            </div>
        </div>
    </div>

    <!-- card bottom -->
    <ng-container *ngIf="mainSettings$ | async as mainSettings">
        <ng-container *ngIf="mainSettings.show_category_name">
            <div class="card-bottom d-flex w-100 align-items-start bg-white">
                <!-- inner shell -->
                <div class="card-bottom-inner-shell w-100 h-100 d-flex flex-wrap">
                    <!-- menu item info -->
                    <div class="d-flex flex-column h-auto w-100">
                        <ng-container
                                *ngIf="category?.badge_color && category?.badge_text_color && category?.badge_text">
                            <div class="badge rounded-pill w-fit-content mb-1"
                                 [ngStyle]="{'color': category?.badge_text_color ? category?.badge_text_color : null, 'background-color': category?.badge_color ? category?.badge_color : null }">
                                {{category?.badge_text}}
                            </div>
                        </ng-container>
                        <!-- menu item title -->
                        <div class="category-title w-100 fw-bold">
                            {{ category.name | truncate: calculateTruncate() }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
