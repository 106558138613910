<ng-container *ngIf="(customIcons$ | async) as customIcons">
  <ng-container *ngIf="customIconKey && customIcons[customIconKey] && customIcons[customIconKey].data; else defaultIcon">
    <img [width]="size" [style.min-width.px]="size" [style.min-height.px]="size" [height]="size" [alt]="altText" [src]="customIcons[customIconKey].data.full_url" />
  </ng-container>
</ng-container>

<ng-template #defaultIcon>
  <ng-container *ngIf="default; else defaultMissing">
    <div class="bi d-flex align-middle" [ngClass]="default" [style.font-size.px]="size" [style.color]="color"></div>
  </ng-container>
  <ng-template #defaultMissing>
    <img [width]="size" [height]="size" [style.min-width.px]="size" [style.min-height.px]="size" alt="DineEngine Icon" src="assets/icon/favicon.svg" />
  </ng-template>
</ng-template>
