<ng-container *ngIf="(groupOrder$ | async) as groupOrder">
  <!--  <app-route-modal-frame [title]="groupOrder.groupOrderOwner + '\'s Group'" (clickModalClose)="closePage()">-->
  <div [class.bg-light]="mode.mode === 'desktop'" class="h-100">
    <div class="container d-flex justify-content-center align-items-start p-0 h-100">
      <form action="" autocomplete="on" class="join-group-container mt-0 mt-sm-5 mb-0 mb-sm-5 justify-content-between">

        <div class="d-flex flex-column gap-4">
          <h2 class="header-font fw-bold">
            Join Group Order
          </h2>

          <ol type="1">
            <li>
              Please add all of your items prior to the order deadline.
            </li>
            <li>
              When you’ve finished adding your items, let your host know that your order is in.
            </li>
          </ol>
        </div>

        <div class="d-flex flex-column gap-3">

          <ng-container *ngIf="mode.mode !== 'tableside'">
            <div class="d-flex flex-column text-center">
              <h4 class="header-font fw-bold">
                Order Deadline
              </h4>

              <!--      deadline dynamic from order provider set by creator-->
              <h1 class="text-danger fw-bold">
                {{ groupOrder.groupOrderDeadline | date:"shortTime" }}
              </h1>
            </div>
          </ng-container>

          <ng-container *ngIf="groupOrder.groupOrderNote">
            <div class="d-flex flex-column align-items-center text-center">

              <h5 class="header-font fw-bold">
                Order Note:
              </h5>

              <!--      note dynamic from order provider set by creator-->
              <p>
                {{ groupOrder.groupOrderNote }}
              </p>
            </div>
          </ng-container>

        </div>

        <div class="d-flex flex-column gap-5">
          <ng-container *ngIf="!displayUser || displayUser?.isGuest">
            <div class="form-group" [formGroup]="nameForm">
              <!-- name will be used in cart to separate orders. The name will automatically apply, so no need to name products when customizing a product-->
              <label for="name">Enter Your Name (required)</label>
              <input type="text" class="form-control" id="name" name="name" formControlName="name"
                     placeholder="Enter First Name, Last Name">
            </div>
          </ng-container>

          <div class="d-flex justify-content-center mb-sm-3">
            <div class="w-100 px-4 pb-4 pb-sm-0">
              <button type="submit" class="de-primary-btn w-100"
                      (click)="joinGroupOrder()" [disabled]="name.invalid || loading">
                Join Group Order
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
  <!--  </app-route-modal-frame>-->
</ng-container>
