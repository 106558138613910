<ion-row class="popup-body">
  <ion-col class="modal-contents">
      <ion-row class="header-font modal-title">
        Oops, there was an issue.
      </ion-row>
      <ion-row class="modal-text ln-1">
        {{ error }}
      </ion-row>
      <ion-row class="modal-text ln-2">

      </ion-row>
      <ion-row class="modal-btns">
        <button type="button" class="de-primary-btn" (click)="activeModal.close('Close click')">Continue</button>
      </ion-row>
  </ion-col>
  <ion-row size="2">
    <ion-button expand="full" fill="clear" (click)="activeModal.close('Close click')">
      <div id="close" class="d-flex align-items-center">
        <i class="bi bi-x-circle font-size-h1 d-flex"></i>
      </div>
    </ion-button>
  </ion-row>
</ion-row>
