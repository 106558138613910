import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ClearOrder } from '../../../../store/actions/order.actions';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Order } from '../../../../interfaces/order.interface';
import { NavigationService } from '@modules/navigation/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardTerminalService } from '../../../../services/vendor-config-service/card-terminal.service';

@Component({
  selector: 'app-kiosk-start-over-modal',
  templateUrl: './kiosk-start-over-modal.component.html',
  styleUrls: ['./kiosk-start-over-modal.component.scss'],
})
export class KioskStartOverModalComponent {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Output() startOver = new EventEmitter<any>();

  order: Order;
  productCount = 0;

  constructor(private store: Store, private navigation: NavigationService, private modalService: NgbModal, private cardTerminal: CardTerminalService) {
  }

  startOrderOver() {
    this.order = this.store.selectSnapshot(state => state.order.order);
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.closeModal();
        this.clearCardReader();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.closeModal();
      this.startOver.emit();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private clearCardReader() {
    this.cardTerminal.getService().subscribe(terminal => {
      if (terminal) {
        terminal.cancelTransaction().subscribe({
          next: () => {
            terminal.resetPinPad().subscribe();
          },
          error: () => {
            terminal.resetPinPad().subscribe();
          },
        });
      }
    });
  }

}
