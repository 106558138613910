import { Injectable } from '@angular/core';

import { MobileService } from '../services/mobile.service';
import {NavigationService} from '@modules/navigation/services';

@Injectable()
export class MobileGuard  {
  constructor(
    private mobile: MobileService,
    private navigation: NavigationService,
  ) {}

  canActivate(): boolean {
    if (this.mobile.isMobile) {
      return true;
    } else {
      this.navigation.navigateToLocationsPage();
      return false;
    }
  }
}
