import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalController } from '@ionic/angular';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';
import { Select } from '@ngxs/store'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-rewards-balances',
  templateUrl: './rewards-balances.component.html',
  styleUrls: ['./rewards-balances.component.scss']
})
export class RewardsBalancesComponent {
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;

  @Input() balanceTotals: RewardsBalances;

  instructionsCopy = 'Place orders to earn more loyalty points and rewards. Rewards expire, so use them up!';

  constructor(
    public activeModal: NgbActiveModal,
    private modalController: ModalController
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

}
