import { Component, Input } from '@angular/core';
import { Order } from 'src/interfaces/order.interface';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-tableside-nav-order-type',
  templateUrl: './nav-order-type.component.html',
  styleUrls: ['./nav-order-type.component.scss']
})
export class TablesideNavOrderTypeComponent {

  @Input() order: Order;

  constructor(
    public mobile: MobileService
  ) { }

}
