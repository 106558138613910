import {Injectable} from '@angular/core';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';

@Injectable()
export class OnlineStatusService {
  networkStatus = true;
  networkStatus$: Subscription = Subscription.EMPTY;

  checkNetworkStatus() {
    if (Capacitor.getPlatform() === 'web') {
      this.networkStatus = navigator.onLine;
      this.networkStatus$ = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      )
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {
          this.networkStatus = status;
        });
    } else {
      Network.getStatus().then((connectionStatus) => {
        console.log(connectionStatus);
        this.networkStatus = connectionStatus.connected;
      });
      Network.addListener('networkStatusChange', connectionStatus => {
        console.log(connectionStatus);
        this.networkStatus = connectionStatus.connected;
      });
    }
  }
}
