import { Injectable } from '@angular/core';

import {NavigationService} from '@modules/navigation/services';
import {ModeService} from '../services/mode.service';

@Injectable()
export class TablesideHiddenGuard  {
  constructor(
    private mode: ModeService,
    private navigation: NavigationService,
  ) {}

  canActivate(): boolean {
    if (this.mode.mode !== 'tableside') {
      return true;
    } else {
      this.navigation.navigateToHomePage();
      return false;
    }
  }
}
