import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-drawer-wrapper',
  templateUrl: './drawer-wrapper.component.html',
  styleUrls: ['drawer-wrapper.component.scss'],
})
export class DrawerWrapperComponent implements OnInit, AfterViewInit {
  @Input() title = 'Drawer Title';
  @Input() set toggleCartSent(value: boolean) {
    this.toggleCart();
  }

  @ViewChild('drawerHandle', { static: false }) drawerHandle: ElementRef;

  // Drawer UI
  cartHeight = '62px';
  scrollHeight;

  lastTouch;
  scrolldirection;
  isShow = false;
  cartInnerHeight;
  isClosed = true;
  clickevents = false;

  constructor() {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.drawerHandle.nativeElement.addEventListener('touchstart', this.testFunc.bind(this));
    this.drawerHandle.nativeElement.addEventListener('touchmove', this.testFunc.bind(this));
    this.drawerHandle.nativeElement.addEventListener('touchend', this.testFunc.bind(this));
    const vh = window.innerHeight * 0.85;
    const toFixed = vh.toFixed(0);
    this.cartInnerHeight = toFixed;
    this.scrollHeight = Number(toFixed) - 62 - 130;
    this.toggleCart();
  }

  testFunc(event) {
    event.preventDefault();
    event.stopPropagation();
    const touch = event.touches[0] || event.changedTouches[0];
    // check the events
    if (event.type === 'touchstart') {
      // when user starts to scroll
    } else if (event.type === 'touchend') {
      // when user ends scroll
      if (this.scrolldirection) {
        if (this.scrolldirection === 'up') {
          this.cartHeight = this.cartInnerHeight + 'px';
          this.isClosed = false;
          this.clickevents = true;
        } else {
          this.cartHeight = '62px';
          this.isClosed = true;
          this.clickevents = false;
        }
        this.scrolldirection = null;
      } else {
        if (this.isClosed) {
          this.cartHeight = this.cartInnerHeight + 'px';
          this.isClosed = false;
          this.clickevents = true;
        } else {
          this.cartHeight = '62px';
          this.isClosed = true;
          this.clickevents = false;
        }
      }
      event.preventDefault();
      event.stopPropagation();
    } else if (event.type === 'touchmove') {
      if (this.lastTouch) {
        if (this.lastTouch > touch.pageY) {
          this.scrolldirection = 'up';
          this.lastTouch = touch.pageY;
        } else {
          this.scrolldirection = 'down';
          this.lastTouch = touch.pageY;
        }
      } else {
        this.lastTouch = touch.pageY;
      }
      if ((window.innerHeight - touch.pageY) < 62) {
        this.cartHeight = '62px';
      } else {
        this.cartHeight = (window.innerHeight - touch.pageY) + 'px';
      }
    }
    event.stopPropagation();
  }

  toggleCart() {
    if (this.cartHeight === '62px') {
      this.cartHeight = this.cartInnerHeight + 'px';
    } else {
      this.cartHeight = '62px';
    }
    this.isShow = !this.isShow;
  }
}
