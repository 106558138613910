import { Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../interfaces/user.interface';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';

@Component({
  selector: 'app-welcome-header',
  templateUrl: './welcome-header.component.html',
  styleUrls: ['welcome-header.component.scss'],
})
export class WelcomeHeaderComponent implements OnInit {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  constructor() {}
  ngOnInit() {}
}
