import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Reward } from 'src/interfaces/reward.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { TextField } from 'src/vendors/directus/interfaces/text-field.interface';
import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { User } from 'src/interfaces/user.interface';
import { Select, Store } from '@ngxs/store';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap } from 'rxjs/operators';
import { GlobalStateModel } from '../../../../store/state.model';
import { Router } from '@angular/router';
import { NavigationService } from '@modules/navigation/services';
import { LoyaltySection } from '../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
  selector: 'app-rewards-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './rewards-section.component.html',
  styleUrls: ['./rewards-section.component.scss'],
})
export class RewardsSectionComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.user.rewards) rewards$: Observable<Reward[]>;

  @Output() redeemReward = new EventEmitter<Reward>();
  @Output() redeemInStoreReward = new EventEmitter<Reward>();
  @Output() removeReward = new EventEmitter<Reward>();
  @Output() openRewardsBalances = new EventEmitter<void>();
  @Output() orderNow = new EventEmitter<void>();

  @Input() currencyCode: string;
  @Input() textFields: TextField;
  @Input() displayBalances: RewardsBalances;
  @Input() stagedReward: Reward;
  @Input() displayVendorSetup: VendorSetup;
  @Input() displayUser: User;
  @Input() isDashboard = false;
  @Input() section: LoyaltySection;

  rewardsRoute: string;
  locationsRoute: string;

  displayRewards$: Observable<Reward[]>;
  error = false;

  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.rewardsRoute = this.navigation.getRewardsPageSlug();
    this.locationsRoute = this.navigation.getLocationsPageSlug();

    this.displayRewards$ = this.rewards$.pipe(
      filter(rewards => !!rewards),
      switchMap(rewards => {
        if (rewards && rewards.length > 0) {
          return of(rewards);
        } else {
          return of([]);
        }
      }),
      catchError(() => {
        this.error = true;
        return of([]);
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  rewardTrackBy(index: number, item: Reward) {
    return item.rewardID;
  }
}
