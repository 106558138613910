import {ApplicationRef, Injectable, Injector, NgZone} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {PendingRequestsService} from '@app/services/pending-requests.service';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  private pendingRequestsService: PendingRequestsService;

  constructor(
      private injector: Injector,
  ) {
    this.pendingRequestsService = this.injector.get(PendingRequestsService);
  }

  filteredUrlPatterns = [
    new RegExp('/custom/dineengine/vendor/olo/baskets/.*/products'),
    new RegExp('/custom/dineengine/vendor/olo/users/.*/qualifyingrewards'),
    new RegExp(/\/custom\/dineengine\/vendor\/olo\/grouporders\/.*/g),
    new RegExp('/custom/dineengine/vendor/olo/restaurants/near.*'),
    new RegExp('/custom/dineengine/vendor/novadine/orders.*'),
    new RegExp('/custom/dineengine/vendor/novadine/orders/.*/items'),
      new RegExp('/custom/dineengine/vendor/olo/baskets/.*'),
      new RegExp('/custom/dineengine/vendor/olo/restaurants/.*'),
      new RegExp('/custom/dineengine/vendor/novadine/stores.*'),
      new RegExp('/custom/dineengine/vendor/olo/baskets/.*/validate'),
      new RegExp('/custom/dineengine/vendor/olo/baskets/.*/dispatchaddress'),
      new RegExp('/custom/dineengine/vendor/paytronix/sale/saleConfigForAccount.json'),
      new RegExp('/custom/dineengine/vendor/paytronix/guest/accountInformationById.json'),
      new RegExp('/custom/dineengine/vendor/olo/users/.*/contactdetails'),
    new RegExp('/custom/dineengine/vendor/olo/users/.*/contactoptions'),
    new RegExp('/custom/dineengine/vendor/olo/users/.*'),
new RegExp('/default/custom/dineengine/vendor/olo/baskets/.*'),
new RegExp('stripe.com/.*'),
      new RegExp('/custom/dineengine/vendor/punchh/message_readerships.*'),
      new RegExp('/custom/dineengine/vendor/punchh/users/balance.*'),


  ];

  paytronixSSOFilter = new RegExp('/custom/dineengine/vendor/paytronix/olo/requestOloGuestToken.json');
  oloDispatchAddressFilter = new RegExp('/custom/dineengine/vendor/olo/baskets/.*/dispatchaddress');

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if ((this.filteredUrlPatterns.every(pattern => !pattern.test(request.url))) || this.paytronixSSOFilter.test(request.url)) {
      return next.handle(request).pipe(tap((event) => {
            // if (event.type === HttpEventType.Response) {
            //   this.pendingRequestsService.removePendingRequest(request);
            // }
            if (event.type === HttpEventType.Sent) {
              this.pendingRequestsService.addPendingRequest(request);
            }
          }),
          // catchError((error: HttpErrorResponse) => {
          //   this.pendingRequestsService.removePendingRequest(request);
          //   return throwError(error);
          // }),
          finalize(() => this.pendingRequestsService.removePendingRequest(request)));
    } else {
      return next.handle(request);
    }
  }
}
