import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import { ModeService } from 'src/services/mode.service';
import {Observable, Subscription} from 'rxjs';
import {Order} from '../../../../interfaces/order.interface';
import {User} from '../../../../interfaces/user.interface';
import {Select} from '@ngxs/store';
import {NavbarSettings} from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import {NotificationService} from '../../../../services/notification.service';
import {InboxMessage} from '../../../../interfaces/inbox-message.interface';

@Component({
    selector: 'app-header-bar',
    template: `
        <ng-container [ngSwitch]="modeService.mode">
            <app-mobile-header-bar *ngSwitchCase="'mobile'"
              [title]="title"
              [order]="order"
              [routeBack]="routeBack"
              [user]="(user)"
              [cartIcon]="cartIcon"
              [showBack]="showBack"
              [inboxMessages]="inboxMessages"
              [unreadMessagesCount]="notificationService.unreadNotificationsCount$ | async"
              (addNewOrderType)="addNewOrderTypeClicked()"
              (startNewOrder)="startOrder($event)"
            ></app-mobile-header-bar>
            <app-desktop-header-bar *ngSwitchCase="'desktop'"
              [title]="title"
              [routeBack]="routeBack"
            ></app-desktop-header-bar>
            <app-tableside-header-bar *ngSwitchCase="'tableside'"
              [title]="title"
              [routeBack]="routeBack"
              [user]="(user)"
              [order]="order"
            ></app-tableside-header-bar>
        </ng-container>
    `
})
export class HeaderBarComponent implements OnInit, OnDestroy {

    @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

    @Input() title = 'back';
    @Input() routeBack: string;
    @Input() loyaltyBar = false;
    @Input() user: User = null;
    @Input() order: Order;
    @Input() cartIcon;
    @Input() showBack = false;
    @Input() inboxMessages?: InboxMessage[];

    @Output() addNewOrderType = new EventEmitter<any>();
    @Output() startNewOrder = new EventEmitter<any>();

    private subs: Subscription[] = [];

    constructor(
      public modeService: ModeService,
      public notificationService: NotificationService,
    ) {}

    ngOnInit() {
        this.subs.push(this.navbarSettings$.subscribe(navbar => {
            if (navbar) {
                this.cartIcon = navbar.cart_type;
            }
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    addNewOrderTypeClicked() {
        this.addNewOrderType.emit();
    }

    startOrder(order) {
        this.startNewOrder.emit(order);
    }
}



