import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-order-submission-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './order-submission-modal.component.html',
  styleUrls: ['order-submission-modal.component.scss'],
})
export class OrderSubmissionModalComponent implements OnInit {
  constructor() {}
  ngOnInit() {}

  animationOptions: AnimationOptions = {
    path: '/assets/lottie/processing-payment.json',
  };
}
