import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo-banner',
  templateUrl: './logo-banner.component.html',
  styleUrls: ['./logo-banner.component.scss']
})
export class LogoBannerComponent {
  
  @Input() logoUrl: string;

}
