import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HandoffTypeService {

  private handoffKey = 'HandoffType';
  private subs: Subscription[] = [];

  /**
   * Constructor for the handoff type service
   * @constructor
   */
  constructor(
    // private navBarService: NavBarService
  ) {

    // this.subs.push(this.navBarService.announcementBar$.subscribe(announcement => {

    // }));

  }

  setHandoffAndGetOrder(queryParam: string, locationID: string): number {
    let handoff = queryParam === undefined
      ? this.getHandoffFromLocalStorage()
      : this.saveHandoffToLocalStorage(queryParam);

    if (handoff === null) { handoff = '0'; }

    if (handoff !== 'delivery' && handoff !== 'dispatch') {
      // this.navBarService.setOrCreateOrder(locationID, handoff).subscribe(() => {});
    }

    return parseInt(handoff, 10);
  }

  getHandoffFromLocalStorage() {
    return localStorage.getItem(this.handoffKey);
  }

  saveHandoffToLocalStorage(handoff: string) {
    localStorage.setItem(this.handoffKey, handoff);

    return handoff;
  }

}
