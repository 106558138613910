<form [formGroup]="tipForm">
  <ng-container *ngIf="kiosk !== true; else kioskTip">
    <div class="d-flex flex-column flex-sm-row">
      <div class="d-flex flex-row flex-nowrap align-items-center grow-1">
        <div class="d-flex flex-row flex-nowrap flex-grow-1" style="flex-basis: 100%;">
          <div class="tip-tab-amts-container">
            <div class="px-2 py-2 tip-tabs"
                 style="border-top-left-radius: var(--cms-input-radius); border-bottom-left-radius: var(--cms-input-radius);"
                 [ngClass]="{'selected-tip': selectedTip === 15}" (click)="addTip(15)">
              <div>15%</div>
            </div>
            <div class="tip-amts">
              {{ (subTotalCents / 100) * .15 | currency }}
            </div>
          </div>
          <div class="tip-tab-amts-container">
            <div class="px-2 py-2 tip-tabs" [ngClass]="{'selected-tip': selectedTip === 20}" (click)="addTip(20)">
              <div>20%</div>
            </div>
            <div class="tip-amts">
              {{ (subTotalCents / 100) * .20 | currency }}
            </div>
          </div>
          <div class="tip-tab-amts-container">
            <div class="px-2 py-2 tip-tabs" [ngClass]="{'selected-tip': selectedTip === 25}" (click)="addTip(25)">
              <div>25%</div>
            </div>
            <div class="tip-amts">
              {{ (subTotalCents / 100) * .25 | currency }}
            </div>
          </div>
          <div class="tip-tab-amts-container">
            <div class="px-2 py-2 tip-tabs"
                 style="  border-top-right-radius: var(--cms-input-radius) !important; border-bottom-right-radius: var(--cms-input-radius) !important;"
                 [ngClass]="{'selected-tip': selectedTip === 0}" (click)="addTip(0)">
              <div>None</div>
            </div>
            <div class="tip-amts">
              {{ 0 | currency: currencyCode }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 ml-0 ms-sm-3 mt-3 mt-sm-0" style="min-width: 0;">
        <input class="px-2 py-2 form-control" placeholder="Custom Tip" formControlName="tipAmount"
               type="text"
               inputmode="decimal" mask="0*.00"
               [dropSpecialCharacters]="false"
               (focus)="emitFocus()"
               (blur)="emitBlur();addTip('custom')" (keyup.enter)="emitBlur();addTip('custom')" />
        <div class="custom-tip-tab tip-amts">
          Custom Tip Amount
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #kioskTip>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row flex-nowrap align-items-center grow-1 w-100 mb-3">
        <div class="d-flex flex-row flex-nowrap flex-grow-1 gap-3" style="flex-basis: 100%;">
          <div class="flex-grow-1 basis-0">
            <div class="btn ki-buttons outline-dark w-100 flex-column py-2"
                 [ngClass]="{'dark': selectedTip === 15, 'outline-dark': selectedTip !== 15}"
                 (click)="addTip(15)">
              <div>15%</div>
              <div class="tip-amts pt-0">
                {{ (subTotalCents / 100) * .15 | currency }}
              </div>
            </div>
          </div>
          <div class="flex-grow-1 basis-0">
            <div class="btn ki-buttons outline-dark w-100 flex-column py-2"
                 [ngClass]="{'dark': selectedTip === 20, 'outline-dark': selectedTip !== 20}" (click)="addTip(20)">
              <div>20%</div>
              <div class="tip-amts pt-0">
                {{ (subTotalCents / 100) * .20 | currency }}
              </div>
            </div>
          </div>
          <div class="flex-grow-1 basis-0">
            <div class="btn ki-buttons outline-dark w-100 flex-column py-2"
                 [ngClass]="{'dark': selectedTip === 25, 'outline-dark': selectedTip !== 25}"
                 (click)="addTip(25)">
              <div class="">25%</div>
              <div class="tip-amts pt-0">
                {{ (subTotalCents / 100) * .25 | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row gap-3 w-100">
        <div class="btn ki-buttons outline-dark flex-grow-1 basis-0" [ngClass]="{'dark': selectedTip === null, 'outline-dark': selectedTip !== null}" (click)="customClick()" >
          <div>
            Custom Tip
          </div>
        </div>
        <div class="btn ki-buttons outline-dark flex-grow-1 basis-0"
             [ngClass]="{'dark': selectedTip === 0, 'outline-dark': selectedTip !== 0}" (click)="addTip(0)">
          <div>No Tip</div>
        </div>
      </div>

      <div class="my-3 form-floating" [@collapse]="!isCustom">
        <input type="text" #tipInput id="tipAmount"
               class="form-control"
               placeholder="Custom tip" aria-label="Custom tip"
               formControlName="tipAmount" inputmode="decimal" mask="0*.00"
               [dropSpecialCharacters]="false"
               (focus)="emitFocus()"
               (blur)="emitBlur();addTip('custom')" (keyup.enter)="emitBlur();addTip('custom')">
        <label for="tipAmount" class="form-label text-start">Custom Tip</label>

        <div class="form-text text-start mt-1 font-size-body-regular">
          Add a tip for our crew.
        </div>

      </div>
    </div>
  </ng-template>
</form>
