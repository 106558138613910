import { Injectable } from '@angular/core';
import { UserDetailsResponse } from './interfaces/user-details-response.interface';
import { User } from 'src/interfaces/user.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import {
  Appliedreward,
  Choice,
  CreateBasketResponse,
  Product as OloBasketProduct,
  Fee as OloFee,
} from './interfaces/create-basket-response.interface';
import { Order } from 'src/interfaces/order.interface';
import { OrderItem as DineEngineOrderItem, OrderItem, Product } from 'src/interfaces/product.interface';
import { Location } from 'src/interfaces/location.interface';
import { Customfield as RestaurantCustomField, Restaurant } from './interfaces/all-restaurants-response.interface';
import { AlcoholStatus, Category as OloCategory, Product as OloProduct, RestaurantMenu } from './interfaces/restaurant-menu.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { CreateUserResponse } from './interfaces/create-user-response.interface';
import { Option as OloOption, Optiongroup } from './interfaces/product-modifiers-response.interface';
import {
  Choice as PastOrderChoice,
  Discount,
  Order as oloOrder,
  Product as OloOrderProduct,
} from './interfaces/recent-orders-response.interface';
import { TransferBasketResponse } from './interfaces/transfer-basket-response.interface';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { Option, OrderItemModifier } from 'src/interfaces/option.interface';
import { Category } from 'src/interfaces/category.interface';
import { Calendar, Range } from './interfaces/calendar-response.interface';
import { TimeFrame } from 'src/interfaces/time-frame.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { Upsells } from 'src/interfaces/upsells.interface';
import { UpsellProduct } from 'src/interfaces/upsell-product.interface';
import { Reward as OloReward } from './interfaces/rewards-response.interface';
import { Redeemable as PunchhRedeemable } from '../punchh/interfaces/redeemable.interface';
import { Billingaccount } from './interfaces/billing-accounts-response.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { CustomField } from 'src/interfaces/custom-fields.interface';
import { Fee } from 'src/interfaces/fee.interface';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { Item, UpsellItemsResponse } from './interfaces/upsell-items-response.interface';
import moment from 'moment-timezone';
import { HttpErrorResponse } from '@angular/common/http';
import { SSOProvider } from 'src/interfaces/sso-provider.enum';
import { GiftCardBalance } from './interfaces/gift-card-balance.interface';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';
import { Billingscheme } from './interfaces/billing-schemes-and-account-response.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import { Customfield } from './interfaces/order-response.interface';
import { GroupOrderResponse } from './interfaces/group-order.interface';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { AddProductsRequest } from './interfaces/add-products-request.interface';
import { Location as CMSLocation } from '../directus/interfaces/location.interface';

import tzlookup from 'tz-lookup';

@Injectable({
  providedIn: 'root',
})
export class OLOMappingService {
  ssoLoginProviderName(provider: SSOProvider) {
    switch (provider) {
      case SSOProvider.paytronix:
        return 'paytronix';
      case SSOProvider.punchh:
        return 'punchh';
      case SSOProvider.personica:
        return 'fishbowl-mybistro';
    }
    throw new DineEngineError('Unknown SSO Provider');
  }

  ssoLoginError(err: Error): DineEngineError {
    return new DineEngineError('SSO Login Error');
  }

  loginError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
        case 403:
          msg = err.error.message;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  oloError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
        case 403:
          msg = err.error.message;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  toCreditCard(account: Billingaccount): SavedCard {
    return {
      description: account.description,
      isDefault: account.isdefault,
      isRemovable: account.removable,
      cardExpiration: moment(account.expiration, 'yyyy-MM').toDate(),
      lastFourDigits: account.cardsuffix,
      savedCardID: account.accountid,
      type: account.cardtype,
      isGiftCard: !!(account.balance && account.accounttype !== 'creditcard' && account.accounttype !== 'payinstore'),
      balanceCents: account.balance ? account.balance.balance * 100 : null,
    } as SavedCard;
  }

  toReward(r: OloReward): Reward {
    return {
      membershipID: r.membershipid,
      name: r.label,
      description: r.description,
      rewardID: r.rewardid || r.reference,
      pointCost: r.value,
      isApplied: r.applied,
      externalRef: r.reference,
      imageURL: r.imageurl,
      redeemInStore: r.availableoffline,
      redeemOnline: r.availableonline,
      expDate: r.expirationdate ? moment(r.expirationdate, 'YYYYMMDD hh:mm').toDate() : null,
      redemptionInfo: {
        redemptionCode: null,
        redemptionMessage: null,
        expiryDate: r.expirationdate ? moment(r.expirationdate, 'YYYYMMDD hh:mm').toDate() : null,
        expiryHours: null,
      },
      isDollarReward: false,
      quantity: r.quantityavailable,
      applicableGlobalIDs: r.items ? r.items.map(i => i.toString()) : [],
    } as Reward;
  }

  punchhRedeemableToReward(r: PunchhRedeemable): Reward {
    return {
      membershipID: null,
      name: r.name,
      description: r.description,
      rewardID: r.id,
      pointCost: r.points,
      isApplied: false,
      externalRef: null,
      redemptionInfo: {
        redemptionCode: null,
        redemptionMessage: null,
        expiryDate: r.expiry_date,
        expiryHours: null,
      },
      isDollarReward: false,
    } as Reward;
  }

  getLocationWithHours(location: Location, calendars: Calendar[]): Location {
    location.curbsideHours = this.getHoursForHandoff(calendars, HandoffType.curbside, location.timezone);
    location.pickupHours = this.getHoursForHandoff(calendars, HandoffType.pickup, location.timezone);
    location.deliveryHours = this.getHoursForHandoff(calendars, HandoffType.delivery, location.timezone);
    location.driveThruHours = this.getHoursForHandoff(calendars, HandoffType.driveThru, location.timezone);
    location.dispatchHours = this.getHoursForHandoff(calendars, HandoffType.dispatch, location.timezone);
    return location;
  }

  productModifiersToProduct(prod: Product, optionGroups: Optiongroup[], baseImageUrl?: string): Product {
    const product = JSON.parse(JSON.stringify(prod));
    product.optionGroups = optionGroups ? optionGroups.map(og => this.toOptionGroup(og, baseImageUrl)) : [];
    return product;
  }

  transferBasketResponseToOrder(transfer: TransferBasketResponse, location: Location, billingSchemes: Billingscheme[]): Order {
    return this.createBasketResponseToOrder(transfer.basket, location, billingSchemes);
  }

  toGiftCard(giftCard: GiftCardBalance, cardNumber: string, pinNumber: string): GiftCardItem {
    return {
      balanceCents: giftCard.balance * 100,
      cardNumber,
      pinNumber,
      message: giftCard.message ? giftCard.message : null,
    } as GiftCardItem;
  }

  toUpsell(upsells: UpsellItemsResponse): Upsells {
    return {
      items: [].concat(...upsells.groups.map(g => g.items)).map(i => this.toUpsellProduct(i)),
    } as Upsells;
  }

  toUpsellProduct(prod: Item): UpsellProduct {
    return {
      priceCents: Number(prod.cost.replace('$', '')) * 100,
      name: prod.name,
      productID: prod.id,
      shortdescription: prod.shortdescription,
      maxquantity: prod.maxquantity,
      minquantity: prod.minquantity,
    } as UpsellProduct;
  }

  orderToOrder(order: oloOrder, location: Location): Order {
    return {
      orderID: order.id,
      orderReference: order.orderref,
      taxCents: order.taxes.map(tax => tax.tax).reduce((a, b) => a + b, 0) * 100,
      subTotalCents: order.subtotal * 100,
      tipCents: order.tip * 100,
      totalCents: order.total * 100,
      orderPlacedTimestamp: null,
      appliedCouponCents: order.discount * 100,
      handoffType: this.handoffTypeToHandoffTypeReverse(order.deliverymode),
      orderReadyTimestamp: this.getDateFromOLODateString(order.readytime, location.timezone),
      location,
      orderStatus: order.status,
      items: order.products.concat(order.unavailableproducts || []).map(prod => this.orderProductToOrderItem(prod)),
      deliveryAddress: order.deliveryaddress
        ? {
            address1: order.deliveryaddress.streetaddress ? order.deliveryaddress.streetaddress : '',
            address2: order.deliveryaddress.building ? order.deliveryaddress.building : '',
            city: order.deliveryaddress.city,
            state: null,
            zipCode: order.deliveryaddress.zipcode,
            latitude: null,
            longitude: null,
            specialInstructions: order.deliveryaddress.specialinstructions ? order.deliveryaddress.specialinstructions : '',
          }
        : null,
      earliestReadyTimestamp: null,
      isASAP: null,
      customFields: order.customfields
        ? order.customfields
            .filter(cf => cf.scope !== 'CurbsidePickupOrdersOnly' && cf.scope !== 'DineInOrdersOnly')
            .map(custom => this.getCustomFields(custom))
        : null,
      curbsideCustomFields: order.customfields
        ? order.customfields.filter(cf => cf.scope === 'CurbsidePickupOrdersOnly').map(custom => this.getCustomFields(custom))
        : null,
      dineinCustomFields: order.customfields
        ? order.customfields.filter(cf => cf.scope === 'DineInOrdersOnly').map(custom => this.getCustomFields(custom))
        : null,
      appliedCouponCode: null,
      appliedReward: null,
      appliedRewards: [],
      balanceCents: null,
      isGroup: null,
      paidCents: null,
      specialInstructions: null,
      tableNumber: null,
      fees: order.fees.map(fee => this.oloFeeToFee(fee)),
      deliveryFee:
        order.deliverymode === 'dispatch' || order.deliverymode === 'delivery'
          ? {
              note: 'Delivery Fee',
              description: 'Delivery Fee',
              feeCents: order.customerhandoffcharge * 100,
            }
          : null,
      canTip: false,
      arrivalstatus: order.arrivalstatus ? order.arrivalstatus : null,
      isEditable: order.iseditable,
      customerFacingID: order.oloid ? order.oloid : null,
      requiresFullBillingAddress: false,
      donationType: order.donations,
      donationsTotal: order.totaldonations,
      supportsOloPay: false,
      isManualFire: order.timemode === 'manualfire' && order.status === 'Pending Manual Fire',
    } as Order;
  }

  createUserResponseToUser(userReq: CreateUserResponse): User {
    return {
      userID: userReq.authtoken,
      firstName: userReq.firstname,
      lastName: userReq.lastname,
      email: userReq.emailaddress,
      phoneNumber: userReq.contactnumber,
    } as User;
  }

  restaurantMenuToMenu(menuID: string, menu: RestaurantMenu, singleUseName?: string, singleUseDescription?: string): Menu {
    return {
      menuID,
      categories: menu.categories.map(cat => this.toCategory(cat, menuID, menu.imagepath)),
      // tslint:disable-next-line:max-line-length
      singleUseProducts: menu.singleusecategories.length
        ? this.toSingleUseCategory(menu.singleusecategories, menuID, menu.imagepath, singleUseName, singleUseDescription)
        : null,
      imageBaseUrl: menu.imagepath,
    };
  }

  toSingleUseCategory(
    cats: OloCategory[],
    menuID: string,
    baseImageURL: string,
    singleUseName?: string,
    singleUseDescription?: string
  ): Category {
    const products = [];
    cats.forEach(cat => {
      products.push(...cat.products);
    });
    return {
      categoryID: cats[0].id.toString(),
      name: singleUseName ? singleUseName : cats[0].name,
      description: singleUseDescription ? singleUseDescription : cats[0].description,
      products: products.map(prod => this.toProduct(prod, menuID, cats[0].id.toString(), baseImageURL)),
      categoryOrder: -1,
      hideFromGuests: false,
      isHidden: false,
      thumbnailImageURL: null,
      standardImageURL: null,
      hasAvailableProducts: true,
      nameSlug: null,
      seoDescription: null,
      badge_text: null,
      badge_color: null,
      badge_text_color: null,
      show_category_banner: true,
    };
  }

  restaurantToLocation(restaurant: Restaurant): Location {
    const timezone = tzlookup(restaurant.latitude, restaurant.longitude);
    return {
      locationID: restaurant.id.toString(),
      address: {
        address1: restaurant.streetaddress,
        address2: null,
        city: restaurant.city,
        state: restaurant.state,
        zipCode: restaurant.zip,
        latitude: restaurant.latitude,
        longitude: restaurant.longitude,
        country: restaurant.country,
        crossStreet: restaurant.crossstreet,
      },
      utcOffset: String(restaurant.utcoffset),
      isOpen: restaurant.iscurrentlyopen,
      isLive: restaurant.isavailable,
      isPrivate: false,
      supportsCurbside: restaurant.supportscurbside,
      supportsDriveThru: restaurant.supportsdrivethru,
      supportsDelivery: restaurant.candeliver,
      supportsDispatch: restaurant.supportsdispatch,
      supportsTableside: restaurant.supportsdinein,
      supportsGroupOrders: restaurant.supportsgrouporders,
      supportsGuestOrdering: restaurant.supportsguestordering,
      supportsOnlineOrdering: restaurant.isavailable,
      supportsPickup: restaurant.canpickup,
      supportsSpecialInstructions: restaurant.supportsspecialinstructions,
      supportsRecipientName: restaurant.supportsproductrecipientnames,
      customFields: restaurant.customfields
        ? restaurant.customfields
            .filter(cf => cf.qualificationcriteria !== 'CurbsidePickupOrdersOnly' && cf.qualificationcriteria !== 'DineInOrdersOnly')
            .map(custom => this.restaurantCustomFieldToCustomField(custom))
        : null,
      curbsideCustomFields: restaurant.customfields
        ? restaurant.customfields
            .filter(cf => cf.qualificationcriteria === 'CurbsidePickupOrdersOnly')
            .map(custom => this.restaurantCustomFieldToCustomField(custom))
        : null,
      dineInCustomFields: restaurant.customfields
        ? restaurant.customfields
            .filter(cf => cf.qualificationcriteria === 'DineInOrdersOnly')
            .map(custom => this.restaurantCustomFieldToCustomField(custom))
        : null,
      emailAddress: null,
      mapIconURL: null,
      name: restaurant.name,
      phoneNumber: restaurant.telephone,
      requiresPhoneNumber: restaurant.requiresphonenumber,
      specialInstructionsMaxLength: restaurant.specialinstructionsmaxlength,
      supportsSplitPayments: restaurant.supportssplitpayments,
      supportsTip: restaurant.supportstip,
      supportsBasketTransfer: restaurant.supportsbaskettransfers,
      curbsideHours: restaurant.calendars ? this.getHoursForHandoff(restaurant.calendars, HandoffType.curbside, timezone) : null,
      deliveryHours: restaurant.calendars ? this.getHoursForHandoff(restaurant.calendars, HandoffType.delivery, timezone) : null,
      dispatchHours: restaurant.calendars ? this.getHoursForHandoff(restaurant.calendars, HandoffType.dispatch, timezone) : null,
      pickupHours: restaurant.calendars ? this.getHoursForHandoff(restaurant.calendars, HandoffType.pickup, timezone) : null,
      driveThruHours: restaurant.calendars ? this.getHoursForHandoff(restaurant.calendars, HandoffType.driveThru, timezone) : null,
      canDeliver: false,
      seoDescription: null,
      pickupInstructions: this.getLabel('thanks_pickupinstructions', restaurant),
      curbsideInstructions: this.getLabel('thanks_curbsidepickup_instructions', restaurant),
      deliveryInstructions: this.getLabel('thanks_delivery_instructions', restaurant),
      dispatchInstructions: this.getLabel('thanks_dispatch_instructions', restaurant),
      tablesideInstructions: this.getLabel('thanks_dinein_instructions', restaurant),
      driveThruInstructions: this.getLabel('thanks_drivethru_instructions', restaurant),
      externalRef: restaurant.extref ?? null,
      orderAheadDays: restaurant.advanceorderdays,
      supportsAdvancedOrders: restaurant.supportedtimemodes.includes('advance'),
      supportsAsapOrders: restaurant.supportedtimemodes.includes('asap'),
      timezone: tzlookup(restaurant.latitude, restaurant.longitude),
      connectedProjects: [],
    } as Location;
  }

  private getLabel(labelName: string, restaurant: Restaurant) {
    const result = restaurant.labels.find(x => x.key === labelName);
    return result ? result.value : null;
  }

  createBasketResponseToOrder(basket: CreateBasketResponse, location: Location, bSchemes: Billingscheme[]): Order {
    const timezone = tzlookup(location.address.latitude, location.address.longitude);
    return {
      orderID: basket.id,
      taxCents: basket.taxes.map(tax => tax.tax).reduce((a, b) => a + b, 0) * 100,
      subTotalCents: basket.subtotal * 100,
      tipCents: basket.tip * 100,
      totalCents: basket.total * 100,
      appliedCouponCents: basket.discount * 100,
      orderPlacedTimestamp: null,
      handoffType: this.handoffTypeToHandoffTypeReverse(basket.deliverymode),
      orderReadyTimestamp: basket.timewanted
        ? this.getDateFromOLODateString(basket.timewanted, timezone)
        : this.getDateFromOLODateString(basket.earliestreadytime, timezone),
      location,
      orderStatus: null,
      items: basket.products.map(prod => this.basketProductToOrderItem(prod, null, null)),
      deliveryAddress: basket.deliveryaddress
        ? {
            address1: basket.deliveryaddress.streetaddress ? basket.deliveryaddress.streetaddress : '',
            address2: basket.deliveryaddress.building ? basket.deliveryaddress.building : '',
            city: basket.deliveryaddress.city,
            state: null,
            zipCode: basket.deliveryaddress.zipcode,
            latitude: null,
            longitude: null,
            specialInstructions: basket.deliveryaddress.specialinstructions ? basket.deliveryaddress.specialinstructions : null,
          }
        : null,
      customFields: basket.customfields ? basket.customfields.map(custom => this.getCustomFields(custom)) : null,
      earliestReadyTimestamp: this.getDateFromOLODateString(basket.earliestreadytime, timezone),
      isASAP: basket.timemode === 'asap',
      appliedCouponCode: basket.coupon ? basket.coupon.couponcode : null,
      appliedRewards: basket.appliedrewards ? basket.appliedrewards.map(r => this.appliedRewardToReward(r, basket.discounts)) : [],
      balanceCents: 0,
      isGroup: false,
      paidCents: 0,
      specialInstructions: null,
      fees: basket.fees.map(fee => this.oloFeeToFee(fee)),
      deliveryFee:
        basket.deliverymode === 'dispatch' || basket.deliverymode === 'delivery'
          ? {
              note: 'Delivery Fee',
              description: 'Delivery Fee',
              feeCents: basket.customerhandoffcharge * 100,
            }
          : null,
      canTip: basket.allowstip,
      arrivalstatus: basket.arrivalstatus ? basket.arrivalstatus : null,
      supportedPaymentTypes: this.mapPaymentTypes(bSchemes),
      requiresFullBillingAddress: this.checkIfFullAddressNeeded(bSchemes),
      donationType: basket.donations,
      donationsTotal: basket.totaldonations,
      supportsOloPay: bSchemes.some(scheme => scheme.type === 'creditcard' && scheme.supportstokens),
    } as Order;
  }

  appliedRewardToReward(r: Appliedreward, discounts: Discount[]): Reward {
    return {
      membershipID: r.membershipid,
      name: r.label,
      description: r.description,
      rewardID: r.rewardid,
      pointCost: r.value,
      isApplied: r.applied,
      externalRef: r.reference,
      redeemInStore: r.availableoffline,
      redeemOnline: r.availableonline,
      quantity: r.quantityavailable,
      applicableGlobalIDs: r.items ? r.items.map(i => i.toString()) : [],
      discountCents: discounts.find(d => d.description === r.label)?.amount * 100,
    } as Reward;
  }

  mapPaymentTypes(schemes: Billingscheme[]): PaymentTypes[] {
    const paymentTypes: PaymentTypes[] = [];
    schemes.forEach(scheme => {
      switch (scheme.type) {
        case 'payinstore':
          paymentTypes.push(PaymentTypes.cash);
          break;
        case 'creditcard':
          paymentTypes.push(PaymentTypes.creditCard);
          break;
        case 'giftcard':
          paymentTypes.push(PaymentTypes.giftCard);
          break;
        case 'prepaid':
          paymentTypes.push(PaymentTypes.prepaid);
          break;
        case 'external':
          if (scheme.name.includes('Apple Pay')) {
            paymentTypes.push(PaymentTypes.applePay);
          }
          if (scheme.name.includes('Google Pay')) {
            paymentTypes.push(PaymentTypes.googlePay);
          }
          break;
      }
    });
    return paymentTypes;
  }

  checkIfFullAddressNeeded(bSchemes: Billingscheme[]): boolean {
    const ccScheme = bSchemes.find(scheme => scheme.type === 'creditcard');
    return ccScheme && ccScheme.supportsfulladdresscollection;
  }

  userDetailsToUser(userDetails: UserDetailsResponse): User {
    return {
      userID: userDetails.authtoken,
      firstName: userDetails.firstname,
      lastName: userDetails.lastname,
      email: userDetails.emailaddress,
      userAsBarcode: null,
      userAsQrCode: null,
      isGuest: false,
    } as User;
  }

  handoffTypeToHandoffType(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbside' | 'pickup' | 'drivethru' | 'dinein' {
    switch (handoffType) {
      case HandoffType.delivery:
        return 'delivery';
      case HandoffType.dispatch:
        return 'dispatch';
      case HandoffType.curbside:
        return 'curbside';
      case HandoffType.pickup:
        return 'pickup';
      case HandoffType.driveThru:
        return 'drivethru';
      case HandoffType.dineIn:
        return 'dinein';
      default:
        return 'pickup';
    }
  }

  handoffTypeToString(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbside' | 'pickup' | 'drivethru' {
    switch (String(handoffType)) {
      case 'delivery':
        return 'delivery';
      case 'dispatch':
        return 'dispatch';
      case 'curbside':
        return 'curbside';
      case 'pickup':
        return 'pickup';
      case 'driveThru':
        return 'drivethru';
      default:
        return 'pickup';
    }
  }

  handoffTypeToHandoffTypeReverse(handoffType: string): HandoffType {
    switch (handoffType) {
      case 'delivery':
        return HandoffType.delivery;
      case 'dispatch':
        return HandoffType.dispatch;
      case 'curbside':
        return HandoffType.curbside;
      case 'pickup':
        return HandoffType.pickup;
      case 'drivethru':
        return HandoffType.driveThru;
      case 'dinein':
        return HandoffType.dineIn;
      default:
        return HandoffType.pickup;
    }
  }

  toOptionGroup(og: Optiongroup, baseImageUrl: string): OptionGroup {
    return {
      optionGroupID: og.id.toString(),
      brandOptionGroupID: og.chainmodifierid.toString(),
      name: og.description,
      nameSlug: og.description
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, ''),
      description: og.explanationtext,
      maxAllowed: og.mandatory ? 1 : Number(og.maxselects),
      minRequired: og.mandatory ? 1 : Number(og.minselects),
      options: og.options ? og.options.map(op => this.mapOption(op, baseImageUrl)) : [],
      mandatorySelection: !!og.minselects,
      minTotalQuantity: Number(og.minaggregatequantity),
      maxTotalQuantity: Number(og.maxaggregatequantity),
      minChoiceQuantity: Number(og.minchoicequantity),
      maxChoiceQuantity: Number(og.maxchoicequantity),
      incrementValue: Number(og.choicequantityincrement),
      hideCost: og.hidechoicecost,
      metadata: og.metadata ?? [],
    } as OptionGroup;
  }

  // tslint:disable-next-line:max-line-length
  groupOrderResponseToOrder(
    groupOrderResponse: GroupOrderResponse,
    location: Location,
    bSchemes: Billingscheme[],
    isOwner: boolean
  ): GroupOrder {
    const basket: CreateBasketResponse = groupOrderResponse.basket;
    const order = this.createBasketResponseToOrder(basket, location, bSchemes);
    return {
      groupOrderID: groupOrderResponse.id,
      groupOrderDeadline: this.getDateFromOLODateString(
        groupOrderResponse.deadline,
        tzlookup(location.address.latitude, location.address.longitude)
      ),
      groupOrderNote: groupOrderResponse.note,
      groupOrderOwner: groupOrderResponse.ownername,
      groupOrderIsOpen: groupOrderResponse.isopen,
      role: isOwner ? 'owner' : 'member',
      order,
    } as GroupOrder;
  }

  orderItemToAddProductsBody(orderItem: OrderItem, quantity?: number): AddProductsRequest {
    return {
      products: [
        {
          productid: Number(orderItem.orderItemID ? orderItem.orderItemID : orderItem.productID),
          quantity: quantity ?? orderItem.quantity,
          choices:
            orderItem.options?.map(option => ({
              choiceid: Number(option.optionID),
              quantity: !option.quantity ? 1 : option.quantity,
              customfields: [],
            })) ?? [],
          specialinstructions: orderItem.instructions,
          recipient: orderItem.guestName,
          customdata: null,
        },
      ],
    };
  }

  private getCustomFields(rest: Customfield): CustomField {
    return {
      id: rest.id,
      label: rest.label,
      required: rest.isrequired,
      value: rest.value ? rest.value : null,
      applicableHandoffs: this.scopeToAvailableHandoffs(rest.scope),
      type: rest.validationregex === '^(?:Yes|No)$' ? 'checkbox' : 'input',
    } as CustomField;
  }

  private restaurantCustomFieldToCustomField(field: RestaurantCustomField): CustomField {
    return {
      id: field.id,
      label: field.label,
      required: field.required,
      value: null,
      applicableHandoffs: this.scopeToAvailableHandoffs(field.qualificationcriteria),
      type: field.validationregex === '^(?:Yes|No)$' ? 'checkbox' : 'input',
    };
  }

  private handoffTypeToCalendarType(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbsidepickup' | 'business' | 'drivethru' {
    switch (handoffType) {
      case HandoffType.delivery:
        return 'delivery';
      case HandoffType.dispatch:
        return 'dispatch';
      case HandoffType.curbside:
        return 'curbsidepickup';
      case HandoffType.pickup:
        return 'business';
      case HandoffType.driveThru:
        return 'drivethru';
      default:
        return 'business';
    }
  }

  private mapOption(option: OloOption, baseImageUrl?: string): Option {
    return {
      optionID: option.id.toString(),
      brandOptionID: option.chainoptionid.toString(),
      name: option.name,
      nameSlug: option.name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, ''),
      addedCents: option.cost * 100,
      isDefault: option.isdefault,
      isSelected: false,
      hasChildren: option.children,
      optionGroups: option && option.modifiers ? option.modifiers.map(mod => this.toOptionGroup(mod, baseImageUrl)) : [],
      nutritionInfo: null,
      standardImageURL:
        baseImageUrl && !!option.images?.find(i => i.groupname === 'mobileweb-modifier-choice')
          ? baseImageUrl.concat(option.images.find(i => i.groupname === 'mobileweb-modifier-choice')?.filename)
          : null,
      thumbnailImageURL:
        baseImageUrl && !!option.images?.find(i => i.groupname === 'mobileweb-modifier-choice')
          ? baseImageUrl.concat(option.images.find(i => i.groupname === 'mobileweb-modifier-choice')?.filename)
          : null,
      metadata: option.metadata ?? [],
    } as Option;
  }

  private toCategory(cat: OloCategory, menuID: string, baseImageURL: string): Category {
    const isMobile = window.innerWidth <= 991;

    return {
      menuID,
      name: cat.name,
      nameSlug: null,
      standardImageURL:
        baseImageURL && !!cat.images?.find(i => i.groupname === (isMobile ? 'mobile-webapp-customize' : 'responsive-large'))
          ? baseImageURL.concat(cat.images.find(i => i.groupname === (isMobile ? 'mobile-webapp-customize' : 'responsive-large'))?.filename)
          : null,
      thumbnailImageURL: null,
      description: cat.description,
      categoryID: cat.id.toString(),
      products: cat.products.map(p => this.toProduct(p, menuID, cat.id.toString(), baseImageURL)),
      isHidden: false,
      seoDescription: null,
      categoryOrder: null,
      hasAvailableProducts: true,
      badge_text: null,
      badge_color: null,
      badge_text_color: null,
      show_category_banner: false,
    } as Category;
  }

  private toProduct(prod: OloProduct, menuID: string, categoryID: string, baseImageURL: string): Product {
    return {
      allergenInfo: null,
      canModify: null,
      categoryID,
      isAvailable: true,
      longDesc: prod.description,
      menuID,
      name: prod.name,
      nutritionInfo: {},
      optionGroups: null,
      priceCents: prod.cost * 100,
      productID: prod.id.toString(),
      requiresModification: null,
      shortDesc: prod.description,
      standardImageURL:
        baseImageURL && prod.imagefilename && !!prod.images?.find(i => i.groupname === 'marketplace-product')
          ? baseImageURL.concat(prod.images.find(i => i.groupname === 'marketplace-product')?.filename)
          : null,
      thumbnailImageURL:
        baseImageURL && prod.imagefilename && !!prod.images?.find(i => i.groupname === 'marketplace-product')
          ? baseImageURL.concat(prod.images.find(i => i.groupname === 'marketplace-product')?.filename)
          : null,
      seoDescription: null,
      currentlyAvailable: prod.availability.now,
      globalID: prod.chainproductid.toString(),
      isAlcohol: prod.alcoholstatus !== AlcoholStatus.NONE,
      minQuantity: prod.minimumquantity ? Number(prod.minimumquantity) : 1,
    } as Product;
  }

  private basketProductToOrderItem(prod: OloBasketProduct, menuID: string, categoryID: string): OrderItem {
    return {
      categoryID,
      guestName: prod.recipient,
      instructions: prod.specialinstructions,
      longDesc: null,
      menuID,
      name: prod.name,
      nameSlug: null,
      optionGroups: null,
      orderItemID: prod.id.toString(),
      totalCents: prod.totalcost * 100,
      productID: Object.keys(prod).includes('productid') ? prod['productid'] : prod.productId.toString(),
      quantity: prod.quantity,
      shortDesc: null,
      standardImageURL: null,
      thumbnailImageURL: null,
      userID: null,
      options: this.reduceOrderItemModifers(prod.choices.map(op => this.toOrderItemModifier(op))),
      isAlcohol: false,
      seoDescription: null,
      showAsModal: null,
    } as OrderItem;
  }

  toOrderItemWithProductInfo(orderItem: DineEngineOrderItem, product: Product): DineEngineOrderItem {
    return {
      ...orderItem,
      isAlcohol: product.isAlcohol,
    };
  }

  private toOrderItemModifier(op: Choice): OrderItemModifier {
    return {
      name: op.name,
      addedCents: op.cost,
      nutritionInfo: null,
      optionID: op.optionid.toString(),
      brandOptionID: op.optionid.toString(),
      quantity: op.quantity,
      indentLevel: op.indent,
    } as OrderItemModifier;
  }

  private getHoursForHandoff(calendars: Calendar[], handoffType: HandoffType, timezone: string): TimeFrame[] {
    const calendar = calendars.find(cal => cal.type === this.handoffTypeToCalendarType(handoffType));
    return calendar && calendar.ranges ? calendar.ranges.map(range => this.toTimeFrame(range, timezone)) : [];
  }

  private toTimeFrame(range: Range, timezone: string): TimeFrame {
    return {
      end: this.getDateFromOLODateString(range.end, timezone),
      start: this.getDateFromOLODateString(range.start, timezone),
    } as TimeFrame;
  }

  private getDateFromOLODateString(dateStr: string, timezone: string): Date {
    return moment.tz(dateStr, 'YYYYMMDD HH:mm', timezone).toDate();
  }

  private orderProductToOrderItem(prod: OloOrderProduct): DineEngineOrderItem {
    return {
      categoryID: null,
      guestName: null,
      instructions: prod.specialinstructions ? prod.specialinstructions : null,
      longDesc: null,
      menuID: null,
      name: prod.name,
      orderItemID: null,
      totalCents: prod.totalcost * 100,
      productID: null,
      quantity: prod.quantity,
      shortDesc: null,
      standardImageURL: null,
      thumbnailImageURL: null,
      userID: null,
      options: prod.choices.map(choice => this.pastChoiceToOrderItemModifier(choice)),
    } as DineEngineOrderItem;
  }

  private pastChoiceToOrderItemModifier(choice: PastOrderChoice): OrderItemModifier {
    return {
      name: choice.name,
      quantity: choice.quantity,
      addedCents: 0,
      modifierCategoryID: null,
      nutritionInfo: null,
      optionID: null,
      brandOptionID: null,
      optionGroupID: null,
    } as OrderItemModifier;
  }

  private oloFeeToFee(fee: Discount | OloFee): Fee {
    return {
      feeCents: fee.amount * 100,
      description: fee.description,
      note: 'note' in fee && fee.note ? fee.note : '',
    } as Fee;
  }

  private scopeToAvailableHandoffs(scope: string): HandoffType[] {
    switch (scope) {
      case 'AllOrders':
        return [HandoffType.delivery, HandoffType.dispatch, HandoffType.curbside, HandoffType.pickup, HandoffType.dineIn];
      case 'DeliveryOrdersOnly':
        return [HandoffType.dispatch, HandoffType.delivery];
      case 'CurbsidePickupOrdersOnly':
        return [HandoffType.curbside];
      case 'DriveThruOnly':
        return [HandoffType.driveThru];
      case 'DineInOrdersOnly':
        return [HandoffType.dineIn];
      case 'CarryOutOnly':
        return [HandoffType.pickup];
      default:
        return [];
    }
  }

  private reduceOrderItemModifers(modifiers: OrderItemModifier[]): OrderItemModifier[] {
    const reducedModifiers = [];
    // combine modifiers with same optionID and sum quantity
    modifiers.forEach(mod => {
      const existingMod = reducedModifiers.find(rm => rm.optionID === mod.optionID);
      if (existingMod) {
        existingMod.quantity += mod.quantity;
      } else {
        reducedModifiers.push(mod);
      }
    });
    return reducedModifiers;
  }

  cmsLocationToLocation(location: CMSLocation): Location {
    return {
      cateringLink: '',
      conceptLogoURL: '',
      curbsideCustomFields: [],
      curbsideHours: [],
      curbsideInstructions: '',
      customFields: [],
      deliveryHours: [],
      deliveryInstructions: '',
      dineInCustomFields: [],
      dispatchHours: [],
      dispatchInstructions: '',
      driveThruHours: [],
      driveThruInstructions: '',
      emailAddress: '',
      externalLink: '',
      externalRef: '',
      isHidden: location.is_hidden,
      isLive: location.status === 'published',
      isOpen: false,
      isPrivate: false,
      mapIconURL: '',
      orderAheadDays: 0,
      phoneNumber: '',
      pickupHours: [],
      pickupInstructions: '',
      requiresPhoneNumber: false,
      seoDescription: '',
      slugURL: '',
      specialInstructionsMaxLength: 0,
      supportsAdvancedOrders: false,
      supportsAsapOrders: false,
      supportsBasketTransfer: false,
      supportsCurbside: false,
      supportsDelivery: false,
      supportsDispatch: false,
      supportsDriveThru: false,
      supportsGroupOrders: false,
      supportsGuestOrdering: false,
      supportsOnlineOrdering: false,
      supportsPickup: false,
      supportsRecipientName: false,
      supportsSpecialInstructions: false,
      supportsSplitPayments: false,
      supportsTip: false,
      tablesideInstructions: '',
      name: location.location_name,
      locationID: location.menu_id,
      address: {
        latitude: Number(location.latitude),
        longitude: Number(location.longitude),
        address1: location.location_address,
        address2: location.location_address_line_2,
        city: null,
        state: null,
        zipCode: null,
      },
      timezone: tzlookup(location.latitude, location.longitude),
      connectedProjects: location.connected_project_location_ids
        ? Object.entries(location.connected_project_location_ids).map(([key, value]) => {
            return {
              project: key,
              locationID: value,
            };
          })
        : [],
    };
  }
}
