import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Offer} from '../../../../interfaces/offer.interface';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-offers-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './offers-modal.component.html',
    styleUrls: ['offers-modal.component.scss'],
})
export class OffersModalComponent implements OnInit {
    @Select((state) => state.user.offers) offers$: Observable<Offer[]>;

    constructor(
        private modalController: ModalController
    ) {}
    ngOnInit() {}

    close(): void {
        this.modalController.dismiss()
    }
}
