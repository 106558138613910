import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressSearch } from '@modules/locations/models/locations.address-search';
import { Order } from '../../../../interfaces/order.interface';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Location } from '../../../../interfaces/location.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MobileService } from '../../../../services/mobile.service';
import { LocationSearchService } from '@modules/locations/services/locations-search.service';
import { Select, Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { HandoffType } from '../../../../interfaces/handoff-type.enum';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { SetDeliveryLocations } from '../../../../store/actions/location.actions';
import { Options } from '@barkhub/ngx-google-places-autocomplete';

declare let google: any;

export interface AddressEmit {
  address: AddressSearch;
  secondAddress: string;
  instructions: string;
  location?: Location;
}

@Component({
  selector: 'app-delivery-info-form',
  templateUrl: './delivery-info-form.component.html',
  styleUrls: ['delivery-info-form.component.scss'],
})
export class DeliveryInfoFormComponent implements OnInit, OnDestroy {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.location.deliveryLocations)
  deliveryLocations$: Observable<Location[]>;

  @Input() provider = 'google';
  @Input() orderType = null;

  @Output() startDeliveryWithLocation = new EventEmitter<AddressEmit>();
  @Output() startDeliveryOrderEmit = new EventEmitter<AddressEmit>();
  @Output() deliveryAddressChange = new EventEmitter<AddressSearch>();
  @Output() startPickupOrderEmit = new EventEmitter<number>();

  displayError = null;
  fullAddress: AddressSearch;
  isLoading = false;
  displayOrder: Order;
  private subs: Subscription[] = [];

  selectedLocation: Location;

  deliveryInstructionsLength = 128;

  handoffs = HandoffType;

  chooseDelivery = false;

  deliveryForm = new UntypedFormGroup({
    deliveryAddress: new UntypedFormControl('', [Validators.required]),
    secondDeliveryAddress: new UntypedFormControl(''),
    deliveryInstructions: new UntypedFormControl(),
  });

  autocompleteOptions: Partial<Options> = {
    fields: ['address_components', 'formatted_address', 'geometry'],
    types: ['address'],
  };

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public mobile: MobileService,
    private locationsSearch: LocationSearchService,
    private store: Store
  ) {}

  ngOnInit() {
    this.subs.push(
      combineLatest([this.vendorSetup$, this.order$]).subscribe(
        ([vendorSetup, order]) => {
          if (
            vendorSetup.order_provider !== 'olo' &&
            vendorSetup.order_provider !== 'punchh-olo'
          ) {
            if (order) {
              this.deliveryInstructionsLength =
                order.location.specialInstructionsMaxLength;
            }
          }
        }
      )
    );
    this.subs.push(
      this.order$.pipe(filter(o => o !== null)).subscribe(order => {
        this.displayOrder = order;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  typeSelected(num: number) {
    if (num === 0) {
      this.startPickupOrderEmit.emit();
    } else {
      this.orderType = 1;
    }
  }

  startDeliveryOrder() {
    if (!this.fullAddress) {
      return;
    } else {
      this.displayError = '';
    }
    this.isLoading = true;
    this.displayError = null;
    if (this.deliveryForm.invalid) {
      this.displayError = 'Please fill out all required information.';
      return;
    }
    const info: AddressEmit = {
      address: this.fullAddress,
      secondAddress: this.address2.value,
      instructions: this.instructions.value,
    };
    this.startDeliveryOrderEmit.emit(info);
  }

  deliveryAddressChanged(event: any) {
    if (
      this.fullAddress &&
      this.fullAddress.addressComponents &&
      this.fullAddress.formattedAddress
    ) {
      this.deliveryAddressChange.emit(this.fullAddress);
    }
  }

  placeChanged(place) {
    this.displayError = '';
    this.fullAddress = this.locationsSearch.googlePlaceToAddressSearch(
      place,
      this.deliveryForm.get('deliveryAddress').value
    );
    // this.deliveryForm.get('deliveryAddress').setValue(this.fullAddress.formattedAddress);
  }

  deliveryLocationSelect(location: Location) {
    const info: AddressEmit = {
      address: this.fullAddress,
      secondAddress: this.address2.value,
      instructions: this.instructions.value,
      location,
    };
    this.startDeliveryWithLocation.emit(info);
  }

  get address2() {
    return this.deliveryForm.get('secondDeliveryAddress');
  }
  get instructions() {
    return this.deliveryForm.get('deliveryInstructions');
  }

  checkAddress() {
    if (!this.fullAddress) {
      return;
    } else {
      this.displayError = '';
    }
    this.isLoading = true;
    const coords: ICoordinates = {
      latitude: this.fullAddress.addressComponents.latitude,
      longitude: this.fullAddress.addressComponents.longitude,
    };

    this.store
      .dispatch(
        new SetDeliveryLocations(coords, 25, this.fullAddress.addressComponents)
      )
      .subscribe(
        state => {
          if (
            state.location.deliveryLocations.length > 0 &&
            state.location.deliveryLocations.find(loc => loc.canDeliver)
          ) {
            this.chooseDelivery = true;
            this.isLoading = false;
          } else if (
            !state.location.deliveryLocations.find(loc => loc.canDeliver)
          ) {
            this.displayError =
              'There are no locations that can currently deliver to you';
            this.isLoading = false;
          } else {
            this.displayError = 'We could not find any location near you';
            this.isLoading = false;
          }
        },
        error => {
          this.displayError = error;
          this.isLoading = false;
        }
      );
  }
}
