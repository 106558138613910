import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {of, Observable, BehaviorSubject, from, ReplaySubject} from 'rxjs';
import {ProjectKey} from '../vendors/directus/interfaces/project-key.interface';
import {map, switchMap} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {Preferences as Storage} from '@capacitor/preferences';
import {ajax, AjaxResponse} from 'rxjs/ajax';

@Injectable({
    providedIn: 'root'
})
export class ProjectKeyService {
    public get projectKey(): string {
        return this._projectKey;
    }
    private projectKeySubject = new ReplaySubject<string>(1);
    readonly projectKey$ = this.projectKeySubject.asObservable();
    private projectKeyLoadFlag = false;
    private _projectKey: string;

    constructor(

    ) {}

    @Cacheable()
    getProjectKeyCall(): Observable<string> {
        const domain = window.location.hostname;
        const resource = environment.domainAPI + '/dineengine/custom/dineengine/get-project-key?domain='+domain;
        return ajax(resource).pipe(switchMap((key: AjaxResponse<ProjectKey>) => {
            this._projectKey = (key.response).data.project_key;
            this.projectKeySubject.next(this._projectKey);
            return from(Storage.set({key: 'projectKey', value: this._projectKey})).pipe(map(() => this._projectKey));
        }));
    }

    getProjectKey(): Observable<string> {
        if (this._projectKey) {
            return of(this._projectKey);
        }
        return from(Storage.get({key: 'projectKey'})).pipe(switchMap(key => {
            if (key.value) {
                this._projectKey = key.value;
                this.projectKeySubject.next(this._projectKey);
                return of(this._projectKey);
            } else {
                return this.getProjectKeyCall()
            }
        }));
    }
}
