<div class="d-flex flex-column w-100">
  <!-- Header -->
  <div class="w-100 d-flex flex-row flex-nowrap align-items-center gap-2 mb-3">
    <h4 class="fw-bold header-font mb-0 d-flex flex-row align-items-center">
      <app-custom-icon
        class="color-primary me-2"
        default="bi-award"
        customIconKey="rewards_navigation_icon"
        altText="Rewards"
      ></app-custom-icon>
      {{ section?.section_title || 'Rewards' }}
    </h4>
    <!-- Points Balance -->
    <div class="ms-auto">
      <ng-container *ngIf="isDashboard; else viewAll">
        <ng-container *ngIf="displayBalances?.rewardAmounts && displayBalances?.rewardAmounts.length">
          <button
            class="pill-btn-default pill-btn-sm text-nowrap"
            (click)="openRewardsBalances.emit()">
            View Expirations
          </button>
        </ng-container>
      </ng-container>
      <ng-template #viewAll>
        <button
          class="pill-btn-default pill-btn-sm text-nowrap"
          [routerLink]="rewardsRoute">
          View All Rewards
        </button>
      </ng-template>
    </div>
  </div>

  <!-- Rewards List -->
  <ng-container *ngIf="displayRewards$ | async as rewards; else rewardsLoading">
    <ng-container *ngIf="(rewards | removeCouponRewards).length > 0; else noRewards">
      <div class="reward-cards-container">
        <app-reward-card
          *ngFor="let reward of rewards | removeCouponRewards; trackBy: rewardTrackBy"
          [reward]="reward"
          [isStaged]="stagedReward && stagedReward.externalRef === reward.externalRef"
          (clickedRedeem)="redeemReward.emit(reward)"
          (clickedRedeemInStore)="redeemInStoreReward.emit(reward)"
          (clickedRemove)="removeReward.emit(reward)"
          [currencyCode]="currencyCode">
        </app-reward-card>
      </div>
    </ng-container>
  </ng-container>

  <!-- Loading Skeletons Template -->
  <ng-template #rewardsLoading>
    <ion-list class="w-100 reward-cards-container">
      <ng-container *ngFor="let item of [].constructor(6)">
        <app-skeleton-card class="w-100" [productCard]="true" [description]="false"></app-skeleton-card>
      </ng-container>
    </ion-list>
  </ng-template>

  <!-- No Rewards Template -->
  <ng-template #noRewards>
    <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
      <app-custom-icon
        class="color-primary display-3"
        default="bi-award"
        customIconKey="rewards_navigation_icon"
        altText="Rewards"
      ></app-custom-icon>
      <div class="text-center d-flex flex-column">
        <h5 class="fw-bold">You currently have no rewards.</h5>
        <div>Order now to start earning rewards!</div>
      </div>
      <button
        class="de-primary-link w-100"
        style="max-width: 264px"
        [routerLink]="locationsRoute">
        Order Now
      </button>
    </div>
  </ng-template>
</div>
