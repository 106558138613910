import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../navigation/services';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { VendorSetup } from '../../../../vendors/directus/interfaces/vendor.interface';
import { LoyaltySection, LoyaltySectionType, MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { map } from 'rxjs/operators';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { Reward } from '../../../../interfaces/reward.interface';
import { Order } from '../../../../interfaces/order.interface';
import { MobileService } from '../../../../services/mobile.service';
import { ModeService } from '../../../../services/mode.service';

@Component({
  selector: 'app-dashboard-navigation-section',
  templateUrl: './dashboard-navigation-section.component.html',
  styleUrls: ['dashboard-navigation-section.component.scss'],
})
export class DashboardNavigationSectionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.inboxMessages) inboxMessages$: Observable<InboxMessage[]>;
  @Select(state => state.user.rewards) rewards$: Observable<Reward[]>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  loyaltySections$: Observable<LoyaltySection[]>;
  displayVendorSetup: VendorSetup;

  // Define routes
  profilePageRoute = this.navigation.getProfilePageSlug();
  inboxRoute = this.navigation.getInboxPageSlug();
  rewardsRoute = this.navigation.getRewardsPageSlug();
  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  activityRoute = this.navigation.getActivityPageSlug();
  editProfileRoute = this.navigation.getEditProfilePageSlug();
  savedCardsRoute = this.navigation.getSavedCardsPageSlug();
  savedAddressesRoute = this.navigation.getSavedAddressesPageSlug();

  constructor(
    private router: Router,
    private navigation: NavigationService,
    protected mobileService: MobileService,
    public modeService: ModeService
  ) {}

  ngOnInit() {
    // Subscribe to vendor setup
    this.vendorSetup$.subscribe(vendorSetup => {
      this.displayVendorSetup = vendorSetup;
    });

    // Get loyalty sections and sort them
    this.loyaltySections$ = this.mainSettings$.pipe(
      map(settings => {
        if (settings && settings.loyalty_sections && settings.loyalty_sections.length > 0) {
          // Create a shallow copy before sorting to avoid mutating the original array
          return [...settings.loyalty_sections].sort(this.sortSectionLinks);
        } else {
          return [];
        }
      })
    );
  }

  // Map section types to routes
  getRouteForSection(section: LoyaltySection): string {
    switch (section.section_type) {
      case LoyaltySectionType.rewards:
        return this.rewardsRoute;
      case LoyaltySectionType.activity:
        return this.activityRoute;
      case LoyaltySectionType.payments:
        return this.savedCardsRoute;
      case LoyaltySectionType.profile:
        return this.editProfileRoute;
      case LoyaltySectionType.orders:
        return this.previousOrdersRoute;
      case LoyaltySectionType.savedAddresses:
        return this.savedAddressesRoute;
      // Handle additional section types if needed
      default:
        return '/';
    }
  }

  // Determine if the section should be displayed
  shouldDisplaySection(section: LoyaltySection): boolean {
    if (!section.show_route) {
      return false;
    }
    switch (section.section_type) {
      case 'rewards':
        return this.displayVendorSetup?.loyalty_provider !== 'none';
      case 'orders':
        // Prevent the "orders" tab from appearing in tableside mode
        if (this.modeService.mode === 'tableside') {
          return false;
        }
        return this.displayVendorSetup?.order_history_provider !== 'none';
      case 'activity':
        return this.displayVendorSetup?.user_provider !== 'none';
      case 'payments':
        // Assuming you want to display the payments section
        return true;
      default:
        return true;
    }
  }

  getIconForSection(section: LoyaltySection): string {
    switch (section.section_type) {
      case LoyaltySectionType.rewards:
        return section.section_icon;
      case LoyaltySectionType.offers:
        return section.section_icon;
      case LoyaltySectionType.activity:
        return section.section_icon;
      case LoyaltySectionType.payments:
        return section.section_icon;
      case LoyaltySectionType.profile:
        return section.section_icon;
      case LoyaltySectionType.orders:
        return section.section_icon;
      case LoyaltySectionType.purchaseRewards:
        return section.section_icon;
      case LoyaltySectionType.transferBalance:
        return section.section_icon;
      // Add icons for other sections as needed
      default:
        return 'list';
    }
  }

  // Sorting function
  sortSectionLinks(a: LoyaltySection, b: LoyaltySection): number {
    const aSort = Number.isNaN(parseInt(a.route_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(a.route_order, 10);
    const bSort = Number.isNaN(parseInt(b.route_order, 10)) ? Number.MAX_SAFE_INTEGER : parseInt(b.route_order, 10);
    return aSort - bSort;
  }

  isActiveRoute(route: string): boolean {
    return this.router.isActive(route, true);
  }

  routeToInbox() {
    this.navigation.navigateToInboxPage();
  }

  get isMobileView(): boolean {
    return this.mobileService.isMobile || this.modeService.mode === 'tableside';
  }
}
