import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { OloConfiguration } from '../../../vendors/directus/interfaces/olo-configuration.interface';
import { filter, map, take } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { GlobalStateModel } from '../../../store/state.model';
import { Store } from '@ngxs/store';

@Injectable()
export class MenuGuard {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  canActivate(): Observable<boolean> {
    return this.store
      .select((state: GlobalStateModel) => state.app.oloConfig)
      .pipe(
        filter(oc => !!oc),
        take(2),
        map(
          (oloConfig: OloConfiguration) => {
            if (oloConfig.wrap_olo_serve && Capacitor.getPlatform() !== 'web') {
              this.router.navigateByUrl('browser-wrapper').then(r => {});
              return false;
            } else {
              return true;
            }
          },
          () => {
            return true;
          }
        )
      );
  }
}
