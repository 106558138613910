import { Pipe, PipeTransform } from '@angular/core';
import { OptionGroup } from 'src/interfaces/option-group.interface';

@Pipe({
  name: 'optiongroupfilter',
  pure: false,
})
export class OptionGroupFilter implements PipeTransform {
  transform(items: OptionGroup[], slideSection: any): OptionGroup[] {
    if (!items || !slideSection) {
      return items;
    }
    // Return all items if parent is not a slider section
    return items.filter(item => !slideSection.includes(item.optionGroupParent));
  }
}
