<ng-container *ngIf="(mainSettings$ | async) as mainSettings">
  <ng-container *ngIf="(order$ | async) as order">
    <ng-container *ngIf="!(allProductsAlwaysAvailable$ | async) || !(groupOrder$ | async)">
      <div class="order-controls text-start" [ngClass]="{'border-bottom bg-white': isMobileView}">
        <ng-container *ngIf="!(allProductsAlwaysAvailable$ | async)">
          <app-menu-limit-toggle class="mt-3 mb-0 mb-3 mb-lg-0"></app-menu-limit-toggle>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
