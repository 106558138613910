import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Branding} from '../../../../vendors/directus/interfaces/branding.interface';

@Component({
    selector: 'app-distance-confirmation',
    templateUrl: './distance-confirmation.component.html',
    styleUrls: ['distance-confirmation.component.scss'],
})
export class DistanceConfirmationComponent {
    @Select(state => state.app.branding) branding$: Observable<Branding>;
    @Input() distance = false;
    @Output() continueEmit = new EventEmitter<any>();

    constructor(
        public activeModal: NgbActiveModal
    ) {}

    continueClicked() {
        this.continueEmit.emit();
    }

    cancelClicked() {
        this.activeModal.close('Cancel click');
    }
}
