import { Injectable, Injector } from '@angular/core';
import {filter, map, take} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CmsVendorSetupService } from './cms-vendor-setup.service';
import { MenuProvider } from '../../providers/menu-provider.interface';
import { NovaDineProviderService } from 'src/vendors/novadine/novadine-provider.service';
import { OLOProviderService } from 'src/vendors/olo/olo-provider.service';
import { PunchhOLOProviderService } from 'src/vendors/punchh-olo/punchh-olo-provider.service';
import {ItwercsProviderService} from '../../vendors/itwercs/itwercs-provider.service';
import ProviderConfig from '../../vendors/config/vendor.config';
import {Select} from '@ngxs/store';
import {VendorSetup} from '../../interfaces/vendor.interface';
import {TastyIgniterProviderService} from '../../vendors/tasty-igniter/tasty-igniter-provider.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

    private currentService: MenuProvider;

    constructor(
        private vendorService: CmsVendorSetupService,
        private injector: Injector
    ) {}

    getService(): Observable<MenuProvider> {
        if (this.currentService) {
            return of(this.currentService);
        } else {
            return this.vendorSetup$.pipe(filter(vs => !!vs), take(1), map(vs => {
                return this.chooseService(vs.menu_provider);
            }));
            // return this.vendorService.getService().pipe(switchMap(service => {
            //     return service.getVendorSetup().pipe(map(setup => {
            //         return this.chooseService(setup.menu_provider);
            //     }));
            // }));
        }
    }

    private chooseService(vendor: string): MenuProvider {
        switch (vendor) {
            case ProviderConfig.novadine: return this.currentService = this.injector.get(NovaDineProviderService);
            case ProviderConfig.olo: return this.currentService = this.injector.get(OLOProviderService);
            case ProviderConfig.punchhOlo: return this.currentService = this.injector.get(PunchhOLOProviderService);
            case ProviderConfig.itwercs: return this.currentService = this.injector.get(ItwercsProviderService);
            case ProviderConfig.tastyIgniter: return this.currentService = this.injector.get(TastyIgniterProviderService);
            default: return null;
        }
    }
}
