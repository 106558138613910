import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navLink',
  pure: false
})
export class NavLinkPipe implements PipeTransform {

  transform(url: string): boolean {
    return window.location.href.indexOf(url) !== -1;
  }

}
