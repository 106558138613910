import { Injectable } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';

import {
  CreateNewOrder,
  AddToOrder,
  RemoveFromOrder,
  UpdateOrderItem,
  ValidateOrder,
  SubmitPayment,
  SetPreviousOrder,
  AddPaymentInfo,
  CheckGiftCardDetails,
  ResetGiftCardDetails,
  AddSaleConfig,
  AddShippingInfo,
  ReloadGiftCard,
} from '../actions/gift-card-order.actions';

import { GiftCardConfig, GiftCardItem, GiftCardOrder } from '../../models';

import { GiftCardPurchaseService } from '../../../../services/vendor-config-service/gift-card-purchase.service';
import { TransactionResponse } from '../../../../vendors/paytronix/interfaces/transaction-response.interface';
import { GiftCard } from '../../../../interfaces/giftcard.interface';
import { SetRewardsBalances } from '../../../../store/actions/user.actions';
import { User } from '../../../../interfaces/user.interface';
import { CalculatePriceResponse } from '../../../../vendors/paytronix/interfaces/calculate-price-response.interface';

export interface GiftCardOrderStateModel {
  saleConfig: any;
  order: GiftCardOrder;
  previousOrder: GiftCardOrder;
  cardBalance: number;
  transactionHistory: TransactionResponse;
  confirmation: number;
  newCard: GiftCard;
  calculateResponse: CalculatePriceResponse;
}

@State<GiftCardOrderStateModel>({
  name: 'giftCardOrder',
  defaults: {
    saleConfig: null,
    order: null,
    previousOrder: null,
    cardBalance: null,
    transactionHistory: null,
    confirmation: null,
    newCard: null,
    calculateResponse: null,
  },
})
@Injectable()
export class GiftCardOrderState {
  constructor(
    private store: Store,
    private giftCardPurchaseService: GiftCardPurchaseService
  ) {}

  @Action(AddSaleConfig)
  addSaleConfig(ctx: StateContext<GiftCardOrderStateModel>, action: AddSaleConfig) {
    return this.giftCardPurchaseService.getService().pipe(
      switchMap(gcpService => {
        return gcpService.getItems(action.flowType).pipe(
          filter(sc => sc !== null),
          take(1),
          map((saleConfig: GiftCardConfig) => {
            const state = produce(ctx.getState(), draft => {
              draft.saleConfig = saleConfig;
              draft.order.orderType = action.flowType;
            });
            return ctx.patchState(state);
          })
        );
      })
    );
  }

  @Action(CreateNewOrder)
  createNewOrder(ctx: StateContext<GiftCardOrderStateModel>, action: CreateNewOrder) {
    return ctx.patchState({
      order: {
        orderType: null,
        giftCards: [],
        subTotal: 0,
        total: 0,
        paymentInfo: null,
        billingInfo: null,
        shippingInfo: null,
      },
    });
  }

  @Action(AddToOrder)
  addToOrder(ctx: StateContext<GiftCardOrderStateModel>, action: AddToOrder) {
    const order = produce(ctx.getState().order, draft => {
      draft.giftCards.push(action.orderItem);
      let total = 0;
      draft.giftCards.forEach((card: GiftCardItem) => {
        total += Number(card.value) * card.quantity;
      });
      draft.subTotal = total;
      draft.total = total;
    });
    ctx.dispatch(new ValidateOrder(order));
    return ctx.patchState({
      order,
    });
  }

  @Action(RemoveFromOrder)
  removeFromOrder(ctx: StateContext<GiftCardOrderStateModel>, action: RemoveFromOrder) {
    const order = produce(ctx.getState().order, draft => {
      if (action.index > -1) {
        draft.giftCards.splice(action.index, 1);
      }
      let total = 0;
      draft.giftCards.forEach((card: GiftCardItem) => {
        total += Number(card.value) * card.quantity;
      });
      draft.subTotal = total;
      draft.total = total;
    });
    ctx.dispatch(new ValidateOrder(order));
    return ctx.patchState({
      order,
    });
  }

  @Action(UpdateOrderItem)
  updateOrderItem(ctx: StateContext<GiftCardOrderStateModel>, action: UpdateOrderItem) {
    return this.giftCardPurchaseService.getService().pipe(
      map(gcpService => {
        const order = ctx.getState().order;
        // Do something with the order
        ctx.dispatch(new ValidateOrder(order));
        return ctx.patchState({
          order,
        });
      })
    );
  }

  @Action(AddPaymentInfo)
  addPaymentInfo(ctx: StateContext<GiftCardOrderStateModel>, action: AddPaymentInfo) {
    const order = produce(ctx.getState().order, draft => {
      draft.paymentInfo = action.payment;
      draft.billingInfo = action.billing;
    });
    return ctx.patchState({
      order,
    });
  }

  @Action(AddShippingInfo)
  addShippingInfo(ctx: StateContext<GiftCardOrderStateModel>, action: AddShippingInfo) {
    const order = produce(ctx.getState().order, draft => {
      draft.shippingInfo = action.shipping;
    });
    return ctx.patchState({
      order,
    });
  }

  @Action(ValidateOrder)
  validateOrder(ctx: StateContext<GiftCardOrderStateModel>, action: ValidateOrder) {
    return this.giftCardPurchaseService.getService().pipe(
      switchMap(gcpService => {
        return gcpService.getPrices(ctx.getState().order).pipe(
          map(orderRes => {
            return ctx.patchState({
              calculateResponse: orderRes,
            });
          })
        );
      })
    );
  }

  @Action(SubmitPayment)
  submitPayment(ctx: StateContext<GiftCardOrderStateModel>, action: SubmitPayment) {
    return this.giftCardPurchaseService.getService().pipe(
      switchMap(gcpService => {
        const order = ctx.getState().order;
        return gcpService.purchaseCards(order, ctx.getState().calculateResponse).pipe(
          map(orderRes => {
            if (orderRes.errorCode) {
              throw new Error(orderRes.errorMessage);
            } else {
              return ctx.patchState({
                confirmation: orderRes.order.orderNumber,
                newCard: {
                  cardNumber: orderRes.order.saleGroups[0].saleItems[0].createdCard[0].printedCardNumber,
                  cardPin: orderRes.order.saleGroups[0].saleItems[0].createdCard[0].regCode,
                  balance: parseFloat(orderRes.order.saleGroups[0].saleItems[0].price),
                },
                previousOrder: order,
              });
            }
          })
        );
      })
    );
  }

  @Action(SetPreviousOrder)
  setPreviousOrder(ctx: StateContext<GiftCardOrderStateModel>, action: SetPreviousOrder) {
    // tslint:disable-next-line:max-line-length
    return this.store
      .select(state => state.giftCardOrder.order)
      .pipe(
        filter(gco => gco !== null),
        take(1),
        map((order: GiftCardOrder) => {
          return ctx.patchState({
            order: null,
            previousOrder: order,
          });
        })
      );
  }

  @Action(CheckGiftCardDetails)
  checkGiftCardTransactionHistory(ctx: StateContext<GiftCardOrderStateModel>, action: CheckGiftCardDetails) {
    return this.giftCardPurchaseService.getService().pipe(
      switchMap(gcpService => {
        return gcpService.getTransactionHistory(action.cardNumber, action.pin).pipe(
          map(orderRes => {
            if (orderRes.errorCode) {
              throw new Error(orderRes.errorMessage);
            } else {
              return ctx.patchState({
                cardBalance: orderRes.svCurrentBalance,
                transactionHistory: orderRes,
              });
            }
          })
        );
      })
    );
  }

  @Action(ResetGiftCardDetails)
  resetGiftCardTransactionHistory(ctx: StateContext<GiftCardOrderStateModel>, action: ResetGiftCardDetails) {
    return ctx.patchState({
      cardBalance: null,
      transactionHistory: null,
    });
  }

  @Action(ReloadGiftCard)
  reloadGiftCard(ctx: StateContext<GiftCardOrderStateModel>, action: ReloadGiftCard) {
    return this.giftCardPurchaseService.getService().pipe(
      switchMap(gcpService => {
        return gcpService.reloadGiftCard(action.rechargeOrder).pipe(
          map(orderRes => {
            if (orderRes.errorCode) {
              throw new Error(orderRes.errorMessage);
            } else {
              // return ctx.patchState({
              //   cardBalance: orderRes.svCurrentBalance,
              //   transactionHistory: orderRes
              // });
              const user: User = this.store.selectSnapshot(state => state.user.user);
              return ctx.dispatch(new SetRewardsBalances(user.userID));
            }
          })
        );
      })
    );
  }
}
