import { Pipe, PipeTransform } from '@angular/core';
import {Store} from '@ngxs/store';
import {OrderItem} from '../interfaces/product.interface';
import {GlobalStateModel} from '../store/state.model';

@Pipe({
  name: 'isSingleUse',
  pure: false
})
export class IsSingleUsePipe implements PipeTransform {

  constructor(private store: Store) {
  }

  transform(item: OrderItem): boolean {
    const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.menu.menu);
    if (menu) {
      return !!menu.singleUseProducts?.products.find((product) => product.productID === item.productID);
    }
    return false;
  }

}
