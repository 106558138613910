<!-- page-link.component.html -->
<ng-container *ngIf="shouldDisplayPage()">
  <!-- Simple Link -->
  <ng-container *ngIf="!hasChildren(); else dropdownTemplate">
    <a
      class="navbar-link other ms-0 me-4 my-auto"
      style="color: var(--nav-text-color)"
      tabindex="0"
      (click)="onPageClicked()"
      (keydown.enter)="onPageClicked()"
      [class.active-link]="isActive">
      {{ page.title }}
    </a>
  </ng-container>

  <!-- Dropdown Link -->
  <ng-template #dropdownTemplate>
    <div
      ngbDropdown
      container="body"
      #d="ngbDropdown"
      class="other ms-0 me-4"
      [class.active-link]="isActive"
      tabindex="0"
      (keydown.enter)="toggleDropdown(d)"
      [autoClose]="'outside'"
      [placement]="['bottom-start', 'bottom-end', 'top-start', 'top-end']">

      <!-- Dropdown Toggle Button -->
      <button
        type="button"
        class="navbar-link btn btn-link text-decoration-none dropdown-toggle ms-0"
        id="dropdownBasic{{ uniqueId }}"
        ngbDropdownToggle
        aria-haspopup="true"
       [attr.aria-expanded]="d.isOpen">
        {{ page.title }}
      </button>

      <!-- Dropdown Menu -->
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic{{ uniqueId }}">
        <button
          *ngFor="let child of page.child_navigation"
          class="dropdown-item navbar-link m-0"
          type="button"
          tabindex="0"
          (click)="onChildPageClicked(child)"
          (keydown.enter)="onChildPageClicked(child)"
          ngbDropdownItem>
          {{ child.name }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
