<div class="popup-body">
  <ion-row class="ion-justify-content-end">
    <ion-col class="ion-float-end" size="auto">
      <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
        <i class="bi bi-x-circle font-size-h1 d-flex"></i>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="tableside-modal-contents ion-no-padding">
      <ion-row class="ion-justify-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
          <title>Beer</title>
          <path d="M352 200v240a40.12 40.12 0 01-40 40H136a40.12 40.12 0 01-40-40V224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
          <path d="M352 224h40a56.16 56.16 0 0156 56v80a56.16 56.16 0 01-56 56h-40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M224 256v160M288 256v160M160 256v160M320 112a48 48 0 010 96c-13.25 0-29.31-7.31-38-16H160c-8 22-27 32-48 32a48 48 0 010-96 47.91 47.91 0 0126 9"/>
          <path d="M91.86 132.43a40 40 0 1160.46-52S160 91 160 96M145.83 64.71C163.22 44.89 187.57 32 216 32c52.38 0 94 42.84 94 95.21a95 95 0 01-1.67 17.79" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/>
        </svg>
      </ion-row>
      <ion-row class="header-font modal-title">
        How many people are you buying for?
      </ion-row>
      <ion-row class="modal-text ln-1">
        Buffalo Wings and Rings has a limit of 2 alchoholic drinks per person, per order.
      </ion-row>

        <ion-row class="guest-count">

          <ion-col size="2">
            <button class="amt-btn">
              1
            </button>
          </ion-col>
          <ion-col size="2">
            <button class="amt-btn">
              2
            </button>
          </ion-col>
          <ion-col size="2">
            <button class="amt-btn">
              3
            </button>
          </ion-col>
          <ion-col size="2">
            <button class="amt-btn">
              4
            </button>
          </ion-col>
          <ion-col size="4">
              <ion-input class="custom-field" placeholder="Custom">
              </ion-input>
          </ion-col>

        </ion-row>

    </ion-col>
  </ion-row>
  <ion-row class="d-flex justify-content-center flex-row">

    <!-- //TODO: route back to drink menu but set limit on drinks based off of selection -->
    <!-- Also, if customer orders more than "set limit" pop up modal again and set new limit -->

    <button type="button" class="de-primary-btn modal-btns">
      Confirm
    </button>
  </ion-row>
</div>
