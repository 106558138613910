import {Component, OnInit, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import {GiftCardConfig} from '@modules/gift-card/models';
import {GenericComponent} from '@modules/ecommerce/models';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {DirectusService} from '../../../../vendors/directus/directus.service';

declare var Spreedly: any;

@Component({
  selector: 'app-balance-reload-payment-info',
  templateUrl: './balance-reload-payment-info.component.html',
  styleUrls: ['balance-reload-payment-info.component.scss'],
})
export class BalanceReloadPaymentInfoComponent implements OnInit, AfterViewInit, GenericComponent {
  @Select(state => state.giftCardOrder.saleConfig) saleConfig$: Observable<GiftCardConfig>;
  @Input() flowType: string;
  @Input() hideCartFunctionality: boolean;
  @Input() set sectionComplete(val: boolean) {
    this.itemConfigured = val;
  }
  @Input() itemTotal = 0;
  @Output() sendItemTotal = new EventEmitter<number>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() passData = new EventEmitter<UntypedFormGroup>();
  @Output() createCard = new EventEmitter<number>();
  itemConfigured = false;
  data = null;
  token = '';
  paymentInfo = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    // creditCardNumber: new FormControl(0, Validators.required),
    expiration: new UntypedFormControl(0, Validators.required),
    // cvv: new FormControl(0, Validators.required),
    cardType: new UntypedFormControl('', Validators.required),
    zipCode: new UntypedFormControl('', [Validators.required, Validators.maxLength(5)])
  });
  minDate = '';
  constructor(
    private directus: DirectusService
  ) {
    this.directus.getPaytronixSettings().subscribe((settings) => {
      this.token = settings.spreedly_token;
    });
  }
  ngOnInit() {
    this.saleConfig$.subscribe((saleConfig: GiftCardConfig) => {
      this.data = saleConfig;
    });
    this.getTodaysDate();
    this.paymentInfo.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.isValid.emit(this.paymentInfo.valid);
      if (this.paymentInfo.valid) {
        this.passData.emit(this.paymentInfo);
      }
    });
  }
  ngAfterViewInit() {
    console.log('init tried');
    const initSpreedly = setInterval(() => {
      if (Spreedly) {
        Spreedly.init(this.token, {
          numberEl: 'spreedly-number',
          cvvEl: 'spreedly-cvv'
        });
        console.log('init ran');
        clearInterval(initSpreedly);
      }
    }, 1000);
  }
  changeCardType(event: any) {
    this.paymentInfo.get('cardType').setValue(event.target.value);
    setTimeout(() => {
      this.isValid.emit(this.paymentInfo.valid);
      if (this.paymentInfo.valid) {
        this.passData.emit(this.paymentInfo);
      }
    }, 300);
  }
  getTodaysDate() {
    const today = new Date();
    const m = today.getMonth() + 1;
    const y = today.getFullYear();
    let month = m.toString();
    if (m < 10) { month = '0' + m.toString(); }
    this.minDate = y + '-' + month;
  }
}
