import {Component, TemplateRef, ViewChild} from '@angular/core';
import {Location as URLLocation} from '@angular/common';
import {DateTimeService} from '../../../../services/date-time.service';
import {Select, Store} from '@ngxs/store';
import {
    CreateNewOrder,
    GetCurrentGroupOrder,
    StartGroupOrder
} from '../../../../store/actions/order.actions';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NavigationService} from '@modules/navigation/services';
import {ModeService} from '../../../../services/mode.service';
import {Router} from '@angular/router';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {User} from '../../../../interfaces/user.interface';
import {SubscriptionComponent} from '@common/components';
import {MobileService} from '../../../../services/mobile.service';
import {SetRouteBack, UpdateTitle} from '../../../../store/actions/app.actions';
import {Order} from '../../../../interfaces/order.interface';
import {TextField} from '../../../../vendors/directus/interfaces/text-field.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-start-group-order',
    templateUrl: './start-group-order.component.html',
    styleUrls: ['start-group-order.component.scss'],
})
export class StartGroupOrderComponent extends SubscriptionComponent {
    @Select(state => state.order.order) order$: Observable<Order>;
    @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
    @Select(state => state.app.textField) textFields$: Observable<TextField>;
    @ViewChild('infoModal') infoModalRef: TemplateRef<any>;
    title = 'start group order';
    timeIndex = -1;
    availableTimes = [];
    startAttempt = false;
    displayUser: User;
    displayOrder: Order;
    loading = false;
    error: string;
    groupOrderInstructions = 'Fill out the information below to create a group order and invite others to your order.\n' +
        'Once others have begun adding items you will see their orders appear in your cart. You can also see your deadline and note in the cart.\n' +
        'To place the order, proceed to checkout as usual.';

    deadlineForm = new UntypedFormGroup({
        deadline: new UntypedFormControl('', [Validators.required]),
    });
    membersForm = new UntypedFormGroup({
        members: new UntypedFormControl(''),
    });
    noteForm = new UntypedFormGroup({
        note: new UntypedFormControl(''),
    });

    constructor(
        public modalService: NgbModal,
        private store: Store,
        private router: Router,
        public mode: ModeService,
        public mobile: MobileService,
        private dateTimeService: DateTimeService,
        private navigation: NavigationService,
        private urlLocation: URLLocation
    ) {
        super();
        this.availableTimes = this.dateTimeService.getGroupOrderTimesToday();
        this.subs.push(
            this.user$.subscribe(user => {
                this.displayUser = user ? user : null;
            }),
            this.order$.subscribe(order => {
                this.displayOrder = order ? order : null;
            })
        );
        if (mode.mode === 'tableside') {
            this.store.dispatch(new SetRouteBack('/'));
        } else {
            this.order$.pipe(filter(o => !!o)).subscribe(order => {
                this.store.dispatch(new SetRouteBack('menu/' + order.location.locationID));
            })
        }

        this.store.dispatch(new UpdateTitle(this.title));
    }

    onTimeChange(event: any) {
        this.timeIndex = this.availableTimes.findIndex((time: any) => time.value === event.target.value);
    }

    onTimeButtonClick(index: number) {
        this.timeIndex = index;
        this.deadlineForm.controls['deadline'].setValue(this.availableTimes[this.timeIndex].value);
    }

    startGroupOrder() {
        this.startAttempt = true;
        this.loading = true;
        const deadline = new Date(this.availableTimes[this.timeIndex].value);
        const members = this.members.value.split(',');
        const guestName = this.displayUser.firstName + ' ' + this.displayUser.lastName;
        this.store.dispatch(new CreateNewOrder(this.displayOrder.location.locationID, this.displayOrder.handoffType, this.displayOrder.deliveryAddress)).subscribe(() => {
            this.store.dispatch(new StartGroupOrder(this.note.value, members, deadline, guestName)).subscribe(() => {
                this.store.dispatch(new GetCurrentGroupOrder(guestName));
                this.navigation.navigateToGroupOrderDetailsPage();
            }, (error: any) => {
                this.error = error.error.message;
                this.loading = false;
            });
        });
    }

    closePage() {
        // Allow fade out animation to play before navigating back
        setTimeout(
            // () => this.router.navigate(['..']),
            () => this.urlLocation.back(),
            100
        );
    }

    get deadline() {
        return this.deadlineForm.get('deadline');
    }

    get members() {
        return this.membersForm.get('members');
    }

    get note() {
        return this.noteForm.get('note');
    }

    openInfoModal(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.modalService.dismissAll();
        this.modalService.open(this.infoModalRef, {
            centered: true,
            animation: true,
            windowClass: 'card-info-modal'
        });
    }
}
