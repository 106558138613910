<div class="mobile-modal-header-back">
  <button class="back-button" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
  </button>
  <div class="header-title header-font fw-bold">Rewards</div>
</div>
<ion-content class="mobile-modal-content">
  <div class="activity-container">

    <h2 class="header-font fw-bold">
      Account Activity
    </h2>

    <!--  populated state-->
    <ng-container *ngIf="activity$ | async as activity else noActivity">
      <div class="activity-cards-container pb-3" *ngIf="activity.length else noActivity">
        <ng-container *ngFor="let event of activity">
          <app-activity-event-card
            class="w-100"
            [event]="event"
          ></app-activity-event-card>
        </ng-container>
      </div>
    </ng-container>

    <!--  empty state-->
    <ng-template #noActivity>
      <div class="activity-cards-empty">

        <app-custom-icon
          default="bi-activity"
          customIconKey="activity_navigation_icon"
          size="88"
          altText="Activity"
        ></app-custom-icon>

        <div class="d-flex flex-column gap-1 text-center">
          <h4 class="header-font fw-bold">
            You don't have any activity
          </h4>
          <p>
            Check back later to see your account activity
          </p>
        </div>
      </div>
    </ng-template>

  </div>
</ion-content>
