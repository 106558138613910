import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { LocationService } from 'src/services/vendor-config-service/location.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-call-server',
  templateUrl: './call-server.component.html',
  styleUrls: ['./call-server.component.scss'],
})
export class CallServerComponent {
  @Input() error;
  @Output() submitted = new EventEmitter();
  @Input() locationID: string;

  callItemsCustomer = ['I need Silverware', 'I need a To Go Box', 'I need Napkins', 'Call a Server', 'Speak to a Manager'];

  callItemsServer = ['silverware', 'To Go Box', 'Napkins', 'Server', 'Manager'];

  isLoading = false;

  addInformation = new UntypedFormGroup({
    information: new UntypedFormControl(),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private directus: DirectusService,
    private locationsService: LocationService
  ) {}

  submitClicked() {
    this.isLoading = true;
    const selected = [];
    this.callItemsCustomer.forEach((item: string, i: number) => {
      const el = document.getElementById(item) as HTMLInputElement;
      if (el.checked) {
        selected.push({ newItem: true, type: this.callItemsServer[i] });
      }
    });
    const information = this.addInformation.get('information').value;

    const tableNumber = Number(sessionStorage.getItem('tablenumber'));
    const locationId = Number(sessionStorage.getItem('tablesidelocation'));
    this.locationsService.getService().subscribe(
      service => {
        service.getLocationtableNumber(tableNumber, locationId).subscribe(table => {
          const data = {
            additional_instructions: information,
            service_types: selected,
            table_number: table,
            created_time: moment().format('YYYY-MM-DD HH:mm:ss'),
            location_id: this.locationID,
          };
          this.directus.sendServiceRequest(data).subscribe(
            () => {
              this.isLoading = false;
              this.submitted.emit();
            },
            error => {
              console.log('error', error);
              this.isLoading = false;
            }
          );
        });
      },
      error => {
        console.log('error', error);
        this.isLoading = false;
      }
    );
  }
}
