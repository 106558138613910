import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {SectionResponseData} from '@modules/ecommerce/models';
import {BillingInfo, GiftCardConfig, GiftCardItem, PaymentInfo} from '@modules/gift-card/models';
import {User} from '../../../interfaces/user.interface';

@Injectable()
export class MappingService {
  constructor() {}

  getMapping$(): Observable<{}> {
      return of({});
  }

  // Balance Reload

  sectionResponseDataToBalanceReloadItem(sectionData: SectionResponseData, saleConfig: GiftCardConfig, user: User): GiftCardItem {
    return ({
      id: saleConfig.id,
      design: saleConfig.cardDesigns[0].id,
      image: null,
      value: sectionData.groups[0].value.value,
      sender: user.firstName + ' ' + user.lastName,
      recipient: user.firstName + ' ' + user.lastName,
      recipientEmail: user.email,
      message: 'This gift card balance was transferred to ' + user.firstName + ' ' + user.lastName + '\'s loyalty balance',
      deliveryDate: null,
      quantity: 1,
    } as GiftCardItem);
  }

  sectionResponseDataToBalanceReloadPaymentInfo(sectionData: SectionResponseData): PaymentInfo {
    return ({
      cardName: sectionData.groups[1].value.name,
      cardNumber: sectionData.groups[1].value.creditCardNumber,
      expirationDate: {
        month: sectionData.groups[1].value.expiration.split('-')[1],
        year: sectionData.groups[1].value.expiration.split('-')[0]
      },
      cvv: sectionData.groups[1].value.cvv,
      zipCode: sectionData.groups[1].value.zipCode,
    } as PaymentInfo);
  }

  sectionResponseDataToBalanceReloadBillingInfo(sectionData: SectionResponseData): BillingInfo {
    return ({
      firstName: sectionData.groups[2].value.firstName,
      lastName: sectionData.groups[2].value.lastName,
      phoneNumber: sectionData.groups[2].value.phoneNumber,
      emailAddress: sectionData.groups[2].value.email,
      address: {
        streetAddress: sectionData.groups[2].value.streetAddress,
        suiteNo: sectionData.groups[2].value.suiteNumber,
        city: sectionData.groups[2].value.city,
        state: sectionData.groups[2].value.state,
        country: sectionData.groups[2].value.country,
        zipCode: sectionData.groups[2].value.zipCode
      }
    } as BillingInfo);
  }
}
