<div id="open-modal" [class.text-muted]="!selectedDate" class="form-control w-100 cms-input-radius">
  {{ selectedDate ? (selectedDate | date: 'mediumDate') : 'Enter birthday' }}
</div>

<ion-modal #modal trigger="open-modal" [initialBreakpoint]="breakpoint" [breakpoints]="[breakpoint]" [handle]="false" (ionModalDidPresent)="scrollToSelectedYear(selectedYear)" style="pointer-events: none">
  <ng-template>
    <div class="datepicker-modal">
      <div class="header">
        <button class="cancel-btn" (click)="modal.dismiss()">CANCEL</button>
        <button class="confirm-btn" (click)="confirmDate(); modal.dismiss()">CONFIRM</button>
      </div>
      <div class="date-options-container">
        <div class="date-options-selector">
          <div class="year date-options-column" id="year1" #year1 (scroll)="updateYear($event)">
            <div *ngFor="let year of years" class="text-center" [ngClass]="{'selected-option': selectedYear == year}">{{ year }}</div>
          </div>
          <div class="month date-options-column" id="month1" (scroll)="updateMonth($event)">
            <div *ngFor="let month of months; index as index" class="text-center" [ngClass]="{'selected-option': selectedMonth == index}">{{ month }}</div>
          </div>
          <div class="day date-options-column" id="day1" (scroll)="updateDay($event)">
            <div *ngFor="let day of days" class="text-center" [ngClass]="{'selected-option': selectedDay == day}">{{ day }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ion-modal>
