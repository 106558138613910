import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { ClearOrder } from '../../../../store/actions/order.actions';
import { TrancloudProviderService } from '../../../../vendors/datacap-trancloud/services/trancloud-provider.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Order } from '../../../../interfaces/order.interface';
import { NavigationService } from '@modules/navigation/services';
import { SubscriptionComponent } from '@common/components';
import { PinPadDeviceService } from '../../../../vendors/datacap-trancloud/services/pin-pad-device.service';
import { KioskConfig } from '../../../../vendors/directus/interfaces/kiosk-config.interface';
import { CardTerminalProvider } from '../../../../providers/card-terminal-provider.interface';
import { CardTerminalService } from '../../../../services/vendor-config-service/card-terminal.service';
import { SetKioskConfig } from '../../../../store/actions/app.actions';
import { ToastService } from '../../../../services/toast.service';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-kiosk-configuration',
  templateUrl: './kiosk-configuration.component.html',
  styleUrls: ['kiosk-configuration.component.scss'],
})
export class KioskConfigurationComponent extends SubscriptionComponent implements OnInit {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Output() restartKiosk = new EventEmitter<any>();
  order: Order;
  kioskConfigs: KioskConfig[] = [];
  configurePinPadLoading = false;
  resetPinPadLoading = false;
  cancelTransactionLoading = false;

  public version: string = packageJson.version;
  constructor(
    private modalService: NgbModal,
    private store: Store,
    private navigation: NavigationService,
    private directus: DirectusService,
    public pinPad: PinPadDeviceService,
    private terminal: CardTerminalService,
    private toast: ToastService
  ) {
    super();
  }
  ngOnInit() {
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.order = order;
        }
      }),
      this.directus.getKioskConfigs().subscribe(kioskConfigs => {
        this.kioskConfigs = kioskConfigs;
        console.log(kioskConfigs);
      })
    );
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  configureKioskClicked(config: any) {
    this.configureKioskProvider(config);
  }
  configurePinPadClicked() {
    this.configurePinPadLoading = true;
    this.terminal.getService().subscribe((service: CardTerminalProvider) => {
      service.connectToReader().subscribe(() => {
        this.configurePinPadLoading = false;
        this.toast.success('Pin pad connected');
      });
    });
  }
  resetPinPadClicked() {
    this.resetPinPadLoading = true;
    this.terminal.getService().subscribe((service: CardTerminalProvider) => {
      service.resetPinPad().subscribe(() => {
        this.resetPinPadLoading = false;
        this.toast.success('Pin pad reset');
      });
    });
  }
  cancelTransactionClicked() {
    this.cancelTransactionLoading = true;
    this.terminal.getService().subscribe((service: CardTerminalProvider) => {
      service.cancelTransaction().subscribe(() => {
        this.cancelTransactionLoading = false;
        this.toast.success('Transaction canceled');
      });
    });
  }
  configureKioskProvider(config: KioskConfig) {
    this.store.dispatch(new SetKioskConfig(config));
    sessionStorage.setItem('kiosknumber', config.kiosk_id);
    localStorage.setItem('kiosklocation', config.location_id.toString());
    this.terminal.getService().subscribe(async (service: CardTerminalProvider) => {
      await service.setupKioskConfig(config, true).toPromise();
    });
  }
  resetKioskClicked() {
    if (this.order && this.order.location) {
      const orderLocation = this.order.location.locationID;
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.closeModal();
        this.navigation.navigateToKioskStartOrderPage(orderLocation);
      });
    } else {
      this.closeModal();
      this.restartKiosk.emit();
    }
  }
}
