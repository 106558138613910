<ng-container *ngIf="message">
  <ion-item [button]="true" detail="false" (click)="openInboxMessage()" class="de-border-bottom">
    <div class="unread-indicator-wrapper" slot="start">
      <div class="unread-indicator" *ngIf="!message.read"></div>
      <div class="read-indicator" *ngIf="message.read"></div>
    </div>
    <ion-label class="ion-padding-vertical ion-no-margin" [class.read]="message.read">
      <strong class="line-clamp-1" style="display: -webkit-box !important;">{{ message.title }}</strong>
      <ion-note class="line-clamp-1">
        {{ message.body }}
      </ion-note>
    </ion-label>
    <div class="metadata-end-wrapper" slot="end">
      <ion-note [class.read]="message.read">
        {{ message.date | date: 'shortDate' }}
      </ion-note>
      <ion-icon name="chevron-forward"></ion-icon>
    </div>
  </ion-item>
</ng-container>

<ng-container *ngIf="notification">
  <ion-item [button]="true" detail="false" (click)="openInboxMessage()" class="de-border-bottom">
    <div class="unread-indicator-wrapper" slot="start">
      <div class="unread-indicator" *ngIf="!(notification | checkIfUnreadNotification)"></div>
      <div class="read-indicator" *ngIf="(notification | checkIfUnreadNotification)"></div>
    </div>
    <ion-label class="ion-padding-vertical ion-no-margin" [class.read]="(notification | checkIfUnreadNotification)">
      <strong class="line-clamp-1"
              style="display: -webkit-box !important;">{{ !!notification.title ? notification.title : notification.body }}</strong>
      <ion-note class="line-clamp-1" [class.read]="(notification | checkIfUnreadNotification)">
        {{ notification.subtitle }}
      </ion-note>
    </ion-label>
    <ion-icon name="chevron-forward" slot="end"></ion-icon>
  </ion-item>
</ng-container>
