import { Component, HostListener, Input } from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Order} from '../../../../interfaces/order.interface';
import {GroupOrder} from '../../../../interfaces/group-order.interface';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';
import {User} from '../../../../interfaces/user.interface';
import {NavigationService} from '@modules/navigation/services';
import {GlobalStateModel} from '../../../../store/state.model';
import {ModeService} from "../../../../services/mode.service";

@Component({
  selector: 'app-order-controls',
  templateUrl: './order-controls.component.html',
  styleUrls: ['order-controls.component.scss'],
})
export class OrderControlsComponent {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.menu.allProductsAlwaysAvailable) allProductsAlwaysAvailable$: Observable<boolean>;
  @Input() isMenu: boolean = true;

  isMobileView = false;

  constructor(
    private store: Store,
    private navigation: NavigationService,
    public modeService: ModeService
  ) {}

  ngOnInit() {
    this.checkWindowSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  checkWindowSize() {
    this.isMobileView = window.innerWidth <= 991;
  }

  startGroupOrder() {
    localStorage.setItem('returnUrl', 'group-order/start');
    this.navigation.navigateToStartGroupOrderPage();
  }
}
