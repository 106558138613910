import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyBaseURLService } from '../../services/proxy-base-url.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlybuyHttpService {

  private vendorName = 'flybuy';


  constructor(
    private http: HttpClient,
    private urlService: ProxyBaseURLService,
    ) {
  }

  get<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.get<T>(baseURL + resource);
    }));
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.post<T>(baseURL + resource, body);
    }));
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.put<T>(baseURL + resource, body);
    }));
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.patch<T>(baseURL + resource, body);
    }));
  }

  delete<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.delete<T>(baseURL + resource);
    }));
  }

}
