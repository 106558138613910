import {Component, Input, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../interfaces/user.interface';

export enum UserRoles {
  member = 1,
  guest,
  all,
}

@Component({
  selector: 'app-show-for-role',
  templateUrl: './show-for-role.component.html',
})
export class ShowForRoleComponent implements OnInit {
  roles = UserRoles;
  @Select(state => state.user.user) user$: Observable<User>;
  @Input() role: UserRoles = UserRoles.all;
  ngOnInit() {
    console.log(this.role);
  }
}
