import { GiftCardOrder, GiftCardItem, PaymentInfo, BillingInfo, ShippingInfo } from '../../models';
import { RechargeOrder } from '../../../../interfaces/recharge-order.interface';
import { WebSaleProgramType } from '../../../../vendors/paytronix/interfaces/sale-config-for-account.interface';

export class AddSaleConfig {
  static readonly type = '[Gift Card Order] Add Sale Config';
  constructor(public flowType: WebSaleProgramType | 'RECHARGE') {}
}

export class CreateNewOrder {
  static readonly type = '[Gift Card Order] Create New Order';
  constructor() {}
}

export class AddToOrder {
  static readonly type = '[Gift Card Order] Add to Order';
  constructor(public orderItem: GiftCardItem) {}
}

export class RemoveFromOrder {
  static readonly type = '[Gift Card Order] Remove from Order';
  constructor(public index: number) {}
}

export class UpdateOrderItem {
  static readonly type = '[Gift Card Order] Update Order Item';
  constructor(public index: number) {}
}

export class AddPaymentInfo {
  static readonly type = '[Gift Card Order] Add Payment Info';
  constructor(
    public payment: PaymentInfo,
    public billing: BillingInfo
  ) {}
}

export class AddShippingInfo {
  static readonly type = '[Gift Card Order] Add Shipping Info';
  constructor(public shipping: ShippingInfo) {}
}

export class ValidateOrder {
  static readonly type = '[Gift Card Order] Validate Order';
  constructor(public order: GiftCardOrder) {}
}

export class SubmitPayment {
  static readonly type = '[Gift Card Order] Submit Payment';
  constructor(public order: GiftCardOrder) {}
}

export class SetPreviousOrder {
  static readonly type = '[Gift Card Order] Set Previous Order';
  constructor(public order: GiftCardOrder) {}
}

export class CheckGiftCardDetails {
  static readonly type = '[Gift Card Order] Check Gift Card Details';
  constructor(
    public cardNumber: string,
    public pin: string
  ) {}
}

export class ResetGiftCardDetails {
  static readonly type = '[Gift Card Order] Reset Gift Card Details';
  constructor() {}
}

export class ReloadGiftCard {
  static readonly type = '[Gift Card Order] Reload Gift Card';
  constructor(public rechargeOrder: RechargeOrder) {}
}
