import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InfoCardComponent } from '../auth/components/info-card/info-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeSelectionComponent } from '../cart/components/time-selection/time-selection.component';
import { SkeletonItemComponent } from './components/skeleton-item/skeleton-item.component';
import { SideMenuComponent } from '../navigation/components/side-menu/side-menu.component';
import { FabComponent } from '../loyalty/components/fab/fab.component';
import { HideFabDirective } from '../../directives/hide-fab.directive';
import { NutritionCardComponent } from '../menu/components/nutrition-card/nutrition-card.component';
import { BgLoaderDirective } from '../../directives/bg-loader.directive';
import { KeyboardHideDirective } from '../../directives/keyboard-hide.directive';
import { RewardCardComponent } from '../loyalty/components/reward-card/reward-card.component';
import { OfferCardComponent } from '../menu/components/offer-card/offer-card.component';
import { AllergenBoxComponent } from '../menu/components/allergen-box/allergen-box.component';
import { SubnavigationComponent } from '@modules/navigation/components/subnavigation/subnavigation.component';
import { AccountSubnavComponent } from '@modules/profile/components/account-subnav/account-subnav.component';
import { MobileAccountSubnavComponent } from '@modules/profile/components/account-subnav/mobile/account-subnav.component';
import { DesktopAccountSubnavComponent } from '@modules/profile/components/account-subnav/desktop/account-subnav.component';
import { LogoBannerComponent } from '../navigation/components/logo-banner/logo-banner.component';
import { MenuHeaderComponent } from '../menu/components/menu-header/menu-header.component';
import { MenuCardComponent } from '../menu/components/menu-card/menu-card.component';
import { AllergenIconsComponent } from '../menu/components/allergen-icons/allergen-icons.component';
import { CreditCardCardComponent } from '../profile/components/credit-card-card/credit-card-card.component';
import { VehicleCardComponent } from '../profile/components/vehicle-card/vehicle-card.component';
import { ReorderCardComponent } from '../profile/components/reorder-card/reorder-card.component';
import { NutritionKeyComponent } from '../menu/components/nutrition-key/nutrition-key.component';
import { OptionCardComponent } from '../menu/components/option-card/option-card.component';
import { SliderOptionCardComponent } from '../menu/components/slider-option-card/slider-option-card.component';
import { MobileMenuHeaderComponent } from '../menu/components/menu-header/mobile-menu-header/mobile-menu-header.component';
import { MobileMenuCardComponent } from '../menu/components/menu-card/mobile-menu-card/mobile-menu-card.component';
import { MobileCategoryCardComponent } from '../menu/components/category-card/mobile-category-card/mobile-category-card.component';
import { NavbarComponent } from '../navigation/components/navbar/navbar.component';
import { NavbarDesktopComponent } from '../navigation/components/navbar/navbar-desktop/navbar-desktop.component';
import { NavbarMobileComponent } from '../navigation/components/navbar/navbar-mobile/navbar-mobile.component';
import { RouterModule } from '@angular/router';
import { DesktopNavCartComponent } from '../cart/components/nav-cart/desktop-nav-cart/desktop-nav-cart.component';
import { OptionSelectorComponent } from '../menu/components/option-selector/option-selector.component';
import { SelectedModsScrollComponent } from '../menu/components/selected-mods-scroll/selected-mods-scroll.component';
import { HorizontalCategoryScrollComponent } from '../brochure/components/horizontal-category-scroll/horizontal-category-scroll.component';
import { ConfirmationComponent } from '../checkout/containers/confirmation/base/confirmation.component';
import { CategoryComponent } from '../menu/containers/category/base/category.component';
import { CheckoutComponent } from '../checkout/containers/checkout/base/checkout.component';
import { CustomPageComponent } from '../brochure/containers/custom-page/base/custom-page.component';
import { ResetPasswordComponent } from '../auth/containers/reset-password/base/reset-password.component';
import { LocationDetailsComponent } from '../locations/containers/location-details/base/location-details.component';
import { LocationsComponent } from '../locations/containers/locations/base/locations.component';
import { RewardsComponent } from '../loyalty/containers/rewards/base/rewards.component';
import { OrdersComponent } from '../profile/containers/orders/base/orders.component';
import { ProfileComponent } from '../profile/containers/profile/base/profile.component';
import { SavedCardsComponent } from '../profile/containers/saved-cards/base/saved-cards.component';
import { MenuComponent } from '../menu/containers/menu/base/menu.component';
import { SavedVehiclesComponent } from '../profile/containers/saved-vehicles/base/saved-vehicles.component';
import { MobileHeaderBarComponent } from '../navigation/components/header-bar/mobile/header-bar.component';
import { DesktopHeaderBarComponent } from '../navigation/components/header-bar/desktop/header-bar.component';
import { TablesideHeaderBarComponent } from '../navigation/components/header-bar/tableside/header-bar.component';
import { HeaderBarComponent } from '../navigation/components/header-bar/header-bar.component';
import { BarcodeScannerComponent } from '../loyalty/components/barcode-scanner/barcode-scanner.component';
import { ModalComponent } from './components/modal/modal.component';
import { PromotionModalComponent } from '../brochure/components/promotion-modal/promotion-modal.component';
import { OrderTypeModalComponent } from '../cart/components/order-type-modal/order-type-modal.component';
import { AnnouncementBarComponent } from '../brochure/components/announcement-bar/announcement-bar.component';
import { MobileNutritionCardComponent } from '../menu/components/nutrition-card/mobile/nutrition-card.component';
import { DesktopNutritionCardComponent } from '../menu/components/nutrition-card/desktop/nutrition-card.component';
import { InStoreRedemptionComponent } from '../loyalty/components/in-store-redemption/in-store-redemption.page';
import { MissingPageComponent } from '../error/containers/missing-page/base/missing-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterSortBarComponent } from '../menu/components/filter-sort-bar/filter-sort-bar.component';
import { GuestCountComponent } from '../cart/components/guest-count/guest-count.component';
import { AgeCheckComponent } from '../cart/components/age-check/age-check.component';
import { MobileRedemptionComponent } from '../loyalty/components/mobile-redemption/mobile-redemption.component';
import { CancelOrderComponent } from '../cart/components/cancel-order/cancel-order.component';
import { CallServerComponent } from './components/call-server/call-server.component';
import { HelpConfirmationComponent } from './components/help-confirmation/help-confirmation.component';
import { ModalFrameComponent } from './components/modal-frame/modal-frame.component';
import { TipComponent } from '../checkout/components/tip/tip.component';
import { ProfileDashboardComponent } from '../profile/containers/profile-dashboard/base/profile-dashboard.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PointRedemptionComponent } from '../loyalty/components/point-redemption/point-redemption.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from '@barkhub/ngx-google-places-autocomplete';
import { ToastComponent } from './components/toast/toast.component';
import { RewardsBalancesComponent } from '../loyalty/components/rewards-balances/rewards-balances.component';
import { CancelEditOrderModalComponent } from '../profile/components/cancel-edit-order-modal/cancel-edit-order-modal.component';
import { TransferOrderModalComponent } from '../cart/components/transfer-order-modal/transfer-order-modal.component';
import { DesktopNavOrderTypeComponent } from '../cart/components/nav-order-type/desktop/nav-order-type.component';
import { MobileNavOrderTypeComponent } from '../cart/components/nav-order-type/mobile/nav-order-type.component';
import { TablesideNavOrderTypeComponent } from '../cart/components/nav-order-type/tableside/nav-order-type.component';
import { NavOrderTypeComponent } from '../cart/components/nav-order-type/nav-order-type.component';
import { OrderTypeListComponent } from '../cart/components/order-type-list/order-type-list.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { IvyCarouselModule } from 'ngx-ivy-responsive-carousel';
import { DownloadingUpdateModalComponent } from '../utility/components/downloading-update-modal/downloading-update-modal.component';
import { LoyaltyLoginModalComponent } from '@modules/checkout/components/loyalty-login-modal/loyalty-login-modal.component';
import { ModifyModalComponent } from '../menu/components/modify-modal/modify-modal.component';
import { AutofocusDirective } from '@modules/utility/directives';
import { FooterComponent } from '@modules/navigation/components';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { GiftCardBalanceTransferComponent } from '@modules/loyalty/components/gift-card-balance-transfer/gift-card-balance-transfer.component';
import { PurchaseableRewardCardComponent } from '@modules/loyalty/components/purchaseable-reward-card/purchaseable-reward-card.component';
import { OffersModalComponent } from '@modules/loyalty/components/offers-modal/offers-modal.component';
import { BankedDollarsModalComponent } from '@modules/loyalty/components/banked-dollars-modal/banked-dollars-modal.component';
import { TransferBalanceModalComponent } from '@modules/loyalty/components/transfer-balance-modal/transfer-balance-modal.component';
import { InboxModalComponent } from '@modules/loyalty/components/inbox-modal/inbox-modal.component';
import { AccountActivityModalComponent } from '@modules/loyalty/components/account-activity-modal/account-activity-modal.component';
import { PurchaseRewardsModalComponent } from '@modules/loyalty/components/purchase-rewards-modal/purchase-rewards-modal.component';
import { InboxMessageCardComponent } from '@modules/loyalty/containers/inbox/components/message-card/inbox-message-card.component';
import { InboxMessageModalComponent, LoyaltyInstructionsModalComponent } from '@modules/loyalty/components';
import { PipesModule } from '../../pipes/pipes.module';
import { GroupOrderModule } from '@modules/group-order/group-order.module';
import { AppCommonModule } from '@common/app-common.module';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { FilterBrandNameFromLocationPipe } from '../../pipes/filter-brand-name-from-location.pipe';
import { HalfAndHalfSelectorComponent } from '@modules/menu/components/half-and-half-selector/half-and-half-selector.component';
import { ChildOptionSelectorComponent } from '@modules/menu/components/child-option-selector/child-option-selector.component';
import { DropdownSelectorComponent } from '@modules/menu/components/dropdown-selector/dropdown-selector.component';
import { ButtonGroupSelectorComponent } from '@modules/menu/components/button-group-selector/button-group-selector.component';
import { RadioCheckboxSelectorComponent } from '@modules/menu/components/radio-checkbox-selector/radio-checkbox-selector.component';
import { OptionGroupStatusComponent } from '@modules/menu/components/option-group-status/option-group-status.component';
import { OptionGroupInfoComponent } from '@modules/menu/components/option-group-info/option-group-info.component';
import { OptionCardSelectorComponent } from '@modules/menu/components/option-card-selector/option-card-selector.component';
import { CustomizeProductSummaryComponent } from '@modules/menu/components/customize-product-summary/customize-product-summary.component';
import { MobileEditPageComponent } from '@modules/profile/containers/profile/components/mobile-edit-page/mobile-edit-page.component';
import { LoyaltyHeaderComponent } from '@modules/profile/components/loyalty-header/loyalty-header.component';
import { DesktopLocationCardComponent } from '@modules/locations/components/location-card/desktop-location-card/desktop-location-card.component';
import { MobileLocationCardComponent } from '@modules/locations/components/location-card/mobile-location-card/mobile-location-card.component';
import { DeliveryLocationCardComponent } from '@modules/locations/components/location-card/delivery-location-card/delivery-location-card.component';
import { RadioButtonCheckboxCardComponent } from '@modules/menu/components';
import { AutofillDirective } from '@common/directives';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    NgbModule,
    QRCodeModule,
    LazyLoadImageModule,
    GoogleMapsModule,
    GooglePlaceModule,
    IvyCarouselModule,
    NavigationModule,
    PipesModule,
    GroupOrderModule,
    RoundProgressModule,
    AppCommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [
    OrdersComponent,
    ProfileComponent,
    ProfileDashboardComponent,
    RewardsComponent,
    CategoryComponent,
    CheckoutComponent,
    SavedCardsComponent,
    MenuComponent,
    SavedVehiclesComponent,
    CustomPageComponent,
    ResetPasswordComponent,
    LocationDetailsComponent,
    LocationsComponent,
    InfoCardComponent,
    TimeSelectionComponent,
    SkeletonItemComponent,
    SideMenuComponent,
    NutritionCardComponent,
    FabComponent,
    HideFabDirective,
    BgLoaderDirective,
    KeyboardHideDirective,
    RewardCardComponent,
    OfferCardComponent,
    HeaderBarComponent,
    DesktopHeaderBarComponent,
    TablesideHeaderBarComponent,
    MobileHeaderBarComponent,
    AllergenBoxComponent,
    SubnavigationComponent,
    AccountSubnavComponent,
    MobileAccountSubnavComponent,
    DesktopAccountSubnavComponent,
    LogoBannerComponent,
    MenuHeaderComponent,
    MenuCardComponent,
    AllergenIconsComponent,
    CreditCardCardComponent,
    VehicleCardComponent,
    ReorderCardComponent,
    NutritionKeyComponent,
    OptionCardComponent,
    SliderOptionCardComponent,
    MobileMenuHeaderComponent,
    MobileMenuCardComponent,
    MobileCategoryCardComponent,
    NavbarComponent,
    NavbarDesktopComponent,
    NavbarMobileComponent,
    DesktopNavCartComponent,
    OptionSelectorComponent,
    SelectedModsScrollComponent,
    HorizontalCategoryScrollComponent,
    ConfirmationComponent,
    BarcodeScannerComponent,
    ModalComponent,
    PromotionModalComponent,
    OrderTypeModalComponent,
    AnnouncementBarComponent,
    MobileNutritionCardComponent,
    DesktopNutritionCardComponent,
    InStoreRedemptionComponent,
    RewardsBalancesComponent,
    MissingPageComponent,
    MissingPageComponent,
    FilterSortBarComponent,
    CallServerComponent,
    AgeCheckComponent,
    MobileRedemptionComponent,
    CancelOrderComponent,
    GuestCountComponent,
    HelpConfirmationComponent,
    ModalFrameComponent,
    PointRedemptionComponent,
    TipComponent,
    ToastComponent,
    CancelEditOrderModalComponent,
    TransferOrderModalComponent,
    DesktopNavOrderTypeComponent,
    MobileNavOrderTypeComponent,
    TablesideNavOrderTypeComponent,
    NavOrderTypeComponent,
    OrderTypeListComponent,
    DownloadingUpdateModalComponent,
    LoyaltyLoginModalComponent,
    ModifyModalComponent,
    AutofocusDirective,
    GiftCardBalanceTransferComponent,
    PurchaseableRewardCardComponent,
    OffersModalComponent,
    BankedDollarsModalComponent,
    TransferBalanceModalComponent,
    InboxModalComponent,
    AccountActivityModalComponent,
    PurchaseRewardsModalComponent,
    InboxMessageCardComponent,
    InboxMessageModalComponent,
    LoyaltyInstructionsModalComponent,
    FilterBrandNameFromLocationPipe,
    HalfAndHalfSelectorComponent,
    ChildOptionSelectorComponent,
    DropdownSelectorComponent,
    ButtonGroupSelectorComponent,
    RadioCheckboxSelectorComponent,
    OptionGroupStatusComponent,
    OptionGroupInfoComponent,
    OptionCardSelectorComponent,
    CustomizeProductSummaryComponent,
    MobileEditPageComponent,
    LoyaltyHeaderComponent,
    DesktopLocationCardComponent,
    MobileLocationCardComponent,
    DeliveryLocationCardComponent,
    RadioButtonCheckboxCardComponent,
    AutofillDirective,
  ],
  exports: [
    FooterComponent,
    SideMenuComponent,
    InfoCardComponent,
    TimeSelectionComponent,
    SkeletonItemComponent,
    NutritionCardComponent,
    SideMenuComponent,
    FabComponent,
    BgLoaderDirective,
    HideFabDirective,
    KeyboardHideDirective,
    RewardCardComponent,
    OfferCardComponent,
    HeaderBarComponent,
    DesktopHeaderBarComponent,
    TablesideHeaderBarComponent,
    MobileHeaderBarComponent,
    AllergenBoxComponent,
    SubnavigationComponent,
    AccountSubnavComponent,
    MobileAccountSubnavComponent,
    DesktopAccountSubnavComponent,
    LogoBannerComponent,
    MenuHeaderComponent,
    MenuCardComponent,
    AllergenIconsComponent,
    CreditCardCardComponent,
    VehicleCardComponent,
    ReorderCardComponent,
    NutritionKeyComponent,
    OptionCardComponent,
    SliderOptionCardComponent,
    MobileMenuHeaderComponent,
    MobileMenuCardComponent,
    MobileCategoryCardComponent,
    NavbarComponent,
    NavbarDesktopComponent,
    NavbarMobileComponent,
    DesktopNavCartComponent,
    OptionSelectorComponent,
    SelectedModsScrollComponent,
    HorizontalCategoryScrollComponent,
    BarcodeScannerComponent,
    ModalComponent,
    PromotionModalComponent,
    OrderTypeModalComponent,
    AnnouncementBarComponent,
    MobileNutritionCardComponent,
    DesktopNutritionCardComponent,
    InStoreRedemptionComponent,
    RewardsBalancesComponent,
    FilterSortBarComponent,
    CallServerComponent,
    AgeCheckComponent,
    MobileRedemptionComponent,
    CancelOrderComponent,
    GuestCountComponent,
    HelpConfirmationComponent,
    ModalFrameComponent,
    PointRedemptionComponent,
    TipComponent,
    ToastComponent,
    DesktopNavOrderTypeComponent,
    MobileNavOrderTypeComponent,
    TablesideNavOrderTypeComponent,
    NavOrderTypeComponent,
    OrderTypeListComponent,
    DownloadingUpdateModalComponent,
    LoyaltyLoginModalComponent,
    ModifyModalComponent,
    GiftCardBalanceTransferComponent,
    ModifyModalComponent,
    PurchaseableRewardCardComponent,
    AutofocusDirective,
    OffersModalComponent,
    BankedDollarsModalComponent,
    TransferBalanceModalComponent,
    InboxModalComponent,
    AccountActivityModalComponent,
    PurchaseRewardsModalComponent,
    InboxMessageCardComponent,
    InboxMessageModalComponent,
    LoyaltyInstructionsModalComponent,
    AutofocusDirective,
    HalfAndHalfSelectorComponent,
    ChildOptionSelectorComponent,
    DropdownSelectorComponent,
    ButtonGroupSelectorComponent,
    RadioCheckboxSelectorComponent,
    OptionGroupStatusComponent,
    OptionGroupInfoComponent,
    OptionCardSelectorComponent,
    CustomizeProductSummaryComponent,
    ProfileDashboardComponent,
    MobileEditPageComponent,
    LoyaltyHeaderComponent,
    DesktopLocationCardComponent,
    MobileLocationCardComponent,
    DeliveryLocationCardComponent,
    RadioButtonCheckboxCardComponent,
    AutofillDirective,
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
