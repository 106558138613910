import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HandoffType as DineEngineHandoffType, HandoffType } from 'src/interfaces/handoff-type.enum';
import { OrderItem as DineEngineOrderItem, Product as DineEngineProduct } from 'src/interfaces/product.interface';
import { User as DineEngineUser } from 'src/interfaces/user.interface';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { Order as DineEngineOrder } from 'src/interfaces/order.interface';
import { Menu as DineEngineMenu } from 'src/interfaces/menu.interface';
import { TimeFrame as DineEngineTimeFrame } from 'src/interfaces/time-frame.interface';
import { Category as DineEngineCategory } from 'src/interfaces/category.interface';
import { OptionGroup as DineEngineOptionGroup } from 'src/interfaces/option-group.interface';
import { Option as DineEngineOption, OrderItemModifier } from 'src/interfaces/option.interface';

import { Store as NovaDineStore, StoreHours as NovaDineStoreHours } from './interfaces/store.interface';
import { PickList, PickListItem, ProductDetails as NovaDineProductDetails } from './interfaces/product-details.interface';
import {
  AppliedCoupon,
  CustomerLocation,
  OrderItem as NovaDineOrderItem,
  OrderResponse as NovaDineOrderResponse,
} from './interfaces/order-response.interface';
import { CustomerInfo } from './interfaces/customer-info.interface';
import {
  Category as NovaDineCategory,
  CategoryItem as NovaDineCategoryItem,
  Menu as NovaDineMenu,
  MenuHours as NovaDineMenuHours,
  PickList as NovaDinePickList,
  PickListItem as NovaDinePickListItem,
} from './interfaces/menu.interface';
import { SavedCard as NovaDineSavedCard } from './interfaces/saved-card.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { OrderInfo } from './interfaces/order-info.interface';
import { PastOrderItem as NovaDinePastOrderItem } from './interfaces/past-order-item.interface';
import { Address } from 'src/interfaces/address.interface';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment-timezone';
import { PaymentMethod } from './interfaces/payment-method.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import {
  RetrieveCustomerLoyaltyBalancesBalance,
  RetrieveCustomerLoyaltyBalancesResponse,
} from './interfaces/retrieve-customer-loyalty-balances.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { RetrieveAvailableLoyaltyDiscountsForOrderDiscount } from './interfaces/retrieve-available-loyalty-discounts-for-order.interface';
import { Reward } from '../../interfaces/reward.interface';
import { RewardsBalanceAmount } from '../../interfaces/rewards-balance-amount.interface';
import tzlookup from 'tz-lookup';

@Injectable({
  providedIn: 'root',
})
export class NovaDineMappingService {
  private static childItemToOption(item: NovaDineOrderItem, parentItemID: string): OrderItemModifier {
    return {
      addedCents: item.base_price,
      modifierCategoryID: item.category_id,
      name: item.item_name,
      nutritionInfo: {},
      optionGroupID: item.pick_list_id.toString(),
      optionID: item.item_id.toString(),
      brandOptionID: item.item_id.toString(),
      quantity: item.quantity,
    } as OrderItemModifier;
  }

  private static pastChildItemToOption(item: NovaDineOrderItem, parentItemID: string): OrderItemModifier {
    return {
      addedCents: null,
      modifierCategoryID: null,
      name: item.item_name,
      nutritionInfo: {},
      optionGroupID: parentItemID,
      optionID: null,
      brandOptionID: null,
      quantity: item.quantity,
    } as OrderItemModifier;
  }

  // Takes a string representing time as HH:mm and converts to number representing minutes
  private static toMinutes(hour: string): number {
    if (!hour) {
      return 0;
    }
    const arr = hour.split(':');
    if (!arr || !arr[0]) {
      return 0;
    }
    return arr[1] ? Number(arr[0]) * 60 + Number(arr[1]) : Number(arr[0]) * 60;
  }

  private static toJSDayOfWeek(novaDineDayOfWeek: number): number {
    const novaDineDays = [6, 0, 1, 2, 3, 4, 5]; // SuMTuWThFSa
    const jsDays = [0, 1, 2, 3, 4, 5, 6]; // SuMTuWThFSa
    return jsDays[novaDineDays.indexOf(novaDineDayOfWeek)];
  }

  private static getDateThisWeek(dayOfWeek: number, totalMinutes: number): Date {
    if (dayOfWeek === undefined || dayOfWeek === null || totalMinutes === undefined || totalMinutes === null) {
      return null;
    }
    const dt = new Date();
    dt.setDate(dt.getDate() - (dt.getDay() - dayOfWeek));
    dt.setHours(0);
    dt.setMinutes(totalMinutes);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  private static generateCalendars(hours: NovaDineStoreHours[]): DineEngineTimeFrame[] {
    // console.log(hours)
    const times = [];
    const today = moment();
    switch (today.format('d')) {
      case '0':
        times.push({
          // tslint:disable:max-line-length
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '1':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '2':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '3':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '4':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '5':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
      case '6':
        times.push({
          start: moment(hours.find(day => day.display_name === 'Saturday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Saturday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear())
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Sunday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Sunday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Monday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Monday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Tuesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Tuesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Wednesday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Wednesday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Thursday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Thursday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(hours.find(day => day.display_name === 'Friday').start_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(hours.find(day => day.display_name === 'Friday').end_time, 'hh:mm')
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        break;
    }
    return times;
  }

  private static toHandoffType(serviceTypeID: number): HandoffType {
    switch (serviceTypeID) {
      case 2:
        return DineEngineHandoffType.pickup;
      case 1:
        return DineEngineHandoffType.delivery;
      case 3:
        return DineEngineHandoffType.curbside;
      case 4:
        return DineEngineHandoffType.dispatch;
      default:
        return DineEngineHandoffType.driveThru;
    }
  }

  private static toOption(item: NovaDinePickListItem): DineEngineOption {
    const defaultVariant = item.variants[0]; // same issue as above
    return {
      name: item.name,
      isDefault: false,
      isSelected: false,
      nutritionInfo: {}, // we actually don't have this info
      optionGroups: [],
      optionID: item.item_id.toString(),
      brandOptionID: item.item_id.toString(),
      addedCents: defaultVariant.price,
      standardImageURL: null,
      thumbnailImageURL: null,
    } as DineEngineOption;
  }

  regNewCustErr(err: Error) {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          msg = err.error.error;
          break;
        case 404:
          msg = 'Cannot reach ordering server. Please try again momentarily.';
          break;
      }
      // tslint:disable-next-line:max-line-length
      if (msg === 'This email has already been registered.  Please use a different email address or enter your password to continue.') {
        msg = 'This email has already been registered. Please use a different email address or log in to continue.';
      }
    }
    return new DineEngineError(msg);
  }

  loginError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          msg = err.error.error;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  deDupeCards(cards) {
    const visiblePayments = [];
    cards.forEach((newMethod: any) => {
      if (newMethod.is_credit_card === true) {
        let found = false;
        let foundUpdate = false;
        visiblePayments.forEach((method: any) => {
          if (newMethod.last_four === method.last_four) {
            found = true;
            if (newMethod.expiration_year > method.expiration_year) {
              foundUpdate = true;
              // tslint:disable-next-line:max-line-length
            } else if (newMethod.expiration_year === method.expiration_year && newMethod.expiration_month > method.expiration_month) {
              foundUpdate = true;
            }
          }
        });
        if (found === false) {
          visiblePayments.push(newMethod);
        }
        // @ts-ignore
        if (foundUpdate === true) {
          const removeIndex = visiblePayments.findIndex((method: any) => {
            return method.last_four === newMethod.last_four;
          });
          visiblePayments.splice(removeIndex, 1, newMethod);
        }
      }
    });
    console.log(visiblePayments);
    return visiblePayments;
  }

  toSavedCard(card: NovaDineSavedCard): SavedCard {
    return {
      cardExpiration: moment(card.expiration_month + '/' + card.expiration_year, 'MM/YY').toDate(),
      description: card.display_name,
      isDefault: false,
      isRemovable: true,
      lastFourDigits: card.last_four,
      savedCardID: card.cc_account_id,
      type: card.cc_type,
      isGiftCard: !card.is_credit_card,
      balanceCents: null,
    } as SavedCard;
  }

  toLocation(store: NovaDineStore, order?: NovaDineOrderResponse): DineEngineLocation {
    return {
      locationID: store.store_id,
      name: store.name,
      phoneNumber: store.phone,
      requiresPhoneNumber: false,
      utcOffset: String(Math.round(store.timezone_offset_mins / 60)),
      specialInstructionsMaxLength: 140,
      supportsCurbside: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.curbside)),
      supportsDelivery: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.delivery)),
      supportsDispatch: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.dispatch)),
      supportsPickup: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.pickup)),
      supportsDriveThru: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.driveThru)),
      supportsTableside: store.services.some(service => service.service_type_id === this.toServiceTypeID(HandoffType.dineIn)),
      supportsGroupOrders: store.allow_group_orders,
      supportsGuestOrdering: true,
      supportsOnlineOrdering: true, // that's a real problem if they don't
      supportsSpecialInstructions: true,
      supportsRecipientName: true,
      supportsSplitPayments: false,
      supportsTip: order ? store.services.find(s => s.name === order.order.service_type).allow_tip : true,
      supportsBasketTransfer: true,
      mapIconURL: null,
      address: {
        address1: store.address1,
        address2: store.address2,
        city: store.city,
        state: store.state,
        zipCode: store.postal_code,
        latitude: store.latitude,
        longitude: store.longitude,
        country: store.country,
      },
      curbsideHours: NovaDineMappingService.generateCalendars(store.hours),
      deliveryHours: NovaDineMappingService.generateCalendars(store.hours),
      pickupHours: NovaDineMappingService.generateCalendars(store.hours),
      dispatchHours: NovaDineMappingService.generateCalendars(store.hours),
      driveThruHours: NovaDineMappingService.generateCalendars(store.hours),
      isLive: store.online,
      isOpen: store.is_open,
      isPrivate: false,
      emailAddress: null,
      seoDescription: null,
      pickupInstructions: null,
      curbsideInstructions: null,
      deliveryInstructions: null,
      dispatchInstructions: null,
      tablesideInstructions: null,
      driveThruInstructions: null,
      externalRef: String(store.restaurant_id),
      orderAheadDays: 7,
      supportsAdvancedOrders: true,
      supportsAsapOrders: true,
      timezone: tzlookup(store.latitude, store.longitude),
      connectedProjects: [],
    } as DineEngineLocation;
  }

  toMenu(menu: NovaDineMenu): DineEngineMenu {
    return {
      menuID: menu.menu_id.toString(),
      categories: menu.categories.map(category => this.toCategory(category, menu.menu_id.toString())),
    } as DineEngineMenu;
  }

  toUser(info: CustomerInfo, balances: RetrieveCustomerLoyaltyBalancesResponse): DineEngineUser {
    return {
      userID: info.customer_id.toString(),
      phoneNumber: info.phone,
      email: info.email,
      company: '',
      emailOptIn: false,
      firstName: info.first_name,
      lastName: info.last_name,
      isGuest: info.is_guest,
      loyaltyOptIn: false,
      pushOptIn: false,
      sMSOptIn: false,
      userAsBarcode: balances?.info?.loyalty_id,
      userAsQrCode: balances?.info?.loyalty_id,
      addresses: [],
      orderingToken: null,
      orderingTokenProvider: null,
      birthday: moment(info.birthdate, 'YYYY-MM-DD').toDate(),
    } as DineEngineUser;
  }

  orderInfoToOrder(order: OrderInfo, store?: NovaDineStore) {
    const handoffType = NovaDineMappingService.toHandoffType(order.service_type_id);
    return {
      handoffType,
      orderID: order.orderid.toString(),
      location: store ? this.toLocation(store) : null,
      orderReadyTimestamp: new Date(order.dpstamp),
      orderPlacedTimestamp: new Date(order.created),
      orderStatus: order.status,
      items: order.items
        ? order.items.filter(item => item.item_name && item.order_item_id).map(item => this.pastOrderItemToOrderItem(item))
        : [],
      deliveryAddress: null,
      earliestReadyTimestamp: null,
      isASAP: null,
      subTotalCents: order.subtotal_cents,
      taxCents: order.tax_cents,
      tipCents: order.tip_cents,
      totalCents: order.total_cents,
      paidCents: order.paid_cents,
      balanceCents: order.balance_cents,
      appliedCouponCents: null,
      appliedCouponCode: null,
      appliedRewards: [],
      isGroup: null,
      deliveryFee:
        handoffType === 2 || handoffType === 3
          ? {
              note: 'Delivery Fee',
              description: 'Delivery Fee',
              feeCents: order.deliverycharge,
            }
          : null,
    } as DineEngineOrder;
  }

  toOrder(order: NovaDineOrderResponse, store: NovaDineStore, pMethods: PaymentMethod[], address?: CustomerLocation): DineEngineOrder {
    let userAddress: Address;
    const readyTime = new Date();
    const service = store.services.find(serv => serv.service_type_id === order.order.service_type_id);
    const leadTimeMinutes = service ? service.lead_time : 0;
    const handoffType = NovaDineMappingService.toHandoffType(order.order.service_type_id);
    readyTime.setMinutes(readyTime.getMinutes() + leadTimeMinutes + 15); // +1 because it takes some time to get to the server
    const currentReadyTime = new Date(order.order.ready_time);
    if (address) {
      userAddress = {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zip_code,
        latitude: address.latitude,
        longitude: address.longitude,
      };
    }

    let coupCode: string = null;
    let coupCents = 0;

    if (order.coupons && order.coupons.length) {
      const coupon = order.coupons.find(c => !c.is_loyalty);
      coupCode = coupon ? coupon.clu : null;
      order.coupons.forEach(c => (coupCents += c.value));
    }

    return {
      handoffType: NovaDineMappingService.toHandoffType(order.order.service_type_id),
      orderID: order.order.order_id.toString(),
      location: this.toLocation(store, order),
      orderReadyTimestamp: currentReadyTime < readyTime ? readyTime : currentReadyTime,
      orderPlacedTimestamp: new Date(order.order.created),
      orderStatus: order.order.order_status.is_completed ? 'completed' : 'in-progress',
      items: order.order.items.map(item => this.toOrderItem(item)),
      deliveryAddress: address ? userAddress : null,
      earliestReadyTimestamp: readyTime,
      isASAP: !(currentReadyTime > readyTime),
      canTip: true,
      subTotalCents: order.order.charges.subtotal_cents,
      taxCents: order.order.charges.tax_cents,
      tipCents: order.order.charges.tip_cents,
      totalCents: order.order.charges.total_cents,
      paidCents: order.order.charges.paid_cents,
      balanceCents: order.order.charges.balance_cents,
      deliveryFee:
        handoffType === 2 || handoffType === 3
          ? {
              note: 'Delivery Fee',
              description: 'Delivery Fee',
              feeCents: order.order.charges.delivery_cents,
            }
          : null,
      appliedCouponCents: coupCents,
      appliedCouponCode: coupCode,
      appliedRewards: order.coupons.length ? order.coupons.filter(c => c.is_loyalty).map(c => this.couponToReward(c)) : [],
      isGroup: null,
      specialInstructions: order.order.comments_to_restaurant,
      tableNumber: order.order.table_number,
      supportedPaymentTypes: pMethods ? this.mapPaymentMethods(pMethods) : [PaymentTypes.creditCard],
      requiresFullBillingAddress: false,
      customFields: null,
      curbsideCustomFields: null,
      dineinCustomFields: null,
      donationType: [],
      donationsTotal: 0,
    } as DineEngineOrder;
  }

  toProductFromProductDetails(details: NovaDineProductDetails, menuID: string): DineEngineProduct {
    return {
      // Likely needs sorted out for further NovaDine clients
      menuID,
      categoryID: details.category_id?.toString(),
      productID: details.item_id.toString(),
      name: details.item_name,
      longDesc: details.long_description,
      shortDesc: details.short_desc,
      standardImageURL: null,
      thumbnailImageURL: null,
      isAvailable: true,
      nutritionInfo: {}, // why is this not in the nutrition_matrix?
      allergenInfo: details.nutrition_matrix.None.None.allergens,
      optionGroups: details.pick_list_matrix.None.None.pick_lists
        ? details.pick_list_matrix.None.None.pick_lists.map(pl => this.pickListToOptionGroup(pl))
        : [],
      priceCents: details.price_matrix.None.None.baseprice,
      canModify: null,
      requiresModification: null,
      seoDescription: null,
      currentlyAvailable: true,
      configurations: [],
      globalID: details.item_id.toString(),
      minQuantity: 1,
    } as DineEngineProduct;
  }

  toServiceTypeID(handoffType: DineEngineHandoffType): number {
    switch (Number(handoffType)) {
      case DineEngineHandoffType.pickup:
        return 2;
      case DineEngineHandoffType.delivery:
        return 1;
      case DineEngineHandoffType.curbside:
        return 3;
      case DineEngineHandoffType.dispatch:
        return 4;
      default:
        return 0;
    }
  }

  toTimeFrame(hours: NovaDineMenuHours[]): DineEngineTimeFrame[] {
    return hours.map(
      hour =>
        ({
          start: NovaDineMappingService.getDateThisWeek(hour.day_of_week, hour.start_time),
          end: NovaDineMappingService.getDateThisWeek(hour.day_of_week, hour.end_time),
        }) as DineEngineTimeFrame
    );
  }

  toMenuHours(storeHours: NovaDineStoreHours[]): NovaDineMenuHours[] {
    return storeHours.map(
      sh =>
        ({
          day_of_week: NovaDineMappingService.toJSDayOfWeek(sh.day_of_week),
          display_name: sh.display_name,
          end_time: NovaDineMappingService.toMinutes(sh.end_time),
          start_time: NovaDineMappingService.toMinutes(sh.start_time),
        }) as NovaDineMenuHours
    );
  }

  toOrderDateString(dt: Date): string {
    return formatDate(dt, 'yyyy-MM-dd HH:mm:ss', 'en-US');
  }

  retrieveCustomerLoyaltyBalancesResponseToRewardsBalances(
    balances: RetrieveCustomerLoyaltyBalancesResponse,
    threshold: number
  ): RewardsBalances {
    return {
      points: balances.info.point_balances[0] ? balances.info.point_balances[0].balance : null,
      bankedRewards: null,
      pointsThreshold: threshold,
      pointsUnit: balances.info.point_balances[0] ? balances.info.point_balances[0].name : null,
      rewardAmounts: balances.balances
        ?.filter(balance => balance.name)
        .map(balance => this.retrieveCustomerLoyaltyBalancesBalanceToRewardsBalanceAmount(balance)),
      storedValueCents: null,
    };
  }

  retrieveCustomerLoyaltyBalancesBalanceToRewardsBalanceAmount(balance: RetrieveCustomerLoyaltyBalancesBalance): RewardsBalanceAmount {
    return {
      amount: balance.amount > balance.quantity ? '$'.concat((balance.amount / 100).toPrecision(2)) : String(balance.quantity),
      description: balance.name,
      type: balance.description,
      expDate: null,
    };
  }

  retrieveAvailableDiscountsForOrderDiscountToRewards(discount: RetrieveAvailableLoyaltyDiscountsForOrderDiscount): Reward {
    return {
      rewardID: discount.discount_code,
      membershipID: null,
      pointCost: null,
      name: discount.name,
      description: discount.description,
      isApplied: false,
      externalRef: discount.discount_code,
      finePrint: null,
      imageURL: null,
      redeemInStore: false,
      redeemOnline: true,
      isDollarReward: false,
      isCoupon: false,
      couponCode: null,
    };
  }

  private mapPaymentMethods(pMethods: PaymentMethod[]): PaymentTypes[] {
    let methods = [];
    pMethods.forEach(p => {
      if (p.name.toLowerCase() === 'cash') {
        // methods.push(PaymentTypes.cash);
      } else if (p.schema.toLowerCase() === 'gift_card') {
        methods.push(PaymentTypes.giftCard);
      } else if (p.is_credit_card && p.schema.toLowerCase() === 'credit_card') {
        methods.push(PaymentTypes.creditCard);
      }
    });

    methods = methods.filter((t, index) => {
      return methods.indexOf(t) === index;
    });
    // console.log(methods);
    // console.log(pMethods)
    return methods;
  }

  private toCategory(category: NovaDineCategory, menuID: string): DineEngineCategory {
    return {
      categoryID: category.category_id.toString(),
      name: category.name,
      description: category.short_desc,
      products: category.items.map(item => this.toProduct(item, menuID, category.category_id.toString())),
      standardImageURL: null,
      thumbnailImageURL: null,
      isHidden: false,
      seoDescription: null,
      hasAvailableProducts: true,
    } as DineEngineCategory;
  }

  private toProduct(item: NovaDineCategoryItem, menuID: string, categoryID: string): DineEngineProduct {
    const defaultVariant = item.variants[0]; // when is there more than one variant?
    return {
      // Likely needs sorted out for further NovaDine clients
      menuID,
      categoryID,
      productID: item.item_id.toString(),
      name: item.name,
      longDesc: item.long_desc,
      shortDesc: item.short_desc,
      standardImageURL: null,
      thumbnailImageURL: null,
      isAvailable: true,
      nutritionInfo: {},
      allergenInfo: [],
      optionGroups: defaultVariant && defaultVariant.pick_lists ? defaultVariant.pick_lists.map(pl => this.toOptionGroup(pl)) : null,
      priceCents: defaultVariant.price,
      canModify: null,
      requiresModification: null,
      seoDescription: null,
      currentlyAvailable: true,
      configurations: [],
      minQuantity: 1,
    } as DineEngineProduct;
  }

  private toOptionGroup(pl: NovaDinePickList): DineEngineOptionGroup {
    return {
      maxAllowed: pl.max_qty,
      minRequired: pl.min_qty,
      name: pl.prompt,
      description: null,
      optionGroupID: pl.pick_list_id.toString(),
      options: pl.items.map(i => NovaDineMappingService.toOption(i)),
      mandatorySelection: pl.min_qty > 0,
    } as DineEngineOptionGroup;
  }

  private pickListToOptionGroup(pl: PickList): DineEngineOptionGroup {
    return {
      maxAllowed: pl.pick_list_items ? (pl.maxcount > pl.pick_list_items.length ? pl.pick_list_items.length : pl.maxcount) : pl.maxcount,
      minRequired: pl.mincount,
      name: pl.prompt,
      description: pl.prompt,
      optionGroupID: pl.pick_list_id.toString(),
      options: pl.pick_list_items ? pl.pick_list_items.map(i => this.pickListItemToOption(i, pl.pick_list_id, pl.defaults_are_free)) : [],
      mandatorySelection: pl.mincount > 0,
    } as DineEngineOptionGroup;
  }

  private pickListItemToOption(item: PickListItem, pickListID: number, freeDefault: boolean): DineEngineOption {
    const isDefault = item.default_qty > 0;
    return {
      name: item.name,
      isDefault,
      isSelected: false,
      nutritionInfo: {}, // item.nutrition_matrix is always empty
      optionGroups:
        item.child_pick_list_data && item.child_pick_list_data.pick_lists
          ? item.child_pick_list_data.pick_lists.map(pl => this.pickListToOptionGroup(pl))
          : [], // apparently they don't do the recursion thing?
      optionID: item.itemid.toString(),
      brandOptionID: item.itemid.toString(),
      addedCents: isDefault && freeDefault ? 0 : item.baseprice,
      standardImageURL: null,
      thumbnailImageURL: null,
      whenSelected: null,
      modifierCategoryID: item.catid, // dumb as hell prop needed for adding modifiers
      parentOptionGroupID: pickListID.toString(),
    } as DineEngineOption;
  }

  private toOrderItem(item: NovaDineOrderItem): DineEngineOrderItem {
    return {
      userID: null,
      guestName: null,
      menuID: item.menu_id.toString(),
      categoryID: item.category_id.toString(),
      productID: item.item_id.toString(),
      orderItemID: item.order_item_id.toString(),
      name: item.item_name,
      longDesc: item.description,
      shortDesc: item.category,
      instructions: item.comment,
      options: item.child_items ? item.child_items.map(i => NovaDineMappingService.childItemToOption(i, item.item_id.toString())) : [],
      quantity: item.quantity,
      totalCents: this.toItemTotalCents(item),
      standardImageURL: null,
      thumbnailImageURL: null,
    } as DineEngineOrderItem;
  }

  private toItemTotalCents(item: NovaDineOrderItem): number {
    let basePrice = item.unit_price;
    if (item.child_items) {
      item.child_items.forEach(it => {
        basePrice = basePrice + it.extended_price;
      });
    }
    return basePrice;
  }

  private pastOrderItemToOrderItem(item: NovaDinePastOrderItem): DineEngineOrderItem {
    return {
      userID: null,
      guestName: null,
      menuID: null,
      categoryID: null,
      productID: null,
      orderItemID: item.order_item_id.toString(),
      name: item.item_name,
      longDesc: null,
      shortDesc: null,
      instructions: null,
      options: item.items ? item.items.map(i => NovaDineMappingService.pastChildItemToOption(i, item.order_item_id.toString())) : [],
      quantity: item.quantity,
      totalCents: null,
      standardImageURL: item.image_urls.standard,
      thumbnailImageURL: item.image_urls.thumbnail,
    } as DineEngineOrderItem;
  }

  private couponToReward(coupon: AppliedCoupon): Reward {
    return {
      description: coupon.description,
      externalRef: coupon.clu,
      finePrint: '',
      imageURL: '',
      isApplied: true,
      isDollarReward: false,
      membershipID: coupon.order_item_coupon_id,
      name: coupon.name,
      pointCost: 0,
      redeemInStore: true,
      redeemOnline: true,
      rewardID: coupon.coupon_id,
      isCoupon: false,
      couponCode: null,
    };
  }
}
