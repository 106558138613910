<div
  [ngClass]="!isListCard ? 'icon-wrap' : 'icon-list-card'"
  *ngIf="allergenList">
  <div *ngIf="allergenList.includes('VG')">
    <img
      class="icon me-1"
      alt="vegan icon"
      title="Vegan"
      src="../../../../img/allergen-vegan-icon.svg" />
  </div>

  <div *ngIf="allergenList.includes('S')">
    <img
      class="icon me-1"
      alt="spicy icon"
      title="Spicy"
      src="../../../../img/allergen-spicy-icon.svg" />
  </div>

  <div *ngIf="allergenList.includes('GF')">
    <img
      class="icon me-1"
      alt="gluten-free icon"
      title="Gluten-free"
      src="../../../../img/allergen-glutenfree-icon.svg" />
  </div>

  <div *ngIf="allergenList.includes('A')">
    <img
      class="icon"
      alt="allergen icon"
      title="Allergen"
      src="../../../../img/allergen-allergen-icon.svg" />
  </div>
</div>
