import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { MenuController } from '@ionic/angular';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import packageJson from '../../../../../package.json';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { feedbackIntegration } from '@sentry/angular';

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  @Input() navbarSettings: NavbarSettings;
  @Input() showReportBug = true;

  public version: string = packageJson.version;

  constructor(
    public menu: MenuController,
    public router: Router
  ) {}

  async openFeedbackForm() {
    await this.menu.close();
    const feedback = feedbackIntegration({
      // Disable the injection of the default widget
      colorScheme: 'system',
      autoInject: false,
      showBranding: false,
    });
    const form = await feedback.createForm();
    form.appendToDom();
    form.open();
  }
}
