<div class="p-0 grow-1">
  <div class="radio-checkbox-grid">
    <ng-container
      *ngFor="let option of optionGroup.options; trackBy: optionTrackBy">
      <app-radio-button-checkbox-card
        [attr.data-cy]="option.name"
        [option]="option"
        [isModifierFlow]="isModifierFlow"
        [showPricing]="optionGroup.showPricing"
        (clickedOption)="optionClicked($event)"
        (clickedSubOption)="optionClicked($event)"
        [optionGroup]="optionGroup"
        [isValid]="menuService.isOptionValid(option, displayOptionGroups ?? [])"
        [selectedOptions]="selectedOptions"
        [maxChoiceQuantity]="option | maxChoiceQuantity: optionGroup : selectedOptions"
        [hitMaxGroupQuantity]="optionGroup | optionGroupFull"
        [allowsOptionQuantity]="!!optionGroup.maxChoiceQuantity"
      ></app-radio-button-checkbox-card>
    </ng-container>
  </div>
</div>
