import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import {ModeService} from "../../../../services/mode.service";

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
})
export class MenuCardComponent {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  @Input() name: string;
  @Input() description: string;
  @Input() imageUrl: string;
  @Input() badgeColor: string;
  @Input() badgeTextColor: string;
  @Input() badgeText: string;
  @Input() isLoading = false;

  @Output() clickedCard = new EventEmitter<string>();

  constructor(
      protected mode: ModeService,
  ) {}

  cardClicked(categoryName: string) {
    this.clickedCard.emit(categoryName);
  }
}
