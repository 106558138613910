import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionGroup} from '../../../../interfaces/option-group.interface';
import {OptionStack, OptionSubGroup} from '@modules/menu/components';
import {Option} from '../../../../interfaces/option.interface';

@Component({
  selector: 'app-child-option-selector',
  templateUrl: './child-option-selector.component.html',
  styleUrls: ['child-option-selector.component.scss'],
})
export class ChildOptionSelectorComponent implements OnInit {
  @Input() optionGroup: OptionGroup;
  @Input() optionSubGroup: OptionSubGroup;
  @Output() optionClick = new EventEmitter<Option>();
  @Output() subOptionClick = new EventEmitter<OptionStack>();
  selectedOptionIndex: number;
  ngOnInit() {
    this.optionGroup.options.forEach((option: Option, index: number) => {
      if (option.isSelected) {
        this.selectedOptionIndex = index;
      }
    });
  }
  optionClicked(option: Option) {
    if (option) {
      this.optionClick.emit(option);
    }
  }
  subOptionClicked(optionStack: OptionStack) {
    if (optionStack.option) {
      this.subOptionClick.emit(optionStack);
    }
  }
}
