import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from 'src/services/mobile.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-point-redemption',
  templateUrl: './point-redemption.component.html',
  styleUrls: ['./point-redemption.component.scss'],
})
export class PointRedemptionComponent {

  @Input() error;
  @Input() currentDollars: number;
  @Input() currencyCode: string;

  @Output() clickedRedeem = new EventEmitter<number>();

  dollarConversion = new UntypedFormGroup({
    dollarAmount: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public activeModal: NgbActiveModal,
    public mobile: MobileService
  ) {}

  redeemClicked() {
    this.clickedRedeem.emit(this.dollarConversion.get('dollarAmount').value);
    this.activeModal.close();
  }

  get dollarAmount() { return this.dollarConversion.get('dollarAmount'); }

}
