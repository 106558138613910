import { Component } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { DirectusService } from 'src/vendors/directus/directus.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  customPages$ = this.directus.getCustomPages();

  pages = [
    { title: 'Home', icon: 'home', route: '/' },
    { title: 'Rewards & Offers', icon: 'star', route: '/profile/rewards' },
    { title: 'Inbox', icon: 'mail', route: '/profile/activity' },
    { title: 'Menu', icon: 'menu', route: '/menu' },
    { title: 'Group Order', icon: 'people', route: '/group-ordering' },
    { title: 'Locations', icon: 'pin', route: '/locations' },
    { title: 'My Profile', icon: 'mail', route: '/profile' },
    { title: 'Settings', icon: 'cog', route: '/settings' }
  ];

  constructor(
    public menu: MenuController,
    private navCtrl: NavController,
    private directus: DirectusService
  ) {}

  navigateTo(route) {
    this.menu.close();
    // setTimeout(() => {
      this.navCtrl.navigateRoot(route);
    // }, 50);
  }

}
