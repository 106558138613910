<div>
  <ion-row>
    <button tab="cart" class="handoff-mode-button d-flex justify-content-center align-items-center de-btn nav-button px-3"
      (click)="cartButtonClicked()">
      <ng-container *ngIf="buttonSwitch">
        <div class="m-0 p-0" [ngClass]="isProductPrice ? 'cart-product-price' : 'cart-product-count'">
          <div class="">
            <ng-container *ngIf="customIcons$ | async as customIcons">
              <ng-container *ngIf="customIcons.empty_cart_icon?.data?.full_url && customIcons.filled_cart_icon?.data?.full_url else bootstrapCart">
                <ng-container *ngIf="order && order?.items && order?.items?.length > 0 else emptyCart">
                  <div class="w-100 h-100" style="display:grid;place-items: center"><img [src]="customIcons.filled_cart_icon.data.full_url"></div>
                </ng-container>
                <ng-template #emptyCart>
                  <div class="w-100 h-100" style="display:grid;place-items: center"><img [src]="customIcons.empty_cart_icon.data.full_url"></div>
                </ng-template>
              </ng-container>
              <ng-template #bootstrapCart>
                <i id="desktopCartIcon" class="d-flex justify-content-start align-items-center m-0 p-0 {{ cartIcon | bootstrapIcon }} cart-icon"></i>
              </ng-template>

            </ng-container>

          </div>
        </div>
        <ng-container *ngIf="isProductPrice; else productCount">
          <ng-container *ngIf="order; else zero">
            <div
              class="ms-2">
              {{ (order?.subTotalCents / 100) | currency: currencyCode }}
            </div>
          </ng-container>
          <ng-template #zero>
            <div
              class="navbar-button-text ms-2">
              $0.00
            </div>
          </ng-template>
        </ng-container>
        <ng-template #productCount>
          <div
            class="navbar-button-text ms-2">
            {{order?.items?.length ? order.items.length : '0'}}
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!buttonSwitch">
        <div class="m-0 d-flex align-items-center justify-content-center navbar-button-text">
          {{buttonText}}
        </div>
      </ng-container>
    </button>
  </ion-row>
</div>
