<ng-container *ngIf="(menu$ | async) as menu">
  <app-category-jump-nav
    [categories]="menu.categories"
    (clickCategory)="menuService.setCategory($event)"
  ></app-category-jump-nav>
</ng-container>

<app-order-controls [isMenu]="false"></app-order-controls>

<div class="category-list mt-3">
  <ng-container *ngIf="category">
    <ng-container *ngIf="category.products && category.products.length">
      <div class="category-anchor" [id]="category.nameSlug"></div>
      <div class="category mb-3">

        <div class="category-info mb-3">
          <h4 class="category-title header-font mb-1">
            {{ category.name }}
          </h4>

          <div class="category-details">
            <div class="category-product-amount">
              ({{ category.products.length }} Products)
            </div>
          </div>

          <ng-container *ngIf="category.description !== ''">
            <div class="category-description mt-1">
              {{ category.description }}
            </div>
          </ng-container>
        </div>

        <div class="category-container-list">
          <ng-container *ngIf="(user$ | async) as user">
            <ng-container *ngFor="let product of category.products | secretProducts: (user.isGuest ? roles.guest : roles.all); trackBy: productTrackBy; let last = last;">
              <ng-container *ngIf="!last">
                <app-product-card
                        [productID]="product.productID"
                        [productDetails]="product"
                        [category]="category"
                        [modifyModalRef]="modifyModalRef"
                        [isListCard]="true" class="mb-lg-3"
                ></app-product-card>
              </ng-container>
              <ng-container *ngIf="last">
                <app-product-card
                        [productID]="product.productID"
                        [productDetails]="product"
                        [category]="category"
                        [modifyModalRef]="modifyModalRef"
                        [isListCard]="true"
                ></app-product-card>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

      </div>

    </ng-container>
  </ng-container>
</div>

<ng-template #modifyModal let-modal>
  <app-modify-modal [isLoading]="menuService.settingProduct" (closeModal)="modal.close()"></app-modify-modal>
</ng-template>
