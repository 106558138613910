export interface RetrieveMemberStatusRequest {
  /**
   * Required if you are looking up by the member’s phone number.
   */
  phone?: string;
  /**
   * Required if you are looking up by the member’s email address.
   */
  email?: string;
  /**
   * Optional applicable only if you are looking up by the member’s email address. Set value to true to check whether the user previously
   * tried to create an account but did not validate their email to complete the registration process.
   */
  check_email_validated_status?: boolean;
}

export interface RetrieveMemberStatusResponse {
  /**
   * Member’s Spendgo account status
   */
  status: RetrieveMemberAccountStatus;
  /**
   * Identifies whether the member is associated with your account.
   */
  linked_to_account: boolean;
}

export enum RetrieveMemberAccountStatus {
  ACTIVATED = 'Activated',
  STARTER_ACCOUNT = 'StartedAccount',
  NOT_FOUND = 'NotFound',
  INVALID_EMAIL = 'InvalidEmail',
  WAITING_FOR_EMAIL_VALIDATION = 'WaitingForEmailValidation'
}
