import { Pipe, PipeTransform } from '@angular/core';
import { TimeFrame } from '../interfaces/time-frame.interface';

@Pipe({
  name: 'businessHours',
})
export class BusinessHoursPipe implements PipeTransform {
  // Abbreviated day names
  private daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  transform(timeFrames: TimeFrame[]): string[] {
    if (!timeFrames) {
      return [];
    }

    // Create a map of date string ('YYYY-MM-DD') to TimeFrame for quick lookup
    const timeFrameMap: { [date: string]: TimeFrame } = {};
    timeFrames.forEach(tf => {
      const dateStr = this.formatDate(tf.start);
      timeFrameMap[dateStr] = tf;
    });

    // Generate the next 7 days starting with today
    const today = new Date();
    const weekDays: WeekDay[] = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() + i);
      const dateStr = this.formatDate(currentDate);
      const tf = timeFrameMap[dateStr];
      const isToday = this.isSameDay(currentDate, today);

      if (tf) {
        weekDays.push({
          date: currentDate,
          day: this.daysOfWeek[currentDate.getDay()],
          startTime: this.formatTime(tf.start),
          endTime: this.formatTime(tf.end),
          isClosed: false,
          isToday: isToday,
        });
      } else {
        // Day is closed
        weekDays.push({
          date: currentDate,
          day: this.daysOfWeek[currentDate.getDay()],
          startTime: '',
          endTime: '',
          isClosed: true,
          isToday: isToday,
        });
      }
    }

    // Group consecutive days with the same hours or closed status
    const groups: Group[] = [];
    let currentGroup: Group | null = null;

    weekDays.forEach(item => {
      if (currentGroup) {
        if (currentGroup.isClosed === item.isClosed && currentGroup.startTime === item.startTime && currentGroup.endTime === item.endTime) {
          currentGroup.days.push(item);
        } else {
          groups.push(currentGroup);
          currentGroup = this.createGroup(item);
        }
      } else {
        currentGroup = this.createGroup(item);
      }
    });

    if (currentGroup) {
      groups.push(currentGroup);
    }

    // Format the groups into display strings
    const result: string[] = groups.map(group => {
      const dayStr = this.formatDayRange(group.days);
      const timeStr = group.isClosed ? 'Closed' : `${group.startTime} - ${group.endTime}`;
      return `${dayStr}: ${timeStr}`;
    });

    return result;
  }

  /**
   * Creates a new group based on the WeekDay item.
   * @param item WeekDay item
   * @returns New Group
   */
  private createGroup(item: WeekDay): Group {
    return {
      days: [item],
      startTime: item.startTime,
      endTime: item.endTime,
      isClosed: item.isClosed,
    };
  }

  /**
   * Formats a Date object into a 'YYYY-MM-DD' string.
   * @param date Date object
   * @returns Formatted date string
   */
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Checks if two dates are on the same day.
   * @param date1 First date
   * @param date2 Second date
   * @returns True if both dates are on the same calendar day
   */
  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  /**
   * Formats a Date object into a 12-hour time string with am/pm.
   * @param date Date object to format
   * @returns Formatted time string (e.g., "11:00 am")
   */
  private formatTime(date: Date): string {
    if (!date) {
      return '';
    }

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    return `${hours}:${minutesStr} ${ampm}`;
  }

  /**
   * Formats a range of day names into a string.
   * Replaces the first day with "Today" if applicable.
   * @param days Array of WeekDay objects
   * @returns Formatted day range string (e.g., "Today - Fri" or "Mon")
   */
  private formatDayRange(days: WeekDay[]): string {
    if (days.length === 1) {
      return days[0].isToday ? 'Today' : days[0].day;
    } else {
      const firstDay = days[0].isToday ? 'Today' : days[0].day;
      const lastDay = days[days.length - 1].day;
      return `${firstDay} - ${lastDay}`;
    }
  }
}

/**
 * Interface for grouped TimeFrames.
 */
interface Group {
  days: WeekDay[];
  startTime: string;
  endTime: string;
  isClosed: boolean;
}

/**
 * Interface for each day's data.
 */
interface WeekDay {
  date: Date;
  day: string;
  startTime: string;
  endTime: string;
  isClosed: boolean;
  isToday: boolean;
}
