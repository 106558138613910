import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Reward } from 'src/interfaces/reward.interface';
import { Offer } from 'src/interfaces/offer.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { ErrorService } from 'src/services/error.service';
import { PunchhSettings } from 'src/vendors/directus/interfaces/punchh-settings.interface';
import { MobileService } from 'src/services/mobile.service';
import { VendorSetup } from 'src/vendors/directus/interfaces/vendor.interface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MobileRedemptionComponent } from '@modules/loyalty/components/mobile-redemption/mobile-redemption.component';
import { InStoreRedemptionComponent } from '@modules/loyalty/components/in-store-redemption/in-store-redemption.page';
import { PointRedemptionComponent } from '@modules/loyalty/components/point-redemption/point-redemption.component';
import { DollarReward } from 'src/interfaces/dollar-reward.interface';
import { HistoryEvent } from 'src/interfaces/history-event.interface';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { RewardsBalancesComponent } from '@modules/loyalty/components/rewards-balances/rewards-balances.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastService } from '../../../../../services/toast.service';
import { DineEngineError } from '../../../../../interfaces/dineengine-error.interface';
import { CurrencyPipe } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import {
  PurchaseReward,
  RedeemInStoreReward,
  RedeemPointsFromScanner,
  RedeemRewardPoints,
  ReturnRewardPoints,
  SetPurchaseableRewards,
  SetRewards,
  SetRewardsBalances,
  UpdateAppliedRewards,
} from '../../../../../store/actions/user.actions';
import { Location } from '../../../../../interfaces/location.interface';
import { RemoveReward, StageReward } from '../../../../../store/actions/order.actions';
import { MetaService } from '../../../../../services/meta.service';
import { LoyaltySection, MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import { PurchaseableReward } from '../../../../../interfaces/purchaseable-reward.interface';
import { ModalController } from '@ionic/angular';
import {
  AccountActivityModalComponent,
  BankedDollarsModalComponent,
  CompleteRewardBalancesModalComponent,
  InboxModalComponent,
  LoyaltyInstructionsModalComponent,
  OffersModalComponent,
  PurchaseRewardsModalComponent,
  TransferBalanceModalComponent,
} from '@modules/loyalty/components';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { LoyaltyReward } from '../../../../../interfaces/loyalty-reward.interface';
import { InboxMessage } from '../../../../../interfaces/inbox-message.interface';
import { ContentService } from '../../../../../services/vendor-config-service/content-provider.service';
import { GlobalStateModel } from '../../../../../store/state.model';
import { ThemeColor } from '../../../../../vendors/directus/interfaces/theme-color.interface';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { LoyaltyService } from '../../../services';
import { ModeService } from '../../../../../services/mode.service';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';
import { SentryService } from '@common/services';
import { PaytronixConfiguration } from '../../../../../vendors/directus/interfaces/paytronix-configuration.interface';
import { MobileAppSettings } from '../../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'app-rewards',
  template: '',
})
export class RewardsComponent implements OnDestroy, AfterViewInit, OnInit {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.rewards) rewards$: Observable<Reward[]>;
  @Select(state => state.user.activity) activity$: Observable<HistoryEvent[]>;
  @Select(state => state.user.offers) offers$: Observable<Offer[]>;
  @Select(state => state.user.rewardPoints)
  rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.user.inStoreReward) inStoreReward$: Observable<Reward>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;
  @Select(state => state.location.pickupLocations) pickupLocations$: Observable<Location[]>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.user.purchaseableRewards)
  purchaseableRewards$: Observable<PurchaseableReward[]>;
  @Select(state => state.user.allowsTransferToLoyalty)
  allowsTransfer$: Observable<boolean>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.user.loyaltyRewards) loyaltyRewards$: Observable<LoyaltyReward[]>;
  @Select(state => state.user.inboxMessages) inboxMessages$: Observable<InboxMessage[]>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings)
  mobileAppSettings$: Observable<MobileAppSettings>;

  @Select(state => state.order.stagedReward) stagedReward$: Observable<Reward>;
  @Select((state: GlobalStateModel) => state.app.theme) theme$: Observable<ThemeColor[]>;

  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;

  redeemReward = new EventEmitter<Reward>();
  redeemInStoreReward = new EventEmitter<Reward>();
  removeReward = new EventEmitter<Reward>();
  currencyCode: string;

  points: RewardsBalances;
  loyaltySections: LoyaltySection[] = [];

  // Page details for SEO
  title = 'Rewards & Offers';
  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displaySettings: PunchhSettings;
  displayRewards: Reward[];
  displayCurrentReward: Reward;
  displayRewardsDollars: DollarReward[] = [];
  displayOffers: Offer[];
  displayActivity: HistoryEvent[];
  displayBalances: RewardsBalances = null;
  enableQR = false;
  areRewardsLoading = true;
  areRewardsSelecting = false;
  errorMessage = '';
  pointsPercentage = 0;
  isRewards = true;
  openModal = false;
  genericError: string;
  purchasingReward = false;

  orderNowLoading = false;
  stagedReward: Reward;

  guageValue = 0;

  showTiers = false;

  locationsRoute = this.navigation.getLocationsPageSlug();

  modalRef: NgbModalRef;
  barcodeForm = new UntypedFormGroup({
    manualBarcode: new UntypedFormControl(),
  });
  private subs: Subscription[] = [];

  pointsBalanceCopy = 'Points Balance';
  purchaseRewardsCopy = 'Redeem Rewards';
  purchaseRewardsSuccessText = 'Reward Purchased';
  redeemsCodeSuccessText = 'Code Redeemed';

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private errorService: ErrorService,
    public mobile: MobileService,
    private modalService: NgbModal,
    private directus: DirectusService,
    private toast: ToastService,
    private cp: CurrencyPipe,
    private store: Store,
    private meta: MetaService,
    private content: ContentService,
    private modal: ModalController,
    private orderTypeService: OrderTypeService,
    public modeService: ModeService,
    private loyaltyService: LoyaltyService,
    private route: ActivatedRoute,
    private sentry: SentryService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.sentry.setTransactionName('Rewards');
    this.meta.manualUpdate({
      title: 'Rewards',
      description: null,
      keywords: null,
    });

    console.log(this.rewards$);
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.subs.push(
      this.vendorSetup$.subscribe(vendorSetup => {
        this.displayVendorSetup = vendorSetup ? vendorSetup : null;
      }),
      this.order$.subscribe(order => {
        this.displayOrder = order ? order : null;
      }),
      this.user$.pipe(filter(u => u !== null)).subscribe(user => {
        this.displayUser = user ? user : null;
        this.store.dispatch(new SetRewardsBalances(user.userID));
        this.store.dispatch(new SetPurchaseableRewards());
      }),
      combineLatest([this.order$, this.user$.pipe(filter(u => u !== null)), this.content.getService()])
        .pipe(take(1))
        .subscribe(([order, user, cService]) => {
          if (order) {
            this.store.dispatch(new SetRewards(user.userID, order.location.locationID)).subscribe(() => {
              this.store.dispatch(new UpdateAppliedRewards(null));
              this.areRewardsLoading = false;
            });
          } else {
            cService.getLocations().subscribe(cLocations => {
              const staticLoc = cLocations.find(l => l.is_static_menu);
              if (staticLoc) {
                this.store.dispatch(new SetRewards(user.userID, staticLoc.menu_id)).subscribe(() => {
                  this.store.dispatch(new UpdateAppliedRewards(null));
                  this.areRewardsLoading = false;
                });
              }
            });
          }
        }),
      combineLatest([this.rewards$, this.activity$]).subscribe(([rewards, records]) => {
        if (rewards) {
          if (!this.mobile.isMobile) {
            this.displayRewards = rewards.filter(r => !r.redeemInStore);
          }
          this.displayRewards = rewards;
          this.areRewardsLoading = false;
        }
        if (records) {
          this.displayActivity = records;
          this.getDollarRewardsFromActivityRecords(records);
        }
      }),
      this.offers$.subscribe(offers => {
        this.displayOffers = offers ? offers : null;
      }),
      // tslint:disable-next-line:max-line-length
      this.rewardPoints$
        .pipe(
          filter(rp => rp !== null),
          distinctUntilChanged((prev, curr) => prev.points === curr.points)
        )
        .subscribe(balances => {
          // if (!balances || balances?.bankedRewards !== this.displayBalances?.bankedRewards) {
          //   this.store.dispatch(new SetRewardsBalances())
          //   this.pageService.reloadRewards();
          // }
          if (balances) {
            // this.pointsPercentage = this.getPointsPecentage(balances);
            this.displayBalances = balances;
            this.points = {
              points: 0,
              pointsThreshold: balances.pointsThreshold,
              bankedRewards: balances.bankedRewards,
              rewardAmounts: balances.rewardAmounts,
              storedValueCents: balances.storedValueCents,
            };
            setTimeout(() => {
              this.points.points = balances.points;
            }, 1000);
          }
        }),
      this.stagedReward$.subscribe(stagedReward => (this.stagedReward = stagedReward)),
      this.route.fragment.subscribe(fragment => {
        if (fragment === 'earn') {
          this.loyaltyService.openEarn();
        }
      }),
      this.directus.getPaytronixSettings().subscribe((settings: PaytronixConfiguration) => {
        this.showTiers = settings.show_tier_progress;
      }),
      this.textFields$.subscribe(textFields => {
        if (textFields) {
          this.purchaseRewardsSuccessText = textFields.purchase_rewards_success_text;
          this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  ngAfterViewInit() {
    this.shouldEnableQR();
  }

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }

  redeemedReward(reward: Reward) {
    if (this.displayVendorSetup.loyalty_provider === 'punchh-olo' || this.displayVendorSetup.loyalty_provider === 'personica') {
      this.modalRef = this.modalService.open(MobileRedemptionComponent, {
        centered: true,
      });
      this.modalRef.componentInstance.reward = reward;
      this.modalRef.componentInstance.clickedRedeem.subscribe(() => {
        this.store
          .dispatch(new StageReward(reward))
          .toPromise()
          .then(() =>
            this.toast.success(
              'Reward added. Remember to add the necessary item to your cart to take advantage of this reward. Your reward will be applied at checkout.',
              7000
            )
          );
        this.modalRef.dismiss();
      });
      this.modalRef.componentInstance.clickedRedeemInStore.subscribe(() => {
        this.redeemedInStoreReward(reward);
      });
    } else {
      this.store
        .dispatch(new StageReward(reward))
        .toPromise()
        .then(() =>
          this.toast.success(
            'Reward added. Remember to add the necessary item to your cart to take advantage of this reward. Your reward will be applied at checkout.',
            7000
          )
        );
    }
  }

  redeemPoints() {
    this.modalRef = this.modalService.open(PointRedemptionComponent, {
      modalDialogClass: 'condensed-modal',
      centered: true,
    });
    this.modalRef.componentInstance.currentDollars = this.displayBalances.bankedRewards;
    this.modalRef.componentInstance.clickedRedeem.subscribe(points => {
      this.store.dispatch(new RedeemRewardPoints(points));
    });
  }

  shouldEnableQR() {
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
      this.enableQR = ms.enable_qr_scanner;
    });
  }

  redeemedDollars(reward: DollarReward) {
    // The only thing keep this from being optimized is rewards not inheriting from a base class
    this.modalRef = this.modalService.open(InStoreRedemptionComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.reward = reward;
    this.modalRef.componentInstance.clickedVoidPoints.subscribe(() => {
      this.modalRef.dismiss();
      this.store.dispatch(new ReturnRewardPoints(reward));
    });
  }

  redeemedInStoreReward(reward: Reward) {
    this.store.dispatch(new RedeemInStoreReward(reward));
    this.subs.push(
      this.inStoreReward$.subscribe(currentReward => {
        if (currentReward && currentReward.rewardID === reward.rewardID) {
          this.displayCurrentReward = currentReward;
          this.openInStoreRedemption(currentReward);
        }
      })
    );
  }

  removedReward(reward: Reward) {
    this.store.dispatch(new StageReward(null));
    this.store
      .dispatch(new RemoveReward(reward))
      .toPromise()
      .then(() => this.store.dispatch(new UpdateAppliedRewards(reward)));
  }

  orderNowClicked() {
    // This should be handled by the menu guard and just be a simple route to the menu
    this.orderNowLoading = true;
    this.order$
      .pipe(take(1))
      .toPromise()
      .then(order => {
        if (order) {
          const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
          this.navigation.navigateToMenuPage(menuID).then(() => (this.orderNowLoading = false));
        } else {
          this.orderTypeService.setNewOrderType(false);
        }
      });
  }

  earnPointsFromReceiptCode() {
    const qr = this.barcodeForm.get('manualBarcode').value;
    if (qr) {
      this.store
        .dispatch(new RedeemPointsFromScanner(qr))
        .toPromise()
        .then(() => {
          this.toast.showDismissableIonicToast(this.redeemsCodeSuccessText, 'de-ionic-success-toast', 8000);
          this.barcodeForm.reset();
        })
        .catch(error => {
          if (error instanceof DineEngineError) {
            if (error.message.includes('range')) {
              this.toast.showDismissableIonicToast('Invalid Receipt Number', 'de-ionic-error-toast', 8000);
            } else {
              this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
            }
          } else {
            this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
          }
        });
    } else {
      this.toast.showDismissableIonicToast('Please enter in code', 'de-ionic-warning-toast', 8000);
    }
  }

  purchaseReward(reward: PurchaseableReward) {
    this.purchasingReward = true;
    this.store.dispatch(new PurchaseReward(reward)).subscribe({
      next: () => {
        this.store.dispatch(new SetPurchaseableRewards());
        this.store.dispatch(new SetRewardsBalances(this.displayUser.userID));
        this.purchasingReward = false;
        this.toast.showDismissableIonicToast(this.purchaseRewardsSuccessText, 'de-ionic-success-toast', 8000);
      },
      error: err => {
        this.toast.showDismissableIonicToast(err, 'de-ionic-error-toast', 8000);
        this.purchasingReward = false;
      },
    });
  }

  openAccountActivity() {
    this.modal
      .create({
        component: AccountActivityModalComponent,
      })
      .then(modal => modal.present());
  }

  openBankedDollars() {
    this.modal
      .create({
        component: BankedDollarsModalComponent,
      })
      .then(modal => modal.present());
  }

  openInbox() {
    this.modal
      .create({
        component: InboxModalComponent,
      })
      .then(modal => modal.present());
  }

  openOffers() {
    this.modal
      .create({
        component: OffersModalComponent,
        animated: true,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  openPurchaseRewards() {
    this.modal
      .create({
        component: PurchaseRewardsModalComponent,
      })
      .then(modal => modal.present());
  }

  openTransferBalance() {
    this.modal
      .create({
        component: TransferBalanceModalComponent,
      })
      .then(modal => modal.present());
  }

  openLoyaltyInfo() {
    this.modal
      .create({
        component: LoyaltyInstructionsModalComponent,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  private openInStoreRedemption(reward: Reward) {
    this.modalRef.dismiss();
    this.modalRef = this.modalService.open(InStoreRedemptionComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.reward = reward;
  }

  openRewardsExpirations() {
    this.modalRef = this.modalService.open(RewardsBalancesComponent, {
      centered: true,
    });
    this.modalRef.componentInstance.balanceTotals = this.displayBalances;
  }

  openBalances() {
    this.modal
      .create({
        component: CompleteRewardBalancesModalComponent,
        animated: true,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  private getDollarRewardsFromActivityRecords(records: HistoryEvent[]) {
    // Clear the array so that the list doesn't double up
    this.displayRewardsDollars = [];
    // Check each record to see if it is a dollar redemption
    records.forEach(record => {
      if (record.title === 'Rewards Redeemed' && record.redemptionInfo) {
        console.log(record);
        const dollarRedemption: DollarReward = this.activityRecordToDollarReward(record);
        this.displayRewardsDollars.push(dollarRedemption);
      }
    });
  }

  private activityRecordToDollarReward(record: HistoryEvent): DollarReward {
    const currencyCode = this.store.selectSnapshot(state => state.order.currencyCode);
    // Create a dollar redemption from a history event
    return {
      rewardID: record.externalID,
      name: `${this.cp.transform(record.dollarsOff, currencyCode, true, '1.0-2')} off`,
      description: record.description,
      redeemInStore: false,
      redeemOnline: true,
      redemptionInfo: record.redemptionInfo,
      isDollarReward: true,
    };
  }

  generateLabel = (num: number) => {
    return `${num.toFixed(0)}/${this.points ? this.points.pointsThreshold : 0}`;
  };

  routeToInbox() {
    this.navigation.navigateToInboxPage();
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal',
    });
  }

  openRewardsBalances() {
    this.modalRef = this.modalService.open(RewardsBalancesComponent, {
      centered: true,
      modalDialogClass: 'condensed-modal',
    });
    this.modalRef.componentInstance.balanceTotals = this.displayBalances;
  }

  orderNow() {
    this.orderNowLoading = true;
    this.order$
      .pipe(take(1))
      .toPromise()
      .then(order => {
        if (order) {
          const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
          this.navigation.navigateToMenuPage(menuID).then(() => (this.orderNowLoading = false));
        } else {
          this.orderTypeService.setNewOrderType(false);
        }
      });
  }

  rewardTrackBy(index: number, item: Reward) {
    return item.rewardID;
  }
}
