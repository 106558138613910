<div class="progress-dial-container" [style.width.px]="size" [style.height.px]="size">
  <svg
    [attr.height]="size"
    [attr.width]="size"
    role="img"
    [attr.aria-label]="'Progress: ' + (progressPercentage | number:'1.0-0') + '%'"
  >
    <!-- Background Circle -->
    <circle
      stroke="#e6e6e6"
      fill="transparent"
      [attr.stroke-width]="strokeWidth"
      [attr.r]="normalizedRadius"
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.transform]="'rotate(-90 ' + radius + ' ' + radius + ')'"
    ></circle>
    <!-- Progress Circle -->
    <circle
      [attr.stroke]="progressColor"
      fill="transparent"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke-dasharray]="circumference + ' ' + circumference"
      [attr.stroke-dashoffset]="strokeDashoffset"
      stroke-linecap="round"
      [attr.r]="normalizedRadius"
      [attr.cx]="radius"
      [attr.cy]="radius"
      class="progress-circle"
      [attr.transform]="'rotate(-90 ' + radius + ' ' + radius + ')'"
    ></circle>
  </svg>
  <div class="progress-dial-icon">
    <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
      <ng-container
        *ngIf="(mobileAppSettings.active_rewards_icon?.data && mobileAppSettings.inactive_rewards_icon?.data) else defaultRewards">
        <img class="tab-icon"
             [src]="mobileAppSettings.active_rewards_icon?.data?.full_url"
             [alt]="mobileAppSettings.rewards_section_copy"
             [style.height.px]="size * 0.35"
             [style.width.px]="size * 0.35">
      </ng-container>
      <ng-template #defaultRewards>
        <i class="bi bi-award" [style.fontSize.px]="size * 0.35"></i>
      </ng-template>
    </ng-container>

  </div>
</div>
