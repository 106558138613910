<div class="app-loader">
  <svg class="circular" viewBox="25 25 50 50">
    <circle class="path" cx="50" cy="50" fill="none" r="20" stroke-miterlimit="10" stroke-width="3" />
  </svg>
</div>

<div class="h-100 w-100" style="display: grid; place-content: center">
  <div>
    <svg class="circular m-0 position-relative" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" fill="none" r="20" stroke-miterlimit="10" stroke-width="3" />
    </svg>
  </div>
</div>
