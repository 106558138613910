import { KioskNavbarComponent } from './kiosk-navbar/kiosk-navbar.component';
import { KioskProductCardComponent } from './kiosk-product-card/kiosk-product-card.component';
import { KioskMenuBlockComponent } from './kiosk-menu-block/kiosk-menu-block.component';
import { KioskCategoryBlockComponent } from './kiosk-category-block/kiosk-category-block.component';
import { KioskScrollButtonComponent } from './kiosk-scroll-button/kiosk-scroll-button.component';
import { KioskCategoryCardComponent } from './kiosk-category-card/kiosk-category-card.component';
import { KioskConfigurationComponent } from './kiosk-configuration/kiosk-configuration.component';
import {KioskStartOverModalComponent} from './kiosk-start-over-modal/kiosk-start-over-modal.component';

export const components = [KioskNavbarComponent, KioskProductCardComponent, KioskMenuBlockComponent, KioskCategoryBlockComponent, KioskScrollButtonComponent, KioskCategoryCardComponent, KioskConfigurationComponent, KioskStartOverModalComponent];

export * from './kiosk-navbar/kiosk-navbar.component';
export * from './kiosk-product-card/kiosk-product-card.component';
export * from './kiosk-menu-block/kiosk-menu-block.component';
export * from './kiosk-category-block/kiosk-category-block.component';
export * from './kiosk-scroll-button/kiosk-scroll-button.component';
export * from './kiosk-category-card/kiosk-category-card.component';
export * from './kiosk-configuration/kiosk-configuration.component';
export * from './kiosk-start-over-modal/kiosk-start-over-modal.component';
