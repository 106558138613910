import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Product} from '../../../../interfaces/product.interface';

@Component({
  selector: 'app-kiosk-category-card',
  templateUrl: './kiosk-category-card.component.html',
  styleUrls: ['kiosk-category-card.component.scss'],
})
export class KioskCategoryCardComponent {
  @Input() imageURL: string;
  @Input() title: string;
  @Input() description: string;
  @Input() isProduct: string;
  @Input() product: Product;
  @Input() productLoading: string;
  @Input() isSkeleton = false;
  @Output() clickedCard = new EventEmitter<any>();
  @Output() addToOrder = new EventEmitter<any>();
  @Output() modifyProduct = new EventEmitter<any>();
  cardClicked() {
    this.clickedCard.emit();
  }
  addClicked(event) {
    this.addToOrder.emit(event);
  }
  modifyClicked() {
    this.modifyProduct.emit();
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
