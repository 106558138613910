import { AfterViewInit, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavbarSettings, NavbarSettingsSubnavLink } from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import { UtilityService } from '@modules/utility/services';

@Component({
  selector: 'app-additional-desktop-links',
  templateUrl: './additional-desktop-links.component.html',
  styleUrls: ['additional-desktop-links.component.scss'],
})
export class AdditionalDesktopLinksComponent implements AfterViewInit {
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  constructor(private utilityService: UtilityService) {}

  ngAfterViewInit() {
    this.navbarSettings$.subscribe(navbarSettings => {
      if (navbarSettings && navbarSettings.additional_links) {
        const root = document.documentElement;
        root.style.setProperty('--toast-top-nav-padding', '3rem');
      }
    });
  }

  subnavClicked(subnav: NavbarSettingsSubnavLink) {
    this.utilityService.handleDynamicLink(subnav.link);
  }
}
