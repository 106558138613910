import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Vehicle } from 'src/interfaces/vehicle.interface';

@Component({
  selector: 'app-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in'))
    ])
  ]
})
export class VehicleCardComponent {

  @Input() vehicle: Vehicle

  @Output() clickedDeleteVehicle = new EventEmitter<any>();

  deleteVehicleClicked() {
    this.clickedDeleteVehicle.emit();
  }

}
