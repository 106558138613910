import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import {GiftCardConfig} from '@modules/gift-card/models';
import {GenericComponent} from '@modules/ecommerce/models';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-balance-reload-billing-info',
  templateUrl: './balance-reload-billing-info.component.html',
  styleUrls: ['balance-reload-billing-info.component.scss'],
})
export class BalanceReloadBillingInfoComponent implements OnInit, GenericComponent, AfterViewInit {
  @Select(state => state.giftCardOrder.saleConfig) saleConfig$: Observable<GiftCardConfig>;
  @Input() flowType: string;
  @Input() hideCartFunctionality: boolean;
  @Input() set sectionComplete(val: boolean) {
    this.itemConfigured = val;
  }
  @Input() itemTotal = 0;
  @Input() currencyCode: string;
  @Output() sendItemTotal = new EventEmitter<number>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() passData = new EventEmitter<UntypedFormGroup>();
  @Output() createCard = new EventEmitter<number>();
  data = null;
  itemConfigured = false;
  billingInfo = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    streetAddress: new UntypedFormControl('', Validators.required),
    suiteNumber: new UntypedFormControl('', ),
    city: new UntypedFormControl('', Validators.required),
    state: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    zipCode: new UntypedFormControl('', Validators.required),
    phoneNumber: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    sameAsShipping: new UntypedFormControl(false),
  }, { updateOn: 'blur' });
  ngOnInit() {
    this.saleConfig$.subscribe((saleConfig: GiftCardConfig) => {
      this.data = saleConfig;
    });
    this.billingInfo.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.isValid.emit(this.billingInfo.valid);
      if (this.billingInfo.valid) {
        this.passData.emit(this.billingInfo);
      }
    });
    console.log(this.flowType);
  }
  ngAfterViewInit() {}
  changeState(event: any) {
    this.billingInfo.get('state').setValue(event.target.value);
    setTimeout(() => {
      this.isValid.emit(this.billingInfo.valid);
      if (this.billingInfo.valid) {
        this.passData.emit(this.billingInfo);
      }
    }, 300);
  }
  changeCountry(event: any) {
    this.billingInfo.get('country').setValue(event.target.value);
    setTimeout(() => {
      this.isValid.emit(this.billingInfo.valid);
      if (this.billingInfo.valid) {
        this.passData.emit(this.billingInfo);
      }
    }, 300);
  }
  createCardClicked(quantity: number) {
    this.createCard.emit(quantity);
  }
  // Add getters
}
