import {Component, Input, OnInit} from '@angular/core';
import {HistoryEvent} from '../../../../interfaces/history-event.interface';

@Component({
  selector: 'app-activity-event-card',
  templateUrl: './activity-event-card.component.html',
  styleUrls: ['activity-event-card.component.scss'],
})
export class ActivityEventCardComponent implements OnInit {
  @Input() event: HistoryEvent;
  constructor() {}
  ngOnInit() {}
}
