<ng-container *ngIf="mainSettings$ | async as mainSettings">
  <ion-header no-border>
    <ion-toolbar color="secondary" class="de-nav-shadow" no-padding style="padding-top: env(safe-area-inset-top) !important;">
      <ion-buttons slot="start" class="header-icon-container">
        <ng-container *ngIf="routeBack !== ''">
          <ion-back-button
            [defaultHref]="routeBack"
            class="show-back-button" mode="md">
          </ion-back-button>
        </ng-container>
      </ion-buttons>

      <ion-title class="text-center" mode="ios">{{ title | capitalize }}</ion-title>

        <ion-buttons slot="end" class="header-icon-container">
          <ng-container *ngIf="mainSettings.allow_call_to_server">
            <ng-container *ngIf="title !== 'Welcome'">
              <img alt="Call To Server Icon" (click)="openHelpModal()"
                   src="../../../../../assets/icons/cts-icon-white.svg" style="height: 26px; width: 26px;">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
            <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
              <div class="header-icon d-flex flex-column align-items-center justify-content-center" (click)="goToLogin()">
                  <ng-container *ngIf="(user$ | async) as user">
                    <ng-container *ngIf="user.isGuest">
                      <div class="d-flex flex-column align-items-center">
                        <i class="bi bi-person d-flex"></i>
                        <div class="mt-1" [style.font-size.px]="10">Sign In</div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!user.isGuest">
                      <div class="d-flex flex-column align-items-center">
                        <i class="bi bi-person-fill d-flex"></i>
                        <div class="mt-1" [style.font-size.px]="10">Profile</div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
            </ng-container>
          </ng-container>
        </ion-buttons>
    </ion-toolbar>
  </ion-header>
</ng-container>
