import { Pipe, PipeTransform } from '@angular/core';
import {Product} from '../interfaces/product.interface';

@Pipe({
  name: 'daypartProducts'
})
export class DaypartProductsPipe implements PipeTransform {

  transform(products: Product[], showFullMenu: boolean): Product[] {
    return showFullMenu ? products : products.filter(prod => prod.currentlyAvailable);
  }

}
