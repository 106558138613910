<button class="card cms-card-radius delivery-location-btn p-3 d-flex flex-column" (click)="orderNowButtonClicked($event)"
        *ngIf="!isLoading; else skeleton">
  <h5 class="card-title mb-1 line-clamp-2">
    <ng-container *ngIf="!cardEmphasis else addressEmphasis">{{ location?.name }}</ng-container>
  </h5>
  <p class="mb-1">
    <ng-container *ngIf="cardEmphasis else fullAddressEmphasis">{{ location?.name }}</ng-container>
  </p>
  <ng-template #addressEmphasis>{{ location?.address | streetAddress }}</ng-template>
  <ng-template #fullAddressEmphasis>
    {{ location?.address | streetAddress }}, {{ location?.address.city }}, {{ location?.address.state }} {{ location?.address.zipCode }}
  </ng-template>

  <p *ngIf="!(distance | isNan)" id="distance-text" class="mb-1">{{ distance }} miles away</p>

  <div class="d-flex w-100 justify-content-between">
    <p *ngIf="location?.pickupHours && pickupStart && pickupEnd">
      {{ pickupStart | date: 'shortTime' : location?.utcOffset }} -
      {{ pickupEnd | date: 'shortTime' : location?.utcOffset }}
    </p>
    <div class="d-flex ion-align-items-center order-now-btn">
      <ng-container *ngIf="locationLoading !== location?.locationID else orderLoading">
        Order Now
        <i class="bi bi-arrow-right-circle font-size-subheading-1 d-flex ms-2"></i>
      </ng-container>
      <ng-template #orderLoading>
        <div *ngIf="locationLoading === location?.locationID" class="de-btn-loader"></div>
      </ng-template>
    </div>
  </div>
</button>

<ng-template #skeleton>
  <div class="card p-3 d-flex flex-column flex-nowrap w-100">
    <!-- Location Name Skeleton -->
    <ion-skeleton-text animated style="width: 60%; height: 24px;" class="mb-2"></ion-skeleton-text>

    <!-- Address Line 1 Skeleton -->
    <ion-skeleton-text animated style="width: 70%; height: 16px;" class="mb-1"></ion-skeleton-text>

    <!-- Address Line 2 Skeleton -->
    <ion-skeleton-text animated style="width: 50%; height: 16px;" class="mb-1"></ion-skeleton-text>
    <!-- Hours Skeleton -->
    <ion-skeleton-text animated style="width: 60%; height: 16px;"></ion-skeleton-text>
  </div>
</ng-template>