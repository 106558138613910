import { Injectable } from '@angular/core';
import { UserDetailsResponse } from './interfaces/user-details-response.interface';
import { User } from 'src/interfaces/user.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { Appliedreward, Choice, CreateBasketResponse, Product as OloBasketProduct } from './interfaces/create-basket-response.interface';
import { Order } from 'src/interfaces/order.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Location } from 'src/interfaces/location.interface';
import { Hours, Restaurant as TIRestaurant } from './interfaces/all-restaurants-response.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { CreateUserResponse } from './interfaces/create-user-response.interface';
import { Option as OloOption, Optiongroup } from './interfaces/product-modifiers-response.interface';
import { TransferBasketResponse } from './interfaces/transfer-basket-response.interface';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { Option, OrderItemModifier } from 'src/interfaces/option.interface';
import { Category } from 'src/interfaces/category.interface';
import { Calendar, Range } from './interfaces/calendar-response.interface';
import { TimeFrame } from 'src/interfaces/time-frame.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { Upsells } from 'src/interfaces/upsells.interface';
import { UpsellProduct } from 'src/interfaces/upsell-product.interface';
import { Reward as OloReward } from './interfaces/rewards-response.interface';
import { Redeemable as PunchhRedeemable } from '../punchh/interfaces/redeemable.interface';
import { Billingaccount } from './interfaces/billing-accounts-response.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { CustomField } from 'src/interfaces/custom-fields.interface';
import { Fee } from 'src/interfaces/fee.interface';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { UpsellItemsResponse } from './interfaces/upsell-items-response.interface';
import moment from 'moment-timezone';
import { HttpErrorResponse } from '@angular/common/http';
import { SSOProvider } from 'src/interfaces/sso-provider.enum';
import { GiftCardBalance } from './interfaces/gift-card-balance.interface';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';
import { Billingscheme } from './interfaces/billing-schemes-and-account-response.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import { OrderResponseInterface } from './interfaces/order-response.interface';
import { CardDetails } from '../../interfaces/card-details.interface';
import { TastyIgniterOrder } from './interfaces/order.interface';

// import {NutritionInfo} from '../../dineengine-interfacing/dineengine-interfaces/nutrition-info.interface';

@Injectable({
  providedIn: 'root',
})
export class TastyIgniterMappingService {
  ssoLoginProviderName(provider: SSOProvider) {
    switch (provider) {
      case SSOProvider.paytronix:
        return 'paytronix';
      case SSOProvider.punchh:
        return 'punchh';
      case SSOProvider.personica:
        return 'fishbowl-mybistro';
    }
    throw new DineEngineError('Unknown SSO Provider');
  }

  ssoLoginError(err: Error): DineEngineError {
    return new DineEngineError('SSO Login Error');
  }

  loginError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
        case 403:
          msg = err.error.message;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  // Error

  oloError(err: Error): DineEngineError {
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
        case 403:
          msg = err.error.message;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  // Payment

  toCreditCard(account: Billingaccount): SavedCard {
    return {
      description: account.description,
      isDefault: account.isdefault,
      isRemovable: account.removable,
      cardExpiration: moment(account.expiration, 'yyyy-MM').toDate(),
      lastFourDigits: account.cardsuffix,
      savedCardID: account.accountid,
      type: account.cardtype,
    } as SavedCard;
  }

  toGiftCard(giftCard: GiftCardBalance, cardNumber: string, pinNumber: string): GiftCardItem {
    return {
      balanceCents: giftCard.balance * 100,
      cardNumber,
      pinNumber,
      message: giftCard.message ? giftCard.message : null,
    } as GiftCardItem;
  }

  mapPaymentTypes(schemes: Billingscheme[]): PaymentTypes[] {
    const paymentTypes: PaymentTypes[] = [];
    schemes.forEach(scheme => {
      switch (scheme.type) {
        case 'payinstore':
          paymentTypes.push(PaymentTypes.cash);
          break;
        case 'creditcard':
          paymentTypes.push(PaymentTypes.creditCard);
          break;
        case 'giftcard':
          paymentTypes.push(PaymentTypes.giftCard);
          break;
        case 'prepaid':
          paymentTypes.push(PaymentTypes.prepaid);
          break;
      }
    });
    return paymentTypes;
  }

  // Loyalty

  toReward(r: OloReward): Reward {
    return {
      membershipID: r.membershipid,
      name: r.label,
      description: r.description,
      rewardID: r.rewardid || r.reference,
      pointCost: r.value,
      isApplied: r.applied,
      externalRef: r.reference,
      imageURL: r.imageurl,
      redeemInStore: r.availableoffline,
      redeemOnline: r.availableonline,
      redemptionInfo: {
        redemptionCode: null,
        redemptionMessage: null,
        expiryDate: r.expirationdate ? moment(r.expirationdate, 'YYYYMMDD hh:mm').toDate() : null,
        expiryHours: null,
      },
      isDollarReward: false,
    } as Reward;
  }

  punchhRedeemableToReward(r: PunchhRedeemable): Reward {
    return {
      membershipID: null,
      name: r.name,
      description: r.description,
      rewardID: r.id,
      pointCost: r.points,
      isApplied: false,
      externalRef: null,
      redemptionInfo: {
        redemptionCode: null,
        redemptionMessage: null,
        expiryDate: r.expiry_date,
        expiryHours: null,
      },
      isDollarReward: false,
    } as Reward;
  }

  appliedRewardToReward(r: Appliedreward): Reward {
    return {
      membershipID: r.membershipid,
      name: r.label,
      description: r.description,
      rewardID: r.rewardid,
      pointCost: r.value,
      isApplied: r.applied,
      externalRef: r.reference,
      redeemInStore: r.availableoffline,
      redeemOnline: r.availableonline,
    } as Reward;
  }

  // Locations

  restaurantToLocation(restaurant: TIRestaurant, hours: Hours): Location {
    const todayHours: TimeFrame = {
      start: new Date(hours.open),
      end: new Date(hours.close),
    };
    const pickupHours: TimeFrame[] = [];
    for (let i = 0; i < 7; i += 1) {
      const dayHours: TimeFrame = {
        start: this.getDayHours(i, todayHours.start),
        end: this.getDayHours(i, todayHours.end),
      };
      pickupHours.push(dayHours);
    }
    return {
      locationID: restaurant.id,
      address: {
        address1: restaurant.attributes.location_address_1,
        address2: restaurant.attributes.location_address_2,
        city: restaurant.attributes.location_city,
        state: restaurant.attributes.location_state,
        zipCode: restaurant.attributes.location_radius,
        latitude: restaurant.attributes.location_lat,
        longitude: restaurant.attributes.location_lng,
      },
      isOpen: restaurant.attributes.location_status, // Make a flag using the calendars
      isLive: restaurant.attributes.location_status,
      isPrivate: !restaurant.attributes.location_status,
      supportsCurbside: false,
      supportsDriveThru: false,
      supportsDelivery: false,
      supportsDispatch: false,
      supportsGroupOrders: false,
      supportsGuestOrdering: false,
      supportsOnlineOrdering: restaurant.attributes.location_status,
      supportsPickup: true,
      supportsSpecialInstructions: false,
      supportsRecipientName: false,
      customFields: null,
      emailAddress: restaurant.attributes.location_email,
      mapIconURL:
        restaurant.attributes.media && restaurant.attributes.media.find(media => media.tag === 'thumb')
          ? // tslint:disable-next-line:max-line-length
            'https://dev-ti.chepri.io/assets/media/uploads/' + restaurant.attributes.media.find(media => media.tag === 'thumb').file_name
          : null,
      // tslint:disable-next-line:max-line-length
      galleryURL:
        restaurant.attributes.media && restaurant.attributes.media.find(media => media.tag === 'gallery')
          ? // tslint:disable-next-line:max-line-length
            'https://dev-ti.chepri.io/assets/media/uploads/' + restaurant.attributes.media.find(media => media.tag === 'gallery').file_name
          : null,
      name: restaurant.attributes.location_name,
      phoneNumber: restaurant.attributes.location_telephone,
      requiresPhoneNumber: false,
      specialInstructionsMaxLength: 0,
      supportsSplitPayments: false,
      supportsTip: false,
      supportsBasketTransfer: false,
      curbsideHours: null, // this.getHoursForHandoff(restaurant.calendars, HandoffType.curbside)
      deliveryHours: null,
      dispatchHours: null,
      pickupHours,
      driveThruHours: null,
      canDeliver: false,
      seoDescription: restaurant.attributes.description,
      pickupInstructions: '',
      curbsideInstructions: '',
      deliveryInstructions: '',
      dispatchInstructions: '',
      tablesideInstructions: '',
      driveThruInstructions: '',
      slugURL: restaurant.attributes.permalink_slug,
      supportedPaymentTypes: this.tiPaymentMethodsToDEPaymentTypes(restaurant.attributes.options.payments),
      orderAheadDays: 7,
      supportsAdvancedOrders: true,
      supportsAsapOrders: true,
      connectedProjects: [],
    } as Location;
  }

  getDayHours(index: number, today: Date): any {
    const newDate = new Date(today);
    const adjustedDate = newDate.setDate(today.getDate() + index);
    return new Date(adjustedDate).toISOString();
  }

  private toTimeFrame(range: Range): TimeFrame {
    return {
      end: this.getDateFromOLODateString(range.end),
      start: this.getDateFromOLODateString(range.start),
    } as TimeFrame;
  }

  private getDateFromOLODateString(dateStr: string): Date {
    // parse date like yyyymmdd hh:mm
    const year = Number(dateStr.substr(0, 4));
    const month = Number(dateStr.substr(4, 2));
    const day = Number(dateStr.substr(6, 2));
    const hour = Number(dateStr.substr(9, 2));
    const minute = Number(dateStr.substr(12, 2));
    return new Date(year, month - 1, day, hour, minute, 0, 0);
  }

  // Products

  productModifiersToProduct(prod: Product, optionGroups: Optiongroup[]): Product {
    prod.optionGroups = optionGroups ? optionGroups.map(og => this.toOptionGroup(og)) : [];
    return prod;
  }

  toUpsell(upsells: UpsellItemsResponse): Upsells {
    return {
      items: [].concat(...upsells.groups.map(g => g.items)).map(i => this.toUpsellProduct(i)),
    } as Upsells;
  }

  toUpsellProduct(prod: any): UpsellProduct {
    return {
      priceCents: Number(prod.cost.replace('$', '')) * 100,
      name: prod.name,
      productID: prod.id,
      shortdescription: prod.shortdescription,
      maxquantity: prod.maxquantity,
      minquantity: prod.minquantity,
    } as UpsellProduct;
  }

  toOptionGroup(og: Optiongroup): OptionGroup {
    return {
      optionGroupID: og.id.toString(),
      name: og.description, // wtf is the name supposed to be?
      description: null,
      maxAllowed: og.mandatory ? 1 : Number(og.maxselects),
      minRequired: og.mandatory ? 1 : Number(og.minselects),
      options: og.options ? og.options.map(op => this.mapOption(op)) : [],
      mandatorySelection: og.mandatory,
    } as OptionGroup;
  }

  private mapOption(option: OloOption): Option {
    return {
      optionID: option.id.toString(),
      brandOptionID: option.id.toString(),
      name: option.name,
      addedCents: option.cost * 100,
      isDefault: option.isdefault,
      isSelected: false,
      optionGroups: option && option.modifiers ? option.modifiers.map(mod => this.toOptionGroup(mod)) : [],
      nutritionInfo: null,
      standardImageURL: null,
      thumbnailImageURL: null,
    } as Option;
  }

  private basketProductToOrderItem(prod: OloBasketProduct, menuID: string, categoryID: string): OrderItem {
    console.log(prod);
    return {
      categoryID,
      guestName: prod.recipient,
      instructions: prod.specialinstructions,
      longDesc: null,
      menuID,
      name: prod.name,
      nameSlug: null,
      optionGroups: null,
      orderItemID: prod.id.toString(),
      totalCents: prod.totalcost * 100,
      productID: prod.productId.toString(),
      quantity: prod.quantity,
      shortDesc: null,
      standardImageURL: null,
      thumbnailImageURL: null,
      userID: null,
      options: prod.choices.map(op => this.toOrderItemModifier(op)),
      isAlcohol: false,
      seoDescription: null,
      showAsModal: null,
    } as OrderItem;
  }

  private toOrderItemModifier(op: Choice): OrderItemModifier {
    return {
      name: op.name,
      addedCents: op.cost,
      nutritionInfo: null,
      optionID: op.optionid.toString(),
      brandOptionID: op.optionid.toString(),
      quantity: 1,
    } as OrderItemModifier;
  }

  // Basket

  transferBasketResponseToOrder(transfer: TransferBasketResponse, location: Location, billingSchemes: Billingscheme[]): Order {
    return this.createBasketResponseToOrder(transfer.basket, location, billingSchemes);
  }

  handoffTypeToHandoffType(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbside' | 'pickup' | 'drivethru' {
    switch (handoffType) {
      case HandoffType.delivery:
        return 'delivery';
      case HandoffType.dispatch:
        return 'dispatch';
      case HandoffType.curbside:
        return 'curbside';
      case HandoffType.pickup:
        return 'pickup';
      case HandoffType.driveThru:
        return 'drivethru';
      default:
        return 'pickup';
    }
  }

  handoffTypeToString(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbside' | 'collection' | 'drivethru' {
    switch (String(handoffType)) {
      case 'delivery':
        return 'delivery';
      // case ('dispatch'): return 'dispatch';
      // case ('curbside'): return 'curbside';
      case 'pickup':
        return 'collection';
      // case ('driveThru'): return 'drivethru';
      default:
        return 'collection';
    }
  }

  handoffTypeToHandoffTypeReverse(handoffType: string): HandoffType {
    switch (handoffType) {
      case 'delivery':
        return HandoffType.delivery;
      // case ('dispatch'): return HandoffType.dispatch;
      // case ('curbside'): return HandoffType.curbside;
      case 'collection':
        return HandoffType.pickup;
      // case ('drivethru'): return HandoffType.driveThru;
      default:
        return HandoffType.pickup;
    }
  }

  private handoffTypeToCalendarType(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbsidepickup' | 'business' | 'drivethru' {
    switch (handoffType) {
      case HandoffType.delivery:
        return 'delivery';
      case HandoffType.dispatch:
        return 'dispatch';
      case HandoffType.curbside:
        return 'curbsidepickup';
      case HandoffType.pickup:
        return 'business';
      case HandoffType.driveThru:
        return 'drivethru';
      default:
        return 'business';
    }
  }

  private oloFeeToFee(fee: any): Fee {
    return {
      feeCents: fee.amount * 100,
      description: fee.description,
      note: fee.note,
    } as Fee;
  }

  // Order

  orderToOrder(order: any, location: Location): Order {
    // let addrParts = ['', ''];
    // if (order.deliveryaddress && order.deliveryaddress.streetaddress) {
    //     addrParts = order.deliveryaddress.streetaddress.split(',');
    // }
    console.log(order.data);
    return {
      orderID: order.data.id,
      orderReference: order.data.attributes.hash,
      taxCents: 0,
      subTotalCents: order.data.attributes.order_totals[0].value * 100,
      tipCents: 0,
      totalCents: order.data.attributes.order_totals[0].value * 100,
      orderPlacedTimestamp: order.data.attributes.status_updated_at,
      appliedCouponCents: 0,
      handoffType: this.handoffTypeToHandoffTypeReverse(order.data.attributes.order_type),
      orderReadyTimestamp: order.data.attributes.order_date_time,
      location: location,
      // orderStatus: order.status_id,
      orderStatus: order.data.attributes.status_name,
      items: order.data.attributes.order_menus,
      deliveryAddress: null,
      earliestReadyTimestamp: null,
      isASAP: null,
      customFields: null,
      curbsideCustomFields: null,
      dineinCustomFields: null,
      appliedCouponCode: null,
      appliedReward: null,
      appliedRewards: [],
      balanceCents: null,
      isGroup: null,
      paidCents: null,
      specialInstructions: order.comment,
      tableNumber: null,
      fees: [],
      deliveryFee: null,
      canTip: false,
      arrivalstatus: null,
      isEditable: null,
      customerFacingID: null,
      requiresFullBillingAddress: false,
      donationType: [],
      donationsTotal: 0,
    } as Order;
  }

  createBasketResponseToOrder(basket: CreateBasketResponse, location: Location, bSchemes: Billingscheme[]): Order {
    return {
      orderID: basket.id,
      taxCents: basket.taxes.map(tax => tax.tax).reduce((a, b) => a + b, 0) * 100,
      subTotalCents: basket.subtotal * 100,
      tipCents: basket.tip * 100,
      totalCents: basket.total * 100,
      appliedCouponCents: basket.discount * 100,
      orderPlacedTimestamp: null,
      handoffType: this.handoffTypeToHandoffTypeReverse(basket.deliverymode),
      orderReadyTimestamp: basket.timewanted
        ? this.getDateFromOLODateString(basket.timewanted)
        : this.getDateFromOLODateString(basket.earliestreadytime),
      location,
      orderStatus: null,
      items: basket.products.map(prod => this.basketProductToOrderItem(prod, null, null)),
      deliveryAddress: basket.deliveryaddress
        ? {
            address1: basket.deliveryaddress.streetaddress.split(',')[0],
            address2: basket.deliveryaddress.streetaddress.split(',')[1] ? basket.deliveryaddress.streetaddress.split(',')[1].trim() : '',
            city: basket.deliveryaddress.city,
            state: null,
            zipCode: basket.deliveryaddress.zipcode,
            latitude: null,
            longitude: null,
            specialInstructions: basket.deliveryaddress.specialinstructions ? basket.deliveryaddress.specialinstructions : null,
          }
        : null,
      customFields: basket.customfields ? basket.customfields.map(custom => this.getCustomFields(custom)) : null,
      earliestReadyTimestamp: this.getDateFromOLODateString(basket.earliestreadytime),
      isASAP: basket.timemode === 'asap',
      appliedCouponCode: basket.coupon ? basket.coupon.couponcode : null,
      appliedRewards: basket.appliedrewards ? basket.appliedrewards.map(r => this.appliedRewardToReward(r)) : [],
      balanceCents: 0,
      isGroup: false,
      paidCents: 0,
      specialInstructions: null,
      fees: basket.fees.map(fee => this.oloFeeToFee(fee)),
      deliveryFee:
        basket.deliverymode === 'dispatch' || basket.deliverymode === 'delivery'
          ? {
              note: 'Delivery Fee',
              description: 'Delivery Fee',
              feeCents: basket.customerhandoffcharge * 100,
            }
          : null,
      canTip: basket.allowstip,
      arrivalstatus: basket.arrivalstatus ? basket.arrivalstatus : null,
      supportedPaymentTypes: this.mapPaymentTypes(bSchemes),
      donationType: [],
      donationsTotal: 0,
    } as Order;
  }

  tiPaymentMethodsToDEPaymentTypes(paymentMethods: any) {
    const paymentTypes: PaymentTypes[] = [];
    if (paymentMethods && paymentMethods !== '0' && paymentMethods.length && paymentMethods.length > 0) {
      paymentMethods.forEach(method => {
        // if (method.attributes.status) {
        //     paymentTypes.push(this.tiPaymentMethodToDEPaymentType(method.attributes.code));
        paymentTypes.push(this.tiPaymentMethodToDEPaymentType(method));
        // }
      });
    }
    return paymentTypes;
  }

  tiPaymentMethodToDEPaymentType(paymentMethod: string): PaymentTypes {
    switch (paymentMethod) {
      case 'cod':
        return PaymentTypes.cash;
      case 'paypalexpress':
        return PaymentTypes.paypalExpress;
      case 'authorizenetaim':
        return PaymentTypes.authorizeNet;
      case 'stripe':
        return PaymentTypes.stripe;
      case 'mollie':
        return PaymentTypes.mollie;
      case 'square':
        return PaymentTypes.square;
    }
  }
  // User

  createUserResponseToUser(userReq: CreateUserResponse): User {
    return {
      userID: userReq.authtoken,
      firstName: userReq.firstname,
      lastName: userReq.lastname,
      email: userReq.emailaddress,
      phoneNumber: userReq.contactnumber,
    } as User;
  }

  userDetailsToUser(userDetails: UserDetailsResponse): User {
    return {
      userID: userDetails.authtoken,
      firstName: userDetails.firstname,
      lastName: userDetails.lastname,
      email: userDetails.emailaddress,
      userAsBarcode: null,
      userAsQrCode: null,
      isGuest: false,
    } as User;
  }

  // Menu

  tiMenusToDEProducts(menuID: string, tiProducts: any, categories: any): Menu {
    const products: Product[] = [];
    tiProducts.data.forEach(product => {
      const optionGroups: OptionGroup[] = [];
      tiProducts.included.forEach(optionGroup => {
        if (optionGroup.type === 'menu_options' && optionGroup.attributes.menu_id === parseInt(product.id, 10)) {
          const optionGroupsValues: Option[] = [];
          if (optionGroup.attributes.menu_option_values && optionGroup.attributes.menu_option_values.length > 0) {
            optionGroup.attributes.menu_option_values.forEach(optionGroupValue => {
              optionGroupsValues.push(this.tiMenuOptionValueToDEOption(optionGroupValue, optionGroup));
            });
          }
          optionGroups.push(this.tiMenuOptionToDEOptionGroup(optionGroup, optionGroupsValues));
        }
      });
      products.push(this.tiProductToDEProduct(product, optionGroups));
    });
    categories.forEach((category: Category) => {
      category.products = products.filter(product => {
        return product.categoryID === category.categoryID;
      });
    });
    return {
      menuID,
      categories,
    } as Menu;
  }

  tiCategoriesToDECategories(menuID: string, tiCategories: any): Category[] {
    const categories: Category[] = [];
    tiCategories.data.forEach(category => {
      const menuContainsCategory = category.relationships.locations.data.find(location => {
        return location.id === menuID;
      });
      if (menuContainsCategory) {
        categories.push(this.tiCategoryToDECategory(category));
      }
    });
    return categories;
  }

  tiMenuOptionValueToDEOption(optionGroupValue: any, optionGroup: any): Option {
    return {
      brandOptionID: optionGroupValue.option_value_id,
      optionID: optionGroupValue.option_value_id,
      name: optionGroupValue.name,
      optionGroups: [],
      addedCents: optionGroupValue.price * 100,
      nutritionInfo: null,
      isDefault: optionGroupValue.is_default,
      isSelected: false,
      whenSelected: null,
      modifierCategoryID: null,
      standardImageURL: null,
      thumbnailImageURL: null,
      parentOptionGroupID: optionGroup.attributes.option_id,
    } as Option;
  }

  tiMenuOptionToDEOptionGroup(optionGroup: any, optionGroupsValues: any): OptionGroup {
    return {
      optionGroupID: optionGroup.attributes.option_id,
      name: optionGroup.attributes.option_name,
      description: '',
      options: optionGroupsValues,
      minRequired: optionGroup.attributes.min_selected,
      maxAllowed: optionGroup.attributes.max_selected,
      mandatorySelection: optionGroup.attributes.required,
    } as OptionGroup;
  }

  tiProductToDEProduct(product: any, optionGroups: any): Product {
    return {
      productID: product.id,
      categoryID:
        product.relationships?.categories?.data && product.relationships?.categories?.data.length
          ? product.relationships?.categories?.data[0]?.id
          : null,
      menuID: '0',
      shortDesc: product.attributes.menu_description,
      longDesc: product.attributes.menu_description,
      name: product.attributes.menu_name,
      nameSlug: product.attributes.menu_name,
      standardImageURL: product?.attributes?.media[0]?.file_name
        ? 'https://dev-ti.chepri.io/assets/media/uploads/' + product.attributes.media[0].file_name
        : null,
      thumbnailImageURL: product?.attributes?.media[0]?.file_name
        ? 'https://dev-ti.chepri.io/assets/media/uploads/' + product.attributes.media[0].file_name
        : null,
      isAlcohol: false,
      seoDescription: product.attributes.menu_description,
      optionGroups,
      priceCents: product.attributes.menu_price * 100,
      isAvailable: product.attributes.menu_status,
      nutritionInfo: null,
      allergenInfo: [],
      requiresModification: null, // view property set on the category page
      canModify: null, // view property set on the category page
      currentlyAvailable: product.attributes.menu_status,
      minQuantity: 1,
    } as Product;
  }

  tiCategoryToDECategory(category: any): Category {
    return {
      categoryID: category.id,
      name: category.attributes.name,
      nameSlug: category.attributes.permalink_slug,
      description: category.attributes.description,
      products: null,
      standardImageURL: category.attributes.media[0]?.file_name
        ? 'https://dev-ti.chepri.io/assets/media/uploads/' + category.attributes.media[0].file_name
        : null,
      thumbnailImageURL: category.attributes.media[0]?.file_name
        ? 'https://dev-ti.chepri.io/assets/media/uploads/' + category.attributes.media[0].file_name
        : null,
      isHidden: !category.attributes.status,
      seoDescription: category.attributes.description,
      categoryOrder: category.attributes.priority,
    } as Category;
  }

  // Special

  getCustomFields(rest: any): CustomField {
    return {
      id: rest.id,
      label: rest.label,
      required: rest.required,
      value: rest.value ? rest.value : null,
    } as CustomField;
  }

  formatToTIDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  formatToTITime(date) {
    const d = new Date(date);
    let hours = '' + (d.getHours() + 1);
    let minutes = '' + d.getMinutes();
    const year = d.getFullYear();

    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }

    return [hours, minutes].join(':');
  }

  basketToOrderSubmission(basket: Order, cardDetails: CardDetails): OrderResponseInterface {
    // {
    //   basket.taxCents: 0,
    //   basket.subTotalCents: 0,
    //   basket.tipCents: 0,
    //   basket.totalCents: 0,
    //   basket.orderPlacedTimestamp: null,
    //   basket.appliedCouponCents: 0,
    //   basket.handoffType: isNaN(handoff) ? 0 : handoff,
    //   basket.orderReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
    //   basket.orderStatus: null,
    //   basket.deliveryAddress: null,
    //   basket.earliestReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
    //   basket.isASAP: true,
    //   basket.customFields: null,
    //   basket.appliedCouponCode: null,
    //   basket.appliedReward: null,
    //   basket.appliedRewards: [],
    //   basket.balanceCents: 0,
    //   basket.isGroup: null,
    //   basket.paidCents: 0,
    //   basket.specialInstructions: null,
    //   basket.tableNumber: sessionStorage.getItem('tablenumber'),
    //   basket.fees: [],
    //   basket.deliveryFee: null,
    //   basket.canTip: true,
    //   basket.arrivalstatus: null,
    //   basket.isEditable: false
    // }
    return {
      // customer_id: null,
      location_id: 1,
      // location_id: null,
      // address_id: null, // WTF
      first_name: cardDetails.firstName,
      last_name: cardDetails.lastName,
      email: cardDetails.emailAddress,
      telephone: cardDetails.phoneNumber,
      comment: basket.specialInstructions,
      order_type: this.handoffTypeToString(basket.handoffType), // map this
      order_date: this.formatToTIDate(basket.orderReadyTimestamp),
      order_time: this.formatToTITime(basket.orderReadyTimestamp),
      // order_date: null,
      // order_time: null,
      payment: 'cod',
      processed: true,
      status_id: 1,
      // status_comment: 'Order Submitted',
      total_items: basket.items.length,
      order_total: basket.subTotalCents / 100,
      // invoice_prefix: null,
      // invoice_date: null,
      // assignee_id: null,
      // assignee_group_id: null,
      // ip_address: '192.168.10.1',
      // user_agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:76.0) Gecko/20100101 Firefox/76.0',
      order_menus: this.deOrderItemsToTIOrderMenus(basket.items),
      order_totals: [
        {
          code: 'total',
          title: 'Total',
          value: 4.99,
          priority: 1,
        },
      ],
    };
  }

  deOrderItemsToTIOrderMenus(orderItems: OrderItem[]) {
    console.log(orderItems);
    const orderMenus = [];
    orderItems.forEach(item => {
      orderMenus.push({
        rowId: 0,
        id: item.productID,
        name: item.name,
        qty: item.quantity,
        price: item.totalCents / 100,
        subtotal: (item.totalCents * item.quantity) / 100,
        comment: item.instructions,
        options: [],
      });
    });
    return orderMenus;
  }
}
