import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {InboxMessage} from '../../../../../../interfaces/inbox-message.interface';

@Component({
  selector: 'app-inbox-message-card',
  templateUrl: './inbox-message-card.component.html',
  styleUrls: ['./inbox-message-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({opacity: '0'})),
      state('*', style({opacity: '1'})),
      transition('void <=> *', animate('400ms ease-in')),
    ]),
  ],
})
export class InboxMessageCardComponent {

  @Input() message: InboxMessage;

}
