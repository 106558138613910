import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';
import {User} from '../../../../interfaces/user.interface';
import {HandoffType} from '../../../../interfaces/handoff-type.enum';

@Component({
  selector: 'app-order-type-picker',
  templateUrl: './order-type-picker.component.html',
  styleUrls: ['order-type-picker.component.scss'],
})
export class OrderTypePickerComponent implements OnInit {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor>;
  @Output() handoffTypeClick = new EventEmitter<HandoffType>();

  handoffTypes = HandoffType;

  constructor() {}

  ngOnInit() {}

  handoffTypeClicked(handoffType: HandoffType) {
    this.handoffTypeClick.emit(handoffType);
  }
}
