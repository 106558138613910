import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {Product} from '../../../../interfaces/product.interface';
import {ModalController} from '@ionic/angular';
import {OptionGroup} from '../../../../interfaces/option-group.interface';
import {ConfiguredProduct, MenuService} from '@modules/menu/services';
import {Option} from '../../../../interfaces/option.interface';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OptionStack, OptionSubGroup} from '@modules/menu/components';

export interface OptionGroupForm {
  id: string;
  form: UntypedFormGroup;
}

export interface SubGroupQualifiers {
  word: string;
  fix: 'prefix' | 'suffix';
}

@Component({
  selector: 'app-customize-product-block',
  templateUrl: './customize-product-block.component.html',
  styleUrls: ['customize-product-block.component.scss'],
})
export class CustomizeProductBlockComponent implements OnInit, OnDestroy {
  @Select(state => state.menu.selectedProduct) product$: Observable<Product>;
  @Output() selctedOptions = new EventEmitter<Option[]>();
  product: Product;
  parentStack: OptionStack;
  parentOptionGroup: OptionGroup;
  parentOption: Option;
  optionGroups: OptionGroup[];
  optionForms: OptionGroupForm[] = [];
  optionSubGroups: OptionSubGroup[][] = [];
  groupSelections: string[] = [];
  options: Option[] = [];
  aggregatedGroupLimits: number[] = [];
  quantity = 1;
  depth = 0;
  multiselect = false;
  hasSteps = false;
  currentStepIndex = 0;
  nextStep = null;
  loadingNewGroup = false;
  canAdd = false;
  expandDescription = false;
  private subs: Subscription[] = [];
  constructor(
    private menuService: MenuService,
    private modalController: ModalController,
    private fb: UntypedFormBuilder
  ) {}
  ngOnInit() {
    this.subs.push(
      this.product$.subscribe((product: Product) => {
        if (product && product.optionGroups && product.optionGroups.length) {
          this.product = product;
          this.generateFormGroups(this.product.optionGroups);
          this.generateSubgroups(this.product.optionGroups);
        }
      })
    );
  }
  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  closeClicked() {
    this.modalController.dismiss().then((r) => {
    });
  }
  continueClicked(product: Product) {
    console.log(this.optionForms);
    if (!this.optionGroups) {
      const configuredProduct: ConfiguredProduct = {
        product,
        options: this.options
      };
      this.menuService.addConfiguredProductToCart(configuredProduct, this.quantity);
      this.modalController.dismiss(this.options).then((r) => {
        console.log('modal close', r);
      });
    } else {
      this.goToParent();
    }
  }
  incrementClicked() {
    this.quantity += 1;
  }
  decrementClicked() {
    this.quantity -= 1;
  }
  addOptionAndEdit(optionStack: OptionStack) {
    this.addOrRemoveOptionAndUpdateView(optionStack);
    setTimeout(() => {
      this.editStarted(optionStack);
    }, 200);
  }
  addOrRemoveOptionAndUpdateView(optionStack: OptionStack) {
    const multiselect = optionStack.optionGroup.maxAllowed !== 1;
    if (this.optionGroups) {
      this.optionGroups.forEach((optionGroup: OptionGroup, i: number) => {
        this.canAdd = optionGroup.minRequired > 0 && this.optionForms[i]?.form?.invalid;
      });
    } else {
      this.product.optionGroups.forEach((optionGroup: OptionGroup, i: number) => {
        this.canAdd = optionGroup.minRequired > 0 && this.optionForms[i]?.form?.invalid;
      });
    }
    console.log('setgroup', multiselect);
    if (!multiselect) {
      if (!!optionStack.subOptionGroup) {
        this.setSubRadioGroup(optionStack);
      } else {
        this.setRadioGroup(optionStack);
      }
      optionStack.optionGroup.options.forEach((selectedOption: Option, i: number) => {
        const oldValue = this.options.findIndex((option: Option) => option.optionID === selectedOption.optionID);
        if (oldValue >= 0) {
          this.options.splice(oldValue, 1);
        }
      });
      this.addOrRemoveSelectedOption(optionStack.option);
    } else {
      const groupLimit = optionStack.optionGroup.maxAllowed !== 0
        ? optionStack.optionGroup.maxAllowed === 0 : optionStack.optionGroup.options.length;
      this.addOrRemoveSelectedOption(optionStack.option);
    }
    // console.log(this.optionForms);
    // console.log(this.options);
  }
  editStarted(optionStack: OptionStack) {
    this.loadingNewGroup = true;
    this.generateFormGroups(optionStack.option.optionGroups);
    this.generateSubgroups(optionStack.option.optionGroups);
    this.depth += 1;
    if (this.depth > 1) {
      this.parentStack = {
        option: this.parentOption,
        optionGroup: this.parentOptionGroup
      };
    }
    this.parentOption = optionStack.option;
    this.parentOptionGroup = optionStack.optionGroup;
    this.optionGroups = optionStack.option.optionGroups;
    this.multiselect = this.parentOptionGroup?.maxAllowed !== 1;
    this.hasSteps = false;
    this.nextStep = null;
    if (this.parentOptionGroup?.options && this.parentOptionGroup?.options.length && this.multiselect) {
      this.hasSteps = true;
      if (this.parentStack) {
        this.parentStack.option.optionGroups.forEach((parentOptionGroup: OptionGroup, parentIndex: number) => {
          if (this.parentOptionGroup.optionGroupID === parentOptionGroup.optionGroupID) {
            if (parentIndex < this.parentStack.option.optionGroups.length) {
              this.nextStep = this.parentStack.option.optionGroups[parentIndex + 1];
            }
          }
        });
      }
    }
    this.loadingNewGroup = false;
  }
  siblingStarted(optionStack: OptionStack) {
    this.loadingNewGroup = true;
    this.generateFormGroups(optionStack.option.optionGroups);
    this.generateSubgroups(optionStack.option.optionGroups);
    this.parentOption = optionStack.option;
    this.parentOptionGroup = optionStack.optionGroup;
    this.optionGroups = optionStack.option.optionGroups;
    this.multiselect = this.parentOptionGroup?.maxAllowed !== 1;
    this.hasSteps = false;
    this.nextStep = null;
    if (this.parentOptionGroup?.options && this.parentOptionGroup?.options.length && this.multiselect) {
      this.hasSteps = true;
      this.parentStack.option.optionGroups.forEach((parentOptionGroup: OptionGroup, parentIndex: number) => {

        if (this.parentOptionGroup.optionGroupID === parentOptionGroup.optionGroupID) {
          if (parentIndex < this.parentStack.option.optionGroups.length) {
            this.nextStep = this.parentStack.option.optionGroups[parentIndex + 1];
          }
        }
      });
    }
    this.loadingNewGroup = false;
  }
  goToParent() {
    this.loadingNewGroup = true;
    this.depth -= 1;
    if (this.depth === 0) {
      this.generateFormGroups(this.product.optionGroups);
      this.generateSubgroups(this.product.optionGroups);
      this.parentOption = undefined;
      this.parentOptionGroup = undefined;
      this.optionGroups = undefined;
    } else {
      this.generateFormGroups(this.parentStack.option.optionGroups);
      this.generateSubgroups(this.parentStack.option.optionGroups);
      this.parentOption = this.parentStack.option;
      this.parentOptionGroup = this.parentStack.optionGroup;
      this.optionGroups = this.parentStack.option.optionGroups;
      this.multiselect = this.parentOptionGroup?.maxAllowed !== 1;
      this.hasSteps = false;
      this.nextStep = null;
      if (this.parentOptionGroup?.options && this.parentOptionGroup?.options.length && this.multiselect) {
        this.hasSteps = true;
        this.parentOptionGroup?.options.forEach((option: Option, index: number) => {
          if (this.parentOption?.optionID === option.optionID) {
            this.currentStepIndex = index;
            if (index < this.parentOptionGroup.options.length) {
              this.nextStep = this.parentOptionGroup.options[index + 1];
            }
          }
        });
      }
    }
    this.loadingNewGroup = false;
  }
  generateFormGroups(optionGroups: OptionGroup[]) {
    this.aggregatedGroupLimits = [];
    optionGroups.forEach((optionGroup: OptionGroup) => {
      const foundForm = this.optionForms.find((optionForm: OptionGroupForm) => optionForm.id === optionGroup.nameSlug);
      if (!foundForm) {
        const optionGroupForm = this.fb.group({});
        this.optionForms.push({
          id: optionGroup.nameSlug,
          form: optionGroupForm
        });
      }
      this.aggregatedGroupLimits.push(optionGroup.maxTotalQuantity);
    });
    // console.log(this.optionForms);
    console.log(this.aggregatedGroupLimits);
  }
  generateSubgroups(optionGroups: OptionGroup[]) {
    const keyWords: SubGroupQualifiers[] = [
      // { word: 'No ', fix: 'prefix' },
      { word: ' - Whole Pie', fix: 'suffix' },
      { word: ' - Left Half', fix: 'suffix' },
      { word: ' - Right Half', fix: 'suffix' },
      { word: ' - Extra', fix: 'suffix' },
      { word: ' - Remove', fix: 'suffix' },
      { word: ' - Remove Left Half', fix: 'suffix' },
      { word: ' - Remove Right Half', fix: 'suffix' },
      { word: 'Light ', fix: 'prefix' },
      { word: 'Regular ', fix: 'prefix' },
      { word: 'Extra ', fix: 'prefix' },
    ];
    this.optionSubGroups = [];
    optionGroups.forEach((optionGroup: OptionGroup) => {
      const optionSubGroups: OptionSubGroup[] = [];
      if (optionGroup.displayType === 'button-group') {
        optionGroup.options.forEach((option: Option) => {
          let match = false;
          keyWords.forEach((qualifier: SubGroupQualifiers) => {
            if (!match) {
              const optionFix = qualifier.fix === 'prefix'
                ? option.name.slice(0, qualifier.word.length).toLowerCase()
                : option.name.slice(option.name.length - qualifier.word.length, option.name.length).toLowerCase();
              if (optionFix === qualifier.word.toLowerCase()) {
                match = true;
                const subgroupName = qualifier.fix === 'prefix'
                  ? option.name.slice(qualifier.word.length)
                  : option.name.slice(0, option.name.length - qualifier.word.length + 1);
                const currentSubgroup = optionSubGroups.findIndex((subgroup: OptionSubGroup) => subgroup.name === subgroupName);
                const subGroupOptionName = qualifier.fix === 'prefix'
                  ? qualifier.word.slice(0, qualifier.word.length - 1)
                  : qualifier.word.slice(3, qualifier.word.length);
                if (currentSubgroup !== -1) {
                  optionSubGroups[currentSubgroup].subOptions.push({
                    name: subGroupOptionName,
                    option
                  });
                } else {
                  const newSubgroup: OptionSubGroup = {
                    name: subgroupName,
                    type: subGroupOptionName === 'No' || subGroupOptionName === 'Light'
                      || subGroupOptionName === 'Regular' || subGroupOptionName === 'Extra'
                      ? 'button-group' : 'half-and-half',
                    subOptions: [{
                      name: subGroupOptionName,
                      option
                    }]
                  };
                  optionSubGroups.push(newSubgroup);
                }
              }
            }
          });
        });
      }
      this.optionSubGroups.push(optionSubGroups);
    });
    let noSubGroups = true;
    this.optionSubGroups.forEach((group: OptionSubGroup[]) => {
      noSubGroups = group.length === 0;
    });
    if (noSubGroups) {
      this.optionSubGroups = [];
    }
    // console.log(this.optionSubGroups);
  }
  setNewSliderLimit(value: number, index: number, optionGroups: OptionGroup[]) {
    this.aggregatedGroupLimits[index] = this.aggregatedGroupLimits[index] + value;
    console.log(this.aggregatedGroupLimits);
  }
  setRadioGroup(optionStack: OptionStack) {
    const formIndex = this.getFormGroupIndexByOptionGroupName(optionStack.optionGroup.nameSlug);
    if (formIndex !== -1) {
      this.optionForms[formIndex].form.get(optionStack.optionGroup.nameSlug).setValue(optionStack.option.nameSlug);
    }
  }
  setSubRadioGroup(optionStack: OptionStack) {
    const formIndex = this.getFormGroupIndexByOptionGroupName(optionStack.optionGroup.nameSlug);
    if (formIndex !== -1) {
      this.optionForms[formIndex].form.get(optionStack.optionGroup.nameSlug).setValue(optionStack.subOptionGroup.name);
    }
  }
  addOrRemoveSelectedOption(option: Option) {
    if (this.options.find((opt: Option) => opt.optionID === option.optionID)) {
      this.removeSelectedOption(option);
    } else {
      this.options.push(option);
    }
  }
  removeSelectedOption(option: Option) {
    const index = this.options.findIndex((opt: Option) => opt.optionID === option.optionID);
    if (index > -1) {
      this.options.splice(index, 1);
    }
  }
  getFormGroupIndexByOptionGroupName(name: string) {
    let formIndex = -1;
    this.optionForms.forEach((optionGroupForm: OptionGroupForm, i: number) => {
      if (formIndex === -1) {
        let formFound = false;
        Object.keys(optionGroupForm.form.controls).forEach(key => {
          formFound = (key === name);
        });
        if (formFound) {
          formIndex = i;
        }
      }
    });
    return formIndex;
  }
}
