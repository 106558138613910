import { Pipe, PipeTransform } from '@angular/core';
import {ICoordinates} from '@modules/locations/models/coordinates.interface';

@Pipe({
  name: 'geoDistance'
})
export class GeoDistancePipe implements PipeTransform {

  transform(locationCoordinates: ICoordinates, userCoordinates: ICoordinates): number {
    const latLng1 = new google.maps.LatLng(locationCoordinates.latitude, locationCoordinates.longitude);
    const latLng2 = new google.maps.LatLng(userCoordinates.latitude, userCoordinates.longitude);
    return Math.ceil(Math.abs(0.000621371 * google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2)));
  }

}
