<div class="popup-body p-3" style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
  <div style="display: grid; width: 100%; place-items: center end">
    <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
      <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
  </div>
  <ng-container *ngIf="reward.imageURL; else noImage">
    <img [src]="reward.imageURL" style="height: 100px; width: 100px; object-fit: cover" [alt]="reward.name"/>
  </ng-container>
  <ng-template #noImage>
    <i class="bi bi-cash-stack" style="font-size: 100px; height: 100px; width: 100px;"></i>
  </ng-template>
  <div class="header-font modal-title">{{reward.name}}</div>
  <div class="modal-text ln-1">{{reward.description}}</div>
  <button class="w-100 de-primary-btn" [disabled]="!reward.redeemOnline" (click)="redeemClicked()">
    <ng-container *ngIf="reward.redeemOnline">
      <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
        <div class="">Add To Cart</div>
      </div>
    </ng-container>
    <ng-container *ngIf="!reward.redeemOnline">
      Not Available Online
    </ng-container>
  </button>
  <ng-container *ngIf="reward.rewardID !== 'banked' && mobile.isMobile">
    <button class="de-secondary-btn w-100" [disabled]="!reward.redeemInStore" (click)="redeemInStoreClicked()">
      <ng-container *ngIf="reward.redeemInStore">
        <ng-container *ngIf="!inStoreLoading">
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
            <div class="">Redeem At Register</div>
          </div>
        </ng-container>
        <ng-container *ngIf="inStoreLoading">
          <div class="de-btn-loader-secondary"></div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!reward.redeemInStore">
        Not Available In-Store
      </ng-container>
    </button>
    <div class="modal-text">Redeeming your reward online?<br/>You will see it applied at checkout</div>
  </ng-container>

  <!-- <div style="display: grid; width: 100%; place-items: center end">
    <div id="close" class="d-flex align-items-center" (click)="activeModal.close('Close click')">
      <i class="bi bi-x-circle font-size-h1 d-flex"></i>
    </div>
  </div> -->

  <!--  <ion-row style="padding: 0 20px;">-->
  <!--    <ion-col class="modal-contents ion-no-padding">-->
  <!--      -->
  <!--      <ion-row class="ion-justify-content-center">-->
  <!--        <ng-container *ngIf="reward.imageURL; else noImage">-->
  <!--          <img [src]="reward.imageURL" style="height: 100px; width: 100px; object-fit: cover"/>-->
  <!--        </ng-container>-->
  <!--        <ng-template #noImage>-->
  <!--              <i class="bi bi-cash-stack" style="font-size: 100px; height: 100px; width: 100px;"></i>-->
  <!--        </ng-template>-->
  <!--      </ion-row>-->
  <!--      <ion-row class="header-font modal-title">-->
  <!--        {{ reward.name }}-->
  <!--      </ion-row>-->
  <!--      <ion-row class="modal-text ln-1">-->
  <!--        {{ reward.description }}-->
  <!--      </ion-row>-->

  <!--      <ion-button class="de-primary-btn" id="modify" expand="full" (click)="redeemClicked()">-->
  <!--        Redeem Online-->
  <!--      </ion-button>-->

  <!--      &lt;!&ndash; //IN-STORE REDEMPTION  &ndash;&gt;-->
  <!--      -->
  <!--      <ion-row *ngIf="reward.rewardID !== 'banked'" class="instore-redemption">-->
  <!--        <p (click)="redeemInStoreClicked()">-->
  <!--          Redeem In-Store-->
  <!--        </p>-->
  <!--      </ion-row>-->

  <!--    </ion-col>-->
  <!--  </ion-row>-->
</div>
