import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MobileService } from 'src/services/mobile.service';
import { Plugins } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { UtilityService } from '@modules/utility/services';

@Component({
  selector: 'app-promotion-modal',
  templateUrl: './promotion-modal.component.html',
  styleUrls: ['./promotion-modal.component.scss'],
})
export class PromotionModalComponent {
  @Input() eyebrowText;
  @Input() headingText;
  @Input() headingColor;
  @Input() subheadingText;
  @Input() bodyText;
  @Input() imageUrl;
  @Input() buttonText;
  @Input() buttonLink;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public mobile: MobileService,
    private utilityService: UtilityService
  ) {}

  buttonClicked() {
    this.activeModal.close();
    if (this.buttonLink) {
      this.utilityService.handleDynamicLink(this.buttonLink);
    }
  }

  bodyClicked() {
    if (this.mobile.isMobile) {
      this.activeModal.close('Close click');
      if (this.buttonLink) {
        this.utilityService.handleDynamicLink(this.buttonLink);
      }
    }
  }
}
