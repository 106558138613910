<ng-container *ngIf="!isCart; else cartReward">
  <ng-container *ngIf="reward; else emptyReward">
    <div class="card cms-card-radius overflow-hidden">
      <div
        class="card-body p-0 rounded-0 achievement-badge-wrapper d-flex flex-row flex-nowrap grid-gap-3 position-relative">
        <!-- Quantity Indicator -->
        <ng-container *ngIf="reward.quantity">
          <small class="quantity-pill position-absolute bottom-0 start-0 mb-1 ms-1">
            x{{ reward.quantity }}
          </small>
        </ng-container>
        <!-- Image Section -->
        <div class="d-flex flex-column align-items-center position-relative">
          <ng-container
            *ngIf="reward.imageURL">
            <img
              [src]="reward.imageURL"
              class="reward-image w-100 img-border-inherit"
              [alt]="reward.name" />
          </ng-container>
          <ng-template #noImage>
            <img
              src="assets/img/600x600.png"
              class="reward-image w-100 img-border-inherit"
              [alt]="reward.name" />
            <div
              class="position-absolute w-100 h-100 img-border-inherit"
              style="display: grid; place-items: center; top: 0; background-color: var(--ion-color-card-background-color);">
              <div class="no-image-text font-size-body-large">
                {{ reward.name }}
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Text and Action Buttons Section -->
        <div class="d-flex flex-column w-100 p-3">
          <!-- Title and Optional Info Button -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h6 class="mb-0 fw-bold line-clamp-2">{{ reward.name }}</h6>
          </div>

          <!-- Expiration Date -->
          <div *ngIf="reward.expDate" class="font-size-body-small text-nowrap mb-2">
            Expires: {{ reward.expDate | date: 'shortDate' }}
          </div>

          <!-- Action Buttons -->
          <ng-container *ngIf="canRedeem$ | async">
            <div class="mt-auto d-flex justify-content-end">
              <ng-container *ngIf="!disabled">
                <ng-container
                  *ngIf="
                !(loadingReward === reward.rewardID && reward.rewardID !== null)
              "
                >
                  <!-- Redeem Button -->
                  <ng-container *ngIf="!reward.isApplied && !isStaged">
                    <button
                      type="button"
                      class="fw-bold pointer de-primary-btn-sm font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center"
                      (click)="redeemClicked()"
                    >
                      Redeem
                      <i
                        class="ms-2 bi bi-plus-circle font-size-body-large"
                      ></i>
                    </button>
                  </ng-container>
                  <!-- Remove Button -->
                  <ng-container *ngIf="reward.isApplied || isStaged">
                    <button
                      type="button"
                      class="fw-bold pointer de-danger-btn-sm font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center"
                      (click)="removeClicked()"
                    >
                      Remove
                      <i
                        class="ms-2 bi bi-dash-circle font-size-body-large"
                      ></i>
                    </button>
                  </ng-container>
                </ng-container>
                <!-- Loading State -->
                <ng-container
                  *ngIf="
                loadingReward === reward.rewardID && reward.rewardID !== null
              "
                >
                  <button
                    type="button"
                    class="d-flex flex-row flex-nowrap text-nowrap gap-1 de-primary-btn align-items-center"
                  >
                    <div class="fw-bold">Loading</div>
                    <i
                      class="bi bi-arrow-repeat spinning font-size-body-large"
                    ></i>
                  </button>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-template #emptyReward>
    <div class="rewards-card-empty">
      <small class="text-center text-muted">
        Place orders to earn points and rewards.
      </small>
      <div
        class="d-flex flex-column align-items-center justify-content-center h-100 w-100 text-muted"
        style="gap: 0.5rem">
        <ng-container *ngIf="theme$ | async as theme">
          <app-custom-icon
            class=""
            default="bi-award"
            customIconKey="rewards_navigation_icon"
            size="48"></app-custom-icon>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #cartReward>
  <ng-container *ngIf="reward.isApplied && !isStaged; else stagedCartReward">
    <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
      <div class="reward-cart-card">

        <ng-container *ngIf="reward.imageURL else rewardIcon">
          <div
            class="reward-cart-icon-shell"
            style="height: 34px; width: 34px">
            <img
              class="reward-cart-icon"
              [src]="reward.imageURL" />
          </div>
        </ng-container>
        <ng-template #rewardIcon>

          <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
            <ng-container *ngIf="mobileAppSettings.active_rewards_icon">
              <div
                class="reward-cart-icon-shell" [style.background-color]="mobileAppSettings.background_color"
                style="height: 34px; width: 34px">
                <img
                  class="reward-cart-icon"
                  [src]="
                  mobileAppSettings.active_rewards_icon?.data
                    ? mobileAppSettings.active_rewards_icon.data.full_url
                    : mobileAppSettings.active_rewards_icon.data.full_url
                " />
              </div>
            </ng-container>
          </ng-container>
        </ng-template>


        <div class="d-flex flex-nowrap gap-3 flex-grow-1 px-2">
          <div class="fees-small applied-reward-name">
            {{ reward.name }}
          </div>
        </div>

        <ng-container *ngIf="!disabled">
          <ng-container
            *ngIf="
                !(loadingReward === reward.rewardID && reward.rewardID !== null)
              ">
            <ng-container *ngIf="reward.isApplied || isStaged">
              <div
                class="fw-bold pointer h-auto font-size-body d-flex flex-row flex-nowrap align-items-center gap-2 pe-2"
                (click)="removeClicked()">
                <i class="bi bi-dash-circle font-size-body-large d-flex"></i>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
                loadingReward === reward.rewardID && reward.rewardID !== null
              ">
            <div
              class="d-flex flex-row flex-nowrap gap-1 h-auto align-items-center pe-2">
              <div class="fw-bold"></div>
              <i
                class="bi bi-arrow-repeat spinning font-size-body-large d-flex"></i>
            </div>
          </ng-container>
        </ng-container>
      </div>


      <div class="d-flex flex-nowrap gap-3">
        <div class="fees-small-right text-nowrap fw-bold">
          -
          {{
            (reward.discountCents ?? 0) / 100
              | currency: (currencyCode$ | async)
          }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #stagedCartReward>
    <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
      <div class="reward-cart-card-staged">
        <ng-container *ngIf="reward.imageURL else rewardIcon">
          <div
            class="reward-cart-icon-shell"
            style="height: 34px; width: 34px">
            <img
              class="reward-cart-icon"
              [src]="reward.imageURL" />
          </div>
        </ng-container>
        <ng-template #rewardIcon>

        <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
          <ng-container *ngIf="mobileAppSettings.active_rewards_icon">
            <div
              class="reward-cart-icon-shell" [style.background-color]="mobileAppSettings.background_color"
              style="height: 34px; width: 34px">
              <img
                class="reward-cart-icon"
                [src]="
                  mobileAppSettings.active_rewards_icon?.data
                    ? mobileAppSettings.active_rewards_icon.data.full_url
                    : mobileAppSettings.active_rewards_icon.data.full_url
                " />
            </div>
          </ng-container>
        </ng-container>
        </ng-template>

        <div class="d-flex flex-nowrap gap-3 flex-grow-1 px-2">
          <div class="fees-small applied-reward-name">
            {{ reward.name }}
          </div>
        </div>

        <ng-container *ngIf="!disabled">
          <ng-container
            *ngIf="
              !(loadingReward === reward.rewardID && reward.rewardID !== null)
            ">
            <ng-container *ngIf="reward.isApplied || isStaged">
              <div
                class="fw-bold pointer h-auto font-size-body d-flex flex-row flex-nowrap align-items-center gap-2 pe-2"
                (click)="unstageReward()">
                <i class="bi bi-dash-circle font-size-body-large d-flex"></i>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              loadingReward === reward.rewardID && reward.rewardID !== null
            ">
            <div
              class="d-flex flex-row flex-nowrap gap-1 h-auto align-items-center pe-2">
              <div class="fw-bold"></div>
              <i
                class="bi bi-arrow-repeat spinning font-size-body-large d-flex"></i>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #infoModal>
  <div class="px-3 pt-3 pb-5 position-relative h-100">
    <div class="d-flex justify-content-end" style="position: sticky; top: 1rem;">
      <i class="bi bi-x-circle d-flex pointer bg-white rounded-circle" style="font-size: 30px;"
         data-bs-dismiss="modal"></i>
    </div>
    <div class="grow-1 d-flex flex-column align-items-center h-100" style="max-width: 550px;">

      <ng-container
        *ngIf="reward.imageURL">
        <div class="d-flex justify-content-center pt-4 pt-md-0 pb-4">
          <img
          [src]="reward.imageURL"
          class="reward-image w-100 rounded"
          [alt]="reward.name" />
        </div>
      </ng-container>

      <div class="w-100 modal-heading m-0 header-font py-2">
        <h1 class="font-size-h1 m-0 fw-bold text-center">{{ reward.name }}</h1>
      </div>
      <div class="w-100 d-flex justify-content-center text-center pb-4">
        <div class="header-underline m-0"></div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <ng-container *ngIf="reward.expDate">
          <p class=" text-center">{{ reward.expDate }}</p>
        </ng-container>
        <ng-container *ngIf="reward.description">
          <p class="text-center">{{ reward.description }}</p>
        </ng-container>
      </div>

      <ng-container *ngIf="canRedeem$ | async">
        <div class="mt-auto d-flex justify-content-end w-100">
          <ng-container *ngIf="!disabled">
            <ng-container
              *ngIf="
                !(loadingReward === reward.rewardID && reward.rewardID !== null)
              "
            >
              <!-- Redeem Button -->
              <ng-container *ngIf="!reward.isApplied && !isStaged">
                <button
                  type="button"
                  class="fw-bold pointer de-primary-btn-sm font-size-body d-flex flex-row flex-nowrap text-nowrap justify-content-center align-items-center w-100"
                  (click)="redeemClicked()"
                >
                  Redeem
                  <i
                    class="ms-2 bi bi-plus-circle font-size-body-large"
                  ></i>
                </button>
              </ng-container>
              <!-- Remove Button -->
              <ng-container *ngIf="reward.isApplied || isStaged">
                <button
                  type="button"
                  class="fw-bold pointer de-danger-btn-sm font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center w-100 justify-content-center"
                  (click)="removeClicked()"
                >
                  Remove
                  <i
                    class="ms-2 bi bi-dash-circle font-size-body-large"
                  ></i>
                </button>
              </ng-container>
            </ng-container>
            <!-- Loading State -->
            <ng-container
              *ngIf="
                loadingReward === reward.rewardID && reward.rewardID !== null
              "
            >
              <button
                type="button"
                class="d-flex flex-row flex-nowrap text-nowrap gap-1 de-primary-btn align-items-center w-100 justify-content-center"
              >
                <div class="fw-bold">Loading</div>
                <i
                  class="bi bi-arrow-repeat spinning font-size-body-large"
                ></i>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
