import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of, Subscription, timer } from 'rxjs';
import { SavedCard } from '../../../../interfaces/saved-card.interface';
import { GlobalStateModel } from '../../../../store/state.model';
import { RewardsBalances } from '../../../../interfaces/rewards-balances.interface';
import { DeleteSavedCard, SaveCardAsDefault, SetSavedCards } from '../../../../store/actions/user.actions';
import { SetRouteBack, UpdateTitle } from '../../../../store/actions/app.actions';
import { catchError, filter, mapTo, switchMap } from 'rxjs/operators';
import { MetaService } from '../../../../services/meta.service';
import { VendorSetup } from '../../../../vendors/directus/interfaces/vendor.interface';
import { Order } from '../../../../interfaces/order.interface';
import { User } from '../../../../interfaces/user.interface';
import { NavigationService } from '@modules/navigation/services';
import { LoyaltySection } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { Reward } from '../../../../interfaces/reward.interface';

@Component({
  selector: 'app-saved-cards-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './saved-cards-section.component.html',
  styleUrls: ['saved-cards-section.component.scss'],
})
export class SavedCardsSectionComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.user.savedCards) savedCards$: Observable<SavedCard[]>;
  @Select((state: GlobalStateModel) => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select((state: GlobalStateModel) => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.order.order) order$: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;

  @Input() section: LoyaltySection;
  @Input() isResponsive = false;

  private subs: Subscription[] = [];
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displaySavedCards: SavedCard[];
  savedCreditCards: SavedCard[];
  isLoading = true;
  error = false;

  title = 'saved cards';

  locationsRoute = this.navigation.getLocationsPageSlug();

  displaySavedCards$: Observable<SavedCard[]>;


  constructor(
    private store: Store,
    private meta: MetaService,
    private navigation: NavigationService
  ) {}
  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.meta.resetToDefault();
    this.meta.blockCrawling();

    this.displaySavedCards$ = this.savedCards$.pipe(
      switchMap(savedCards => {
        if (savedCards && savedCards.length > 0) {
          return of(savedCards);
        } else {
          return timer(3000).pipe(mapTo([]));
        }
      }),
      catchError(() => {
        this.error = true;
        return of([]);
      })
    );

    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // Navbar service data
    this.subs.push(
      this.vendorSetup$.subscribe(vendorSetup => {
        if (vendorSetup) {
          this.displayVendorSetup = vendorSetup;
        }
      })
    );
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
        }
      })
    );
    this.subs.push(
      this.user$.pipe(filter(u => u !== null)).subscribe(user => {
        this.isLoading = true;
        this.displayUser = user ? user : null;
        this.store
          .dispatch(new SetSavedCards(user.userID, true))
          .toPromise()
          .then(() => {
            this.isLoading = false;
          });
      })
    );
    // Page service data
    this.subs.push(
      this.savedCards$.subscribe(savedCreditCards => {
        this.savedCreditCards = savedCreditCards;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  deleteCard(card: SavedCard) {
    this.isLoading = true;
    this.store
      .dispatch(new DeleteSavedCard(card))
      .toPromise()
      .then(() => {
        this.isLoading = false;
      });
    // this.navbarService.deleteSavedCard(card);
  }

  defaultCard(card: SavedCard) {
    this.store
      .dispatch(new SaveCardAsDefault(card))
      .toPromise()
      .then(() => {});
    // document.getElementById('cardBorder').style.color = '#00ff00 !important';
  }
}
