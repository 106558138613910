<div class="w-100 d-flex flex-row flex-nowrap align-items-center gap-2 mb-3">
  <h4 class="text-start fw-bold header-font mb-0 d-flex flex-row align-items-center">
    <app-custom-icon
      class="color-primary me-2"
      default="bi-activity"
      customIconKey="activity_navigation_icon"
      altText="Activity"
    ></app-custom-icon>
    {{ section?.section_title || 'Activity' }}
  </h4>
  <!-- Points Balance -->
  <div class="ms-auto">
    <ng-container *ngIf="isDashboard">
        <button
          class="pill-btn-default pill-btn-sm text-nowrap"
          [routerLink]="activityRoute">
          View All Activity
        </button>
      </ng-container>
  </div>
</div>

<div class="d-flex flex-column gap-2">
  <ng-container *ngIf="!isLoading else activityLoading">
    <ng-container *ngIf="displayActivities && displayActivities.length > 0; else noActivity">
      <ion-list lines="none">
        <ng-container *ngFor="let event of displayActivities">
            <app-activity-event-card
              class="w-100 h-100"
              [event]="event"
            ></app-activity-event-card>
        </ng-container>
      </ion-list>
    </ng-container>
  </ng-container>
</div>

<ng-template #activityLoading>
  <div style="text-align: center; padding: 15vh 2vw">
    Loading loyalty activity
    <ion-spinner name="crescent"></ion-spinner>
  </div>
</ng-template>

<ng-template #noActivity>
  <div class="d-flex flex-column gap-3 justify-content-center align-items-center p-3">
    <app-custom-icon
      class="color-primary display-3"
      default="bi-activity"
      customIconKey="activity_navigation_icon"
      altText="Activity"
    ></app-custom-icon>
    <div class="text-center d-flex flex-column">
      <h5 class="fw-bold">Oops, you have no activity.</h5>
      <div>Check back after placing an order</div>
    </div>
    <button
      class="de-primary-link w-100"
      style="max-width: 264px"
      [routerLink]="locationsRoute">
      Order Now
    </button>
  </div>
</ng-template>
