export interface CreateMemberRequest {
  /**
   * Required
   *
   * Member’s phone number
   */
  phone: string;
  /**
   * Required
   *
   * Member's email address
   */
  email: string;
  /**
   * Required
   *
   * Member's password
   */
  password: string;
  /**
   * Optional
   *
   * Member's first name
   */
  first_name?: string;
  /**
   * Optional
   *
   * Member's last name
   */
  last_name?: string;
  /**
   * Optional
   *
   * Member's date of birth formatted as YYYY-MM-DD
   */
  dob?: string;
  /**
   * Optional
   *
   * Member's gender
   */
  gender?: Gender;
  /**
   * Optional
   *
   * Member's marital status
   */
  marital_status?: MaritalStatus;
  /**
   * Optional
   *
   * Member's street address
   */
  street?: string;
  /**
   * Optional
   *
   * Member's address unit
   */
  unit?: string;
  /**
   * Optional
   *
   * Member's city
   */
  city?: string;
  /**
   * Optional
   *
   * Member's 2-letter state abbreviation (e.g. CA)
   */
  state?: string;
  /**
   * Optional
   *
   * Member's zip code
   */
  zip?: string;
  /**
   * Optional
   *
   * Spendgo unique identifier of the member’s favorite store
   */
  favorite_store_id?: number;
  /**
   * Optional
   *
   * Member’s text subscription preference for your brand. If the value is true, once the user has validated their account creation, they
   * will be sent the text double opt-in flow to confirm their text subscription.
   */
  sms_opt_in?: boolean;
  /**
   * Optional
   *
   * Member’s email marketing preference for your brand.
   */
  email_opt_in?: boolean;
  /**
   * Optional
   *
   * Member’s research survey opt-in preference.
   */
  research_opt_in?: boolean;
}

export interface CreateMemberResponse {
  id: string;
  status: MemberAccountStatus;
}

export enum MemberAccountStatus {
  ACTIVE = 'Active',
  WAITING_FOR_VERIFICATION = 'WaitingEmailVerification'
}

export enum MaritalStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  DOMESTIC_PARTNER = 'Domestic Partner'
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F'
}
